import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';

const useStyles = makeStyles()((theme) => ({
  tablePaginationArrows: {
    flexShrink: 0,
    marginLeft: theme.spacing(5),
  },
})); // useStyles

const TablePaginationActions = ({ count, page, rowsPerPage, onChangePage, isEditing }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { dialogDispatch } = useContext(DialogContext);

  const handleDialogOpen = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        singleBtn: true,
        title: '您尚未完成編輯！',
        message: '請先儲存或取消編輯',
      },
    });
  };

  const handleFirstPageButtonClick = () => {
    if (isEditing) {
      handleDialogOpen();
    } else {
      onChangePage(1);
    }
  };
  const handleBackButtonClick = () => {
    if (isEditing) {
      handleDialogOpen();
    } else {
      onChangePage(page - 1);
    }
  };

  const handleNextButtonClick = () => {
    if (isEditing) {
      handleDialogOpen();
    } else {
      onChangePage(page + 1);
    }
  };

  const handleLastPageButtonClick = () => {
    if (isEditing) {
      handleDialogOpen();
    } else {
      onChangePage(Math.max(0, Math.ceil(count / rowsPerPage)));
    }
  };

  return (
    <div className={classes.tablePaginationArrows}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 1}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}; // TablePaginationActions
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default TablePaginationActions;
