import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';

const CustomerTypeToolbar = ({ handleSearch, isEditing }) => {
  const [customer, setCustomer] = useState('');
  const { dialogDispatch } = useContext(DialogContext);

  const handleCustomerInputChange = (e) => {
    setCustomer(e.target.value);
  };
  const onSearchClick = (e, isSearchAll = false) => {
    if (!isEditing) {
      if (isSearchAll) {
        handleSearch('');
        setCustomer('');
        return;
      }
      handleSearch(customer);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '您尚未完成編輯！',
          message: '請先儲存或取消編輯',
        },
      });
    }
  };
  return (
    <Toolbar>
      <Grid
        container
        style={{ marginTop: 25, marginBottom: 20, width: '100%' }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid xs={7} style={{ marginLeft: -25, marginRight: 15 }}>
          <InputField
            id="customerNo"
            label=""
            value={customer}
            variant="outlined"
            onChange={handleCustomerInputChange}
            placeholder="請輸入要搜尋的客戶名稱"
            name="customerNo"
            type="text"
            errormsg="請輸入正確的客戶名稱"
            size="small"
          />
        </Grid>
        <Grid xs={5}>
          <Button
            onClick={onSearchClick}
            variant="outlined"
            color="primary"
            startIcon={<SearchRoundedIcon />}
            style={{ marginRight: 10 }}
          >
            查詢
          </Button>
          <Button onClick={(e) => onSearchClick(e, true)} variant="outlined" color="grey">
            查詢全部
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
}; // CustomerTypeToolbar

CustomerTypeToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default CustomerTypeToolbar;
