/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
import { specConfigItemStatus } from '../constants/enums';

/**
 * check if current specConfig is different to the original data
 * @param {Array<Object>} currentSpecConfig measure field from api /getSinterBase
 * @param {Array<Object>} dataSpecConfig  first row of sinterRow field from api /getSinterCheck
 * @description example currentSpecConfig object data:
 * { specId: '21', specItem: 'A', specTarget: '', specUpper: '', specLower: '', limitUpper: '', limitLower: '', specBias: '' }
 * @description example dataSpecConfig object data:
 * { measureId: 21, sinterDetailId: 113, sinterRowId: 23, detailValue: '81' }
 * @returns { Array<Object>, Array<Object>, Array<Object> }
 */
export const checkSpecConfigDiffOldVersion = (currentSpecConfig, dataSpecConfig) => {
  const intersection = dataSpecConfig.filter((spec) =>
    currentSpecConfig.find((row) => row.specId === spec.measureId.toString())
  );
  const currentDiff = currentSpecConfig.filter(
    (spec) => !dataSpecConfig.find((row) => row.measureId.toString() === spec.specId)
  );
  const dataDiff = dataSpecConfig.filter(
    (spec) => !currentSpecConfig.find((row) => row.specId === spec.measureId.toString())
  );
  console.log('currentDiff: ', currentDiff);
  console.log('dataDiff: ', dataDiff);
  console.log('intersection: ', intersection);
  // modify specItem header row
  // 1. 只有currentSpecConfig有的欄位，且isEnabled為true，表示為新增的config欄位
  const modifiedCurrentSpecConfig = currentSpecConfig.map((spec) => {
    const targetSpec = currentDiff.find((row) => row.specId === spec.specId);
    if (targetSpec) {
      return { ...spec, colStatus: specConfigItemStatus.ADDED };
    }
    return { ...spec, colStatus: specConfigItemStatus.CURRENT };
  });
  // 2. 只有dataSoecConfig中的欄位，表示為已刪除的config欄位 -> 已刪除的config欄位資料可從currentSpecConfig中取得
  dataDiff.forEach((item) => {
    const targetSpec = currentSpecConfig.find((spec) => spec.specId === item.measureId.toString());
    modifiedCurrentSpecConfig.push({
      ...targetSpec,
      colStatus: specConfigItemStatus.REMOVED,
    });
  });
  return {
    modifiedCurrentSpecConfig,
    currentDiff,
    dataDiff,
  };
};

/**
 * check if current specConfig is different to the original data
 * @param {*} currentSpecConfig measure field from api /getSinterBase
 * @param {*} dataSpecConfig first rowof sinterRow field from api /getSinterCheck
 * @description example currentSpecConfig object data:
 * { specId: '21', specItem: 'A', specTarget: '', specUpper: '', specLower: '', limitUpper: '', limitLower: '', specBias: '' }
 * @description example dataSpecConfig object data:
 * { measureId: 21, sinterDetailId: 113, sinterRowId: 23, detailValue: '81' }
 * @returns { Array, Array, Array }
 *
 * [modifiedCurrentSpecConfig] will be the header row of the table |
 * [currentDiff] are the measure that are added (only exist in currentSpecConfig and isEnabled = true) |
 * [dataDiff] are the measure that are removed (only exist in dataSpecConfig and isEnabled = false) |
 */
export const checkSpecConfigDiff = (currentSpecConfig, dataSpecConfig) => {
  if (currentSpecConfig.length === 0)
    return { modifiedCurrentSpecConfig: [], currentDiff: [], dataDiff: [] };
  // TODO: after config differetiation test pass, delete all console
  // if (!currentSpecConfig || !dataSpecConfig)
  //   return { modifiedCurrentSpecConfig: [], currentDiff: [], dataDiff: [] };
  const currentDiff = currentSpecConfig.filter(
    (spec) => !dataSpecConfig.find((item) => item.measureId.toString() === spec.specId)
  );
  let currentDiffEnabled = currentDiff.filter((spec) => spec.isEnabled);

  let dataSpecConfigArr = [];
  const dataDiffUnabled = [];
  // eslint-disable-next-line no-unused-expressions
  dataSpecConfig.forEach((row) => {
    const targetSpec = currentSpecConfig.find((spec) => spec.specId === row.measureId.toString());
    dataSpecConfigArr.push(targetSpec);
    if (targetSpec && !targetSpec.isEnabled) {
      dataDiffUnabled.push({ ...targetSpec, colStatus: specConfigItemStatus.REMOVED });
    }
  });

  // dataSpecConfig中有的欄位：isEnabled = true表舊有的欄位; isEnabled = false表已刪除的欄位資料
  const modifiedCurrentSpecConfig = dataSpecConfigArr.map((spec) =>
    spec.isEnabled
      ? { ...spec, colStatus: specConfigItemStatus.CURRENT }
      : { ...spec, colStatus: specConfigItemStatus.REMOVED }
  );

  // 取得目前開啟的量測規格
  currentDiffEnabled.forEach((spec) => {
    modifiedCurrentSpecConfig.push({ ...spec, colStatus: specConfigItemStatus.ADDED });
  });

  modifiedCurrentSpecConfig.sort((a, b) => {
    if (a.colStatus === b.colStatus) {
      return a.measureId - b.measureId;
    }
    return b.colStatus - a.colStatus;
  });

  return {
    modifiedCurrentSpecConfig,
    currentDiff: currentDiffEnabled,
    dataDiff: dataDiffUnabled,
  };
};
