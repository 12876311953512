/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import useStyles from './useStyles';

const AutoComplete = ({
  options,
  label,
  id,
  value,
  onChange,
  className,
  placeholder,
  error,
  errormsg,
  disabled,
  ...rest
}) => {
  const { classes } = useStyles();
  return (
    <FormControl
      variant="outlined"
      className={`${classes.formControl} ${className}`}
      fullWidth
      {...rest}
    >
      <Autocomplete
        freeSolo
        id={id}
        name={id}
        disableClearable
        options={options.map((option) => option.name)}
        value={value}
        inputValue={value}
        onChange={onChange}
        onInputChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            placeholder={options.length > 0 ? placeholder : '沒有符合條件的選項'}
            variant="outlined"
            name={id}
            InputProps={{ ...params.InputProps, type: 'search' }}
            error={error}
            errormsg={error && errormsg}
            disabled={disabled}
          />
        )}
      />
      {error && (
        <FormHelperText className={`${classes.error} ${classes.errorMsg}`}>
          {errormsg}
        </FormHelperText>
      )}
    </FormControl>
  );
};
AutoComplete.defaultProps = {
  className: '',
  error: false,
  errormsg: '',
  disabled: false,
};
AutoComplete.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.bool,
  errormsg: PropTypes.string,
  disabled: PropTypes.bool,
};
export default AutoComplete;
