import { makeStyles } from 'tss-react/mui';
import { alpha, darken } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  error: {
    color: '#f44336',
    '&:hover': {
      backgroundColor: alpha('#f44336', 0.1),
    },
    '&.MuiButton-outlined': {
      backgroundColor: 'transparent',
      borderWidth: 1.5,
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    '&.MuiButton-contained': {
      backgroundColor: 'theme.palette.error.main',
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: darken(theme.palette.error.main, 0.1),
      },
    },
  },
  grey: {
    color: theme.palette.grey[600],
    borderColor: theme.palette.grey[600],
    '&:hover': {
      color: alpha(theme.palette.grey[600], 0.1),
    },
    '&.MuiButton-outlined': {
      backgroundColor: 'transparent',
      borderWidth: 1.5,
      borderColor: theme.palette.grey[600],
      color: theme.palette.grey[600],
    },
  },
  rounded: {
    '&.MuiButton-root': {
      borderRadius: 100,
    },
    '&.MuiButton-outlined': {
      backgroundColor: 'transparent',
      borderWidth: 1.5,
      borderColor: 'currentColor',
      '&:hover': {
        opacity: 0.85,
      },
    },
  },
}));

export default useStyles;
