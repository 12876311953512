/* eslint-disable react/prop-types */
import React from 'react';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const Tabs = ({ listItem, className, ...rest }) => (
  <MuiTabs {...rest}>
    {listItem.map((item) => (
      <Tab className={className} label={item} key={item} />
    ))}
  </MuiTabs>
);

export default Tabs;
