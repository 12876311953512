/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MuiListItemButton from '@mui/material/ListItemButton';
import { withStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
import indigo from '@mui/material/colors/indigo';

const styles = {
  root: {
    color: alpha('#000', 0.87),
    textDecoration: 'none',
    '&.Mui-disabled': {
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor: alpha(indigo[300], 0.2),
      '& .MuiListItemIcon-root': {
        color: indigo[700],
      },
    },
    '&:hover': {
      backgroundColor: alpha(indigo[300], 0.2),
      color: indigo[700],
      '& .MuiListItemIcon-root': {
        color: indigo[700],
      },
    },
  },
  selected: {
    backgroundColor: alpha(indigo[300], 0.2),
    color: indigo[700],
  },
  disabled: {
    color: alpha('#000', 0.87),
  },
};

const ListItem = (props) => <MuiListItemButton {...props} />;

export default withStyles(ListItem, styles);
