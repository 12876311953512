/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import useCommonStyles from '../../useStyles';
import { measureStyleType } from '../../../../constants/enums';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import { getSpec } from '../../../../Utils/measureSpecUtils';

const LMeasureStyle = ({ specSettings, measureConfig, diffNLMeasure }) => {
  const LSpec = getSpec(specSettings, measureStyleType.L).toString();
  const diffLSpec = getSpec(specSettings, measureStyleType.L, diffNLMeasure, true).toString();
  const coilSpec = getSpec(specSettings, measureStyleType.COIL).join(' / ');
  const diffCoilSpec = getSpec(specSettings, measureStyleType.COIL, measureConfig, true).join(
    ' / '
  );
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;

  return (
    <Grid container className={`${commonClasses.table_header} ${classes.measure_header}`}>
      {LSpec && (
        <Typography variant="subtitle1" className={classes.measure_info}>
          <b>L設定：</b>
          <b>{LSpec}</b>
          {diffLSpec && LSpec !== diffLSpec && (
            <>
              <br />
              <b style={{ opacity: 0 }}>L設定：</b>
              <b className={classes.measure_info_disabled}>{diffLSpec}</b>
            </>
          )}
        </Typography>
      )}
      {coilSpec && (
        <Typography variant="subtitle1" className={classes.measure_info}>
          <span>線圈設定：</span>
          <i>{coilSpec}</i>
          {diffCoilSpec && diffCoilSpec !== coilSpec && (
            <>
              <br />
              <span style={{ opacity: 0 }}>線圈設定：</span>
              <i className={classes.measure_info_disabled}>{diffCoilSpec}</i>
            </>
          )}
        </Typography>
      )}
    </Grid>
  );
};

LMeasureStyle.propTypes = {
  specSettings: PropTypes.object.isRequired,
  measureConfig: PropTypes.array.isRequired,
  diffNLMeasure: PropTypes.array.isRequired,
};

export default LMeasureStyle;
