export const authActions = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  INIT: 'INIT',
  FATAL: 'FATAL',
  PRIVILEGE: 'PRIVILEGE',
};

const authReducer = (state, action) => {
  switch (action.type) {
    case authActions.LOGIN: {
      return {
        ...state,
        isLogin: true,
        fatalError: false,
      };
    }
    case authActions.LOGOUT:
      return {
        ...state,
        isLogin: false,
        fatalError: false,
      };
    case authActions.INIT: {
      return {
        ...state,
        lineAuth: { ...action.lineAuth },
        fatalError: false,
      };
    }
    case authActions.PRIVILEGE:
      return {
        ...state,
        hasPrivilege: action.hasPrivilege,
      };
    case authActions.FATAL:
      return {
        ...state,
        isLogin: false,
        fatalError: true,
      };
    default:
      return state;
  }
};

export default authReducer;
