import { createTheme, alpha, darken } from '@mui/material';
import indigo from '@mui/material/colors/indigo';

const font = `'Noto Sans TC', Roboto, sans-serif`;
const theme = createTheme({
  typography: {
    fontFamily: font,
    h1: {
      fontSize: 92,
      lineHeight: 1.2,
      letterSpacing: '-1.395px',
      fontWeight: 300,
    },
    h2: {
      fontSize: 58,
      lineHeight: 1.24,
      letterSpacing: '-0.464px',
      fontWeight: 300,
    },
    h3: {
      fontSize: 48,
      lineHeight: 1.17,
      fontWeight: 400,
    },
    h4: {
      fontSize: 32,
      lineHeight: 1.25,
      letterSpacing: '0.5px',
      fontWeight: 400,
    },
    h5: {
      fontSize: 24,
      lineHeight: 1,
      letterSpacing: '0.2px',
      fontWeight: 400,
    },
    h6: {
      fontSize: 20,
      lineHeight: 1.2,
      letterSpacing: '0.15px',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.15px',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: 1.7,
      letterSpacing: '0.1px',
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.5px',
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.4,
      letterSpacing: '0.25px',
      fontWeight: 400,
    },
    button: {
      fontSize: 17,
      lineHeight: 1.2,
      letterSpacing: '1.5px',
      fontWeight: 700,
    },
    overline: {
      fontSize: 15,
      lineHeight: 1.2,
      letterSpacing: '1.335px',
      fontWeight: 700,
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.3,
      letterSpacing: '0.4px',
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      dark: indigo[700],
      main: indigo[500],
      light: indigo[200],
    },
    secondary: {
      dark: '#007d74',
      border: '#01A299',
      main: '#00B3A6',
      light: '#33C2b7',
    },
    error: {
      dark: darken('#B00020', 0.3),
      main: '#B00020',
      lighter: '#CF6679',
      light: alpha('#CF6679', 0.3),
    },
    grey: {
      main: alpha('#000', 0.87),
    },
    background: {
      default: '#fafafa',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: '12px 22px',
        },
        outlined: {
          backgroundColor: alpha(indigo[100], 0.4),
          fontSize: 15,
          padding: '8px 17px',
        },
        text: {
          fontSize: 16,
          fontWeight: 400,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: '1em',
          transform: 'translate(14px, -6px) scale(0.75)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: alpha('#000', 0.08),
            '&:hover': {
              backgroundColor: alpha('#000', 0.08),
            },
            '&:focus': {
              backgroundColor: alpha('#000', 0.08),
            },
          },
        },
      },
    },
    // 覆蓋掉 DatePicker 預設當日會有一個 border
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&:not(.Mui-selected)': {
            border: 'none',
          },
          fontSize: 14,
        },
        today: {
          color: indigo[500],
        },
      },
    },
  },
});

export default theme;
