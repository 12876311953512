import { inventoryStatus } from '../constants/enums';

const useInventoryStatus = (type) => {
  switch (type) {
    case inventoryStatus.SHORTAGE:
      return {
        name: '盤虧',
        value: type,
        id: type,
      };
    case inventoryStatus.OVERAGE:
      return {
        name: '盤盈',
        value: type,
        id: type,
      };
    default:
      return {
        name: '-',
        value: inventoryStatus.UNCHANGED,
        id: inventoryStatus.UNCHANGED,
      };
  }
};

export default useInventoryStatus;
