import React from 'react';
import FormingInfo from './FormingType/FormingInfo';
import { stationType, formingManufactureType } from '../../constants/enums';

const TransUpdateForming = () => (
  <FormingInfo
    pageTitle="更新移轉單（壓型）"
    pageLabel="壓型"
    formingType={formingManufactureType.FORMING}
    stationType={stationType.FORMING}
  />
);

export default TransUpdateForming;
