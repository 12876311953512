/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format, addDays } from 'date-fns';
import zhTW from 'date-fns/locale/zh-TW';
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';
import Typography from '../../../../components/Typography/Typography';
import Divider from '../../../../components/Divider/Divider';
import Dialog from '../../../../components/Dialog/Dialog';
import DialogTitle from '../../../../components/DialogTitle/DialogTitle';
import DialogContent from '../../../../components/DialogContent/DialogContent';
import DialogActions from '../../../../components/DialogActions/DialogActions';
import CircularProgress from '../../../../components/CircularProgress/CircularProgress';
import useStyles from './useStyles';
import useCommonStyles from '../../useStyles';
import { parseDecimalToFixedString } from '../../../../Utils/stringUtil';

const SPCChartType = {
  AVG: 1,
  SD: 2,
};

const ChartDialog = ({ open, handleChartClose, currentRow, spcChartData, spcChartConfig }) => {
  const { material, productName, specItem } = currentRow;
  const [isLoading, setIsLoading] = useState(false);
  const [isAvgChartComplete, setIsAvgChartComplete] = useState(false);
  const [isSdChartComplete, setIsSdChartComplete] = useState(false);
  const avgSpcChartEl = useRef(null);
  const avgSpcChart = useRef(null);
  const sdSpcChartEl = useRef(null);
  const sdSpcChart = useRef(null);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  Chart.register(...registerables);

  const { measureTarget, measureUpperSpec, measureLowerSpec } = spcChartConfig;
  const { UCLAvg, LCLAvg, UCLSd, LCLSd } = currentRow;
  const upperSpec = +measureTarget + +measureUpperSpec;
  const lowerSpec = +measureTarget - +measureLowerSpec;
  const avgTargetArr = [+measureTarget, upperSpec, lowerSpec, UCLAvg, LCLAvg];
  const sdTargetArr = [UCLSd, LCLSd];

  const getLabels = () => Array.from(spcChartData, (item) => item.modifyDate);
  const autoGenerateLabels = () => [
    ...getLabels(),
    addDays(new Date(getLabels()[0]), 1).toISOString(),
  ];

  const getSourceArr = () => (spcChartData.length > 1 ? spcChartData : new Array(2));

  const drawSpcChart = (
    ctx,
    type,
    dataset,
    maxValue,
    UCL,
    LCL,
    targetData,
    upperSpecData,
    lowerSpecData
  ) =>
    new Chart(ctx, {
      data: {
        labels: getLabels().length > 1 ? getLabels() : autoGenerateLabels(),
        datasets:
          type === SPCChartType.AVG
            ? [
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: dataset,
                  backgroundColor: ['rgba(1, 162, 153, 1)'],
                  borderColor: 'rgba(1, 162, 153, 1)',
                  order: 2,
                },
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: dataset,
                  backgroundColor: ['rgba(0,0,0,0)'],
                  borderColor: 'rgba(0,0,0,0)',
                  order: 1,
                },
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: targetData,
                  borderColor: ['rgba(63, 81, 181, 1)'],
                  borderDash: [4, 6],
                  pointRadius: 0,
                  borderWidth: 1.5,
                  order: 1,
                },
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: upperSpecData,
                  borderColor: ['rgba(176, 0, 32, 1)'],
                  borderDash: [4, 6],
                  pointRadius: 0,
                  borderWidth: 1.5,
                  order: 1,
                },
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: lowerSpecData,
                  borderColor: ['rgba(176, 0, 32, 1)'],
                  borderDash: [10, 10],
                  pointRadius: 0,
                  borderWidth: 1.5,
                  order: 1,
                },
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: UCL,
                  borderColor: ['rgba(255, 152, 0, 1)'],
                  borderDash: [4, 6],
                  pointRadius: 0,
                  borderWidth: 1.5,
                  order: 1,
                },
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: LCL,
                  borderColor: ['rgba(255, 152, 0, 1)'],
                  borderDash: [10, 10],
                  pointRadius: 0,
                  borderWidth: 1.5,
                  order: 1,
                },
              ]
            : [
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: dataset,
                  backgroundColor: ['rgba(1, 162, 153, 1)'],
                  borderColor: 'rgba(1, 162, 153, 1)',
                  order: 2,
                },
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: dataset,
                  backgroundColor: ['rgba(0,0,0,0)'],
                  borderColor: 'rgba(0,0,0,0)',
                  order: 1,
                },
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: UCL,
                  borderColor: ['rgba(255, 152, 0, 1)'],
                  borderDash: [4, 6],
                  pointRadius: 0,
                  borderWidth: 1.5,
                  order: 1,
                },
                {
                  type: 'line',
                  yAxisID: 'y',
                  data: LCL,
                  borderColor: ['rgba(255, 152, 0, 1)'],
                  borderDash: [10, 10],
                  pointRadius: 0,
                  borderWidth: 1.5,
                  order: 1,
                },
              ],
      },
      options: {
        aspectRatio: 2.5,
        scales: {
          x: {
            axis: 'x',
            display: true,
            label: 'Defect Type',
            ticks: {
              autoSkipPadding: 5,
              maxRotation: 90,
              callback: (value, idx) => {
                if (getLabels().length <= 1) {
                  if (spcChartData[idx]) {
                    return `${format(new Date(autoGenerateLabels()[idx]), 'yyyy-MM-dd')} ${
                      spcChartData[idx].transId
                    }`;
                  }
                  return null;
                }
                if (getLabels().length <= 10 && getLabels().length > 1) {
                  return `${format(new Date(getLabels()[idx]), 'yyyy-MM-dd')} ${
                    spcChartData[idx].transId
                  }`;
                }
                return `${format(new Date(getLabels()[idx]), 'yyyy-MM-dd')}`;
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            suggestedMax: maxValue,
            z: 2,
            grid: {
              drawBorder: false,
            },
            ticks: {
              padding: 25,
            },
          },
          //   y2: {
          //     type: 'linear',
          //     position: 'right',
          //     display: true,
          //     z: 1,
          //     grid: {
          //       display: false,
          //       drawBorder: false,
          //     },
          //     ticks: {
          //       display: false,
          //       // TODO: when the deviation is too big, will cause the performance issue -> use plan B to show those values under the chart if that is fine by client (2021.07.11)
          //       // stepSize: 0.01,
          //       // padding: 25,
          //       // callback: (value, idx, values) => {
          //       //   if (type === SPCChartType.AVG) {
          //       //     if (avgTargetArr.includes(value)) {
          //       //       return value;
          //       //     }
          //       //   }
          //       //   if (type === SPCChartType.SD) {
          //       //     if (sdTargetArr.includes(value)) {
          //       //       return value;
          //       //     }
          //       //   }
          //       //   return null;
          //       // },
          //     },
          //   },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: type === SPCChartType.AVG ? '平均值SPC Chart' : '標準差SPC Chart',
            font: {
              size: 16,
              weight: 'normal',
            },
            padding: {
              bottom: 30,
              top: 24,
            },
          },
          tooltip: {
            filter: (tooltipItem) => tooltipItem.datasetIndex === 0,
            callbacks: {
              label: (context) => {
                if (!context) return null;
                const { raw } = context;
                return type === SPCChartType.AVG
                  ? `Avg: ${parseDecimalToFixedString(raw)}`
                  : `SD: ${parseDecimalToFixedString(raw)}`;
              },
              beforeTitle: (context) => {
                if (!context[0]) return null;
                const { dataIndex } = context[0];
                return `移轉單號：${spcChartData[dataIndex].transId}`;
              },
              title: (context) => {
                if (!context[0]) return null;
                const { label } = context[0];
                return `檢測日期：${format(new Date(label), 'yyyy-MM-dd')}`;
              },
              afterTitle: (context) => {
                if (!context[0]) return null;
                const { label } = context[0];
                return `檢驗時間：${format(new Date(label), 'HH:mm:ss')}`;
              },
              labelColor: () => ({
                borderRadius: 6,
                borderColor: 'rgba(0,0,0,0)',
                backgroundColor: 'rgba(1, 162, 153, 1)',
              }),
            },
          },
        },
        animation: {
          onComplete: (e) => {
            if (isAvgChartComplete && isSdChartComplete) return;
            if (!isAvgChartComplete && type === SPCChartType.AVG) {
              setIsAvgChartComplete(true);
            }
            if (!isSdChartComplete && type === SPCChartType.SD) {
              setIsSdChartComplete(true);
            }
          },
        },
      },
    });

  const getMaxValue = (type) => {
    const UCLValue =
      type === SPCChartType.AVG
        ? parseFloat(parseDecimalToFixedString(currentRow.UCLAvg))
        : parseFloat(parseDecimalToFixedString(currentRow.UCLSd));
    const datasetMax =
      type === SPCChartType.AVG
        ? Math.max(...spcChartData.map((item) => item.avgDetectValue))
        : Math.max(...spcChartData.map((item) => item.sdDetectValue));
    return datasetMax > UCLValue ? datasetMax + 1 : UCLValue + 1;
  };

  const drawAvgSpcChart = () => {
    if (avgSpcChart.current) {
      avgSpcChart.current.destroy();
    }
    avgSpcChartEl.current = document.getElementById('avgSpcChartEl');
    const ctx = avgSpcChartEl.current.getContext('2d');
    const dataset = Array.from(spcChartData, (item) => item.avgDetectValue);
    const sourceArr = getSourceArr();
    const targetData = Array.from(sourceArr, () => spcChartConfig.measureTarget);
    const upperSpecData = Array.from(sourceArr, () =>
      parseFloat(
        parseDecimalToFixedString(+spcChartConfig.measureTarget + +spcChartConfig.measureUpperSpec)
      )
    );
    const lowerSpecData = Array.from(sourceArr, () =>
      parseFloat(
        parseDecimalToFixedString(+spcChartConfig.measureTarget - +spcChartConfig.measureLowerSpec)
      )
    );
    const UCLData = Array.from(sourceArr, () =>
      parseFloat(parseDecimalToFixedString(currentRow.UCLAvg))
    );
    const LCLData = Array.from(sourceArr, () =>
      parseFloat(parseDecimalToFixedString(currentRow.LCLAvg))
    );
    const type = SPCChartType.AVG;
    const maxValue = getMaxValue(type);
    avgSpcChart.current = drawSpcChart(
      ctx,
      type,
      dataset.length > 1 ? dataset : [...dataset, ...dataset],
      maxValue,
      UCLData,
      LCLData,
      targetData,
      upperSpecData,
      lowerSpecData
    );
  };

  const drawSdSpcChart = () => {
    if (sdSpcChart.current) {
      sdSpcChart.current.destroy();
    }
    sdSpcChartEl.current = document.getElementById('sdSpcChartEl');
    const ctx = sdSpcChartEl.current.getContext('2d');
    const dataset = Array.from(spcChartData, (item) => item.sdDetectValue);
    const sourceArr = getSourceArr();
    const UCLData = Array.from(sourceArr, () =>
      parseFloat(parseDecimalToFixedString(currentRow.UCLSd))
    );
    const LCLData = Array.from(sourceArr, () =>
      parseFloat(parseDecimalToFixedString(currentRow.LCLSd))
    );
    const type = SPCChartType.SD;
    const maxValue = getMaxValue(type);
    avgSpcChart.current = drawSpcChart(ctx, type, dataset, maxValue, UCLData, LCLData);
  };

  useEffect(() => {
    if (!open) {
      setIsAvgChartComplete(false);
      setIsSdChartComplete(false);
      return;
    }
    setIsLoading(true);
    setTimeout(() => {
      drawAvgSpcChart();
      drawSdSpcChart();
    }, 100);
  }, [open]);

  useEffect(() => {
    if (isAvgChartComplete && isSdChartComplete) {
      setIsLoading(false);
    }
  }, [isAvgChartComplete, isSdChartComplete]);

  useEffect(
    () => () => {
      if (avgSpcChart.current) {
        avgSpcChart.current.destroy();
      }
      if (sdSpcChart.current) {
        sdSpcChart.current.destroy();
      }
    },
    []
  );

  return (
    <div className={classes.dialog}>
      <Dialog open={open} onClose={handleChartClose} fullWidth maxWidth="lg">
        <DialogTitle
          id="detail-title"
          onClose={handleChartClose}
          className={commonClasses.detail_header}
        >
          {material}｜{productName}｜{specItem} SPC Chart
        </DialogTitle>
        <DialogContent dividers className={commonClasses.detail_content}>
          <div className={`${classes.chart_container} ${isLoading ? classes.chart_loading : ''}`}>
            <canvas id="avgSpcChartEl" width="600" height="400" />
            {!isLoading && (
              <div className={classes.chart_legend_container}>
                <div className={classes.chart_legend}>
                  <span className={`${classes.chart_legend_label} ucl`} />
                  <Typography variant="subtitle2">UCL: {UCLAvg.toFixed(2)}</Typography>
                </div>
                <div className={classes.chart_legend}>
                  <span className={`${classes.chart_legend_label} lcl`} />
                  <Typography variant="subtitle2">LCL: {LCLAvg.toFixed(2)}</Typography>
                </div>
                <div className={classes.chart_legend}>
                  <span className={`${classes.chart_legend_label} upperSpec`} />
                  <Typography variant="subtitle2">Upper Spec: {upperSpec.toFixed(2)}</Typography>
                </div>
                <div className={classes.chart_legend}>
                  <span className={`${classes.chart_legend_label} lowerSpec`} />
                  <Typography variant="subtitle2">Lower Spec: {lowerSpec.toFixed(2)}</Typography>
                </div>
                <div className={classes.chart_legend}>
                  <span className={`${classes.chart_legend_label} target`} />
                  <Typography variant="subtitle2">Target: {measureTarget}</Typography>
                </div>
              </div>
            )}
          </div>
          {!isLoading && (
            <Divider className={commonClasses.dashed_divider} style={{ margin: `30px auto` }} />
          )}
          <div className={`${classes.chart_container} ${isLoading ? classes.chart_loading : ''}`}>
            <canvas id="sdSpcChartEl" width="600" height="400" />
            {!isLoading && (
              <div className={classes.chart_legend_container}>
                <div className={classes.chart_legend}>
                  <span className={`${classes.chart_legend_label} ucl`} />
                  <Typography variant="subtitle2">UCL: {UCLSd}</Typography>
                </div>
                <div className={classes.chart_legend}>
                  <span className={`${classes.chart_legend_label} lcl`} />
                  <Typography variant="subtitle2">LCL: {LCLSd}</Typography>
                </div>
              </div>
            )}
          </div>
          {isLoading && (
            <CircularProgress direction="column" alignItems="center" mt={11.25}>
              圖表繪製中⋯⋯
            </CircularProgress>
          )}
        </DialogContent>
        <DialogActions className={commonClasses.detail_footer} onClick={handleChartClose}>
          確認
        </DialogActions>
      </Dialog>
    </div>
  );
};

ChartDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleChartClose: PropTypes.func.isRequired,
  currentRow: PropTypes.object.isRequired,
  spcChartData: PropTypes.array.isRequired,
  spcChartConfig: PropTypes.object.isRequired,
};

export default ChartDialog;
