/* eslint-disable react/prop-types */
import React, { createContext, useReducer, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useLoginAxios from '../hooks/useLoginAxios';
import useVersionAxios from '../hooks/useVersion';
import authReducer, { authActions } from '../reducers/authReducer';

export const AuthContext = createContext();

const initialContext = {
  isLogin: false,
  hasPrivilege: false,
  lineAuth: {},
  fatalError: false,
};

const AuthContextProvider = ({ children }) => {
  const [authContext, authDispatch] = useReducer(authReducer, initialContext);
  const { isLogin } = authContext;
  const navigate = useNavigate();
  const location = useLocation();

  const getVersion = async () => {
    const [status, result] = await useVersionAxios('/getVersion', {});
    if (status) {
      const { version } = result;
      localStorage.setItem('version', version);
    }
  };
  const getLineParam = async () => {
    // if (localStorage.getItem('lineParam') && localStorage.getItem('lineParam').length !== 0) return;
    try {
      const [status, result, jwttoken] = await useLoginAxios('/lineParam');
      if (status) {
        const { id, state: lineState, secret, url } = result;
        localStorage.setItem('lineParam', JSON.stringify({ state: lineState, id, secret, url }));
        localStorage.setItem('pre-jwttoken', jwttoken);
        getVersion();
        authDispatch({
          type: authActions.INIT,
          lineAuth: {
            id,
            lineState,
            secret,
            url,
          },
        });
      } else {
        authDispatch({
          type: authActions.FATAL,
        });
      }
    } catch (err) {
      authDispatch({
        type: authActions.FATAL,
      });
    }
  };

  const logoutCleanup = () => {
    localStorage.removeItem('privilege');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('jwttoken');
  };

  const logout = () => {
    authDispatch({
      type: authActions.LOGOUT,
    });
    logoutCleanup();
  };

  const checkJwt = async () => {
    try {
      const [status, result, jwttoken] = await useLoginAxios('/resetJwt', {}, true);
      if (status) {
        localStorage.setItem('jwttoken', jwttoken);
        authDispatch({
          type: authActions.LOGIN,
        });
      } else {
        logout();
        const { pathname } = location;
        localStorage.setItem('targetPath', pathname);
        navigate('/login', { replace: true });
      }
    } catch (err) {
      authDispatch({
        type: authActions.FATAL,
      });
    }
  };

  const checkLogin = () => {
    const jwtToken = localStorage.getItem('jwttoken');
    if (jwtToken && jwtToken.length > 0) {
      checkJwt();
    } else {
      logout();
    }
  };

  const checkLoginStatus = () => {
    const jwtToken = localStorage.getItem('jwttoken');
    const privilege = localStorage.getItem('privilege');
    if (privilege && jwtToken && jwtToken.length > 0) {
      return true;
    }
    if (isLogin) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getLineParam();
    checkLogin();
  }, []);

  return (
    <AuthContext.Provider
      value={{ authContext, authDispatch, checkLoginStatus, logout, checkLogin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
