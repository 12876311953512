import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Typography from '../Typography/Typography';

const useStyles = makeStyles()((theme) => ({
  root: {
    '&.MuiTypography-root': {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
}));

const PageTitle = ({ title }) => {
  const { classes } = useStyles();
  return (
    <Typography variant="h4" className={classes.root}>
      {title}
    </Typography>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitle;
