/* eslint-disable no-lonely-if */
import React, { useState, useEffect, useRef, useContext } from 'react';
import useStyles from './useStyles';
import Tabs from '../../components/Tabs/Tabs';
import Paper from '../../components/Paper/Paper';
import Snackbar from '../../components/Snackbar/Snackbar';
import SettingPrivilegeStaff from './SettingPrivilegeStaff/SettingPrivilegeStaff';
import SettingPrivilegeRole from './SettingPrivilegeRole/SettingPrivilegeRole';
import SettingPrivilegeDialog from './SettingPrivilegeRole/SettingPrivilegeDialog';
import regex from '../../constants/validationRegex';
import useAxios from '../../hooks/useAxios';
import useCancelToken from '../../hooks/useCancelToken';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';

const tabsLabel = ['新增員工', '修改員工權限', '刪除員工', '角色'];

const SettingsPrivilege = () => {
  const { classes } = useStyles();
  const { newCancelToken } = useCancelToken();
  const { dialogDispatch } = useContext(DialogContext);
  const [selectedTab, setSelectTab] = useState(0);
  const [adminNo, setAdminNo] = useState('');
  const [adminNoErr, setAdminNoErr] = useState(false);
  const [statusMsg, setStatusMsg] = useState('');
  const [showStatus, setShowStatus] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [editedRoleData, setEditedRoleData] = useState({});
  const [settingsConfigTableContent, setSettingsConfigTableContent] = useState([]);
  const privilegeList = useRef([]);
  const totalCount = useRef(0);
  const [currentPage, setCurrentPage] = useState(1);
  const statusTimer = useRef(null);

  const handleTabChange = (event, newValue) => {
    if (newValue === selectedTab) return;
    setSelectTab(newValue);
  };

  const resetRoleData = () => {
    setIsAdding(false);
    setIsEditing(false);
    setIsViewing(false);
  };

  // 取得角色權限
  const getRoleData = (roleData) => {
    const data = roleData.map((role) => ({
      rowId: role.roleId,
      columns: [
        {
          name: 'roleName',
          value: role.roleId,
          displayValue: role.roleName,
          visible: true,
          isEditable: false,
        },
        {
          name: 'privilege',
          value: role.roleId,
          displayValue: role.apiApplyList
            .map((list) => list.apiName)
            .toString()
            .replace(regex.SPLIT_STRING, '、'),
          codeValue: role.apiApplyList.map((list) => list.code),
          visible: true,
          isEditable: false,
        },
      ],
    }));
    setSettingsConfigTableContent(data);
  };

  // 取得所有角色＆所有權限
  const getRoleList = async (page = currentPage) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getRoleList',
      { page },
      true,
      cancelToken
    );
    if (status) {
      const { privilegeTittleList, rolelist, count } = result;
      privilegeList.current = privilegeTittleList;
      totalCount.current = count;
      getRoleData(rolelist);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得角色列表',
          message:
            noPrivilegeMsg || '目前暫時無法取得角色的詳細資訊，請稍後再試，或請聯絡網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  // 取得設定角色狀態(Snackbar顯示文字)
  const getPrivilegeRoleStatus = (roleName, title) => {
    if (title === '新增') {
      setStatusMsg(`已成功新增 ${roleName} 角色！`);
    }
    if (title === '更新') {
      setStatusMsg(`已成功更新 ${roleName} 角色！`);
    }
    if (title === '刪除') {
      setStatusMsg(`已成功刪除 ${roleName} 角色！`);
    }

    setShowStatus(true);
    clearTimeout(statusTimer.current);
    statusTimer.current = setTimeout(() => {
      setShowStatus(false);
      clearTimeout(statusTimer.current);
    }, 3000);
  };

  // 設定角色權限
  const settingPrivilegeRole = async (data, title) => {
    let finalData = data;
    if (title === '刪除') {
      finalData = {
        roleData: {
          roleId: data.rowId,
          roleName: data.columns[0].displayValue,
          apiApplyList: data.columns[1].codeValue.map((item) => ({
            code: item,
          })),
        },
      };
      finalData.roleData.isEnabled = 0;
    }
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/userRoleSetting',
      finalData,
      true,
      cancelToken
    );
    if (status) {
      setCurrentPage(1);
      getPrivilegeRoleStatus(finalData.roleData.roleName, title);
      getRoleList();
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: `${title}錯誤`,
          message: noPrivilegeMsg || result,
        },
      });
    }
  };

  // 取得目前所點擊到的角色
  const getCurrentRoleData = (roleId) =>
    settingsConfigTableContent.filter((list) => list.rowId === roleId)[0];

  // 新增角色權限
  const handleRoleAdding = () => {
    setOpenDialog(true);
    setIsAdding(true);
  };

  // 編輯角色權限
  const handleRoleEditing = (roleId) => {
    setOpenDialog(true);
    setEditedRoleData(getCurrentRoleData(roleId));
    setIsEditing(true);
  };

  // 檢視角色權限
  const handleRoleViewing = (roleId) => {
    setIsViewing(true);
    setOpenDialog(true);
    setEditedRoleData(getCurrentRoleData(roleId));
  };

  // 刪除角色權限
  const handleRoleDelete = async (roleId) => {
    const finalData = getCurrentRoleData(roleId.rowId);
    finalData.isEnabled = 0;
    settingPrivilegeRole(finalData, '刪除');
  };

  // 切換頁碼
  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (selectedTab === 3) {
      getRoleList();
    } else {
      setCurrentPage(1);
    }
  }, [selectedTab, currentPage]);

  return (
    <div className="setting-privilege">
      <Paper elevation={2} className={classes.paper_tabs}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          textColor="secondary"
          indicatorColor="secondary"
          listItem={tabsLabel}
          className={classes.tabs}
        />
      </Paper>
      <Snackbar msg={statusMsg} type="success" open={showStatus} className={classes.snackbar} />

      {selectedTab !== 3 ? (
        <SettingPrivilegeStaff
          selectedTab={selectedTab}
          setStatusMsg={setStatusMsg}
          setShowStatus={setShowStatus}
          adminNo={adminNo}
          setAdminNo={setAdminNo}
          adminNoErr={adminNoErr}
          setAdminNoErr={setAdminNoErr}
        />
      ) : (
        <SettingPrivilegeRole
          setOpenDialog={setOpenDialog}
          setIsViewing={setIsViewing}
          settingsConfigTableContent={settingsConfigTableContent}
          totalRows={totalCount.current}
          currentPage={currentPage}
          handleChangePage={handleChangePage}
          handleAdding={handleRoleAdding}
          handleEditing={handleRoleEditing}
          handleViewing={handleRoleViewing}
          handleDelete={handleRoleDelete}
        />
      )}
      <SettingPrivilegeDialog
        open={openDialog}
        handleDialogClose={() => setOpenDialog(false)}
        privilegeList={privilegeList.current}
        settingPrivilegeRole={settingPrivilegeRole}
        isAdding={isAdding}
        isEditing={isEditing}
        isViewing={isViewing}
        resetData={resetRoleData}
        editedData={editedRoleData}
        setEditedData={setEditedRoleData}
      />
    </div>
  );
};

export default SettingsPrivilege;
