import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import NewDetectTypeAction from './SettingsConfigNewTransferDetectTypeAction';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';

const TransferDetectTypeTableToolbar = ({
  handleDelete,
  isChecked,
  handleAddTransferType,
  isEditing,
  isAdding,
  handleIsAdding,
}) => {
  const { dialogDispatch } = useContext(DialogContext);

  const handleDeleteClick = () => {
    handleDelete();
  };

  const handleAddingOpen = (e) => {
    e.preventDefault();
    if (!isEditing) {
      handleIsAdding(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '您尚未完成編輯！',
          message: '請先儲存或取消編輯',
        },
      });
    }
  };

  const handleAddingClose = () => {
    handleIsAdding(false);
  };

  return (
    <div>
      {isAdding ? (
        <NewDetectTypeAction
          handleAddingClose={handleAddingClose}
          handleAddTransferType={handleAddTransferType}
        />
      ) : (
        <div>
          <Toolbar>
            <Grid
              container
              style={{ marginTop: 25, marginBottom: 20, width: '100%' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  rounded
                  startIcon={<AddIcon />}
                  onClick={handleAddingOpen}
                >
                  新增一項
                </Button>
              </Grid>
              <Grid container xs={6} alignItems="center" justifyContent="flex-end">
                <Grid>
                  {isChecked ? (
                    <Button
                      variant="outlined"
                      color="error"
                      rounded
                      startIcon={<DeleteOutlinedIcon />}
                      onClick={() => handleDeleteClick()}
                    >
                      刪除多筆
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      rounded
                      startIcon={<DeleteOutlinedIcon />}
                      onClick={() => handleDeleteClick()}
                      disabled
                    >
                      刪除多筆
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
      )}
    </div>
  );
}; // TransferDetectTypeTableToolbar

TransferDetectTypeTableToolbar.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  handleAddTransferType: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool.isRequired,
  handleIsAdding: PropTypes.func.isRequired,
};

export default TransferDetectTypeTableToolbar;
