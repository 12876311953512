/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../components/Grid/Grid';
import ReadonlyField from '../../components/ReadonlyField/ReadonlyField';
import Divider from '../../components/Divider/Divider';
import useFormingType from '../../hooks/useFormingType';

const FilterInfo = ({ workInfo, classes }) => (
  <>
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <ReadonlyField
          name="formingType"
          label="壓型加工"
          value={useFormingType(workInfo.formingType).name || '-'}
        />
      </Grid>
      <Grid item xs={3}>
        <ReadonlyField name="elNo" label="料號" value={workInfo.elNo || '-'} />
      </Grid>
      <Grid item xs={3}>
        <ReadonlyField name="customer" label="客戶" value={workInfo.customer || '-'} />
      </Grid>
      <Grid item xs={3}>
        <ReadonlyField name="materialName" label="材質" value={workInfo.materialName || '-'} />
      </Grid>
      <Grid xs={3}>
        <ReadonlyField name="productName" label="產品名稱" value={workInfo.productName || '-'} />
      </Grid>
      <Grid xs={3}>
        <ReadonlyField name="scFDate" label="開工日期" value={workInfo.scFDate || '-'} />
      </Grid>
      <Grid xs={3}>
        <ReadonlyField name="odReDate" label="出貨日期" value={workInfo.odReDate || '-'} />
      </Grid>
    </Grid>
    <Divider className={classes.dashed_divider} />
    <Grid container spacing={4}>
      <Grid xs={3}>
        <ReadonlyField name="scQty" label="工令總數" value={workInfo.scQty || '-'} />
      </Grid>
      <Grid xs={3}>
        <ReadonlyField
          name="normalFormingQty"
          label="已壓型總數"
          value={workInfo.normalFormingQty || '-'}
        />
      </Grid>
      <Grid xs={3}>
        <ReadonlyField
          name="otherFormingQty"
          label="鑽孔/排列總數"
          value={workInfo.otherFormingQty || '-'}
        />
      </Grid>
      <Grid xs={3}>
        <ReadonlyField name="defectQty" label="耗損總數" value={workInfo.defectQty || '-'} />
      </Grid>
      <Grid xs={3}>
        <ReadonlyField name="storageQty" label="暫存工令總數" value={workInfo.storageQty || '-'} />
      </Grid>
      <Grid xs={3}>
        <ReadonlyField name="belongQty" label="掛帳工令總數" value={workInfo.belongQty || '-'} />
      </Grid>
      <Grid xs={3}>
        <ReadonlyField name="waitQty" label="待加工品數量" value={workInfo.waitQty || '-'} />
      </Grid>
    </Grid>
  </>
);

FilterInfo.propTypes = {
  workInfo: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default FilterInfo;
