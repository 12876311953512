/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import useStyles from './useStyles';
import useCommonStyles from '../useStyles';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import InputField from '../../../components/InputField/InputField';
import SelectField from '../../../components/SelectField/SelectField';
import ReadonlyField from '../../../components/ReadonlyField/ReadonlyField';
import Button from '../../../components/Button/Button';
import useAxios from '../../../hooks/useAxios';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useCancelToken from '../../../hooks/useCancelToken';
import regex from '../../../constants/validationRegex';

const TransInfo = ({
  transInfo,
  setTransInfo,
  transId,
  setTransId,
  handleTransBlur,
  transIdErr,
  handleEditMeasure,
  showEditBtn,
  resetTransInfo,
  setResetTransInfo,
  getMachineList,
}) => {
  const { dialogDispatch } = useContext(DialogContext);
  // const [currentTransInfo, setCurrentTransInfo] = useState({});
  const currentTransInfo = useRef({});
  const [formMachineErr, setFormMachineErr] = useState(false);
  const [formerErr, setFormerErr] = useState(false);
  const [bucketNoErr, setBucketNoErr] = useState(false);
  const [machineList, setMachineList] = useState([]);
  const [formerList, setFormerList] = useState([]);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();

  const handleTransInfoChange = (e) => {
    setTransInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const fetchMachineList = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/machineData',
      { stationType: 1 },
      true,
      cancelToken
    );
    if (status) {
      const { machine } = result;
      getMachineList(machine);
      setMachineList(
        machine.map((item) => ({
          name: item.machineName,
          value: item.machineId,
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得機台清單',
          message: noPrivilegeMsg || '目前暫時無法取得機台清單，請稍後再試，或請聯絡網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  const fetchFormerList = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/formerEnable',
      {},
      true,
      cancelToken
    );
    if (status) {
      const { former } = result;
      setFormerList(
        former.map((item) => ({
          name: item.userName,
          value: item.userId,
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得壓型人員清單',
          message: noPrivilegeMsg || '目前暫時無法取得壓型人員清單，清稍後再試，或請聯絡網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  const validationTransInfo = () => {
    // if (transInfo.bucketNo.length === 0 || !regex.BUCK_NO.test(transInfo.bucketNo)) {
    //   setBucketNoErr(true);
    //   return false;
    // }
    if (transInfo.formMachineId === '0') {
      setFormMachineErr(true);
      return false;
    }
    // if (transInfo.modifyUser === '0') {
    //   setFormerErr(true);
    //   return false;
    // }
    return true;
  };

  const resetTransInfoErr = () => {
    setBucketNoErr(false);
    setFormMachineErr(false);
    setFormerErr(false);
  };

  const handleConfirmClick = (isNew) => {
    if (transInfo.isInventory) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '該單號為暫存單號',
          message: `該單號為暫存單號，不得新增壓型檢測！`,
          singleBtn: true,
        },
      });
      return;
    }
    resetTransInfoErr();
    if (!validationTransInfo()) return;
    handleEditMeasure(isNew);
  };

  const handleInputChange = (e) => {
    setTransId(e.target.value);
  };
  useEffect(() => {
    if (transInfo.customer === '-') return;
    // setCurrentTransInfo(transInfo);
    currentTransInfo.current = transInfo;
  }, [transInfo.customer]);

  useEffect(() => {
    if (transInfo.formMachine === '0') {
      fetchMachineList();
    }
    // currentTransInfo.current = transInfo;
  }, [transInfo.formMachine]);

  useEffect(() => {
    if (transInfo.modifyUser === '0') {
      fetchFormerList();
    }
  }, [transInfo.modifyUser]);

  useEffect(() => {
    if (resetTransInfo) {
      // setCurrentTransInfo({});
      currentTransInfo.current = {};
      setResetTransInfo(false);
    }
  }, [resetTransInfo]);

  return (
    <PaperContainer className={commonClasses.container}>
      <Typography variant="h6">移轉單資訊</Typography>
      <Grid container className={commonClasses.content}>
        <Grid item xs={3} className={commonClasses.field_column}>
          <InputField
            id="transId"
            label="移轉單號"
            value={transId}
            onChange={handleInputChange}
            placeholder="請輸入移轉單號"
            type="text"
            name="transId"
            error={transIdErr}
            errormsg="請輸入正確的移轉單號"
            onBlur={handleTransBlur}
            onFocus={() => {}}
          />
        </Grid>
        {transInfo.transId && (
          <Grid item xs={3} className={commonClasses.field_column}>
            <ReadonlyField
              label="原始單號"
              name="sourceTransId"
              value={transInfo.transId}
              className={commonClasses.field_item}
            />
          </Grid>
        )}

        <Grid item xs={3} className={commonClasses.field_column}>
          <ReadonlyField
            label="客戶"
            name="customer"
            value={transInfo.customer}
            className={commonClasses.field_item}
          />
        </Grid>
        <Grid item xs={3} className={commonClasses.field_column}>
          {transInfo.formMachine !== '0' && (
            <ReadonlyField label="機台" name="formMachine" value={transInfo.formMachine} />
          )}
          {transInfo.formMachine === '0' && (
            <SelectField
              id="formMachineId"
              value={transInfo.formMachineId}
              selections={machineList}
              placeholder="請選擇機台"
              label="機台"
              handleChange={handleTransInfoChange}
              error={formMachineErr}
              errormsg="請選擇機台"
            />
          )}
        </Grid>
        <Grid item xs={3} className={commonClasses.field_column} />
        <Grid item xs={4.5} className={commonClasses.field_column_productInfo}>
          <ReadonlyField
            label="產品資訊"
            name="elName"
            value={transInfo.elName}
            className={commonClasses.field_item}
          />
        </Grid>
        <Grid item xs={3} className={commonClasses.field_column_button}>
          <div className={`${classes.button_group}`}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddRoundedIcon />}
              onClick={() => handleConfirmClick(true)}
              // style={showEditBtn ? {} : { marginLeft: 'auto' }}
            >
              新增
            </Button>

            {showEditBtn && (
              <div className={`${classes.button_edit}`}>
                <Button
                  variant="outlined"
                  color="grey"
                  startIcon={<EditRoundedIcon />}
                  customClasses={classes.edit_btn}
                  onClick={() => handleConfirmClick(false)}
                >
                  修改
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

TransInfo.propTypes = {
  transInfo: PropTypes.object.isRequired,
  setTransInfo: PropTypes.func.isRequired,
  transId: PropTypes.string.isRequired,
  setTransId: PropTypes.func.isRequired,
  handleTransBlur: PropTypes.func.isRequired,
  transIdErr: PropTypes.bool.isRequired,
  handleEditMeasure: PropTypes.func.isRequired,
  showEditBtn: PropTypes.bool.isRequired,
  resetTransInfo: PropTypes.bool.isRequired,
  setResetTransInfo: PropTypes.func.isRequired,
};

export default TransInfo;
