/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import useStyles from './useStyles';
import Typography from '../../../../components/Typography/Typography';
import Button from '../../../../components/Button/Button';

const SPCListDataRow = ({ rowData, handleRowClick }) => {
  const {
    material,
    productName,
    firstProdDate,
    lastProdDate,
    specItem,
    measureFrequency,
    ca,
    cp,
    cpk,
    grade,
    measureId,
  } = rowData;
  const { classes } = useStyles();

  return (
    <div className={classes.table_row}>
      <div className={classes.table_column}>
        <Typography variant="body2">{material}</Typography>
      </div>
      <div className={classes.table_column}>
        <Typography variant="body2">{productName}</Typography>
      </div>
      <div className={classes.table_column}>
        <Typography variant="body2">{format(new Date(firstProdDate), 'yyyy-MM-dd')}</Typography>
      </div>
      <div className={classes.table_column}>
        <Typography variant="body2">{format(new Date(lastProdDate), 'yyyy-MM-dd')}</Typography>
      </div>
      <div className={classes.table_column}>
        <Typography variant="body2">{specItem}</Typography>
      </div>
      <div className={classes.table_column}>
        <Typography variant="body2">{measureFrequency}</Typography>
      </div>
      <div className={classes.table_column}>
        <Typography variant="body2">{ca}</Typography>
      </div>
      <div className={classes.table_column}>
        <Typography variant="body2">{cp}</Typography>
      </div>
      <div className={classes.table_column}>
        <Typography variant="body2">{cpk}</Typography>
      </div>
      <div className={classes.table_column}>
        <Typography variant="body2">{grade}</Typography>
      </div>
      <div className={`${classes.table_column} ${classes.table_row_action}`}>
        <Button
          variant="outlined"
          color="primary"
          rounded
          customClasses={classes.table_row_action_btn}
          onClick={() => handleRowClick(measureId, rowData)}
        >
          <SearchRoundedIcon />
        </Button>
      </div>
    </div>
  );
};

SPCListDataRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleRowClick: PropTypes.func.isRequired,
};

export default SPCListDataRow;
