/* eslint-disable react/prop-types */
import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import styleConst from '../../constants/style';
import NoPrivilege from '../../pages/NoPrivilege';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const { DRAWER_WIDTH, DRAWER_WIDTH_MD, DRAWER_CLOSE } = styleConst;
const useStyles = makeStyles()((theme) => ({
  containerOpen: {
    width: `calc(100vw - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    [theme.breakpoints.up('xl')]: {
      width: `calc(100vw - ${DRAWER_WIDTH * 2}px)`,
    },
  },
  containerClose: {
    width: `calc(100vw - ${DRAWER_CLOSE}px)`,
    marginLeft: `${DRAWER_CLOSE}px`,
    [theme.breakpoints.up('xl')]: {
      width: `calc(100vw - ${DRAWER_CLOSE * 2}px)`,
    },
  },
  container: {
    marginTop: 70,
    padding: '25px 40px',
    [theme.breakpoints.up('xl')]: {
      margin: '70px auto 0',
    },
    [theme.breakpoints.down('xl')]: {
      maxWidth: 1280,
    },
    [theme.breakpoints.down('lg')]: {
      width: `calc(100vw - ${DRAWER_WIDTH_MD}px)`,
      marginLeft: DRAWER_WIDTH_MD,
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      marginLeft: 0,
      paddingLeft: 24,
      paddingRight: 24,
      marginTop: 60,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  fullWidth: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100vw',
  },
}));

const Main = ({ isLogin, hasPrivilege, menuOpen }) => {
  const { classes } = useStyles();
  return (
    <>
      <main
        className={`${classes.container} ${
          menuOpen ? classes.containerOpen : classes.containerClose
        } ${!isLogin ? classes.fullWidth : ''}`}
      >
        {isLogin && <Breadcrumbs />}
        {hasPrivilege ? <Outlet /> : <NoPrivilege />}
      </main>
    </>
  );
};

export default Main;
