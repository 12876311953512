/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import useStyles from '../useStyles';
import SettingsConfigProductRecipeTable from './SettingsConfigProductRecipeTable';
import SettingsConfigSearchByElNo from './SettingsConfigSearchByElNo';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useAxios from '../../../hooks/useAxios';
import { manufactureType, measureStyleType } from '../../../constants/enums';
import useCancelToken from '../../../hooks/useCancelToken';
import Typography from '../../../components/Typography/Typography';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import Snackbar from '../../../components/Snackbar/Snackbar';
import useFormingType from '../../../hooks/useFormingType';

const settingsConfigTableHeadCells = [
  { id: 'styleName', numeric: false, disablePadding: true, label: '量測型態' },
  {
    id: 'measureRecipe',
    numeric: false,
    disablePadding: true,
    label: '量測Recipe',
  },
  {
    id: 'measureItem',
    numeric: false,
    disablePadding: true,
    label: '量測項目',
  },
  {
    id: 'measureTarget',
    numeric: false,
    disablePadding: true,
    label: 'Target',
  },
  {
    id: 'measureUpperSpec',
    numeric: false,
    disablePadding: true,
    label: 'Upper Spec',
  },
  {
    id: 'measureLowerSpec',
    numeric: false,
    disablePadding: true,
    label: 'Lower Spec',
  },
  {
    id: 'measureUpperLimit',
    numeric: false,
    disablePadding: true,
    label: 'Upper Limit',
  },
  {
    id: 'measureLowerLimit',
    numeric: false,
    disablePadding: true,
    label: 'Lower Limit',
  },
  {
    id: 'measureDeviation',
    numeric: false,
    disablePadding: true,
    label: '量測偏差',
  },
];

const SettingsConfigProductType = () => {
  const { classes } = useStyles();

  const [selectedFormingType, setSelectedFormingType] = useState(null);

  const [elNoGet, setElNo] = useState('');
  const [elNoInput, setElNoInput] = useState('');
  const [elShapeGet, setElShape] = useState('');
  const [elSizeGet, setElSize] = useState('');
  const [elMaterial, setElMaterial] = useState('');
  const [prodWeight, setProdWeight] = useState('');

  const [sc, setSc] = useState(null);
  const [styleList, setStyleList] = useState([]);
  const [detectList, setDetectList] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [addAllList, setAddAllList] = useState([]);
  const detectListRef = useRef([]);

  const [isOpenTable, setIsOpenTable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isAddingAll, setIsAddingAll] = useState(false);

  const [elNoErr, setElNoErr] = useState(false);
  const [elNoErrMsg, setElNoErrMsg] = useState('');
  const [elPlan, setElPlan] = useState({});
  const [defaultElCustomer, setDefaultElCustomer] = useState({});
  const [elCustomer, setElCustomer] = useState({});
  const [elCustomerList, setCustomerList] = useState([]);
  const [statusMsg, setStatusMsg] = useState('');
  const [statusType, setStatusType] = useState('success');
  const [showStatus, setShowStatus] = useState(false);
  const addAllEmptyRow = styleList.map((style, index) => ({
    rowId: index,
    styleId: style.styleId,
    measureRecipe: '',
    measureItem: '',
    measureTarget: '',
    measureLimit: '',
    measureUpperSpec: '',
    measureLowerSpec: '',
    measureDeviation: '',
    styleName: style.styleName,
    isOld: true,
  }));
  const snackbarTimer = useRef(null);
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();

  const resetData = () => {
    setElNo('');
    setSelectedFormingType(null);
    setElShape('');
    setElSize('');
    setElMaterial('');
    setDefaultElCustomer({});
    setCustomerList([]);
    setElCustomer({});
    setElPlan({});
    setIsOpenTable(false);
  };

  const handleOpenAddingAll = () => {
    setIsAddingAll(true);
    setIsOpenTable(true);
  }; // handleOpenAddingAll

  const switchListLengthAndBtnShow = (list) => {
    switch (list.length) {
      case 1:
        setIsAddingAll(false);
        Object.assign(list[0], { isAddBtnShow: true, isDeleteBtnShow: false });
        break;
      case 0:
        break;
      default:
        setIsAddingAll(false);
        Object.assign(list.pop(), {
          isAddBtnShow: true,
          isDeleteBtnShow: true,
        });
        list.forEach((item) => Object.assign(item, { isAddBtnShow: false, isDeleteBtnShow: true }));
        break;
    }
  }; // switchListLengthAndBtnShow

  const handleIsAddDeleteBtnShow = (oriList, elNo = elNoGet, isDefault) => {
    if (isDefault === true && elNo !== '') {
      handleOpenAddingAll();
    } else {
      const styleSIZEList = oriList.filter((item) => item.styleId === measureStyleType.SIZE);
      const styleCOILList = oriList.filter((item) => item.styleId === measureStyleType.COIL);
      const styleDBList = oriList.filter((item) => item.styleId === measureStyleType.DB) || [];
      const styleLList = oriList.filter((item) => item.styleId === measureStyleType.L) || [];
      const styleNList = oriList.filter((item) => item.styleId === measureStyleType.N) || [];
      const styleZList = oriList.filter((item) => item.styleId === measureStyleType.Z) || [];

      switchListLengthAndBtnShow(styleSIZEList);
      switchListLengthAndBtnShow(styleCOILList);
      switchListLengthAndBtnShow(styleDBList);
      switchListLengthAndBtnShow(styleLList);
      switchListLengthAndBtnShow(styleNList);
      switchListLengthAndBtnShow(styleZList);
    }
  }; // handleIsAddDeleteBtnShow

  const handleAddAllEmptyList = (list) =>
    list.map((item, index) => ({
      ...item,
      rowId: index,
      styleId: styleList[index].styleId,
      styleName: styleList[index].styleName,
    })); // handleAddAllEmptyList

  const getAddAllList = (styleL) => {
    const addAllEmptyList = styleL.map(() => addAllEmptyRow);
    setAddAllList(handleAddAllEmptyList(addAllEmptyList));
    setAddAllList(addAllEmptyRow);
  }; // getAddAllList
  const openSnackbar = () => {
    setShowStatus(true);
    clearTimeout(snackbarTimer.current);
    snackbarTimer.current = setTimeout(() => {
      setShowStatus(false);
      clearTimeout(snackbarTimer.current);
    }, 3000);
  }; // openSnackbar

  const getCustomerList = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/customerDataSorted',
      {},
      true,
      cancelToken
    );
    if (status) {
      setCustomerList(
        result.customer.map((item) => ({
          id: item.customerId,
          value: item.customerId,
          name: item.customerAlias
            ? `${item.customerAlias} | ${item.customerName}`
            : item.customerName,
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '無法取得客戶資訊',
          message: noPrivilegeMsg || result,
        },
      });
    }
  }; // getCustomerList

  const getProduct = async (elNoInputed) => {
    const data = { elNo: elNoInputed };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getProdDetect',
      data,
      true,
      cancelToken
    );
    if (status) {
      const {
        sc: {
          elNo,
          elName1,
          elSize,
          prodName,
          prodShade,
          boQty,
          materialName,
          isRStick,
          planHours,
          planQuantity,
          planHoursQuantity,
          customerAlias,
          customerId,
          customerName,
          manufactureType: formingManufactureType,
        },
        isDefault,
        style,
        detect,
      } = result;
      setSelectedFormingType(
        !formingManufactureType ? null : useFormingType(formingManufactureType).value
      );
      setSc({
        elNo,
        elName1: elName1 || '',
        elSize: elSize || '',
        prodName: prodName || '',
        prodShade: prodShade || '',
        materialName: materialName || '',
        prodWeight: boQty || '-',
        isRStick,
        manufactureType: formingManufactureType,
      });
      setElNo(elNo);
      setElShape(prodShade);
      setElSize(elSize);
      setElMaterial(materialName);
      setProdWeight(boQty || '-');
      setElPlan({
        planHours: planHours || '',
        planQuantity: planQuantity || '',
        planHoursQuantity: planHoursQuantity || '',
      });
      setDefaultElCustomer({
        customerAlias: `${customerAlias} | ${customerName}` || '',
        customerId: customerId || '',
        customerName: customerName || '',
      });
      setStyleList(
        style.map((item) => ({
          styleId: item.styleId,
          styleName: item.styleName,
        }))
      );
      if (customerId) {
        setElCustomer((prev) => ({
          ...prev,
          customerAlias: `${customerAlias} | ${customerName}` || '',
          customerId: customerId || '',
        }));
      }
      getCustomerList();
      const oriDetect = detect.map((item) => ({
        measureId: item.measureId,
        elNo: item.elNo,
        styleId: item.styleId,
        measureRecipe: item.measureRecipe || '-',
        measureItem: item.measureItem || '-',
        measureTarget: item.measureTarget || '-',
        measureUpperLimit: item.measureUpperLimit || '-',
        measureLowerLimit: item.measureLowerLimit || '-',
        measureUpperSpec: item.measureUpperSpec || '-',
        measureLowerSpec: item.measureLowerSpec || '-',
        measureDeviation: item.measureDeviation || '-',
        styleName: item.styleName,
      }));
      const styleSIZEList = oriDetect
        .filter((item) => item.styleId === measureStyleType.SIZE)
        .sort((smallItem, largeItem) => smallItem.measureId - largeItem.measureId);
      const styleCOILList = oriDetect
        .filter((item) => item.styleId === measureStyleType.COIL)
        .sort((smallItem, largeItem) => smallItem.measureId - largeItem.measureId);
      const styleSTARList = oriDetect
        .filter((item) => item.styleId === measureStyleType.STAR)
        .sort((smallItem, largeItem) => smallItem.measureId - largeItem.measureId);
      const styleDBList =
        oriDetect
          .filter((item) => item.styleId === measureStyleType.DB)
          .sort((smallItem, largeItem) => smallItem.measureId - largeItem.measureId) || [];
      const styleLList =
        oriDetect
          .filter((item) => item.styleId === measureStyleType.L)
          .sort((smallItem, largeItem) => smallItem.measureId - largeItem.measureId) || [];
      const styleNList =
        oriDetect
          .filter((item) => item.styleId === measureStyleType.N)
          .sort((smallItem, largeItem) => smallItem.measureId - largeItem.measureId) || [];
      const styleZList =
        oriDetect
          .filter((item) => item.styleId === measureStyleType.Z)
          .sort((smallItem, largeItem) => smallItem.measureId - largeItem.measureId) || [];

      detectListRef.current = styleSIZEList
        .concat(styleCOILList)
        .concat(styleSTARList)
        .concat(styleDBList)
        .concat(styleLList)
        .concat(styleNList)
        .concat(styleZList);

      handleIsAddDeleteBtnShow(detectListRef.current, elNo, isDefault);
      setDetectList(detectListRef.current);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '無法取得產品量測相關設定資料',
          message: noPrivilegeMsg || result,
        },
      });
    }
    setIsLoading(false);
  }; // getProduct

  const handleElNoInputChange = (e) => {
    setElNoInput(e.target.value);
  }; // handleElNoInputChange

  const handleMaterialInputChange = (e) => {
    setElMaterial(e.target.value);
  }; // handleMaterialInputChange

  const handleSearch = () => {
    resetData();
    if (elNoInput.length === 0) {
      setElNoErr(true);
      setElNoErrMsg('請輸入料號');
    } else {
      setElNoErr(false);
      setElNoErrMsg('');
      getProduct(elNoInput);
      setIsOpenTable(true);
    }
  }; // handleSearch

  const modifyEditedData = (editedSingleData) => {
    let modifiedEditedData = '';
    switch (editedSingleData) {
      case '-':
        modifiedEditedData = '';
        break;
      default:
        modifiedEditedData = editedSingleData;
        break;
    }
    return modifiedEditedData;
  }; // modifyEditedData

  const onRowEditClick = (targetRowId, isOpenEdit) => {
    setDetectList((prev) =>
      prev.map((config) =>
        // eslint-disable-next-line no-nested-ternary
        config.measureId && config.measureId === targetRowId
          ? { ...config, isEditing: isOpenEdit }
          : config.styleId === targetRowId
          ? { ...config, isEditing: isOpenEdit }
          : config
      )
    ); // setDetectList
    if (isOpenEdit) {
      const targetRow = detectList.find((config) =>
        config.measureId ? config.measureId === targetRowId : config.styleId === targetRowId
      );
      setEditedData({
        measureId: modifyEditedData(targetRow.measureId),
        styleId: modifyEditedData(targetRow.styleId),
        measureRecipe: modifyEditedData(targetRow.measureRecipe),
        measureItem: modifyEditedData(targetRow.measureItem),
        measureTarget: modifyEditedData(targetRow.measureTarget),
        measureUpperSpec: modifyEditedData(targetRow.measureUpperSpec),
        measureLowerSpec: modifyEditedData(targetRow.measureLowerSpec),
        measureUpperLimit: modifyEditedData(targetRow.measureUpperLimit),
        measureLowerLimit: modifyEditedData(targetRow.measureLowerLimit),
        measureDeviation: modifyEditedData(targetRow.measureDeviation),
      });
    } else {
      setEditedData({});
    }
  }; // onRowEditClick

  const onRowAddClick = (targetRowId, isOpenAdd, isCanceled) => {
    setDetectList((prev) =>
      prev.map((config) =>
        config.measureId === targetRowId ? { ...config, isAdding: isOpenAdd } : config
      )
    ); // setDetectList
    if (isOpenAdd) {
      const targetRow = detectList.find((config) => config.measureId === targetRowId);
      setEditedData({
        styleId: targetRow.styleId,
        measureRecipe: modifyEditedData(targetRow.measureRecipe),
        measureItem: modifyEditedData(targetRow.measureItem),
        measureTarget: modifyEditedData(targetRow.measureTarget),
        measureUpperSpec: modifyEditedData(targetRow.measureUpperSpec),
        measureLowerSpec: modifyEditedData(targetRow.measureLowerSpec),
        measureUpperLimit: modifyEditedData(targetRow.measureUpperLimit),
        measureLowerLimit: modifyEditedData(targetRow.measureLowerLimit),
        measureDeviation: modifyEditedData(targetRow.measureDeviation),
      });
    } else {
      setEditedData({});
      if (isCanceled) {
        const oriDetectList = detectList.filter((item) => item.measureId !== targetRowId);
        setDetectList(oriDetectList);
        detectListRef.current = oriDetectList;
      }
    }
    handleIsAddDeleteBtnShow(detectListRef.current);
  }; // onRowAddClick

  const onAddingAllRowAddClick = (newAddAllList) => {
    setAddAllList(
      newAddAllList.map((item, index) => ({
        ...item,
        rowId: index,
      }))
    );
  }; // onAddingAllRowAddClick

  const handleRowEditing = (e) => {
    setEditedData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }; // handleRowEditing

  const handleAddAllRowEditing = (e, id) => {
    const targetKey = e.target.name;
    const newValue = e.target.value;
    const taretRow = addAllList[id];

    const newTargetRow = { ...taretRow, [targetKey]: newValue };

    const newAddAllList = [...addAllList];
    newAddAllList[id] = newTargetRow;
    setAddAllList(newAddAllList);
  }; // handleAddAllRowEditing

  const handleProdDetectSettings = async (data, sendType, shownText) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/prodDetectSetting',
      data,
      true,
      cancelToken
    );
    switch (sendType) {
      case 'update':
        if (status) {
          setStatusMsg('已成功更新產品相關設定！');
          setStatusType('success');
          getProduct(elNoInput);
          setSelectedFormingType(data.material.manufactureType);
        } else {
          setStatusType('error');
          setStatusMsg(
            noPrivilegeMsg || rtCode === 'C01'
              ? result
              : '更新產品相關設定失敗，請稍後再試，或請通知網管人員！'
          );
          setSelectedFormingType(sc.manufactureType);
        }
        break;
      case 'edit':
        if (status) {
          setStatusMsg('已成功更新產品量測資料設定！');
          setStatusType('success');
          getProduct(elNoInput);
        } else {
          setStatusType('error');
          setStatusMsg(noPrivilegeMsg || '更新產品量測資料設定失敗，請稍後再試，或通知網管人員！');
        }
        break;
      case 'delete':
        if (status) {
          getProduct(elNoInput);
          setStatusMsg(`已成功刪除 ${shownText} 資料`);
          setStatusType('success');
        } else {
          setStatusType('error');
          setStatusMsg(noPrivilegeMsg || `刪除 ${shownText} 資料失敗，請稍後再試！`);
        }
        break;
      case 'add':
        if (status) {
          setStatusMsg('已成功新增產品量測資料設定！');
          setStatusType('success');
          getProduct(elNoGet);
        } else {
          setStatusType('error');
          setStatusMsg(noPrivilegeMsg || '新增產品量測資料設定失敗，請稍後再試，或通知網管人員！');
        }
        break;
      case 'addAll':
        if (status) {
          setStatusMsg('已成功新增產品量測資料設定！');
          setStatusType('success');
          getProduct(elNoGet);
        } else {
          setStatusType('error');
          setStatusMsg(noPrivilegeMsg || '新增產品量測資料設定失敗，請稍後再試，或通知網管人員！');
        }
        break;
      default:
        break;
    }
  }; // handleProdDetectSettings

  const handleUpdateConfig = (formingType) => {
    const material = {
      elNo: elNoGet,
      materialName: elMaterial,
      manufactureTypeName: formingType || selectedFormingType,
    };
    setSelectedFormingType(formingType);
    if (defaultElCustomer.customerId && !elCustomer.customerId) {
      setStatusType('error');
      setStatusMsg('更新產品量測資料設定失敗，請填入正確的客戶！');
      openSnackbar();
      return;
    }
    if (!formingType && !selectedFormingType) {
      setStatusType('error');
      setStatusMsg('更新產品量測資料設定失敗，請填入正確的壓型加工類別！');
      openSnackbar();
      return;
    }
    const data = { material, productQuantity: elPlan, prodShade: sc.prodShade };
    data.customerId = elCustomer.customerId;
    handleProdDetectSettings(data, 'update', '');
    openSnackbar();
  };

  const handleChangeFormingType = (e) => {
    setSelectedFormingType(e.target.value);
  };

  const handleEditData = (row) => {
    const material = { elNo: elNoGet, materialName: elMaterial };
    const detect = [
      {
        ...editedData,
        elNo: elNoGet,
        styleId: row.styleId,
        isEnabled: 1,
      },
    ];
    const data = { material, detect, prodShade: sc.prodShade };

    handleProdDetectSettings(data, 'edit', '');
    openSnackbar();
    setEditedData({});
  }; // handleEditData

  const handleRowDelete = (productData) => {
    const { measureId, styleName } = productData;
    const detect = [{ measureId, elNo: elNoGet, isEnabled: 0 }];
    const data = { detect, prodShade: sc.prodShade };

    handleProdDetectSettings(data, 'delete', styleName);
    openSnackbar();
  }; // handleRowDelete

  const handleAddData = () => {
    const material = { elNo: elNoGet, materialName: elMaterial };
    const detect = [
      {
        ...editedData,
        elNo: elNoGet,
        isEnabled: 1,
      },
    ];
    const data = { material, detect, prodShade: sc.prodShade };

    handleProdDetectSettings(data, 'add', '');
    openSnackbar();
    setEditedData({});
  }; // handleAddData

  const handleAddAllData = () => {
    const inputedAddAllDataList = addAllList.map((row) => ({
      measureRecipe: row.measureRecipe.trim(),
      measureItem: row.measureItem.trim(),
      measureTarget: row.measureTarget.trim(),
      measureLimit: row.measureLimit.trim(),
      measureUpperSpec: row.measureUpperSpec.trim(),
      measureLowerSpec: row.measureLowerSpec.trim(),
      measureDeviation: row.measureDeviation.trim(),
      measureStyle: row.styleName.trim(),
    }));
    const isPassList = inputedAddAllDataList.filter(
      (row) => Object.values(row).filter((item) => item === '').length !== 7
    );
    if (sc.isRStick === 1 && (isPassList.length < addAllList.length || addAllList.length === 0)) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '請填寫完整',
          message: '各欄需至少填寫一項設定',
        },
      });
    } else {
      const material = { elNo: elNoGet, materialName: elMaterial };
      const detect = addAllList.map((item) => ({
        ...item,
        elNo: elNoGet,
        isEnabled: 1,
      }));
      const data = { material, detect, prodShade: sc.prodShade };

      handleProdDetectSettings(data, 'addAll', '');
      openSnackbar();
      setEditedData({});
      setAddAllList([]);
      setIsAddingAll(false);
    }
  }; // handleAddAllData

  const handleElPlanChange = (e) => {
    if (e.target.name === 'planHours') {
      setElPlan((prev) => ({
        ...prev,
        planHours: e.target.value,
        planHoursQuantity:
          e.target.value !== '' && prev.planQuantity !== ''
            ? (prev.planQuantity / e.target.value).toFixed(0)
            : '',
      }));
    }
    if (e.target.name === 'planQuantity') {
      setElPlan((prev) => ({
        ...prev,
        planQuantity: e.target.value,
        planHoursQuantity:
          e.target.value !== '' && prev.planHours !== ''
            ? (e.target.value / prev.planHours).toFixed(0)
            : '',
      }));
    }
  };

  const handleElCustomerChange = (data) => {
    if (!data) {
      setElCustomer({});
      return;
    }
    const { id, name } = data;
    setElCustomer({
      customerId: id,
      customerAlias: name,
    });
  };

  useEffect(() => {
    getAddAllList(styleList);
  }, [elNoGet, styleList]);

  useEffect(() => {
    const hasEditingRow = detectList.find((item) => item.isEditing);
    const hasAddingRow = detectList.find((item) => item.isAdding);
    if (hasEditingRow) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
    if (hasAddingRow) {
      setIsAdding(true);
    } else {
      setIsAdding(false);
    }
  }, [detectList]);

  useEffect(
    () => () => {
      clearTimeout(snackbarTimer.current);
    },
    []
  );

  return (
    <div>
      <SettingsConfigSearchByElNo
        elNo={elNoGet}
        elNoInput={elNoInput}
        elShape={elShapeGet}
        elSize={elSizeGet}
        elMaterial={elMaterial}
        prodWeight={prodWeight}
        handleElNoInputChange={handleElNoInputChange}
        handleMaterialInputChange={handleMaterialInputChange}
        handleSearch={handleSearch}
        handleUpdateConfig={handleUpdateConfig}
        toShowUpdateBtn={detectList.length > 0}
        elNoErr={elNoErr}
        elNoErrMsg={elNoErrMsg}
        elPlan={elPlan}
        selectElCustomer={elCustomer}
        elCustomerList={elCustomerList}
        handleElPlanChange={handleElPlanChange}
        handleElCustomerChange={handleElCustomerChange}
        selectedFormingType={selectedFormingType}
        handleChangeFormingType={handleChangeFormingType}
      />
      {isOpenTable && (
        <PaperContainer className={classes.container}>
          <Typography variant="h6" style={{ marginBottom: 24 }}>
            Recipe / Spec
          </Typography>
          <Snackbar
            msg={statusMsg}
            type={statusType}
            open={showStatus}
            className={classes.snackbar}
          />
          <SettingsConfigProductRecipeTable
            headCells={settingsConfigTableHeadCells}
            tableContentList={detectList}
            addAllList={addAllList}
            onRowEditClick={onRowEditClick}
            onRowAddClick={onRowAddClick}
            onAddingAllRowAddClick={onAddingAllRowAddClick}
            handleRowEditing={handleRowEditing}
            handleAddAllRowEditing={handleAddAllRowEditing}
            editedData={editedData}
            onRowDelete={handleRowDelete}
            handleEditData={handleEditData}
            handleAddData={handleAddData}
            handleAddAllData={handleAddAllData}
            isLoading={isLoading}
            isEditing={isEditing}
            isAdding={isAdding}
            isAddingAll={isAddingAll}
          />
        </PaperContainer>
      )}
    </div>
  );
};

export default SettingsConfigProductType;
