import axios from 'axios';
import { base64Decode } from '../Utils/stringUtil';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_PREFIX,
  timeout: 1000 * 60 * 3,
  headers: {
    'Content-Type': 'text/plain',
  },
});

const useVersionAxios = async (api, data) => {
  console.log('request: ', {
    api,
    req: {
      data,
    },
  });
  try {
    const res = await instance.get(api, data);
    console.log('raw response: ', {
      status: res.status,
      data: res.data,
    });
    if (res.status !== 200) {
      return [false, 'Something went wrong...'];
    }
    const { rtCode, rtData, rtMsg } = res.data;
    if (rtCode === 'E52') {
      const url = window.location.href;
      const pathname = url.split('#')[1];
      localStorage.clear();
      localStorage.setItem('targetPath', pathname);
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#/login`;
      window.location.reload();
      return [false, rtMsg, rtCode];
    }
    if (rtCode !== 'S00') {
      return [false, rtMsg, rtCode];
    }
    const result = JSON.parse(base64Decode(rtData));
    console.log('parsed response: ', {
      rtCode,
      rtMsg,
      rtData: result,
    });
    return [true, result];
  } catch (err) {
    // 未經授權返回登入頁
    if (err.toString().includes('401')) {
      const url = window.location.href;
      const pathname = url.split('#')[1];
      localStorage.clear();
      localStorage.setItem('targetPath', pathname);
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#/login`;
      window.location.reload();
    }
    if (axios.isCancel(err)) {
      console.log(`${api} api call is cancelled`);
    }
    return [false, '網路連線異常'];
  }
};

export default useVersionAxios;
