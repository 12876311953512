/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from '../useStyles';
import Typography from '../../../../components/Typography/Typography';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import SizeDetail from './SizeDetail';
import ElectricalDetail from './ElectricalDetail';

const targetPageType = {
  SIZE: 1,
  ELECTRICAL: 2,
};

const SinteringDetail = ({ renderData, specData, isLoading }) => {
  const { sinteringSize, sinteringElectrical } = renderData;
  const {
    sinteringSize: sizeSpecConfig,
    sinteringElectrical: electricalSpecConfig,
    isRStick,
  } = specData;
  const [targetPage, setTargetPage] = useState(targetPageType.SIZE);
  const { classes } = useStyles();

  return (
    <div className={classes.detail_table}>
      <PaperContainer className={classes.detail_nav}>
        <Typography
          variant="body1"
          className={`${classes.detail_nav_item} ${
            targetPage === targetPageType.SIZE ? 'active' : ''
          }`}
          onClick={() => setTargetPage(targetPageType.SIZE)}
        >
          尺寸
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.detail_nav_item} ${
            targetPage === targetPageType.ELECTRICAL ? 'active' : ''
          }`}
          onClick={() => setTargetPage(targetPageType.ELECTRICAL)}
        >
          電性
        </Typography>
      </PaperContainer>
      {targetPage === targetPageType.SIZE && (
        <SizeDetail
          sinteringSize={sinteringSize}
          sizeSpecConfig={sizeSpecConfig}
          isLoading={isLoading}
        />
      )}
      {targetPage === targetPageType.ELECTRICAL && electricalSpecConfig && sinteringElectrical && (
        <ElectricalDetail
          sinteringElectrical={sinteringElectrical}
          electricalSpecConfig={electricalSpecConfig}
          isRStick={isRStick}
        />
      )}
    </div>
  );
};

SinteringDetail.propTypes = {
  renderData: PropTypes.object.isRequired,
  specData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SinteringDetail;
