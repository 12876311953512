/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef } from 'react';
import { subDays, format, isWithinInterval, addMonths, compareDesc } from 'date-fns';
import useCommonStyles from '../../useStyles';
import useStyles from './useStyles';
import useForm from '../../../../hooks/useForm';
import DefectLineChart from './DefectLineChart';
import FinishedFilter from './FinishedFilter';
import UnfinishedFilter from './UnfinishedFilter';
import { reportFinishedFilterInterval } from '../../../../constants/enums';
import UnfinishedDataChart from './UnfinishedDataChart';
import useAxios from '../../../../hooks/useAxios';
import { DialogContext } from '../../../../contexts/DialogContext';
import { dialogActions } from '../../../../reducers/dialogReducer';
import FinishedChartDialog from './FinishedChartDialog';
import useCancelToken from '../../../../hooks/useCancelToken';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Divider from '../../../../components/Divider/Divider';

const DefectDashboard = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const [finishedFilter, setFinishedFilter] = useState({
    interval: reportFinishedFilterInterval.WEEKLY,
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
    month: new Date(),
    year: new Date(),
    quarter: 1,
    semi: 1,
  });
  const initialUnfinishedFilter = {
    startDate: new Date(),
    endDate: new Date(),
  };
  const {
    formData: unfinishedFilter,
    setFormData: setUnfinishedFilter,
    formErr: unfinishedFilterErr,
    setFormErr: setUnfinishedFilterErr,
    resetFormData: resetUnfinishedFilter,
    resetFormErr: resetUnfinishedFilterErr,
  } = useForm(initialUnfinishedFilter);
  const [unfinishedDateErrMsg, setUnfinishedDateErrMsg] = useState('');
  const [showUnfinishedResult, setShowUnfinishedResult] = useState(false);
  const [toUpdatePareto, setToUpdatePareto] = useState(false);
  const [toRenderPareto, setToRenderPareto] = useState(false);
  const [emptyParetoResult, setEmptyParetoResult] = useState('');
  const [showFinishedChartDialog, setShowFinishedChartDialog] = useState(false);
  const [emptyProductResult, setEmptyProductResult] = useState('');
  const [emptyMachineResult, setEmptyMachineResult] = useState('');
  const [emptyTestResult, setEmptyTestResult] = useState('');
  const [toUpdateProdChart, setToUpdateProdChart] = useState(false);
  const [toRenderProdChart, setToRenderProdChart] = useState(false);
  const [toUpdateMachineChart, setToUpdateMachineChart] = useState(false);
  const [toRenderMachineChart, setToRenderMachineChart] = useState(false);
  const [toUpdateTestChart, setToUpdateTestChart] = useState(false);
  const [toRenderTestChart, setToRenderTestChart] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const initialAdvanceData = {
    scOdnoArray: [{ name: '', value: '' }],
    transferArray: [{ name: '', value: '' }],
    prodNameArray: [{ name: '', value: '' }],
  };
  const [advanceData, setAdvanceData] = useState(initialAdvanceData);
  const initialAdvancedSelectionValue = {
    scOdno: '',
    transId: '',
    prodName: '',
  };

  const [advancedSelectionValue, setAdvanceSelectionValue] = useState(
    initialAdvancedSelectionValue
  );
  const [advancedSelectionValueError, setAdvanceSelectionValueError] = useState(false);
  const paretoData = useRef([]);
  const currentDefectType = useRef('');
  const currentFinishedFilterReq = useRef({});
  const currentUnfinishedFilterReq = useRef(null);
  const unfinishedProdData = useRef([]);
  const unfinishedMachineData = useRef([]);
  const unfinishedTestData = useRef([]);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();

  const formatSearchField = () => {
    const data = {};
    const { interval, startDate, month, year, quarter, semi } = finishedFilter;
    if (interval === reportFinishedFilterInterval.WEEKLY) {
      data.week = format(new Date(startDate), 'yyyy-MM-dd');
    }
    if (interval === reportFinishedFilterInterval.MONTHLY) {
      data.year = month.getFullYear();
      data.month = month.getMonth() + 1;
    }
    if (interval === reportFinishedFilterInterval.QUARTERLY) {
      data.year = year.getFullYear();
      data.season = quarter;
    }
    if (interval === reportFinishedFilterInterval.SEMI_ANNUALLY) {
      data.year = year.getFullYear();
      data.half = semi;
    }
    if (interval === reportFinishedFilterInterval.ANNUALLY) {
      data.year = year.getFullYear();
    }
    return data;
  };
  const validationFinishedAdvancedFilter = () => {
    if (
      advancedSelectionValue.scOdno === '' &&
      advancedSelectionValue.transId === '' &&
      advancedSelectionValue.prodName === ''
    ) {
      setAdvanceSelectionValueError(true);
      return false;
    }
    return true;
  };

  const handleFetchFinishedData = async () => {
    const data = formatSearchField();
    if (showAdvanced === false) {
      data.isAdvanced = 0;
    } else {
      data.isAdvanced = 1;
    }
    if (showAdvanced === true) {
      if (!validationFinishedAdvancedFilter()) return;
      data.scOdno = advancedSelectionValue.scOdno;
      data.transId = advancedSelectionValue.transId;
      data.prodName = advancedSelectionValue.prodName;
    }
    currentFinishedFilterReq.current = data;
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkBadList',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { bad } = result;
      if (bad.length === 0) {
        paretoData.current = bad;
        setEmptyParetoResult('目前所選的區間沒有相應的資料，請重新選擇分析區間！');
        setToRenderPareto(true);
        return;
      }
      setEmptyParetoResult('');
      if (paretoData.current.length > 0 && bad.length > 0) {
        setToUpdatePareto(true);
      }
      paretoData.current = bad;
      setToRenderPareto(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得所選區間資料',
          message:
            noPrivilegeMsg || `${result} 目前無法取得所選區間資料，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };
  const getAdvanceSelections = async () => {
    const data = formatSearchField();
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkBadListAdvancedItem',
      data,
      true,
      cancelToken
    );
    if (status) {
      setShowAdvanced(true);
      const { prodNameArray, scOdnoArray, transferArray } = result;

      setAdvanceData({
        prodNameArray: prodNameArray.map((item) => ({ name: item, value: item })),
        scOdnoArray: scOdnoArray.map((item) => ({ name: item, value: item })),
        transferArray: transferArray.map((item) => ({ name: item, value: item })),
      });
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得進階搜尋選項',
          message:
            noPrivilegeMsg || `${result} 目前無法取得進階搜尋選項，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };
  const isWithinOneYear = (start, end) => {
    const maxEndDate = addMonths(start, 12);
    return isWithinInterval(end, { start, end: maxEndDate });
  };

  const validationUnfinishedFilter = () => {
    const { startDate, endDate } = unfinishedFilter;
    if (compareDesc(startDate, endDate) < 0) {
      setUnfinishedFilterErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      setUnfinishedDateErrMsg('結束日期不得早於起始日期！');
      return false;
    }
    if (!isWithinOneYear(startDate, endDate)) {
      setUnfinishedDateErrMsg('所選區間不得大於一年！');
      setUnfinishedFilterErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      return false;
    }
    return true;
  };

  const fetchUnfinishedProdData = async (data) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkHalfProd',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { half } = result;
      if (half.length === 0) {
        unfinishedProdData.current = half;
        setEmptyProductResult('目前所選區間沒有相應的資料，請重新選擇分析區間');
        setToRenderProdChart(true);
        return;
      }
      setEmptyProductResult('');
      if (unfinishedProdData.current.length > 0 && half.length > 0) {
        unfinishedProdData.current = half;
        setToUpdateProdChart(true);
        return;
      }
      unfinishedProdData.current = half;
      setToRenderProdChart(true);
    } else {
      setShowUnfinishedResult(false);
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得個別產品相關資料',
          message:
            noPrivilegeMsg ||
            `${result}：目前暫時無法取得個別產品相關資料，請稍後再試，或請通知網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const fetchUnfinishedMachineData = async (data) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkHalfMachine',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { half } = result;
      if (half.length === 0) {
        unfinishedMachineData.current = half;
        setEmptyMachineResult('目前所選區間沒有相應的資料，請重新選擇分析區間');
        setToRenderMachineChart(true);
        return;
      }
      setEmptyMachineResult('');
      setEmptyTestResult('');
      if (unfinishedMachineData.current.length > 0 && half.length > 0) {
        unfinishedMachineData.current = half;
        setToUpdateMachineChart(true);
        return;
      }
      unfinishedMachineData.current = half;
      setToRenderMachineChart(true);
    } else {
      setShowUnfinishedResult(false);
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得個別機台相關資料',
          message:
            noPrivilegeMsg ||
            `${result}：目前暫時無法取得個別機台相關資料，請稍後再試，或請通知網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const fetchUnfinishedTestData = async (data) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkCountMachineTime',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { machineSize } = result;
      if (machineSize.length === 0) {
        unfinishedTestData.current = machineSize;
        setEmptyTestResult('目前所選區間沒有相應的資料，請重新選擇分析區間');
        setToRenderTestChart(true);
        return;
      }
      setEmptyTestResult('');
      if (unfinishedTestData.current.length > 0 && machineSize.length > 0) {
        unfinishedTestData.current = machineSize;
        setToUpdateTestChart(true);
        return;
      }
      unfinishedTestData.current = machineSize;
      setToRenderTestChart(true);
    } else {
      setShowUnfinishedResult(false);
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得個別機台相關資料',
          message:
            noPrivilegeMsg ||
            `${result}：目前暫時無法取得個別機台相關資料，請稍後再試，或請通知網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleFetchUnfinishedData = () => {
    if (!validationUnfinishedFilter()) return;
    const { startDate, endDate } = unfinishedFilter;
    const data = {
      checkBegin: format(startDate, 'yyyy-MM-dd'),
      checkEnd: format(endDate, 'yyyy-MM-dd'),
    };
    currentUnfinishedFilterReq.current = data;
    setShowUnfinishedResult(true);
    fetchUnfinishedProdData(data);
    fetchUnfinishedMachineData(data);
    fetchUnfinishedTestData(data);
  };

  const handleChartDialogClose = () => {
    setShowFinishedChartDialog(false);
  };

  const handleChartDialogOpen = () => {
    setShowFinishedChartDialog(true);
  };

  const setCurrentDefectType = (type) => {
    currentDefectType.current = type;
  };

  const handleSelectionChange = (event, value) => {
    const valueName = event.target.id.split('-')[0];

    setAdvanceSelectionValue((prev) => ({
      ...prev,
      [valueName]: value,
    }));
  };

  const resetAdvanceFilter = () => {
    setAdvanceData(initialAdvanceData);
    setAdvanceSelectionValue(initialAdvancedSelectionValue);
    setShowAdvanced(false);
    setEmptyParetoResult('');
    paretoData.current = [];
    setToRenderPareto(true);
    setAdvanceSelectionValueError(false);
  };

  return (
    <div className={commonClasses.container}>
      <div className={commonClasses.content} style={{ marginTop: 30 }}>
        <Typography variant="h5">成品</Typography>
        <Grid container className={classes.content_container} spacing={2} alignItems="stretch">
          <Grid item xs={8} sm={9}>
            <DefectLineChart
              paretoData={paretoData.current}
              toUpdate={toUpdatePareto}
              setToUpdate={setToUpdatePareto}
              toRender={toRenderPareto}
              setToRender={setToRenderPareto}
              emptyResult={emptyParetoResult}
              handleChartDialogOpen={handleChartDialogOpen}
              setCurrentDefectType={setCurrentDefectType}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <FinishedFilter
              finishedFilter={finishedFilter}
              setFinishedFilter={setFinishedFilter}
              handleFetchFinishedData={handleFetchFinishedData}
              advanceData={advanceData}
              getAdvanceSelections={getAdvanceSelections}
              showAdvanced={showAdvanced}
              setShowAdvanced={setShowAdvanced}
              advancedSelectionValue={advancedSelectionValue}
              handleSelectionChange={handleSelectionChange}
              resetAdvanceFilter={resetAdvanceFilter}
              advancedSelectionValueError={advancedSelectionValueError}
            />
          </Grid>
        </Grid>
      </div>
      <FinishedChartDialog
        open={showFinishedChartDialog}
        handleChartClose={handleChartDialogClose}
        currentDefectType={currentDefectType.current}
        currentFilterData={currentFinishedFilterReq.current}
      />
      <Divider
        className={commonClasses.dashed_divider}
        style={{ marginTop: 24, marginBottom: 16 }}
      />
      <div className={commonClasses.content} style={{ marginTop: 30 }}>
        <Typography variant="h5">半成品</Typography>
        <Grid container className={classes.content_container}>
          <UnfinishedFilter
            unfinishedFilter={unfinishedFilter}
            setUnfinishedFilter={setUnfinishedFilter}
            unfinishedFilterErr={unfinishedFilterErr}
            handleFetchUnfinishedData={handleFetchUnfinishedData}
            dateErrMsg={unfinishedDateErrMsg}
          />
          {showUnfinishedResult && (
            <UnfinishedDataChart
              currentFilterData={currentUnfinishedFilterReq.current}
              prodData={unfinishedProdData.current}
              machineData={unfinishedMachineData.current}
              testData={unfinishedTestData.current}
              toUpdateProd={toUpdateProdChart}
              toUpdateMachine={toUpdateMachineChart}
              toUpdateTest={toUpdateTestChart}
              setToUpdateProd={setToUpdateProdChart}
              setToUpdateMachine={setToUpdateMachineChart}
              setToUpdateTest={setToUpdateTestChart}
              toRenderProd={toRenderProdChart}
              toRenderMachine={toRenderMachineChart}
              toRenderTest={toRenderTestChart}
              setToRenderProd={setToRenderProdChart}
              setToRenderMachine={setToRenderMachineChart}
              setToRenderTest={setToRenderTestChart}
              emptyProdResult={emptyProductResult}
              emptyMachineResult={emptyMachineResult}
              emptyTestResult={emptyTestResult}
            />
          )}
        </Grid>
      </div>
    </div>
  );
};

export default DefectDashboard;
