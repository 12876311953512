/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import useStyles from '../useStyles';
import { calcTargetValue, isOutSpec } from '../../detailUtils';

const InspectionDetail = ({ renderRows, specConfig }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.detail_table}>
      <Grid container className={classes.detail_header_row}>
        <Grid item className={`${classes.detail_header_column} ${classes.size_defect_sm}`}>
          <Typography variant="subtitle1">層數</Typography>
        </Grid>
        <Grid item className={`${classes.detail_header_column} ${classes.size_defect_sm}`}>
          <Typography variant="subtitle1">位置</Typography>
        </Grid>
        {specConfig.map((spec) => (
          <div
            key={spec.specId}
            className={`${classes.size_header_column} ${classes.inspection_column} ${classes.detail_inspection_column}`}
          >
            <Grid item className={`${classes.inspection_column}`}>
              <Typography variant="subtitle1">{spec.specItem}</Typography>
              <div className={classes.size_target_group}>
                <Typography variant="body1" className={classes.size_target}>
                  {spec.specTarget}
                </Typography>
                <ul className={classes.size_spec_group}>
                  <li className={`${classes.size_spec} ${classes.size_spec_upper}`}>
                    +{parseFloat(spec.specUpper)}
                  </li>
                  <li className={`${classes.size_spec} ${classes.size_spec_lower}`}>
                    {parseFloat(spec.specUpper) > 0
                      ? `-${parseFloat(spec.specLower)}`
                      : spec.specLower}
                  </li>
                </ul>
              </div>
            </Grid>
            {spec.specBias && (
              <Grid item className={classes.size_bias}>
                量測偏差： {spec.specBias}
              </Grid>
            )}
          </div>
        ))}

        <Grid item className={`${classes.detail_header_column} ${classes.size_defect_sm}`}>
          <Typography variant="subtitle1">Defect Type</Typography>
        </Grid>
        <Grid item className={`${classes.detail_header_column} ${classes.size_defect_sm}`}>
          <Typography variant="subtitle1">量測人員</Typography>
        </Grid>
      </Grid>
      {renderRows.map((row) => (
        <Grid container className={classes.detail_row} key={row.detailId}>
          <Grid
            item
            className={`${row.sinterLevel !== '' && classes.detail_column} ${
              classes.size_defect_sm
            }`}
          >
            <Typography variant="body1">{row.sinterLevel}</Typography>
          </Grid>

          <Grid
            item
            className={`${row.rowId !== '' && classes.detail_column} ${classes.size_defect_sm}`}
          >
            <Typography variant="body1">{row.sinterPosition}</Typography>
          </Grid>
          {row.specItems &&
            row.specItems.map((spec) => {
              const targetSpec = specConfig.find((item) => item.specId === spec.specId);
              const [maxValue, minValue, upperLimit, lowerLimit] = calcTargetValue(targetSpec);
              const [outSpec, outLimit] = isOutSpec(
                spec.specValue,
                maxValue,
                minValue,
                upperLimit,
                lowerLimit
              );
              return (
                <Grid
                  item
                  className={`${classes.detail_column} ${classes.inspection_column} ${
                    classes.inspection_value_cell
                  } ${outSpec ? classes.out_spec : ''} ${outLimit ? classes.out_limit : ''}`}
                  key={spec.specId}
                >
                  <Typography variant="body1">{spec.specValue || '-'}</Typography>
                </Grid>
              );
            })}
          <Grid item className={`${classes.detail_column} ${classes.size_defect_sm}`}>
            <Typography variant="body1">
              {row.defectType.length !== 0
                ? row.defectType.map((type) => type.detectName).join(', ')
                : '-'}
            </Typography>
          </Grid>
          <Grid item className={`${classes.detail_column} ${classes.size_defect_sm}`}>
            <Typography variant="body1">{row.measureUser || '-'}</Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

InspectionDetail.propTypes = {
  renderRows: PropTypes.array.isRequired,
  specConfig: PropTypes.array.isRequired,
};

export default InspectionDetail;
