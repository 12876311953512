/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import InsertInvitationRoundedIcon from '@mui/icons-material/InsertInvitationRounded';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import indigo from '@mui/material/colors/indigo';
import InputField from '../InputField/InputField';

const useStyles = makeStyles()((theme) => ({
  date_picker_container: {
    display: 'flex',
    position: 'relative',
  },
  date_picker: {
    '&.MuiTextField-root': {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  },
  date_picker_icon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[600],
    cursor: 'pointer',
  },
  error_icon: {
    transform: `translateY(calc(-50% - ${theme.spacing(1)}))`,
  },
  error_opacity: {
    color: theme.palette.error.main,
  },
  disabled_opacity: {
    color: `${theme.palette.grey[400]} !important`,
    cursor: 'default !important',
  },
}));

const paperPropsSX = {
  '& .PrivatePickersMonth-root': {
    '&.Mui-selected': {
      color: indigo[500],
      backgroundColor: 'white !important',
      fontSize: 24,
      fontWeight: 500,
      '&:hover': {
        color: indigo[500],
        backgroundColor: 'transparent',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '& .PrivatePickersYear-root': {
    flexBasis: '100%',
  },
  '& .PrivatePickersYear-yearButton': {
    '&.Mui-selected': {
      color: indigo[500],
      backgroundColor: 'white !important',
      fontSize: 24,
      fontWeight: 500,
      '&:hover': {
        color: indigo[500],
        backgroundColor: 'transparent',
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

const DatePicker = ({
  id,
  value,
  onChange,
  hasDateErr,
  errMsg,
  format,
  maxDate,
  configs,
  className,
  containerClass,
  containerStyle,
  ...rest
}) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  // const datePickerEl = useRef(null);
  // useEffect(() => {
  //   datePickerEl.current = document.getElementById(id);
  //   console.log(datePickerEl.current, id);
  // }, []);

  const handleOpen = () => {
    if (rest.disabled) return;
    setOpen((state) => !state);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={`${classes.date_picker_container} ${containerClass}`} style={containerStyle}>
        <MuiDatePicker
          disableOpenPicker
          className={`${classes.date_picker} ${className}`}
          id={id}
          maxDate={maxDate || new Date()}
          inputFormat={format || 'yyyy-MM-dd'}
          value={value}
          onChange={onChange}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          {...rest}
          {...configs}
          renderInput={({ inputProps, className, inputRef }) => (
            <InputField
              {...rest}
              inputRef={inputRef}
              className={className}
              name={id}
              id={id}
              value={String(value)}
              onChange={onChange}
              onClick={() => setOpen((state) => !state)}
              error={hasDateErr}
              helperText={hasDateErr && errMsg}
              FormHelperTextProps={{
                classes: {
                  root: classes.error_opacity,
                },
              }}
              inputProps={{
                ...inputProps,
                readOnly: true,
              }}
            />
          )}
          PaperProps={{ sx: paperPropsSX }}
        />
        <span
          className={`${classes.date_picker_icon} ${hasDateErr ? classes.error_icon : ''} ${
            rest.disabled ? classes.disabled_opacity : ''
          }`}
          onClick={handleOpen}
        >
          <InsertInvitationRoundedIcon />
        </span>
      </div>
    </LocalizationProvider>
  );
};

DatePicker.defaultProps = {
  format: 'yyyy-MM-dd',
  configs: {},
  maxDate: new Date(),
  className: '',
  containerClass: '',
  containerStyle: {},
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  hasDateErr: PropTypes.bool.isRequired,
  errMsg: PropTypes.string.isRequired,
  maxDate: PropTypes.object,
  format: PropTypes.string,
  configs: PropTypes.object,
  className: PropTypes.string,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.object,
};

export default DatePicker;
