import {
  stationType,
  positionType,
  measureStyleType,
  qcExportMeasureStyle,
  reportFinishedFilterInterval,
} from './enums';

export const cuttingSelection = [
  {
    name: '1',
    value: '1',
  },
  {
    name: '2',
    value: '2',
  },
  {
    name: '3',
    value: '3',
  },
];

export const moldSelection = [
  {
    name: '模具1',
    value: '1',
  },
  {
    name: '模具2',
    value: '2',
  },
  {
    name: '模具3',
    value: '3',
  },
  {
    name: '模具4',
    value: '4',
  },
  {
    name: '模具5',
    value: '5',
  },
  {
    name: '模具6',
    value: '6',
  },
  {
    name: '模具7',
    value: '7',
  },
  {
    name: '模具8',
    value: '8',
  },
  {
    name: '模具9',
    value: '9',
  },
  {
    name: '模具10',
    value: '10',
  },
  {
    name: '模具11',
    value: '11',
  },
  {
    name: '模具12',
    value: '12',
  },
];

export const stationSelection = [
  {
    name: '所有站別',
    value: 0,
  },
  {
    name: '壓型',
    value: stationType.FORMING,
  },
  {
    name: '鑽孔',
    value: stationType.DRILLING,
  },
  {
    name: '排列',
    value: stationType.ALIGNING,
  },
  {
    name: '燒結',
    value: stationType.SINTERING,
  },
  {
    name: '研磨',
    value: stationType.GRINDING,
  },
  {
    name: '全檢',
    value: stationType.TESTING,
  },
  {
    name: '包裝',
    value: stationType.PACKAGING,
  },
];

export const positionSelection = [
  {
    name: '前',
    value: positionType.FRONT,
  },
  {
    name: '中',
    value: positionType.MIDDLE,
  },
  {
    name: '後',
    value: positionType.BACK,
  },
];

export const layerSelection = [
  {
    name: '1',
    value: 1,
  },
  {
    name: '2',
    value: 2,
  },
  {
    name: '3',
    value: 3,
  },
  {
    name: '4',
    value: 4,
  },
  {
    name: '5',
    value: 5,
  },
];

export const measureStyleSelection = [
  {
    name: 'DB',
    value: measureStyleType.DB,
  },
  {
    name: 'L',
    value: measureStyleType.L,
  },
  {
    name: 'N',
    value: measureStyleType.N,
  },
];

export const notRMeasureStyleSelection = [
  {
    name: 'Z',
    value: measureStyleType.Z,
  },
  {
    name: 'L',
    value: measureStyleType.L,
  },
];

export const qcExportMeasureStyleSelection = [
  {
    name: '壓型檢測',
    value: qcExportMeasureStyle.FORMING,
  },
  {
    name: '燒結巡檢',
    value: qcExportMeasureStyle.SINTERING_INSPECTION,
  },
  {
    name: '燒結尺寸／電性檢測',
    value: qcExportMeasureStyle.SIZE_ELECTRICAL,
  },
];

export const finishedFilterIntervalSelection = [
  {
    name: 'weekly',
    value: reportFinishedFilterInterval.WEEKLY,
  },
  {
    name: 'monthly',
    value: reportFinishedFilterInterval.MONTHLY,
  },
  {
    name: 'quarterly',
    value: reportFinishedFilterInterval.QUARTERLY,
  },
  {
    name: 'semi-annually',
    value: reportFinishedFilterInterval.SEMI_ANNUALLY,
  },
  {
    name: 'annually',
    value: reportFinishedFilterInterval.ANNUALLY,
  },
];

export const finishedFilterSeasonSelection = [
  {
    name: '第一季',
    value: 1,
  },
  {
    name: '第二季',
    value: 2,
  },
  {
    name: '第三季',
    value: 3,
  },
  {
    name: '第四季',
    value: 4,
  },
];

export const finishedFilterSemiSelection = [
  {
    name: '上半年',
    value: 1,
  },
  {
    name: '下半年',
    value: 2,
  },
];
