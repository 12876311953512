import { useState } from 'react';

const useForm = (initialForm) => {
  const [formData, setFormData] = useState({ ...initialForm });
  const [formErr, setFormErr] = useState({});
  const resetFormErr = () => {
    Object.keys(formData).forEach((key) =>
      setFormErr((prev) => ({
        ...prev,
        [key]: false,
      }))
    );
  };

  const resetFormData = () => {
    setFormData({ ...initialForm });
  };
  return { formData, setFormData, formErr, setFormErr, resetFormData, resetFormErr };
};

export default useForm;
