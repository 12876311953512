/* eslint-disable react/no-children-prop */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import MuiButton from '@mui/material/Button';
import useStyles from './useStyles';

const Button = ({ rounded, customClasses, color, variant, ...rest }) => {
  const { classes } = useStyles();
  const getColor = (COLOR) => {
    if (COLOR === 'primary') {
      return 'primary';
    }
    if (COLOR === 'secondary') {
      return 'secondary';
    }
    if (COLOR === 'error') {
      return 'error';
    }
    if (COLOR === 'warning') {
      return 'warning';
    }
    if (COLOR === 'info') {
      return 'info';
    }
    if (COLOR === 'success') {
      return 'success';
    }
    if (COLOR === 'grey') {
      return 'grey';
    }
    return 'primary';
  };
  const roundedClassName = rounded ? classes.rounded : '';
  const errorClass = getColor(color) === 'error' && variant !== 'text' ? classes.error : '';
  const greyClass = getColor(color) === 'grey' && variant !== 'text' ? classes.grey : '';
  return (
    <MuiButton
      {...rest}
      variant={variant}
      color={getColor(color)}
      className={`${roundedClassName} ${customClasses} ${errorClass} ${greyClass}`}
    />
  );
};

export default Button;
