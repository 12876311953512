/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { compareDesc, format, subDays } from 'date-fns';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import useCommonStyles from '../../useStyles';
import useStyles from '../useStyles';
import DeliveryDataRow from './DeliveryDataRow';
import YieldRateChart from '../YieldRateChart';
import TransListDialog from '../TransListDialog';
import useAxios from '../../../../hooks/useAxios';
import useCancelToken from '../../../../hooks/useCancelToken';
import useForm from '../../../../hooks/useForm';
import { DialogContext } from '../../../../contexts/DialogContext';
import { dialogActions } from '../../../../reducers/dialogReducer';
import { parseDecimalToFixedString } from '../../../../Utils/stringUtil';
import { reportScheduleType } from '../../../../constants/enums';
import FilterInfo from '../FilterInfo';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import SelectField from '../../../../components/SelectField/SelectField';
import Button from '../../../../components/Button/Button';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Pagination from '../../../../components/Pagination/Pagination';
import CircularProgress from '../../../../components/CircularProgress/CircularProgress';

const DeliveryProgress = ({ handleFromDeliveryToScOd }) => {
  const initialFilterField = {
    // 取得90天以前的日期
    startDate: subDays(new Date(), 90),
    endDate: new Date(),
    elName: [],
    customerName: [],
    scOdNo: [],
  };
  const {
    formData: filterField,
    setFormData: setFilterField,
    formErr: filterErr,
    setFormErr: setFilterErr,
    resetFormData: resetFilterField,
    resetFormErr: resetFilterFieldErr,
  } = useForm(initialFilterField);
  const orderArr = [
    { name: '工令單號', value: 1 },
    { name: '出貨日期', value: 2 },
    { name: '良率', value: 3 },
    { name: '完成率', value: 4 },
  ];
  const { dialogDispatch } = useContext(DialogContext);
  const [actionBasis, setActionBasis] = useState(reportScheduleType.BY_DELIVERY_DATE);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showYieldRateChart, setShowYieldRateChart] = useState(false);
  const [showTransListDialog, setShowTransListDialog] = useState(false);
  const [deliveryProgressData, setDeliveryProgressData] = useState([]);
  const [currentTransList, setCurrentTransList] = useState([]);
  const [targetScOdNo, setTargetScOdNo] = useState('');
  const [transType, setTransType] = useState('');
  const [toRenderYieldChart, setToRenderYieldChart] = useState(false);
  const [showAdvance, setShowAdvance] = useState(false);
  const [productSelection, setProductSelection] = useState([{}]);
  const [customerSelection, setCustomerSelection] = useState([{}]);
  const initProductSelection = useRef([{}]);
  const initCustomerSelection = useRef([{}]);
  const [orderType, setOrderType] = useState(1);
  const [dialogType, setDialogType] = useState('');
  const [noProgress, setNoProgress] = useState(false);
  const yieldRateData = useRef([]);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();

  const onActionBasisChange = (e) => {
    setActionBasis(e.target.value);
  };

  const handleOrderChange = (e) => {
    setOrderType(e.target.value);
  };

  const handleOrderSearch = (type, sortWay) => {
    if (type === 1) {
      const newArray = [...deliveryProgressData].sort((a, b) =>
        sortWay === 'increase'
          ? a.scOdNo.slice(1) - b.scOdNo.slice(1)
          : b.scOdNo.slice(1) - a.scOdNo.slice(1)
      );
      setDeliveryProgressData(newArray);
      return;
    }
    if (type === 2) {
      const newArray = [...deliveryProgressData].sort((a, b) =>
        sortWay === 'increase'
          ? new Date(a.deliveryDate) - new Date(b.deliveryDate)
          : new Date(b.deliveryDate) - new Date(a.deliveryDate)
      );
      setDeliveryProgressData(newArray);
      return;
    }
    if (type === 3) {
      const newArray = [...deliveryProgressData].sort((a, b) =>
        sortWay === 'increase' ? a.yieldRate - b.yieldRate : b.yieldRate - a.yieldRate
      );
      setDeliveryProgressData(newArray);
      return;
    }
    if (type === 4) {
      const newArray = [...deliveryProgressData].sort((a, b) =>
        sortWay === 'increase'
          ? a.completionRate - b.completionRate
          : b.completionRate - a.completionRate
      );
      setDeliveryProgressData(newArray);
    }
  };

  const formatFilterDate = () => {
    const { startDate, endDate } = filterField;
    return {
      startDate: format(new Date(startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(endDate), 'yyyy-MM-dd'),
    };
  };
  const validationDate = () => {
    const { startDate, endDate } = filterField;
    if (compareDesc(startDate, endDate) < 0) {
      setFilterErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      return false;
    }
    return true;
  };

  const handleFetchTransList = async (workId, type) => {
    const data = {
      workId,
      receipt: type === 6 || type === 7 ? 1 : type,
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/transByWork',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { transfer } = result;
      setCurrentTransList(
        transfer.map((item, idx) => ({
          id: idx + 1,
          transId: item.transId,
          transQty: item.transQuantity,
          formingDate: item.formDate,
          lastUpdateDate: item.modifyDate,
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得移轉單資訊',
          message:
            noPrivilegeMsg || `${result}：目前無法取得移轉單資訊，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleFetchOdNoList = async (workId) => {
    const data = {
      scOrderNum: workId,
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkOrderInfoByWork',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { orderInfoList } = result;
      setCurrentTransList(
        orderInfoList.map((item, idx) => ({
          id: idx + 1,
          odNo: item.orderNum,
          odQty: item.orderQty,
          odReDate: item.orderReDate,
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得移轉單資訊',
          message:
            noPrivilegeMsg || `${result}：目前無法取得移轉單資訊，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleFetchYieldRateData = async (workId) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkBadByWork',
      { workId },
      true,
      cancelToken
    );
    if (status) {
      const { bad } = result;
      yieldRateData.current = bad;
      setToRenderYieldChart(true);
      setShowYieldRateChart(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得不良原因資訊',
          message:
            noPrivilegeMsg ||
            `${result}：目前無法取得不良原因相關資訊，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleShowTransList = (scOdNo, workId, type) => {
    setShowTransListDialog(true);
    setTargetScOdNo(scOdNo);
    setTransType(type);
    handleFetchTransList(workId, type);
    setDialogType('delivery_progress');
  };

  const handleShowYieldRateChart = (scOdNo, workId) => {
    setTargetScOdNo(scOdNo);
    handleFetchYieldRateData(workId);
  };

  const handleShowScOdProcess = (scOdNo) => {
    setTargetScOdNo(scOdNo);
    handleFromDeliveryToScOd(scOdNo);
  };

  const handleShowOdNoInfo = (scOdNo) => {
    setShowTransListDialog(true);
    handleFetchOdNoList(scOdNo);
    setDialogType('od_no_info');
  };

  const handleYieldRateChartClose = () => {
    setShowYieldRateChart(false);
  };

  const handleTransListDialogClose = () => {
    setShowTransListDialog(false);
    setTransType('');
    setTargetScOdNo('');
    setCurrentTransList([]);
  };

  const handleFetchDeliveryProgress = async ({ isAdvanced = 0, type, page = 0 }) => {
    resetFilterFieldErr();
    if (!validationDate()) return;
    const cancelToken = newCancelToken();
    const { startDate, endDate } = formatFilterDate();
    setIsLoading(true);
    setDeliveryProgressData([]);

    if (page) {
      setCurrentPage(page);
    }

    // eslint-disable-next-line no-nested-ternary
    const data = {
      checkBegin: startDate,
      checkEnd: endDate,
      isAdvanced: isAdvanced || 0,
      page: page || currentPage,
    };

    if (type === reportScheduleType.BY_DELIVERY_DATE) {
      data.customs = filterField.customerName.map((item) => item.value).toString();
      data.prods = filterField.elName.map((item) => item.name).toString();
    }

    if (type === reportScheduleType.BY_SC_OD_NO) {
      data.orderNumbers = filterField.scOdNo;
    }

    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/deliveryProgress',
      data,
      true,
      cancelToken
    );
    setIsLoading(false);
    if (status) {
      if (!result) return;
      const { progress, totalPages } = result;
      setTotalPage(totalPages || 1);
      setDeliveryProgressData(
        progress.map((item, idx) => ({
          id: idx + 1,
          workId: item.workId,
          scOdNo: item.scOdno,
          lastUpdateDate: item.modifyDate,
          deliveryDate: item.odRedat,
          manufactureType: item.manufactureType,
          productInfo: `${item.prodName}(${item.materialName})`,
          producedQty: item.prodQuantity,
          passQty: item.passQuantity,
          storageQty: item.packageQuantity,
          requirementQty: item.scQty,
          completionRate: parseDecimalToFixedString(item.completeRate * 100),
          yieldRate: item.goodRate ? parseDecimalToFixedString(item.goodRate * 100) : '',
          formingTransNum: item.formCount,
          formingTransQty: item.formQuantity,
          formingTotalQty: item.formingTotalQty || 0,
          sinteringTransNum: item.sinterCount,
          sinteringTransQty: item.sinterQuantity,
          sinteringTotalQty: item.sinteringTotalQty || 0,
          grindingTransNum: item.grindCount,
          grindingTransQty: item.grindQuantity,
          grindingTotalQty: item.grindingTotalQty || 0,
          checkingTransNum: item.checkCount,
          checkingTransQty: item.checkQuantity,
        }))
      );
      setNoProgress(false);
      // 所選取的時間範圍內，沒有該生產批號的資料
      if (progress.length === 0 && data.isAdvanced) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '無法取得出貨進度相關資料',
            message:
              actionBasis === reportScheduleType.BY_DELIVERY_DATE
                ? '目前沒有所查詢時間區間內的出貨進度表相關資料'
                : '生產批號不存在',
            singleBtn: true,
          },
        });
        return;
      }
      // 所選取的時間範圍內沒有資料
      if (progress.length === 0) {
        setNoProgress(true);
      }
      window.scrollTo(0, 0);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得出貨進度相關資料',
          message:
            noPrivilegeMsg ||
            `${result}：目前無法取得出貨進度相關資料，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleFetchDeliveryAdvanced = async (bool) => {
    if (!validationDate()) return;

    const cancelToken = newCancelToken();
    const { startDate, endDate } = formatFilterDate();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/deliveryAdvanced',
      { checkBegin: startDate, checkEnd: endDate },
      true,
      cancelToken
    );
    if (status) {
      setShowAdvance(bool);
      const { customer, product } = result;
      initCustomerSelection.current = customer.map((cus) => ({
        name: cus.customerName,
        value: cus.customerId,
      }));
      initProductSelection.current = product.map((item, index) => ({
        name: item.prodName,
        value: index + 1,
      }));
      setCustomerSelection(initCustomerSelection.current);
      setProductSelection(initProductSelection.current);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '錯誤',
          message:
            noPrivilegeMsg || `${result}：目前無法取得進階相關資料，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handlePage = (e, page) => {
    setCurrentPage(page);
    handleFetchDeliveryProgress({ isAdvanced: showAdvance ? 1 : 0, page });
  };

  const handleCancelDeliveryAdvanced = () => {
    setShowAdvance(false);
    setFilterField(initialFilterField);
  };

  return (
    <>
      <FilterInfo
        onActionBasisChange={onActionBasisChange}
        actionBasis={actionBasis}
        showAdvance={showAdvance}
        handleShowAdvancedFilter={handleFetchDeliveryAdvanced}
        filterField={filterField}
        setFilterField={setFilterField}
        handleFetch={handleFetchDeliveryProgress}
        productSelection={productSelection}
        customerSelection={customerSelection}
        setProductSelection={setProductSelection}
        setCustomerSelection={setCustomerSelection}
        initProductSelection={initProductSelection.current}
        initCustomerSelection={initCustomerSelection.current}
        filterErr={filterErr}
        setFilterErr={setFilterErr}
        resetFilterFieldErr={resetFilterFieldErr}
        handleCancelDeliveryAdvanced={handleCancelDeliveryAdvanced}
      />
      <div className={classes.main_content}>
        <Typography variant="h6">出貨進度表</Typography>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Typography variant="subtitle1">排序方法：</Typography>
          <Grid xs={2}>
            <SelectField
              id="orderType"
              selections={orderArr}
              handleChange={handleOrderChange}
              value={orderType}
              size="small"
            />
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            customClasses={classes.order_Button}
            onClick={() => handleOrderSearch(orderType, 'increase')}
          >
            遞增排序
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<RemoveIcon />}
            customClasses={classes.order_Button}
            onClick={() => handleOrderSearch(orderType, 'decrease')}
          >
            遞減排序
          </Button>
        </Grid>
        <Grid container className={commonClasses.content}>
          {isLoading ? (
            <CircularProgress direction="column" alignItems="center" mt={2} />
          ) : (
            <>
              {deliveryProgressData.map((row) => (
                <DeliveryDataRow
                  rowData={row}
                  key={`${row.id}-${row.workId}`}
                  handleShowTransList={handleShowTransList}
                  handleShowOdNoInfo={handleShowOdNoInfo}
                  handleShowYieldRateChart={handleShowYieldRateChart}
                  handleShowScOdProcess={handleShowScOdProcess}
                />
              ))}
              {noProgress && (
                <PaperContainer
                  className={`${commonClasses.container} ${classes.delivery_container}`}
                >
                  <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                    目前沒有所查詢時間區間內的出貨進度表相關資料
                  </Typography>
                </PaperContainer>
              )}
            </>
          )}
          {deliveryProgressData.length > 0 && (
            <Grid container justifyContent="end">
              <Pagination
                shape="rounded"
                size="large"
                count={totalPage}
                showFirstButton
                showLastButton
                onChange={handlePage}
                page={currentPage}
                disabled={totalPage <= 1}
              />
            </Grid>
          )}
        </Grid>
        <YieldRateChart
          open={showYieldRateChart}
          handleChartClose={handleYieldRateChartClose}
          targetScOdNo={targetScOdNo}
          yieldRateData={yieldRateData.current}
          toRender={toRenderYieldChart}
          setToRender={setToRenderYieldChart}
        />
        <TransListDialog
          open={showTransListDialog}
          handleTransListDialogClose={handleTransListDialogClose}
          targetScOdNo={targetScOdNo}
          transType={transType}
          transList={currentTransList}
          type={dialogType}
        />
      </div>
    </>
  );
};

DeliveryProgress.propTypes = {
  handleFromDeliveryToScOd: PropTypes.func.isRequired,
};

export default DeliveryProgress;
