/* eslint-disable no-unused-vars */
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  fetch_btn: {
    marginLeft: theme.spacing(2.5),
  },
  error: {
    marginBottom: theme.spacing(2.5),
  },
  record_header: {
    display: 'flex',
    width: '100%',
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
  },
  record_content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 0,
    margin: 0,
  },
  record_row: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
  },
  record_column: {
    minWidth: 90,
    marginRight: theme.spacing(2),
  },
  config_container: {
    marginBottom: theme.spacing(5),
  },
  note_input: {
    width: `calc(100% - 150px)`,
    marginRight: 'auto',
  },
}));

export default useStyles;
