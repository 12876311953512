import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_LINE_AUTH_API_PREFIX,
  timeout: 1000 * 3,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const useLineAxios = async (api, params) => {
  try {
    console.log('line request: ', {
      api,
      req: params,
    });
    const result = await instance.post(api, params);
    console.log('line response: ', {
      status: result.status,
      data: result.data,
    });
    if (result.status !== 200) {
      return [false, 'LINE login failed!'];
    }
    return [true, result.data];
  } catch (err) {
    return [false, 'LINE login failed!'];
  }
};

export default useLineAxios;
