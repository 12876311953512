const styleConst = {
  DRAWER_WIDTH: 290,
  DRAWER_WIDTH_MD: 240,
  DRAWER_CLOSE: 80,
  TOOLBAR_HEIGHT: 70,
  TOOLBAR_HEIGHT_MOBILE: 60,
  ROWS_PER_PAGE: 15,
};

export default styleConst;
