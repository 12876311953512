/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import SelectField from '../../../components/SelectField/SelectField';

const EditRecordSelection = ({
  qcFormingRecord,
  qcFormingRecordErr,
  qcFormingRecords,
  handleQcFormingRecordChange,
}) => {
  const [formingRecord, setFormingRecord] = useState(qcFormingRecord);
  const [formingRecordErr] = useState(qcFormingRecordErr);
  const { classes } = useStyles();

  const handleFormingRecordChange = (e) => {
    setFormingRecord(e.target.value);
    handleQcFormingRecordChange(e.target.value);
  };

  return (
    <div className={classes.dialog_selection}>
      <SelectField
        id="qcFormingRecord"
        label="已建立之壓型檢測"
        placeholder="請選擇已建立之壓型檢測"
        value={formingRecord}
        selections={qcFormingRecords}
        handleChange={handleFormingRecordChange}
        error={formingRecordErr}
        errormsg="請選擇要更新的壓型檢測紀錄，或按取消關閉此對話框，並點選新增以新增一筆壓型檢測紀錄"
      />
    </div>
  );
};

EditRecordSelection.propTypes = {
  qcFormingRecord: PropTypes.string.isRequired,
  qcFormingRecordErr: PropTypes.bool.isRequired,
  qcFormingRecords: PropTypes.array.isRequired,
  handleQcFormingRecordChange: PropTypes.func.isRequired,
};

export default EditRecordSelection;
