/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import MuiCircularProgress from '@mui/material/CircularProgress';
import Grid from '../Grid/Grid';
import Typography from '../Typography/Typography';

const CircularProgress = ({ style, children, id, ...rest }) =>
  id !== 'exportLoading' ? (
    <Grid container justifyContent="center" style={style} {...rest}>
      {children && (
        <Typography variant="subtitle1" mb={3}>
          {children}
        </Typography>
      )}
      <MuiCircularProgress />
    </Grid>
  ) : (
    <MuiCircularProgress {...rest} />
  );

CircularProgress.defaultProps = {
  style: {},
  children: '',
  id: '',
};

CircularProgress.propTypes = {
  style: PropTypes.object,
  children: PropTypes.string,
  id: PropTypes.string,
};

export default CircularProgress;
