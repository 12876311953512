import React, { useEffect, useContext, useState, useReducer } from 'react';
import { blue } from '@mui/material/colors';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import PageTitle from '../components/PageTitle/PageTitle';
import { DialogContext } from '../contexts/DialogContext';
import { dialogActions } from '../reducers/dialogReducer';
import checkboxReducer, { checkboxActions } from '../reducers/checkboxReducer';
import Typography from '../components/Typography/Typography';
import Grid from '../components/Grid/Grid';
import Button from '../components/Button/Button';
import InputField from '../components/InputField/InputField';
import ReadonlyField from '../components/ReadonlyField/ReadonlyField';
import CheckboxGroup from '../components/CheckboxGroup/CheckboxGroup';
import SelectField from '../components/SelectField/SelectField';
import Radio from '../components/Radio/Radio';

const Demo = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const [transNo, setTransNo] = useState('');
  const [machine, setMachine] = useState(0);
  const [machineList, setMachineList] = useState([]);
  const [checkboxItems, checkboxDispatch] = useReducer(checkboxReducer, []);
  const [checkboxItems2, checkboxDispatch2] = useReducer(checkboxReducer, []);
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    checkboxDispatch({
      type: checkboxActions.INIT,
      init: [
        {
          name: 'trans_create',
          label: '建立移轉單',
          checked: false,
        },
        {
          name: 'trans_update_forming',
          label: '更新移轉單（壓型）',
          checked: false,
        },
        {
          name: 'trans_update_sintering',
          label: '更新移轉單（燒結）',
          checked: false,
        },
        {
          name: 'trans_update_grinding',
          label: '更新移轉單（研磨）',
          checked: false,
        },
        {
          name: 'package_create',
          label: '建立檢裝單',
          checked: false,
        },
        {
          name: 'trans_update_testing',
          label: '更新檢裝單（檢測）',
          checked: false,
        },
        {
          name: 'trans_update_package',
          label: '更新檢裝單（包裝）',
          checked: false,
        },
      ],
    });
    checkboxDispatch2({
      type: checkboxActions.INIT,
      init: [
        {
          name: 'trans_create',
          label: '建立移轉單',
          checked: true,
        },
        {
          name: 'trans_update_forming',
          label: '更新移轉單（壓型）',
          checked: false,
        },
        {
          name: 'trans_update_sintering',
          label: '更新移轉單（燒結）',
          checked: false,
        },
        {
          name: 'trans_update_grinding',
          label: '更新移轉單（研磨）',
          checked: false,
        },
        {
          name: 'package_create',
          label: '建立檢裝單',
          checked: true,
        },
        {
          name: 'trans_update_testing',
          label: '更新檢裝單（檢測）',
          checked: true,
        },
        {
          name: 'trans_update_package',
          label: '更新檢裝單（包裝）',
          checked: true,
        },
      ],
    });
    setMachineList([
      {
        id: 1,
        value: 'machine 1',
        name: '機台一',
      },
      {
        id: 2,
        value: 'machine 2',
        name: '機台二',
      },
      {
        id: 3,
        value: 'machine 3',
        name: '機台三',
      },
      {
        id: 4,
        value: 'machine 4',
        name: '機台四',
      },
    ]);
    const unloadFn = (e) => {
      e.preventDefault();
      e.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadFn);

    return () => window.removeEventListener('beforeunload', unloadFn);
  }, []);

  const handleDialogConfirm = () => {
    console.log('Dialog Ready to close');
  };

  const handleOpen = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認刪除？',
        message: '請注意！！模具2, 7的數值為空，按下確認後，資料將寫入資料庫。確定嗎？確定嗎？',
        singleBtn: true,
        handleConfirm: handleDialogConfirm,
      },
    });
  };

  const handleInputChange = (e) => {
    setTransNo(e.target.value);
  };

  const handleSelectChange = (e) => {
    setMachine(e.target.value);
  };

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log(checkboxItems);
    console.log(transNo);
    console.log(machine);
  };

  return (
    <div className="Demo">
      <PageTitle title="Demo page" />
      <Grid container style={{ backgroundColor: blue[100], marginBottom: 20 }}>
        <Grid item md={9} xs={12} style={{ backgroundColor: blue[500] }}>
          <Typography variant="h3">This is the demo page for components usage demo.</Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 20 }} justifyContent="center">
        <Button onClick={handleOpen}>Open Dialog</Button>
      </Grid>
      <Grid container style={{ marginBottom: 20 }} justifyContent="center">
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckRoundedIcon />}
            onClick={handleClick}
          >
            確認
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button variant="outlined" color="primary" startIcon={<SearchRoundedIcon />}>
            查詢
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="error"
            rounded
            startIcon={<RemoveCircleOutlineRoundedIcon />}
          >
            刪除
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="primary"
            rounded
            startIcon={<CheckRoundedIcon />}
            onClick={handleClick}
          >
            確認
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="error"
            size="small"
            rounded
            startIcon={<SearchRoundedIcon />}
          >
            取消
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="secondary"
            rounded
            startIcon={<RemoveCircleOutlineRoundedIcon />}
          >
            修改
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckRoundedIcon />}
            onClick={handleClick}
            fullWidth
            style={{ marginTop: 15 }}
          >
            確認
          </Button>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 20 }} spacing={3}>
        <Grid item xs={6}>
          <InputField
            id="transNo"
            label="移轉單號"
            value={transNo}
            variant="outlined"
            onChange={handleInputChange}
            placeholder="請輸入移轉單號"
            name="transNo"
            type="text"
            errormsg="請輸入正確的移轉單號"
          />
        </Grid>
        <Grid item xs={6}>
          <ReadonlyField label="移轉單號" value="TFH03457891" name="transNoReadonly" />
        </Grid>
        <Grid item xs={4}>
          <CheckboxGroup itemList={checkboxItems} checkboxDispatch={checkboxDispatch} />
        </Grid>
        <Grid item xs={4}>
          <CheckboxGroup itemList={checkboxItems2} checkboxDispatch={checkboxDispatch2} disabled />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            label="機台 / Mesin"
            placeholder="請選擇機台/Mesin"
            value={machine}
            handleChange={handleSelectChange}
            selections={machineList}
            id="machine"
          />
        </Grid>
        <Grid item xs={6}>
          <Radio
            label="通孔"
            name="plated"
            value="plated"
            selectedValue={selectedValue}
            onChange={handleRadioChange}
          />
          <Radio
            label="研磨"
            name="grinder"
            value="grinder"
            selectedValue={selectedValue}
            onChange={handleRadioChange}
          />
          <Radio
            label="通孔 + 研磨"
            name="both"
            value="both"
            selectedValue={selectedValue}
            onChange={handleRadioChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default Demo;
