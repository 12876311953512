import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import MuiTextField from '@mui/material/TextField';

const useStyles = makeStyles()((theme) => ({
  input: {
    '&::placeholder': {
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
    },
  },
}));

const InputField = (props) => {
  const {
    id,
    label,
    value,
    onChange,
    placeholder,
    type,
    name,
    error,
    errormsg,
    inputClasses,
    tabIndex,
  } = props;
  const { classes } = useStyles();
  const cancelInputWheel = (e) => {
    if (e.target.type === 'number') {
      e.target.blur();
    }
  };
  return (
    <MuiTextField
      id={id}
      label={label}
      value={value}
      variant="outlined"
      onChange={onChange}
      onWheel={cancelInputWheel}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        className: `${classes.input} ${inputClasses}`,
        pattern: type === 'number' ? '\\d*' : '',
        tabIndex,
        onKeyPress(e) {
          if (type === 'number' && !(e.charCode === 46 || (e.charCode >= 48 && e.charCode <= 57))) {
            e.preventDefault();
          }
        },
      }}
      name={name}
      type={type}
      fullWidth
      error={error}
      helperText={error && errormsg}
      {...props}
    />
  );
};

InputField.defaultProps = {
  placeholder: '',
  type: 'text',
  error: false,
  errormsg: '',
  inputClasses: '',
  tabIndex: '',
};

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.bool,
  errormsg: PropTypes.string,
  inputClasses: PropTypes.string,
  tabIndex: PropTypes.string,
};

export default InputField;
