import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: 250,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      height: 'calc(100vh - 120px)',
    },
  },
  container: {
    width: 600,
    height: 220,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  text_redirect: {
    marginBottom: theme.spacing(2),
    textTransform: 'Uppercase',
  },
  text_desktop: {
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  text_mobile: {
    marginBottom: theme.spacing(1.2),
  },
  text_meta: {
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(2),
  },
  lineLogin: {
    backgroundColor: '#00c300',
    color: theme.palette.common.white,
    fontSize: 15,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: '#00e000',
    },
    '&:hover span': {
      borderColor: '#00c900',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
      letterSpacing: '0.5px',
      paddingRight: theme.spacing(5),
    },
  },
  lineLogo: {
    display: 'inline-block',
    height: 46,
    padding: theme.spacing(0.6),
    borderRight: '2px solid #00b300',
    marginRight: theme.spacing(2.5),
    '& img': {
      width: 'auto',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(5),
    },
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  icon_container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3.2),
  },
  icon: {
    fontSize: 110,
  },
}));

export default useStyles;
