/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import useStyles from './useStyles';
import useCommonStyles from '../../useStyles';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import SelectField from '../../../../components/SelectField/SelectField';
import InputField from '../../../../components/InputField/InputField';
import Button from '../../../../components/Button/Button';
import Radio from '../../../../components/Radio/Radio';
import {
  measureStyleSelection,
  notRMeasureStyleSelection,
} from '../../../../constants/configSelections';
import MeasureStyleContent from './MeasureStyleContent';
import { tabDirectionType } from '../../../../constants/enums';

const QCSinteringElectrical = ({
  isRStick,
  measureStyle,
  lastMeasureStyle,
  handleMeasureStyleChange,
  handleFetchDifferentMeasureStyle,
  showTable,
  showPaper,
  isLoading,
  currentSubSpec,
  currentSpec,
  measureConfig,
  diffNLMeasure,
  ampSpecConfig,
  specConfig,
  renderRow,
  setRenderRow,
  handleSubSpecChange,
  resultNote,
  handleResultNoteChange,
  handleFormSubmit,
  isLMeasure,
  currentMeasureStyle,
  oneColumn,
  rowLength,
  handleDelete,
  disabled,
}) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const [actionBasis, setActionBasis] = useState(tabDirectionType.BY_ITEM);
  const onActionBasisChange = (e) => {
    setActionBasis(e.target.value);
  };
  return (
    showPaper && (
      <PaperContainer className={commonClasses.container} type="QCSinteringElectrical">
        <Typography variant="h6" className={classes.paper_padding_title}>
          填寫量測數據
        </Typography>
        <Grid container direction="column" mt={2.8}>
          {isRStick !== 0 && (
            <>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="space-between"
                className={`${classes.paper_padding}`}
              >
                <Grid item xs={5}>
                  {!oneColumn && (
                    <div>
                      <Radio
                        label="依量測item填寫"
                        name="byItem"
                        value={tabDirectionType.BY_ITEM}
                        selectedValue={actionBasis}
                        onChange={onActionBasisChange}
                      />
                      <Radio
                        label="依量測sample填寫"
                        name="bySample"
                        value={tabDirectionType.BY_SAMPLE}
                        selectedValue={actionBasis}
                        onChange={onActionBasisChange}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={7}>
                  <div className={classes.measure_style_group}>
                    <Typography variant="subtitle1">選擇量測型態：</Typography>
                    <Grid item xs={3}>
                      <SelectField
                        id="measureStyle"
                        placeholder="請選擇量測型態"
                        value={measureStyle === 0 ? currentMeasureStyle : measureStyle}
                        selections={measureStyleSelection}
                        handleChange={handleMeasureStyleChange}
                        className={classes.measure_selection}
                        size="small"
                      />
                    </Grid>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<SearchRoundedIcon />}
                      customClasses={classes.select_btn}
                      onClick={handleFetchDifferentMeasureStyle}
                    >
                      查詢
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
          {isRStick === 0 && isLMeasure !== 1 && (
            <>
              <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                className={`${classes.paper_padding} ${classes.title_padding}`}
              >
                {!oneColumn && (
                  <div>
                    <Radio
                      label="依量測item填寫"
                      name="byItem"
                      value={tabDirectionType.BY_ITEM}
                      selectedValue={actionBasis}
                      onChange={onActionBasisChange}
                    />
                    <Radio
                      label="依量測sample填寫"
                      name="bySample"
                      value={tabDirectionType.BY_SAMPLE}
                      selectedValue={actionBasis}
                      onChange={onActionBasisChange}
                    />
                  </div>
                )}
                <Typography
                  variant="subtitle1"
                  className={`${classes.font_weight} ${classes.paper_padding_title}`}
                >
                  量測型態： <b>Z</b>
                </Typography>
              </Grid>
            </>
          )}
          {isRStick === 0 && isLMeasure === 1 && (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              className={`${classes.paper_padding} ${classes.title_padding}`}
            >
              <Grid item xs={5}>
                {!oneColumn && (
                  <div>
                    <Radio
                      label="依量測item填寫"
                      name="byItem"
                      value={tabDirectionType.BY_ITEM}
                      selectedValue={actionBasis}
                      onChange={onActionBasisChange}
                    />
                    <Radio
                      label="依量測sample填寫"
                      name="bySample"
                      value={tabDirectionType.BY_SAMPLE}
                      selectedValue={actionBasis}
                      onChange={onActionBasisChange}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={7}>
                <div className={classes.measure_style_group}>
                  <Typography variant="subtitle1">選擇量測型態：</Typography>
                  <Grid item xs={3}>
                    <SelectField
                      id="measureStyle"
                      placeholder="請選擇量測型態"
                      value={measureStyle === 0 ? currentMeasureStyle : measureStyle}
                      selections={notRMeasureStyleSelection}
                      handleChange={handleMeasureStyleChange}
                      className={classes.measure_selection}
                      size="small"
                    />
                  </Grid>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<SearchRoundedIcon />}
                    customClasses={classes.select_btn}
                    onClick={handleFetchDifferentMeasureStyle}
                  >
                    查詢
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
          <MeasureStyleContent
            showTable={showTable}
            isLoading={isLoading}
            currentSubSpec={currentSubSpec}
            specConfig={specConfig}
            currentSpec={currentSpec}
            measureConfig={measureConfig}
            diffNLMeasure={diffNLMeasure}
            ampSpecConfig={ampSpecConfig}
            renderRow={renderRow}
            handleRowChange={setRenderRow}
            handleSubSpecChange={handleSubSpecChange}
            measureStyle={lastMeasureStyle}
            isLMeasure={isLMeasure}
            isRStick={isRStick}
            tabDirection={actionBasis}
            rowLength={rowLength}
          />
        </Grid>

        {showTable && (
          <Grid container alignItems="center" wrap="nowrap" className={classes.table_footer}>
            <InputField
              id="note"
              name="note"
              type="text"
              label="備註"
              placeholder="請輸入備註內容"
              value={resultNote}
              onChange={handleResultNoteChange}
            />
            {/* 20230103 Shane - Delete QC record functionality will re-implement in the next phase */}
            {renderRow[0].specItems[0].value !== '' && (
              <Button
                variant="contained"
                color="error"
                rounded
                customClasses={classes.submit_btn}
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
                disabled={disabled}
              >
                刪除
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              rounded
              customClasses={classes.submit_btn}
              startIcon={<CheckRoundedIcon />}
              onClick={handleFormSubmit}
            >
              送出
            </Button>
          </Grid>
        )}
      </PaperContainer>
    )
  );
};

QCSinteringElectrical.propTypes = {
  isRStick: PropTypes.bool.isRequired,
  measureStyle: PropTypes.number.isRequired,
  lastMeasureStyle: PropTypes.number.isRequired,
  handleMeasureStyleChange: PropTypes.func.isRequired,
  handleFetchDifferentMeasureStyle: PropTypes.func.isRequired,
  showTable: PropTypes.bool.isRequired,
  showPaper: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentSubSpec: PropTypes.string.isRequired,
  currentSpec: PropTypes.array.isRequired,
  measureConfig: PropTypes.array.isRequired,
  ampSpecConfig: PropTypes.array.isRequired,
  diffNLMeasure: PropTypes.array.isRequired,
  specConfig: PropTypes.array.isRequired,
  renderRow: PropTypes.array.isRequired,
  setRenderRow: PropTypes.func.isRequired,
  handleSubSpecChange: PropTypes.func.isRequired,
  resultNote: PropTypes.string.isRequired,
  handleResultNoteChange: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  isLMeasure: PropTypes.number.isRequired,
  currentMeasureStyle: PropTypes.number.isRequired,
  oneColumn: PropTypes.bool.isRequired,
  rowLength: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default QCSinteringElectrical;
