/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../../components/Dialog/Dialog';
import DialogTitle from '../../../components/DialogTitle/DialogTitle';
import DialogContent from '../../../components/DialogContent/DialogContent';
import DialogActions from '../../../components/DialogActions/DialogActions';
import CircularProgress from '../../../components/CircularProgress/CircularProgress';
import useStyles from './useStyles';
import {
  measureStyleType,
  qcExportMeasureStyle,
  sinteringSizeType,
} from '../../../constants/enums';
import FormingDetail from './FormingDetail/FormingDetail';
import InspectionDetail from './InspectionDetail/InspectionDetail';
import SinteringDetail from './SinteringDetail/SinteringDetail';
import useAxios from '../../../hooks/useAxios';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useCancelToken from '../../../hooks/useCancelToken';

const DetailDialog = ({ open, handleDetailClose, currentRow }) => {
  const { dialogDispatch } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState(true);
  const [renderRows, setRenderRows] = useState([]);
  const [specConfig, setSpecConfig] = useState([]);
  const [formingSpec, setFormingSpec] = useState({});
  const [renderData, setRenderData] = useState({}); // For sintering size / electircal type
  const [specData, setSpecData] = useState({}); // For sintering size / electrical type
  const { transId, customer, product, measureStyleId, styleId, checkId } = currentRow;
  const { classes } = useStyles();
  const { newCancelToken } = useCancelToken();

  const handleConfirm = () => {
    handleDetailClose();
  };

  const transformSpecConfig = (measure) =>
    measure.map((spec) => ({
      specId: spec.measureId,
      styleId: spec.styleId,
      specItem: spec.measureItem,
      specRecipe: spec.measureRecipe,
      specTarget: spec.measureTarget,
      specUpper: spec.measureUpperSpec,
      specLower: spec.measureLowerSpec,
      limitUpper: spec.measureUpperLimit,
      limitLower: spec.measureLowerLimit,
      specBias: spec.measureDeviation,
    }));

  const getFormingDetail = (result) => {
    const { detail } = result;
    const sortedDetail = detail.sort((a, b) => a.formDetailId - b.formDetailId);
    const sampleDetail = detail[0];
    setFormingSpec({
      heightMax: sampleDetail.heightUpper,
      heightMin: sampleDetail.heightLower,
      weightMax: sampleDetail.weightUpper,
      weightMin: sampleDetail.weightLower,
    });
    setRenderRows(
      sortedDetail.map((row, idx) => ({
        rowId: idx,
        detailId: row.formDetailId,
        mold: row.formModel,
        cutNo: row.knifeCount,
        height: row.formHeight,
        weight: row.formWeight,
        note: row.detailComments,
        heightMax: row.heightUpper,
        heightMin: row.heightLower,
        weightMax: row.weightUpper,
        weightMin: row.weightLower,
        defectType: row.checkDetectList.filter((type) => type.isPromise),
      }))
    );
  };

  const setSizeConfig = (measure) => {
    const specArr = transformSpecConfig(measure);
    if (currentRow.measureStyleId === qcExportMeasureStyle.SINTERING_INSPECTION) {
      setSpecConfig([...specArr]);
    } else {
      setSpecData((prev) => ({
        ...prev,
        sinteringSize: [...specArr],
      }));
    }
  };
  const checkRenderRows = (array) => {
    const emptyRowIndexArr = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < array.length; i++) {
      const condition = array[i].sinterLevel - array[i - 1].sinterLevel;
      if (condition !== 0) {
        emptyRowIndexArr.push(i);
      }
    }
    return emptyRowIndexArr;
  };

  const setSizeData = (rows) => {
    const dataRows = rows
      ? rows.map((row, idx) => ({
          rowId: idx,
          sinterRowId: row.sinterRowId,
          sinterLevel: row.sinterLevel,
          sinterPosition: row.sinterPosition,
          checkId: row.sinterCheckId,
          specItems: row.checkDetailList.map((spec) => ({
            specId: spec.measureId,
            itemId: spec.sinterDetailId,
            specValue: spec.detailValue,
          })),
          defectType: row.checkDetectList.filter((type) => type.isPromise),
          measureUser: row.modifyUser || '',
        }))
      : {};
    const emptyRow = {
      rowId: '',
      sinterRowId: '',
      sinterLevel: '',
      sinterPosition: '',
      checkId: '',
      specItems: [],
      defectType: [],
      measureUser: '',
    };
    if (
      currentRow.measureStyleId === qcExportMeasureStyle.SINTERING_INSPECTION &&
      Object.keys(dataRows).length > 0
    ) {
      const emptyIndexArr = checkRenderRows(dataRows).map((i, index) => i + index);

      emptyIndexArr.map((index, i) => dataRows.splice(index, 0, emptyRow));
      setRenderRows([...dataRows]);
    } else if (Object.keys(dataRows).length > 0) {
      setRenderData((prev) => ({
        ...prev,
        sinteringSize: [...dataRows],
      }));
    } else {
      setRenderData({});
    }
  };

  const getMeasureDiff = (measure, rowData) => {
    const rowDataMeasure = [];
    if (rowData && rowData.checkDetailList) {
      const specItems = rowData.checkDetailList;
      specItems.forEach((spec) =>
        rowDataMeasure.push(measure.find((item) => item.measureId === spec.measureId))
      );
    }
    const coilSpec = measure.find((spec) => spec.styleId === measureStyleType.COIL);
    const starSpec = measure.find((spec) => spec.styleId === measureStyleType.STAR);
    if (coilSpec) rowDataMeasure.push(coilSpec);
    if (starSpec) rowDataMeasure.push(starSpec);
    return rowDataMeasure;
  };

  const getInspectionDetail = (result) => {
    const { detailInspect, measureSize } = result;
    const measureSizeDiff = getMeasureDiff(measureSize, detailInspect[0]);
    setSizeConfig(measureSizeDiff);
    setSizeData(detailInspect);
  };

  const setElectricalData = (rows) =>
    rows
      ? rows.map((row, idx) => ({
          rowId: idx,
          sinterRowId: row.sinterRowId,
          checkId: row.sinterCheckId,
          specItems: row.checkDetailList.map((spec) => ({
            specId: spec.measureId,
            itemId: spec.sinterDetailId,
            specRecipe: spec.measureRecipe,
            specValue: spec.detailValue,
          })),
        }))
      : [];

  const getSizeElectricalDetail = (result) => {
    const {
      detailSize,
      measureSize,
      detailDb,
      detailL,
      detailN,
      detailZ,
      measureDb,
      measureL,
      measureN,
      measureZ,
      transfer,
    } = result;
    const measureSizeDiff =
      detailSize && detailSize.length > 0
        ? getMeasureDiff(measureSize, detailSize[0])
        : getMeasureDiff(measureSize, []);
    setSizeConfig(measureSizeDiff);
    setSizeData(detailSize);
    const { isRStick } = transfer;
    const detailDataN = detailN || [];
    const detailDataL = detailL || [];
    const detailDataDB = detailDb || [];
    const detailDataZ = detailZ || [];
    if (isRStick) {
      const measureNDiff = getMeasureDiff(measureN, detailDataN[0]);
      const measureLDiff = getMeasureDiff(measureL, detailDataL[0]);
      const measureDBDiff = getMeasureDiff(measureDb, detailDataDB[0]);
      setSpecData((prev) => ({
        ...prev,
        sinteringElectrical: {
          NSpecConfig: transformSpecConfig(measureNDiff),
          LSpecConfig: transformSpecConfig(measureLDiff),
          DBSpecConfig: transformSpecConfig(measureDBDiff),
        },
        isRStick: !!isRStick,
      }));
      setRenderData((prev) => ({
        ...prev,
        sinteringElectrical: {
          NDetail: setElectricalData(detailN),
          LDetail: setElectricalData(detailL),
          DBDetail: setElectricalData(detailDb),
        },
      }));
      return;
    }
    if (!isRStick && detailL) {
      const measureZDiff = getMeasureDiff(measureZ, detailDataZ[0]);
      const measureLDiff = getMeasureDiff(measureL, detailDataL[0]);
      setSpecData((prev) => ({
        ...prev,
        sinteringElectrical: {
          LSpecConfig: transformSpecConfig(measureLDiff),
          ZSpecConfig: transformSpecConfig(measureZDiff),
        },
        isRStick: !!isRStick,
      }));
      setRenderData((prev) => ({
        ...prev,
        sinteringElectrical: {
          LDetail: setElectricalData(detailL),
          ZDetail: setElectricalData(detailZ),
        },
      }));
      return;
    }
    const measureZDiff = getMeasureDiff(measureZ, detailDataZ[0]);
    setSpecData((prev) => ({
      ...prev,
      sinteringElectrical: {
        ZSpecConfig: transformSpecConfig(measureZDiff),
      },
      isRStick: !!isRStick,
    }));
    setRenderData((prev) => ({
      ...prev,
      sinteringElectrical: {
        ZDetail: setElectricalData(detailZ),
      },
    }));
  };

  const getExportDetail = async () => {
    const sizeType =
      measureStyleId === qcExportMeasureStyle.SINTERING_INSPECTION
        ? sinteringSizeType.INSPECTION
        : sinteringSizeType.SIZE;
    const data = {
      styleId,
      checkId,
      transId,
      isNGHistoryData: currentRow.isNGHistoryData,
    };
    if (
      measureStyleId === qcExportMeasureStyle.SINTERING_INSPECTION ||
      measureStyleId === qcExportMeasureStyle.SIZE_ELECTRICAL
    ) {
      data.sizeType = sizeType;
    }
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/qcExportDetail',
      { qc: [data], isExcel: 0 },
      true,
      cancelToken
    );
    if (status) {
      if (currentRow.measureStyleId === qcExportMeasureStyle.FORMING) {
        getFormingDetail(result);
      }
      if (currentRow.measureStyleId === qcExportMeasureStyle.SINTERING_INSPECTION) {
        getInspectionDetail(result);
      }
      if (currentRow.measureStyleId === qcExportMeasureStyle.SIZE_ELECTRICAL) {
        getSizeElectricalDetail(result);
      }
      setIsLoading(false);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得QC單資訊',
          message:
            noPrivilegeMsg || '目前暫時無法取得QC單的詳細資訊，請稍後再試，或請聯絡網管人員！',
          singleBtn: true,
          handleConfirm: handleDetailClose,
        },
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (typeof styleId !== 'number' || typeof checkId !== 'number') return;
    setRenderRows([]);
    setSpecConfig([]);
    setRenderData({});
    setSpecData({});
    setIsLoading(true);
    getExportDetail();
  }, [currentRow.rowId]);

  return (
    <div className={classes.dialog}>
      <Dialog open={open} onClose={handleDetailClose} fullWidth maxWidth="lg">
        <DialogTitle
          id="detail-title"
          onClose={handleDetailClose}
          className={classes.detail_header}
        >
          {transId} 量測數據｜{customer} / {product}
        </DialogTitle>
        <DialogContent dividers className={classes.detail_content}>
          {measureStyleId === qcExportMeasureStyle.FORMING && (
            <FormingDetail renderRows={renderRows} specConfig={formingSpec} />
          )}
          {measureStyleId === qcExportMeasureStyle.SINTERING_INSPECTION && (
            <InspectionDetail renderRows={renderRows} specConfig={specConfig} />
          )}
          {measureStyleId === qcExportMeasureStyle.SIZE_ELECTRICAL && (
            <SinteringDetail renderData={renderData} specData={specData} isLoading={isLoading} />
          )}
          {isLoading && <CircularProgress mt={7.5} />}
        </DialogContent>
        <DialogActions className={classes.detail_footer} onClick={handleConfirm}>
          確認
        </DialogActions>
      </Dialog>
    </div>
  );
};

DetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDetailClose: PropTypes.func.isRequired,
  currentRow: PropTypes.object.isRequired,
};

export default DetailDialog;
