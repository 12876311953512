/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Typography from '../../../../components/Typography/Typography';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Divider from '../../../../components/Divider/Divider';
import useStyles from '../useStyles';
import useCommonStyles from '../../useStyles';
import { manufactureType, receiptType } from '../../../../constants/enums';
import { useFormingTypeName } from '../../../../hooks/useFormingType';

const ScOdDataRow = ({ rowData, handleShowTransList }) => {
  const {
    transId,
    mainFormingType,
    formingType,
    initDate,
    updateDate,
    status,
    transQty,
    yieldRate,
    isFinishTesting,
    standardQty,
    efficiency,
    bucketNo,
    formingMachine,
    formingUser,
    aligningMachine,
    aligningUser,
    drillingUser,
    sinteringMachine,
    sinteringUser,
    grindingMachine,
    grindingUser,
    testingLine,
    eachFormHours,
    eachDrillHours,
    eachAlignHours,
    eachSinterHours,
    eachGrindHours,
    eachTestHours,
    theFormHours,
    theDrillHours,
    theAlignHours,
    theSinterHours,
    theGrindHours,
    theTestHours,
    packageId,
    platedMachine,
    platedAndGrindMachine,
  } = rowData;
  const commonClasses = useCommonStyles().classes;
  const { classes } = useStyles();

  const handleTransClick = (type, pkgId) => {
    const id = rowData.transId.slice(2, rowData.transId.length);
    if (type === receiptType.FORMING) {
      handleShowTransList(`TF${id}`, type);
    }
    if (type === receiptType.SINTERING) {
      handleShowTransList(`TH${id}`, type);
    }
    if (type === receiptType.GRINDING) {
      handleShowTransList(`TI${id}`, type);
    }
    if (type === receiptType.PACKAGING) {
      handleShowTransList(pkgId, type);
    }
  };

  return (
    <PaperContainer className={`${commonClasses.container} ${classes.scOd_container}`}>
      <div className={classes.scOd_trans}>
        <div className={classes.scOd_trans_row}>
          <Typography variant="subtitle1">移轉單資訊</Typography>
          <Typography variant="subtitle2">
            {useFormingTypeName(mainFormingType).name}移轉單號
          </Typography>
          <Typography variant="subtitle2">建立日期</Typography>
          <Typography variant="subtitle2">更新日期</Typography>
          <Typography variant="subtitle2">狀態</Typography>
          <Typography variant="subtitle2">移轉數量</Typography>
          <Typography variant="subtitle2">良率</Typography>
          {formingType === manufactureType.FORMING && (
            <>
              <Typography variant="subtitle2">標準產能</Typography>
              <Typography variant="subtitle2">效率</Typography>
            </>
          )}
        </div>
        <div className={classes.scOd_trans_row}>
          <p />
          <Typography variant="body1">{transId}</Typography>
          <Typography variant="body1">
            {initDate ? `${format(new Date(initDate), 'yyyy.MM.dd HH:mm:ss')}` : '-'}
          </Typography>
          <Typography variant="body1">
            {updateDate ? `${format(new Date(updateDate), 'yyyy.MM.dd HH:mm:ss')}` : '-'}
          </Typography>
          <Typography variant="body1">{status}</Typography>
          <Typography variant="body1">{transQty}</Typography>
          <Typography variant="body1">
            {yieldRate ? (!isFinishTesting ? `${yieldRate}%` : `${yieldRate}%`) : '-'}
          </Typography>
          {formingType === manufactureType.FORMING && (
            <>
              <Typography variant="body1">{standardQty}</Typography>
              <Typography variant="body1">
                {efficiency && efficiency !== 'NaN' ? `${efficiency}%` : '-'}
              </Typography>
            </>
          )}
        </div>
      </div>
      <Divider
        className={commonClasses.dashed_divider}
        style={{ marginTop: 10, marginBottom: 16 }}
      />
      <div className={classes.scOd_manuInfo}>
        {formingType !== manufactureType.ALIGNING ? (
          // 非排列的製造資訊：
          <>
            <div className={classes.scOd_manuInfo_row}>
              <Typography variant="subtitle1">製造資訊</Typography>
              <Typography variant="subtitle2">粉料桶號</Typography>
              <Typography variant="subtitle2">壓型機台</Typography>
              <Typography variant="subtitle2">
                {useFormingTypeName(mainFormingType).name}人員
              </Typography>
              <Typography variant="subtitle2">燒結爐號</Typography>
              <Typography variant="subtitle2">燒結人員</Typography>
              <Typography variant="subtitle2">研磨機台</Typography>
              <Typography variant="subtitle2">研磨人員</Typography>
              <Typography variant="subtitle2">檢裝線別</Typography>
              <Typography variant="subtitle2">檢裝單號</Typography>
            </div>
            <div className={classes.scOd_manuInfo_row}>
              <p />
              <Typography variant="body1">{bucketNo}</Typography>
              <Typography variant="body1">{formingMachine}</Typography>
              <Typography variant="body1">
                {useFormingTypeName(formingType).value === manufactureType.DRILLING && drillingUser
                  ? drillingUser
                  : formingUser || '-'}
              </Typography>
              <Typography variant="body1">{sinteringMachine || '-'}</Typography>
              <Typography variant="body1">{sinteringUser || '-'}</Typography>
              <Typography variant="body1">
                {grindingMachine && platedMachine && !platedAndGrindMachine
                  ? `${grindingMachine};${platedMachine}`
                  : grindingMachine && platedAndGrindMachine && !platedMachine
                  ? `${grindingMachine};${platedAndGrindMachine}`
                  : platedMachine && platedAndGrindMachine && !grindingMachine
                  ? `${platedMachine};${platedAndGrindMachine}`
                  : grindingMachine && platedMachine && platedAndGrindMachine
                  ? `${grindingMachine};${platedMachine};${platedAndGrindMachine}`
                  : grindingMachine || platedMachine || '-'}
              </Typography>
              <Typography variant="body1">{grindingUser || '-'}</Typography>
              <Typography variant="body1">{testingLine || '-'}</Typography>
              {packageId ? (
                <Typography
                  variant="body1"
                  className={classes.clickable}
                  onClick={() => handleTransClick(receiptType.PACKAGING, packageId)}
                >
                  {packageId}
                  <i className={classes.clickable_icon} />
                </Typography>
              ) : (
                <Typography variant="body1">-</Typography>
              )}
            </div>
          </>
        ) : (
          // 排列的製造資訊：
          <>
            <div className={classes.scOd_manuInfo_align_row}>
              <Typography variant="subtitle1">製造資訊</Typography>
              <Typography variant="subtitle2">粉料桶號</Typography>
              <Typography variant="subtitle2">壓型機台</Typography>
              <Typography variant="subtitle2">排列機台</Typography>
              <Typography variant="subtitle2">排列人員</Typography>
              <Typography variant="subtitle2">燒結爐號</Typography>
              <Typography variant="subtitle2">燒結人員</Typography>
            </div>
            <div className={classes.scOd_manuInfo_align_row} style={{ marginBottom: 4 }}>
              <p />
              <Typography variant="body1">{bucketNo}</Typography>
              <Typography variant="body1">{formingMachine}</Typography>
              <Typography variant="body1">{aligningMachine || '-'}</Typography>
              <Typography variant="body1">{aligningUser || '-'}</Typography>
              <Typography variant="body1">{sinteringMachine || '-'}</Typography>
              <Typography variant="body1">{sinteringUser || '-'}</Typography>
            </div>
            <div className={classes.scOd_manuInfo_align_row}>
              <p />
              <Typography variant="subtitle2">研磨機台</Typography>
              <Typography variant="subtitle2">研磨人員</Typography>
              <Typography variant="subtitle2">檢裝線別</Typography>
              <Typography variant="subtitle2">檢裝單號</Typography>
            </div>
            <div className={classes.scOd_manuInfo_align_row}>
              <p />
              <Typography variant="body1">
                {grindingMachine && platedMachine && !platedAndGrindMachine
                  ? `${grindingMachine};${platedMachine}`
                  : grindingMachine && platedAndGrindMachine && !platedMachine
                  ? `${grindingMachine};${platedAndGrindMachine}`
                  : platedMachine && platedAndGrindMachine && !grindingMachine
                  ? `${platedMachine};${platedAndGrindMachine}`
                  : grindingMachine && platedMachine && platedAndGrindMachine
                  ? `${grindingMachine};${platedMachine};${platedAndGrindMachine}`
                  : grindingMachine || platedMachine || '-'}
              </Typography>
              <Typography variant="body1">{grindingUser || '-'}</Typography>
              <Typography variant="body1">{testingLine || '-'}</Typography>
              {packageId ? (
                <Typography
                  variant="body1"
                  className={classes.clickable}
                  onClick={() => handleTransClick(receiptType.PACKAGING, packageId)}
                >
                  {packageId}
                  <i className={classes.clickable_icon} />
                </Typography>
              ) : (
                <Typography variant="body1">-</Typography>
              )}
            </div>
          </>
        )}
      </div>
      <Divider
        className={commonClasses.dashed_divider}
        style={{ marginTop: 10, marginBottom: 16 }}
      />
      <div className={classes.scOd_period}>
        <div className={classes.scOd_period_row}>
          <Typography variant="subtitle1">各站作業時間</Typography>
          <Typography variant="subtitle2">{useFormingTypeName(mainFormingType).name}</Typography>
          <Typography variant="subtitle2">燒結</Typography>
          <Typography variant="subtitle2">研磨</Typography>
          <Typography variant="subtitle2">檢裝</Typography>
        </div>
        <div className={classes.scOd_period_row}>
          <p />

          {eachFormHours >= 0 ? (
            <Typography
              variant="body1"
              className={classes.clickable}
              onClick={() => handleTransClick(receiptType.FORMING)}
            >
              {formingType === manufactureType.FORMING && (
                <>
                  {eachFormHours} hr
                  <i className={eachFormHours && classes.clickable_icon} />
                </>
              )}
              {formingType === manufactureType.DRILLING && (
                <>
                  {eachDrillHours} hr
                  <i className={eachDrillHours && classes.clickable_icon} />
                </>
              )}
              {formingType === manufactureType.ALIGNING && (
                <>
                  {eachAlignHours} hr
                  <i className={eachAlignHours && classes.clickable_icon} />
                </>
              )}
            </Typography>
          ) : (
            <Typography variant="body1">-</Typography>
          )}

          {eachSinterHours >= 0 ? (
            <Typography
              variant="body1"
              className={classes.clickable}
              onClick={() => handleTransClick(receiptType.SINTERING)}
            >
              {`${eachSinterHours} hr`}
              {<i className={eachSinterHours && classes.clickable_icon} />}
            </Typography>
          ) : (
            <Typography variant="body1">-</Typography>
          )}

          {eachGrindHours >= 0 ? (
            <Typography
              variant="body1"
              className={classes.clickable}
              onClick={() => handleTransClick(receiptType.GRINDING)}
            >
              {`${eachGrindHours} hr`}
              {<i className={eachGrindHours && classes.clickable_icon} />}
            </Typography>
          ) : (
            <Typography variant="body1">-</Typography>
          )}

          <Typography variant="body1">{eachTestHours ? `${eachTestHours} hr` : '-'}</Typography>
        </div>
      </div>
      <Divider
        className={commonClasses.dashed_divider}
        style={{ marginTop: 10, marginBottom: 16 }}
      />
      <div className={classes.scOd_period}>
        <div className={classes.scOd_period_row}>
          <Typography variant="subtitle1">該站停留時間</Typography>
          <Typography variant="subtitle2">{useFormingTypeName(mainFormingType).name}</Typography>
          <Typography variant="subtitle2">燒結</Typography>
          <Typography variant="subtitle2">研磨</Typography>
          <Typography variant="subtitle2">檢裝</Typography>
        </div>
        <div className={classes.scOd_period_row}>
          <p />
          <Typography variant="body1">
            {formingType === manufactureType.FORMING && (
              <>{theFormHours ? `${theFormHours} hr` : '-'}</>
            )}
            {formingType === manufactureType.DRILLING && (
              <>{theDrillHours ? `${theDrillHours} hr` : '-'}</>
            )}
            {formingType === manufactureType.ALIGNING && (
              <>{theAlignHours ? `${theAlignHours} hr` : '-'}</>
            )}
          </Typography>
          <Typography variant="body1">{theSinterHours ? `${theSinterHours} hr` : '-'}</Typography>
          <Typography variant="body1">{theGrindHours ? `${theGrindHours} hr` : '-'}</Typography>
          <Typography variant="body1">{theTestHours ? `${theTestHours} hr` : '-'}</Typography>
        </div>
      </div>
    </PaperContainer>
  );
};

ScOdDataRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleShowTransList: PropTypes.func,
};

export default ScOdDataRow;
