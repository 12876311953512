import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  content_container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'stretch',
  },
  filter_container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1.5),
    padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    minHeight: 440,
    height: '100%',
  },
  // filter_container_minHeight: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   marginBottom: theme.spacing(1.5),
  //   padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
  //   minHeight: 440,
  // },
  filter_content: {
    height: '100%',
  },
  finished_date: {
    marginBottom: theme.spacing(5),
  },
  finished_btn_div: {
    width: '100%',
    textAlign: 'right',
    margin: 'auto',
  },
  finished_btn: {
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: '10px',
    height: '3rem',
  },
  advance_filter_btn: {
    borderRadius: '20px',
    marginRight: theme.spacing(0.5),
    marginBottom: '10px',
    height: '3rem',
  },
  auto_complete_selection_div: {
    margin: theme.spacing(2),
  },
  auto_complete_selection: {
    margin: theme.spacing(1.25),
  },
  date_group: {
    display: 'flex',
    alignItems: 'center',
  },
  pareto_chart_container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1.5),
    height: '100%',
    minHeight: 445,
  },
  pareto_canvas: {
    marginTop: theme.spacing(2),
  },
  unfinished_date_grid: {
    width: 240,
    maxWidth: 240,
    marginRight: theme.spacing(3),
  },
  unfinished_date_picker: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(2),
    },
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(2),
    },
  },
  error_opacity: {
    color: 'transparent',
  },
  error: {
    transform: `translateY(-${theme.spacing(2)})`,
  },
  unfinished_btn: {
    marginBottom: theme.spacing(3),
    alignSelf: 'flex-end',
  },
  unfinished_advance_btn: {
    marginLeft: theme.spacing(2),
  },
  bar_chart_container: {
    marginTop: theme.spacing(3),
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    display: 'flex',
  },
  machine_table: {
    marginTop: theme.spacing(2),
  },
  machine_table_header: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    display: 'grid',
    gridTemplateColumns: `minmax(auto, 70px) minmax(auto, 120px) minmax(auto, 150px) minmax(auto, 150px) 1fr`,
    columnGap: theme.spacing(1),
  },
  machine_table_row: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    display: 'grid',
    gridTemplateColumns: `minmax(auto, 70px) minmax(auto, 120px) minmax(auto, 150px) minmax(auto, 150px) 1fr`,
    columnGap: theme.spacing(1),
  },
  advanced_pie_chart: {
    position: 'relative',
  },
  advanced_bar_chart: {
    position: 'relative',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(7),
  },
  back_btn: {
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'pointer',
    display: 'flex',
    width: 'fit-content',
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    color: alpha(theme.palette.common.black, 0.7),
    border: `1px solid currentColor`,
    borderRadius: 100,
    '& h6': {
      transition: 'all .4s ease-in-out',
      marginLeft: theme.spacing(1),
    },
    '&:hover h6': {
      marginLeft: theme.spacing(2),
    },
  },
  hide: {
    transform: `scale(0)`,
    visibility: 'hidden',
    transition: 'all .5s ease-in-out',
    height: 0,
    padding: 0,
  },
  show: {
    transform: `scale(1)`,
    visibility: 'visible',
    transition: `all .5s ease-in-out`,
  },
}));

export default useStyles;
