import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
import { orange } from '@mui/material/colors';

const useStyles = makeStyles()((theme) => ({
  date_group: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
  date_picker: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(2),
    },
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(2),
    },
  },
  filter_field_row: {
    marginTop: theme.spacing(3),
  },
  filter_field: {
    maxWidth: 255,
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(5.5),
    },
  },
  table_header: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    display: 'grid',
    width: '100%',
    gridTemplateColumns: `minmax(auto, 70px) 2fr 1fr 1fr minmax(auto, 60px) minmax(auto, 90px) minmax(auto, 60px) minmax(auto, 60px) minmax(auto, 80px) minmax(auto, 50px) minmax(auto, 50px)`,
    columnGap: theme.spacing(1),
  },
  table_row: {
    padding: `${theme.spacing(1.4)} ${theme.spacing(1)} ${theme.spacing(1)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    display: 'grid',
    width: '100%',
    gridTemplateColumns: `minmax(auto, 70px) 2fr 1fr 1fr minmax(auto, 60px) minmax(auto, 90px) minmax(auto, 60px) minmax(auto, 60px) minmax(auto, 80px) minmax(auto, 50px) minmax(auto, 50px)`,
    columnGap: theme.spacing(1),
    '& div': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  table_header_action: {
    justifySelf: 'end',
  },
  table_row_action: {
    justifySelf: 'end',
  },
  table_row_action_btn: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
    borderRadius: '50%',
    width: 27,
    height: 27,
    display: 'grid',
    cursor: 'pointer',
    padding: 0,
    minWidth: 'auto',
    '& svg': {
      margin: 'auto',
      width: 20,
      height: 20,
    },
  },
  filter_date_grid: {
    width: 240,
    maxWidth: 240,
    marginRight: theme.spacing(3),
  },
  filter_date_picker: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(2),
    },
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(2),
    },
  },
  error_opacity: {
    color: 'transparent',
  },
  error: {
    transform: `translateY(-${theme.spacing(2)})`,
  },
  filter_btn_container: {
    marginBottom: theme.spacing(3),
    width: `calc(50% - ${theme.spacing(10)})`,
    maxWidth: `calc(50% - ${theme.spacing(10)})`,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  filter_field_grid: {
    width: 240,
    maxWidth: 240,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  chart_container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chart_loading: {
    visibility: 'none',
    height: 0,
    '& canvas': {
      transform: `scaleY(0)`,
    },
  },
  chart_legend_container: {
    display: 'grid',
    gridTemplateColumns: `250px 250px`,
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: `80%`,
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  chart_legend: {
    display: 'flex',
    width: `100%`,
    marginBottom: theme.spacing(2),
    '&:not(nth-of-type(2n))': {
      marginRight: theme.spacing(3),
    },
  },
  chart_legend_label: {
    display: 'inline-block',
    width: theme.spacing(4),
    height: 2,
    transform: `translateY(${theme.spacing(1.2)})`,
    marginRight: theme.spacing(2),
    '&.ucl': {
      background: `repeating-linear-gradient(90deg,${orange[500]},${orange[500]} 3px,transparent 3px,transparent 7px)`,
    },
    '&.lcl': {
      background: `repeating-linear-gradient(90deg,${orange[500]},${orange[500]} 6.5px,transparent 6.5px,transparent 10px)`,
    },
    '&.upperSpec': {
      background: `repeating-linear-gradient(90deg,${theme.palette.error.main},${theme.palette.error.main} 3px,transparent 3px,transparent 7px)`,
    },
    '&.lowerSpec': {
      background: `repeating-linear-gradient(90deg,${theme.palette.error.main},${theme.palette.error.main} 6.5px,transparent 6.5px,transparent 10px)`,
    },
    '&.target': {
      background: `repeating-linear-gradient(90deg,${theme.palette.primary.main},${theme.palette.primary.main} 3px,transparent 3px,transparent 7px)`,
    },
  },
}));

export default useStyles;
