/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import useCommonStyles from '../../useStyles';
import { measureStyleType } from '../../../../constants/enums';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import { getSpec } from '../../../../Utils/measureSpecUtils';

const DBMeasureStyle = ({
  currentSubSpec,
  currentSpec,
  handleSubSpecChange,
  measureConfig,
  ampSpecConfig,
}) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const coilSpec = getSpec(currentSpec, measureStyleType.COIL).join(' / ');
  const diffCoilSpec = getSpec(currentSpec, measureStyleType.COIL, measureConfig, true).join(' / ');
  const ampSpec = currentSpec.filter((spec) => spec.styleId === measureStyleType.DB);
  const ampSettingConfig = []; // 設定檔所設定的
  ampSpec.forEach((spec) => {
    if (!spec.isEnabled) return;
    if (ampSettingConfig.includes(spec.specRecipe)) return;
    ampSettingConfig.push(spec.specRecipe);
  });
  const isRemove = ampSpecConfig.filter((e) => ampSettingConfig.indexOf(e) === -1);
  const displayAmp = [...ampSettingConfig, ...ampSpecConfig].filter(
    (item, i, arr) => arr.indexOf(item) === i
  ); // 要顯示在畫面上的

  return (
    <Grid container className={`${commonClasses.table_header} ${classes.measure_header}`}>
      {coilSpec && (
        <Typography variant="subtitle1" className={classes.measure_info}>
          <span>線圈設定：</span>
          <i>{coilSpec}</i>
          {diffCoilSpec && diffCoilSpec !== coilSpec && (
            <>
              <br />
              <span style={{ opacity: 0 }}>線圈設定：</span>
              <i className={classes.measure_info_disabled}>{diffCoilSpec}</i>
            </>
          )}
        </Typography>
      )}
      <Grid item xs={12} className={classes.db_selection}>
        <Typography variant="subtitle1">Amp： </Typography>
        {displayAmp.map((spec) =>
          isRemove.length !== 0 ? (
            isRemove.map((item) => (
              <Typography
                variant="subtitle1"
                className={`${classes.amp_selection} ${spec === currentSubSpec ? 'active' : ''} ${
                  spec === item ? classes.amp_selection_remove : ''
                }`}
                onClick={() => handleSubSpecChange(spec)}
              >
                {spec}
              </Typography>
            ))
          ) : (
            <Typography
              variant="subtitle1"
              className={`${classes.amp_selection} ${spec === currentSubSpec ? 'active' : ''}`}
              onClick={() => handleSubSpecChange(spec)}
            >
              {spec}
            </Typography>
          )
        )}
      </Grid>
    </Grid>
  );
};

DBMeasureStyle.propTypes = {
  currentSubSpec: PropTypes.string.isRequired,
  currentSpec: PropTypes.object.isRequired,
  handleSubSpecChange: PropTypes.func.isRequired,
  measureConfig: PropTypes.array.isRequired,
  ampSpecConfig: PropTypes.array.isRequired,
};

export default DBMeasureStyle;
