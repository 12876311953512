import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Chip from '../Chip/Chip';

const useStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.48,
    padding: `6px 5px 8px`,
    height: 37,
    borderRadius: 50,
  },
}));

const TransLabel = ({ label, color, ...rest }) => {
  const { classes } = useStyles();
  return <Chip label={label} color={color} className={classes.root} {...rest} />;
};

TransLabel.defaultProps = {
  color: 'secondary',
};

TransLabel.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default TransLabel;
