/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Paper from '../Paper/Paper';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    padding: `${theme.spacing(2.5)} ${theme.spacing(3.25)} ${theme.spacing(4)}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(3)} 0`,
    },
  },
  rounded: {
    '&.MuiPaper-root': {
      borderRadius: 10,
    },
  },
}));

const PaperContainer = React.forwardRef(
  ({ children, className, positionFixed, scrollObject, type }, ref) => {
    const { classes } = useStyles();
    return (
      <Paper
        className={
          type !== 'QCSinteringElectrical' ? `${classes.container} ${className}` : `${className}`
        }
        elevation={3}
        classes={{ rounded: classes.rounded }}
        ref={ref}
        sx={{ height: '100%' }}
        style={positionFixed && scrollObject}
      >
        {children}
      </Paper>
    );
  }
);

export default PaperContainer;
