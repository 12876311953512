import React from 'react';
import SentimentSatisfiedRoundedIcon from '@mui/icons-material/SentimentSatisfiedRounded';
import useStyles from './useStyles';
import PageTitle from '../../components/PageTitle/PageTitle';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import PaperContainer from '../../components/PaperContainer/PaperContainer';

const TransUpdateSuccess = () => {
  const { classes } = useStyles();
  return (
    <Grid container>
      <PageTitle title="更新移轉單成功" />
      <PaperContainer className={classes.result}>
        <div className={classes.icon_container}>
          <SentimentSatisfiedRoundedIcon color="secondary" className={classes.icon} />
        </div>
        <Typography variant="h5" color="secondary">
          Update successed
        </Typography>
        <Typography variant="h6" className={classes.upper_gap_sm}>
          資料更新成功！
        </Typography>
        <Typography variant="caption" className={`${classes.upper_gap_lg} ${classes.lower_gap_xl}`}>
          您可放心關閉此分頁⋯⋯
          <br />
          若要繼續更新移轉單，請重新掃描其他QRCode
        </Typography>
      </PaperContainer>
    </Grid>
  );
};

export default TransUpdateSuccess;
