/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { compareDesc, format } from 'date-fns';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import useForm from '../../../hooks/useForm';
import useCommonStyles from '../useStyles';
import useStyles from './useStyles';
import FilterInfo from './FilterInfo';
import ResultRow from './ResultRow';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Button from '../../../components/Button/Button';
import Snackbar from '../../../components/Snackbar/Snackbar';
import useAxios from '../../../hooks/useAxios';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import { qcExportMeasureStyle, receiptType, sinteringSizeType } from '../../../constants/enums';
import DetailDialog from './DetailDialog';
import useCancelToken from '../../../hooks/useCancelToken';

const QCExport = () => {
  const { dialogDispatch, infoDialogDispatch } = useContext(DialogContext);
  const initialFilterField = {
    startDate: new Date(),
    endDate: new Date(),
    measureStyle: '0',
    machine: '0',
    transId: '0',
    measureUser: '0',
    customer: '0',
    product: '0',
    workId: '',
  };
  const {
    formData: filterField,
    setFormData: setFilterField,
    formErr: filterFieldErr,
    setFormErr: setFilterFieldErr,
    resetFormData: resetFilterField,
    resetFormErr: resetFilterFieldErr,
  } = useForm(initialFilterField);
  const [machineSelection, setMachineSelection] = useState([]);
  const [filteredMachineSelection, setFilteredMachineSelection] = useState([]);
  const [transIdSelection, setTransIdSelection] = useState([]);
  const [filteredTransIdSelection, setFilteredTransIdSelection] = useState([]);
  const [measureUserSelection, setMeasureUserSelection] = useState([]);
  const [customerSelection, setCustomerSelection] = useState([]);
  const [productSelection, setProductSelection] = useState([]);
  const [filterResult, setFilterResult] = useState([]);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [openDetail, setOpenDetail] = useState(false);
  const [warningMsg, setWarningMsg] = useState('');
  const [useDateCheck, setUseDateCheck] = useState(true);
  const warningTimer = useRef(null);
  const downloadTimer = useRef([]);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();

  const handleDetailClose = () => {
    setOpenDetail(false);
  };

  const handleUseDateCheck = () => {
    setUseDateCheck((prev) => !prev);
  };

  const validationDate = () => {
    const { startDate, endDate } = filterField;
    if (compareDesc(startDate, endDate) < 0) {
      setFilterFieldErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      return false;
    }
    return true;
  };

  const validationWorkId = () => {
    const { workId } = filterField;
    if (workId.length <= 0) {
      setFilterFieldErr((prev) => ({
        ...prev,
        workId: true,
      }));
      return false;
    }
    return true;
  };

  const formatFilterDate = () => {
    const { startDate, endDate } = filterField;
    return {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    };
  };

  const getFilterResult = async (data) => {
    const cancleToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/qcCheckExport',
      data,
      true,
      cancleToken
    );
    if (status) {
      if (!result) return;
      const { transfer } = result;
      setFilterResult(
        transfer.map((row, idx) => {
          const { styleId, sizeType } = row;
          let measureStyleId = styleId === 0 ? qcExportMeasureStyle.FORMING : 0;
          if (sizeType && sizeType === 1)
            measureStyleId = qcExportMeasureStyle.SINTERING_INSPECTION;
          if (sizeType && sizeType === 2) measureStyleId = qcExportMeasureStyle.SIZE_ELECTRICAL;
          return {
            measureStyleId,
            rowId: idx + 1,
            checkId: row.checkId, // For detail api call
            styleId: row.styleId, // For detail api call
            transId: row.transId,
            customer: row.customerAlias,
            machine: row.machineName,
            product: row.prodName,
            measureStyle: row.styleName,
            measureUser: row.modifyUserName,
            updateTime: format(new Date(row.modifyDate), 'yyyy-MM-dd HH:mm:ss'),
            isChecked: false,
            isNGHistoryData: row.isNGHistoryData,
          };
        })
      );
      setShowResult(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得搜尋結果',
          message:
            noPrivilegeMsg ||
            '目前的篩選條件無法取得可匯出的QC單內容，請更改搜尋條件，或請聯絡網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  const handleDateFetch = () => {
    resetFilterFieldErr();
    if (!validationDate()) return;
    const { startDate, endDate } = formatFilterDate();
    const data = {
      checkBegin: startDate,
      checkEnd: endDate,
      isAdvanced: 0,
    };
    getFilterResult(data);
  };

  const handleWorkIdFetch = () => {
    resetFilterFieldErr();
    if (!validationWorkId()) return;
    const { workId } = filterField;
    const data = {
      scOdno: workId,
      isAdvanced: 0,
    };
    getFilterResult(data);
  };

  const handleAdvancedFetch = () => {
    resetFilterFieldErr();
    const { startDate, endDate } = formatFilterDate();
    const { measureStyle, machine, transId, measureUser, customer, product, workId } = filterField;
    const data = {
      isAdvanced: 1,
      machineId: machine !== '0' ? machine : 0,
      transId: transId !== '0' ? transId : '',
      modifyUserId: measureUser !== '0' ? measureUser : '',
      customerId: customer !== '0' ? customer : 0,
      prodName: product !== '0' ? product : '',
    };
    if (useDateCheck) {
      data.checkBegin = startDate;
      data.checkEnd = endDate;
    } else {
      data.scOdno = workId;
    }
    if (measureStyle !== '0') {
      const styleId = measureStyle === qcExportMeasureStyle.FORMING ? 0 : 1; // 0為壓型檢測，1為燒結相關檢測
      let sizeType;
      if (measureStyle !== '0') {
        if (measureStyle === qcExportMeasureStyle.SINTERING_INSPECTION) {
          sizeType = sinteringSizeType.INSPECTION;
        }
        if (measureStyle === qcExportMeasureStyle.SIZE_ELECTRICAL) {
          sizeType = sinteringSizeType.SIZE;
        }
      }
      data.styleId = styleId;
      data.sizeType = sizeType;
    }
    getFilterResult(data);
  };

  const handleRowCheck = (rowId) => {
    setFilterResult((prev) =>
      prev.map((row) => (row.rowId === rowId ? { ...row, isChecked: !row.isChecked } : row))
    );
  };

  const handleCheckAll = () => {
    const isCheckAll = checkAll;
    setCheckAll(!isCheckAll);
    setFilterResult((prev) =>
      prev.map((row) => ({
        ...row,
        isChecked: !isCheckAll,
      }))
    );
  };

  const resetCheckAll = () => {
    setCheckAll(false);
    setFilterResult((prev) =>
      prev.map((row) => ({
        ...row,
        isChecked: false,
      }))
    );
  };
  const removeDuplicate = (array) => {
    const result = array.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.place === value.place && t.name === value.name)
    );
    return result;
  };
  const getAdvancedSelection = async () => {
    if (!validationDate()) return;
    const { startDate, endDate } = formatFilterDate();
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/qcExportAdvanced',
      useDateCheck ? { checkBegin: startDate, checkEnd: endDate } : { scOdno: filterField.workId },
      true,
      cancelToken
    );
    if (status) {
      const { customer, machine, transfer, moduler, product } = result;
      setMachineSelection(
        machine.map((item) => ({
          machineType: item.receiptType,
          name: item.machineName,
          value: item.machineId,
          type: item.stationName,
        }))
      );
      setTransIdSelection(
        transfer.map((item) => ({
          name: item.transId,
          value: item.transId,
          type: item.stationName,
        }))
      );
      setFilteredTransIdSelection(
        removeDuplicate(
          transfer.map((item) => ({
            name: item.transId,
            value: item.transId,
            type: item.stationName,
          }))
        )
      );
      setMeasureUserSelection(
        removeDuplicate(
          moduler.map((item) => ({
            name: item.userName,
            value: item.userId,
          }))
        )
      );
      setCustomerSelection(
        removeDuplicate(
          customer.map((item) => ({
            name: item.customerAlias,
            value: item.customerId,
          }))
        )
      );
      setProductSelection(
        removeDuplicate(
          product.map((item) => ({
            name: item.prodName,
            value: item.prodName,
          }))
        )
      );
      setShowAdvanced(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得進階選項',
          message:
            noPrivilegeMsg ||
            '您所選的時間區間沒有相關的進階選項，請嘗試其他時間區間，或請通知網管人員！',
          singleBtn: true,
        },
      });
    }
  };
  const handleShowAdvancedFilter = (show) => {
    if (show) {
      getAdvancedSelection();
      return;
    }
    setShowAdvanced(show);
  };

  const handleDetailClick = (row) => {
    setOpenDetail(true);
    setCurrentRow(row);
  };

  const openWarningSnackbar = (msg) => {
    setWarningMsg(msg);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    clearTimeout(warningTimer.current);
    warningTimer.current = setTimeout(() => {
      setWarningMsg('');
      clearTimeout(warningTimer.current);
    }, 3000);
  };

  const validationExport = () => {
    const isValidExport = filterResult.some((row) => row.isChecked);
    if (!isValidExport) {
      openWarningSnackbar('請至少勾選一筆要匯出的QC單紀錄');
    }
    return isValidExport;
  };

  const downloadFile = (url) => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none'; // 防止影響頁面
    iframe.style.height = 0; // 防止影響頁面
    iframe.src = url;
    document.body.appendChild(iframe); // iframe掛載到DOM tree上才會發請求
    const timer = setTimeout(() => {
      iframe.remove();
      clearTimeout(timer);
    }, 1000);
    downloadTimer.current.push(timer);
  };

  const handleExport = async () => {
    if (!validationExport()) return;
    infoDialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '資料產生中',
        message: 'QC單紀錄資料產生中，請稍候⋯⋯',
        noBtn: true,
      },
    });
    const checkedResult = filterResult.filter((row) => row.isChecked);
    const data = {
      qc: checkedResult.map((row) => {
        let sizeType;
        if (row.measureStyleId === qcExportMeasureStyle.SINTERING_INSPECTION) {
          sizeType = sinteringSizeType.INSPECTION;
        }
        if (row.measureStyleId === qcExportMeasureStyle.SIZE_ELECTRICAL) {
          sizeType = sinteringSizeType.SIZE;
        }
        return {
          sizeType,
          styleId: row.styleId,
          checkId: row.checkId,
          transId: row.transId,
          isNGHistoryData: row.isNGHistoryData,
        };
      }),
      isExcel: 1,
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/qcExportDetail',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { url } = result;
      url.forEach((item) => downloadFile(item));
      infoDialogDispatch({
        type: dialogActions.CLOSE,
      });
      resetCheckAll();
    } else {
      infoDialogDispatch({
        type: dialogActions.CLOSE,
      });
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得匯出檔案',
          message:
            noPrivilegeMsg || '目前無法取得所選擇的QC單匯出檔案，請稍後再試，或請聯絡網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  useEffect(() => {
    const { measureStyle } = filterField;
    if (measureStyle !== '0') {
      if (measureStyle === qcExportMeasureStyle.FORMING) {
        setFilteredMachineSelection(
          removeDuplicate(
            machineSelection.filter((machine) => machine.machineType === receiptType.FORMING)
          )
        );
        setFilteredTransIdSelection(
          removeDuplicate(transIdSelection.filter((transId) => transId.type === '壓型檢測'))
        );
        return;
      }
      if (measureStyle === qcExportMeasureStyle.SINTERING_INSPECTION) {
        setFilteredMachineSelection(
          removeDuplicate(machineSelection.filter((machine) => machine.type === '燒結巡檢'))
        );
        setFilteredTransIdSelection(
          removeDuplicate(transIdSelection.filter((transId) => transId.type === '燒結巡檢'))
        );
        return;
      }
      if (measureStyle === qcExportMeasureStyle.SIZE_ELECTRICAL) {
        setFilteredMachineSelection(
          removeDuplicate(
            machineSelection.filter((machine) => machine.type === '燒結尺寸/電性檢測')
          )
        );
        setFilteredTransIdSelection(
          removeDuplicate(
            transIdSelection.filter((transId) => transId.type === '燒結尺寸/電性檢測')
          )
        );
      }
    }
  }, [filterField.measureStyle]);

  useEffect(() => {
    resetFilterFieldErr();
    resetFilterField();
    if (showAdvanced) {
      setShowAdvanced(false);
    }
  }, [useDateCheck]);

  useEffect(
    () => () => {
      clearTimeout(warningTimer.current);
      if (downloadTimer.current.length > 0) {
        downloadTimer.current.forEach((timer) => clearTimeout(timer));
      }
    },
    []
  );

  return (
    <div className={commonClasses.main}>
      <PageTitle title="匯出QC單" />
      <FilterInfo
        filterField={filterField}
        setFilterField={setFilterField}
        filterFieldErr={filterFieldErr}
        handleFetch={useDateCheck ? handleDateFetch : handleWorkIdFetch}
        handleAdvancedFetch={handleAdvancedFetch}
        machineSelection={filteredMachineSelection}
        transIdSelection={filteredTransIdSelection}
        measureUserSelection={measureUserSelection}
        customerSelection={customerSelection}
        productSelection={productSelection}
        showAdvanced={showAdvanced}
        handleShowAdvancedFilter={handleShowAdvancedFilter}
        useDateCheck={useDateCheck}
        handleUseDateCheck={handleUseDateCheck}
      />
      <Snackbar
        msg={warningMsg}
        open={warningMsg.length > 0}
        type="error"
        className={classes.error_snackbar}
      />
      {showResult && (
        <PaperContainer className={commonClasses.container}>
          <Typography variant="h6">勾選需要匯出的移轉單號</Typography>
          <Grid container className={commonClasses.content}>
            <div className={classes.table}>
              <div className={classes.table_header}>
                <div className={`${classes.table_column} ${classes.table_column_selection}`}>
                  <Checkbox
                    name="checkAll"
                    checked={checkAll}
                    handleChange={handleCheckAll}
                    customClasses={classes.table_selection}
                  />
                </div>
                <div className={`${classes.table_column} ${classes.transId_cell}`}>
                  <Typography variant="subtitle2">移轉單號</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.customer_cell}`}>
                  <Typography variant="subtitle2">客戶</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.product_cell}`}>
                  <Typography variant="subtitle2">產品</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.measure_cell}`}>
                  <Typography variant="subtitle2">量測型態</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.machine_cell}`}>
                  <Typography variant="subtitle2">機台</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.user_cell}`}>
                  <Typography variant="subtitle2">量測人員</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.update_cell}`}>
                  <Typography variant="subtitle2">更新時間</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.update_NG_cell}`}>
                  <Typography variant="subtitle2">是否NG</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.table_column_action}`}>
                  <Typography variant="subtitle2">Detail</Typography>
                </div>
              </div>
              <div className={classes.table_content}>
                {filterResult.map((row) => (
                  <ResultRow
                    row={row}
                    key={row.rowId}
                    handleRowCheck={handleRowCheck}
                    handleDetailClick={handleDetailClick}
                  />
                ))}
              </div>
            </div>
            <Grid item container xs={3} justifyContent="flex-end" className={classes.result_btn}>
              <Button
                variant="contained"
                color="primary"
                rounded
                startIcon={<GetAppRoundedIcon />}
                onClick={handleExport}
              >
                匯出
              </Button>
            </Grid>
          </Grid>
        </PaperContainer>
      )}
      <DetailDialog
        open={openDetail}
        handleDetailClose={handleDetailClose}
        currentRow={currentRow}
      />
    </div>
  );
};

export default QCExport;
