/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import useStyles from './useStyles';
import PageTitle from '../../components/PageTitle/PageTitle';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import TransLabel from '../../components/TransLabel/TransLabel';
import ReadonlyField from '../../components/ReadonlyField/ReadonlyField';
import Button from '../../components/Button/Button';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';
import useAxios from '../../hooks/useAxios';
import useCancelToken from '../../hooks/useCancelToken';

const TransUpdatePackaging = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const [transInfo, setTransInfo] = useState({
    transId: '-',
    workId: '-',
    elNo: '-',
    materialName: '-',
    productName: '-',
    passQty: '-',
  });
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { trans_id } = useParams();
  const { newCancelToken } = useCancelToken();

  const getTransData = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getPackage',
      { packageId: trans_id },
      true,
      cancelToken
    );
    if (status) {
      const {
        pkg: {
          checkPassQuantity,
          prodName,
          elNo,
          materialName,
          packageId,
          scOdno,
          checkProcessHours,
        },
      } = result;
      setTransInfo({
        elNo,
        materialName,
        transId: packageId,
        workId: scOdno,
        productName: prodName,
        passQty: checkPassQuantity ? checkPassQuantity.toString() : '-',
        time: checkProcessHours,
      });
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料讀取錯誤',
          message: noPrivilegeMsg || '請掃描有效之移轉單或確定正確掃描移轉單。',
        },
      });
    }
  };

  const handleConfirm = async () => {
    const { transId, passQty } = transInfo;
    const cancelToken = newCancelToken();
    if (!transInfo.time) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '全檢單尚未輸入工時',
          message: '全檢單尚未輸入工時，請填寫工時！',
        },
      });
      return;
    }
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/updatePackage',
      { pkg: { packageId: transId, packagePassQuantity: passQty === '-' ? 0 : passQty } },
      true,
      cancelToken
    );
    if (status) {
      navigate('/trans-update-success');
    } else {
      if (rtCode === 'E45') {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            singleBtn: true,
            title: '錯誤',
            message: noPrivilegeMsg,
          },
        });
        return;
      }
      navigate('/trans-update-failed');
    }
  };

  useEffect(() => {
    getTransData();
  }, []);

  return (
    <Grid container className={classes.root}>
      <PageTitle title="更新檢裝單（包裝）" />
      <PaperContainer>
        <div className={`${classes.content} ${classes.header}`}>
          <Typography variant="h6">移轉單資訊</Typography>
          <TransLabel label="包裝" />
        </div>
        <div className={classes.content}>
          <div className={classes.field}>
            <ReadonlyField
              label="移轉單號 / Trans nomor pesanan"
              value={transInfo.transId}
              name="transId"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="工令單號 / Nomor perintah kerja"
              value={transInfo.workId}
              name="workId"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField label="料號 / Bagian No" value={transInfo.elNo} name="elNo" />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="材質 / Bahan"
              value={transInfo.materialName}
              name="materialName"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="產品名稱 / Nama Produk"
              value={transInfo.productName}
              name="productName"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="檢測通過數量 / Jumlah lintasan"
              value={transInfo.passQty}
              name="passQty"
            />
          </div>
        </div>
        <div className={classes.confirm}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<CheckRoundedIcon />}
            onClick={handleConfirm}
          >
            送出
          </Button>
        </div>
      </PaperContainer>
    </Grid>
  );
};

export default TransUpdatePackaging;
