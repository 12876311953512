/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Typography from '../../../../components/Typography/Typography';
import Divider from '../../../../components/Divider/Divider';
import Dialog from '../../../../components/Dialog/Dialog';
import DialogTitle from '../../../../components/DialogTitle/DialogTitle';
import DialogContent from '../../../../components/DialogContent/DialogContent';
import DialogActions from '../../../../components/DialogActions/DialogActions';
import CircularProgress from '../../../../components/CircularProgress/CircularProgress';
import useStyles from './useStyles';
import useCommonStyles from '../../useStyles';
import useAxios from '../../../../hooks/useAxios';
import { CHART_COLORS } from '../../../../Utils/chartUtils';

const FinishedChartDialog = ({ open, handleChartClose, currentDefectType, currentFilterData }) => {
  const [isLoading, setInsLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [customerDataErr, setCustomerDataErr] = useState('');
  const [materialData, setMaterialDate] = useState([]);
  const [materialDataErr, setMaterialDataErr] = useState('');
  const [showBaseChart, setShowBaseChart] = useState(true);
  const [shapeData, setShapeData] = useState([]);
  const [shapeDataErr, setShapeDataErr] = useState('');
  const [showAdvancedChart, setShowAdvancedChart] = useState(true);
  const [specData, setSpecData] = useState([]);
  const [specDataErr, setSpecDataErr] = useState('');
  const customerChartEl = useRef(null);
  const customerChart = useRef(null);
  const materialChartEl = useRef(null);
  const materialChart = useRef(null);
  const shapeChartEl = useRef(null);
  const shapeChart = useRef(null);
  const specChartEl = useRef(null);
  const specChart = useRef(null);
  const currentMaterial = useRef(null);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;

  const resetDialog = () => {
    setCustomerData([]);
    setCustomerDataErr('');
    setMaterialDate([]);
    setMaterialDataErr('');
    setShowBaseChart(true);
    setShapeData([]);
    setShapeDataErr('');
    currentDefectType = '';
  };
  // Register the plugin to all charts:
  Chart.register(ChartDataLabels);
  const drawPieChart = (ctx, labels, dataset, title, detectPersent, onClick = null) =>
    new Chart(ctx, {
      data: {
        labels,
        datasets: [
          {
            type: 'pie',
            label: 'Defect數量',
            data: dataset,
            responsive: true,
            backgroundColor: CHART_COLORS,
            percentage: detectPersent,
          },
        ],
      },
      options: {
        onClick,
        aspectRatio: 2.2,
        plugins: {
          title: {
            display: true,
            font: {
              size: 14,
            },
            text: title,
          },
          legend: {
            display: true,
            position: 'top',
            labels: {
              generateLabels: (chart) => {
                const { data } = chart;
                return data.labels.map((label, i) => {
                  const custom = data.datasets[0];
                  const fill = custom.backgroundColor[i % CHART_COLORS.length];
                  const stroke = custom.borderColor;

                  return {
                    text: `${label}`,
                    fillStyle: fill,
                    strokeStyle: stroke,
                    lineWidth: 0,
                    index: i,
                  };
                });
              },
            },
          },
          datalabels: {
            display: true,
            formatter: (value, context) => context.dataset.percentage[context.dataIndex],
            backgroundColor: 'rgba(100,100,100,0.6)',
            borderColor: '#E8E9EB',
            color: '#ffffff',
            borderRadius: 25,
            padding: 6,
          },
        },
      },
    });

  const drawSpecBarChart = (spec) => {
    if (specChart.current) {
      specChart.current.destroy();
    }
    specChartEl.current = document.getElementById('specBarChart');
    const ctx = specChartEl.current.getContext('2d');
    specChart.current = new Chart(ctx, {
      data: {
        labels: Array.from(spec, (item) => item.elSize),
        datasets: [
          {
            type: 'bar',
            label: 'Defect數量',
            data: Array.from(spec, (item) => item.detectCount),
            backgroundColor: ['rgba(63, 81, 181, .2)'],
          },
        ],
      },
      options: {
        aspectRatio: 3,
      },
    });
  };

  const handleFetchSpecData = async (shape) => {
    const { materialName } = currentMaterial.current;
    const data = {
      ...currentFilterData,
      materialName,
      prodShade: shape.prodShade,
      detectId: currentDefectType.detectId,
    };
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios('/checkBadSize', data, true);
    if (status) {
      const { bad } = result;
      setSpecData(bad);
      drawSpecBarChart(bad);
    } else {
      setSpecDataErr(noPrivilegeMsg || `${result}：目前無法取得與 ${shape.prodShade} 相關的`);
    }
  };

  const drawShapePieChart = (shape) => {
    if (shapeChart.current) {
      shapeChart.current.destroy();
    }
    shapeChartEl.current = document.getElementById('shapePieChart');
    const ctx = shapeChartEl.current.getContext('2d');
    const labels = Array.from(shape, (item) => item.prodShade);
    const dataset = Array.from(shape, (item) => item.detectCount);
    const detectPersent = Array.from(
      shape,
      (item) => `${parseFloat(item.detectPersent * 100).toFixed(0)}%`
    );
    const title = '個別產品形狀所佔比例';
    const onClick = (e) => {
      const activeEl = shapeChart.current.getActiveElements(e);
      if (!activeEl[0]) return;
      if (activeEl[0] && activeEl[0].datasetIndex !== 0) return;
      const targetShape = shape[activeEl[0].index];
      setShowAdvancedChart(false);
      handleFetchSpecData(targetShape);
    };
    shapeChart.current = drawPieChart(ctx, labels, dataset, title, detectPersent, onClick);
  };

  const handleFetchShapeData = async () => {
    setShapeDataErr('');
    const { materialName } = currentMaterial.current;
    const data = { ...currentFilterData, materialName, detectId: currentDefectType.detectId };
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios('/checkBadShade', data, true);
    if (status) {
      const { bad } = result;
      setShapeData(bad);
      drawShapePieChart(bad);
    } else {
      setShapeDataErr(
        noPrivilegeMsg ||
          `${result}：目前無法取得與 ${materialName} 相關的產品形狀資料，請稍後再試，或請聯絡網管人員！`
      );
    }
  };

  const drawMaterialPieChart = (material) => {
    if (materialChart.current) {
      materialChart.current.destroy();
    }
    materialChartEl.current = document.getElementById('materialPieChart');
    const ctx = materialChartEl.current.getContext('2d');
    const labels = Array.from(material, (item) => item.materialName);
    const dataset = Array.from(material, (item) => item.detectCount);
    const detectPersent = Array.from(
      material,
      (item) => `${parseFloat(item.detectPersent * 100).toFixed(0)}%`
    );
    const title = '個別材質產品所佔比例';
    const onClick = (e) => {
      const activeEl = materialChart.current.getActiveElements(e);
      if (!activeEl[0]) return;
      if (activeEl[0] && activeEl[0].datasetIndex !== 0) return;
      const targetMaterial = material[activeEl[0].index];
      currentMaterial.current = targetMaterial;
      setShowBaseChart(false);
      handleFetchShapeData();
    };
    materialChart.current = drawPieChart(ctx, labels, dataset, title, detectPersent, onClick);
  };

  const handleFetchMaterialData = async () => {
    setMaterialDataErr('');
    const data = { ...currentFilterData, detectId: currentDefectType.detectId };
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkBadMaterial',
      data,
      true
    );
    if (status) {
      const { bad } = result;
      setMaterialDate(bad);
    } else {
      setMaterialDataErr(
        noPrivilegeMsg ||
          `${result}：目前無法取得與 ${currentDefectType.detectName} 相關的材質資料，請稍後再試，或請通知網管人員！`
      );
    }
  };

  const drawCustomerPieChart = (customer) => {
    if (customerChart.current) {
      customerChart.current.destroy();
    }
    customerChartEl.current = document.getElementById('customerPieChart');
    const ctx = customerChartEl.current.getContext('2d');
    const labels = Array.from(customer, (item) => item.customerAlias);
    const dataset = Array.from(customer, (item) => item.detectCount);
    const detectPersent = Array.from(
      customer,
      (item) => `${parseFloat(item.detectPersent * 100).toFixed(0)}%`
    );
    const title = '個別客戶所佔比例';
    customerChart.current = drawPieChart(ctx, labels, dataset, title, detectPersent);
  };

  const handleFetchCustomerData = async () => {
    setCustomerDataErr('');
    const data = { ...currentFilterData, detectId: currentDefectType.detectId };
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkBadCustomer',
      data,
      true
    );
    if (status) {
      const { bad } = result;
      setCustomerData(bad);
    } else {
      setCustomerDataErr(
        noPrivilegeMsg ||
          `${result}：目前無法取得與 ${currentDefectType.detectName} 有關的客戶資料, 請稍後再試，或請聯絡網管人員！`
      );
    }
  };

  const handleBackBaseChart = () => {
    setShowBaseChart(true);
    setShapeData([]);
    setShapeDataErr('');
  };

  const handleBackAdvancedChart = () => {
    setShowAdvancedChart(true);
    setSpecData([]);
    setSpecDataErr('');
  };

  useEffect(() => {
    if (!open) {
      resetDialog();
      return;
    }
    handleFetchCustomerData();
    handleFetchMaterialData();
  }, [open]);

  useEffect(() => {
    if (customerData.length === 0) return;
    drawCustomerPieChart(customerData);
  }, [customerData]);

  useEffect(() => {
    if (materialData.length === 0) return;
    drawMaterialPieChart(materialData);
  }, [materialData]);

  useEffect(
    () => () => {
      if (customerChart.current) {
        customerChart.current.destroy();
      }
      if (materialChart.current) {
        materialChart.current.destroy();
      }
      if (shapeChart.current) {
        shapeChart.current.destroy();
      }
      if (specChart.current) {
        specChart.current.destroy();
      }
    },
    []
  );

  return (
    <div className={classes.dialog}>
      <Dialog open={open} onClose={handleChartClose} fullWidth maxWidth="lg">
        <DialogTitle
          id="detail-title"
          onClose={handleChartClose}
          className={commonClasses.detail_header}
        >
          {currentDefectType && currentDefectType.detectName}｜進階分析
        </DialogTitle>
        <DialogContent
          dividers
          className={commonClasses.detail_content}
          style={{ paddingTop: '36px', paddingBottom: '36px' }}
        >
          <div className={!showBaseChart ? classes.hide : classes.show}>
            {customerData.length > 0 && <canvas id="customerPieChart" width="600" height="400" />}
            {customerDataErr.length > 0 && (
              <Typography variant="subtitle1">{customerDataErr}</Typography>
            )}
            <Divider className={commonClasses.dashed_divider} style={{ margin: `40px 50px` }} />
            {materialData.length > 0 && <canvas id="materialPieChart" width="600" height="400" />}
            {materialDataErr.length > 0 && (
              <Typography variant="subtitle1">{materialDataErr}</Typography>
            )}
          </div>
          <div
            className={`${classes.advanced_pie_chart} ${
              !showBaseChart && showAdvancedChart ? classes.show : classes.hide
            }`}
          >
            <div className={classes.back_btn} onClick={handleBackBaseChart}>
              <ArrowBackRoundedIcon />
              <Typography variant="subtitle1">返回上一層</Typography>
            </div>
            <canvas id="shapePieChart" width="600" height="400" />
          </div>
          <div
            className={`${classes.advanced_bar_chart} ${
              !showBaseChart && !showAdvancedChart ? classes.show : classes.hide
            }`}
          >
            <div className={classes.back_btn} onClick={handleBackAdvancedChart}>
              <ArrowBackRoundedIcon />
              <Typography variant="subtitle1">返回上一層</Typography>
            </div>
            <canvas id="specBarChart" width="600" height="400" />
          </div>

          {isLoading && <CircularProgress mt={7.5} />}
        </DialogContent>
        <DialogActions className={commonClasses.detail_footer} onClick={handleChartClose}>
          確認
        </DialogActions>
      </Dialog>
    </div>
  );
};

FinishedChartDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleChartClose: PropTypes.func.isRequired,
  currentDefectType: PropTypes.string.isRequired,
  currentFilterData: PropTypes.object.isRequired,
};

export default FinishedChartDialog;
