/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Typography from '../../../components/Typography/Typography';
import Dialog from '../../../components/Dialog/Dialog';
import DialogTitle from '../../../components/DialogTitle/DialogTitle';
import DialogContent from '../../../components/DialogContent/DialogContent';
import DialogActions from '../../../components/DialogActions/DialogActions';
import CircularProgress from '../../../components/CircularProgress/CircularProgress';
import useStyles from './useStyles';
import useCommonStyles from '../useStyles';
import { receiptType, reportProgressType } from '../../../constants/enums';

const transTypeName = {
  [receiptType.FORMING]: '壓型移轉單列表',
  [receiptType.DRILLING]: '鑽孔移轉單列表',
  [receiptType.ALIGNING]: '排列移轉單列表',
  [receiptType.SINTERING]: '燒結移轉單列表',
  [receiptType.GRINDING]: '研磨移轉單列表',
  [receiptType.TESTING]: '檢測移轉單列表',
};

const TransListDialog = ({
  open,
  handleTransListDialogClose,
  targetScOdNo,
  transType,
  transList,
  type,
}) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (transList) {
      setIsLoading(false);
    }

    if (!open) {
      setIsLoading(true);
    }
  }, [transList, open]);

  return (
    <>
      {open && (
        <div className={classes.dialog}>
          <Dialog
            open={open}
            onClose={handleTransListDialogClose}
            fullWidth
            maxWidth="md"
            // maxWidth={type === reportProgressType.OD_NO_INFO ? 'sm' : 'md'}
          >
            <DialogTitle
              id="detail-title"
              onClose={handleTransListDialogClose}
              className={commonClasses.detail_header}
            >
              {type === reportProgressType.DELIVERY_PROGRESS &&
                `${targetScOdNo}｜${transTypeName[transType]}`}
              {type === reportProgressType.SOURCE_ORDER_PROGRESS &&
                `${targetScOdNo}｜${transTypeName[transType].slice(0, 2)}`}
              {type === reportProgressType.OD_NO_INFO && `工令之生產批號資訊`}
              {type === reportProgressType.PACKAGE_SEPARATION && `${targetScOdNo}｜拆批檢裝單列表`}
            </DialogTitle>

            {isLoading && (
              <DialogContent dividers>
                <CircularProgress mt={7.5} mb={7.5} />
              </DialogContent>
            )}

            {!isLoading && type === reportProgressType.DELIVERY_PROGRESS && (
              <DialogContent dividers>
                <div className={classes.trans_list}>
                  <div className={classes.trans_list_header}>
                    <Typography variant="subtitle1">移轉單號</Typography>
                    <Typography variant="subtitle1">移轉數量</Typography>
                    <Typography variant="subtitle1">
                      {transType === receiptType.DRILLING
                        ? '鑽孔'
                        : transType === receiptType.ALIGNING
                        ? '排列'
                        : '壓型'}
                      更新時間
                    </Typography>
                    <Typography variant="subtitle1">最後一站更新時間</Typography>
                  </div>
                  {transList.length !== 0 &&
                    transList.map((row) => (
                      <div className={classes.trans_list_row} key={row.id}>
                        <Typography variant="body1">{row.transId}</Typography>
                        <Typography variant="body1">{row.transQty}</Typography>
                        <Typography variant="body1">
                          {row.formingDate
                            ? format(new Date(row.formingDate), 'yyyy-MM-dd HH:mm:ss')
                            : '-'}
                        </Typography>
                        <Typography variant="body1">
                          {row.lastUpdateDate
                            ? format(new Date(row.lastUpdateDate), 'yyyy-MM-dd HH:mm:ss')
                            : '-'}
                        </Typography>
                      </div>
                    ))}
                </div>
              </DialogContent>
            )}

            {!isLoading && type === reportProgressType.SOURCE_ORDER_PROGRESS && (
              <DialogContent dividers>
                <div className={classes.trans_list}>
                  <div
                    className={`${classes.trans_list_header} ${classes.trans_list_time_reason_gridTemplateColumns}`}
                  >
                    <Typography variant="subtitle1">暫停原因</Typography>
                    <Typography variant="subtitle1">起始時間</Typography>
                    <Typography variant="subtitle1">結束時間</Typography>
                    <Typography variant="subtitle1">總耗時間</Typography>
                  </div>
                  {transList.length !== 0 ? (
                    transList.map((row) => (
                      <div
                        className={`${classes.trans_list_row} ${classes.trans_list_time_reason_gridTemplateColumns}`}
                        key={row.id}
                      >
                        <Typography variant="body1">{row.reason}</Typography>
                        <Typography variant="body1">
                          {format(new Date(row.startTime), 'yyyy-MM-dd HH:mm')}
                        </Typography>
                        <Typography variant="body1">
                          {format(new Date(row.endTime), 'yyyy-MM-dd HH:mm')}
                        </Typography>
                        <Typography variant="body1">{row.totalTime}</Typography>
                      </div>
                    ))
                  ) : (
                    <div style={{ marginTop: 50, textAlign: 'center' }}>
                      <Typography variant="subtitle1">目前沒有暫停原因資料</Typography>
                    </div>
                  )}
                </div>
              </DialogContent>
            )}

            {!isLoading && type === reportProgressType.OD_NO_INFO && (
              <DialogContent dividers>
                <div className={classes.trans_list}>
                  <div
                    className={`${classes.trans_list_header} ${classes.trans_list_odNo_gridTemplateColumns}`}
                  >
                    <Typography variant="subtitle1">生產批號</Typography>
                    <Typography variant="subtitle1">排產數量</Typography>
                    <Typography variant="subtitle1">交期</Typography>
                  </div>
                  {transList.length !== 0 ? (
                    transList.map((row) => (
                      <div
                        className={`${classes.trans_list_row} ${classes.trans_list_odNo_gridTemplateColumns}`}
                        key={row.id}
                      >
                        <Typography variant="body1">{row.odNo}</Typography>
                        <Typography variant="body1">{row.odQty}</Typography>
                        <Typography variant="body1">{row.odReDate}</Typography>
                      </div>
                    ))
                  ) : (
                    <div style={{ marginTop: 50, textAlign: 'center' }}>
                      <Typography variant="subtitle1">目前沒有工令之生產批號資料</Typography>
                    </div>
                  )}
                </div>
              </DialogContent>
            )}

            {!isLoading && type === reportProgressType.PACKAGE_SEPARATION && (
              <DialogContent dividers>
                <div className={classes.trans_list}>
                  <div
                    className={`${classes.trans_list_header} ${classes.trans_list_package_gridTemplateColumns}`}
                  >
                    <Typography variant="subtitle1">檢裝單號</Typography>
                    <Typography variant="subtitle1">檢裝數量</Typography>
                    <Typography variant="subtitle1">狀態</Typography>
                    <Typography variant="subtitle1">更新時間</Typography>
                  </div>
                  {transList.length !== 0 ? (
                    transList.map((row) => (
                      <div
                        className={`${classes.trans_list_row} ${classes.trans_list_package_gridTemplateColumns}`}
                        key={row.id}
                      >
                        <Typography variant="body1">{row.packageId}</Typography>
                        <Typography variant="body1">{row.packageQty}</Typography>
                        <Typography variant="body1">
                          {row.checkStatus ? '已送出' : '未送出'}
                        </Typography>
                        <Typography variant="body1">
                          {row.modifyDate
                            ? format(new Date(row.modifyDate), 'yyyy-MM-dd HH:mm')
                            : '-'}
                        </Typography>
                      </div>
                    ))
                  ) : (
                    <div style={{ marginTop: 50, textAlign: 'center' }}>
                      <Typography variant="subtitle1">目前沒有拆批號資料</Typography>
                    </div>
                  )}
                </div>
              </DialogContent>
            )}

            <DialogActions
              className={commonClasses.detail_footer}
              onClick={handleTransListDialogClose}
            >
              確認
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

TransListDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleTransListDialogClose: PropTypes.func.isRequired,
  targetScOdNo: PropTypes.string.isRequired,
  transType: PropTypes.number.isRequired,
  transList: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default TransListDialog;
