/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import FaceRoundedIcon from '@mui/icons-material/FaceRounded';
import LineLogo from '../../images/line_logo.png';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import Button from '../../components/Button/Button';
import useStyles from './useStyles';
import { AuthContext } from '../../contexts/AuthContext';

const Login = () => {
  const { logout, checkLoginStatus } = useContext(AuthContext);
  const { classes } = useStyles();

  const handleClick = () => {
    const lineAuth = localStorage.getItem('lineParam');
    // const { id, url, state } = JSON.parse(lineAuth);
    const { id, state } = JSON.parse(lineAuth);
    const url = `${process.env.REACT_APP_BASE_URL}/`;
    if (process.env.REACT_APP_PROJECT_PHASE === 'DEVELOPMENT') {
      window.location.href = `${process.env.REACT_APP_BASE_URL}?devLogin`;
    } else {
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${id}&redirect_uri=${url}&state=${state}&scope=profile%20openid%20email&nonce=qcUpdate&max_age=180`;
    }
  };

  useEffect(() => {
    const targetPath = localStorage.getItem('targetPath') || '/';
    if (checkLoginStatus()) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#${targetPath}`;
    } else {
      logout();
    }
  }, []);

  return (
    <Grid container className={classes.root} justifyContent="center">
      {!checkLoginStatus() && (
        <PaperContainer className={classes.container}>
          <Typography variant="h6" className={classes.text_desktop}>
            您尚未登入或已被登出，請使用Line帳號登入
          </Typography>
          <div className={classes.mobile}>
            <div className={classes.icon_container}>
              <FaceRoundedIcon color="primary" className={classes.icon} />
            </div>
            <Typography variant="h5" color="primary" className={classes.text_mobile}>
              Please login...
            </Typography>
            <Typography variant="h6" className={classes.text_mobile}>
              您尚未登入或已被登出⋯
            </Typography>
            <Typography variant="caption" className={classes.text_meta}>
              若要繼續使用系統，請使用Line帳號登入
            </Typography>
          </div>
          <Button variant="contained" customClasses={classes.lineLogin} onClick={handleClick}>
            <span className={classes.lineLogo}>
              <img src={LineLogo} alt="Line Login" />
            </span>
            使用Line登入
          </Button>
        </PaperContainer>
      )}
    </Grid>
  );
};

export default Login;
