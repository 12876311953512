/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import useStyles from './useStyles';
import Typography from '../Typography/Typography';

const PopperComponent = (props) => (
  <Popper {...props} style={{ width: 500 }} placement="bottom-start" />
);

const SelectAutoComplete = ({
  selections,
  label,
  value,
  handleChange,
  onFocus,
  id,
  className,
  setConfigInfo,
  placeholder,
  readOnly,
  readOnlyStyles,
  ...rest
}) => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = useState('');
  const inputEl = useRef(null);
  return (
    <FormControl
      variant="outlined"
      className={`${classes.formControl} ${className} ${readOnly && classes.readyOnly}`}
      fullWidth
      {...rest}
    >
      <InputLabel id={id} shrink className={readOnly ? classes.readyOnlyLabel : classes.label}>
        {label}
      </InputLabel>
      <Autocomplete
        openOnFocus
        options={selections}
        onChange={(e, data) => {
          handleChange(data);
        }}
        onFocus={onFocus}
        inputValue={value || inputValue || ''}
        onInputChange={(e, v, reason) => {
          if (e && e.type === 'blur') {
            setInputValue('');
          } else if (reason !== 'reset') {
            setInputValue(v);
          }
        }}
        renderTags={(selected) => (
          <Typography
            onClick={(e) => {
              inputEl.current.focus();
              e.stopPropagation();
            }}
            noWrap
            sx={{
              paddingRight: '1rem',
            }}
          >
            {selected.map((item) => item.name).join(', ')}
          </Typography>
        )}
        getOptionLabel={(option) => option.name}
        PopperComponent={PopperComponent}
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.id}>
            {option.name}
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            placeholder={readOnly ? '' : placeholder}
            name={id}
            inputRef={inputEl}
            variant={readOnly ? 'standard' : 'outlined'}
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                style: {
                  minWidth: 'auto',
                  textOverflow: 'initial',
                },
              },
              sx: {
                '&.MuiInputBase-root.MuiAutocomplete-inputRoot': {
                  paddingLeft: '1rem',
                  paddingRight: '2rem',
                  display: 'inline-flex',
                  flexWrap: 'nowrap',
                },
              },
            }}
          />
        )}
        sx={readOnlyStyles}
        readOnly={readOnly}
      />
    </FormControl>
  );
};
SelectAutoComplete.defaultProps = {
  className: '',
  readOnly: false,
  readOnlyStyles: {},
};
SelectAutoComplete.propTypes = {
  selections: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  setConfigInfo: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  readOnlyStyles: PropTypes.object,
  onFocus: PropTypes.func.isRequired,
};
export default SelectAutoComplete;
