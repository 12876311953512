/* eslint-disable no-lonely-if */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext, useRef, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CreateIcon from '@mui/icons-material/Create';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import Button from '../../../components/Button/Button';
import Grid from '../../../components/Grid/Grid';
import InputField from '../../../components/InputField/InputField';
import ReadonlyField from '../../../components/ReadonlyField/ReadonlyField';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import Typography from '../../../components/Typography/Typography';
import useStyles from '../useStyles';
import useAxios from '../../../hooks/useAxios';
import useForm from '../../../hooks/useForm';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useCancelToken from '../../../hooks/useCancelToken';
import regex from '../../../constants/validationRegex';
import PrivilegeCheckbox from './PrivilegeCheckbox';
import checkboxReducer, { checkboxActions } from '../../../reducers/checkboxReducer';

const SettingPrivilegeStaff = ({ selectedTab, setStatusMsg, setShowStatus }) => {
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();
  const [adminNo, setAdminNo] = useState('');
  const [adminNoErr, setAdminNoErr] = useState(false);
  const [adminNoErrMsg, setAdminNoErrMsg] = useState('');
  const [isMySelf, setIsMySelf] = useState(false);
  const statusTimer = useRef(null);

  const initialForm = {
    userId: '',
    userName: '-',
    userInDate: '-',
    userLineData: '',
    userLineId: '-',
    isEnable: 1,
    roleList: [],
  };
  const {
    formData: privilegeForm,
    setFormData: setPrivilegeForm,
    formErr,
    setFormErr,
    resetFormErr,
    resetFormData,
  } = useForm(initialForm);

  // 設定
  const [checkboxItems, checkboxDispatch] = useReducer(checkboxReducer, []);
  // 檢視
  const roleListView = useRef([]);

  const resetData = () => {
    resetFormErr();
    resetFormData();
    setAdminNo('');
    setAdminNoErr(false);
    setIsMySelf(false);
  };

  const handleInputChange = (e) => {
    setAdminNo(e.target.value);
    if (adminNoErr) {
      setAdminNoErr(false);
    }
  };

  const handleEmailChange = (e) => {
    resetFormErr();
    setPrivilegeForm((prev) => ({
      ...prev,
      userLineData: e.target.value,
    }));
  };

  const handleUserLineIdChange = (e) => {
    resetFormErr();
    setPrivilegeForm((prev) => ({
      ...prev,
      userLineId: e.target.value,
    }));
  };

  const validationAdminNo = () => {
    if (adminNo.length === 0) {
      setAdminNoErr(true);
      setAdminNoErrMsg('請輸入正確的員工編號');
      return false;
    }
    const currentUserId = JSON.parse(localStorage.getItem('currentUser')).userId;
    if (adminNo === currentUserId) {
      setIsMySelf(true);
    } else {
      setIsMySelf(false);
    }
    return true;
  };

  const handleFetch = async () => {
    if (!validationAdminNo()) return;
    resetFormData();
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/userData',
      { userId: adminNo, isNew: selectedTab === 0 ? 1 : 0 },
      true,
      cancelToken
    );
    if (status) {
      const { userData, fullRoleList, roleList } = result;
      const { userName, userId, userInDate, userLineData, userLineId } = userData;
      if (!userLineData && !userLineId && selectedTab !== 0) {
        setAdminNoErr(true);
        setAdminNoErrMsg('請先至【新增員工】分頁將該員工資料新增至此系統！');
        return;
      }
      setPrivilegeForm((prev) => ({
        ...prev,
        userName,
        userId,
        userInDate,
        userLineData: userLineData || '',
        userLineId: userLineId || '',
        roleList,
      }));
      checkboxDispatch({
        type: checkboxActions.INIT,
        init: fullRoleList.map((list) => ({
          name: list.roleId,
          label: list.roleName,
          checked: list.isSelected,
        })),
      });
      roleListView.current = roleList.map((list) => ({
        name: list.roleId,
        label: list.roleName,
      }));
    } else {
      setAdminNoErr(true);
      setAdminNoErrMsg(noPrivilegeMsg || '找不到資料，請確認輸入正確的員工編號');
    }
  };

  const validationPrivilegeForm = (pass) => {
    if (pass) return true;
    const { userLineData, userLineId, userName } = privilegeForm;
    if (userLineData.length === 0 && userLineId.length === 0) {
      setFormErr((prev) => ({
        ...prev,
        userLineData: true,
      }));
      setFormErr((prev) => ({
        ...prev,
        userLineId: true,
      }));
      return false;
    }
    if (userLineData.length !== 0 && !regex.EMAIL.test(userLineData)) {
      setFormErr((prev) => ({
        ...prev,
        userLineData: true,
      }));
      return false;
    }
    const isEmptyPrivilege = checkboxItems.every((item) => !item.checked);
    if (isEmptyPrivilege && selectedTab !== 2) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '是否確定送出？',
          message: `您是否確定要將 ${userName} 的權限設為全部取消？`,
          cancelText: '返回',
          handleConfirm: () => handleConfirm(true),
        },
      });
      return false;
    }
    if (selectedTab === 2) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '是否確定刪除？',
          message: `您是否確定要將 ${userName} 的權限刪除？`,
          handleConfirm: () => handleConfirm(true),
        },
      });
      return false;
    }
    return true;
  };

  const handleConfirm = async (pass = false) => {
    if (!validationPrivilegeForm(pass)) return;
    const { userId, userName, userInDate, userLineData, userLineId } = privilegeForm;
    const cancelToken = newCancelToken();
    const roleList = checkboxItems
      .filter((role) => role.checked)
      .map((item) => ({
        roleId: item.name,
        roleName: item.label,
      }));
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/userSetting',
      {
        userData: {
          userId,
          userName,
          userInDate: userInDate ? userInDate.replaceAll('/', '-') : '',
          userLineData,
          userLineId,
          isEnabled: selectedTab === 2 ? 0 : 1,
        },
        roleList,
      },
      true,
      cancelToken
    );
    if (status) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      if (selectedTab === 0) {
        setStatusMsg('新增員工設定成功！');
        resetData();
      }
      if (selectedTab === 1) {
        setStatusMsg('修改員工權限設定成功！');
        roleListView.current = roleList.map((list) => ({
          name: list.roleId,
          label: list.roleName,
        }));
      }
      if (selectedTab === 2) {
        setStatusMsg('刪除員工成功！');
        resetData();
      }
      setShowStatus(true);
      clearTimeout(statusTimer.current);
      statusTimer.current = setTimeout(() => {
        setShowStatus(false);
        clearTimeout(statusTimer.current);
      }, 3000);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料更新失敗！',
          message: noPrivilegeMsg || '資料更新失敗，請稍後再試⋯⋯',
        },
      });
    }
  };

  useEffect(() => {
    resetData();
  }, [selectedTab]);

  useEffect(
    () => () => {
      clearTimeout(statusTimer.current);
    },
    []
  );

  return (
    <>
      <PaperContainer className={classes.container}>
        <Typography variant="h6">查詢員工資訊</Typography>
        <Grid container style={{ marginTop: 22 }} spacing={3} alignItems="flex-end">
          <Grid item xs={5}>
            <InputField
              id="adminNo"
              label="員工編號"
              value={adminNo}
              variant="outlined"
              onChange={handleInputChange}
              placeholder="請輸入員工編號"
              name="adminNo"
              type="text"
              error={adminNoErr}
              errormsg={adminNoErrMsg}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SearchRoundedIcon />}
              onClick={handleFetch}
              customClasses={adminNoErr && classes.error}
            >
              查詢
            </Button>
          </Grid>
        </Grid>
      </PaperContainer>
      {privilegeForm.userId.length !== 0 && (
        <>
          <PaperContainer className={classes.container}>
            <Typography variant="h6">員工資訊綁定</Typography>
            <Grid
              container
              style={{
                marginTop: selectedTab !== 2 ? 16 : 22,
                marginBottom: selectedTab !== 2 ? 20 : 1,
              }}
              spacing={selectedTab !== 2 ? 5 : 3}
              alignItems="flex-end"
            >
              <Grid item xs={selectedTab !== 2 ? 4 : 3}>
                <ReadonlyField label="員工姓名" value={privilegeForm.userName} name="userName" />
              </Grid>
              <Grid item xs={selectedTab !== 2 ? 4 : 3}>
                <ReadonlyField
                  label="到職日期"
                  value={privilegeForm.userInDate}
                  name="userInDate"
                />
              </Grid>
            </Grid>
            {selectedTab !== 2 && (
              <Grid container spacing={5} alignItems="flex-start">
                <Grid xs={4}>
                  <InputField
                    id="userLineData"
                    label="Line Email"
                    value={privilegeForm.userLineData}
                    variant="outlined"
                    onChange={handleEmailChange}
                    placeholder="請輸入要綁定的Line Email"
                    name="userLineData"
                    type="email"
                    error={formErr.userLineData}
                    errormsg="Line userID 與Line Email請至少輸入一個，並請輸入正確的Email"
                    disabled={isMySelf}
                  />
                </Grid>
                <Grid xs={4} className={classes.user_lineId}>
                  <InputField
                    id="userLineId"
                    label="Line userID"
                    value={privilegeForm.userLineId}
                    variant="outlined"
                    onChange={handleUserLineIdChange}
                    placeholder="請輸入要綁定的Line userID"
                    name="userLineId"
                    type="text"
                    error={formErr.userLineId}
                    errormsg="Line userID 與Line Email請至少輸入一個"
                    disabled={isMySelf}
                  />
                </Grid>
              </Grid>
            )}
          </PaperContainer>

          {/* 員工角色綁定 */}
          {selectedTab !== 2 && (
            <PaperContainer className={classes.container}>
              <Typography variant="h6">員工角色綁定</Typography>
              <Grid
                container
                style={{
                  marginTop: selectedTab !== 2 ? 16 : 22,
                  marginBottom: 0,
                }}
                spacing={3}
                alignItems="flex-start"
              >
                <PrivilegeCheckbox
                  checkboxItems={checkboxItems}
                  checkboxDispatch={checkboxDispatch}
                  disabled={isMySelf}
                />
              </Grid>
            </PaperContainer>
          )}

          {/* 員工所屬權限 */}
          {selectedTab !== 0 && roleListView.current.length !== 0 && (
            <PaperContainer className={classes.container}>
              <Typography variant="h6">員工所屬權限</Typography>
              <Grid
                container
                style={{
                  marginTop: 16,
                }}
              >
                <PrivilegeCheckbox checkboxItems={roleListView.current} type="view" />
              </Grid>
            </PaperContainer>
          )}
          <Grid container xs={12} justifyContent="flex-end">
            <Grid>
              <Button
                variant="contained"
                color={selectedTab !== 2 ? 'primary' : 'error'}
                rounded
                customClasses={classes.submit_btn}
                disabled={isMySelf}
                startIcon={
                  selectedTab === 0 ? (
                    <CheckRoundedIcon />
                  ) : selectedTab === 1 ? (
                    <CreateIcon />
                  ) : (
                    selectedTab === 2 && <RemoveCircleOutlineRoundedIcon />
                  )
                }
                onClick={() => handleConfirm()}
              >
                {selectedTab === 0
                  ? '確認'
                  : selectedTab === 1
                  ? '編輯'
                  : selectedTab === 2 && '刪除'}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default SettingPrivilegeStaff;

SettingPrivilegeStaff.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  setStatusMsg: PropTypes.func.isRequired,
  setShowStatus: PropTypes.func.isRequired,
};
