/* eslint-disable import/prefer-default-export */
export const errorCodeMsg = {
  E22: '找不到資料 !',
};

export const classType = {
  CLASS_MORNING: '1',
  CLASS_NOON: '2',
  CLASS_NIGHT: '3',
};

export const machineType = {
  GRIND: '1',
  PLATED: '2',
  BOTH: '3',
  NONE: '4',
};

export const receiptType = {
  FORMING: 1,
  SINTERING: 2,
  GRINDING: 3,
  TESTING: 4,
  PACKAGING: 5,
  DRILLING: 6,
  ALIGNING: 7,
};

export const configType = {
  MACHINE_TYPE: 1,
  MODULER_USER: 2,
  FORMING_USER: 3,
  PRODUCT_TYPE: 4,
  CUSTOMER_TYPE: 5,
  TRANSFER_DETECT_TYPE: 6,
  QC_DETECT_TYPE: 7,
  // 後端有設定產能功能是8，但並不會回傳前端，因此時間暫停為9
  TIMER_REASON_TYPE: 9,
};

export const loginType = {
  WEB_BROWSER: 1,
  MOBILE_BROWSER: 2,
};

export const stationType = {
  FORMING: '1',
  SINTERING: '2',
  PLATED: '3',
  GRINDING: '4',
  TESTING: '5',
  PACKAGING: '6',
  DRILLING: '10',
  ALIGNING: '11',
};

export const formingManufactureType = {
  FORMING: 'FORMING',
  DRILLING: 'DRILLING',
  ALIGNING: 'ALIGNING',
};

export const manufactureType = {
  FORMING: 'NORMAL',
  DRILLING: 'DRILL',
  ALIGNING: 'ALIGN',
  NONE: 'NONE',
};

export const formingType = {
  FORMING: 0,
  DRILLING: 1,
  ALIGNING: 2,
  NONE: 3,
};

export const reportScheduleType = {
  BY_DELIVERY_DATE: 'byDeliveryDate',
  BY_SC_OD_NO: 'byScOdNo',
};

export const tabDirectionType = {
  BY_SAMPLE: 'bySample',
  BY_ITEM: 'byItem',
};

export const qcFormResult = {
  PASS: '1',
  FAIL: '0',
};

export const processComplete = {
  NONE: 0,
  FORMING: 1,
  SINTERING: 2,
  GRINDING: 3,
  TESTING: 4,
  PACKAGING: 5,
};

export const positionType = {
  FRONT: 1,
  MIDDLE: 2,
  BACK: 3,
};

export const measureStyleType = {
  FORMING: 0,
  SIZE: 1,
  COIL: 2,
  DB: 3,
  L: 4,
  N: 5,
  STAR: 6,
  Z: 7,
};

export const QCNGActions = {
  TEST_SINTERING: 1,
  RECYCLE: 2,
  FORMING_PASS: 3,
  FORMING_OTHER: 4,
  RE_SINTERING: 5,
  RETIRED: 6,
  SINTERING_PASS: 7,
  SINTERING_OTHER: 8,
};

export const qcFormingPath = {
  FORMING: 'qc-forming',
};
export const sinteringSizePath = {
  INSPECTION: 'qc-sintering-inspection',
  SIZE: 'qc-sintering-size',
  ELECTRICAL: 'qc-sintering-electrical',
};

export const sinteringSizeType = {
  INSPECTION: 1,
  SIZE: 2,
};

export const specConfigItemStatus = {
  REMOVED: 0,
  ADDED: 1,
  CURRENT: 2,
};

export const qcExportMeasureStyle = {
  FORMING: 1,
  SINTERING_INSPECTION: 2,
  SIZE_ELECTRICAL: 3,
};

export const reportFinishedFilterInterval = {
  WEEKLY: 1,
  MONTHLY: 2,
  QUARTERLY: 3,
  SEMI_ANNUALLY: 4,
  ANNUALLY: 5,
};

export const processRecordStatus = {
  END: 0,
  SUBMIT: 1,
  CLEAR: 2,
};

export const reportProgressType = {
  DELIVERY_PROGRESS: 'delivery_progress',
  SOURCE_ORDER_PROGRESS: 'source_order_progress',
  OD_NO_INFO: 'od_no_info',
  PACKAGE_SEPARATION: 'package_separation',
};

export const inventoryStatus = {
  UNCHANGED: 0,
  SHORTAGE: 1,
  OVERAGE: 2,
};
