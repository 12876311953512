/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import useStyles from '../useStyles';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import TransLabel from '../../../components/TransLabel/TransLabel';
import ReadonlyField from '../../../components/ReadonlyField/ReadonlyField';
import InputField from '../../../components/InputField/InputField';
import SelectField from '../../../components/SelectField/SelectField';
import Button from '../../../components/Button/Button';
import Divider from '../../../components/Divider/Divider';
import useAxios from '../../../hooks/useAxios';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import regex from '../../../constants/validationRegex';
import useForm from '../../../hooks/useForm';
import useCancelToken from '../../../hooks/useCancelToken';
import { handleUpdateTransTime } from '../../../Utils/processTimeUtils';
import UpdateSubmitReasonSelect from '../UpdateSubmitReasonSelect';
import { formingManufactureType, manufactureType } from '../../../constants/enums';
import useStationType from '../../../hooks/useStationType';

const FormingInfo = ({ pageTitle, pageLabel, formingType, stationType }) => {
  const { dialogDispatch } = useContext(DialogContext);
  const [transInfo, setTransInfo] = useState({
    transId: '-',
    workId: '-',
    elNo: '-',
    materialName: '-',
    productName: '-',
  });
  const [timer, setTimer] = useState({});
  const initialForm = {
    bucketNo: '',
    machineId: '0',
    alignMachineId: '0',
    createQty: '',
    transQty: '',
  };
  const {
    formData: transForm,
    setFormData: setTransForm,
    formErr: transFormErr,
    setFormErr: setTransFormErr,
    resetFormErr,
  } = useForm(initialForm);
  const [machineList, setMachineList] = useState([]);
  const [aligningMachineList, setAligningMachineList] = useState([]);
  const [transQtyErr, setTransQtyErr] = useState('');
  const [submitDisable, setSubmitDisable] = useState(true);
  const [processDisable, setProcessDisable] = useState(true);
  const [startDisable, setStartDisable] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [isWriteOff, setIsWriteOff] = useState(false);
  const currentTargetQty = useRef(0);
  const transQtyLimit = useRef(null);
  const processStartTime = useRef(null);
  const processEndTime = useRef(null);
  const reason = useRef(0);
  const reasonErr = useRef(false);
  const reasonList = useRef([]);

  const { classes } = useStyles();
  const navigate = useNavigate();
  const { trans_id } = useParams();
  const { newCancelToken } = useCancelToken();

  const getProcessComplete = (processComplete) => {
    if (processComplete > 3) {
      setTransFormErr((prev) => ({ ...prev, transQty: true }));
      setTransQtyErr(
        `已完成全檢，禁止修改壓型數量 Inspeksi penuh telah selesai, dan dilarang mengubah kuantitas pembuatan profil`
      );
    }
  };

  const getTransData = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getTrans',
      { transId: trans_id },
      true,
      cancelToken
    );
    if (status) {
      const {
        transfer,
        machine,
        align: alignMachine,
        sample,
        timerRecord,
        isWriteOff: isBeWriteOff,
      } = result;
      const {
        transId,
        scOdno,
        elNo,
        materialName,
        prodName,
        bucketNo,
        createQuantity,
        transQuantity,
        formMachineId,
        alignMachineId,
        processComplete,
        manufactureType: type,
        workId,
      } = transfer;
      const { sampleMin, sampleMax } = sample;
      transQtyLimit.current = {
        min: sampleMin,
        max: sampleMax,
      };
      setIsWriteOff(isBeWriteOff);
      setTransInfo({
        transId,
        elNo,
        materialName,
        productName: prodName,
        workId: scOdno,
        manufactureType: type,
        id: workId,
      });
      setMachineList(
        machine.map((item) => ({
          name: item.machineName,
          value: item.machineId.toString(),
        }))
      );
      if (alignMachine && formingType === formingManufactureType.ALIGNING) {
        setAligningMachineList(
          alignMachine.map((item) => ({
            name: item.machineName,
            value: item.machineId.toString(),
          }))
        );
      }

      if (type !== manufactureType.FORMING) {
        setTransForm((prev) => ({
          ...prev,
          bucketNo: bucketNo || '',
          createQty: createQuantity ? createQuantity.toString() : '',
          transQty: transQuantity ? transQuantity.toString() : '',
          machineId: formMachineId || '0',
          alignMachineId: alignMachineId || '0',
        }));
        currentTargetQty.current = transQuantity ? transQuantity.toString() : '0';
      } else {
        setTransForm((prev) => ({
          ...prev,
          bucketNo: bucketNo || '',
          createQty: createQuantity ? createQuantity.toString() : '',
          transQty: transQuantity ? transQuantity.toString() : '',
          machineId: formMachineId || '0',
          alignMachineId: alignMachineId || '0',
        }));
      }
      getProcessComplete(processComplete);

      // 如果 processComplete !== 0 不要顯示 開始計時＆暫停計時
      /**
       * processComplete 總共有 0 - 5
       * 0: 什麼都沒有做
       * 1: 做完壓型移轉單
       * 2: 做完燒結移轉單
       * 3: 做完研磨移轉單
       * 4: 做完全檢移轉單
       * 5: 做完包裝移轉單
       * */
      if (processComplete !== 0) {
        setIsCreate(false);
      }

      // 是否需要清空timer待討論
      // handleUpdateTransTime(newCancelToken, dialogDispatch, {
      //   transId,
      //   status: processRecordStatus.CLEAR,
      // });
      if (timerRecord) {
        setTimer(timerRecord);
        setStartDisable(true);
        setSubmitDisable(false);
        setProcessDisable(false);
      }
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料讀取錯誤',
          message: noPrivilegeMsg || '請掃描有效之移轉單或確定正確掃描移轉單。',
        },
      });
    }
  };

  const getReasonList = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getTimerReason',
      { stationType },
      true,
      cancelToken
    );
    if (status) {
      reasonList.current = result.timerReason;
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料讀取錯誤',
          message: noPrivilegeMsg || '目前無法取得暫停原因資訊，請稍後再試，或請聯絡網管人員！',
        },
      });
    }
  };

  const handleTransFormChange = (e) => {
    const { name, value } = e.target;
    setTransForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMachineChange = (e) => {
    setTransForm((prev) => ({
      ...prev,
      machineId: e.target.value,
    }));
  };

  const handleArrangingChange = (e) => {
    setTransForm((prev) => ({
      ...prev,
      alignMachineId: e.target.value,
    }));
  };

  const validateTransForm = () => {
    setTransQtyErr('');
    if (transForm.bucketNo.length === 0 || !regex.BUCK_NO.test(transForm.bucketNo)) {
      setTransFormErr((prev) => ({ ...prev, bucketNo: true }));
      return false;
    }
    if (transForm.machineId === '0') {
      setTransFormErr((prev) => ({ ...prev, machineId: true }));
      return false;
    }
    if (transForm.alignMachineId === '0' && formingType === formingManufactureType.ALIGNING) {
      setTransFormErr((prev) => ({ ...prev, alignMachineId: true }));
      return false;
    }
    if (transForm.createQty.length === 0) {
      setTransFormErr((prev) => ({ ...prev, createQty: true }));
      return false;
    }
    if (transForm.transQty.length === 0) {
      setTransFormErr((prev) => ({ ...prev, transQty: true }));
      return false;
    }
    if (
      transForm.transQty > transQtyLimit.current.max ||
      transForm.transQty < transQtyLimit.current.min
    ) {
      setTransFormErr((prev) => ({ ...prev, transQty: true }));
      setTransQtyErr(
        `移轉數量須介於${transQtyLimit.current.min} ~ ${transQtyLimit.current.max} 之間！`
      );
      return false;
    }
    return true;
  };

  const updateForm = async (timerRecord) => {
    setProcessDisable(true);
    const { bucketNo, machineId, alignMachineId, transQty, createQty } = transForm;
    const data = {
      feTransfer: {
        bucketNo,
        transId: trans_id,
        formMachineId: machineId,
        transQuantity: transQty,
        createQuantity: createQty,
        manufactureType: transInfo.manufactureType,
      },
    };
    if (formingType === formingManufactureType.ALIGNING) {
      data.feTransfer.alignMachineId = alignMachineId;
    }
    if (formingType === formingManufactureType.FORMING) {
      data.feTransfer.workId = transInfo.id;
    }
    if (isCreate) {
      data.timerRecord = timerRecord;
    }
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      formingType === formingManufactureType.FORMING ? '/updateForm' : '/updateDrillOrAlign',
      data,
      true,
      cancelToken
    );
    if (status) {
      navigate('/trans-update-success');
    } else {
      if (rtCode === 'E45') {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            singleBtn: true,
            title: '錯誤',
            message: noPrivilegeMsg,
          },
        });
        return;
      }
      navigate('/trans-update-failed');
    }
  };
  const checkMaxValue = async (transQty) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode] = await useAxios(
      '/checkMaxValue',
      {
        scOdno: transInfo.workId,
        transQuantity: Number(transQty),
        transId: transInfo.transId,
      },
      true,
      cancelToken
    );

    const updateData = {
      ...timer,
      endTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      reasonId: reason.current,
    };

    if (status) {
      updateForm(updateData);
      return;
    }

    if (rtCode === 'E95') {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '壓型數量已超出需生產數量',
          message: <p>{result}</p>,
        },
      });
    }

    if (rtCode === 'E99') {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '送出移轉單？',
          message: <p>{result}</p>,
          handleConfirm: () => updateForm(updateData),
        },
      });
    }
  };

  const handleConfirm = async () => {
    resetFormErr();
    if (!validateTransForm()) return;
    // 若點擊送出按鈕，reason 設定 -1
    reason.current = -1;
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        singleBtn: true,
        title: '送出移轉單？',
        message: (
          <p>
            您即將送出移轉單，確認匣數為{transForm.createQty}，移轉數量為{transForm.transQty}嗎？
            <br />
            Kuantitas manufaktur {transForm.createQty}? Kuantitus transfer {transForm.transQty}?
          </p>
        ),
        handleConfirm: () => checkMaxValue(transForm.transQty),
      },
    });
  };

  // 開啟 dialog
  const getReasonDialog = (validationEditReason) => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '請選擇暫停原因',
        body: (
          <UpdateSubmitReasonSelect
            reason={reason.current}
            reasonList={reasonList.current}
            reasonErr={reasonErr.current}
            handleReasonChange={(value) => {
              reason.current = value;
            }}
          />
        ),
        handleConfirm: validationEditReason,
        handleCancel: () => {
          reasonErr.current = false;
          reason.current = 0;
        },
      },
    });
  };

  // 判斷 dialog
  const validationEditReason = async () => {
    if (reason.current === 0) {
      reasonErr.current = true;
      const dialogTimer = setTimeout(() => {
        getReasonDialog(validationEditReason);
        clearTimeout(dialogTimer);
      }, [500]);
      return;
    }
    reasonErr.current = false;
    setSubmitDisable(true);
    processEndTime.current = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    setStartDisable(false);
    const updateData = {
      ...timer,
      endTime: processEndTime.current,
      reasonId: reason.current,
      stationType: useStationType(transInfo.manufactureType),
    };
    const [status] = await handleUpdateTransTime(newCancelToken, dialogDispatch, updateData);
    if (status) {
      setProcessDisable(true);
      reason.current = 0;
    }
  };

  const showRecordsSelectionDialog = () => {
    getReasonDialog(validationEditReason);
  };

  const handleProcessStart = async () => {
    processStartTime.current = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    setStartDisable(true);
    setSubmitDisable(false);
    setProcessDisable(false);
    const startProcessData = {
      transId: transInfo.transId,
      startTime: processStartTime.current,
      stationType: useStationType(transInfo.manufactureType),
    };
    const [status, result] = await handleUpdateTransTime(
      newCancelToken,
      dialogDispatch,
      startProcessData
    );
    if (!status) {
      setProcessDisable(true);
      return;
    }
    setTimer(result);
  };
  const handleProcessEnd = () => {
    showRecordsSelectionDialog();
  };

  useEffect(() => {
    getTransData();
    getReasonList();
    resetFormErr();
  }, []);

  return (
    <Grid container className={classes.root}>
      <PageTitle title={pageTitle} />
      <PaperContainer>
        <div className={`${classes.content} ${classes.header}`}>
          <Typography variant="h6">移轉單資訊</Typography>
          <TransLabel label={`${pageLabel}${isWriteOff ? ' / 已銷帳' : ''}`} />
        </div>
        <div className={classes.content}>
          <div className={classes.field}>
            <ReadonlyField
              label="移轉單號 / Trans nomor pesanan"
              value={transInfo.transId}
              name="transId"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="工令單號 / Nomor perintah kerja"
              value={transInfo.workId}
              name="workId"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField label="料號 / Bagian No" value={transInfo.elNo} name="elNo" />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="材質 / Bahan"
              value={transInfo.materialName}
              name="materialName"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="產品名稱 / Nama Produk"
              value={transInfo.productName}
              name="productName"
            />
          </div>
          {isCreate && (
            <div className={classes.field} style={{ display: 'flex' }}>
              <Button
                variant="outlined"
                color={!startDisable ? 'error' : 'grey'}
                disabled={startDisable || isWriteOff}
                fullWidth
                startIcon={<TimerIcon />}
                customClasses={classes.process_btn}
                onClick={handleProcessStart}
              >
                開始 / Mulai
              </Button>
              <Button
                variant="contained"
                color="error"
                disabled={isWriteOff || isCreate ? processDisable : false}
                fullWidth
                startIcon={<TimerOffIcon />}
                customClasses={classes.process_btn}
                onClick={handleProcessEnd}
              >
                暫停 / Berhenti
              </Button>
            </div>
          )}
        </div>
        <Divider className={classes.divider} />
        <div className={`${classes.content} ${classes.header}`}>
          <Typography variant="h6">製造資訊</Typography>
        </div>
        <div className={classes.content}>
          <div className={classes.field}>
            <InputField
              id="bucketNo"
              label="桶號 / Nomor barel"
              disabled={isCreate ? submitDisable : false}
              value={transForm.bucketNo}
              placeholder="請輸入桶號 / Nomor barel"
              type="text"
              name="bucketNo"
              error={transFormErr.bucketNo}
              errormsg="請正確輸入桶號/Normor barel！"
              onChange={handleTransFormChange}
            />
          </div>
          <div className={classes.field}>
            <SelectField
              label="壓型機台 / Mesin pembentuk"
              placeholder="請選擇壓型機台 / Mesin pembentuk"
              disabled={isCreate ? submitDisable : false}
              id="machineId"
              value={transForm.machineId}
              handleChange={handleMachineChange}
              selections={machineList}
              error={transFormErr.machineId}
              errormsg="請選擇壓型機台 / Mesin pembentuk！"
            />
          </div>
          {formingType === formingManufactureType.ALIGNING && (
            <div className={classes.field}>
              <SelectField
                label="排列機台 / Mesin atur"
                placeholder="請選擇排列機台 / Mesin atur"
                disabled={isCreate ? submitDisable : false}
                id="alignMachineId"
                value={transForm.alignMachineId}
                handleChange={handleArrangingChange}
                selections={aligningMachineList}
                error={transFormErr.alignMachineId}
                errormsg="請選擇排列機台 / Mesin atur！"
              />
            </div>
          )}
          <div className={classes.field}>
            <InputField
              id="createQty"
              label={
                transInfo.manufactureType === manufactureType.FORMING
                  ? '匣數 / Kuantitas manufaktur'
                  : formingType === formingManufactureType.FORMING
                  ? '箱數 / Jumlah kotak'
                  : '匣數 / Kuantitas manufaktur'
              }
              value={transForm.createQty}
              disabled={isCreate ? submitDisable : false}
              placeholder={
                transInfo.manufactureType === manufactureType.FORMING
                  ? '請輸入匣數 / Kuantitas manufaktur'
                  : formingType === formingManufactureType.FORMING
                  ? '請輸入箱數 / Jumlah kotak'
                  : '請輸入匣數 / Kuantitas manufaktur'
              }
              type="number"
              name="createQty"
              error={transFormErr.createQty}
              errormsg={
                transInfo.manufactureType === manufactureType.FORMING
                  ? '請正確輸入匣數 / Kuantitas manufaktur！'
                  : formingType === formingManufactureType.FORMING
                  ? '請正確輸入箱數 / Jumlah kotak！'
                  : '請正確輸入匣數 / Kuantitas manufaktur！'
              }
              onChange={handleTransFormChange}
            />
          </div>
          <>
            <div className={classes.field}>
              <div className={classes.field}>
                <InputField
                  id="transQty"
                  label="移轉數量 / Kuantitus transfer"
                  value={transForm.transQty}
                  placeholder="請輸入移轉數量 / Kuantitus transfer"
                  type="number"
                  disabled={isCreate ? processDisable : false}
                  name="transQty"
                  error={transFormErr.transQty}
                  errormsg={
                    transQtyErr.length === 0
                      ? '請正確輸入移轉數量 / Kuantitus transfer！'
                      : transQtyErr
                  }
                  onChange={handleTransFormChange}
                />
              </div>
            </div>
          </>
        </div>
        <div className={classes.confirm}>
          <Button
            variant="contained"
            color="primary"
            disabled={isWriteOff || isCreate ? submitDisable : false}
            fullWidth
            startIcon={<CheckRoundedIcon />}
            onClick={handleConfirm}
          >
            送出
          </Button>
        </div>
      </PaperContainer>
    </Grid>
  );
};

FormingInfo.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageLabel: PropTypes.string.isRequired,
  formingType: PropTypes.string.isRequired,
  stationType: PropTypes.string.isRequired,
};

export default FormingInfo;
