/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import useStyles from '../useStyles';
import useCommonStyles from '../../useStyles';
import Typography from '../../../../components/Typography/Typography';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Divider from '../../../../components/Divider/Divider';
import { manufactureType, receiptType } from '../../../../constants/enums';
import { useFormingTypeName } from '../../../../hooks/useFormingType';

const deliveryScHeaderColumns = [
  '工令單號',
  '最後更新日期',
  '出貨日期',
  '產品資訊',
  '已全檢數量',
  '檢測通過數量',
  '已入庫數量',
  '需生產數量',
  '完成率',
  '良率',
];

const DeliveryDataRow = ({
  rowData,
  handleShowYieldRateChart,
  handleShowTransList,
  handleShowScOdProcess,
  handleShowOdNoInfo,
}) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;

  const handleScOdNoClick = () => {
    handleShowScOdProcess(rowData.scOdNo);
  };

  const handleOdNoClick = () => {
    handleShowOdNoInfo(rowData.scOdNo);
  };

  const handleYieldRateClick = () => {
    handleShowYieldRateChart(rowData.scOdNo, rowData.workId);
  };

  const handleTransClick = (type) => {
    handleShowTransList(rowData.scOdNo, rowData.workId, type);
  };

  return (
    <PaperContainer className={`${commonClasses.container} ${classes.delivery_container}`}>
      <div className={classes.delivery_sc}>
        <div className={classes.delivery_sc_header}>
          {deliveryScHeaderColumns.map((col) => (
            <div className={classes.delivery_sc_column}>
              <Typography variant="subtitle2">{col}</Typography>
            </div>
          ))}
        </div>
        <div className={classes.delivery_sc_row}>
          <div className={classes.delivery_sc_column}>
            <Typography variant="body1" className={classes.clickable} onClick={handleScOdNoClick}>
              {rowData.scOdNo}
              <i className={classes.clickable_icon} />
            </Typography>
            <Typography
              variant="subtitle2"
              className={`${classes.clickable} ${classes.delivery_trans_column}`}
              onClick={handleOdNoClick}
            >
              工令之生產批號
              <i className={classes.clickable_icon} />
            </Typography>
          </div>
          <div className={classes.delivery_sc_column}>
            <Typography variant="body1">
              {rowData.lastUpdateDate
                ? format(new Date(rowData.lastUpdateDate), 'yyyy.MM.dd HH:mm:ss')
                : '-'}
            </Typography>
          </div>
          <div className={classes.delivery_sc_column}>
            <Typography variant="body1">
              {rowData.deliveryDate ? format(new Date(rowData.deliveryDate), 'yyyy.MM.dd') : '-'}
            </Typography>
          </div>
          <div className={classes.delivery_sc_column}>
            <Typography variant="body1" style={{ wordBreak: 'break-all' }}>
              {rowData.productInfo}
            </Typography>
          </div>
          <div className={classes.delivery_sc_column}>
            <Typography variant="body1">{rowData.producedQty}</Typography>
          </div>
          <div className={classes.delivery_sc_column}>
            <Typography variant="body1">{rowData.passQty}</Typography>
          </div>
          <div className={classes.delivery_sc_column}>
            <Typography variant="body1">{rowData.storageQty}</Typography>
          </div>
          <div className={classes.delivery_sc_column}>
            <Typography variant="body1">{rowData.requirementQty}</Typography>
          </div>
          <div className={classes.delivery_sc_column}>
            <Typography variant="body1">{rowData.completionRate}%</Typography>
          </div>
          <div className={classes.delivery_sc_column}>
            <Typography
              variant="body1"
              className={
                rowData.yieldRate &&
                (rowData.yieldRate !== '100.00' || rowData.yieldRate.length !== 0)
                  ? classes.clickable
                  : ''
              }
              onClick={
                rowData.yieldRate &&
                (rowData.yieldRate !== '100.00' || rowData.yieldRate.length !== 0)
                  ? handleYieldRateClick
                  : null
              }
            >
              {rowData.yieldRate ? `${rowData.yieldRate}%` : '-'}
              {rowData.yieldRate &&
                (rowData.yieldRate !== '100.00' || rowData.yieldRate.length !== 0) && (
                  <i className={classes.clickable_icon} />
                )}
            </Typography>
          </div>
        </div>
      </div>
      <Divider
        className={commonClasses.dashed_divider}
        style={{ marginTop: 8, marginBottom: 16 }}
      />
      {rowData.manufactureType === manufactureType.NONE ? (
        <div className={classes.delivery_trans_section}>
          <Typography variant="subtitle2">尚未設定製造類別</Typography>
        </div>
      ) : (
        <div className={classes.delivery_trans}>
          <div className={classes.delivery_trans_section}>
            <div className={classes.delivery_trans_row_three}>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                {useFormingTypeName(rowData.manufactureType).name}移轉單
              </Typography>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                {useFormingTypeName(rowData.manufactureType).name}移轉數量
              </Typography>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                {useFormingTypeName(rowData.manufactureType).name}總數量
              </Typography>
            </div>
            <div className={classes.delivery_trans_row_three}>
              {rowData.formingTransNum === 0 && (
                <Typography variant="body1" className={classes.delivery_trans_column}>
                  {rowData.formingTransNum}
                </Typography>
              )}
              {rowData.formingTransNum > 0 && (
                <Typography
                  variant="body1"
                  className={`${classes.clickable} ${classes.delivery_trans_column}`}
                  onClick={() =>
                    handleTransClick(
                      // eslint-disable-next-line no-nested-ternary
                      rowData.manufactureType === manufactureType.ALIGNING
                        ? receiptType.ALIGNING
                        : rowData.manufactureType === manufactureType.DRILLING
                        ? receiptType.DRILLING
                        : receiptType.FORMING
                    )
                  }
                >
                  {rowData.formingTransNum}
                  <i className={classes.clickable_icon} />
                </Typography>
              )}
              <Typography variant="body1" className={classes.delivery_trans_column}>
                {rowData.formingTransQty}
              </Typography>
              <Typography variant="body1" className={classes.delivery_trans_column}>
                {rowData.formingTotalQty}
              </Typography>
            </div>
          </div>
          <div className={classes.delivery_trans_section}>
            <div className={classes.delivery_trans_row_three}>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                燒結移轉單
              </Typography>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                燒結移轉數量
              </Typography>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                燒結總數量
              </Typography>
            </div>
            <div className={classes.delivery_trans_row_three}>
              {rowData.sinteringTransNum === 0 && (
                <Typography variant="body1" className={classes.delivery_trans_column}>
                  {rowData.sinteringTransNum}
                </Typography>
              )}
              {rowData.sinteringTransNum > 0 && (
                <Typography
                  variant="body1"
                  className={`${classes.clickable} ${classes.delivery_trans_column}`}
                  onClick={() => handleTransClick(receiptType.SINTERING)}
                >
                  {rowData.sinteringTransNum}
                  <i className={classes.clickable_icon} />
                </Typography>
              )}
              <Typography variant="body1" className={classes.delivery_trans_column}>
                {rowData.sinteringTransQty}
              </Typography>
              <Typography variant="body1" className={classes.delivery_trans_column}>
                {rowData.sinteringTotalQty}
              </Typography>
            </div>
          </div>
          <div className={classes.delivery_trans_section}>
            <div className={classes.delivery_trans_row_three}>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                研磨移轉單
              </Typography>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                研磨移轉數量
              </Typography>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                研磨總數量
              </Typography>
            </div>
            <div className={classes.delivery_trans_row_three}>
              {rowData.grindingTransNum === 0 && (
                <Typography variant="body1" className={classes.delivery_trans_column}>
                  {rowData.grindingTransNum}
                </Typography>
              )}
              {rowData.grindingTransNum > 0 && (
                <Typography
                  variant="body1"
                  className={`${classes.clickable} ${classes.delivery_trans_column}`}
                  onClick={() => handleTransClick(receiptType.GRINDING)}
                >
                  {rowData.grindingTransNum}
                  <i className={classes.clickable_icon} />
                </Typography>
              )}
              <Typography variant="body1" className={classes.delivery_trans_column}>
                {rowData.grindingTransQty}
              </Typography>
              <Typography variant="body1" className={classes.delivery_trans_column}>
                {rowData.grindingTotalQty}
              </Typography>
            </div>
          </div>
          <div className={classes.delivery_trans_section}>
            <div className={classes.delivery_trans_header}>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                檢測移轉單
              </Typography>
              <Typography variant="subtitle2" className={classes.delivery_trans_column}>
                檢測移轉數量
              </Typography>
            </div>
            <div className={classes.delivery_trans_row_two}>
              {rowData.checkingTransNum === 0 && (
                <Typography variant="body1" className={classes.delivery_trans_column}>
                  {rowData.checkingTransNum}
                </Typography>
              )}
              {rowData.checkingTransNum > 0 && (
                <Typography
                  variant="body1"
                  className={`${classes.clickable} ${classes.delivery_trans_column}`}
                  onClick={() => handleTransClick(receiptType.TESTING)}
                >
                  {rowData.checkingTransNum}
                  <i className={classes.clickable_icon} />
                </Typography>
              )}
              <Typography variant="body1" className={classes.delivery_trans_column}>
                {rowData.checkingTransQty}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </PaperContainer>
  );
};

DeliveryDataRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleShowTransList: PropTypes.func.isRequired,
  handleShowYieldRateChart: PropTypes.func.isRequired,
  handleShowScOdProcess: PropTypes.func.isRequired,
  handleShowOdNoInfo: PropTypes.func.isRequired,
};

export default DeliveryDataRow;
