/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import useStyles from '../useStyles';
import useCommonStyles from '../../useStyles';
import useForm from '../../../../hooks/useForm';
import ScOdInfo from './ScOdInfo';
import ScOdDataRow from './ScOdDataRow';
import useAxios from '../../../../hooks/useAxios';
import useCancelToken from '../../../../hooks/useCancelToken';
import { DialogContext } from '../../../../contexts/DialogContext';
import { dialogActions } from '../../../../reducers/dialogReducer';
import { parseDecimalToFixedString } from '../../../../Utils/stringUtil';
import Typography from '../../../../components/Typography/Typography';
import TransListDialog from '../TransListDialog';
import { manufactureType, receiptType, reportProgressType } from '../../../../constants/enums';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import ScOdFormDataRow from './ScOdFormDataRow';
import { useFormingTypeName } from '../../../../hooks/useFormingType';

const targetPageType = {
  TRANS_INFO: 'source_order_progress',
  FORMING_INFO: 'delivery_progress',
};

const targetPageName = {
  TRANS_INFO: '移轉單資訊',
  FORMING_INFO: '壓型資訊',
};

const ScOdProgress = ({ transScOdNo, fromDeliveryProgress, setFromDeliveryProgress }) => {
  const { dialogDispatch } = useContext(DialogContext);
  const [scOdNo, setScOdNo] = useState('');
  const [scOdTransList, setScOdTransList] = useState([]); // 所有的工令進度表
  const [scOdFormTransList, setScOdFormTransList] = useState([]); // 純壓型的工令進度表
  const [scOdNoErr, setScOdNoErr] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showTransListDialog, setShowTransListDialog] = useState(false);
  const [targetScOdNo, setTargetScOdNo] = useState('');
  const [transType, setTransType] = useState('');
  const [dialogType, setDialogType] = useState('');
  const [currentTransList, setCurrentTransList] = useState([]);
  const [targetPage, setTargetPage] = useState(targetPageType.TRANS_INFO);
  const initialScOdInfo = {
    productInfo: '-',
    producedQty: '-',
    storageQty: '-',
    requirementQty: '-',
    deliveryDate: '-',
    completionRate: '',
    yieldRate: '',
  };
  // eslint-disable-next-line prettier/prettier
  const {
    formData: scOdInfo,
    setFormData: setScOdInfo,
    resetFormData: resetScOdInfo,
  } = useForm(initialScOdInfo);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();

  const validationScOdNo = (ispass = false) => {
    if (ispass) {
      return true;
    }
    if (scOdNo.length === 0) {
      setScOdNoErr(true);
      return false;
    }
    return true;
  };

  const transformProcessCompleteStatus = ({ formingType, status }) => {
    if (formingType === manufactureType.FORMING || formingType === manufactureType.NONE) {
      return status;
    }

    if (status === '等待壓型') {
      return `等待${useFormingTypeName(formingType).name}`;
    }

    return status;
  };

  const handleFetch = async (transScOd = '', ispass = false) => {
    setScOdNoErr(false);
    if (!validationScOdNo(ispass)) return;
    const data = { scOdno: scOdNo };
    if (transScOd) {
      data.scOdno = transScOd;
    }
    if (fromDeliveryProgress) {
      setFromDeliveryProgress(false);
    }
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/workProgress',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { sc, progress, preTrans } = result;
      if (!sc) {
        setScOdNoErr(true);
        return;
      }
      setScOdInfo({
        formingType: sc.manufactureType,
        productInfo: `${sc.prodName}(${sc.materialName})`,
        producedQty: sc.prodQuantity,
        formingTotalQty: sc.formingTotalQty,
        storageQty: sc.packageQuantity,
        requirementQty: sc.scQty,
        deliveryDate: sc.odRedat,
        completionRate: parseDecimalToFixedString(sc.completeRate * 100),
        yieldRate: parseDecimalToFixedString(sc.goodRate * 100),
      });
      setScOdTransList(
        progress.map((item, idx) => ({
          id: idx + 1,
          transId: item.transId,
          mainFormingType: sc.manufactureType, // 負責顯示移轉單單號文字
          formingType: item.manufactureType, // 負責判斷是移轉單資訊還是壓型資訊
          initDate: item.createDate,
          updateDate: item.modifyDate,
          status: transformProcessCompleteStatus({
            formingType: sc.manufactureType,
            status: item.processCompleteStatus,
          }),
          transQty: item.transQuantity,
          yieldRate: item.goodRate ? parseDecimalToFixedString(item.goodRate * 100) : '',
          isFinishTesting: item.isCheckedData !== undefined ? item.isCheckedData : true,
          standardQty: item.prodWph,
          efficiency: parseDecimalToFixedString(item.prodEfficiency * 100),
          bucketNo: item.bucketNo,
          formingMachine: item.formMachineName,
          formingUser: item.formUser,
          aligningMachine: item.alignMachineName || '',
          aligningUser: item.drillAlignUser || '',
          drillingUser: item.drillAlignUser || '',
          sinteringMachine: item.sinterMachineName,
          sinteringUser: item.sinterUser,
          grindingMachine: item.grindMachineName,
          grindingUser: item.grindUser,
          platedMachine: item.platedMachine,
          platedAndGrindMachine: item.platedAndGrindMachineId,
          testingLine: item.lineType,
          // 各站作業時間
          eachFormHours: item.formProcessHours,
          eachDrillHours: item.drillAlignProcessHours,
          eachAlignHours: item.drillAlignProcessHours,
          eachSinterHours: item.sinterProcessHours,
          eachGrindHours: item.grindProcessHours,
          eachTestHours: item.checkProcessHours,
          // 該站停留時間(暫停時間)
          theFormHours: item.formHours,
          theDrillHours: item.drillAlignHours,
          theAlignHours: item.drillAlignHours,
          theSinterHours: item.sinterHours,
          theGrindHours: item.grindHours,
          theTestHours: item.checkHours,
          packageId: item.packageId,
        }))
      );
      if (sc.manufactureType && sc.manufactureType !== manufactureType.FORMING) {
        // 壓型資訊的資料
        setScOdFormTransList(
          preTrans.map((item, idx) => ({
            id: idx + 1,
            transId: item.transId,
            preScOd: item.sourceScOdno,
            initDate: item.createDate,
            updateDate: item.modifyDate,
            transQty: item.transQuantity,
            bucketNo: item.bucketNo,
            formingMachine: item.formMachineName,
            formingUser: item.formUser,
            standardQty: item.prodWph || '-',
            efficiency: parseDecimalToFixedString(item.prodEfficiency * 100),
            // 暫停時間
            theFormHours: item.formHours,
          }))
        );
      }
      setShowResult(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得工令相關資訊',
          message:
            noPrivilegeMsg || `${result}：目前無法取得工令相關資訊，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const toHourMinute = (sec) => {
    // 先將秒轉換成分鍾
    const min = Math.floor(sec / 60);
    // 用所有分鐘轉換成小時
    const hour = Math.floor(min / 60) === 0 ? '' : `${Math.floor(min / 60)}小時`;
    // 用所有分鐘轉換成剩餘分鐘
    const minute = min % 60 === 0 ? '' : `${min % 60}分`;

    return `${hour}${minute}`;
  };

  const handleFetchTransList = async (id) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/transByRecord',
      { transId: id },
      true,
      cancelToken
    );
    if (status) {
      const { transRecord } = result;
      setCurrentTransList(
        transRecord.map((item, idx) => ({
          id: idx + 1,
          reason: item.reasonTC,
          startTime: `${format(new Date(item.startTime), 'yyyy.MM.dd HH:mm:ss')}`,
          endTime: `${format(new Date(item.endTime), 'yyyy.MM.dd HH:mm:ss')}`,
          totalTime: toHourMinute(item.spendTime),
        }))
      );
      setShowTransListDialog(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得移轉單資訊',
          message:
            noPrivilegeMsg || `${result}：目前無法取得移轉單資訊，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleFetchPackageList = async (id) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkSubPackage',
      { packageId: id },
      true,
      cancelToken
    );
    if (status) {
      const { packageList } = result;
      setCurrentTransList(
        packageList.map((item) => ({
          packageId: item.packageId,
          packageQty: item.totalCheckQuantity,
          checkStatus: item.checkStatus,
          modifyDate: item.modifyDate,
        }))
      );
      setShowTransListDialog(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得移轉單資訊',
          message:
            noPrivilegeMsg || `${result}：目前無法取得移轉單資訊，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleTransListDialogClose = () => {
    setShowTransListDialog(false);
  };

  const handleShowTransList = (id, type) => {
    setTargetScOdNo(id);
    setTransType(type);

    if (type === receiptType.PACKAGING) {
      handleFetchPackageList(id);
      setDialogType(reportProgressType.PACKAGE_SEPARATION);
    } else {
      handleFetchTransList(id);
      setDialogType(reportProgressType.SOURCE_ORDER_PROGRESS);
    }
  };

  useEffect(() => {
    if (!fromDeliveryProgress) return;
    setScOdNo(transScOdNo);
    handleFetch(transScOdNo, true);
  }, [fromDeliveryProgress]);

  return (
    <div>
      <ScOdInfo
        scOdNo={scOdNo}
        setScOdNo={setScOdNo}
        scOdNoErr={scOdNoErr}
        setScOdNoErr={setScOdNoErr}
        handleFetch={handleFetch}
        scOdInfo={scOdInfo}
      />
      {showResult && (
        <div className={classes.main_content}>
          <Typography variant="h6">工令進度表</Typography>
          <div className={classes.scOd_list}>
            {scOdInfo.formingType !== manufactureType.FORMING && (
              <PaperContainer className={commonClasses.report_nav}>
                <Typography
                  variant="body1"
                  className={`${commonClasses.report_nav_item} ${
                    targetPage === targetPageType.TRANS_INFO ? 'active' : ''
                  }`}
                  id={targetPageType.TRANS_INFO}
                  onClick={(e) => setTargetPage(e.target.id)}
                >
                  {targetPageName.TRANS_INFO}
                </Typography>
                <Typography
                  variant="body1"
                  className={`${commonClasses.report_nav_item} ${
                    targetPage === targetPageType.FORMING_INFO ? 'active' : ''
                  }`}
                  id={targetPageType.FORMING_INFO}
                  onClick={(e) => setTargetPage(e.target.id)}
                >
                  {targetPageName.FORMING_INFO}
                </Typography>
              </PaperContainer>
            )}
            {scOdTransList.length > 0 &&
              targetPage === targetPageType.TRANS_INFO &&
              scOdTransList.map((row) => (
                <ScOdDataRow key={row.id} rowData={row} handleShowTransList={handleShowTransList} />
              ))}
            {scOdFormTransList.length > 0 &&
              targetPage === targetPageType.FORMING_INFO &&
              scOdFormTransList.map((row) => <ScOdFormDataRow key={row.id} rowData={row} />)}
            {scOdTransList.length === 0 && targetPage === targetPageType.TRANS_INFO && (
              <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: 36 }}>
                該工令沒有正在進行中的移轉單，請搜尋其他工令！
              </Typography>
            )}
            {scOdFormTransList.length === 0 && targetPage === targetPageType.FORMING_INFO && (
              <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: 36 }}>
                該工令沒有正在進行中的移轉單，請搜尋其他工令！
              </Typography>
            )}
          </div>
        </div>
      )}
      <TransListDialog
        open={showTransListDialog}
        handleTransListDialogClose={handleTransListDialogClose}
        targetScOdNo={targetScOdNo}
        transType={transType}
        transList={currentTransList}
        type={dialogType}
      />
    </div>
  );
};

ScOdProgress.defaultProps = {
  transScOdNo: '',
  fromDeliveryProgress: false,
};

ScOdProgress.propTypes = {
  transScOdNo: PropTypes.string,
  fromDeliveryProgress: PropTypes.bool,
  setFromDeliveryProgress: PropTypes.func.isRequired,
};

export default ScOdProgress;
