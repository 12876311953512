import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';

const ModulerTypeToolbar = ({ handleSearch }) => {
  const [user, setUser] = useState('');

  const handleCustomerInputChange = (e) => {
    setUser(e.target.value);
  };
  const onSearchClick = (e, isSearchAll = false) => {
    if (isSearchAll) {
      handleSearch('');
      setUser('');
      return;
    }
    handleSearch(user);
  };
  return (
    <Toolbar>
      <Grid
        container
        style={{ marginTop: 25, marginBottom: 20, width: '100%' }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid xs={7} style={{ marginLeft: -25, marginRight: 15 }}>
          <InputField
            id="customerNo"
            label=""
            value={user}
            variant="outlined"
            onChange={handleCustomerInputChange}
            placeholder="請輸入要搜尋的員工名稱"
            name="customerNo"
            type="text"
            errormsg="請輸入要搜尋的員工名稱"
            size="small"
          />
        </Grid>
        <Grid xs={5}>
          <Button
            onClick={onSearchClick}
            variant="outlined"
            color="primary"
            startIcon={<SearchRoundedIcon />}
            style={{ marginRight: 10 }}
          >
            查詢
          </Button>
          <Button onClick={(e) => onSearchClick(e, true)} variant="outlined" color="grey">
            查詢全部
          </Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
}; // ModulerTypeToolbar

ModulerTypeToolbar.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default ModulerTypeToolbar;
