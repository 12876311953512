/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import DatePicker from '../../../components/DatePicker/DatePicker';
import Button from '../../../components/Button/Button';
import SelectField from '../../../components/SelectField/SelectField';
import Divider from '../../../components/Divider/Divider';
import useCommonStyles from '../useStyles';
import useStyles from './useStyles';
import { qcExportMeasureStyleSelection } from '../../../constants/configSelections';
import InputField from '../../../components/InputField/InputField';
import Checkbox from '../../../components/Checkbox/Checkbox';

const FilterInfo = ({
  filterField,
  setFilterField,
  filterFieldErr,
  handleFetch,
  handleAdvancedFetch,
  machineSelection,
  transIdSelection,
  measureUserSelection,
  customerSelection,
  productSelection,
  showAdvanced,
  handleShowAdvancedFilter,
  useDateCheck,
  handleUseDateCheck,
}) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const hasDateErr = filterFieldErr.startDate || filterFieldErr.endDate;
  const machinePlaceholder =
    filterField.measureStyle !== '0'
      ? machineSelection.length !== 0
        ? '請選擇機台'
        : '無此量測型態相關的機台號碼'
      : '請先選擇量測型態';

  const handleFilterChange = (e) => {
    setFilterField((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleStartDateChange = (date) => {
    setFilterField((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFilterField((prev) => ({
      ...prev,
      endDate: date,
    }));
  };

  const handleWorkNoChange = (e) => {
    setFilterField((prev) => ({
      ...prev,
      workId: e.target.value,
    }));
  };

  const handleAdvancedClear = () => {
    setFilterField((prev) => ({
      ...prev,
      measureStyle: '0',
      machine: '0',
      transId: '0',
      measureUser: '0',
      customer: '0',
      product: '0',
    }));
  };

  return (
    <PaperContainer className={commonClasses.container}>
      <Typography variant="h6">查詢QC單</Typography>
      <Grid container className={commonClasses.content} direction="column">
        <Grid container item xs={12} alignItems="center">
          <>
            <Grid item xs={3} className={classes.filter_date_grid}>
              <DatePicker
                id="startDate"
                label="查詢起始日期"
                value={filterField.startDate}
                onChange={handleStartDateChange}
                hasDateErr={hasDateErr}
                errMsg="結束日期不得早於起始日期"
                maxDate={new Date()}
                containerClass={classes.filter_date_picker}
                disabled={!useDateCheck}
              />
            </Grid>
            <Typography
              variant="h6"
              style={{ marginRight: 24 }}
              className={`${hasDateErr ? classes.has_date_err_typography : ''} ${
                useDateCheck ? '' : classes.disabled_opacity
              }`}
            >
              ~
            </Typography>
            <Grid item xs={3} className={classes.filter_date_grid}>
              <DatePicker
                id="endDate"
                label="查詢結束日期"
                value={filterField.endDate}
                onChange={handleEndDateChange}
                hasDateErr={hasDateErr}
                errMsg="結束日期不得早於起始日期"
                maxDate={new Date()}
                containerClass={classes.filter_date_picker}
                disabled={!useDateCheck}
              />
            </Grid>
          </>
          <Grid
            item
            xs={3}
            className={classes.filter_date_grid}
            style={{
              marginBottom: filterFieldErr.workId ? 0 : hasDateErr ? 26 : 8,
              marginTop: filterFieldErr.workId ? 11 : 0,
              marginLeft: 24,
            }}
          >
            <InputField
              type="text"
              id="workNo"
              name="workNo"
              placeholder="請輸入工令單號"
              label="工令單號"
              value={filterField.workId}
              onChange={handleWorkNoChange}
              errormsg="工令單號不得為空"
              error={filterFieldErr.workId}
              disabled={useDateCheck}
            />
          </Grid>

          {!showAdvanced && (
            <>
              <Button
                variant="outlined"
                color="primary"
                customClasses={`${classes.filter_btn} ${
                  hasDateErr ? classes.has_date_err_btn : ''
                }`}
                startIcon={<SearchRoundedIcon />}
                onClick={handleFetch}
              >
                查詢
              </Button>
              <Button
                variant="outlined"
                color="primary"
                customClasses={`${classes.filter_btn} ${
                  hasDateErr ? classes.has_date_err_btn : ''
                }`}
                startIcon={<ImageSearchRoundedIcon />}
                onClick={() => handleShowAdvancedFilter(true)}
              >
                進階查詢
              </Button>
            </>
          )}
        </Grid>
        <Grid item sx={12}>
          <Checkbox
            name="useDateCheck"
            label="使用QC單日期查詢"
            reverse
            checked={useDateCheck}
            handleChange={handleUseDateCheck}
          />
        </Grid>
        {showAdvanced && (
          <>
            <Divider style={{ marginTop: 24 }} />
            <Grid container item xs={12} className={classes.advanced_filter} direction="column">
              <Grid item container xs={12} justifyContent="space-between">
                <Typography variant="subtitle1">進階查詢條件</Typography>
                <Button
                  variant="outlined"
                  color="grey"
                  rounded
                  startIcon={<ExpandLessRoundedIcon />}
                  onClick={() => handleShowAdvancedFilter(false)}
                >
                  取消進階查詢
                </Button>
              </Grid>
              <Grid item container xs={12} className={classes.advanced_filter_row}>
                <Grid item xs={4} lg={3} className={classes.filter_field}>
                  <SelectField
                    id="measureStyle"
                    label="量測型態"
                    placeholder="請選擇量測型態"
                    selections={qcExportMeasureStyleSelection}
                    handleChange={handleFilterChange}
                    value={filterField.measureStyle}
                  />
                </Grid>
                <Grid item xs={4} lg={3} className={classes.filter_field}>
                  <SelectField
                    id="machine"
                    label="機台號碼"
                    placeholder={machinePlaceholder}
                    selections={machineSelection}
                    handleChange={handleFilterChange}
                    value={filterField.machine}
                  />
                </Grid>
                <Grid item xs={4} lg={3} className={classes.filter_field}>
                  <SelectField
                    id="transId"
                    label="移轉單號"
                    placeholder={
                      transIdSelection.length !== 0 ? '請選擇移轉單號' : '此區間沒有相應的移轉單號'
                    }
                    selections={transIdSelection}
                    handleChange={handleFilterChange}
                    value={filterField.transId}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.advanced_filter_row}>
                <Grid item xs={4} lg={3} className={classes.filter_field}>
                  <SelectField
                    id="measureUser"
                    label="量測人員"
                    placeholder={
                      measureUserSelection.length !== 0
                        ? '請選擇量測人員'
                        : '此區間沒有相應的量測人員'
                    }
                    selections={measureUserSelection}
                    handleChange={handleFilterChange}
                    value={filterField.measureUser}
                  />
                </Grid>
                <Grid item xs={4} lg={3} className={classes.filter_field}>
                  <SelectField
                    id="customer"
                    label="客戶"
                    placeholder={
                      customerSelection.length !== 0 ? '請選擇客戶' : '此區間沒有相應的客戶'
                    }
                    selections={customerSelection}
                    handleChange={handleFilterChange}
                    value={filterField.customer}
                  />
                </Grid>
                <Grid item xs={4} lg={3} className={classes.filter_field}>
                  <SelectField
                    id="product"
                    label="產品"
                    placeholder={
                      productSelection.length !== 0 ? '請選擇產品' : '此區間沒有相應的產品'
                    }
                    selections={productSelection}
                    handleChange={handleFilterChange}
                    value={filterField.product}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${classes.advanced_filter_btn}`}
                  container
                  alignItems="flex-end"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<SearchRoundedIcon />}
                    onClick={handleAdvancedFetch}
                  >
                    查詢
                  </Button>
                  <Button
                    variant="outlined"
                    color="grey"
                    startIcon={<ClearRoundedIcon />}
                    onClick={handleAdvancedClear}
                    style={{ marginLeft: 16 }}
                  >
                    重設
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </PaperContainer>
  );
};

FilterInfo.propTypes = {
  filterField: PropTypes.object.isRequired,
  setFilterField: PropTypes.func.isRequired,
  filterFieldErr: PropTypes.object.isRequired,
  handleFetch: PropTypes.func.isRequired,
  handleAdvancedFetch: PropTypes.func.isRequired,
  machineSelection: PropTypes.array.isRequired,
  transIdSelection: PropTypes.array.isRequired,
  measureUserSelection: PropTypes.array.isRequired,
  customerSelection: PropTypes.array.isRequired,
  productSelection: PropTypes.array.isRequired,
  showAdvanced: PropTypes.bool.isRequired,
  handleShowAdvancedFilter: PropTypes.func.isRequired,
  useDateCheck: PropTypes.bool.isRequired,
  handleUseDateCheck: PropTypes.func.isRequired,
};

export default FilterInfo;
