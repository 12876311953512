import React, { useContext, useState, useEffect, useReducer, useRef } from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import useStyles from '../useStyles';
import checkboxReducer, { checkboxActions } from '../../../reducers/checkboxReducer';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import styleConst from '../../../constants/style';
import useAxios from '../../../hooks/useAxios';
import SettingsConfigFormingUserTypeToolbar from './SettingsConfigFormingUserTypeToolbar';
import useCancelToken from '../../../hooks/useCancelToken';
import { errorCodeMsg } from '../../../constants/enums';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import TablePagination from '../../../components/TablePagination/TablePagination';
import Snackbar from '../../../components/Snackbar/Snackbar';
import CheckboxGroup from '../../../components/CheckboxGroup/CheckboxGroup';
import Button from '../../../components/Button/Button';
import Chip from '../../../components/Chip/Chip';

const SettingsConfigFormingUserType = () => {
  const { classes } = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [formingUserList, formingUserDispatch] = useReducer(checkboxReducer, []);
  const [newFormingUserList, setNewFormingUserList] = useState([]);
  const [enableList, setEnableList] = useState([]);
  const [statusMsg, setStatusMsg] = useState('');
  const [statusType, setStatusType] = useState('success');
  const [showStatus, setShowStatus] = useState(false);
  const snackbarTimer = useRef(null);
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();

  const rowsPerPage = styleConst.ROWS_PER_PAGE;

  const arrayChunk = (array, chunkSize = rowsPerPage / 3) =>
    Array(Math.ceil(array.length / chunkSize))
      .fill()
      .map((_, idx) => idx * chunkSize)
      .map((begin) => array.slice(begin, begin + chunkSize));

  const getModulerData = async (page = currentPage, keyWord) => {
    const data = keyWord ? { page, keyWord } : { page };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/modulerData',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { count, moduler } = result;
      setTotalRows(count);
      let modulerArr = moduler.map((item) => ({
        name: item.userId,
        label: item.userName,
        checked: !!item.isForming,
      }));
      // 每次撈資料時都要再檢查是否有異動未儲存的部分
      modulerArr = moduler.map(
        (item) =>
          enableList.find((user) => user.name === item.userId) ||
          newFormingUserList.find((user) => user.name === item.userId) || {
            name: item.userId,
            label: item.userName,
            checked: !!item.isForming,
          }
      );
      formingUserDispatch({
        type: checkboxActions.INIT,
        init: modulerArr,
      });
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得壓型人員列表',
          message: noPrivilegeMsg || `目前無法取得壓型人員列表，${result}`,
        },
      });
    }
  }; // getModulerData

  const getFormingUserEnable = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/formerEnable',
      {},
      true,
      cancelToken
    );
    if (status) {
      const { former } = result;
      setEnableList(
        former.map((item) => ({
          name: item.userId,
          label: item.userName,
          checked: !!item.isForming,
        }))
      );
    } else {
      if (result === errorCodeMsg.E22) return;
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '無法取得壓型人員',
          message: noPrivilegeMsg || `目前無法取得壓型人員清單！ ${result}`,
        },
      });
    }
  };

  const openSnackbar = () => {
    setShowStatus(true);
    clearTimeout(snackbarTimer.current);
    snackbarTimer.current = setTimeout(() => {
      setShowStatus(false);
      clearTimeout(snackbarTimer.current);
    }, 3000);
  };

  const handleSearch = (user) => {
    setCurrentPage(1);
    getModulerData(1, user);
  };

  const init = () => {
    getModulerData();
    getFormingUserEnable();
    setNewFormingUserList([]);
  };

  const handleChange = (e) => {
    const isInEnableList = enableList.find((item) => item.name === e.target.name);
    if (isInEnableList) {
      // 若原存在EnableList中，則直接enableList
      setEnableList((prev) =>
        prev.map((item) =>
          item.name === e.target.name ? { ...item, checked: !item.checked } : item
        )
      );
      return;
    }
    const isInNewFormingUser = newFormingUserList.find((item) => item.name === e.target.name);
    if (isInNewFormingUser) {
      // 若原存在newModulerList中，則表按了要被移除掉
      if (isInNewFormingUser.checked) {
        setNewFormingUserList((prev) => prev.filter((item) => item.name !== e.target.name));
      }
      return;
    }
    // 若都不在上述兩個list中則表一定是新增的異動，加入newModulerList中
    const targetUser = formingUserList.find((item) => item.name === e.target.name);
    setNewFormingUserList((prev) => [...prev, { ...targetUser, checked: true }]);
  }; // handleChange

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
    getModulerData(newPage);
  };

  const handleUnCheck = (item) => {
    setEnableList((prev) =>
      prev.map((user) => (user.name === item.name ? { ...user, checked: !user.checked } : user))
    );
    setNewFormingUserList((prev) => prev.filter((user) => user.name !== item.name));
    formingUserDispatch({
      type: checkboxActions.CHANGE,
      target: item.name,
    });
  };

  const handleSave = async () => {
    let former = newFormingUserList.map((user) => ({
      userId: user.name,
      isForming: user.checked ? 1 : 0,
    }));
    former = former.concat(
      enableList.map((user) => ({
        userId: user.name,
        isForming: user.checked ? 1 : 0,
      }))
    );
    // 異動的部分為新增（newModulerList），與刪除（enableList）中的人員資料
    const data = {
      former,
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/formerSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      setStatusMsg('壓型人員修改成功！');
      setStatusType('success');
      init();
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '壓型人員修改儲存失敗，請稍後再試，或聯絡網管人員！');
    }
    openSnackbar();
  }; // handleSave

  useEffect(() => {
    setCurrentPage(1);
    getModulerData();
    getFormingUserEnable();
    init();

    return () => {
      clearTimeout(snackbarTimer.current);
    };
  }, []);

  return (
    <>
      <div className={classes.moduler_container}>
        <PaperContainer className={classes.moduler_content}>
          <Typography variant="h6">壓型人員設定</Typography>
          <SettingsConfigFormingUserTypeToolbar handleSearch={handleSearch} />
          <Snackbar
            msg={statusMsg}
            type={statusType}
            open={showStatus}
            className={classes.snackbar}
          />
          <div className={classes.checkbox_container}>
            <Grid container className={classes.checkbox_group}>
              <Grid item xs={4} className={classes.checkbox_column}>
                <CheckboxGroup
                  itemList={arrayChunk(formingUserList)[0]}
                  checkboxDispatch={formingUserDispatch}
                  onChange={handleChange}
                />
              </Grid>
              {arrayChunk(formingUserList)[1] && (
                <Grid item xs={4} className={classes.checkbox_column}>
                  <CheckboxGroup
                    itemList={arrayChunk(formingUserList)[1]}
                    checkboxDispatch={formingUserDispatch}
                    onChange={handleChange}
                  />
                </Grid>
              )}
              {arrayChunk(formingUserList)[2] && (
                <Grid item xs={4}>
                  <CheckboxGroup
                    itemList={arrayChunk(formingUserList)[2]}
                    checkboxDispatch={formingUserDispatch}
                    onChange={handleChange}
                  />
                </Grid>
              )}
            </Grid>
          </div>
          <TablePagination
            isEditing={null}
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
          />
        </PaperContainer>
        <div className={classes.moduler_sidebar}>
          <PaperContainer className={classes.sidebar_container}>
            <Typography variant="h6">已選取壓型人員</Typography>
            <div className={classes.chips_container}>
              {newFormingUserList.length > 0 &&
                newFormingUserList.map((item) => (
                  <Chip
                    key={item.name}
                    label={item.label}
                    onDelete={() => handleUnCheck(item)}
                    color="secondary"
                    variant="outlined"
                    className={classes.chip}
                    classes={{ label: classes.chip_label }}
                  />
                ))}
            </div>
            <div className={`${classes.chips_container} ${classes.chips_container_tall}`}>
              {enableList.map((item) => (
                <Chip
                  key={item.name}
                  label={item.label}
                  onDelete={() => handleUnCheck(item)}
                  color="primary"
                  variant="outlined"
                  className={classes.chip}
                  classes={{ label: classes.chip_label }}
                  disabled={!item.checked}
                />
              ))}
            </div>
            <Typography variant="body1" className={classes.moduler_count}>
              壓型人員總數：
              {enableList.filter((item) => item.checked).length + newFormingUserList.length}
            </Typography>
          </PaperContainer>
        </div>
      </div>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          rounded
          startIcon={<CheckRoundedIcon />}
          onClick={handleSave}
        >
          儲存
        </Button>
      </Grid>
    </>
  );
};

export default SettingsConfigFormingUserType;
