import React, { useContext, useState, useEffect, useRef } from 'react';
import useStyles from '../useStyles';
import Typography from '../../../components/Typography/Typography';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import ConfigTable from '../../../components/ConfigTable/ConfigTable';
import TablePagination from '../../../components/TablePagination/TablePagination';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import CustomerTypeToolbar from './SettingsConfigCustomerTypeToolbar';
import useAxios from '../../../hooks/useAxios';
import styleConst from '../../../constants/style';
import Snackbar from '../../../components/Snackbar/Snackbar';
import useCancelToken from '../../../hooks/useCancelToken';

const settingsConfigTableHeadCells = [
  { id: 'station', numeric: false, disablePadding: true, label: '客戶名稱' },
  { id: 'machine', numeric: false, disablePadding: true, label: '客戶簡稱' },
];

const SettingsConfigCustomerType = () => {
  const { classes } = useStyles();
  const [editedData, setEditedData] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [statusMsg, setStatusMsg] = useState('');
  const [statusType, setStatusType] = useState('success');
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { dialogDispatch } = useContext(DialogContext);
  const snackbarTimer = useRef(null);
  const { newCancelToken } = useCancelToken();

  const rowsPerPage = styleConst.ROWS_PER_PAGE;

  const getCustomerData = async (page = currentPage, keyWord) => {
    const data = keyWord ? { page, keyWord } : { page };
    setIsLoading(true);
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/customerData',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { customer, count } = result;
      setTotalRows(count);
      setCustomerList(
        customer.map((item) => ({
          rowId: item.customerId,
          isChecked: false,
          isEditing: false,
          columns: [
            {
              name: 'customerNo',
              value: item.customerNo,
              displayValue: item.customerNo,
              visible: false,
              isEditable: false,
            },
            {
              name: 'customerId',
              value: item.customerId,
              displayValue: item.customerId,
              visible: false,
              isEditable: false,
            },
            {
              name: 'customerName',
              value: item.customerName,
              displayValue: item.customerName,
              visible: true,
              isEditable: false,
            },
            {
              name: 'customerAlias',
              value: item.customerAlias,
              displayValue: item.customerAlias,
              visible: true,
              isEditable: true,
              type: 'text',
              label: '客戶簡稱',
              placeholder: '請輸入客戶簡稱',
              isRequired: true,
              error: false,
            },
          ],
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得客戶列表',
          message: noPrivilegeMsg || `目前無法取得客戶列表，${result}`,
        },
      });
    }
    setIsLoading(false);
  };

  const openSnackbar = () => {
    setShowStatus(true);
    clearTimeout(snackbarTimer.current);
    snackbarTimer.current = setTimeout(() => {
      setShowStatus(false);
      clearTimeout(snackbarTimer.current);
    }, 3000);
  };

  const handleEditData = async () => {
    const data = {
      customer: [
        {
          ...editedData,
        },
      ],
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/customerSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      setStatusMsg('已成功更新客戶簡稱！');
      setStatusType('success');
      getCustomerData();
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '更新客戶簡稱失敗，請稍後再試，或通知網管人員！');
    }
    openSnackbar();
    setEditedData({});
  };

  const onRowEditClick = (targetRowId, rowStatus) => {
    setCustomerList((prev) =>
      prev.map((config) =>
        config.rowId === targetRowId
          ? { ...config, columns: [...config.columns], isEditing: rowStatus }
          : config
      )
    );
    if (rowStatus) {
      const targetRow = customerList.find((config) => config.rowId === targetRowId);
      setEditedData({
        customerNo: targetRow.columns[0].value,
        customerId: targetRow.columns[1].value,
        customerName: targetRow.columns[2].value,
        customerAlias: targetRow.columns[3].value,
      });
    } else {
      setEditedData({});
    }
  };

  const handleRowError = (targetRow, validation) => {
    setCustomerList((prev) =>
      prev.map((config) =>
        config.rowId === targetRow.rowId
          ? {
              ...config,
              columns: targetRow.columns.map((col) => ({ ...col, error: !validation[col.name] })),
            }
          : config
      )
    );
  };

  const handleRowEditing = (e) => {
    setEditedData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSearch = async (customer) => {
    getCustomerData(1, customer);
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
    getCustomerData(newPage);
  };

  useEffect(() => {
    const hasEditingRow = customerList.find((item) => item.isEditing);
    if (hasEditingRow) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [customerList]);

  useEffect(() => {
    setCurrentPage(1);
    getCustomerData();

    return () => {
      clearTimeout(snackbarTimer.current);
    };
  }, []);

  return (
    <PaperContainer className={classes.container}>
      <Typography variant="h6">客戶簡稱設定</Typography>
      <CustomerTypeToolbar handleSearch={handleSearch} isEditing={isEditing} />
      <Snackbar msg={statusMsg} type={statusType} open={showStatus} className={classes.snackbar} />
      <ConfigTable
        isLoading={isLoading}
        isAdding={isAdding}
        handleIsAdding={setIsAdding}
        headCells={settingsConfigTableHeadCells}
        tableContentList={customerList}
        onRowEditClick={onRowEditClick}
        handleRowError={handleRowError}
        handleRowEditing={handleRowEditing}
        editedData={editedData}
        handleEditData={handleEditData}
      />
      <TablePagination
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handleChangePage}
        isEditing={isEditing}
      />
    </PaperContainer>
  );
};

export default SettingsConfigCustomerType;
