export const checkboxActions = {
  INIT: 'INIT',
  CHANGE: 'CHANGE',
  RESET: 'RESET',
};

const checkboxReducer = (state, action) => {
  switch (action.type) {
    case checkboxActions.INIT:
      return [...action.init];
    case checkboxActions.CHANGE:
      return state.map((item) => {
        if (item.name === action.target) {
          return { ...item, checked: !item.checked };
        }
        return item;
      });
    case checkboxActions.RESET:
      return state.map((item) => ({
        ...item,
        checked: false,
      }));
    default:
      throw Error('Should not be in here!');
  }
};

export default checkboxReducer;
