import { formingType, manufactureType, receiptType } from '../constants/enums';

const useFormingType = (type) => {
  switch (type) {
    case manufactureType.FORMING:
      return {
        name: '無',
        value: type,
        id: receiptType.FORMING,
      };
    case manufactureType.DRILLING:
      return {
        name: '鑽孔',
        value: type,
        id: receiptType.DRILLING,
      };
    case manufactureType.ALIGNING:
      return {
        name: '排列',
        value: type,
        id: receiptType.ALIGNING,
      };
    case manufactureType.NONE:
      return {
        name: '尚未設定',
        value: type,
        id: receiptType.FORMING,
      };
    default:
      return {
        name: '-',
        value: manufactureType.FORMING,
        id: receiptType.FORMING,
      };
  }
};

export const useFormingTypeName = (type) => {
  switch (type) {
    case manufactureType.FORMING:
      return {
        name: '壓型',
        value: type,
        id: receiptType.FORMING,
      };
    case manufactureType.DRILLING:
      return {
        name: '鑽孔',
        value: type,
        id: receiptType.DRILLING,
      };
    case manufactureType.ALIGNING:
      return {
        name: '排列',
        value: type,
        id: receiptType.ALIGNING,
      };
    default:
      return {
        name: '壓型',
        value: manufactureType.FORMING,
        id: receiptType.FORMING,
      };
  }
};

export const useFormingTypeToEnum = (type) => {
  switch (type) {
    case manufactureType.FORMING:
      return formingType.FORMING;
    case manufactureType.DRILLING:
      return formingType.DRILLING;
    case manufactureType.ALIGNING:
      return formingType.ALIGNING;
    default:
      return formingType.NONE;
  }
};

export default useFormingType;
