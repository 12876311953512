const regex = {
  BUCK_NO: /^(\d|\w)*$/,
  FORMING_TRANS_ID: /^TF\d{8}$/,
  ALIGNING_TRANS_ID: /^TA\d{8}$/,
  DRILLING_TRANS_ID: /^TD\d{8}$/,
  FORMING_SUBTRANS_ID: /^TF\d{8}-\d+$/,
  SINTERING_TRANS_ID: /^TH\d{8}$/,
  SINTERING_SUBTRANS_ID: /^TH\d{8}-\d+$/,
  GRINDER_TRANS_ID: /^TI\d{8}$/,
  GRINDING_SUBTRANS_ID: /^TI\d{8}-\d+$/,
  TESTING_TRANS_ID: /^TJ\d{8}$/,
  PACKAGING_TRANS_ID: /^TK\d{8}$/,
  EMAIL: /^[^\s@]+@[^\s@]+$/,
  // NUMBER: /^((0{1}\.\d{1})|([1-9]\d*\.{1}\d{1})|([1-9]+\d*))$/,
  NUMBER: /^\d+[.]?\d*$/,
  SPLIT_STRING: /,/gi,
};

export default regex;
