/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { DialogContext } from '../../contexts/DialogContext';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import TransItemRow from './TransItemRow';
import CircularProgress from '../../components/CircularProgress/CircularProgress';
import Button from '../../components/Button/Button';
import useStyles from './useStyles';
import useAxios from '../../hooks/useAxios';
import { dialogActions } from '../../reducers/dialogReducer';
import { downloadFile } from '../../Utils/fileUtil';
import useCancelToken from '../../hooks/useCancelToken';

const TransTable = ({ queryResult, queryInfoProcess }) => {
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();

  const [exportLoading, setExportLoading] = useState(false);

  const handleExport = async () => {
    const data = queryInfoProcess({ isExcel: true });
    setExportLoading(true);
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/transExport',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { url } = result;
      downloadFile(url);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法匯出移轉單',
          message: noPrivilegeMsg || '目前暫時無法匯出移轉單，請稍後再試，或請聯絡網管人員',
          singleBtn: true,
        },
      });
    }
    setExportLoading(false);
  };

  return (
    <>
      {queryResult.length > 0 && (
        <PaperContainer className={classes.container}>
          <Typography variant="h6">移轉單查詢結果</Typography>
          <Grid container className={classes.content}>
            <div className={`${classes.table_header} ${classes.table_row}`}>
              <Grid item className={classes.table_column}>
                <Typography variant="subtitle2">移轉單號</Typography>
              </Grid>
              <Grid item className={classes.table_column_updateDate}>
                <Typography variant="subtitle2">更新日期</Typography>
              </Grid>
              <Grid item className={classes.table_column_station}>
                <Typography variant="subtitle2">製程站別</Typography>
              </Grid>
              <Grid item className={classes.table_column_scOdNo}>
                <Typography variant="subtitle2">工令單號</Typography>
              </Grid>
              <Grid item className={classes.table_column_elNo}>
                <Typography variant="subtitle2">料號</Typography>
              </Grid>
              <Grid item className={classes.table_column_elNo}>
                <Typography variant="subtitle2">壓型加工</Typography>
              </Grid>
              <Grid item className={classes.table_column_elName}>
                <Typography variant="subtitle2">產品資訊</Typography>
              </Grid>
              <Grid item className={classes.table_column_transQty}>
                <Typography variant="subtitle2">移轉數量</Typography>
              </Grid>
              <Grid item className={classes.table_column_updateUser}>
                <Typography variant="subtitle2">更新人員</Typography>
              </Grid>
              <Grid item className={classes.table_column_defectSize}>
                <Typography variant="subtitle2">不良品數量</Typography>
              </Grid>
            </div>
            <ul className={classes.table_content}>
              {queryResult.map((row) => (
                <TransItemRow
                  key={row.transId}
                  transId={row.transId}
                  updateDate={row.updateDate}
                  scOdNo={row.scOdNo}
                  stationType={row.stationType}
                  transQty={row.transQty}
                  updateUser={row.updateUser}
                  elNO={row.elNO}
                  elName={row.elName}
                  defectSize={row.defectSize}
                  manufactureType={row.manufactureType}
                />
              ))}
            </ul>
            <Grid item className={classes.export_footer}>
              {exportLoading && <CircularProgress id="exportLoading" size={30} />}
              <Button
                variant="contained"
                color="primary"
                onClick={handleExport}
                startIcon={<GetAppRoundedIcon />}
                rounded
                customClasses={classes.export_btn}
              >
                匯出
              </Button>
            </Grid>
          </Grid>
        </PaperContainer>
      )}
    </>
  );
};

TransTable.propTypes = {
  queryResult: PropTypes.array.isRequired,
  queryInfoProcess: PropTypes.func.isRequired,
};

export default TransTable;
