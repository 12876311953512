/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Grid from '../../components/Grid/Grid';
import Typography from '../../components/Typography/Typography';
import ReadonlyField from '../../components/ReadonlyField/ReadonlyField';
import Button from '../../components/Button/Button';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import useCancelToken from '../../hooks/useCancelToken';
import { DialogContext } from '../../contexts/DialogContext';
import useAxios from '../../hooks/useAxios';
import { dialogActions } from '../../reducers/dialogReducer';
import InputField from '../../components/InputField/InputField';
import Radio from '../../components/Radio/Radio';

const useStyles = makeStyles()((theme) => ({
  content: {
    marginTop: 12,
  },
  list_header: {
    marginTop: 20,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    display: 'grid',
    width: '100%',
    gridTemplateColumns: `minmax(auto, 80px) minmax(80px, 180px) minmax(80px, 200px)  minmax(auto, 280px)`,
    columnGap: theme.spacing(2.5),
  },
  list_content: {
    marginBottom: 10,
  },
  list_content_end: {
    marginBottom: 10,
    textAlign: 'end',
  },
  list_row: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    display: 'grid',
    gridTemplateColumns: `minmax(auto, 80px) minmax(80px, 180px) minmax(80px, 200px) minmax(auto, 280px)`,
    columnGap: theme.spacing(2.5),
    width: '100%',
    alignItems: 'center',
  },
  checkbox: {
    marginLeft: -10,
    justifyContent: 'start',
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
  confirm_btn: {
    marginTop: theme.spacing(3),
  },
}));

const StorageQty = ({ storageInfo, storageList, handleGoToTransCreate }) => {
  const [inventoryList, setInventoryList] = useState(storageList);
  const [selectedId, setSelectedId] = useState(null);
  const [currentSelectedRow, setCurrentSelectedRow] = useState({
    elNo: null,
    id: null,
    qty: null,
    error: false,
  });
  const [errorMessage, setErrorMessage] = useState('數量不得為0');
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();

  // 送出暫存工令
  const handleConfirm = async () => {
    if (Number(currentSelectedRow.qty) < 16) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '暫存工令移轉失敗',
          message: '轉移失敗，轉移數量不得小於16！',
        },
      });
      return;
    }
    const data = {
      scOdno: storageInfo.workId.trim(),
      inventory: {
        elNo: currentSelectedRow.elNo,
        srcWorkId: currentSelectedRow.id,
        inputQuantity: Number(currentSelectedRow.qty),
      },
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/inventorySetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '暫存工令移轉成功',
          message: '轉移成功，幫您導回建立新移轉單頁面！',
          handleConfirm: () => {
            handleGoToTransCreate();
          },
        },
      });
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '暫存工令移轉失敗',
          message: noPrivilegeMsg || result,
        },
      });
    }
  };

  function handleRadio({ id }) {
    const currentSelected = inventoryList.find((item) => item.workId === Number(id));
    setSelectedId(Number(id));
    setCurrentSelectedRow({
      id: Number(id),
      qty: currentSelected.qty,
      elNo: currentSelected.elNo,
      error: false,
    });

    const data = inventoryList.map((item) =>
      item.workId === Number(id) ? { ...item, disabled: false } : { ...item, disabled: true }
    );
    setInventoryList(data);
  }

  function handleQtyChange({ id, value, totalQty }) {
    const currentSelected = inventoryList.find((item) => item.workId === Number(id));
    setCurrentSelectedRow({
      id: Number(id),
      qty: value,
      elNo: currentSelected.elNo,
      error: value > currentSelected.qty || Number(value) <= 0,
    });
    setErrorMessage(
      // eslint-disable-next-line no-nested-ternary
      Number(value) === 0
        ? '數量不得為0'
        : Number(value) < 0
        ? '數量不得小於0'
        : `數量不能超過${totalQty}`
    );
  }

  return (
    <>
      <PaperContainer>
        <Typography variant="h6">轉入正式工令資訊</Typography>
        <Grid
          container
          spacing={3}
          style={{
            marginTop: 16,
          }}
        >
          <Grid item xs={2}>
            <ReadonlyField label="轉入工令" value={storageInfo.workId} />
          </Grid>
          <Grid item xs={2}>
            <ReadonlyField label="工令總數" value={storageInfo.qty} />
          </Grid>
          <Grid item xs={2}>
            <ReadonlyField label="已壓型總數" value={storageInfo.formingQty} />
          </Grid>
          <Grid item xs={2}>
            <ReadonlyField label="鑽孔排列總數" value={storageInfo.drillAlignQty} />
          </Grid>
          <Grid item xs={2}>
            <ReadonlyField label="損耗總數" value={storageInfo.wasteQty} />
          </Grid>
          <Grid item xs={2}>
            <ReadonlyField label="掛帳工令總數" value={storageInfo.belongQty} />
          </Grid>
        </Grid>
      </PaperContainer>

      <PaperContainer className={classes.content}>
        <Grid>
          <Typography variant="subtitle1">暫存工令：{storageInfo.elNo}</Typography>
        </Grid>
        <Grid>
          {inventoryList.length > 0 ? (
            <>
              <div className={`${classes.list_header}`}>
                <Typography variant="subtitle1">
                  <></>
                </Typography>
                <Typography variant="subtitle1">原始工令單號</Typography>
                <Typography variant="subtitle1">數量</Typography>
              </div>
              <div className={classes.list_content}>
                {inventoryList.map((row) => (
                  <div className={`${classes.list_row}`} key={row.transId}>
                    <Radio
                      value={row.workId}
                      selectedValue={selectedId}
                      onChange={(e) => handleRadio({ id: e.target.value })}
                      className={classes.radio}
                    />
                    <Typography variant="body1">{row.workNo}</Typography>
                    <Typography variant="body1">{row.qty}</Typography>
                    <InputField
                      className={classes.table_input}
                      id={row.workId}
                      label="數量"
                      value={
                        currentSelectedRow.id === row.workId
                          ? currentSelectedRow.qty
                          : row.selectedQty
                      }
                      variant="outlined"
                      onChange={(e) =>
                        handleQtyChange({
                          id: row.workId,
                          value: e.target.value,
                          totalQty: row.qty,
                        })
                      }
                      disabled={row.disabled}
                      placeholder="請輸入想要轉出數量"
                      name="measureDeviation"
                      type="number"
                      error={
                        currentSelectedRow.id === row.workId ? currentSelectedRow.error : false
                      }
                      errormsg={errorMessage}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <Typography style={{ marginTop: 12 }}>目前沒有暫存移轉單</Typography>
          )}
        </Grid>
      </PaperContainer>
      <Grid item xs={12} container justifyContent="end" className={classes.confirm_btn}>
        <Button
          variant="contained"
          rounded
          startIcon={<CheckRoundedIcon />}
          onClick={handleConfirm}
          disabled={inventoryList.length === 0}
        >
          確認
        </Button>
      </Grid>
    </>
  );
};

StorageQty.defaultProps = {
  storageList: [],
};

StorageQty.propTypes = {
  storageInfo: PropTypes.object.isRequired,
  handleGoToTransCreate: PropTypes.func.isRequired,
  storageList: PropTypes.array,
};

export default StorageQty;
