/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '../Checkbox/Checkbox';
import { checkboxActions } from '../../reducers/checkboxReducer';

const useStyles = makeStyles()({
  row: {
    flexDirection: 'row',
  },
});

const CheckboxGroup = ({
  itemList,
  disabled,
  checkboxDispatch,
  gap,
  onChange,
  direction,
  reverse,
}) => {
  // eslint-disable-next-line prettier/prettier
  const { classes } = useStyles();

  const handleChange = (e) => {
    checkboxDispatch({
      type: checkboxActions.CHANGE,
      target: e.target.name,
    });
    if (typeof onChange !== 'function') return;
    onChange(e);
  };
  return (
    <FormControl component="fieldset" fullWidth>
      <FormGroup className={direction === 'row' && classes.row}>
        {itemList &&
          itemList.map((item) => (
            <Checkbox
              name={item.name}
              label={item.label}
              checked={item.checked}
              handleChange={handleChange}
              disabled={disabled}
              key={item.name}
              style={{ marginBottom: `${gap * 10}px` }}
              reverse={reverse}
            />
          ))}
      </FormGroup>
    </FormControl>
  );
};

CheckboxGroup.defaultProps = {
  disabled: false,
  gap: 0,
  onChange: null,
  direction: 'column',
  reverse: false,
};

CheckboxGroup.propTypes = {
  itemList: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  checkboxDispatch: PropTypes.func.isRequired,
  gap: PropTypes.number,
  onChange: PropTypes.func,
  direction: PropTypes.string,
  reverse: PropTypes.bool,
};

export default CheckboxGroup;
