/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import ConfigTable from '../../../components/ConfigTable/ConfigTable';
import TablePagination from '../../../components/TablePagination/TablePagination';
import styleConst from '../../../constants/style';

const settingsConfigTableHeadCells = [
  {
    id: 1,
    label: '角色名稱',
  },
  {
    id: 2,
    label: '角色權限',
  },
];

const rowsPerPage = styleConst.ROWS_PER_PAGE;

const SettingPrivilegeRole = ({
  settingsConfigTableContent,
  totalRows,
  currentPage,
  handleChangePage,
  handleAdding,
  handleEditing,
  handleViewing,
  handleDelete,
}) => (
  <PaperContainer>
    <Grid
      container
      style={{ marginBottom: 20, width: '100%' }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="h6">角色列表</Typography>
      <Grid>
        <Button
          variant="outlined"
          color="primary"
          rounded
          startIcon={<AddIcon />}
          onClick={handleAdding}
        >
          新增一項
        </Button>
      </Grid>
    </Grid>
    <ConfigTable
      isView
      headCells={settingsConfigTableHeadCells}
      tableContentList={settingsConfigTableContent}
      isDeleteBtnShow
      onRowEditClick={handleEditing}
      onRowDelete={handleDelete}
      onRowView={handleViewing}
    />
    <TablePagination
      totalRows={totalRows}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      onChangePage={handleChangePage}
    />
  </PaperContainer>
);

export default SettingPrivilegeRole;

SettingPrivilegeRole.propTypes = {
  settingsConfigTableContent: PropTypes.array.isRequired,
  totalRows: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleAdding: PropTypes.func.isRequired,
  handleEditing: PropTypes.func.isRequired,
  handleViewing: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
