import React, { useContext, useState } from 'react';
import { addDays, format, isWithinInterval } from 'date-fns';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import useCommonStyles from '../../useStyles';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Grid from '../../../../components/Grid/Grid';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import useStyles from '../useStyles';
import Button from '../../../../components/Button/Button';
import { dialogActions } from '../../../../reducers/dialogReducer';
import { DialogContext } from '../../../../contexts/DialogContext';
import useCancelToken from '../../../../hooks/useCancelToken';
import useAxios from '../../../../hooks/useAxios';
import Typography from '../../../../components/Typography/Typography';
import { downloadFile } from '../../../../Utils/fileUtil';

const initErrorMsg = '結束日期不得早於起始日期';

const FormingWorknoDaily = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;

  const [filterField, setFilterField] = useState({
    startDate: addDays(new Date(), -6),
    endDate: new Date(),
  });
  const [filterErr, setFilterErr] = useState({ startDate: false, endDate: false });
  const [errorMsg, setErrorMsg] = useState(initErrorMsg);

  const handleDateChange = (date, type) => {
    setFilterField((prev) => ({
      ...prev,
      [type]: date,
    }));
  };

  const resetFilterFieldErr = () => {
    setFilterErr({
      startDate: false,
      endDate: false,
    });
    setErrorMsg(initErrorMsg);
  };

  const isWithinOneWeek = (start, end) => {
    const maxEndDate = addDays(start, 7);
    return isWithinInterval(end, { start, end: maxEndDate });
  };

  const validationConfig = () => {
    resetFilterFieldErr();
    const { startDate, endDate } = filterField;

    if (+new Date(startDate) > +new Date(endDate)) {
      setFilterErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      return false;
    }

    if (!isWithinOneWeek(startDate, endDate)) {
      setFilterErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      setErrorMsg('所選區間不得大於一週');
      return false;
    }

    return true;
  };

  const handleDownload = async () => {
    if (!validationConfig()) return;
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/findDailyForming',
      {
        checkBegin: format(new Date(filterField.startDate), 'yyyy-MM-dd'),
        checkEnd: format(new Date(filterField.endDate), 'yyyy-MM-dd'),
      },
      true,
      cancelToken
    );
    if (status) {
      const { fileUrl } = result;
      downloadFile(fileUrl);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '下載壓型工令日報表失敗！',
          message: noPrivilegeMsg || result,
        },
      });
    }
  };
  return (
    <>
      <PaperContainer className={commonClasses.container}>
        <Grid container alignItems="center">
          <Typography variant="h6" style={{ marginRight: '24px' }}>
            下載壓型工令日報表
          </Typography>
        </Grid>
        <Grid container className={commonClasses.content} direction="column">
          <Grid container xs={12} alignItems="center">
            <Grid item xs={3} className={classes.filter_date_grid}>
              <DatePicker
                id="startDate"
                label="查詢起始日期"
                value={filterField.startDate}
                onChange={(e) => handleDateChange(e, 'startDate')}
                errMsg={errorMsg}
                hasDateErr={filterErr.startDate}
                maxDate={format(new Date(), 'yyyy-MM-dd')}
                containerClass={classes.filter_date_picker}
              />
            </Grid>
            <Typography
              variant="h6"
              style={{ marginRight: 24 }}
              className={filterErr ? classes.error : ''}
            >
              ~
            </Typography>
            <Grid item xs={3} className={classes.filter_date_grid}>
              <DatePicker
                id="endDate"
                label="查詢結束日期"
                value={filterField.endDate}
                onChange={(e) => handleDateChange(e, 'endDate')}
                errMsg={errorMsg}
                hasDateErr={filterErr.endDate}
                maxDate={format(new Date(), 'yyyy-MM-dd')}
                containerClass={classes.filter_date_picker}
              />
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              customClasses={classes.filter_btn}
              startIcon={<GetAppRoundedIcon />}
              onClick={handleDownload}
            >
              下載
            </Button>
          </Grid>
        </Grid>
      </PaperContainer>
    </>
  );
};

export default FormingWorknoDaily;
