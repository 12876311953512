import React, { useContext } from 'react';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import useCommonStyles from '../../useStyles';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Grid from '../../../../components/Grid/Grid';
import Button from '../../../../components/Button/Button';
import { dialogActions } from '../../../../reducers/dialogReducer';
import { DialogContext } from '../../../../contexts/DialogContext';
import useCancelToken from '../../../../hooks/useCancelToken';
import useAxios from '../../../../hooks/useAxios';
import Typography from '../../../../components/Typography/Typography';
import { downloadFile } from '../../../../Utils/fileUtil';

const FormingStock = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();
  const commonClasses = useCommonStyles().classes;

  const handleDownload = async ({ isExcel = 0 }) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/formingStock',
      {
        isExcel,
      },
      true,
      cancelToken
    );
    if (status) {
      const { fileUrl } = result;
      downloadFile(fileUrl);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '下載品項壓型統計表失敗！',
          message: noPrivilegeMsg || result,
        },
      });
    }
  };
  return (
    <>
      <PaperContainer className={`${commonClasses.container} ${commonClasses.filter_container}`}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h6" style={{ marginRight: '24px' }}>
            品項壓型統計表
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<GetAppRoundedIcon />}
            onClick={() => handleDownload({ isExcel: 1 })}
          >
            下載
          </Button>
        </Grid>
      </PaperContainer>
    </>
  );
};

export default FormingStock;
