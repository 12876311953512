import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { format } from 'date-fns';
import { alpha } from '@mui/system';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AddIcon from '@mui/icons-material/Add';
import Grid from '../../components/Grid/Grid';
import PageTitle from '../../components/PageTitle/PageTitle';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import Typography from '../../components/Typography/Typography';
import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import Toolbar from '../../components/Toolbar/Toolbar';
import NewDeclareAction from './NewDeclareAction';
import Divider from '../../components/Divider/Divider';
import ConfigTable from '../../components/ConfigTable/ConfigTable';
import useCancelToken from '../../hooks/useCancelToken';
import useAxios from '../../hooks/useAxios';
import Snackbar from '../../components/Snackbar/Snackbar';
import { dialogActions } from '../../reducers/dialogReducer';
import { DialogContext } from '../../contexts/DialogContext';
import FilterInfo from './FilterInfo';
import { manufactureType } from '../../constants/enums';
import { useFormingTypeToEnum } from '../../hooks/useFormingType';

const tableHeadCells = [
  { id: 'declareDate', numeric: false, disablePadding: true, label: '申報日期' },
  {
    id: 'declareQty',
    numeric: false,
    disablePadding: true,
    label: '申報數量',
  },
];

const initWorkInfo = {
  formingType: '',
  elNo: '',
  customer: '',
  materialName: '',
  productName: '',
  scFDate: '',
  odReDate: '',
  scQty: '',
  normalFormingQty: '',
  otherFormingQty: '',
  defectQty: '',
  storageQty: '',
};

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(1.5),
  },
  content: {
    marginTop: theme.spacing(4),
  },
  error: {
    marginBottom: theme.spacing(2.5),
  },
  confirm: {
    marginTop: theme.spacing(3),
  },
  dashed_divider: {
    margin: '24px -26px',
    background: `repeating-linear-gradient(90deg,${alpha(theme.palette.common.black, 0.2)},${alpha(
      theme.palette.common.black,
      0.2
    )} 6px,transparent 6px,transparent 12px)`,
    border: 'none',
    height: 1,
  },
  toolbar: {
    '&.MuiToolbar-root': {
      padding: 0,
    },
  },
  snackbar: {
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: '100%',
  },
}));

const FormingLossDeclare = () => {
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();
  const [workNo, setWorkNo] = useState('');
  const [workNoErr, setWorkNoErr] = useState(false);
  const [workInfo, setWorkInfo] = useState(initWorkInfo);
  const [isShowRecordList, setIsShowRecordList] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [maxQty, setMaxQty] = useState(null);
  const [tableContentList, setTableContentList] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [statusMsg, setStatusMsg] = useState('');
  const [showStatus, setShowStatus] = useState(false);
  const [statusType, setStatusType] = useState('success');
  const snackbarTimer = useRef(null);

  const handleChange = (e) => {
    setWorkNo(e.target.value);
  };

  const getDeclareRecordList = async (workId) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/findWasteRecord',
      { workId },
      true,
      cancelToken
    );
    if (status) {
      const { quantityMax, feWasteRecord, editableRecordId } = result;
      setIsAdding(false);
      setMaxQty(quantityMax);
      setIsShowRecordList(true);
      setTableContentList(
        feWasteRecord && feWasteRecord.length > 0
          ? feWasteRecord.map((item, i) => ({
              rowId: item.wasteRecordId,
              formingType: item.manufactureType,
              isChecked: false,
              isEditing: false,
              canDelete: editableRecordId === item.wasteRecordId,
              canEdit: editableRecordId === item.wasteRecordId,
              columns: [
                {
                  name: 'declareDate',
                  label: '申報日期',
                  placeholder: '請輸入申報日期',
                  value: item.recordDate,
                  displayValue: format(new Date(item.recordDate), 'yyyy/MM/dd'),
                  visible: true,
                  type: 'date',
                  isEditable: true,
                  isRequired: true,
                  error: false,
                },
                {
                  name: 'declareQty',
                  label: '申報數量',
                  placeholder: '請輸入申報數量',
                  value: item.wasteQuantity,
                  displayValue: item.wasteQuantity,
                  visible: true,
                  type: 'text',
                  isEditable: true,
                  isRequired: true,
                  error: false,
                },
              ],
            }))
          : []
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '取得損耗申報紀錄失敗',
          message:
            rtCode === 'E99'
              ? result
              : noPrivilegeMsg || '取得損耗申報紀錄失敗，請稍後再試，或聯絡網管人員！',
        },
      });
    }
  };

  const validationWorkNo = () => {
    if (workNo.length === 0) {
      setWorkNoErr(true);
      return false;
    }
    return true;
  };

  const handleFetch = async () => {
    setIsShowRecordList(false);
    if (!validationWorkNo()) return;
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/findWorkOrderInfo',
      { scOdno: workNo.trim() },
      true,
      cancelToken
    );
    if (status) {
      setWorkNoErr(false);
      const {
        sc: {
          elNo,
          customerId,
          customerAlias,
          workId,
          materialName,
          prodName,
          scFdat,
          odRedat,
          manufactureType: formingManufactureType,
          scQty,
          prodPlaning: {
            createdTotalQty,
            transferQty,
            defectQty,
            formingInventoryQty,
            belongFormTotalQty,
            // 可鑽孔排列數量 (或是 待加工品數量)
            waitForDrillingOrAligningQty,
          },
        },
      } = result;

      setWorkInfo({
        formingType: formingManufactureType,
        elNo,
        workId,
        customer: customerAlias || customerId || '',
        materialName: materialName || '',
        productName: prodName || '',
        scFDate: scFdat,
        odReDate: odRedat,
        scQty,
        normalFormingQty: createdTotalQty,
        otherFormingQty: transferQty,
        defectQty,
        storageQty: formingInventoryQty,
        belongQty: belongFormTotalQty,
        // 可鑽孔排列數量 (或是 待加工品數量)
        waitQty: waitForDrillingOrAligningQty,
      });
      if (formingManufactureType === manufactureType.FORMING) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '該工令壓型加工為無',
            message: '該工令壓型加工為無，不得做申報動作！',
          },
        });
        return;
      }
      if (formingManufactureType === manufactureType.NONE) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '尚未設定該工令壓型加工類別',
            message: '該工令壓型加工尚未設定，不得做申報動作！',
          },
        });
        return;
      }
      getDeclareRecordList(workId);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '取得工令資訊失敗',
          message: noPrivilegeMsg || '取得工令資訊失敗，請稍後再試，或聯絡網管人員！',
        },
      });
    }
  };

  const openSnackbar = () => {
    setShowStatus(true);
    clearTimeout(snackbarTimer.current);
    snackbarTimer.current = setTimeout(() => {
      setShowStatus(false);
      clearTimeout(snackbarTimer.current);
    }, 3000);
  };

  const settingDeclare = async ({ data, type, message, errorMessage }) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/wasteRecordSetting',
      data,
      true,
      cancelToken
    );

    if (status) {
      setStatusMsg(message);
      setStatusType('success');
      handleFetch();
      getDeclareRecordList(workInfo.workId);
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || result || errorMessage);
    }
    openSnackbar();
    if (type === 'add') {
      setIsAdding(false);
      // setLastDataId()
    }

    if (type === 'update') {
      setEditedData({});
    }
  };

  const onRowEditClick = (targetRowId, isOpenEdit) => {
    setTableContentList((prev) =>
      prev.map((config) =>
        config.rowId === targetRowId
          ? { ...config, columns: [...config.columns], isEditing: isOpenEdit, isChecked: false }
          : config
      )
    );
    if (isOpenEdit) {
      const targetRow = tableContentList.find((config) => config.rowId === targetRowId);
      setEditedData({
        declareId: targetRow.rowId,
        declareDate: targetRow.columns[0].value,
        declareQty: targetRow.columns[1].value,
        declareFormingType: targetRow.formingType,
      });
    } else {
      setEditedData({});
    }
  };

  const handleRowEditing = (e) => {
    const { name, value } = e.target;
    const declareList = tableContentList.map((item) => item.columns[1].value);
    declareList.pop();
    const declareListQty = declareList.reduce((a, b) => a + b);

    const data = {
      [name]: value,
      error: false,
      errormsg: '請輸入申報數量',
    };

    if (!value) {
      data.error = true;
    }
    if (maxQty < value) {
      data.error = true;
      data.errormsg = '申報數量不可大於(已壓型總數 + 掛帳工令總數 - 鑽孔/排列數量 - 耗損總數)';
    }
    if (workInfo.defectQty - declareListQty + Number(maxQty) < value) {
      data.error = true;
      data.errormsg = '申報數量不可大於(已壓型總數 + 掛帳工令總數 - 鑽孔/排列數量 - 耗損總數)';
    } else {
      data.error = false;
    }
    setEditedData((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const handleRowDateEditing = (date) => {
    setEditedData((prev) => ({
      ...prev,
      declareDate: date,
    }));
  };

  const handleEditData = () => {
    const data = {
      wasteRecordDtos: [
        {
          wasteRecordId: editedData.declareId,
          workId: workInfo.workId,
          manufactureType: useFormingTypeToEnum(editedData.declareFormingType),
          recordDate: format(new Date(editedData.declareDate), 'yyyy-MM-dd'),
          wasteQuantity: editedData.declareQty,
          isEnable: 1,
        },
      ],
    };
    settingDeclare({
      data,
      type: 'update',
      message: '已成功更新申報紀錄！',
      errorMessage: '更新申報紀錄失敗，請稍後再試，或通知網管人員！',
    });
  };

  const handleRowError = (targetRow, validation) => {
    setTableContentList((prev) =>
      prev.map((config) =>
        config.rowId === targetRow.rowId
          ? {
              ...config,
              columns: targetRow.columns.map((col) => ({ ...col, error: !validation[col.name] })),
            }
          : config
      )
    );
  };

  // 刪除單筆
  const handleRowDelete = async (declare) => {
    const data = {
      wasteRecordDtos: [
        {
          wasteRecordId: declare.rowId,
          workId: workInfo.workId,
          manufactureType: useFormingTypeToEnum(declare.formingType),
          recordDate: format(new Date(declare.columns[0].displayValue), 'yyyy-MM-dd'),
          wasteQuantity: declare.columns[1].displayValue,
          isEnable: 0,
        },
      ],
    };

    settingDeclare({
      data,
      type: 'delete',
      message: `已成功刪除「 申報日期：${declare.columns[0].displayValue} ， 申報數量：${declare.columns[1].displayValue} 」！`,
      errorMessage: `刪除「 申報日期：${declare.columns[0].displayValue} ， 申報數量：${declare.columns[1].displayValue} 」失敗，請稍後再試！`,
    });
  };

  useEffect(() => {
    const hasEditingRow = tableContentList.find((item) => item.isEditing);
    if (hasEditingRow) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [tableContentList]);

  return (
    <Grid container>
      <PageTitle title="鑽孔排列損耗申報" />
      <PaperContainer className={classes.container}>
        <Typography variant="h6">工令申報鑽孔排列損耗/盤點數量</Typography>
        <Grid container spacing={2} className={classes.content} alignItems="flex-end">
          <Grid item xs={5}>
            <InputField
              type="text"
              id="workNo"
              name="workNo"
              label="工令單號"
              placeholder="請輸入工令單號"
              value={workNo}
              errormsg="請正確填寫工令單號"
              onChange={handleChange}
              error={workNoErr}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SearchRoundedIcon />}
              onClick={handleFetch}
              customClasses={workNoErr && classes.error}
            >
              查詢
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.dashed_divider} />
        <FilterInfo workInfo={workInfo} classes={classes} />
      </PaperContainer>
      {isShowRecordList && (
        <PaperContainer className={classes.container}>
          <Typography variant="h6">鑽孔損耗申報紀錄</Typography>
          {isAdding ? (
            <NewDeclareAction
              workId={workInfo.workId}
              formingType={workInfo.formingType}
              onAdd={settingDeclare}
              onCloseAdding={() => setIsAdding(false)}
              maxQty={maxQty}
            />
          ) : (
            <Toolbar className={classes.toolbar}>
              <Grid
                container
                style={{ marginTop: 25, marginBottom: 20, width: '100%' }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    rounded
                    startIcon={<AddIcon />}
                    onClick={() => setIsAdding(true)}
                  >
                    新增一項
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          )}
          <Snackbar
            msg={statusMsg}
            type={statusType}
            open={showStatus}
            className={classes.snackbar}
          />
          {tableContentList.length <= 0 ? (
            <div style={{ textAlign: 'center' }}>
              <Typography variant="subtitle1">目前沒有資料</Typography>
            </div>
          ) : (
            <ConfigTable
              isDeleteBtnShow
              headCells={tableHeadCells}
              tableContentList={tableContentList}
              editedData={editedData}
              isAdding={isAdding}
              isEditing={isEditing}
              handleIsAdding={setIsAdding}
              onRowEditClick={onRowEditClick}
              handleRowEditing={handleRowEditing}
              handleRowDateEditing={handleRowDateEditing}
              handleEditData={handleEditData}
              handleRowError={handleRowError}
              onRowDelete={handleRowDelete}
            />
          )}
        </PaperContainer>
      )}
    </Grid>
  );
};
export default FormingLossDeclare;
