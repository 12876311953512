/* eslint-disable import/prefer-default-export */
import {
  pink,
  indigo,
  purple,
  blue,
  cyan,
  teal,
  green,
  lime,
  amber,
  deepOrange,
  brown,
  grey,
} from '@mui/material/colors';

export const CHART_COLORS = [
  pink[500],
  amber[500],
  lime[500],
  green[500],
  teal[500],
  blue[500],
  cyan[500],
  indigo[500],
  purple[500],
  grey[500],
  brown[500],
  deepOrange[500],
];
