/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import useStyles from './useStyles';

const ReadonlyField = ({ label, value, name, className }) => {
  const [inputValue, setInputValue] = useState('');
  const { classes } = useStyles();
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  const onChange = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <FormControl fullWidth variant="filled" className={className}>
      <InputLabel htmlFor={name} className={classes.label} shrink={false}>
        {label}
      </InputLabel>
      <FilledInput
        id={name}
        name={name}
        value={inputValue}
        type="text"
        disabled
        disableUnderline
        classes={{ root: classes.root, disabled: classes.disabled, input: classes.input }}
        onChange={onChange}
      />
    </FormControl>
  );
};

ReadonlyField.defaultProps = {
  className: {},
};

ReadonlyField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ReadonlyField;
