/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import useStyles from '../useStyles';
import useCommonStyles from '../../useStyles';
import { isFormingOutOfSpec } from '../../detailUtils';

const FormingDetail = ({ renderRows, specConfig }) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;

  return (
    <div className={classes.detail_table}>
      <Grid container className={classes.detail_header_row} style={{ marginBottom: '1rem' }}>
        <Grid item xs={3} className={`${classes.detail_header_column} ${classes.forming_column}`}>
          <Typography variant="subtitle2" className={classes.forming_spec}>
            壓高上限：
          </Typography>
          <Typography variant="subtitle1">{specConfig.heightMax}</Typography>
        </Grid>
        <Grid item xs={3} className={`${classes.detail_header_column} ${classes.forming_column}`}>
          <Typography variant="subtitle2" className={classes.forming_spec}>
            壓高下限：
          </Typography>
          <Typography variant="subtitle1">{specConfig.heightMin}</Typography>
        </Grid>
        <Grid item xs={3} className={`${classes.detail_header_column} ${classes.forming_column}`}>
          <Typography variant="subtitle2" className={classes.forming_spec}>
            重量上限：
          </Typography>
          <Typography variant="subtitle1">{specConfig.weightMax}</Typography>
        </Grid>
        <Grid item xs={3} className={`${classes.detail_header_column} ${classes.forming_column}`}>
          <Typography variant="subtitle2" className={classes.forming_spec}>
            重量下限：
          </Typography>
          <Typography variant="subtitle1">{specConfig.weightMin}</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.detail_header_row}>
        <Grid item xs={1} className={`${classes.detail_header_column} ${classes.forming_column}`}>
          <Typography variant="subtitle1">模具</Typography>
        </Grid>
        <Grid item xs={1} className={`${classes.detail_header_column} ${classes.forming_column}`}>
          <Typography variant="subtitle1">刀數</Typography>
        </Grid>
        <Grid item xs={1} className={`${classes.detail_header_column} ${classes.forming_column}`}>
          <Typography variant="subtitle1">壓高</Typography>
        </Grid>
        <Grid item xs={1} className={`${classes.detail_header_column} ${classes.forming_column}`}>
          <Typography variant="subtitle1">重量</Typography>
        </Grid>
        <Grid item xs={6} className={`${classes.detail_header_column} ${classes.forming_defect}`}>
          <Typography variant="subtitle1">Defect Type</Typography>
        </Grid>
        <Grid item xs={2} className={`${classes.detail_header_column} ${classes.forming_note}`}>
          <Typography variant="subtitle1">備註</Typography>
        </Grid>
      </Grid>
      {renderRows.map((row) => {
        const { height, weight } = row;
        const { isHeightOutSpec, isWeightOutSpec } = isFormingOutOfSpec(specConfig, {
          height,
          weight,
        });
        return (
          <Grid container className={classes.detail_row} key={row.detailId}>
            <Grid item xs={1} className={`${classes.detail_column} ${classes.forming_column}`}>
              <Typography variant="body1">{row.mold}</Typography>
            </Grid>
            <Grid item xs={1} className={`${classes.detail_column} ${classes.forming_column}`}>
              <Typography variant="body1">{row.cutNo}</Typography>
            </Grid>
            <Grid
              item
              xs={1}
              className={`${classes.detail_column} ${classes.forming_column} ${
                isHeightOutSpec ? commonClasses.out_spec : ''
              }`}
            >
              <Typography variant="body1">{row.height}</Typography>
            </Grid>
            <Grid
              item
              xs={1}
              className={`${classes.detail_column} ${classes.forming_column} ${
                isWeightOutSpec ? commonClasses.out_spec : ''
              }`}
            >
              <Typography variant="body1">{row.weight}</Typography>
            </Grid>
            <Grid item xs={6} className={`${classes.detail_column} ${classes.forming_defect}`}>
              <Typography variant="body1">
                {row.defectType.length !== 0
                  ? row.defectType.map((type) => type.detectName).join(', ')
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={2} className={`${classes.detail_column} ${classes.forming_note}`}>
              <Typography variant="body1">{row.note !== '' ? row.note : '-'}</Typography>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

FormingDetail.propTypes = {
  renderRows: PropTypes.array.isRequired,
  specConfig: PropTypes.object.isRequired,
};

export default FormingDetail;
