/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import useStyles from './useStyles';
import PageTitle from '../../components/PageTitle/PageTitle';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import TransLabel from '../../components/TransLabel/TransLabel';
import ReadonlyField from '../../components/ReadonlyField/ReadonlyField';
import SelectField from '../../components/SelectField/SelectField';
import Button from '../../components/Button/Button';
import Divider from '../../components/Divider/Divider';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';
import {
  manufactureType,
  processComplete as processEnum,
  stationType,
} from '../../constants/enums';
import useCancelToken from '../../hooks/useCancelToken';
import Checkbox from '../../components/Checkbox/Checkbox';
import { handleUpdateTransTime } from '../../Utils/processTimeUtils';
import UpdateSubmitReasonSelect from './UpdateSubmitReasonSelect';
import useStationType from '../../hooks/useStationType';
import { useFormingTypeName } from '../../hooks/useFormingType';

const TransUpdateSintering = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const [transInfo, setTransInfo] = useState({
    transId: '-',
    workId: '-',
    elNo: '-',
    materialName: '-',
    productName: '-',
    createQty: '-',
    transQty: '-',
  });
  const [timer, setTimer] = useState({});
  const initialForm = {
    elecStoveNo: '0',
  };
  const {
    formData: transForm,
    setFormData: setTransForm,
    formErr: transFormErr,
    setFormErr: setTransFormErr,
    resetFormErr,
  } = useForm(initialForm);
  const [elecStoveList, setElectStoveList] = useState([]);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [processDisable, setProcessDisable] = useState(true);
  const [startDisable, setStartDisable] = useState(false);
  const [transQuantityConfirm, setTransQuantityConfirm] = useState('');
  const [transQuantityConfirmError, setTransQuantityConfirmError] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const processStartTime = useRef(null);
  const processEndTime = useRef(null);
  const reason = useRef(0);
  const reasonErr = useRef(false);
  const reasonList = useRef([]);

  const navigate = useNavigate();
  const { classes } = useStyles();
  const { trans_id } = useParams();
  const { newCancelToken } = useCancelToken();

  const getTransData = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getTrans',
      { transId: trans_id },
      true,
      cancelToken
    );
    if (status) {
      const { transfer, machine, timerRecord } = result;
      const {
        transId,
        scOdno,
        elNo,
        materialName,
        prodName,
        createQuantity,
        transQuantity,
        elecStoveNo,
        processComplete,
        sinterDate,
        isDrill,
        isAlign,
      } = transfer;
      const formingType = isDrill
        ? manufactureType.DRILLING
        : isAlign
        ? manufactureType.ALIGNING
        : manufactureType.FORMING;

      if (processComplete < processEnum.FORMING) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '無法更新此移轉單',
            message: `此移轉單尚未完成${useFormingTypeName(formingType).name}，不得進行燒結更新！`,
            singleBtn: true,
            handleConfirm: () => {
              navigate('/trans-update-failed');
            },
          },
        });
        return;
      }
      setTransInfo({
        transId,
        elNo,
        materialName,
        workId: scOdno,
        productName: prodName,
        transQty: transQuantity ? transQuantity.toString() : '-',
        createQty: createQuantity ? createQuantity.toString() : '-',
      });
      setElectStoveList(
        machine.map((item) => ({
          name: item.machineName,
          value: item.machineId.toString(),
        }))
      );
      setTransForm((prev) => ({
        ...prev,
        elecStoveNo: elecStoveNo || '0',
      }));
      /**
       * processComplete 總共有 0 - 5
       * 0: 什麼都沒有做
       * 1: 做完壓型移轉單
       * 2: 做完燒結移轉單
       * 3: 做完研磨移轉單
       * 4: 做完全檢移轉單
       * 5: 做完包裝移轉單
       * */
      if (processComplete >= 2) {
        setIsCreate(false);
      }

      // 是否需要清空timer 待討論
      // handleUpdateTransTime(newCancelToken, dialogDispatch, {
      //   transId,
      //   status: processRecordStatus.CLEAR,
      // });
      if (timerRecord) {
        setTimer(timerRecord);
        setStartDisable(true);
        setSubmitDisable(false);
        setProcessDisable(false);
      }
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料讀取錯誤',
          message: noPrivilegeMsg || '請掃描有效之移轉單或確定正確掃描移轉單。',
        },
      });
    }
  };

  const getReasonList = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getTimerReason',
      { stationType: stationType.SINTERING },
      true,
      cancelToken
    );
    if (status) {
      reasonList.current = result.timerReason;
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料讀取錯誤',
          message: noPrivilegeMsg || '目前無法取得暫停原因資訊，請稍後再試，或請聯絡網管人員！',
        },
      });
    }
  };

  const handleFormChange = (e) => {
    setTransForm({
      elecStoveNo: e.target.value,
    });
  };

  const validationTransForm = () => {
    if (transForm.elecStoveNo === '0') {
      setTransFormErr((prev) => ({
        ...prev,
        elecStoveNo: true,
      }));
      return false;
    }
    return true;
  };

  const updateSinter = async (timerRecord) => {
    resetFormErr();
    if (!validationTransForm()) return;
    setProcessDisable(true);
    const { elecStoveNo } = transForm;
    const data = {
      elecStoveNo,
      transId: trans_id,
    };
    if (isCreate) {
      data.timerRecord = timerRecord;
    }
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/updateSinter',
      data,
      true,
      cancelToken
    );
    if (status) {
      navigate('/trans-update-success');
    } else {
      if (rtCode === 'E45') {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            singleBtn: true,
            title: '錯誤',
            message: noPrivilegeMsg,
          },
        });
        return;
      }
      navigate('/trans-update-failed');
    }
  };

  const handleConfirm = () => {
    if (transQuantityConfirm === '') {
      setTransQuantityConfirmError(true);
    }
    if (transQuantityConfirm === 'check') {
      // 若點擊送出按鈕，reason 設定 -1
      reason.current = -1;

      // 以下是update timer 的邏輯
      const updateData = {
        ...timer,
        endTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        reasonId: reason.current,
      };
      updateSinter(updateData);
    }
  };
  const handleCheckBoxChange = () => {
    setTransQuantityConfirm((prev) => {
      if (prev === '') {
        if (transQuantityConfirmError) setTransQuantityConfirmError(false);
        return 'check';
      }

      return '';
    });
  };

  // 開啟 dialog
  const getReasonDialog = (validationEditReason) => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '請選擇暫停原因',
        body: (
          <UpdateSubmitReasonSelect
            reason={reason.current}
            reasonList={reasonList.current}
            reasonErr={reasonErr.current}
            handleReasonChange={(value) => {
              reason.current = value;
            }}
          />
        ),
        handleConfirm: validationEditReason,
        handleCancel: () => {
          reasonErr.current = false;
          reason.current = 0;
        },
      },
    });
  };

  // 判斷 dialog
  const validationEditReason = async () => {
    if (reason.current === 0) {
      reasonErr.current = true;
      const dialogTimer = setTimeout(() => {
        getReasonDialog(validationEditReason);
        clearTimeout(dialogTimer);
      }, [500]);
      return;
    }
    reasonErr.current = false;
    setSubmitDisable(true);
    processEndTime.current = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    setStartDisable(false);
    const updateData = {
      ...timer,
      endTime: processEndTime.current,
      reasonId: reason.current,
      stationType: useStationType('SINTERING'),
    };
    const [status] = await handleUpdateTransTime(newCancelToken, dialogDispatch, updateData);
    if (status) {
      setProcessDisable(true);
      reason.current = 0;
    }
  };

  const showRecordsSelectionDialog = () => {
    getReasonDialog(validationEditReason);
  };

  const handleProcessStart = async () => {
    processStartTime.current = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    setStartDisable(true);
    setSubmitDisable(false);
    setProcessDisable(false);
    const startProcessData = {
      transId: transInfo.transId,
      startTime: processStartTime.current,
      stationType: useStationType('SINTERING'),
    };
    const [status, result] = await handleUpdateTransTime(
      newCancelToken,
      dialogDispatch,
      startProcessData
    );
    if (!status) {
      setProcessDisable(true);
      return;
    }
    setTimer(result);
  };
  const handleProcessEnd = () => {
    showRecordsSelectionDialog();
  };

  useEffect(() => {
    getTransData();
    getReasonList();
    resetFormErr();
  }, []);

  return (
    <Grid container className={classes.root}>
      <PageTitle title="更新移轉單（燒結）" />
      <PaperContainer>
        <div className={`${classes.content} ${classes.header}`}>
          <Typography variant="h6">移轉單資訊</Typography>
          <TransLabel label="燒結" />
        </div>
        <div className={classes.content}>
          <div className={classes.field}>
            <ReadonlyField
              label="移轉單號 / Trans nomor pesanan"
              value={transInfo.transId}
              name="transId"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="工令單號 / Nomor perintah kerja"
              value={transInfo.workId}
              name="workId"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField label="料號 / Bagian No" value={transInfo.elNo} name="elNo" />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="材質 / Bahan"
              value={transInfo.materialName}
              name="materialName"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="產品名稱 / Nama Produk"
              value={transInfo.productName}
              name="productName"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="匣數 / Kuantitas manufaktur"
              value={transInfo.createQty}
              name="createQty"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="移轉數量 / Kuantitus transfer"
              value={transInfo.transQty}
              name="transQty"
            />
            <Checkbox
              name="confirmSintering"
              label="確認無誤"
              onChange={handleCheckBoxChange}
              value={transQuantityConfirm}
              customClasses={classes.checkbox}
              checked={transQuantityConfirm}
              error={transQuantityConfirmError}
              errorMsg="請確認移轉數量無誤!"
            />
          </div>
          {isCreate && (
            <div className={classes.field} style={{ display: 'flex' }}>
              <Button
                variant="outlined"
                color={!startDisable ? 'error' : 'grey'}
                disabled={startDisable}
                fullWidth
                startIcon={<TimerIcon />}
                customClasses={classes.process_btn}
                onClick={handleProcessStart}
              >
                開始 / Mulai
              </Button>
              <Button
                variant="contained"
                color="error"
                disabled={processDisable}
                fullWidth
                startIcon={<TimerOffIcon />}
                customClasses={classes.process_btn}
                onClick={handleProcessEnd}
              >
                暫停 / Berhenti
              </Button>
            </div>
          )}
        </div>
        <Divider className={classes.divider} />
        <div className={`${classes.content} ${classes.header}`}>
          <Typography variant="h6">製造資訊</Typography>
        </div>
        <div className={classes.content}>
          <div className={classes.field}>
            <SelectField
              id="elecStoveNo"
              label="電爐編號 / Nomor tungku"
              placeholder="請選擇電爐編號 / Nomor tungku"
              selections={elecStoveList}
              value={transForm.elecStoveNo}
              handleChange={handleFormChange}
              error={transFormErr.elecStoveNo}
              errormsg="請選擇電爐編號 / Nomor tungku！"
              disabled={isCreate ? processDisable : false}
            />
          </div>
        </div>
        <div className={classes.confirm}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={isCreate ? submitDisable : false}
            startIcon={<CheckRoundedIcon />}
            onClick={handleConfirm}
          >
            送出
          </Button>
        </div>
      </PaperContainer>
    </Grid>
  );
};

export default TransUpdateSintering;
