import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: 70,
    justifyContent: 'center',
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.down('md')]: {
      height: 60,
    },
  },
  toolbar: {
    [theme.breakpoints.down('md')]: {
      paddingRight: 8,
    },
  },
  logo: {
    width: 45,
    height: 27,
    marginRight: 10,
    '& img': {
      width: 'auto',
      height: '100%',
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: '0.135px',
    lineHeight: 1,
  },
  version: {
    fontSize: 13,
    marginLeft: '1rem',
    fontWeight: 500,
    letterSpacing: '0.135px',
    lineHeight: 1,
  },
  navFunc: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  logout: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logoutMobile: {
    color: theme.palette.common.white,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  profileDivider: {
    width: 1,
    height: 24,
    backgroundColor: alpha(theme.palette.common.white, 0.6),
    margin: 'auto 20px auto 10px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  d_none: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
