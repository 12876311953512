/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Button from '../Button/Button';
import SelectField from '../SelectField/SelectField';
import Checkbox from '../Checkbox/Checkbox';
import InputField from '../InputField/InputField';
import Table from '../Table/Table';
import TableContainer from '../TableContainer/TableContainer';
import TableBody from '../TableBody/TableBody';
import TableRow from '../TableRow/TableRow';
import TableCell from '../TableCell/TableCell';
import TableBtnSets from './TableBtnSets';
import TableHead from '../TableHead/TableHead';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';
import DatePicker from '../DatePicker/DatePicker';

const useStyles = makeStyles()((theme) => ({
  privilege_table: {
    tableLayout: 'fixed',
  },
  privilege_table_row: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  checkbox: {
    '&.MuiTableCell-paddingCheckbox': {
      padding: `0 ${theme.spacing(2.5)} 0 0`,
    },
  },
  checkboxLabel: {
    marginLeft: 0,
    marginRight: 0,
  },
  datePicker: {
    width: 176,
    '& span': {
      top: '55%',
    },
  },
}));

const ConfigTable = ({
  isCheckBox,
  headCells,
  tableContentList,
  isDeleteBtnShow,
  handleCheckBoxChecked,
  onRowEditClick,
  handleRowEditing,
  handleRowDateEditing,
  editedData,
  isAdding,
  onRowDelete,
  handleWillDeleteList,
  handleEditData,
  handleRowError,
  isLoading,
  isEditing,
  isView,
  onRowView,
}) => {
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);
  const { pathname } = useLocation();

  const validationTableForm = (targetRow) => {
    const { columns } = targetRow;
    const validation = {};
    const isValid = columns.every((col) => {
      if (!col.isRequired) return true;
      validation[col.name] = false;
      if (col.type === 'select' && editedData[col.name] === '0') return false;
      if (col.type === 'text' && editedData[col.name].length === 0) return false;
      validation[col.name] = true;
      return true;
    });
    handleRowError(targetRow, validation);
    return isValid;
  };

  const handleEditFinished = (targetRow) => {
    if (editedData?.error) return;
    if (!validationTableForm(targetRow)) return;
    handleEditData();
    onRowEditClick(targetRow.rowId, false);
  };

  const handleCancelEditing = (targetRowId) => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認離開？',
        message: '是否確認退出編輯？您所做出的更動將不會被儲存。',
        handleConfirm: () => onRowEditClick(targetRowId, false),
      },
    });
  };

  const handleTableClick = (e, targetRow) => {
    if (e.target.checked) {
      handleWillDeleteList(targetRow, true);
    } else {
      handleWillDeleteList(targetRow, false);
    }
    handleCheckBoxChecked(targetRow);
  }; // handleTableClick

  const handleEditingClick = (targetRow) => {
    if (isAdding) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '您尚未完成新增!',
          message: '請先完成新增或取消新增！',
        },
      });
      return;
    }
    const isEditingRow = tableContentList.find((row) => row.isEditing);

    if (isEditingRow) {
      handleCancelEditing(isEditingRow.rowId);
    } else {
      onRowEditClick(targetRow.rowId, true);
      if (typeof handleWillDeleteList !== 'function') return;
      handleWillDeleteList(targetRow, false);
    }
  };

  const handleDelete = (targetRow) => {
    onRowDelete(targetRow);
  };

  const handleView = (targetRow) => {
    onRowView(targetRow.rowId);
  };

  return (
    <TableContainer isLoading={isLoading}>
      <Table className={pathname.includes('privilege') ? classes.privilege_table : ''}>
        <TableHead isCheckBox={isCheckBox} headCells={headCells} />
        <TableBody>
          {tableContentList.map((row) => {
            const labelId = `enhanced-table-checkbox-${row.rowId}`;
            return (
              <TableRow
                tabIndex={-1}
                key={row.rowId}
                style={{ verticalAlign: editedData?.error ? 'top' : 'middle' }}
              >
                {isCheckBox && (
                  <TableCell
                    selected={row.isCheckd}
                    aria-checked={row.isChecked}
                    role="checkbox"
                    align="left"
                    padding="checkbox"
                    classes={{ sizeSmall: classes.checkbox }}
                  >
                    <Checkbox
                      name="checkbox"
                      checked={row.isChecked}
                      inputProps={{ 'aria-labelledby': labelId }}
                      onClick={(e) => handleTableClick(e, row)}
                      className={classes.checkboxLabel}
                    />
                  </TableCell>
                )}
                {row.columns.map(
                  (col, index) =>
                    col.visible && (
                      <TableCell
                        className={
                          pathname.includes('privilege') ? classes.privilege_table_row : ''
                        }
                        key={index}
                      >
                        {row.isEditing &&
                          col.isEditable &&
                          !col.conditional &&
                          col.type === 'select' && (
                            <SelectField
                              style={{
                                width: 176,
                                margin: 10,
                                paddingTop: editedData?.error ? '24px' : '16px',
                              }}
                              label={col.label}
                              placeholder={col.placeholder}
                              value={editedData[col.name]}
                              handleChange={handleRowEditing}
                              selections={col.selectionList}
                              id={col.name}
                              error={col.error}
                              errormsg={col.placeholder}
                            />
                          )}
                        {row.isEditing &&
                          col.isEditable &&
                          col.conditional &&
                          col.type === 'select' && (
                            <SelectField
                              style={{
                                width: 176,
                                margin: 10,
                                paddingTop: editedData?.error ? '24px' : '16px',
                              }}
                              label={col.label}
                              placeholder={col.placeholder}
                              value={editedData[col.name]}
                              handleChange={handleRowEditing}
                              selections={col.selectionList}
                              id={col.name}
                              error={col.error}
                              errormsg={col.placeholder}
                            />
                          )}
                        {row.isEditing &&
                          col.isEditable &&
                          !col.conditional &&
                          col.type === 'text' && (
                            <InputField
                              style={{
                                width: 176,
                                margin: 10,
                              }}
                              id={col.name}
                              label={col.label}
                              value={editedData[col.name]}
                              variant="outlined"
                              onChange={handleRowEditing}
                              placeholder={col.placeholder}
                              name={col.name}
                              type="text"
                              error={editedData.error || col.error}
                              errormsg={editedData.errormsg || col.placeholder}
                            />
                          )}
                        {row.isEditing &&
                          col.isEditable &&
                          col.conditional &&
                          col.type === 'text' && (
                            <InputField
                              style={{
                                width: 176,
                                margin: 10,
                              }}
                              id={col.name}
                              label={col.label}
                              value={editedData[col.name]}
                              variant="outlined"
                              onChange={handleRowEditing}
                              placeholder={col.placeholder}
                              name={col.name}
                              type="text"
                              error={editedData.error || col.error}
                              errormsg={editedData.errormsg || col.placeholder}
                            />
                          )}
                        {row.isEditing &&
                          col.isEditable &&
                          !col.conditional &&
                          col.type === 'date' && (
                            <DatePicker
                              containerStyle={{ marginTop: editedData?.error ? '8px' : '0px' }}
                              containerClass={classes.datePicker}
                              style={{ margin: 0 }}
                              id={col.name}
                              label={col.label}
                              value={editedData[col.name]}
                              onChange={handleRowDateEditing}
                              placeholder={col.placeholder}
                              name={col.name}
                              error={col.error}
                              errormsg={col.placeholder}
                            />
                          )}
                        {row.isEditing &&
                          col.isEditable &&
                          col.conditional &&
                          col.type === 'date' && (
                            <DatePicker
                              containerStyle={{ marginTop: editedData?.error ? '8px' : '0px' }}
                              containerClass={classes.datePicker}
                              style={{ margin: 0 }}
                              id={col.name}
                              label={col.label}
                              value={editedData[col.name]}
                              onChange={handleRowDateEditing}
                              placeholder={col.placeholder}
                              name={col.name}
                              error={col.error}
                              errormsg={col.placeholder}
                            />
                          )}
                        {(!row.isEditing || !col.isEditable) && col.displayValue}
                      </TableCell>
                    )
                )}
                <TableCell align="right">
                  {row.isEditing ? (
                    <div>
                      <Button
                        style={{ marginRight: 10 }}
                        variant="outlined"
                        color="secondary"
                        rounded
                        onClick={() => handleEditFinished(row)}
                      >
                        儲存
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        rounded
                        onClick={() => handleCancelEditing(row.rowId)}
                      >
                        取消
                      </Button>
                    </div>
                  ) : (
                    <TableBtnSets
                      isDelete={isDeleteBtnShow}
                      isEditing={isEditing}
                      isView={isView}
                      handleEditing={() => handleEditingClick(row)}
                      handleDelete={() => handleDelete(row)}
                      handleView={() => handleView(row)}
                      canDelete={row.canDelete}
                      canEdit={row.canEdit}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ConfigTable.defaultProps = {
  onRowDelete: null,
  handleWillDeleteList: null,
  handleCheckBoxChecked: null,
  isAdding: false,
  isDeleteBtnShow: false,
  isCheckBox: false,
  isEditing: false,
};

ConfigTable.propTypes = {
  isCheckBox: PropTypes.bool,
  isDeleteBtnShow: PropTypes.bool,
  handleCheckBoxChecked: PropTypes.func,
  onRowDelete: PropTypes.func,
  handleWillDeleteList: PropTypes.func,
  isAdding: PropTypes.bool,
  isEditing: PropTypes.bool,
  isView: PropTypes.bool,
  onRowView: PropTypes.func,
  isLoading: PropTypes.bool,
  headCells: PropTypes.array.isRequired,
  tableContentList: PropTypes.array.isRequired,
  onRowEditClick: PropTypes.func.isRequired,
  handleRowEditing: PropTypes.func,
  handleRowDateEditing: PropTypes.func,
  editedData: PropTypes.object,
  handleEditData: PropTypes.func,
  handleRowError: PropTypes.func,
};

export default ConfigTable;
