/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectField from '../../components/SelectField/SelectField';
import useStyles from './useStyles';

const UpdateSubmitReasonSelect = ({ reason, reasonList, reasonErr, handleReasonChange }) => {
  const { classes } = useStyles();
  const [reasonValue, setReasonValue] = useState(reason);
  const [isReasonErr] = useState(reasonErr);

  const handleChange = (e) => {
    setReasonValue(e.target.value);
    handleReasonChange(e.target.value);
  };

  return (
    <div className={classes.dialog_input}>
      <SelectField
        id="reason"
        label="暫停原因 / alasan suspensi"
        placeholder="請選擇暫停原因 / alasan suspensi"
        value={reasonValue}
        selections={reasonList}
        handleChange={handleChange}
        error={isReasonErr}
        errormsg="請選擇暫停原因 / alasan suspensi !"
        type="timerReason"
      />
    </div>
  );
};

UpdateSubmitReasonSelect.propTypes = {
  reason: PropTypes.string.isRequired,
  reasonList: PropTypes.array.isRequired,
  reasonErr: PropTypes.bool.isRequired,
  handleReasonChange: PropTypes.func.isRequired,
};

export default UpdateSubmitReasonSelect;
