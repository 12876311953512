import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
import { deepOrange } from '@mui/material/colors';

const useStyles = makeStyles()((theme) => ({
  date_group: {
    display: 'flex',
    alignItems: 'center',
  },
  filter_date_grid: {
    width: 240,
    maxWidth: 240,
    marginRight: theme.spacing(3),
  },
  filter_date_picker: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(2),
    },
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(2),
    },
  },
  error_opacity: {
    color: 'transparent',
  },
  filter_btn_group: {
    paddingLeft: theme.spacing(3),
  },
  filter_btn: {
    marginBottom: theme.spacing(3),
    alignSelf: 'flex-end',
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(1.5),
    },
  },
  filter_field: {
    width: `calc((100% - 80px) / 3)`,
    maxWidth: `calc((100% - 80px) / 3)`,
    '&:not(:nth-of-type(3n))': {
      marginRight: theme.spacing(5),
    },
  },
  advanced_filter: {
    marginTop: theme.spacing(2.5),
  },
  advanced_filter_row: {
    marginTop: theme.spacing(4),
  },
  advanced_filter_btn: {
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.up('lg')]: {
      width: 'calc((100% / 4 * 3) + 80px)',
      maxWidth: 'calc((100% / 4 * 3) + 80px)',
    },
  },
  table: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  table_header: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(1),
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    alignItems: 'center',
  },
  table_column: {
    width: '100%',
    paddingRight: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
  },
  table_cell: {
    fontSize: 15,
  },
  customer_cell: {
    width: 62,
    maxWidth: 62,
  },
  transId_cell: {
    width: 135,
    maxWidth: 135,
  },
  product_cell: {
    width: 250,
    maxWidth: 250,
  },
  product_cell_font: {
    fontSize: 14,
  },
  measure_cell: {
    width: 170,
    maxWidth: 170,
  },
  user_cell: {
    width: 115,
    maxWidth: 115,
  },
  machine_cell: {
    width: 75,
    maxWidth: 75,
  },
  update_cell: {
    width: 210,
    maxWidth: 210,
  },
  update_product_cell: {
    width: 250,
    maxWidth: 250,
  },
  update_NG_cell: {
    width: 80,
    maxWidth: 80,
  },
  table_column_selection: {
    width: 50,
  },
  table_column_action: {
    width: 60,
    marginLeft: 'auto',
    marginRight: 0,
    paddingRight: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  table_action_cell: {
    width: 28,
    minWidth: 'auto',
    height: 28,
    padding: 0,
    fontSize: 14,
  },
  table_selection: {
    marginLeft: -11,
    marginRight: 0,
  },
  table_content: {
    listStyle: 'none',
  },
  table_row: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  result_btn: {
    marginLeft: 'auto',
    marginTop: theme.spacing(3),
  },
  detail_header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: `0 4px 6px ${alpha(theme.palette.common.black, 0.15)}`,
  },
  detail_footer: {
    boxShadow: `0 -4px 6px ${alpha(theme.palette.common.black, 0.1)}`,
  },
  detail_btn: {
    margin: `${theme.spacing(0.6)} 0`,
  },
  detail_content: {
    height: '70vh',
    overflowY: 'scroll',
  },
  detail_table: {
    marginTop: theme.spacing(2),
  },
  detail_nav: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    borderRadius: 5,
    overflow: 'hidden',
    marginBottom: theme.spacing(2.5),
  },
  detail_nav_item: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(5)} ${theme.spacing(1.2)}`,
    cursor: 'pointer',
    '&.active': {
      color: theme.palette.secondary.main,
      borderBottom: `4px solid currentColor`,
    },
  },
  detail_row: {
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    flexWrap: 'noWrap',
  },
  detail_header_row: {
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    flexWrap: 'noWrap',
    alignItems: 'center',
  },
  electrical_header_row: {
    borderBottom: 'none',
    padding: theme.spacing(1.5),
    flexWrap: 'wrap',
  },
  detail_column: {
    padding: theme.spacing(1),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    },
  },
  detail_header_column: {
    display: 'flex',
    padding: theme.spacing(1),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
      paddingRight: 8.5,
    },
  },
  detail_inspection_column: {
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    },
  },
  forming_column: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  forming_spec: {
    marginTop: '2.5px',
  },
  forming_note: {
    paddingLeft: theme.spacing(2),
  },
  forming_defect: {
    paddingLeft: theme.spacing(2),
  },
  size_header_column: {
    flexDirection: 'column',
    width: '100%',
  },
  inspection_column: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 140,
    width: '100%',
    alignItems: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  },
  size_target_group: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  size_target: {
    fontSize: 24,
    fontWeight: 300,
    padding: 0,
    margin: 0,
    lineHeight: 1,
  },
  size_spec_group: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginLeft: theme.spacing(1),
  },
  size_spec: {
    fontSize: 10,
    padding: 0,
    margin: 0,
    paddingLeft: theme.spacing(0.7),
    lineHeight: 1,
    borderLeft: `2px solid currentColor`,
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(0.5),
    },
  },
  size_spec_upper: {
    color: theme.palette.secondary.main,
  },
  size_spec_lower: {
    color: theme.palette.grey[500],
  },
  size_bias: {
    marginLeft: 'auto',
    fontSize: 10,
    paddingRight: theme.spacing(0.8),
  },
  size_defect: {
    width: 250,
    minWidth: 250,
    paddingLeft: theme.spacing(2),
  },
  size_defect_sm: {
    width: 180,
    minWidth: 180,
    paddingLeft: theme.spacing(2),
  },
  measure_btn: {
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(3),
    },
    '&.active': {
      color: theme.palette.primary.main,
      fontWeight: 600,
      textDecoration: 'underline',
    },
    '&:hover': {
      color: theme.palette.primary.main,
      fontWeight: 600,
      cursor: 'pointer',
    },
  },
  measure_settings: {
    '& b': {
      fontWeight: 500,
    },
  },
  measure_subspec: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(1.5),
    alignItems: 'center',
    '& b': {
      fontWeight: 500,
      marginRight: theme.spacing(0.5),
    },
    '& i': {
      fontStyle: 'normal',
      cursor: 'pointer',
      padding: `${theme.spacing(0.4)} ${theme.spacing(2)}`,
      border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
      color: alpha(theme.palette.common.black, 0.4),
      transition: 'all .2s ease-in-out',
      backgroundColor: 'transparent',
      borderRadius: 50,
      marginLeft: theme.spacing(1),
    },
    '& i:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.light, 0.4),
    },
    '& i.active': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.light, 0.4),
      fontWeight: 600,
    },
  },
  electrical_column: {
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  electrical_measure: {
    display: 'flex',
    alignItems: 'center',
    '& b': {
      fontWeight: 500,
    },
    '& i': {
      display: 'block',
      width: 1,
      height: theme.spacing(2),
      backgroundColor: alpha(theme.palette.common.black, 0.4),
      margin: `0 ${theme.spacing(1.2)}`,
    },
  },
  electrical_bias: {
    fontSize: 10,
  },
  electrical_cell: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 100,
    width: '100%',
    alignItems: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  error_snackbar: {
    marginBottom: theme.spacing(2),
  },
  out_spec: {
    color: theme.palette.error.main,
    backgroundColor: alpha(deepOrange[500], 0.1),
  },
  out_limit: {
    color: deepOrange[500],
  },
  hyper_link: {
    cursor: 'pointer',
    color: '#3f51b5',
  },
  disabled_opacity: {
    color: theme.palette.grey[400],
  },
  has_date_err_btn: {
    marginBottom: '44px',
  },
  has_date_err_typography: {
    marginBottom: '20px',
  },
}));

export default useStyles;
