import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import useStyles from './useStyles';
import Grid from '../../components/Grid/Grid';
import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import SelectField from '../../components/SelectField/SelectField';
import useAxios from '../../hooks/useAxios';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';
import useCancelToken from '../../hooks/useCancelToken';

const SettingTargetSelection = ({ setConfigTarget }) => {
  const { dialogDispatch } = useContext(DialogContext);
  const { classes } = useStyles();
  const [settingType, setSettingType] = useState(0);
  const [settingTypeErr, setSettingTypeErr] = useState(false);
  const [settingTypeList, setSettingTypeList] = useState([]);
  const { newCancelToken } = useCancelToken();

  const getSettingTypes = async () => {
    const cancelToken = newCancelToken();
    const [status, result] = await useAxios('/configType', {}, true, cancelToken);
    if (status) {
      const { config } = result;
      await setSettingTypeList(
        config.map((type) => ({
          id: type.linkUrl,
          name: type.configName,
          value: type.configId,
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '系統錯誤！',
          message: '抱歉，目前系統錯誤，請稍後再試，或請通知網管人員！',
        },
      });
    }
  };

  useEffect(() => {
    getSettingTypes();
  }, []);

  const handleSelectChange = (e) => {
    setSettingType(e.target.value);
    if (settingTypeErr) {
      setSettingTypeErr(false);
    }
  };

  const handleSearch = () => {
    if (settingType === 0) {
      setSettingTypeErr(true);
      return;
    }
    setConfigTarget(settingType);
  };
  return (
    <PaperContainer className={classes.container}>
      <Typography variant="h6">選擇要修改的設定</Typography>
      <Grid container style={{ marginTop: 22 }} spacing={3} alignItems="flex-end">
        <Grid item xs={5}>
          <SelectField
            label="設定項目"
            placeholder="設定項目"
            value={settingType}
            handleChange={handleSelectChange}
            selections={settingTypeList}
            id="settingType"
            error={settingTypeErr}
            errormsg="請選擇設定項目"
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => handleSearch()}
            variant="outlined"
            color="primary"
            startIcon={<SearchRoundedIcon />}
            customClasses={settingTypeErr && classes.error}
          >
            查詢
          </Button>
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

SettingTargetSelection.propTypes = {
  setConfigTarget: PropTypes.func.isRequired,
};

export default SettingTargetSelection;
