/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useStyles from './useStyles';

const SelectField = ({
  error,
  value,
  handleChange,
  selections,
  placeholder,
  label,
  errormsg,
  id,
  className,
  type,
  ...rest
}) => {
  // eslint-disable-next-line prettier/prettier
  const { classes } = useStyles();
  return (
    <FormControl
      variant="outlined"
      className={`${classes.formControl} ${className}`}
      fullWidth
      {...rest}
    >
      <InputLabel id={id} shrink className={classes.label}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        id="demo-simple-select-outlined"
        value={value}
        onChange={handleChange}
        label={label}
        error={error}
        className={classes.select}
        name={id}
      >
        <MenuItem value="0" disabled>
          {placeholder}
        </MenuItem>
        {type === 'timerReason'
          ? selections &&
            selections.map((item) => (
              <MenuItem key={item.reasonId} value={item.reasonId}>
                {item.reasonTC} / {item.reasonIDN}
              </MenuItem>
            ))
          : selections &&
            selections.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
      </Select>
      {error && <FormHelperText className={classes.error}>{errormsg}</FormHelperText>}
    </FormControl>
  );
};

SelectField.defaultProps = {
  error: false,
  errormsg: '請選擇欄位',
  className: '',
};

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errormsg: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default SelectField;
