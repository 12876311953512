/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef } from 'react';
import { format, compareDesc, isWithinInterval, addDays, isMonday } from 'date-fns';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import useCommonStyles from '../../useStyles';
import useStyles from '../useStyles';
import useForm from '../../../../hooks/useForm';
import FormingDataRow from './FormingDataRow';
import NGTransListDialog from './NGTransListDialog';
import YieldRateChart from '../YieldRateChart';
import { DialogContext } from '../../../../contexts/DialogContext';
import useCancelToken from '../../../../hooks/useCancelToken';
import useAxios from '../../../../hooks/useAxios';
import { dialogActions } from '../../../../reducers/dialogReducer';
import { parseDecimalToFixedString } from '../../../../Utils/stringUtil';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Button from '../../../../components/Button/Button';
import DatePicker from '../../../../components/DatePicker/DatePicker';

const FormingDaily = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const initialFilterField = {
    startDate: isMonday(new Date()) ? addDays(new Date(), -3) : addDays(new Date(), -1),
    endDate: isMonday(new Date()) ? addDays(new Date(), -1) : new Date(),
  };
  const {
    formData: filterField,
    setFormData: setFilterField,
    formErr: filterFieldErr,
    setFormErr: setFilterFieldErr,
    resetFormData: resetFilterField,
    resetFormErr: resetFilterFieldErr,
  } = useForm(initialFilterField);
  const [showResult, setShowResult] = useState(false);
  const [formingDailyList, setFormingDailyList] = useState([]);
  const [transNGList, setTransNGList] = useState([]);
  const [showNGTransList, setShowNGTransList] = useState(false);
  const [toRenderYieldData, setToRenderYieldData] = useState(false);
  const [showYieldRateChart, setShowYieldRateChart] = useState(false);
  const [targetScOdNo, setTargetScOdNo] = useState('');
  const [targetFormingTrans, setTargetFormingTrans] = useState('');
  const [dateErrMsg, setDateErrMsg] = useState('');
  const yieldData = useRef([]);
  const commonClasses = useCommonStyles().classes;
  const { classes } = useStyles();
  const { newCancelToken } = useCancelToken();
  const hasDateErr = filterFieldErr.startDate || filterFieldErr.endDate;

  const isWithinOneWeek = (start, end) => {
    const maxEndDate = addDays(start, 7);
    return isWithinInterval(end, { start, end: maxEndDate });
  };

  const validationDate = () => {
    const { startDate, endDate } = filterField;
    if (compareDesc(startDate, endDate) < 0) {
      setFilterFieldErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      setDateErrMsg('結束日期不得早於起始日期');
      return false;
    }
    if (!isWithinOneWeek(startDate, endDate)) {
      setFilterFieldErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      setDateErrMsg('所選區間不得大於一週');
      return false;
    }
    return true;
  };
  const handleStartDateChange = (date) => {
    setFilterField((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFilterField((prev) => ({
      ...prev,
      endDate: date,
    }));
  };

  const handleCloseNGTransList = () => {
    setShowNGTransList(false);
  };

  const handleCloseYieldRateChart = () => {
    setShowYieldRateChart(false);
  };

  const handleFetch = async () => {
    resetFilterFieldErr();
    if (!validationDate()) return;
    const { startDate, endDate } = filterField;
    const data = {
      checkBegin: format(new Date(startDate), 'yyyy-MM-dd'),
      checkEnd: format(new Date(endDate), 'yyyy-MM-dd'),
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/formProgress',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { progress } = result;
      setFormingDailyList(
        progress.map((item, idx) => ({
          id: idx + 1,
          transId: item.transId,
          transClass: item.transClass.toString(),
          updateDate: item.formDate,
          scOdNo: item.scOdno,
          productInfo: `${item.prodName}(${item.materialName || '-'})`,
          transQty: item.transQuantity,
          formingWeight: item.formWeight,
          bucketNo: item.bucketNo,
          formingUser: item.formUser,
          formingMachine: item.machineName,
          moldQty: item.formModel,
          standardQty: item.prodWph,
          passQty: item.passQuantity,
          efficiency: item.prodEfficiency
            ? parseDecimalToFixedString(item.prodEfficiency * 100)
            : null,
          daysAvg15: item.daysAvg15,
          daysAvg30: item.daysAvg30,
          daysAvg90: item.daysAvg90,
          qcResult: item.isPass !== undefined ? !!item.isPass : '',
          yieldRate: item.goodRate ? parseDecimalToFixedString(item.goodRate * 100) : null,
        }))
      );
      setShowResult(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得壓型日報表',
          message:
            noPrivilegeMsg || `${result}：目前無法取得壓型日報表，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleShowYieldRateChart = async (transId) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkBadByTrans',
      { transId },
      true,
      cancelToken
    );
    if (status) {
      const { bad } = result;
      yieldData.current = bad;
      setToRenderYieldData(true);
      setShowYieldRateChart(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得不良原因相關資料',
          message:
            noPrivilegeMsg ||
            `${result}：目前無法取得不良原因相關資料，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleShowNGTransList = async (transId) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getFormingDate',
      { transId },
      true,
      cancelToken
    );
    if (status) {
      const { formCheck } = result;
      setTransNGList(
        formCheck.map((item, idx) => ({
          id: idx + 1,
          modifyDate: item.modifyDate,
          isPass: !!item.isPass,
        }))
      );
      setShowNGTransList(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得檢測紀錄',
          message:
            noPrivilegeMsg || `${result}：目前無法取得相關檢測紀錄，請稍後再試，或請通知網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  return (
    <div>
      <PaperContainer classes={commonClasses.container}>
        <Typography variant="h6">查詢更新日期區間</Typography>
        <Grid container className={commonClasses.content}>
          <Grid container item xs={12} className={classes.date_group}>
            <Grid item xs={3} className={classes.filter_date_grid}>
              <DatePicker
                id="startDate"
                label="查詢起始日期"
                value={filterField.startDate}
                onChange={handleStartDateChange}
                hasDateErr={hasDateErr}
                errMsg={dateErrMsg}
                maxDate={new Date()}
                containerClass={classes.filter_date_picker}
              />
            </Grid>
            <Typography
              variant="h6"
              style={{ marginRight: 24 }}
              className={hasDateErr ? classes.error : ''}
            >
              ~
            </Typography>
            <Grid item xs={3} className={classes.filter_date_grid}>
              <DatePicker
                id="endDate"
                label="查詢結束日期"
                value={filterField.endDate}
                onChange={handleEndDateChange}
                hasDateErr={hasDateErr}
                errMsg={dateErrMsg}
                maxDate={new Date()}
                containerClass={classes.filter_date_picker}
              />
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              customClasses={`${classes.filter_btn} ${hasDateErr ? classes.filter_btn_error : ''}`}
              startIcon={<SearchRoundedIcon />}
              onClick={handleFetch}
            >
              查詢
            </Button>
          </Grid>
        </Grid>
      </PaperContainer>
      {showResult && (
        <div className={classes.main_content}>
          <Typography variant="h6">壓型日報表</Typography>
          <div className={classes.daily_list}>
            {formingDailyList.length === 0 && (
              <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: 32 }}>
                目前所選區間沒有相應的資料，請重新選擇區間！
              </Typography>
            )}
            {formingDailyList.map((row) => (
              <FormingDataRow
                key={row.id}
                rowData={row}
                handleShowNGTransList={handleShowNGTransList}
                handleShowYieldRateChart={handleShowYieldRateChart}
                setTargetScOdNo={setTargetScOdNo}
                setTargetFormingTrans={setTargetFormingTrans}
              />
            ))}
          </div>
        </div>
      )}
      <NGTransListDialog
        open={showNGTransList}
        targetScOdNo={targetScOdNo}
        targetFormingTrans={targetFormingTrans}
        transList={transNGList}
        handleTransListDialogClose={handleCloseNGTransList}
      />
      <YieldRateChart
        open={showYieldRateChart}
        handleChartClose={handleCloseYieldRateChart}
        targetScOdNo={targetScOdNo}
        yieldRateData={yieldData.current}
        toRender={toRenderYieldData}
        setToRender={setToRenderYieldData}
      />
    </div>
  );
};

export default FormingDaily;
