/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { parseDecimalToFixedString } from '../../../../Utils/stringUtil';
import useCommonStyles from '../../useStyles';
import useStyles from './useStyles';
import Typography from '../../../../components/Typography/Typography';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';

const DefectLineChart = ({
  paretoData,
  handleChartDialogOpen,
  setCurrentDefectType,
  toUpdate,
  toRender,
  setToRender,
  setToUpdate,
  emptyResult,
}) => {
  const paretoChartEl = useRef(null);
  const paretoChart = useRef(null);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  Chart.register(...registerables);
  // Chart.unregister(ChartDataLabels);
  const getAccPercentage = () =>
    paretoData.reverse().map((data, idx) => {
      const targetArr = paretoData
        .slice(0, idx + 1)
        .map((item) => parseFloat(parseDecimalToFixedString(item.detectPersent * 100)));

      return targetArr.reduce((prev, current) => prev + current);
    });

  const getBarData = () => paretoData.map((item) => item.detectCount);

  const handleBarClick = (e) => {
    const activeEl = paretoChart.current.getActiveElements(e);
    if (!activeEl[0]) return;
    if (activeEl[0] && activeEl[0].datasetIndex !== 0) return;
    const targetDefectType = paretoData[activeEl[0].index];
    setCurrentDefectType(targetDefectType);
    handleChartDialogOpen();
  };

  const handleParetoComplete = (ctx) => {
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    ctx.fillStyle = 'rgba(63, 81, 181, 1)';
    const barDataset = paretoData.map((item) => item.detectCount);
    const meta = paretoChart.current?.getDatasetMeta(0);
    if (meta && meta.data) {
      meta.data.forEach((bar, index) => {
        const data = barDataset[index];
        ctx.fillText(data, bar.x, bar.y - 5);
      });
    }
  };

  const drawPareto = () => {
    if (paretoChart.current) {
      paretoChart.current.destroy();
    }
    paretoChartEl.current = document.getElementById('paretoChart');
    const ctx = paretoChartEl.current.getContext('2d');
    paretoChart.current = new Chart(ctx, {
      data: {
        labels: paretoData.map((item) => item.detectName).reverse(),
        datasets: [
          {
            type: 'bar',
            yAxisID: 'y',
            data: getBarData().reverse(),
            backgroundColor: ['rgba(63, 81, 181, .2)'],
            order: 2,
          },
          {
            type: 'line',
            yAxisID: 'y2',
            data: getAccPercentage(),
            backgroundColor: ['rgba(1,162,153,1)'],
            borderColor: ['rgba(1, 162, 153, 1)'],
            borderWidth: 1.5,
            order: 1,
            tooltip: {
              callbacks: {
                label: (context) => `${context.raw}%`,
              },
            },
          },
        ],
      },
      options: {
        layout: {
          padding: 20,
        },
        scales: {
          x: {
            axis: 'x',
            display: true,
            label: 'Defect Type',
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            suggestedMax: Math.max(...paretoData.map((item) => item.detectCount)) + 1,
            ticks: {
              sampleSize: 10,
            },
            title: {
              display: true,
              text: 'Defect Type Count',
            },
          },
          y2: {
            type: 'linear',
            position: 'right',
            beginAtZero: true,
            suggestedMax: 100,
            z: 1,
            grid: {
              color: 'rgba(0,0,0,0)',
            },
            ticks: {
              sampleSize: 10,
              callback: (value) => `${value}%`,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        animation: {
          onComplete: () => handleParetoComplete(ctx),
        },
        onClick: handleBarClick,
      },
    });
  };

  useEffect(() => {
    if (toUpdate && paretoChart.current) {
      paretoChart.current.update();
      setToUpdate(false);
    }
  }, [toUpdate]);

  useEffect(() => {
    if (!toRender) return;
    if (paretoData.length === 0) {
      if (paretoChart.current) {
        paretoChart.current.destroy();
      }
      setToRender(false);
      return;
    }
    drawPareto();
    setToRender(false);
  }, [toRender, paretoData]);

  useEffect(
    () => () => {
      if (!paretoChart.current) return;
      paretoChart.current.destroy();
    },
    []
  );

  return (
    <PaperContainer className={classes.pareto_chart_container}>
      {emptyResult.length === 0 && paretoData.length === 0 && (
        <Typography variant="subtitle1" style={{ margin: 'auto' }}>
          請選擇欲分析資料之時間區間
        </Typography>
      )}
      {emptyResult.length > 0 && (
        <Typography variant="subtitle1" style={{ margin: 'auto' }}>
          {emptyResult}
        </Typography>
      )}

      <canvas
        id="paretoChart"
        className={classes.pareto_canvas}
        width="600"
        height={paretoChart.length > 0 ? '400' : '0'}
      />
    </PaperContainer>
  );
};

DefectLineChart.propTypes = {
  paretoData: PropTypes.array.isRequired,
  handleChartDialogOpen: PropTypes.func.isRequired,
  setCurrentDefectType: PropTypes.func.isRequired,
  toUpdate: PropTypes.bool.isRequired,
  setToUpdate: PropTypes.func.isRequired,
  toRender: PropTypes.bool.isRequired,
  setToRender: PropTypes.func.isRequired,
  emptyResult: PropTypes.string.isRequired,
};

export default DefectLineChart;
