/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useSearchParams } from 'react-router-dom';
import useCommonStyles from '../../useStyles';
import useStyles from '../useStyles';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import InputField from '../../../../components/InputField/InputField';
import ReadonlyField from '../../../../components/ReadonlyField/ReadonlyField';
import Button from '../../../../components/Button/Button';
import Divider from '../../../../components/Divider/Divider';

const ScOdInfo = ({ scOdNo, setScOdNo, scOdNoErr, setScOdNoErr, handleFetch, scOdInfo }) => {
  const commonClasses = useCommonStyles().classes;
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();

  const handleScOdNoChange = (e) => {
    if (!scOdNoErr) {
      setScOdNoErr(false);
    }
    setScOdNo(e.target.value);
  };

  useEffect(() => {
    if (searchParams.get('scOd')) {
      setScOdNo(searchParams.get('scOd'));
      handleFetch(searchParams.get('scOd'), true);
    }
  }, []);

  return (
    <PaperContainer className={`${commonClasses.container} ${classes.scOd_info_container}`}>
      <Typography variant="h6" style={{ marginLeft: 26 }}>
        工令資訊
      </Typography>
      <Grid container className={`${commonClasses.content} ${classes.scOd_info_content}`}>
        <Grid item xs={3} className={classes.scOd_info_field}>
          <InputField
            id="scOdNo"
            name="scOdNo"
            type="text"
            label="工令單號"
            value={scOdNo}
            placeholder="請輸入工令單號"
            onChange={handleScOdNoChange}
            error={scOdNoErr}
            errormsg="請輸入正確及有效的工令單號"
          />
        </Grid>
        <Grid item xs={3} className={classes.scOd_info_field}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SearchRoundedIcon />}
            onClick={() => handleFetch()}
            style={scOdNoErr ? { marginBottom: 16 } : {}}
          >
            查詢
          </Button>
        </Grid>
      </Grid>
      <Divider
        className={commonClasses.dashed_divider}
        style={{ marginTop: -8, marginBottom: 24 }}
      />
      <Grid container className={`${commonClasses.content} ${classes.scOd_info_content}`}>
        <Grid item xs={3} className={classes.scOd_info_field}>
          <ReadonlyField name="productInfo" label="產品資訊" value={scOdInfo.productInfo} />
        </Grid>
        <Grid item xs={3} className={classes.scOd_info_field}>
          <ReadonlyField
            name="formingTotalQty"
            label="已壓型數量"
            value={scOdInfo.formingTotalQty}
          />
        </Grid>
        <Grid item xs={3} className={classes.scOd_info_field}>
          <ReadonlyField name="producedQty" label="已全檢數量" value={scOdInfo.producedQty} />
        </Grid>
        <Grid item xs={3} className={classes.scOd_info_field}>
          <ReadonlyField name="storageQty" label="已入庫數量" value={scOdInfo.storageQty} />
        </Grid>
        <Grid xs={3} className={classes.scOd_info_field}>
          <ReadonlyField name="requirementQty" label="需生產數量" value={scOdInfo.requirementQty} />
        </Grid>
        <Grid xs={3} className={classes.scOd_info_field}>
          <ReadonlyField
            name="deliveryQty"
            label="出貨日期"
            value={scOdInfo.deliveryDate === '-' ? '-' : scOdInfo.deliveryDate.replaceAll('/', '-')}
          />
        </Grid>
        <Grid xs={3} className={classes.scOd_info_field}>
          <ReadonlyField
            name="completionRate"
            label="完成率"
            value={scOdInfo.completionRate ? `${scOdInfo.completionRate}%` : '-'}
          />
        </Grid>
        <Grid xs={3} className={classes.scOd_info_field}>
          <ReadonlyField
            name="yieldRate"
            label="良率"
            value={scOdInfo.yieldRate ? `${scOdInfo.yieldRate}%` : '-'}
          />
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

ScOdInfo.propTypes = {
  scOdNo: PropTypes.string.isRequired,
  setScOdNo: PropTypes.func.isRequired,
  scOdNoErr: PropTypes.bool.isRequired,
  setScOdNoErr: PropTypes.func.isRequired,
  handleFetch: PropTypes.func.isRequired,
  scOdInfo: PropTypes.object.isRequired,
};

export default ScOdInfo;
