import React, { useState } from 'react';
import useCommonStyles from '../useStyles';
import DefectDashboard from './DefectDashboard/DefectDashboard';
import SPCCharts from './SPCCharts/SPCCharts';
import Typography from '../../../components/Typography/Typography';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import PageTitle from '../../../components/PageTitle/PageTitle';

const targetPageType = {
  DEFECT_DASHBOARD: 'Defect Dashboard',
  SPC_CHARTS: 'SPC Charts',
};

const QCReport = () => {
  const [targetPage, setTargetPage] = useState(targetPageType.DEFECT_DASHBOARD);
  const commonClasses = useCommonStyles().classes;

  return (
    <div className={commonClasses.main}>
      <PageTitle title="QC Report" />
      <PaperContainer className={commonClasses.report_nav}>
        <Typography
          variant="body1"
          className={`${commonClasses.report_nav_item} ${
            targetPage === targetPageType.DEFECT_DASHBOARD ? 'active' : ''
          }`}
          onClick={() => setTargetPage(targetPageType.DEFECT_DASHBOARD)}
        >
          {targetPageType.DEFECT_DASHBOARD}
        </Typography>
        <Typography
          variant="body1"
          className={`${commonClasses.report_nav_item} ${
            targetPage === targetPageType.SPC_CHARTS ? 'active' : ''
          }`}
          onClick={() => setTargetPage(targetPageType.SPC_CHARTS)}
        >
          {targetPageType.SPC_CHARTS}
        </Typography>
      </PaperContainer>

      {targetPage === targetPageType.DEFECT_DASHBOARD && <DefectDashboard />}
      {targetPage === targetPageType.SPC_CHARTS && <SPCCharts />}
    </div>
  );
};

export default QCReport;
