/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import SelectField from '../../../components/SelectField/SelectField';
import InputField from '../../../components/InputField/InputField';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useForm from '../../../hooks/useForm';
import { stationType as stationEnum } from '../../../constants/enums';

const NewTimerReasonAction = ({
  handleAddingClose,
  stationList,
  stationTypeList,
  handleAddReason,
}) => {
  const { dialogDispatch } = useContext(DialogContext);
  const initialForm = {
    stationTypeId: '0',
    stationName: '',
    reasonTC: '',
    reasonIDN: '',
    isEnabled: 1,
  };
  const {
    formData: reasonForm,
    setFormData: setReasonForm,
    formErr,
    setFormErr,
    resetFormErr,
  } = useForm(initialForm);

  const handleStationTypeSelectChange = (e) => {
    resetFormErr();
    setReasonForm((prev) => ({
      ...prev,
      stationTypeId: e.target.value,
      stationTypeName: stationTypeList.find((station) => station.id === e.target.value).name,
    }));
  };

  const handleReasonInputChange = (e) => {
    resetFormErr();
    setReasonForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const validationReasonForm = () => {
    const { reasonTC, reasonIDN, stationTypeId } = reasonForm;
    if (stationTypeId === '0') {
      setFormErr((prev) => ({
        ...prev,
        stationTypeId: true,
      }));
      return false;
    }
    if (reasonTC.length === 0) {
      setFormErr((prev) => ({
        ...prev,
        reasonTC: true,
      }));
      return false;
    }
    if (reasonIDN.length === 0) {
      setFormErr((prev) => ({
        ...prev,
        reasonIDN: true,
      }));
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validationReasonForm()) return;
    handleAddReason(reasonForm);
    handleAddingClose();
  };

  const handleCancel = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        singleBtn: true,
        title: '確認離開？',
        message: '是否確認放棄新增此筆資料？？',
        handleConfirm: handleAddingClose,
      },
    });
  };

  return (
    <div style={{ margin: 20 }}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid container item xs={7} spacing={1}>
          <Grid item xs>
            <SelectField
              label="製程站別"
              placeholder="請選擇站別"
              value={reasonForm.stationTypeId}
              handleChange={handleStationTypeSelectChange}
              selections={stationTypeList}
              id="stationTypeId"
              name="stationInyped"
              error={formErr.stationTypeId}
              errormsg="請選擇製程站別"
            />
          </Grid>
          <Grid item xs>
            <InputField
              id="reasonTC"
              label="暫停原因(中文)"
              value={reasonForm.reasonTC}
              variant="outlined"
              onChange={handleReasonInputChange}
              placeholder="請輸入暫停原因(中文)"
              name="reasonTC"
              type="text"
              error={formErr.reasonTC}
              errormsg="請輸入正確的暫停原因"
            />
          </Grid>
          <Grid item xs>
            <InputField
              id="reasonIDN"
              label="暫停原因(印尼文)"
              value={reasonForm.reasonIDN}
              variant="outlined"
              onChange={handleReasonInputChange}
              placeholder="請輸入暫停原因(印尼文)"
              name="reasonIDN"
              type="text"
              error={formErr.reasonIDN}
              errormsg="請輸入正確的暫停原因"
            />
          </Grid>
        </Grid>
        <Grid container item xs={5} justifyContent="flex-end">
          <Button
            style={{ marginRight: 10 }}
            variant="outlined"
            color="secondary"
            rounded
            onClick={handleSubmit}
          >
            新增
          </Button>
          <Button variant="outlined" color="error" rounded onClick={handleCancel}>
            取消
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}; // NewTimerReasonAction
NewTimerReasonAction.propTypes = {
  handleAddingClose: PropTypes.func.isRequired,
  stationList: PropTypes.array.isRequired,
  stationTypeList: PropTypes.array.isRequired,
  handleAddReason: PropTypes.func.isRequired,
};

export default NewTimerReasonAction;
