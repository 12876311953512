/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import MuiTableHead from '@mui/material/TableHead';
import TableCell from '../TableCell/TableCell';
import TableRow from '../TableRow/TableRow';

const useStyles = makeStyles()(() => ({
  head: {
    borderTop: '1px solid rgba(0, 0, 0, .12)',
  },
}));

const TableHead = ({ headCells, isCheckBox, style }) => {
  const { classes } = useStyles();
  return (
    <MuiTableHead className={classes.head}>
      <TableRow>
        {isCheckBox && <TableCell padding="checkbox" />}
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" style={style}>
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="right" style={style}>
          操作
        </TableCell>
      </TableRow>
    </MuiTableHead>
  );
};

TableHead.defaultProps = {
  style: {},
};

TableHead.propTypes = {
  headCells: PropTypes.arrayOf.isRequired,
  isCheckBox: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

export default TableHead;
