/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useReducer, useEffect, useRef, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import useAxios from '../hooks/useAxios';
import { DialogContext } from '../contexts/DialogContext';
import { dialogActions } from '../reducers/dialogReducer';
import regex from '../constants/validationRegex';
import useCancelToken from '../hooks/useCancelToken';
import PageTitle from '../components/PageTitle/PageTitle';
import Typography from '../components/Typography/Typography';
import Grid from '../components/Grid/Grid';
import PaperContainer from '../components/PaperContainer/PaperContainer';
import InputField from '../components/InputField/InputField';
import ReadonlyField from '../components/ReadonlyField/ReadonlyField';
import Button from '../components/Button/Button';

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(1.5),
  },
  content: {
    marginTop: theme.spacing(4),
  },
  error: {
    marginBottom: theme.spacing(2.5),
  },
  confirm: {
    marginTop: theme.spacing(3),
  },
}));

const PackageSeparation = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const { classes } = useStyles();
  const { newCancelToken } = useCancelToken();

  const [packageId, setPackageId] = useState('');
  const packageIdRef = useRef('');
  const [packageIdErr, setPackageIdErr] = useState(false);
  const [packageIdErrMsg, setPackageIdErrMsg] = useState('請輸入正確的檢裝單單號，並不得輸入中文');
  const [finishedQty, setFinishedQty] = useState('');
  const [finishedQtyErr, setFinishedQtyErr] = useState(false);
  const [finishedQtyErrMsg, setFinishedQtyErrMsg] = useState('');
  const [packageQty, setPackageQty] = useState(0);
  const [finalQty, setFinalQty] = useState(0);

  const resetPackage = () => {
    setPackageId('');
    setPackageIdErr(false);
    setPackageIdErrMsg('');
    setPackageQty(0);
    setFinishedQty('');
    setFinishedQtyErr(false);
    setFinishedQtyErrMsg('');
    setFinalQty(0);
  };

  const calcQty = () => {
    const finishedQuantity = Number(finishedQty);
    if (finishedQuantity > 0 && finishedQuantity < packageQty) {
      setFinalQty(+packageQty - finishedQuantity);
    }

    if (
      finishedQuantity < 0 ||
      finishedQuantity > packageQty ||
      finishedQuantity === 0 ||
      finishedQuantity === '' ||
      finishedQuantity === packageQty
    ) {
      setFinalQty(0);
    }
  };

  const fetchPackageData = async () => {
    if (!regex.TESTING_TRANS_ID.test(packageIdRef.current)) {
      setPackageIdErr(true);
      setPackageIdErrMsg('請輸入正確的檢裝單單號！');
      return;
    }
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getPackage',
      { packageId: packageIdRef.current },
      true,
      cancelToken
    );
    if (status) {
      const { pkg } = result;
      setPackageIdErr(false);
      setPackageQty(pkg.totalTransQuantity);
      setFinishedQty('');
      setFinalQty(0);
    } else {
      setPackageIdErr(true);
      setPackageIdErrMsg(noPrivilegeMsg || result);
    }
  };

  const handleConfirm = async () => {
    if (finishedQtyErr || packageIdErr) return;
    const cancelToken = newCancelToken();
    const data = {
      packageId: packageIdRef.current,
      finQuantity: finishedQty,
    };
    setFinishedQtyErr(false);
    if (data.finQuantity.length === 0 || data.finQuantity <= 0) {
      setFinishedQtyErr(true);
      setFinishedQtyErrMsg('完成數量不得為空!');
      return;
    }
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/splitPackage',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { fileUrl } = result;
      window.open(fileUrl, '_blank');
      resetPackage();
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '拆批檢裝單失敗！',
          message: noPrivilegeMsg || result,
        },
      });
    }
  };

  const handlePackageIdChange = (e) => {
    setPackageIdErr(false);
    packageIdRef.current = e.target.value.trim();
    setPackageId(packageIdRef.current);
    if (packageIdRef.current.length === 10) {
      fetchPackageData();
    }
  };

  const handleFinishedChange = (e) => {
    setFinishedQty(e.target.value);
  };

  const validationFinishedQty = () => {
    const finishedQuantity = Number(finishedQty);
    if (finishedQuantity > packageQty) {
      setFinishedQtyErrMsg('完成數量不能大於檢裝數量!');
      setFinishedQtyErr(true);
      return;
    }

    if (finishedQuantity === packageQty) {
      setFinishedQtyErrMsg('完成數量不能等於檢裝數量!');
      setFinishedQtyErr(true);
      return;
    }

    if (finishedQuantity < 0) {
      setFinishedQtyErrMsg('完成數量不能低於0!');
      setFinishedQtyErr(true);
      return;
    }

    setFinishedQtyErr(false);
  };

  useEffect(() => {
    validationFinishedQty();
    calcQty();
  }, [finishedQty]);

  useEffect(() => {
    resetPackage();
  }, []);

  return (
    <Grid container>
      <PageTitle title="拆批檢裝單" />
      <PaperContainer className={classes.container}>
        <Typography variant="h6">輸入欲拆批之檢裝單號</Typography>
        <Grid container spacing={2} className={classes.content} alignItems="flex-end">
          <Grid item xs={6}>
            <InputField
              id="transId"
              name="transId"
              label="原檢裝單"
              placeholder="請輸入欲拆批之檢裝單號 e.g. TJ20000001"
              type="text"
              error={packageIdErr}
              errormsg={packageIdErrMsg}
              value={packageId}
              onChange={handlePackageIdChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.content} alignItems="flex-end">
          <Grid item xs={3}>
            <ReadonlyField
              name="packageQty"
              value={String(packageQty)}
              label="檢裝總數"
              className={finishedQtyErr && classes.error}
            />
          </Grid>
          <Grid item xs={3}>
            <InputField
              name="finishedQty"
              value={finishedQty || ''}
              label="本次完成數量"
              placeholder="請輸入本次完成數量"
              type="number"
              error={finishedQtyErr}
              errormsg={finishedQtyErrMsg}
              disabled={packageQty <= 0}
              onChange={handleFinishedChange}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadonlyField
              name="productQty"
              value={String(finalQty)}
              label="累計總數"
              className={finishedQtyErr && classes.error}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<CheckRoundedIcon />}
              onClick={handleConfirm}
              customClasses={finishedQtyErr && classes.error}
            >
              拆批
            </Button>
          </Grid>
        </Grid>
      </PaperContainer>
    </Grid>
  );
};

export default PackageSeparation;
