/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import useCommonStyles from '../../useStyles';
import { measureStyleType } from '../../../../constants/enums';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import { getSpec } from '../../../../Utils/measureSpecUtils';

const ZMeasureStyle = ({ specSettings, measureConfig }) => {
  const starSpec = [
    ...Array(getSpec(specSettings, measureStyleType.STAR).toString().length).keys(),
  ];
  const diffStarSpec = [
    ...Array(
      getSpec(specSettings, measureStyleType.STAR, measureConfig, true).toString().length
    ).keys(),
  ];
  const coilSpec = getSpec(specSettings, measureStyleType.COIL).join(' / ');
  const diffCoilSpec = getSpec(specSettings, measureStyleType.COIL, measureConfig, true).join(
    ' / '
  );
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  return (
    <Grid container className={`${commonClasses.table_header} ${classes.measure_header}`}>
      <Typography variant="subtitle1" className={classes.measure_info}>
        <span>星數：</span>
        <b>{starSpec.map(() => '＊')}</b>
        {diffStarSpec && diffStarSpec.length !== starSpec.length && (
          <>
            <br />
            <span style={{ opacity: 0 }}>星數：</span>
            <b className={classes.measure_info_disabled}>{diffStarSpec.map(() => '＊')}</b>
          </>
        )}
      </Typography>
      <Typography variant="subtitle1" className={classes.measure_info}>
        <span>線圈設定：</span>
        <i>{coilSpec}</i>
        {diffCoilSpec && diffCoilSpec !== coilSpec && (
          <>
            <br />
            <span style={{ opacity: 0 }}>線圈設定：</span>
            <i className={classes.measure_info_disabled}>{diffCoilSpec}</i>
          </>
        )}
      </Typography>
    </Grid>
  );
};

ZMeasureStyle.propTypes = {
  specSettings: PropTypes.object.isRequired,
  measureConfig: PropTypes.array.isRequired,
};

export default ZMeasureStyle;
