import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme/theme';
import App from './App';
import AuthContextProvider from './contexts/AuthContext';
import DialogContextProvider from './contexts/DialogContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Router>
        <AuthContextProvider>
          <DialogContextProvider>
            <App />
          </DialogContextProvider>
        </AuthContextProvider>
      </Router>
    </ThemeProvider>
  </>
  // </React.StrictMode>,
);
