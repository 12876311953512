import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: 'transparent',
    borderBottom: `2px solid ${alpha(theme.palette.common.black, 0.2)}`,
  },
  input: {
    padding: '22px 6px 8px !important',
  },
  disabled: {
    '&.Mui-disabled': {
      backgroundColor: 'transparent !important',
      WebkitTextFillColor: theme.palette.common.black,
    },
  },
  label: {
    transform: 'translate(6px, 0) scale(0.85)',
    color: theme.palette.common.black,
  },
}));

export default useStyles;
