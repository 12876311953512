/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '../Typography/Typography';
import routes from '../../routes';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const { classes } = useStyles();

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" className={classes.root}>
      <Link color="inherit" underline="hover" href="/">
        <Typography variant="body2">Home</Typography>
      </Link>
      {pathnames.map((item, idx) =>
        idx === pathnames.length - 1
          ? routes.find((route) => route.name === item) && (
              <Typography variant="body2" color="textPrimary" key={idx}>
                {routes.find((route) => route.name === item).displayName}
              </Typography>
            )
          : routes.find((route) => route.name === item) && (
              <Link
                color="textPrimary"
                underline="hover"
                href={routes.find((route) => route.name === item).path}
                key={idx}
              >
                <Typography variant="body2">
                  {routes.find((route) => route.name === item).displayName}
                </Typography>
              </Link>
            )
      )}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
