/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../components/Grid/Grid';
import ReadonlyField from '../../components/ReadonlyField/ReadonlyField';
import useFormingType from '../../hooks/useFormingType';

const FilterInfo = ({ productInfo }) => (
  <>
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <ReadonlyField name="name" label="產品" value={productInfo.name || '-'} />
      </Grid>
      <Grid item xs={4}>
        <ReadonlyField name="materialName" label="材質" value={productInfo.materialName || '-'} />
      </Grid>
      <Grid xs={4}>
        <ReadonlyField name="elShape" label="產品形狀" value={productInfo.elShape || '-'} />
      </Grid>
      <Grid xs={4}>
        <ReadonlyField name="elSize" label="產品規格" value={productInfo.elSize || '-'} />
      </Grid>
      <Grid xs={4}>
        <ReadonlyField
          name="formingType"
          label="壓型加工"
          value={useFormingType(productInfo.formingType).name || '-'}
        />
      </Grid>
      <Grid xs={4}>
        <ReadonlyField name="preStockQty" label="目前數量" value={productInfo.preStockQty || '-'} />
      </Grid>
    </Grid>
  </>
);

FilterInfo.propTypes = {
  productInfo: PropTypes.object.isRequired,
};

export default FilterInfo;
