/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import useCommonStyles from '../useStyles';
import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';
import checkboxReducer, { checkboxActions } from '../../../reducers/checkboxReducer';
import CheckboxGroup from '../../../components/CheckboxGroup/CheckboxGroup';
import { tabDirectionType } from '../../../constants/enums';

const ConfigItemRow = ({
  data,
  handleRowChange,
  rowIndex,
  totalCut,
  totalMold,
  tabDirection,
  target,
  needRerender,
  targetRerender,
  setTargetRerender,
  disabled,
}) => {
  const { moldNo, cutNo, defectType } = data;
  const [isHeightOutSpec, setIsHeightOutSpec] = useState(false);
  const [isWeightOutSpec, setIsWeightOutSpec] = useState(false);
  const [defectTypeItems, defectTypeDispatch] = useReducer(checkboxReducer, []);
  const [rowState, setRowState] = useState({}); // use useState to re-render
  const rowData = useRef({}); // we use useRef to get the current value --> the async feature of useState will cause data losing
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;

  const heightOutSpec = (heightValue) => {
    const { heightMax, heightMin } = target;
    if (+heightValue <= +heightMax && +heightValue >= +heightMin) {
      return false;
    }
    return true;
  };

  const weightOutSpec = (weightValue) => {
    const { weightMax, weightMin } = target;
    if (+weightValue <= +weightMax && +weightValue >= +weightMin) {
      return false;
    }
    return true;
  };

  const handleHeightChange = (e) => {
    rowData.current = {
      ...rowData.current,
      height: e.target.value,
      isInValid: false,
    };
    setRowState((prev) => ({
      ...prev,
      height: e.target.value,
      isInValid: false,
    }));
    if (heightOutSpec(e.target.value)) {
      setIsHeightOutSpec(true);
      return;
    }
    setIsHeightOutSpec(false);
  };

  const handleWeightChange = (e) => {
    rowData.current = {
      ...rowData.current,
      weight: e.target.value,
      isInValid: false,
    };
    setRowState((prev) => ({
      ...prev,
      weight: e.target.value,
      isInValid: false,
    }));
    if (weightOutSpec(e.target.value)) {
      setIsWeightOutSpec(true);
      return;
    }
    setIsWeightOutSpec(false);
  };

  const handleNoteChange = (e) => {
    rowData.current = {
      ...rowData.current,
      note: e.target.value,
    };
    setRowState((prev) => ({
      ...prev,
      note: e.target.value,
    }));
  };

  const handleClearRow = () => {
    rowData.current = {
      ...rowData.current,
      weight: '',
      height: '',
      note: '',
      defectType: [],
    };
    setRowState((prev) => ({
      ...prev,
      weight: '',
      height: '',
      note: '',
      defectType: [],
    }));
    defectTypeDispatch({
      type: checkboxActions.RESET,
    });
  };

  const gatherRowData = () => ({
    ...rowData.current,
    defectType: [...rowData.current.defectType],
  });

  const registerGatherData = () => {
    // Register a gatherData function to the main component(<QCForming />) to gather all the changes in single itemRow
    handleRowChange((prev) =>
      prev.map((row) =>
        row.rowId === rowIndex
          ? {
              ...row,
              gatherData: gatherRowData,
            }
          : row
      )
    );
  };

  const isTargetOutOfSpec = () => {
    const { weightMax, weightMin, heightMax, heightMin } = target;
    const { weight: currentWeight, height: currentHeight } = rowData.current;
    if (currentWeight && currentWeight.length !== 0) {
      if (+currentWeight > +weightMax || +currentWeight < +weightMin) {
        setIsWeightOutSpec(true);
      } else {
        setIsWeightOutSpec(false);
      }
    }
    if (currentHeight && currentHeight.length !== 0) {
      if (+currentHeight > +heightMax || +currentHeight < +heightMin) {
        setIsHeightOutSpec(true);
      } else {
        setIsHeightOutSpec(false);
      }
    }
  };

  useEffect(() => {
    defectTypeDispatch({
      type: checkboxActions.INIT,
      init: defectType.map((item) => ({
        name: item.typeId,
        label: item.typeName,
        checked: item.isChecked,
      })),
    });
    registerGatherData();
  }, []);

  useEffect(() => {
    if (!targetRerender) return;
    isTargetOutOfSpec();
    setTargetRerender(false);
  }, [targetRerender]);

  useEffect(() => {
    rowData.current = {
      ...data,
      defectType: [...data.defectType],
    };
    setRowState({
      ...data,
      defectType: [...data.defectType],
    });
    defectTypeDispatch({
      type: checkboxActions.INIT,
      init: data.defectType.map((item) => ({
        name: item.name,
        label: item.label,
        checked: item.checked,
      })),
    });
    isTargetOutOfSpec();
  }, [data]);

  useEffect(() => {
    if (needRerender) {
      registerGatherData();
    }
  }, [needRerender, target]);

  useEffect(() => {
    rowData.current = {
      ...rowData.current,
      defectType: [...defectTypeItems],
    };
    setRowState((prev) => ({
      ...prev,
      defectType: [...defectTypeItems],
    }));
  }, [defectTypeItems]);

  return (
    <div className={commonClasses.table_row}>
      <div className={`${commonClasses.table_column} ${classes.table_action_cell}`}>
        <Button
          variant="outlined"
          rounded
          color="primary"
          customClasses={commonClasses.empty_btn}
          onClick={handleClearRow}
          disabled={disabled}
        >
          清空
        </Button>
      </div>
      <div className={commonClasses.table_column}>
        <span className={`${classes.table_cell} ${commonClasses.table_cell}`}>
          <Typography variant="body1">{moldNo}</Typography>
        </span>
        <span className={`${classes.table_cell} ${commonClasses.table_cell}`}>
          <Typography variant="body1">{cutNo}</Typography>
        </span>
        <span
          className={`${commonClasses.table_cell} ${classes.table_cell} ${classes.table_input_cell}`}
        >
          <InputField
            inputClasses={`${commonClasses.table_input} ${
              isHeightOutSpec && commonClasses.out_spec
            }`}
            value={rowData.current.height}
            onChange={handleHeightChange}
            id={`height${rowIndex + 1}`}
            name="height"
            type="number"
            tabIndex={
              tabDirection === tabDirectionType.BY_SAMPLE ? rowIndex + 1 : (rowIndex + 1) * 2
            }
            error={rowData.current.isInValid}
            disabled={disabled}
          />
        </span>
        <span
          className={`${commonClasses.table_cell} ${classes.table_cell} ${classes.table_input_cell}`}
        >
          <InputField
            inputClasses={`${commonClasses.table_input} ${
              isWeightOutSpec && commonClasses.out_spec
            }`}
            value={rowData.current.weight}
            onChange={handleWeightChange}
            id={`weight${rowIndex + 1}`}
            name="weight"
            type="number"
            tabIndex={
              tabDirection === tabDirectionType.BY_SAMPLE
                ? rowIndex + 1 + totalCut * totalMold
                : (rowIndex + 1) * 2 + 1
            }
            error={rowData.current.isInValid}
            disabled={disabled}
          />
        </span>
      </div>
      <div
        className={`${commonClasses.table_column} ${classes.table_defect_cell}`}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <CheckboxGroup
          itemList={defectTypeItems}
          checkboxDispatch={defectTypeDispatch}
          direction="row"
          reverse
          disabled={disabled}
        />
      </div>
      <div
        className={`${commonClasses.table_column} ${classes.table_note_cell} ${classes.table_note_input}`}
      >
        <InputField
          size="small"
          id={`note${rowIndex + 1}`}
          value={rowData.current.note}
          name="note"
          type="text"
          placeholder="備註"
          onChange={handleNoteChange}
          error={false}
          errormsg=""
          disabled={disabled}
        />
      </div>
    </div>
  );
};

ConfigItemRow.propTypes = {
  data: PropTypes.object.isRequired,
  handleRowChange: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  totalCut: PropTypes.number.isRequired,
  totalMold: PropTypes.number.isRequired,
  tabDirection: PropTypes.string.isRequired,
  target: PropTypes.object.isRequired,
  needRerender: PropTypes.bool.isRequired,
  targetRerender: PropTypes.bool.isRequired,
  setTargetRerender: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default React.memo(ConfigItemRow);
