import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  tabs: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    minWidth: 160,
  },
  paper_tabs: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  container: {
    marginBottom: theme.spacing(1.5),
  },
  error: {
    marginBottom: theme.spacing(2.5),
  },
  snackbar: {
    marginBottom: theme.spacing(2),
  },
  dialog_header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: `0 4px 6px ${alpha(theme.palette.common.black, 0.15)}`,
  },
  submit_btn: {
    marginTop: theme.spacing(1),
    marginRight: 0,
  },
}));

export default useStyles;
