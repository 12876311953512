/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import useStyles from './useStyles';
import PageTitle from '../../components/PageTitle/PageTitle';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import TransLabel from '../../components/TransLabel/TransLabel';
import Checkbox from '../../components/Checkbox/Checkbox';
import InputField from '../../components/InputField/InputField';
import ReadonlyField from '../../components/ReadonlyField/ReadonlyField';
import SelectField from '../../components/SelectField/SelectField';
import Button from '../../components/Button/Button';
import Divider from '../../components/Divider/Divider';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';
import {
  machineType as machineTypeEnum,
  processComplete as processEnum,
  stationType,
} from '../../constants/enums';
import useCancelToken from '../../hooks/useCancelToken';
import { handleUpdateTransTime } from '../../Utils/processTimeUtils';
import UpdateSubmitReasonSelect from './UpdateSubmitReasonSelect';
import useStationType from '../../hooks/useStationType';

const TransUpdateGrinding = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const [transInfo, setTransInfo] = useState({
    transId: '-',
    workId: '-',
    elNo: '-',
    materialName: '-',
    productName: '-',
    createQty: '-',
    transQty: '-',
    isRStick: false,
  });
  const [timer, setTimer] = useState({});

  const initialForm = {
    grindMachineId: '0',
    platedMachineId: '0',
    bothGrindMachineId: '0',
    bothPlatedMachineId: '0',
    otherMachineId: '0',
    grindQuantity: '',
    platedQuantity: '',
    platedAndGrindQuantity: '',
    noneQuantity: '',
  };
  const {
    formData: transForm,
    setFormData: setTransForm,
    formErr: transFormErr,
    setFormErr: setTransFormErr,
    resetFormErr,
  } = useForm(initialForm);
  const [grinderList, setGrinderList] = useState([]);
  const [platedList, setPlatedList] = useState([]);
  const [machineType, setMachineType] = useState('');
  const [isMultiChoice, setIsMultiChoice] = useState(false);
  const [isNoChoice, setIsNoChoice] = useState(false);
  const [checkboxCheck, setCheckboxCheck] = useState({
    grinder: false,
    plated: false,
    both: false,
    none: false,
  });
  const [submitDisable, setSubmitDisable] = useState(true);
  const [processDisable, setProcessDisable] = useState(true);
  const [startDisable, setStartDisable] = useState(false);
  const [transQuantityConfirm, setTransQuantityConfirm] = useState('');
  const [transQuantityConfirmError, setTransQuantityConfirmError] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const processStartTime = useRef(null);
  const processEndTime = useRef(null);
  const reason = useRef(0);
  const reasonErr = useRef(false);
  const reasonList = useRef([]);

  const navigate = useNavigate();
  const { classes } = useStyles();
  const { trans_id } = useParams();
  const { newCancelToken } = useCancelToken();

  const getTransData = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getTrans',
      { transId: trans_id },
      true,
      cancelToken
    );
    if (status) {
      const { transfer, machine, grind, timerRecord } = result;
      const {
        transId,
        scOdno,
        elNo,
        materialName,
        prodName,
        createQuantity,
        transQuantity,
        isRStick,
        grindMachineId,
        platedMachineId,
        processComplete,
        platedAndGrindMachineId,
        isNoneCheck,
        grindQuantity,
        platedQuantity,
        otherQuantity,
        noneQuantity,
      } = transfer;

      if (processComplete < processEnum.SINTERING) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '無法更新此移轉單',
            message: '此移轉單尚未完成燒結，不得進行研磨更新！',
            singleBtn: true,
            handleConfirm: () => {
              navigate('/trans-update-failed');
            },
          },
        });
        return;
      }

      setTransInfo({
        transId,
        elNo,
        materialName,
        isRStick: !!isRStick,
        workId: scOdno,
        productName: prodName,
        transQty: transQuantity ? transQuantity.toString() : '-',
        createQty: createQuantity ? createQuantity.toString() : '-',
      });
      setPlatedList(
        machine.map((item) => ({
          name: item.machineName,
          value: item.machineId.toString(),
        }))
      );
      setGrinderList(
        grind.map((item) => ({
          name: item.machineName,
          value: item.machineId.toString(),
        }))
      );

      if (grindMachineId) {
        setMachineType(machineTypeEnum.GRIND);
        setTransForm((prev) => ({
          ...prev,
          grindMachineId: grindMachineId.toString(),
          grindQuantity: grindQuantity ? grindQuantity.toString() : '',
        }));
        setCheckboxCheck((prev) => ({
          ...prev,
          grinder: true,
        }));
      }
      if (platedMachineId) {
        setMachineType(machineTypeEnum.PLATED);
        setTransForm((prev) => ({
          ...prev,
          platedMachineId: platedMachineId.toString(),
          platedQuantity: platedQuantity ? platedQuantity.toString() : '',
        }));
        setCheckboxCheck((prev) => ({
          ...prev,
          plated: true,
        }));
      }

      if (platedAndGrindMachineId && platedAndGrindMachineId !== '0,0') {
        setMachineType(machineTypeEnum.BOTH);
        setTransForm((prev) => ({
          ...prev,
          bothGrindMachineId: platedAndGrindMachineId.split(',')[1],
          bothPlatedMachineId: platedAndGrindMachineId.split(',')[0],
          platedAndGrindQuantity: otherQuantity ? otherQuantity.toString() : '',
        }));
        setCheckboxCheck((prev) => ({
          ...prev,
          both: true,
        }));
      }
      if (isNoneCheck === true) {
        setCheckboxCheck((prev) => ({
          ...prev,
          none: true,
        }));
        setTransForm((prev) => ({
          ...prev,
          noneQuantity: noneQuantity ? noneQuantity.toString() : '',
        }));
      }

      /**
       * processComplete 總共有 0 - 5
       * 0: 什麼都沒有做
       * 1: 做完壓型移轉單
       * 2: 做完燒結移轉單
       * 3: 做完研磨移轉單
       * 4: 做完全檢移轉單
       * 5: 做完包裝移轉單
       * */
      if (processComplete >= 3) {
        setIsCreate(false);
      }
      // 是否需要清空timer 待討論
      // handleUpdateTransTime(newCancelToken, dialogDispatch, {
      //   transId,
      //   status: processRecordStatus.CLEAR,
      // });
      if (timerRecord) {
        setTimer(timerRecord);
        setStartDisable(true);
        setSubmitDisable(false);
        setProcessDisable(false);
      }
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料讀取錯誤',
          message: noPrivilegeMsg || '請掃描有效之移轉單或確定正確掃描移轉單。',
        },
      });
    }
  };

  const getReasonList = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getTimerReason',
      { stationType: stationType.GRINDING },
      true,
      cancelToken
    );
    if (status) {
      reasonList.current = result.timerReason;
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料讀取錯誤',
          message: noPrivilegeMsg || '目前無法取得暫停原因資訊，請稍後再試，或請聯絡網管人員！',
        },
      });
    }
  };

  const handleMachineChange = (e) => {
    setMachineType(e.target.value);
    setCheckboxCheck((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleChange = (e) => {
    setTransForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const validationTransForm = () => {
    if (checkboxCheck.grinder === true && transForm.grindMachineId === '0') {
      setTransFormErr((prev) => ({
        ...prev,
        grindMachineId: true,
      }));
      return false;
    }
    if (checkboxCheck.grinder === true && isMultiChoice && transForm.grindQuantity === '') {
      setTransFormErr((prev) => ({
        ...prev,
        grindQuantity: true,
      }));
      return false;
    }
    if (checkboxCheck.plated === true && transForm.platedMachineId === '0') {
      setTransFormErr((prev) => ({
        ...prev,
        platedMachineId: true,
      }));
      return false;
    }
    if (checkboxCheck.plated === true && isMultiChoice && transForm.platedQuantity === '') {
      setTransFormErr((prev) => ({
        ...prev,
        platedQuantity: true,
      }));
      return false;
    }
    if (checkboxCheck.both === true && transForm.bothGrindMachineId === '0') {
      setTransFormErr((prev) => ({
        ...prev,
        bothGrindMachineId: true,
      }));
      return false;
    }
    if (checkboxCheck.both === true && transForm.bothPlatedMachineId === '0') {
      setTransFormErr((prev) => ({
        ...prev,
        bothPlatedMachineId: true,
      }));
      return false;
    }
    if (checkboxCheck.both === true && isMultiChoice && transForm.platedAndGrindQuantity === '') {
      setTransFormErr((prev) => ({
        ...prev,
        platedAndGrindQuantity: true,
      }));
      return false;
    }
    if (checkboxCheck.none === true && isMultiChoice && transForm.none === '') {
      setTransFormErr((prev) => ({
        ...prev,
        noneQuantity: true,
      }));
      return false;
    }
    if (machineType.length === 0) {
      setMachineType('none');
      setTransForm({
        plated: '',
        grinder: '',
      });
    }
    return true;
  };

  const handleUpdateGrind = async (timerRecord) => {
    resetFormErr();
    if (!validationTransForm()) return;
    if (isNoChoice) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '錯誤',
          message: '請至少選擇一項製造資訊',
        },
      });
      setProcessDisable(false);
      return;
    }
    const sameData = {
      transId: trans_id,
      grindMachineId: !checkboxCheck.grinder ? 0 : transForm.grindMachineId,
      platedMachineId: !checkboxCheck.plated ? 0 : transForm.platedMachineId,
      bothGrindMachineId: !checkboxCheck.both ? 0 : transForm.bothGrindMachineId,
      bothPlatedMachineId: !checkboxCheck.both ? 0 : transForm.bothPlatedMachineId,
    };

    // 如果不是多選，不可以填數量，因此全部給空字串
    const data = !isMultiChoice
      ? {
          ...sameData,
          isNoneCheck: !!checkboxCheck.none,
          grindQuantity: '',
          platedQuantity: '',
          platedAndGrindQuantity: '',
          noneQuantity: '',
          isMultipleChoice: 0,
        }
      : {
          transId: trans_id,
          ...sameData,
          grindQuantity: !checkboxCheck.grinder ? '' : Number(transForm.grindQuantity),
          platedQuantity: !checkboxCheck.plated ? '' : Number(transForm.platedQuantity),
          platedAndGrindQuantity: !checkboxCheck.both
            ? ''
            : Number(transForm.platedAndGrindQuantity),
          noneQuantity: !checkboxCheck.none ? '' : Number(transForm.noneQuantity),
          isMultipleChoice: 1,
        };

    const finalData = {
      ...data,
    };
    if (isCreate) {
      finalData.timerRecord = timerRecord;
    }

    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/updateGrind',
      finalData,
      true,
      cancelToken
    );
    if (status) {
      setProcessDisable(true);
      navigate('/trans-update-success');
    } else {
      if (rtCode === 'E99' || rtCode === 'E45') {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            singleBtn: true,
            title: '錯誤',
            message: noPrivilegeMsg || result,
          },
        });
        return;
      }
      navigate('/trans-update-failed');
    }
  };

  const handleConfirm = () => {
    if (transQuantityConfirm === '') {
      setTransQuantityConfirmError(true);
    }
    if (transQuantityConfirm === 'check') {
      // 若點擊送出按鈕，reason 設定 -1
      reason.current = -1;

      // 以下為update timer 的邏輯
      const updateData = {
        ...timer,
        endTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        reasonId: reason.current,
      };
      handleUpdateGrind(updateData);
    }
  };

  const handleCheckBoxChange = () => {
    setTransQuantityConfirm((prev) => {
      if (prev === '') {
        if (transQuantityConfirmError) setTransQuantityConfirmError(false);
        return 'check';
      }

      return '';
    });
  };

  // 開啟 dialog
  const getReasonDialog = (validationEditReason) => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '請選擇暫停原因',
        body: (
          <UpdateSubmitReasonSelect
            reason={reason.current}
            reasonList={reasonList.current}
            reasonErr={reasonErr.current}
            handleReasonChange={(value) => {
              reason.current = value;
            }}
          />
        ),
        handleConfirm: validationEditReason,
        handleCancel: () => {
          reasonErr.current = false;
          reason.current = 0;
        },
      },
    });
  };

  // 判斷 dialog
  const validationEditReason = async () => {
    if (reason.current === 0) {
      reasonErr.current = true;
      const dialogTimer = setTimeout(() => {
        getReasonDialog(validationEditReason);
        clearTimeout(dialogTimer);
      }, [500]);
      return;
    }
    reasonErr.current = false;
    setSubmitDisable(true);
    processEndTime.current = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    setStartDisable(false);
    const updateData = {
      ...timer,
      endTime: processEndTime.current,
      reasonId: reason.current,
      stationType: useStationType('GRINDING'),
    };
    const [status] = await handleUpdateTransTime(newCancelToken, dialogDispatch, updateData);
    if (status) {
      setProcessDisable(true);
      reason.current = 0;
    }
  };

  const showRecordsSelectionDialog = () => {
    getReasonDialog(validationEditReason);
  };

  const handleProcessStart = async () => {
    processStartTime.current = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    setStartDisable(true);
    setSubmitDisable(false);
    setProcessDisable(false);
    const startProcessData = {
      transId: transInfo.transId,
      startTime: processStartTime.current,
      stationType: useStationType('GRINDING'),
    };
    const [status, result] = await handleUpdateTransTime(
      newCancelToken,
      dialogDispatch,
      startProcessData
    );
    if (!status) {
      setProcessDisable(true);
      return;
    }
    setTimer(result);
  };
  const handleProcessEnd = () => {
    showRecordsSelectionDialog();
  };

  useEffect(() => {
    const selectedStatus = Object.values(checkboxCheck);
    const selectedTrue = selectedStatus.filter((bol) => bol === true);
    const selectedFalse = selectedStatus.filter((bol) => bol === false);
    // eslint-disable-next-line no-unused-expressions
    selectedTrue.length >= 2 ? setIsMultiChoice(true) : setIsMultiChoice(false);
    // eslint-disable-next-line no-unused-expressions
    selectedFalse.length === 4 ? setIsNoChoice(true) : setIsNoChoice(false);
    // eslint-disable-next-line no-unused-expressions
  }, [checkboxCheck]);

  useEffect(() => {
    getTransData();
    getReasonList();
    resetFormErr();
  }, []);

  return (
    <Grid container className={classes.root}>
      <PageTitle title="更新移轉單（研磨）" />
      <PaperContainer>
        <div className={`${classes.content} ${classes.header}`}>
          <Typography variant="h6">移轉單資訊</Typography>
          <TransLabel label="研磨" />
        </div>
        <div className={classes.content}>
          <div className={classes.field}>
            <ReadonlyField
              label="移轉單號 / Trans nomor pesanan"
              value={transInfo.transId}
              name="transId"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="工令單號 / Nomor perintah kerja"
              value={transInfo.workId}
              name="workId"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField label="料號 / Bagian No" value={transInfo.elNo} name="elNo" />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="材質 / Bahan"
              value={transInfo.materialName}
              name="materialName"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="產品名稱 / Nama Produk"
              value={transInfo.productName}
              name="productName"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="匣數 / Kuantitas manufaktur"
              value={transInfo.createQty}
              name="createQty"
            />
          </div>
          <div className={classes.field}>
            <ReadonlyField
              label="移轉數量 / Kuantitus transfer"
              value={transInfo.transQty}
              name="transQty"
            />
            <Checkbox
              name="confirmSintering"
              label="確認無誤"
              onChange={handleCheckBoxChange}
              value={transQuantityConfirm}
              customClasses={classes.checkbox}
              checked={transQuantityConfirm}
              error={transQuantityConfirmError}
              errorMsg="請確認移轉數量無誤!"
            />
          </div>
          {isCreate && (
            <div className={classes.field} style={{ display: 'flex' }}>
              <Button
                variant="outlined"
                color={!startDisable ? 'error' : 'grey'}
                disabled={startDisable}
                fullWidth
                startIcon={<TimerIcon />}
                customClasses={classes.process_btn}
                onClick={handleProcessStart}
              >
                開始 / Mulai
              </Button>
              <Button
                variant="contained"
                color="error"
                disabled={isCreate ? processDisable : false}
                fullWidth
                startIcon={<TimerOffIcon />}
                customClasses={classes.process_btn}
                onClick={handleProcessEnd}
              >
                暫停 / Berhenti
              </Button>
            </div>
          )}
        </div>
        <Divider className={classes.divider} />
        <div className={`${classes.content} ${classes.header}`}>
          {/* processDisable 為 process end button 的disable 狀態，製造資訊disable 邏輯應與process end button 相同，因時間延至下一階段，所以註解掉 */}
          <Typography variant="h6">製造資訊</Typography>
        </div>
        <div className={classes.content}>
          <div className={classes.selection}>
            <Checkbox
              name="grinder"
              label="研磨 / Menggiling"
              onChange={handleMachineChange}
              value={machineTypeEnum.GRIND}
              checked={checkboxCheck.grinder}
              disabled={isCreate ? processDisable : false}
            />
          </div>
          {checkboxCheck.grinder === true && (
            <>
              <div className={classes.selection}>
                <SelectField
                  label="研磨機台 / Mesin gerinda"
                  placeholder="請選擇研磨機台 / Mesin gerinda"
                  handleChange={handleChange}
                  selections={grinderList}
                  errormsg="請選擇研磨機台 / Mesin gerinda！"
                  error={transFormErr.grindMachineId}
                  value={transForm.grindMachineId}
                  id="grindMachineId"
                  disabled={isCreate ? processDisable : false}
                />
              </div>
              {checkboxCheck.grinder === true && isMultiChoice && (
                <div className={classes.selection}>
                  <InputField
                    label="研磨數量 / Gerinda kuantitas"
                    placeholder="請填寫研磨數量 / Gerinda kuantitas"
                    value={transForm.grindQuantity}
                    onChange={handleChange}
                    errormsg="請選擇研磨數量 /Mesin gerinda！"
                    error={transFormErr.grindQuantity}
                    id="grindQuantity"
                    name="grindQuantity"
                    disabled={isCreate ? processDisable : false}
                  />
                </div>
              )}
            </>
          )}
          {transInfo.isRStick && (
            <div className={classes.selection}>
              <Checkbox
                name="plated"
                label="通孔 / Melalui lubang"
                onChange={handleMachineChange}
                value={machineTypeEnum.PLATED}
                checked={checkboxCheck.plated}
                disabled={isCreate ? processDisable : false}
              />
            </div>
          )}
          {checkboxCheck.plated === true && (
            <>
              <div className={classes.selection}>
                <SelectField
                  label="通孔機台 / Melalui mesin lubang"
                  placeholder="請選擇通孔機台 / Melalui mesin lubang"
                  handleChange={handleChange}
                  selections={platedList}
                  errormsg="請選擇通孔機台 / Melalui mesin lubang！"
                  error={transFormErr.platedMachineId}
                  value={transForm.platedMachineId}
                  id="platedMachineId"
                  disabled={isCreate ? processDisable : false}
                />
              </div>
              {checkboxCheck.plated === true && isMultiChoice && (
                <div className={classes.selection}>
                  <InputField
                    label="通孔數量 / Melalui kuantitas"
                    placeholder="請填寫通孔數量 / Melalui kuantitas lubang"
                    value={transForm.platedQuantity}
                    onChange={handleChange}
                    errormsg="請選擇通孔數量 / Melalui kuantitas lubang！"
                    error={transFormErr.platedQuantity}
                    id="platedQuantity"
                    name="platedQuantity"
                    disabled={isCreate ? processDisable : false}
                  />
                </div>
              )}
            </>
          )}
          {transInfo.isRStick && (
            <Checkbox
              name="both"
              label="研磨+通孔 / Menggiling+Melalui lubang"
              onChange={handleMachineChange}
              value={machineTypeEnum.BOTH}
              checked={checkboxCheck.both}
              disabled={isCreate ? processDisable : false}
            />
          )}
          {checkboxCheck.both === true && (
            <>
              <div className={classes.selection}>
                <SelectField
                  label="研磨機台 / Mesin gerinda"
                  placeholder="請選擇研磨機台 / Mesin gerinda"
                  handleChange={handleChange}
                  selections={grinderList}
                  errormsg="請選擇研磨機台 / Mesin gerinda！"
                  error={transFormErr.bothGrindMachineId}
                  value={transForm.bothGrindMachineId}
                  id="bothGrindMachineId"
                  disabled={isCreate ? processDisable : false}
                />
              </div>
              <div className={classes.selection}>
                <SelectField
                  label="通孔機台 / Melalui mesin lubang"
                  placeholder="請選擇通孔機台 / Melalui mesin lubang"
                  handleChange={handleChange}
                  selections={platedList}
                  errormsg="請選擇通孔機台 / Melalui mesin lubang！"
                  error={transFormErr.bothPlatedMachineId}
                  value={transForm.bothPlatedMachineId}
                  id="bothPlatedMachineId"
                  disabled={isCreate ? processDisable : false}
                />
              </div>
              {checkboxCheck.both === true && isMultiChoice && (
                <div className={classes.selection}>
                  <InputField
                    label="研磨+通孔數量 / Menggiling+Melalui kuantitas"
                    placeholder="請填寫研磨+通孔數量 / Menggiling+Melalui kuantitas"
                    value={transForm.platedAndGrindQuantity}
                    onChange={handleChange}
                    errormsg="請填寫研磨+通孔數量 / Menggiling+Melalui kuantitas"
                    error={transFormErr.platedAndGrindQuantity}
                    id="platedAndGrindQuantity"
                    name="platedAndGrindQuantity"
                    disabled={isCreate ? processDisable : false}
                  />
                </div>
              )}
            </>
          )}
          {!transInfo.isRStick && (
            <div className={classes.selection}>
              <Checkbox
                name="none"
                label="無 / None"
                onChange={handleMachineChange}
                value={machineTypeEnum.NONE}
                checked={checkboxCheck.none}
                disabled={isCreate ? processDisable : false}
              />
            </div>
          )}
          {checkboxCheck.none === true && isMultiChoice && (
            <div className={classes.selection}>
              <InputField
                label="無 / None"
                placeholder="請填寫無數量 / None kuantitas"
                value={transForm.noneQuantity}
                onChange={handleChange}
                errormsg="請填寫無數量 / None kuantitas"
                error={transFormErr.noneQuantity}
                id="noneQuantity"
                name="noneQuantity"
              />
            </div>
          )}
        </div>
        <div className={`${classes.confirm} ${classes.upper_gap}`}>
          <Button
            variant="contained"
            color="primary"
            disabled={isCreate ? submitDisable : false}
            fullWidth
            onClick={handleConfirm}
            startIcon={<CheckRoundedIcon />}
          >
            送出
          </Button>
        </div>
      </PaperContainer>
    </Grid>
  );
};

export default TransUpdateGrinding;
