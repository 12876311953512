import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';

const NewQCTypeAction = ({ handleAddingClose, handleAddTransferType }) => {
  const [QCType, setQCType] = useState('');
  const [QCTypeErr, setQCTypeErr] = useState(false);
  const { dialogDispatch } = useContext(DialogContext);

  const handleQCTypeInputChange = (e) => {
    if (QCTypeErr) {
      setQCTypeErr(false);
    }
    setQCType(e.target.value);
  };

  const validationForm = () => {
    if (QCType.length === 0) {
      setQCTypeErr(true);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validationForm()) return;
    handleAddTransferType(QCType);
    setQCType('');
    handleAddingClose();
  };

  const handleCancelAction = () => {
    setQCType('');
    handleAddingClose();
  };

  const handleCancel = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        singleBtn: true,
        title: '確認離開？',
        message: '是否確認放棄新增此筆資料？？',
        handleConfirm: handleCancelAction,
      },
    });
  };

  return (
    <div style={{ margin: 20 }}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <InputField
            id="QCTypeNo"
            label="Defect Type"
            value={QCType}
            variant="outlined"
            onChange={handleQCTypeInputChange}
            placeholder="請輸入Defect Type"
            name="QCTypeNo"
            type="text"
            error={QCTypeErr}
            errormsg="請輸入正確的Defect Type"
          />
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          <Button
            style={{ marginRight: 10 }}
            variant="outlined"
            color="secondary"
            rounded
            onClick={handleSubmit}
          >
            新增
          </Button>
          <Button variant="outlined" color="error" rounded onClick={handleCancel}>
            取消
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}; // NewQCTypeAction
NewQCTypeAction.propTypes = {
  handleAddingClose: PropTypes.func.isRequired,
  handleAddTransferType: PropTypes.func.isRequired,
};

export default NewQCTypeAction;
