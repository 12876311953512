/* eslint-disable no-unused-vars */
import axios from 'axios';
import { base64Decode, base64Encode } from '../Utils/stringUtil';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_PREFIX,
  timeout: 1000 * 60 * 3,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
const useAxios = async (api, data = {}, needJwt = false, token, customJwt) => {
  const cancelSource = axios.CancelToken.source();
  const cancelToken = token || cancelSource.token;
  const jwt = localStorage.getItem('jwttoken');
  if (needJwt) {
    // TODO: should be here
    // const jwt = localStorage.getItem('jwttoken');
    instance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  }
  if (customJwt) {
    instance.defaults.headers.common.Authorization = `Bearer ${customJwt}`;
  }
  console.log('request: ', {
    api,
    req: {
      needJwt,
      jwt,
      data,
    },
  });
  let dataObj;
  if (data) {
    dataObj = base64Encode(JSON.stringify(data));
  }
  try {
    const res = await instance.post(api, dataObj, { cancelToken });
    console.log('raw response: ', {
      status: res.status,
      data: res.data,
    });
    if (res.status !== 200) {
      return [false, 'Something went wrong...'];
    }
    const { rtCode, rtData, rtMsg } = res.data;

    // 沒有權限 error Msg
    if (rtCode === 'E45') {
      return [false, '', rtCode, rtMsg];
    }
    if (rtCode === 'E52') {
      const url = window.location.href;
      const pathname = url.split('#')[1];
      localStorage.clear();
      localStorage.setItem('targetPath', pathname);
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#/login`;
      window.location.reload();
      return [false, rtMsg, rtCode];
    }
    if (rtCode === 'E97') {
      return [false, rtMsg, rtCode];
    }

    // 自定義 error Msg
    if (rtCode === 'C01') {
      return [false, rtMsg, rtCode];
    }
    if (rtCode !== 'S00') {
      return [false, rtMsg, rtCode];
    }
    const result = rtData ? JSON.parse(base64Decode(rtData)) : null;
    console.log('parsed response: ', {
      rtCode,
      rtMsg,
      rtData: result,
    });
    if (needJwt && result) {
      const jwttoken = res.headers.authorization.substring(7, res.headers.authorization.length);
      localStorage.setItem('jwttoken', jwttoken);
    }
    return [true, result];
  } catch (err) {
    // 未經授權返回登入頁
    if (err.toString().includes('401')) {
      const url = window.location.href;
      const pathname = url.split('#')[1];
      localStorage.clear();
      localStorage.setItem('targetPath', pathname);
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#/login`;
      window.location.reload();
    }
    if (axios.isCancel(err)) {
      console.log(`${api} api call is cancelled`);
      return [true];
    }
    return [false, '網路連線異常'];
  }
};

export default useAxios;
