/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { calcTargetValue, isOutSpec } from '../../detailUtils';
import useStyles from '../useStyles';
import { measureStyleType } from '../../../../constants/enums';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';

const ElectricalDetail = ({ sinteringElectrical, electricalSpecConfig, isRStick }) => {
  const { NDetail, ZDetail, DBDetail, LDetail } = sinteringElectrical;
  const { NSpecConfig, ZSpecConfig, DBSpecConfig, LSpecConfig } = electricalSpecConfig;
  const [renderRow, setRenderRow] = useState([]);
  const [specConfig, setSpecConfig] = useState([]);
  const [targetMeasure, setTargetMeasure] = useState(0);
  const [targetMeasureText, setTargetMeasureText] = useState('');
  const [targetSubSpec, setTargetSubSpec] = useState('');
  const { classes } = useStyles();
  const NSpec = NSpecConfig && NSpecConfig.filter((spec) => spec.styleId === measureStyleType.N);
  const LSpec = LSpecConfig && LSpecConfig.filter((spec) => spec.styleId === measureStyleType.L);
  const ZSpec = ZSpecConfig && ZSpecConfig.filter((spec) => spec.styleId === measureStyleType.Z);
  const coilSpec = isRStick
    ? NSpecConfig.find((spec) => spec.styleId === measureStyleType.COIL)
    : ZSpecConfig.find((spec) => spec.styleId === measureStyleType.COIL);
  const starSpec = !isRStick
    ? ZSpecConfig.find((spec) => spec.styleId === measureStyleType.STAR)
    : '';
  const ampSpec = DBSpecConfig
    ? DBSpecConfig.filter((spec) => spec.styleId === measureStyleType.DB)
    : [];
  const ampSet = [];
  ampSpec.forEach((spec) => {
    if (ampSet.includes(spec.specRecipe)) return;
    ampSet.push(spec.specRecipe);
  });

  useEffect(() => {
    if (isRStick) {
      setTargetMeasure(measureStyleType.N);
      return;
    }
    setTargetMeasure(measureStyleType.Z);
  }, [sinteringElectrical, electricalSpecConfig]);

  useEffect(() => {
    if (targetMeasure === measureStyleType.N) {
      setTargetMeasureText('N');
      if (NDetail) {
        setRenderRow(NDetail);
      }
      if (NSpec) {
        setSpecConfig(NSpec);
      }
      return;
    }
    if (targetMeasure === measureStyleType.L) {
      setTargetMeasureText('L');
      if (LDetail) {
        setRenderRow(LDetail);
      }
      if (LSpec) {
        setSpecConfig(LSpec);
      }
      return;
    }
    if (targetMeasure === measureStyleType.DB) {
      setTargetMeasureText('DB');
      if (DBDetail) {
        setRenderRow(DBDetail);
      }
      if (ampSpec) {
        setSpecConfig(ampSpec);
      }
      return;
    }
    if (targetMeasure === measureStyleType.Z) {
      setTargetMeasureText('Z');
      if (ZDetail) {
        setRenderRow(ZDetail);
      }
      if (ZSpec) {
        setSpecConfig(ZSpec);
      }
    }
  }, [targetMeasure]);

  useEffect(() => {
    setTargetSubSpec(ampSet[0]);
  }, [ampSet[0]]);

  useEffect(() => {
    if (!targetSubSpec || targetMeasure !== measureStyleType.DB) return;
    setSpecConfig(ampSpec.filter((spec) => spec.specRecipe === targetSubSpec));
  }, [targetSubSpec, targetMeasure]);

  return (
    <>
      <Grid container className={`${classes.detail_header_row} ${classes.electrical_header_row}`}>
        <Typography variant="subtitle1" style={{ marginRight: 20 }}>
          量測型態：
        </Typography>
        {isRStick && (
          <>
            <Typography
              variant="subtitle1"
              className={`${classes.measure_btn} ${
                targetMeasure === measureStyleType.N ? 'active' : ''
              }`}
              onClick={() => setTargetMeasure(measureStyleType.N)}
            >
              N
            </Typography>
            <Typography
              variant="subtitle1"
              className={`${classes.measure_btn} ${
                targetMeasure === measureStyleType.L ? 'active' : ''
              }`}
              onClick={() => setTargetMeasure(measureStyleType.L)}
            >
              L
            </Typography>
            <Typography
              variant="subtitle1"
              className={`${classes.measure_btn} ${
                targetMeasure === measureStyleType.DB ? 'active' : ''
              }`}
              onClick={() => setTargetMeasure(measureStyleType.DB)}
            >
              DB
            </Typography>
          </>
        )}
        {!isRStick && LSpecConfig && LDetail && (
          <>
            <Typography
              variant="subtitle1"
              className={`${classes.measure_btn} ${
                targetMeasure === measureStyleType.Z ? 'active' : ''
              }`}
              onClick={() => setTargetMeasure(measureStyleType.Z)}
            >
              Z
            </Typography>
            <Typography
              variant="subtitle1"
              className={`${classes.measure_btn} ${
                targetMeasure === measureStyleType.L ? 'active' : ''
              }`}
              onClick={() => setTargetMeasure(measureStyleType.L)}
            >
              L
            </Typography>
          </>
        )}
        {!isRStick && !LSpecConfig && !LDetail && (
          <Typography variant="subtitle1" className={classes.measure_style}>
            Z
          </Typography>
        )}
      </Grid>
      <Grid container className={`${classes.detail_header_row} ${classes.electrical_header_row}`}>
        {targetMeasure === measureStyleType.N && (
          <Typography variant="subtitle1" className={classes.measure_settings}>
            <b>N設定： </b> {NSpec ? NSpec.specRecipe : '-'}
            <b style={{ marginLeft: 24 }}>線圈設定： </b> {coilSpec ? coilSpec.specRecipe : '-'}
          </Typography>
        )}
        {targetMeasure === measureStyleType.L && (
          <Typography variant="subtitle1" className={classes.measure_settings}>
            <b>L設定： </b> {LSpec ? LSpec.specRecipe : '-'}
            <b style={{ marginLeft: 24 }}>線圈設定： </b> {coilSpec ? coilSpec.specRecipe : '-'}
          </Typography>
        )}
        {targetMeasure === measureStyleType.DB && (
          <>
            <Typography variant="subtitle1" className={classes.measure_settings}>
              <b>線圈設定： </b> {coilSpec ? coilSpec.specRecipe : '-'}
            </Typography>
            <Typography variant="subtitle1" className={classes.measure_subspec}>
              <b>Amp: </b>{' '}
              {ampSet.map((amp) => (
                <i
                  aria-hidden
                  className={`${targetSubSpec === amp ? 'active' : ''}`}
                  onClick={() => setTargetSubSpec(amp)}
                >
                  {amp}
                </i>
              ))}
            </Typography>
          </>
        )}
        {targetMeasure === measureStyleType.Z && (
          <Typography variant="subtitle1" className={classes.measure_settings}>
            <b>星數： </b> {starSpec ? starSpec.specRecipe : '-'}
            <b style={{ marginLeft: 24 }}>線圈設定： </b> {coilSpec ? coilSpec.specRecipe : '-'}
          </Typography>
        )}
      </Grid>
      <Grid container className={classes.detail_header_row}>
        {specConfig.length > 0 &&
          specConfig.map((spec) => (
            <Grid
              item
              className={`${classes.detail_header_column} ${classes.electrical_column}`}
              key={spec.specId}
            >
              <Typography variant="subtitle1" className={classes.electrical_measure}>
                <b>{spec.specItem}</b> <i />
                {spec.styleId !== 4 && spec.specLower
                  ? spec.specUpper
                    ? `${spec.specLower}`
                    : `${spec.specLower}↑ `
                  : ''}
                {spec.styleId !== 4 && spec.specUpper ? ` ~ ${spec.specUpper}` : ''}
                {spec.styleId !== 4 && spec.specTarget ? ` / ${spec.specTarget}` : ''}
                {spec.styleId === 4 &&
                  `${(parseFloat(spec.specTarget) - parseFloat(spec.specLower)).toFixed(2)} ~ ${
                    // eslint-disable-next-line prettier/prettier
                    (parseFloat(spec.specTarget) + parseFloat(spec.specUpper)).toFixed(2)
                  }`}
              </Typography>
              {spec.specBias && (
                <Typography variant="subtitle2" className={classes.electrical_bias}>
                  量測偏差： {spec.specBias}
                </Typography>
              )}
            </Grid>
          ))}
      </Grid>
      {renderRow.length > 0 &&
        renderRow.map((row) => (
          <Grid container className={classes.detail_row} key={row.detailId}>
            {row.specItems &&
              row.specItems.map((spec) => {
                if (targetMeasure === measureStyleType.DB && spec.specRecipe !== targetSubSpec)
                  return null;
                const targetSpec = specConfig.find((item) => item.specId === spec.specId);
                const [maxValue, minValue, upperLimit, lowerLimit] = calcTargetValue(targetSpec);
                const [outSpec, outLimit] = isOutSpec(
                  spec.specValue,
                  maxValue,
                  minValue,
                  upperLimit,
                  lowerLimit
                );
                return (
                  <Grid
                    item
                    className={`${classes.detail_column} ${classes.electrical_cell} ${
                      outSpec ? classes.out_spec : ''
                    } ${outLimit ? classes.out_limit : ''}`}
                    key={spec.specId}
                  >
                    <Typography variant="body1">{spec.specValue || '-'}</Typography>
                  </Grid>
                );
              })}
          </Grid>
        ))}
      {renderRow.length === 0 && (
        <div style={{ marginTop: 50, textAlign: 'center' }}>
          <Typography variant="subtitle1">{`目前沒有量測型態 ${targetMeasureText} 的相關量測紀錄`}</Typography>
        </div>
      )}
    </>
  );
};

ElectricalDetail.propTypes = {
  sinteringElectrical: PropTypes.object.isRequired,
  electricalSpecConfig: PropTypes.object.isRequired,
  isRStick: PropTypes.bool.isRequired,
};

export default ElectricalDetail;
