/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef } from 'react';
import { compareDesc, format, addMonths, isWithinInterval } from 'date-fns';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import useCommonStyles from '../../useStyles';
import useStyles from './useStyles';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Button from '../../../../components/Button/Button';
import useForm from '../../../../hooks/useForm';
import SelectField from '../../../../components/SelectField/SelectField';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import Dialog from '../../../../components/Dialog/Dialog';
import DialogTitle from '../../../../components/DialogTitle/DialogTitle';
import DialogContent from '../../../../components/DialogContent/DialogContent';
import DialogContentText from '../../../../components/DialogContentText/DialogContentText';
import DialogActions from '../../../../components/DialogActions/DialogActions';
import useCancelToken from '../../../../hooks/useCancelToken';
import useAxios from '../../../../hooks/useAxios';
import ChartDialog from './ChartDialog';
import { DialogContext } from '../../../../contexts/DialogContext';
import { dialogActions } from '../../../../reducers/dialogReducer';
import SPCListDataRow from './SPCListDataRow';

const SPCCharts = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const initialFilterField = {
    startDate: new Date(),
    endDate: new Date(),
    scOdNo: '0',
    customer: '0',
  };
  const {
    formData: filterField,
    setFormData: setFilterField,
    formErr: filterFieldErr,
    setFormErr: setFilterFieldErr,
    resetFormData: resetFilterField,
    resetFormErr: resetFilterFieldErr,
  } = useForm(initialFilterField);
  const [dateErrMsg, setDateErrMsg] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [scOdList, setScOdList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [spcChartList, setSpcChartList] = useState([]);
  const [openChart, setOpenChart] = useState(false);
  const [openChartAlert, setOpenChartAlert] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const spcChartData = useRef([]);
  const spcChartConfig = useRef([]);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();
  const hasDateErr = filterFieldErr.startDate || filterFieldErr.endDate;

  const isWithinOneYear = (start, end) => {
    const maxEndDate = addMonths(start, 12);
    return isWithinInterval(end, { start, end: maxEndDate });
  };

  const validationDate = () => {
    const { startDate, endDate } = filterField;
    if (compareDesc(startDate, endDate) < 0) {
      setFilterFieldErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      setDateErrMsg('結束日期不得早於起始日期！');
      return false;
    }
    if (!isWithinOneYear(startDate, endDate)) {
      setFilterFieldErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      setDateErrMsg('所選區間不得超過一年！');
      return false;
    }
    return true;
  };

  const handleFilterFieldChange = (e) => {
    setFilterField((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleStartDateChange = (date) => {
    setFilterField((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFilterField((prev) => ({
      ...prev,
      endDate: date,
    }));
  };

  const handleFetch = async () => {
    resetFilterFieldErr();
    if (!validationDate()) return;
    const { startDate, endDate, scOdNo, customer } = filterField;
    const data = {
      checkBegin: format(new Date(startDate), 'yyyy-MM-dd'),
      checkEnd: format(new Date(endDate), 'yyyy-MM-dd'),
    };
    if (scOdNo !== '0') {
      data.scOdno = scOdNo;
    }
    if (customer !== '0') {
      data.customerAlias = customer;
    }
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/spcList',
      data,
      true,
      cancelToken
    );
    if (status) {
      if (!result) return;
      const { progress } = result;
      setSpcChartList(
        progress.map((item, idx) => ({
          id: idx + 1,
          measureId: item.measureId,
          material: item.materialName,
          productName: item.prodName,
          firstProdDate: item.minDate,
          lastProdDate: item.maxDate,
          specItem: item.measureItem,
          measureFrequency: item.checkCount,
          ca: item.caData,
          cp: item.cpData,
          cpk: item.cpkData,
          grade: item.gradeData,
          sampleQty: item.sampleCount,
          UCLAvg: item.uclAvg,
          LCLAvg: item.lclAvg,
          UCLSd: item.uclSd,
          LCLSd: item.lclSd,
        }))
      );
      setShowResult(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得查詢結果',
          message:
            noPrivilegeMsg || `${result}：目前無法取得查詢結果，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleFetchFilterData = async () => {
    resetFilterFieldErr();
    if (!validationDate()) return;
    const cancelToken = newCancelToken();
    const { startDate, endDate } = filterField;
    const data = {
      checkBegin: format(new Date(startDate), 'yyyy-MM-dd'),
      checkEnd: format(new Date(endDate), 'yyyy-MM-dd'),
    };
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/spcTitleList',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { work, customer } = result;
      if (work.length === 0 && customer.length === 0) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '無法取得進階選項',
            message:
              noPrivilegeMsg ||
              '您所選的時間區間沒有相關的進階選項，請嘗試其他時間區間，或請通知網管人員！',
            singleBtn: true,
          },
        });
        return;
      }
      setCustomerList(
        customer.map((item) => ({
          name: item,
          value: item,
        }))
      );
      setScOdList(
        work.map((item) => ({
          name: item,
          value: item,
        }))
      );
      setShowAdvanced(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得進階選項',
          message: `${result}：目前暫時無法取得進階選項資訊，請稍後再試，或請通知網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleCancelAdvanced = () => {
    setShowAdvanced(false);
    setFilterField((prev) => ({
      ...prev,
      scOdNo: '0',
      customer: '0',
    }));
  };

  const handleChartClose = () => {
    setOpenChart(false);
  };

  const handleRowClick = async (measureId, row) => {
    setCurrentRow({ ...row });
    const { startDate, endDate } = filterField;
    const data = {
      measureId,
      checkBegin: format(new Date(startDate), 'yyyy-MM-dd'),
      checkEnd: format(new Date(endDate), 'yyyy-MM-dd'),
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/spcLineChart',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { progress, config } = result;
      spcChartData.current = progress.filter((item) => item.modifyDate);
      spcChartConfig.current = config;
      if (progress && progress.length > 0) {
        setOpenChart(true);
      } else {
        setOpenChartAlert(true);
      }
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得圖表資訊',
          message:
            noPrivilegeMsg || `${result}：目前無法取得圖表資訊，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  return (
    <div className={commonClasses.container}>
      <PaperContainer className={`${commonClasses.container} ${commonClasses.filter_container}`}>
        <Typography variant="h6">查詢燒結尺寸資料</Typography>
        <Grid container className={commonClasses.content} direction="column">
          <Grid container item xs={12} className={classes.date_group}>
            <Grid item xs={3} className={classes.filter_date_grid}>
              <DatePicker
                id="startDate"
                label="查詢起始日期"
                value={filterField.startDate}
                onChange={handleStartDateChange}
                hasDateErr={hasDateErr}
                errMsg={dateErrMsg}
                maxDate={new Date()}
                containerClass={classes.filter_date_picker}
              />
            </Grid>
            <Typography
              variant="h6"
              style={{ marginRight: 24 }}
              className={hasDateErr ? classes.error : ''}
            >
              ~
            </Typography>
            <Grid item xs={3} className={classes.filter_date_grid}>
              <DatePicker
                id="endDate"
                label="查詢結束日期"
                value={filterField.endDate}
                onChange={handleEndDateChange}
                hasDateErr={hasDateErr}
                errMsg={dateErrMsg}
                maxDate={new Date()}
                containerClass={classes.filter_date_picker}
              />
            </Grid>
            {!showAdvanced && (
              <Grid
                item
                xs={6}
                container
                alignItems="flex-end"
                className={`${classes.filter_btn_container} ${hasDateErr ? classes.error : ''}`}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  customClasses={classes.filter_btn}
                  startIcon={<SearchRoundedIcon />}
                  onClick={handleFetch}
                >
                  查詢
                </Button>
                <Button
                  variant="outlined"
                  color="grey"
                  customClasses={classes.filter_btn}
                  startIcon={<SearchRoundedIcon />}
                  onClick={handleFetchFilterData}
                  style={{ marginLeft: 16 }}
                >
                  顯示進階選項
                </Button>
              </Grid>
            )}
          </Grid>
          {showAdvanced && (
            <Grid container item xs={12} className={classes.date_group} alignItems="flex-end">
              <Grid item xs={3} className={classes.filter_field_grid}>
                <SelectField
                  id="scOdNo"
                  label="工令單號"
                  value={filterField.scOdNo}
                  placeholder="請選擇工令單號"
                  handleChange={handleFilterFieldChange}
                  selections={scOdList}
                />
              </Grid>
              <Typography variant="h6" style={{ marginRight: 36 }} />
              <Grid item xs={3} className={classes.filter_field_grid}>
                <SelectField
                  id="customer"
                  label="客戶簡稱"
                  value={filterField.customer}
                  placeholder="請選擇客戶簡稱"
                  handleChange={handleFilterFieldChange}
                  selections={customerList}
                />
              </Grid>
              <Grid
                item
                xs={6}
                container
                alignItems="flex-end"
                className={classes.filter_btn_container}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  customClasses={classes.filter_btn}
                  startIcon={<SearchRoundedIcon />}
                  onClick={handleFetch}
                >
                  查詢
                </Button>
                <Button
                  variant="outlined"
                  color="grey"
                  customClasses={classes.filter_btn}
                  startIcon={<SearchRoundedIcon />}
                  onClick={handleCancelAdvanced}
                  style={{ marginLeft: 16 }}
                >
                  取消進階篩選
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </PaperContainer>
      {showResult && (
        <PaperContainer className={commonClasses.container}>
          <Typography variant="h6">查詢結果</Typography>
          {spcChartList.length === 0 && (
            <Typography
              variant="subtitle1"
              style={{ margin: '30px auto 24px', textAlign: 'center' }}
            >
              所選區間沒有資料，請重新選擇查詢區間與篩選條件
            </Typography>
          )}
          {spcChartList.length > 0 && (
            <Grid container className={`${commonClasses.content} ${classes.table}`}>
              <div className={classes.table_header}>
                <div className={classes.table_column}>
                  <Typography variant="subtitle1">材質</Typography>
                </div>
                <div className={classes.table_column}>
                  <Typography variant="subtitle1">形狀 + 規格</Typography>
                </div>
                <div className={classes.table_column}>
                  <Typography variant="subtitle1">第一批生產日</Typography>
                </div>
                <div className={classes.table_column}>
                  <Typography variant="subtitle1">最後一批生產日</Typography>
                </div>
                <div className={classes.table_column}>
                  <Typography variant="subtitle1">項目</Typography>
                </div>
                <div className={classes.table_column}>
                  <Typography variant="subtitle1">量測次數</Typography>
                </div>
                <div className={classes.table_column}>
                  <Typography variant="subtitle1">Ca</Typography>
                </div>
                <div className={classes.table_column}>
                  <Typography variant="subtitle1">Cp</Typography>
                </div>
                <div className={classes.table_column}>
                  <Typography variant="subtitle1">Cpk</Typography>
                </div>
                <div className={classes.table_column}>
                  <Typography variant="subtitle1">Grade</Typography>
                </div>
                <div className={`${classes.table_column} ${classes.table_header_action}`}>
                  <Typography variant="subtitle1">View</Typography>
                </div>
              </div>
              {spcChartList.map((row) => (
                <SPCListDataRow rowData={row} key={row.id} handleRowClick={handleRowClick} />
              ))}
            </Grid>
          )}
        </PaperContainer>
      )}
      {spcChartData.current.length > 0 && (
        <ChartDialog
          open={openChart}
          handleChartClose={handleChartClose}
          currentRow={currentRow}
          spcChartData={spcChartData.current}
          spcChartConfig={spcChartConfig.current}
        />
      )}
      {spcChartData.current.length === 0 && (
        <Dialog open={openChartAlert} onClose={() => setOpenChartAlert(false)}>
          <DialogTitle>SPC Chart 繪製結果</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: '2rem' }}>
              此筆結果無法取得完整圖表資訊！請聯絡網管人員，或請稍後再試！
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{ paddingBottom: '1rem', paddingRight: '1rem' }}
            onClick={() => setOpenChartAlert(false)}
          >
            關閉
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default SPCCharts;
