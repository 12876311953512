import React, { useState, useContext } from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { addMonths, compareDesc, format, isWithinInterval } from 'date-fns';
import useStyles from './useStyles';
import useAxios from '../../hooks/useAxios';
import useForm from '../../hooks/useForm';
import useCancelToken from '../../hooks/useCancelToken';
import { stationSelection } from '../../constants/configSelections';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';
import PageTitle from '../../components/PageTitle/PageTitle';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import DatePicker from '../../components/DatePicker/DatePicker';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import Checkbox from '../../components/Checkbox/Checkbox';
import InputField from '../../components/InputField/InputField';
import SelectField from '../../components/SelectField/SelectField';
import Button from '../../components/Button/Button';
import Divider from '../../components/Divider/Divider';
import SelectAutoMultiField from '../../components/SelectField/SelectAutoMultiField';
import TransTable from './TransTable';
import useFormingType from '../../hooks/useFormingType';

const defaultDateErrMsg = '所選日期區間不得大於一個月，且結束日期不得早於起始日期';

const TransExport = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const initialQueryInfo = {
    createBegin: new Date(),
    createEnd: new Date(),
    updateBegin: new Date(),
    updateEnd: new Date(),
    stationType: '0',
    scOdNo: '',
    elName: [],
  };
  const {
    formData: queryInfo,
    setFormData: setQueryInfo,
    formErr: queryErr,
    setFormErr: setQueryErr,
    resetFormErr: resetQueryErr,
  } = useForm(initialQueryInfo);
  const [queryResult, setQueryResult] = useState([]);
  const [queryByCreateDate, setQueryByCreateDate] = useState(true);
  const [queryByUpdateDate, setQueryByUpdateDate] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [productSelection, setProductSelection] = useState([]);
  const [dateErrMsg, setDateErrMsg] = useState(defaultDateErrMsg);
  const { classes } = useStyles();
  const { newCancelToken } = useCancelToken();
  const hasDateErr =
    queryErr.createBegin || queryErr.createEnd || queryErr.updateBegin || queryErr.updateEnd
      ? queryErr.createBegin || queryErr.createEnd || queryErr.updateBegin || queryErr.updateEnd
      : false;

  const handleAdvancedDate = () => {
    if (showAdvanced) {
      setShowAdvanced(false);
    }
  };
  const handleCreateBeginChange = (date) => {
    handleAdvancedDate();
    setQueryInfo((prev) => ({
      ...prev,
      createBegin: date,
    }));
  };

  const handleCreateEndChange = (date) => {
    handleAdvancedDate();
    setQueryInfo((prev) => ({
      ...prev,
      createEnd: date,
    }));
  };
  const handleUpdateBeginChange = (date) => {
    handleAdvancedDate();
    setQueryInfo((prev) => ({
      ...prev,
      updateBegin: date,
    }));
  };

  const handleUpdateEndChange = (date) => {
    handleAdvancedDate();
    setQueryInfo((prev) => ({
      ...prev,
      updateEnd: date,
    }));
  };

  const handleScOdNoChange = (e) => {
    setQueryInfo((prev) => ({
      ...prev,
      scOdNo: e.target.value,
    }));
  };

  const handleStationTypeChange = (e) => {
    setQueryInfo((prev) => ({
      ...prev,
      stationType: e.target.value,
    }));
  };

  const queryInfoProcess = ({ isExcel, type }) => {
    const { createBegin, createEnd, updateBegin, updateEnd, scOdNo, stationType, elName } =
      queryInfo;

    if (type === 'GET_ADVANCED') {
      return {
        createBegin: queryByCreateDate ? format(createBegin, 'yyyy-MM-dd') : '',
        createEnd: queryByCreateDate ? format(createEnd, 'yyyy-MM-dd') : '',
        updateBegin: queryByUpdateDate ? format(updateBegin, 'yyyy-MM-dd') : '',
        updateEnd: queryByUpdateDate ? format(updateEnd, 'yyyy-MM-dd') : '',
      };
    }
    const data = {
      stationType,
      scOdno: scOdNo,
      createBegin: queryByCreateDate ? format(createBegin, 'yyyy-MM-dd') : '',
      createEnd: queryByCreateDate ? format(createEnd, 'yyyy-MM-dd') : '',
      updateBegin: queryByUpdateDate ? format(updateBegin, 'yyyy-MM-dd') : '',
      updateEnd: queryByUpdateDate ? format(updateEnd, 'yyyy-MM-dd') : '',
      isExcel: isExcel ? 1 : 0,
    };

    if (elName.length > 0) {
      data.prodName = elName.map((item) => item.name).toString();
    }

    return data;
  };

  const isWithinOneMonth = (start, end) => {
    const maxEndDate = addMonths(start, 1);
    return isWithinInterval(end, { start, end: maxEndDate });
  };

  const validationQuery = (type = 'DEFAULT') => {
    const { createBegin, createEnd, updateBegin, updateEnd, scOdNo } = queryInfo;
    resetQueryErr();
    setDateErrMsg(defaultDateErrMsg);
    if (type === 'DEFAULT') {
      if (scOdNo.length > 0) {
        return true;
      }
      if (!queryByCreateDate && !queryByUpdateDate) {
        setQueryErr((prev) => ({
          ...prev,
          scOdNo: true,
        }));
        return false;
      }
    }
    if (type === 'ADVANCED' && !queryByCreateDate && !queryByUpdateDate) {
      setQueryErr((prev) => ({
        ...prev,
        createBegin: true,
        createEnd: true,
        updateBegin: true,
        updateEnd: true,
      }));
      setDateErrMsg('尚未選取所選區間');
      return false;
    }
    if (queryByCreateDate) {
      if (compareDesc(createBegin, createEnd) < 0 || !isWithinOneMonth(createBegin, createEnd)) {
        setQueryErr((prev) => ({
          ...prev,
          createBegin: true,
          createEnd: true,
        }));
        return false;
      }
    }
    if (queryByUpdateDate) {
      if (compareDesc(updateBegin, updateEnd) < 0 || !isWithinOneMonth(updateBegin, updateEnd)) {
        setQueryErr((prev) => ({
          ...prev,
          updateBegin: true,
          updateEnd: true,
        }));
        return false;
      }
    }
    return true;
  };

  const getProducts = async () => {
    const cancelToken = newCancelToken();
    const data = queryInfoProcess({ type: 'GET_ADVANCED' });
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/transExportAdvanced',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { prodNameList } = result;
      setProductSelection(
        prodNameList.map((item, index) => ({
          name: item.prodName,
          value: index + 1,
        }))
      );
      if (prodNameList.length <= 0) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '找不到資料',
            message: '您所選的篩選條件與日期區間不到產品類別，請嘗試其他篩選條件！',
            singleBtn: true,
          },
        });
        return;
      }
      setShowAdvanced(true);
    } else {
      setProductSelection([]);
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '找不到資料',
          message: noPrivilegeMsg || '您所選的篩選條件與日期區間找不到資料，請嘗試其他篩選條件！',
          singleBtn: true,
        },
      });
    }
  };

  const handleShowAdvancedFilter = (show) => {
    if (show) {
      if (!validationQuery('ADVANCED')) return;
      getProducts();
      return;
    }
    setShowAdvanced(false);
    setQueryInfo((prev) => ({ ...prev, elName: [] }));
  };

  const handleElNameChange = (data = []) => {
    const targetElNameValue = productSelection.filter((product) =>
      data.toString().includes(product.name)
    );
    setQueryInfo((prev) => ({ ...prev, elName: targetElNameValue }));
  };

  const handleFetch = async () => {
    resetQueryErr();
    const data = queryInfoProcess({ isExcel: false });
    if (!validationQuery()) return;
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/transExport',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { transfer } = result;
      const sortTransfer = transfer
        .sort((a, b) => Number(a.modifyDate) - Number(b.modifyDate))
        .sort((c, d) => c.scOdno.slice(1) - d.scOdno.slice(1));
      const sortStation = [
        ...sortTransfer.filter((station) => station.stationName === '壓型'),
        ...sortTransfer.filter((station) => station.stationName === '鑽孔'),
        ...sortTransfer.filter((station) => station.stationName === '排列'),
        ...sortTransfer.filter((station) => station.stationName === '燒結'),
        ...sortTransfer.filter((station) => station.stationName === '研磨'),
        ...sortTransfer.filter((station) => station.stationName === '全檢'),
        ...sortTransfer.filter((station) => station.stationName === '包裝'),
      ];
      setQueryResult(
        sortStation.map((item) => ({
          transId: item.transId,
          stationType: item.stationName,
          // 會回傳日期＆時間，但只需要日期而已，所以刪掉後面多餘的時間
          updateDate: item.modifyDate ? item.modifyDate.split('T')[0] : '',
          scOdNo: item.scOdno,
          transQty: item.transQuantity,
          updateUser: item.modifyUser,
          elNO: item.elNo,
          elName: item.elName1Size,
          defectSize: item.defectSize,
          manufactureType: useFormingType(item.manufactureType).name,
        }))
      );
    } else {
      setQueryResult([]);
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '找不到資料',
          message: noPrivilegeMsg || '您所選的篩選條件與日期區間找不到資料，請嘗試其他篩選條件！',
          singleBtn: true,
        },
      });
    }
  };

  const handleAdvancedClear = () => {
    setQueryInfo((prev) => ({ ...prev, elName: [] }));
  };

  return (
    <Grid container>
      <PageTitle title="匯出移轉單" />
      <PaperContainer className={classes.container}>
        <Typography variant="h6">查詢移轉單</Typography>
        <Grid container className={classes.content}>
          <Grid item xs={5} container mr={6.25}>
            <Typography variant="body1" className={classes.date_heading}>
              移轉單建立日期
            </Typography>
            <Grid item xs={12} className={classes.date_group}>
              <DatePicker
                id="createStart"
                label="建立日期查詢起始"
                value={queryInfo.createBegin}
                onChange={handleCreateBeginChange}
                hasDateErr={hasDateErr}
                errMsg={dateErrMsg}
                maxDate={new Date()}
                containerClass={classes.date_picker}
              />
              <Typography variant="h6">~</Typography>
              <DatePicker
                id="createEnd"
                label="建立日期查詢結束"
                value={queryInfo.createEnd}
                onChange={handleCreateEndChange}
                hasDateErr={hasDateErr}
                errMsg={dateErrMsg}
                maxDate={new Date()}
                containerClass={classes.date_picker}
              />
            </Grid>
            <Checkbox
              name="queryByCreateDate"
              label="使用移轉單建立日期查詢"
              checked={queryByCreateDate}
              handleChange={(e) => {
                setQueryByCreateDate(e.target.checked);
                handleAdvancedDate();
              }}
              reverse
              customClasses={classes.date_checkbox}
            />
          </Grid>
          <Grid item xs={5} container>
            <Typography variant="body1" className={classes.date_heading}>
              移轉單更新日期
            </Typography>
            <Grid item xs={12} className={classes.date_group}>
              <DatePicker
                id="updateStart"
                label="更新日期查詢起始"
                value={queryInfo.updateBegin}
                onChange={handleUpdateBeginChange}
                hasDateErr={hasDateErr}
                errMsg={dateErrMsg}
                maxDate={new Date()}
                containerClass={classes.date_picker}
              />
              <Typography variant="h6">~</Typography>
              <DatePicker
                id="updateEnd"
                label="更新日期查詢結束"
                value={queryInfo.updateEnd}
                onChange={handleUpdateEndChange}
                hasDateErr={hasDateErr}
                errMsg={dateErrMsg}
                maxDate={new Date()}
                containerClass={classes.date_picker}
              />
            </Grid>
            <Checkbox
              name="queryByUpdateDate"
              label="使用移轉單更新日期查詢"
              checked={queryByUpdateDate}
              handleChange={(e) => {
                setQueryByUpdateDate(e.target.checked);
                handleAdvancedDate();
              }}
              reverse
              customClasses={classes.date_checkbox}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={`${classes.content} ${!showAdvanced ? classes.query_btn_container : ''}`}
          alignItems={queryErr.scOdNo ? 'flex-start' : 'center'}
        >
          <Grid item xs={3} mr={3.75}>
            <SelectField
              id="stationType"
              label="製程站別"
              value={queryInfo.stationType}
              placeholder="請選擇篩選站別"
              selections={stationSelection}
              handleChange={handleStationTypeChange}
            />
          </Grid>
          <Grid item xs={3}>
            <InputField
              id="scOdNo"
              name="scOdNo"
              type="text"
              label="工令單號"
              value={queryInfo.scOdNo}
              onChange={handleScOdNoChange}
              placeholder="請輸入篩選的工令單號"
              error={queryErr.scOdNo}
              errormsg="若不以移轉單之建立日期或更新日期作為篩選條件，則工令單號為必填！"
            />
          </Grid>
          {!showAdvanced && (
            <>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<SearchRoundedIcon />}
                onClick={handleFetch}
                style={{ marginLeft: 24, alignSelf: 'flex-start', marginTop: 18 }}
              >
                查詢
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ImageSearchRoundedIcon />}
                onClick={() => handleShowAdvancedFilter(true)}
                style={{ marginLeft: 24, alignSelf: 'flex-start', marginTop: 18 }}
              >
                進階查詢
              </Button>
            </>
          )}
          {showAdvanced && (
            <>
              <Grid container item xs={12} direction="column">
                <Divider style={{ marginTop: 24 }} className={classes.advanced_filter} />
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="space-between"
                  className={classes.advanced_filter}
                >
                  <Typography variant="subtitle1">進階查詢條件</Typography>
                  <Button
                    variant="outlined"
                    color="grey"
                    rounded
                    startIcon={<ExpandLessRoundedIcon />}
                    onClick={() => handleShowAdvancedFilter(false)}
                  >
                    取消進階查詢
                  </Button>
                </Grid>
                <Grid item className={classes.advanced_filter_row}>
                  <SelectAutoMultiField
                    id="elName"
                    label="產品名稱"
                    placeholder={queryInfo.elName.length > 0 ? '' : '請選擇產品名稱'}
                    selections={productSelection}
                    handleChange={handleElNameChange}
                    selectedValue={queryInfo.elName}
                    isNormal
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${classes.advanced_filter_btn}`}
                  container
                  alignItems="flex-end"
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<SearchRoundedIcon />}
                    onClick={handleFetch}
                  >
                    查詢
                  </Button>
                  <Button
                    variant="outlined"
                    color="grey"
                    startIcon={<ClearRoundedIcon />}
                    onClick={handleAdvancedClear}
                    style={{ marginLeft: 16 }}
                  >
                    重設
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </PaperContainer>
      <TransTable queryResult={queryResult} queryInfoProcess={queryInfoProcess} />
    </Grid>
  );
};

export default TransExport;
