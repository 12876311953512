import React, { useState } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import SettingTargetSelection from './SettingsConfigTargetSelection';
import SettingsConfigMachineType from './MachineType/SettingsConfigMachineType';
import SettingsConfigTransferDetectType from './TransferDetectType/SettingsConfigTransferDetectType';
import SettingsConfigCustomerType from './CustomerType/SettingsConfigCustomerType';
import SettingsConfigQCDetectType from './QCDetectType/SettingsConfigQCDetectType';
import SettingsConfigModulerType from './ModulerType/SettingsConfigModulerType';
import SettingsConfigFormingUserType from './FormingUserType/SettingsConfigFormingUserType';
import SettingsConfigTimerReasonType from './TimerReasonType/SettingsConfigTimerReasonType';
import { configType } from '../../constants/enums';
import SettingsConfigProductType from './ProductType/SettingsConfigProductType';

const SettingsConfig = () => {
  const [configTargetName, setConfigTargetName] = useState('');

  const handleConfigTargetName = (name) => {
    setConfigTargetName(name);
  };

  return (
    <div className="setting-config">
      <PageTitle title="管理設定項目" />
      <SettingTargetSelection setConfigTarget={handleConfigTargetName} />
      {configTargetName === configType.MACHINE_TYPE && <SettingsConfigMachineType />}
      {configTargetName === configType.MODULER_USER && <SettingsConfigModulerType />}
      {configTargetName === configType.FORMING_USER && <SettingsConfigFormingUserType />}
      {configTargetName === configType.PRODUCT_TYPE && <SettingsConfigProductType />}
      {configTargetName === configType.CUSTOMER_TYPE && <SettingsConfigCustomerType />}
      {configTargetName === configType.TRANSFER_DETECT_TYPE && <SettingsConfigTransferDetectType />}
      {configTargetName === configType.QC_DETECT_TYPE && <SettingsConfigQCDetectType />}
      {configTargetName === configType.TIMER_REASON_TYPE && <SettingsConfigTimerReasonType />}
    </div>
  );
}; // SettingsConfig

export default SettingsConfig;
