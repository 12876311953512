import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CreateIcon from '@mui/icons-material/Create';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useStyles from '../useStyles';

const ProductTypeTableBtnSets = ({
  isAddingAll,
  isAdding,
  isEditing,
  isDeleteBtnShow,
  handleAdd,
  handleEdit,
  handleDelete,
  isAddBtnShow,
  isEditBtnShow,
  isStyleStar,
  isDefaultRow,
  isLEmpty,
}) => {
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);

  const handleAdding = () => {
    if (isAddingAll) {
      handleAdd();
      return;
    }
    if (isEditing) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '新增尚未完成',
          message: '您尚未完成新增，請先儲存您的新增，或點選「取消」放棄新增！',
          singleBtn: true,
        },
      });
      return;
    }
    if (isAdding) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '新增尚未完成',
          message: '您尚未完成新增，請先儲存您的新增，或點選「取消」放棄新增！',
          singleBtn: true,
        },
      });
      return;
    }
    handleAdd();
  };

  const handleEditing = () => {
    if (isAdding) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '編輯尚未完成',
          message: '您尚未完成編輯，請先儲存您的編輯，或點選「取消」放棄編輯！',
          singleBtn: true,
        },
      });
      return;
    }
    handleEdit();
  };

  const handleDeleteing = () => {
    if (isEditing) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '編輯尚未完成',
          message: '您尚未完成編輯，請先儲存您的編輯，或點選「取消」放棄編輯！',
          singleBtn: true,
        },
      });
      return;
    }
    if (isAdding) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '新增尚未完成',
          message: '您尚未完成新增，請先儲存您的新增，或點選「取消」放棄新增！',
          singleBtn: true,
        },
      });
      return;
    }
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認刪除？',
        message: '是否確認刪除此筆資料？',
        handleConfirm: handleDelete,
      },
    });
  };
  return (
    <div>
      {/* add */}
      {isDefaultRow && !isDeleteBtnShow && isAddBtnShow && !isStyleStar && (
        <IconButton
          className={classes.iconBtnAdd}
          aria-label="modify"
          onClick={() => handleAdding()}
        >
          <AddIcon className={classes.iconBtnIcon} />
        </IconButton>
      )}
      {/* add */}
      {isEditBtnShow && isAddBtnShow && !isStyleStar && !isLEmpty && (
        <IconButton
          className={classes.iconBtnAdd}
          aria-label="modify"
          onClick={() => handleAdding()}
        >
          <AddIcon className={classes.iconBtnIcon} />
        </IconButton>
      )}
      {/* delete */}
      {!isDefaultRow && isAddBtnShow && !isStyleStar && (
        <>
          <IconButton
            className={classes.iconBtnDelete}
            aria-label="modify"
            onClick={() => handleDeleteing()}
          >
            <DeleteOutlinedIcon className={classes.iconBtnIcon} />
          </IconButton>
        </>
      )}
      {/* edit */}
      {isEditBtnShow && (
        <IconButton
          className={classes.iconBtnModify}
          aria-label="modify"
          onClick={() => handleEditing()}
        >
          <CreateIcon className={classes.iconBtnIcon} />
        </IconButton>
      )}
    </div>
  );
};

ProductTypeTableBtnSets.defaultProps = {
  isEditBtnShow: true,
  isDeleteBtnShow: true,
  isAddBtnShow: false,
  isStyleStar: false,
};

ProductTypeTableBtnSets.propTypes = {
  isAddingAll: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isDeleteBtnShow: PropTypes.bool,
  handleAdd: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isAddBtnShow: PropTypes.bool,
  isEditBtnShow: PropTypes.bool,
  isStyleStar: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  isDefaultRow: PropTypes.bool,
  isLEmpty: PropTypes.bool.isRequired,
};

export default ProductTypeTableBtnSets;
