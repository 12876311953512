import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  config_content: {
    marginTop: theme.spacing(0.5),
  },
  config_column: {
    marginRight: theme.spacing(2.5),
  },
  scroll_config_column: {
    marginRight: theme.spacing(3),
    padding: '1rem 0',
  },
  button_group: {
    display: 'flex',
    marginTop: 'auto',
    justifyContent: 'left',
  },
  button_edit: {
    marginLeft: '0.6rem',
  },
  dialog_selection: {
    width: 450,
    margin: `${theme.spacing(2)} 0`,
  },
  edit_btn: {
    backgroundColor: `${alpha(theme.palette.common.black, 0.1)} !important`,
    color: `${alpha(theme.palette.common.black, 0.6)} !important`,
  },
  config_btn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  error: {
    marginBottom: theme.spacing(2.5),
  },
  divider: {
    height: 2,
    width: '100%',
    border: 'none',
    borderBottom: `1px dashed ${alpha(theme.palette.common.black, 0.2)}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mold_container: {
    display: 'flex',
  },
  mold_content: {
    paddingRight: theme.spacing(4),
  },
  mold_heading: {
    width: 180,
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  table_cell: {
    width: 65,
    minWidth: 65,
  },
  table_input_cell: {
    width: 90,
    maxWidth: 90,
  },
  table_action_cell: {
    width: 90,
    minWidth: 90,
  },
  table_defect_cell: {
    width: '100%',
  },
  table_note_cell: {
    width: 125,
    minWidth: 125,
  },
  table_note_input: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(1),
  },
  result: {
    marginRight: theme.spacing(5),
  },
  result_item: {
    marginRight: theme.spacing(4),
  },
  footer: {
    marginTop: theme.spacing(1.5),
    width: '100%',
  },
  submit_btn: {
    width: 122,
    marginLeft: theme.spacing(2),
  },
  auto_complete: {
    marginTop: `-${theme.spacing(2)}`,
  },
}));

export default useStyles;
