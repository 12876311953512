import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  main_content: {
    marginTop: theme.spacing(5),
  },
  delivery_container: {
    padding: `${theme.spacing(2)} 0`,
  },
  delivery_sc: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(1)}`,
  },
  delivery_sc_header: {
    display: 'grid',
    gridTemplateColumns: `minmax(auto, 120px) minmax(auto, 110px) minmax(auto, 100px) 1fr minmax(auto, 95px) minmax(auto, 95px) minmax(auto, 95px) minmax(auto, 95px) minmax(auto, 75px) minmax(auto, 80px)`,
    columnGap: theme.spacing(1),
    width: '100%',
  },
  delivery_sc_row: {
    marginTop: theme.spacing(0.5),
    display: 'grid',
    gridTemplateColumns: `minmax(auto, 120px) minmax(auto, 110px) minmax(auto, 100px) 1fr minmax(auto, 95px) minmax(auto, 95px) minmax(auto, 95px) minmax(auto, 95px) minmax(auto, 75px) minmax(auto, 80px)`,
    columnGap: theme.spacing(1),
    width: '100%',
  },
  delivery_trans: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
  },
  delivery_trans_section: {
    padding: `0 ${theme.spacing(2)}`,
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  delivery_trans_header: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `1fr 1fr`,
  },
  delivery_trans_row_three: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `1fr 1fr 1fr`,
  },
  delivery_trans_row_two: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `1fr 1fr `,
  },
  delivery_trans_column: {
    justifySelf: 'center',
  },
  clickable: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  clickable_icon: {
    display: 'inline-block',
    marginLeft: theme.spacing(0.7),
    marginTop: theme.spacing(0.5),
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: `4px 0 4px 6.9px`,
    borderColor: `transparent transparent transparent ${theme.palette.primary.main}`,
  },
  trans_list: {
    margin: `${theme.spacing(4)} ${theme.spacing(3)}`,
  },
  trans_list_header: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    display: 'grid',
    width: '100%',
    gridTemplateColumns: `minmax(auto, 150px) minmax(auto, 120px) 1fr 1fr `,
    columnGap: theme.spacing(2.5),
  },
  trans_list_row: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    display: 'grid',
    gridTemplateColumns: `minmax(auto, 150px) minmax(auto, 120px) 1fr 1fr `,
    columnGap: theme.spacing(2.5),
    width: '100%',
  },
  trans_list_time_reason_gridTemplateColumns: {
    gridTemplateColumns: `1fr 1fr 1fr minmax(auto, 150px) `,
    alignItems: 'center',
  },
  trans_list_odNo_gridTemplateColumns: {
    gridTemplateColumns: `1fr 1fr 1fr`,
    alignItems: 'center',
  },
  trans_list_package_gridTemplateColumns: {
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
    alignItems: 'center',
  },
  scOd_info_container: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(1),
  },
  scOd_info_content: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(3.2),
    paddingRight: theme.spacing(3.2),
  },
  scOd_info_first_field: {
    '&.MuiGrid2-root': {
      width: `calc((100% - 256px) / 2 + ${theme.spacing(8)})`,
      maxWidth: `calc((100% - 256px) / 2 + ${theme.spacing(8)})`,
      marginRight: theme.spacing(8),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        width: `calc((100% - 128px) / 2 + ${theme.spacing(4)})`,
        maxWidth: `calc((100% - 128px) / 2 + ${theme.spacing(4)})`,
        marginRight: theme.spacing(4),
      },
    },
  },
  scOd_info_field: {
    '&.MuiGrid2-root': {
      width: `calc((100% - 256px) / 4)`,
      maxWidth: `calc((100% - 256px) / 4)`,
      marginRight: theme.spacing(8),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        width: `calc((100% - 128px) / 4)`,
        maxWidth: `calc((100% - 128px) / 4)`,
        marginRight: theme.spacing(4),
      },
    },
  },
  scOd_list: {
    marginTop: theme.spacing(3),
  },
  scOd_container: {
    paddingBottom: theme.spacing(2),
  },
  scOd_trans_row: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `minmax(140px, 140px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 100px) minmax(auto, 90px) minmax(auto, 100px) minmax(auto, 85px) 1fr`,
    columnGap: theme.spacing(1),
  },
  scOd_manuInfo_row: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `minmax(140px, 140px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 100px) minmax(auto, 90px) minmax(auto, 100px) minmax(auto, 85px) minmax(auto, 80px) 1fr`,
    columnGap: theme.spacing(1),
  },
  scOd_manuInfo_align_row: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `minmax(140px, 140px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px)`,
    columnGap: theme.spacing(1),
  },
  scOd_period_row: {
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'dense',
    gridTemplateColumns: `minmax(140px, 140px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px) minmax(auto, 120px) 1fr`,
    columnGap: theme.spacing(1),
  },
  date_group: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
  date_picker: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(2),
    },
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(2),
    },
  },
  filter_date_grid: {
    '&.MuiGrid2-root': {
      width: 240,
      maxWidth: 240,
      marginRight: theme.spacing(3),
    },
  },
  filter_date_picker: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(2),
    },
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(1.5),
    },
  },
  error_opacity: {
    color: 'transparent',
  },
  filter_btn: {
    alignSelf: 'flex-end',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
  filter_btn_error: {
    marginBottom: theme.spacing(5),
  },
  daily_list: {
    marginTop: theme.spacing(3),
  },
  daily_container: {
    paddingBottom: theme.spacing(2.5),
  },
  daily_trans: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  daily_trans_row: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `minmax(auto, 112.5px) minmax(auto, 52.5px) minmax(auto, 120px) 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr`,
    columnGap: theme.spacing(1),
    '& *': {
      wordBreak: 'break-word',
    },
  },
  daily_info: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr 1fr 1fr`,
  },
  daily_info_section: {
    padding: `0 ${theme.spacing(1)}`,
    textAlign: 'center',
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    },
  },
  daily_info_text: {
    display: 'flex',
    justifyContent: 'center',
  },
  yield_chart_container: {
    padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
  },
  trans_ng_list: {
    margin: `${theme.spacing(4)} ${theme.spacing(3)}`,
  },
  trans_ng_list_header: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    display: 'grid',
    width: '100%',
    gridTemplateColumns: `1fr 2.3fr 1fr`,
    columnGap: theme.spacing(2),
  },
  trans_ng_list_row: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    display: 'grid',
    gridTemplateColumns: `1fr 2.3fr 1fr`,
    columnGap: theme.spacing(2),
    width: '100%',
  },
  trans_ng_text: {
    color: theme.palette.error.main,
  },
  filter_field: {
    width: `calc((100% - 40px) / 2)`,
    maxWidth: `calc((100% - 40px) / 2)`,
    '&:not(:nth-of-type(2n))': {
      marginRight: theme.spacing(5),
    },
  },
  advanced_filter: {
    marginTop: theme.spacing(2.5),
  },
  advanced_filter_row: {
    marginTop: theme.spacing(4),
  },
  delivery_progress_advanced_filter_btn: {
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2.5),
  },
  advanced_filter_btn: {
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.up('lg')]: {
      width: 'calc((100% / 4 * 3) + 80px)',
      maxWidth: 'calc((100% / 4 * 3) + 80px)',
    },
  },
  order_Field: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
  },
  order_Select_Field: {
    marginTop: theme.spacing(2),
    width: `230px`,
  },
  order_Button: {
    marginLeft: theme.spacing(2),
  },
}));

export default useStyles;
