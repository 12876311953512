/* eslint-disable no-nested-ternary */

import { measureStyleType } from '../../constants/enums';

/* eslint-disable import/prefer-default-export */
export const calcTargetValue = (specConfig) => {
  let maxValue = '';
  let minValue = '';
  let upperLimit = '';
  let lowerLimit = '';
  if (!specConfig) return [maxValue, minValue, upperLimit, lowerLimit];

  if (specConfig && specConfig.specUpper && specConfig.specUpper.length > 0) {
    if (specConfig.specTarget.length > 0 && specConfig.styleId !== measureStyleType.Z) {
      maxValue = +specConfig.specTarget + +specConfig.specUpper;
    } else {
      maxValue = +specConfig.specUpper;
    }
  }
  if (specConfig && specConfig.specLower && specConfig.specLower.length > 0) {
    if (specConfig.specTarget.length > 0 && specConfig.styleId !== measureStyleType.Z) {
      if (specConfig.specLower < 0) {
        minValue = +specConfig.specTarget + +specConfig.specLower;
      } else {
        minValue = +specConfig.specTarget - +specConfig.specLower;
      }
    } else {
      minValue = +specConfig.specLower;
    }
  }
  if (specConfig.limitUpper && specConfig.limitUpper.length > 0) {
    if (specConfig.specTarget.length > 0 && specConfig.styleId !== measureStyleType.Z) {
      upperLimit = +specConfig.specTarget + +specConfig.limitUpper;
    } else {
      upperLimit = +specConfig.limitUpper;
    }
  }
  if (specConfig.limitLower && specConfig.limitLower.length > 0) {
    if (specConfig.specTarget.length > 0 && specConfig.styleId !== measureStyleType.Z) {
      if (specConfig.limitLower < 0) {
        lowerLimit = +specConfig.specTarget + +specConfig.limitLower;
      } else {
        lowerLimit = +specConfig.specTarget - +specConfig.limitLower;
      }
    } else {
      lowerLimit = +specConfig.limitLower;
    }
  }
  return [maxValue, minValue, upperLimit, lowerLimit];
};

export const isOutSpec = (value, maxValue, minValue, upperLimit, lowerLimit) => {
  const valueNum = parseFloat(value);
  const outSpec =
    (typeof maxValue === 'number' && valueNum > maxValue) ||
    (typeof minValue === 'number' && valueNum < minValue);
  const outLimit =
    (typeof upperLimit === 'number' && valueNum > upperLimit) ||
    (typeof lowerLimit === 'number' && valueNum < lowerLimit);
  return [outSpec, outLimit];
};

export const isFormingOutOfSpec = (target, data) => {
  let isHeightOutSpec = false;
  let isWeightOutSpec = false;
  const { weightMax, weightMin, heightMax, heightMin } = target;
  const { weight: currentWeight, height: currentHeight } = data;
  if (currentWeight && currentWeight.length !== 0) {
    if (+currentWeight > +weightMax || +currentWeight < +weightMin) {
      isWeightOutSpec = true;
    } else {
      isWeightOutSpec = false;
    }
  }
  if (currentHeight && currentHeight.length !== 0) {
    if (+currentHeight > +heightMax || +currentHeight < +heightMin) {
      isHeightOutSpec = true;
    } else {
      isHeightOutSpec = false;
    }
  }

  return { isHeightOutSpec, isWeightOutSpec };
};
