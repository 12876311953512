import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import MuiRadio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles()((theme) => ({
  radio: {
    marginRight: theme.spacing(2),
  },
}));

const Radio = ({ label, value, name, onChange, selectedValue, ...rest }) => {
  const { classes } = useStyles();
  return (
    <FormControlLabel
      value={value}
      control={
        <MuiRadio
          color="primary"
          checked={selectedValue === value}
          onChange={onChange}
          value={value}
          name={name}
          inputProps={{ 'aria-label': label }}
          className={classes.radio}
        />
      }
      label={label}
      {...rest}
    />
  );
};

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default Radio;
