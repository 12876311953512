/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useAxios from '../../../hooks/useAxios';
import useCancelToken from '../../../hooks/useCancelToken';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import Typography from '../../../components/Typography/Typography';
import InputField from '../../../components/InputField/InputField';
import Divider from '../../../components/Divider/Divider';
import Dialog from '../../../components/Dialog/Dialog';
import DialogTitle from '../../../components/DialogTitle/DialogTitle';
import DialogContent from '../../../components/DialogContent/DialogContent';
import DialogActions from '../../../components/DialogActions/DialogActions';
import CircularProgress from '../../../components/CircularProgress/CircularProgress';
import useStyles from '../useStyles';
import checkboxReducer, { checkboxActions } from '../../../reducers/checkboxReducer';
import PrivilegeCheckbox from './PrivilegeCheckbox';
import Grid from '../../../components/Grid/Grid';

const SettingPrivilegeDialog = ({
  open,
  handleDialogClose,
  privilegeList,
  settingPrivilegeRole,
  isAdding,
  isEditing,
  isViewing,
  resetData,
  editedData,
  setEditedData,
}) => {
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);
  const [role, setRole] = useState('');
  const [roleErr, setRoleErr] = useState(false);
  const [codeValue, setCodeValue] = useState([]);

  const [checkboxItems, checkboxDispatch] = useReducer(checkboxReducer, []);
  const [checkboxItems2, checkboxDispatch2] = useReducer(checkboxReducer, []);
  const [checkboxItems3, checkboxDispatch3] = useReducer(checkboxReducer, []);

  const resetDialogData = () => {
    resetData();
    setRole('');
    setRoleErr(false);
    setEditedData({});
    setCodeValue([]);
    handleDialogClose();
  };

  const handleConfirm = (data) => {
    const finalData = {
      roleData: {
        roleId: isEditing ? editedData.rowId : -1,
        roleName: role,
        roleComment: '',
        apiApplyList: data
          .filter((item) => item.isPromise === true)
          .map((item) => ({
            apiName: item.privilegeName,
            code: item.privilegeId,
          })),
      },
    };
    if (finalData.roleData.apiApplyList.length === 0) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '您尚未選擇任何權限',
          message: '請至少選一個權限！',
        },
      });
    } else {
      settingPrivilegeRole(finalData, isEditing ? '更新' : '新增');
      resetDialogData();
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    const privilege = [];
    checkboxItems.forEach((item) => {
      privilege.push({
        privilegeId: Number(item.name),
        isPromise: item.checked,
        privilegeName: item.label,
      });
    });
    checkboxItems2.forEach((item) => {
      privilege.push({
        privilegeId: Number(item.name),
        isPromise: item.checked,
        privilegeName: item.label,
      });
    });
    checkboxItems3.forEach((item) => {
      privilege.push({
        privilegeId: Number(item.name),
        isPromise: item.checked,
        privilegeName: item.label,
      });
    });

    if (!isViewing) {
      if (role === '') {
        setRoleErr(true);
      } else {
        handleConfirm(privilege);
      }
    } else {
      resetDialogData();
    }
  };

  const handleCancel = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認離開？',
        message: '是否確認退出編輯？您所做出的更動將不會被儲存。',
        handleConfirm: () => resetDialogData(),
      },
    });
  };

  useEffect(() => {
    if (privilegeList.length === 0) return;
    checkboxDispatch({
      type: checkboxActions.INIT,
      init: privilegeList[0].apiApplyList.map((item) => ({
        name: item.code.toString(),
        label: item.apiName,
        checked: isEditing && editedData.columns[1].codeValue.includes(item.code),
      })),
    });
    checkboxDispatch2({
      type: checkboxActions.INIT,
      init: privilegeList[1].apiApplyList.map((item) => ({
        name: item.code.toString(),
        label: item.apiName,
        checked: isEditing && editedData.columns[1].codeValue.includes(item.code),
      })),
    });
    checkboxDispatch3({
      type: checkboxActions.INIT,
      init: privilegeList[2].apiApplyList.map((item) => ({
        name: item.code.toString(),
        label: item.apiName,
        checked: isEditing && editedData.columns[1].codeValue.includes(item.code),
      })),
    });
  }, [privilegeList.length, isEditing, isAdding]);
  useEffect(() => {
    setRoleErr(false);
  }, [role]);

  useEffect(() => {
    if (editedData.rowId) {
      setRole(editedData.columns[0].displayValue);
      setCodeValue(editedData.columns[1].codeValue);
    }
    if (!open) {
      resetDialogData();
    }
  }, [open]);

  return (
    <>
      {open && (
        <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
          <DialogTitle
            id="detail-title"
            onClose={handleDialogClose}
            className={classes.dialog_header}
          >
            {isViewing ? '系統權限' : '系統權限設定'}
          </DialogTitle>

          <DialogContent dividers>
            {isViewing ? (
              <Typography variant="h4">{role}</Typography>
            ) : (
              <InputField
                label="角色名稱"
                placeholder="請輸入角色名稱"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                error={roleErr}
                errormsg="角色名稱不得為空！"
                style={{ marginTop: 6 }}
              />
            )}

            <Divider style={{ marginTop: 12 }} />

            <PrivilegeCheckbox
              checkboxItems={checkboxItems}
              checkboxItems2={checkboxItems2}
              checkboxItems3={checkboxItems3}
              checkboxDispatch={checkboxDispatch}
              checkboxDispatch2={checkboxDispatch2}
              checkboxDispatch3={checkboxDispatch3}
              isViewing={isViewing}
              codeValue={codeValue}
            />
          </DialogContent>
          <Grid container justifyContent="flex-end">
            <DialogActions onClick={handleClick}>確認</DialogActions>
            {!isViewing && (
              <DialogActions onClick={handleCancel} color="error">
                取消
              </DialogActions>
            )}
          </Grid>
        </Dialog>
      )}
    </>
  );
};

SettingPrivilegeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  privilegeList: PropTypes.array.isRequired,
  settingPrivilegeRole: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isViewing: PropTypes.bool.isRequired,
  resetData: PropTypes.func.isRequired,
  editedData: PropTypes.object,
  setEditedData: PropTypes.func,
};

export default SettingPrivilegeDialog;
