import { deepOrange } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useCommonStyles = makeStyles()((theme) => ({
  main: {
    marginTop: theme.spacing(2),
  },
  container: {
    marginBottom: theme.spacing(1.5),
  },
  content: {
    marginTop: theme.spacing(3),
    padding: `${theme.spacing(1.8)} 0 ${theme.spacing(1)}`,
  },
  field_column: {
    width: `calc((100% - 256px) / 4)`,
    maxWidth: `calc((100% - 256px) / 4)`,
    marginRight: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      width: `calc((100% - 128px) / 4)`,
      maxWidth: `calc((100% - 128px) / 4)`,
      marginRight: theme.spacing(4),
    },
  },
  field_column_sintering: {
    width: `calc((100% - 215px) / 4)`,
    maxWidth: `calc((100% - 215px) / 4)`,
    marginRight: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      width: `calc((100% - 128px) / 4)`,
      maxWidth: `calc((100% - 128px) / 4)`,
      marginRight: theme.spacing(4),
    },
  },
  field_column_sintering_emptySpace: {
    width: `calc((100% - 215px) / 4)`,
    maxWidth: `calc((100% - 215px) / 4)`,
    marginRight: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  field_column_sintering_productInfo: {
    width: `calc((100% - 256px) / 2 + ${theme.spacing(6.5)})`,
    maxWidth: `calc((100% - 256px) / 2  + ${theme.spacing(6.5)})`,
    display: 'flex',
    marginRight: theme.spacing(4),
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      width: `calc((100% - 128px) / 2)`,
      maxWidth: `calc((100% - 128px) / 2)`,
      // marginLeft: theme.spacing(4),
      marginRight: theme.spacing(8),
    },
  },
  field_column_sintering_productInfo_size_ele: {
    width: `calc((100% - 256px) / 2 + ${theme.spacing(6.5)})`,
    maxWidth: `calc((100% - 256px) / 2  + ${theme.spacing(6.5)})`,
    display: 'flex',
    marginRight: theme.spacing(4),
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      width: `calc((100% - 128px) / 2)`,
      maxWidth: `calc((100% - 128px) / 2)`,
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
  },
  field_column_productInfo: {
    '&.MuiGrid2-root': {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('lg')]: {
        width: `calc((100% - 128px) / 2)`,
        maxWidth: `calc((100% - 128px) / 2)`,
        marginLeft: `calc((100% - 256px) / 3 + ${theme.spacing(4)})`,
        marginRight: theme.spacing(4),
      },
    },
  },
  field_column_button: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.8rem',
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      width: `calc((100% - 128px) / 4)`,
      maxWidth: `calc((100% - 128px) / 4)`,
      marginRight: theme.spacing(4),
    },
  },

  field_column_button_size_ele: {
    width: `calc((100% - 256px) / 4)`,
    maxWidth: `calc((100% - 256px) / 3.7)`,
    marginRight: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    [theme.breakpoints.down('lg')]: {
      width: `calc((100% - 128px) / 4)`,
      maxWidth: `calc((100% - 128px) / 4)`,
      marginRight: '0',
      marginLeft: theme.spacing(2),
    },
  },
  field_item: {
    marginBottom: theme.spacing(3.6),
  },
  field_input: {
    marginTop: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1.8)} 0 ${theme.spacing(1)}`,
  },
  table_header: {
    display: 'flex',
    padding: 0,
    width: '100%',
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    marginBottom: 0,
  },
  table_column: {
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}`,
    boxSizing: 'border-box',
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    },
  },
  onScroll_table_column: {
    '&:not(:last-of-type)': {
      borderRight: 'none',
    },
  },
  table_cell: {
    display: 'block',
    textAlign: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(0.6)}`,
    boxSizing: 'border-box',
    alignSelf: 'flex-start',
  },
  table_row: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
  },
  empty_btn: {
    margin: '0 auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  table_input: {
    padding: theme.spacing(1.5),
  },
  out_spec: {
    color: theme.palette.error.main,
    backgroundColor: alpha(deepOrange[500], 0.1),
  },
  out_limit: {
    color: deepOrange[500],
  },
  error_snackbar: {
    backgroundColor: alpha(theme.palette.error.light, 0.3),
    color: theme.palette.error.main,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& p': {
      marginLeft: 0,
    },
  },
  success_snackbar: {
    marginBottom: theme.spacing(2),
  },
  removed_column: {
    textDecoration: 'line-through',
  },
  table_cell_bias: {
    fontStyle: 'normal',
    color: theme.palette.primary.main,
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(2),
    opacity: 0,
    transition: 'all .4s ease',
    transform: 'translateY(-30px)',
  },
  bias_animation: {
    '& i': {
      animation: `$biasEffect 1s ${theme.transitions.easing.easeInOut}`,
    },
  },
  filter_btn_group: {
    paddingLeft: theme.spacing(3),
  },
  filter_btn: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(1.5),
    alignSelf: 'flex-end',
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(1.5),
    },
  },
  filter_field: {
    width: `calc((100% - 80px) / 3)`,
    maxWidth: `calc((100% - 80px) / 3)`,
    '&:not(:nth-of-type(3n))': {
      marginRight: theme.spacing(5),
    },
  },
  advanced_filter: {
    marginTop: theme.spacing(2.5),
  },
  advanced_filter_row: {
    marginTop: theme.spacing(4),
  },
  advanced_filter_btn: {
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.up('lg')]: {
      width: 'calc((100% / 4 * 3) + 80px)',
      maxWidth: 'calc((100% / 4 * 3) + 80px)',
    },
  },
  '@keyframes biasEffect': {
    '0%': {
      opacity: 0,
      transform: 'translateY(0)',
    },
    '60%': {
      opacity: 1,
      transform: 'translateY(-20px)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateY(-30px)',
    },
  },
}));

export default useCommonStyles;
