import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '../../../components/Typography/Typography';
import PageTitle from '../../../components/PageTitle/PageTitle';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import useCommonClasses from '../useStyles';
import DeliveryProgress from './DeliveryProgress/DeliveryProgress';
import ScOdProgress from './ScOdProgress/ScOdProgress';
import FormingDaily from './FormingDaily/FormingDaily';
import FormingWorknoDaily from './FormingWorknoDaily/FormingWorknoDaily';
import FormingStock from './FormingStock/FormingStock';

const targetPageType = {
  DELIVERY_PROGRESS: 'delivery_progress',
  SOURCE_ORDER_PROGRESS: 'source_order_progress',
  FORMING_DAILY: 'forming_daily',
  FORMING_WORKNO_DAILY: 'forming_workno_daily',
  FORMING_STOCK: 'forming_stock',
};

const targetPageName = {
  DELIVERY_PROGRESS: '出貨進度表',
  SOURCE_ORDER_PROGRESS: '工令進度表',
  FORMING_DAILY: '壓型日報表',
  FORMING_WORKNO_DAILY: '壓型工令日報表',
  FORMING_STOCK: '品項壓型統計表',
};

const TransReport = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [targetPage, setTargetPage] = useState(targetPageType.DELIVERY_PROGRESS);
  const [fromDeliveryProgress, setFromDeliveryProgress] = useState(false);
  const scOdNo = useRef(null);
  const { classes } = useCommonClasses();
  const handleFromDeliveryToScOd = (scOd) => {
    setFromDeliveryProgress(true);
    scOdNo.current = scOd;
    window.open(
      `${process.env.REACT_APP_BASE_URL}/#/trans-report?type=${targetPageType.SOURCE_ORDER_PROGRESS}&scOd=${scOd}`
    );
  };

  const handleTab = (tabName) => {
    const params = new URLSearchParams();
    params.append('type', tabName);
    navigate({ pathname: '/trans-report', search: `?${params.toString()}` });
    setTargetPage(tabName);
  };

  useEffect(() => {
    setTargetPage(searchParams.get('type') || targetPageType.DELIVERY_PROGRESS);
  }, []);

  return (
    <div className={classes.main}>
      <PageTitle title="移轉單 Report" />
      <PaperContainer className={classes.report_nav}>
        <Typography
          variant="body1"
          className={`${classes.report_nav_item} ${
            targetPage === targetPageType.DELIVERY_PROGRESS ? 'active' : ''
          }`}
          id={targetPageType.DELIVERY_PROGRESS}
          onClick={(e) => handleTab(e.target.id)}
        >
          {targetPageName.DELIVERY_PROGRESS}
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.report_nav_item} ${
            targetPage === targetPageType.SOURCE_ORDER_PROGRESS ? 'active' : ''
          }`}
          id={targetPageType.SOURCE_ORDER_PROGRESS}
          onClick={(e) => handleTab(e.target.id)}
        >
          {targetPageName.SOURCE_ORDER_PROGRESS}
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.report_nav_item} ${
            targetPage === targetPageType.FORMING_DAILY ? 'active' : ''
          }`}
          id={targetPageType.FORMING_DAILY}
          onClick={(e) => handleTab(e.target.id)}
        >
          {targetPageName.FORMING_DAILY}
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.report_nav_item} ${
            targetPage === targetPageType.FORMING_WORKNO_DAILY ? 'active' : ''
          }`}
          id={targetPageType.FORMING_WORKNO_DAILY}
          onClick={(e) => handleTab(e.target.id)}
        >
          {targetPageName.FORMING_WORKNO_DAILY}
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.report_nav_item} ${
            targetPage === targetPageType.FORMING_STOCK ? 'active' : ''
          }`}
          id={targetPageType.FORMING_STOCK}
          onClick={(e) => handleTab(e.target.id)}
        >
          {targetPageName.FORMING_STOCK}
        </Typography>
      </PaperContainer>

      <div className={classes.main}>
        {targetPage === targetPageType.DELIVERY_PROGRESS && (
          <DeliveryProgress handleFromDeliveryToScOd={handleFromDeliveryToScOd} />
        )}
        {targetPage === targetPageType.SOURCE_ORDER_PROGRESS && (
          <ScOdProgress
            transScOdNo={scOdNo.current || null}
            fromDeliveryProgress={fromDeliveryProgress}
            setFromDeliveryProgress={setFromDeliveryProgress}
          />
        )}
        {targetPage === targetPageType.FORMING_DAILY && <FormingDaily />}
        {targetPage === targetPageType.FORMING_WORKNO_DAILY && <FormingWorknoDaily />}
        {targetPage === targetPageType.FORMING_STOCK && <FormingStock />}
      </div>
    </div>
  );
};

export default TransReport;
