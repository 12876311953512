import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';
import Grid from '../Grid/Grid';
import TablePaginationActions from './TablePaginationActions';

const TablePagination = ({ totalRows, rowsPerPage, page, onChangePage, isEditing }) => (
  <Grid container direction="row" justifyContent="space-between" alignItems="center">
    <Grid item xs={3}>
      <Typography variant="body2">Total Rows: {totalRows}</Typography>
    </Grid>
    <Grid item container xs={9} alignItems="center" justifyContent="flex-end">
      <Typography variant="body1">
        {(page - 1) * rowsPerPage + 1}-
        {page * rowsPerPage >= totalRows ? totalRows : page * rowsPerPage} of {totalRows}
      </Typography>
      <TablePaginationActions
        onChangePage={onChangePage}
        page={page}
        rowsPerPage={rowsPerPage}
        count={totalRows}
        isEditing={isEditing}
      />
    </Grid>
  </Grid>
);

TablePagination.propTypes = {
  totalRows: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default TablePagination;
