/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import useStyles from './QCSinteringSize/useStyles';
import useCommonStyles from '../useStyles';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import List from '../../../components/List/List';
import ListItem from '../../../components/ListItem/ListItem';
import ListItemText from '../../../components/ListItemText/ListItemText';
import InputField from '../../../components/InputField/InputField';
import ReadonlyField from '../../../components/ReadonlyField/ReadonlyField';
import SelectField from '../../../components/SelectField/SelectField';
import Button from '../../../components/Button/Button';
import Popper from '../../../components/Popper/Popper';
import { layerSelection, positionSelection } from '../../../constants/configSelections';
import { sinteringSizePath } from '../../../constants/enums';

const TransInfo = ({
  transId,
  transIdErr,
  subTransIds,
  multipleTransQty,
  transInfo,
  transInfoErr,
  elecStoveSelection,
  handleTransIdChange,
  handleTransInfoChange,
  handleFetch,
  path,
  resetTransInfo,
  setResetTransInfo,
  transIdErrMsg,
  setShowTable,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [delayClosePopper, setDelayClosePopper] = useState(false);
  const [currentTransInfo, setCurrentTransInfo] = useState({});

  const anchorElRef = useRef(null);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const onTransIdChange = (e) => {
    handleTransIdChange(e.target.value);
    if (!anchorElRef.current) {
      anchorElRef.current = e.currentTarget;
    }
    if (subTransIds.length > 1) {
      setAnchorEl(e.currentTarget);
    }
  };

  const onTransIdBlur = (e) => {
    if (!delayClosePopper) return;
    const timer = setTimeout(() => {
      setAnchorEl(null);
      clearTimeout(timer);
    }, 500);
  };

  const onTransIdFocus = (e) => {
    if (subTransIds.length > 1) {
      setAnchorEl(e.currentTarget);
    }
  };

  const onSubTransIdClick = (id) => {
    if (
      id.trim().replace(' ', '').length % 11 === 10 ||
      id.trim().replace(' ', '').length % 11 === 3
    ) {
      handleTransIdChange(id);
      setShowTable(false);
    } else {
      handleTransIdChange(id, true);
    }
  };

  useEffect(() => {
    if (subTransIds.length > 1) {
      anchorElRef.current.blur();
      anchorElRef.current.focus();
      setDelayClosePopper(true);
    } else {
      setAnchorEl(null);
    }
  }, [subTransIds.length]);

  useEffect(() => {
    if (transInfo.transId === '') return;
    if (Object.keys(currentTransInfo).length === 0) setCurrentTransInfo(transInfo);
  }, [transInfo, transId]);

  useEffect(() => {
    if (resetTransInfo) {
      setCurrentTransInfo({});
      setResetTransInfo(false);
    }
  }, [resetTransInfo]);

  useEffect(() => {
    setDelayClosePopper(false);
  }, [path]);

  return (
    <PaperContainer className={commonClasses.container}>
      <Typography variant="h6">移轉單資訊</Typography>
      <Grid container className={commonClasses.content}>
        <Grid item xs={3} className={commonClasses.field_column_sintering}>
          <InputField
            id="transId"
            name="transId"
            type="text"
            label="移轉單號"
            value={transId}
            placeholder="請輸入移轉單號"
            onChange={onTransIdChange}
            onBlur={onTransIdBlur}
            onFocus={onTransIdFocus}
            error={transIdErr}
            errormsg={transIdErrMsg}
            ref={anchorEl}
            autoComplete="off"
          />{' '}
          <Popper id="subTransId" open={Boolean(anchorEl)} anchorEl={anchorEl}>
            <PaperContainer className={classes.sub_transid_container}>
              <List component="div" className={classes.sub_transid}>
                {subTransIds.map((item) => (
                  <ListItem onClick={(e) => onSubTransIdClick(item.transId)} key={item.transId}>
                    <ListItemText primary={item.transId} />
                  </ListItem>
                ))}
              </List>
            </PaperContainer>
          </Popper>
        </Grid>
        <Grid item xs={3} className={commonClasses.field_column_sintering}>
          <ReadonlyField
            label="客戶"
            name="customer"
            value={transInfo.customer}
            className={commonClasses.field_item}
          />
        </Grid>
        <Grid item xs={3} className={commonClasses.field_column_sintering}>
          {(path === sinteringSizePath.SIZE || path === sinteringSizePath.ELECTRICAL) && (
            <ReadonlyField
              label="數量"
              name="transQty"
              value={multipleTransQty || transInfo.transQty}
            />
          )}
          {path === sinteringSizePath.INSPECTION && (
            <SelectField
              id="layer"
              label="層數"
              placeholder="請選擇層數"
              selections={layerSelection}
              value={transInfo.layer}
              handleChange={handleTransInfoChange}
              error={transInfoErr.layer}
              errormsg="請選擇層數"
              className={commonClasses.field_input}
              style={{ marginTop: '0' }}
            />
          )}
        </Grid>
        {path === sinteringSizePath.INSPECTION && (
          <Grid item xs={3} className={commonClasses.field_column_sintering}>
            {transInfo.machineName !== '0' ? (
              <ReadonlyField
                label="爐號"
                name="machineName"
                value={transInfo.machineName}
                className={commonClasses.field_item}
              />
            ) : (
              <SelectField
                id="elecStoveNo"
                label="爐號"
                placeholder="請選擇爐號"
                selections={elecStoveSelection}
                value={transInfo.elecStoveNo}
                handleChange={handleTransInfoChange}
                error={transInfoErr.elecStoveNo}
                errormsg="請選擇爐號"
              />
            )}
          </Grid>
        )}
        {(path === sinteringSizePath.SIZE || path === sinteringSizePath.ELECTRICAL) && (
          <Grid item xs={3}>
            {transInfo.machineName !== '0' ? (
              <ReadonlyField label="爐號" name="machineName" value={transInfo.machineName} />
            ) : (
              <SelectField
                id="elecStoveNo"
                label="爐號"
                placeholder="請選擇爐號"
                selections={elecStoveSelection}
                value={transInfo.elecStoveNo}
                handleChange={handleTransInfoChange}
                error={transInfoErr.elecStoveNo}
                errormsg="請選擇爐號"
              />
            )}
          </Grid>
        )}
        {(path === sinteringSizePath.SIZE || path === sinteringSizePath.ELECTRICAL) && (
          <Grid item xs={3} className={commonClasses.field_column_sintering_emptySpace} />
        )}

        {path === sinteringSizePath.INSPECTION && (
          <Grid item xs={6} className={commonClasses.field_column_sintering_productInfo}>
            <ReadonlyField
              label="產品資訊"
              name="elName"
              value={transInfo.elName}
              className={commonClasses.field_item}
            />
          </Grid>
        )}
        {(path === sinteringSizePath.SIZE || path === sinteringSizePath.ELECTRICAL) && (
          <Grid item xs={6} className={commonClasses.field_column_sintering_productInfo_size_ele}>
            <ReadonlyField
              label="產品資訊"
              name="elName"
              value={transInfo.elName}
              className={commonClasses.field_item}
            />
          </Grid>
        )}
        {path === sinteringSizePath.INSPECTION && (
          <Grid item xs={3} className={commonClasses.field_column_sintering}>
            <SelectField
              id="position"
              label="位置"
              placeholder="請選擇前｜中｜後"
              selections={positionSelection}
              value={transInfo.position}
              handleChange={handleTransInfoChange}
              error={transInfoErr.position}
              errormsg="請選擇前｜中｜後"
              className={commonClasses.field_input}
              style={{ marginTop: '0' }}
            />
          </Grid>
        )}
        {path === sinteringSizePath.INSPECTION && (
          <Grid item xs={3} className={commonClasses.field_column_button}>
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleFetch}
                startIcon={<SearchRoundedIcon />}
                customClasses={classes.fetch_btn}
                style={{
                  alignSelf: 'flex-start',
                  marginTop: '15px',
                  marginBottom: transInfoErr.elecStoveNo ? 16 : 0,
                }}
              >
                確認
              </Button>
            </div>
          </Grid>
        )}
        {(path === sinteringSizePath.SIZE || path === sinteringSizePath.ELECTRICAL) && (
          <Grid item xs={3} className={commonClasses.field_column_button_size_ele}>
            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleFetch}
                startIcon={<SearchRoundedIcon />}
                customClasses={classes.fetch_btn}
                style={{
                  alignSelf: 'flex-start',
                  marginTop: '15px',
                  marginBottom: transInfoErr.elecStoveNo ? 16 : 0,
                }}
              >
                確認
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    </PaperContainer>
  );
};

TransInfo.defaultProps = {
  transInfoErr: {},
  elecStoveSelection: [],
  handleTransInfoChange: () => {},
};

TransInfo.propTypes = {
  transId: PropTypes.string.isRequired,
  transIdErr: PropTypes.bool.isRequired,
  subTransIds: PropTypes.array.isRequired,
  multipleTransQty: PropTypes.number.isRequired,
  transInfo: PropTypes.object.isRequired,
  transInfoErr: PropTypes.object,
  elecStoveSelection: PropTypes.array,
  handleTransIdChange: PropTypes.func.isRequired,
  handleTransInfoChange: PropTypes.func,
  handleFetch: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  resetTransInfo: PropTypes.bool.isRequired,
  setResetTransInfo: PropTypes.func.isRequired,
  transIdErrMsg: PropTypes.string.isRequired,
  setShowTable: PropTypes.func,
};

export default TransInfo;
