import { makeStyles } from 'tss-react/mui';
import styleConst from '../../constants/style';

const { DRAWER_WIDTH, DRAWER_WIDTH_MD, TOOLBAR_HEIGHT, TOOLBAR_HEIGHT_MOBILE } = styleConst;
const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      width: DRAWER_WIDTH_MD,
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    height: '100vh',
    [theme.breakpoints.down('lg')]: {
      width: DRAWER_WIDTH_MD,
    },
  },
  drawerInit: {
    width: '80px',
    height: '100vh',
    [theme.breakpoints.down('lg')]: {
      // width: DRAWER_WIDTH_MD,
      width: '80px',
    },
  },
  drawerContainer: {
    overflow: 'auto',
    marginTop: 10,
  },
  toolbar: {
    '&.MuiToolbar-root': {
      minHeight: TOOLBAR_HEIGHT,
      [theme.breakpoints.down('sm')]: {
        minHeight: TOOLBAR_HEIGHT_MOBILE,
      },
    },
  },
  switchIcon: {
    textAlign: 'right',
  },
  openMenu: {
    cursor: 'pointer',
    marginLeft: '1rem',
  },
  closeMenu: {
    cursor: 'pointer',
    color: '#303f9f',
    marginRight: '1.3rem',
  },
  displayNone: {
    display: 'none',
  },
  displayBlock: {
    display: 'block',
  },
}));

export default useStyles;
