/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import Typography from '../../../../components/Typography/Typography';
import Dialog from '../../../../components/Dialog/Dialog';
import DialogTitle from '../../../../components/DialogTitle/DialogTitle';
import DialogContent from '../../../../components/DialogContent/DialogContent';
import DialogActions from '../../../../components/DialogActions/DialogActions';
import CircularProgress from '../../../../components/CircularProgress/CircularProgress';
import useStyles from '../useStyles';
import useCommonStyles from '../../useStyles';

const NGTransListDialog = ({
  open,
  handleTransListDialogClose,
  targetScOdNo,
  targetFormingTrans,
  transList,
}) => {
  const [isLoading, setInsLoading] = useState(false);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;

  return (
    <div className={classes.dialog}>
      <Dialog open={open} onClose={handleTransListDialogClose} fullWidth maxWidth="md">
        <DialogTitle
          id="detail-title"
          onClose={handleTransListDialogClose}
          className={commonClasses.detail_header}
        >
          {targetScOdNo}｜{targetFormingTrans}｜QC 壓型檢測結果
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.trans_ng_list}>
            <div className={classes.trans_ng_list_header}>
              <Typography variant="subtitle1">移轉單號</Typography>
              <Typography variant="subtitle1">更新時間</Typography>
              <Typography variant="subtitle1">檢測結果</Typography>
            </div>
            {transList.map((row) => (
              <div className={classes.trans_ng_list_row} key={row.id}>
                <Typography variant="body1">{targetFormingTrans}</Typography>
                <Typography variant="body1">
                  {format(new Date(row.modifyDate), 'yyyy-MM-dd HH:mm:ss')}
                </Typography>
                <Typography variant="body1" className={!row.isPass ? classes.trans_ng_text : ''}>
                  {row.isPass ? '通過' : '不通過'}
                </Typography>
              </div>
            ))}
          </div>
          {isLoading && <CircularProgress mt={7.5} />}
        </DialogContent>
        <DialogActions className={commonClasses.detail_footer} onClick={handleTransListDialogClose}>
          確認
        </DialogActions>
      </Dialog>
    </div>
  );
};

NGTransListDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleTransListDialogClose: PropTypes.func.isRequired,
  targetScOdNo: PropTypes.string.isRequired,
  targetFormingTrans: PropTypes.string.isRequired,
  transList: PropTypes.array.isRequired,
};

export default NGTransListDialog;
