/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Typography from '../../../../components/Typography/Typography';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Divider from '../../../../components/Divider/Divider';
import useStyles from '../useStyles';
import useCommonStyles from '../../useStyles';
import { classType } from '../../../../constants/enums';

const transClassName = {
  [classType.CLASS_MORNING]: '早',
  [classType.CLASS_NOON]: '中',
  [classType.CLASS_NIGHT]: '晚',
};

const FormingDataRow = ({
  rowData,
  handleShowNGTransList,
  handleShowYieldRateChart,
  setTargetScOdNo,
  setTargetFormingTrans,
}) => {
  const {
    transId,
    transClass,
    updateDate,
    scOdNo,
    productInfo,
    transQty,
    formingWeight,
    bucketNo,
    formingUser,
    formingMachine,
    moldQty,
    efficiency,
    standardQty,
    daysAvg15,
    daysAvg30,
    daysAvg90,
    qcResult,
    yieldRate,
  } = rowData;
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const result =
    typeof qcResult === 'string' && qcResult.length === 0 ? '-' : qcResult ? 'All Pass' : 'NG';

  const handleNGTransClick = () => {
    setTargetScOdNo(scOdNo);
    setTargetFormingTrans(transId);
    handleShowNGTransList(transId);
  };

  const handleYieldRateClick = () => {
    setTargetScOdNo(scOdNo);
    setTargetFormingTrans(transId);
    handleShowYieldRateChart(transId);
  };

  return (
    <PaperContainer className={`${commonClasses.container} ${classes.daily_container}`}>
      <div className={classes.daily_trans}>
        <div className={classes.daily_trans_row}>
          <Typography variant="subtitle2">移轉單號</Typography>
          <Typography variant="subtitle2">班別</Typography>
          <Typography variant="subtitle2">更新日期</Typography>
          <Typography variant="subtitle2">工令單號</Typography>
          <Typography variant="subtitle2">產品資訊</Typography>
          <Typography variant="subtitle2">移轉數量</Typography>
          <Typography variant="subtitle2">壓型重量</Typography>
          <Typography variant="subtitle2">粉料桶號</Typography>
          <Typography variant="subtitle2">壓型人員</Typography>
          <Typography variant="subtitle2">壓型機台</Typography>
          <Typography variant="subtitle2">模具數量</Typography>
        </div>
        <div className={classes.daily_trans_row}>
          <Typography variant="body1">{transId || '-'}</Typography>
          <Typography variant="body1">{transClass ? transClassName[transClass] : '-'}</Typography>
          <Typography variant="body1">
            {updateDate ? `${format(new Date(updateDate), 'yyyy.MM.dd HH:mm:ss')}` : '-'}
          </Typography>
          <Typography variant="body1">{scOdNo || '-'}</Typography>
          <Typography variant="body1" style={{ wordBreak: 'break-all', maxWidth: '160px' }}>
            {productInfo || '-'}
          </Typography>
          <Typography variant="body1">{transQty || '-'}</Typography>
          <Typography variant="body1">{formingWeight || '-'}</Typography>
          <Typography variant="body1">{bucketNo || '-'}</Typography>
          <Typography variant="body1">{formingUser || '-'}</Typography>
          <Typography variant="body1">{formingMachine || '-'}</Typography>
          <Typography variant="body1">{moldQty || '-'}</Typography>
        </div>
      </div>
      <Divider
        className={commonClasses.dashed_divider}
        style={{ marginTop: 16, marginBottom: 16 }}
      />
      <div className={classes.daily_info}>
        <div className={classes.daily_info_section}>
          <Typography variant="subtitle2">效率</Typography>
          <Typography variant="body1" className={classes.daily_info_text}>
            {efficiency && efficiency !== 'NaN' ? `${efficiency}%` : null}
          </Typography>
        </div>
        <div className={classes.daily_info_section}>
          <Typography variant="subtitle2">標準產能</Typography>
          <Typography variant="body1" className={classes.daily_info_text}>
            {standardQty}
          </Typography>
        </div>
        <div className={classes.daily_info_section}>
          <Typography variant="subtitle2">15天平均</Typography>
          <Typography variant="body1" className={classes.daily_info_text}>
            {daysAvg15 || '-'}
          </Typography>
        </div>
        <div className={classes.daily_info_section}>
          <Typography variant="subtitle2">30天平均</Typography>
          <Typography variant="body1" className={classes.daily_info_text}>
            {daysAvg30 || '-'}
          </Typography>
        </div>
        <div className={classes.daily_info_section}>
          <Typography variant="subtitle2">90天平均</Typography>
          <Typography variant="body1" className={classes.daily_info_text}>
            {daysAvg90 || '-'}
          </Typography>
        </div>
        <div className={classes.daily_info_section}>
          <Typography variant="subtitle2">QC結果</Typography>
          <Typography
            variant="body1"
            className={`${classes.daily_info_text} ${result === 'NG' ? classes.clickable : ''}`}
            onClick={result === 'NG' ? handleNGTransClick : null}
          >
            {result}
            {result === 'NG' && <i className={classes.clickable_icon} />}
          </Typography>
        </div>
        <div className={classes.daily_info_section}>
          <Typography variant="subtitle2">良率</Typography>
          <Typography
            variant="body1"
            className={`${classes.daily_info_text} ${
              yieldRate && yieldRate !== '100.00' ? classes.clickable : ''
            }`}
            onClick={yieldRate && yieldRate !== '100.00' ? handleYieldRateClick : null}
          >
            {yieldRate ? `${yieldRate}%` : '-'}
            {yieldRate && yieldRate !== '100.00' && <i className={classes.clickable_icon} />}
          </Typography>
        </div>
      </div>
    </PaperContainer>
  );
};

FormingDataRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  handleShowNGTransList: PropTypes.func.isRequired,
  handleShowYieldRateChart: PropTypes.func.isRequired,
  setTargetFormingTrans: PropTypes.func.isRequired,
  setTargetScOdNo: PropTypes.func.isRequired,
};

export default FormingDataRow;
