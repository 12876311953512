/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import useStyles from '../useStyles';
import Toolbar from '../../../components/Toolbar/Toolbar';
import IconButton from '../../../components/IconButton/IconButton';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import StationFilter from './SettingsConfigStationFilter';
import NewMachineAction from './SettingsConfigNewMachineAction';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';

const MachineTableToolbar = ({
  handleDelete,
  isChecked,
  stationList,
  stationTypeList,
  station,
  onStationChange,
  handleSearch,
  handleAddMachine,
  isEditing,
  isAdding,
  handleIsAdding,
}) => {
  const { classes } = useStyles();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { dialogDispatch } = useContext(DialogContext);

  const handleDeleteClick = () => {
    handleDelete();
  };

  const handleAddingOpen = (e) => {
    e.preventDefault();
    if (!isEditing) {
      handleIsAdding(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '您尚未完成編輯！',
          message: '請先儲存或取消編輯',
        },
      });
    }
  };

  const handleAddingClose = () => {
    handleIsAdding(false);
  };

  const handleFilterOpen = () => {
    if (isFilterOpen) {
      setIsFilterOpen(false);
    } else {
      setIsFilterOpen(true);
    }
  };

  return (
    <div>
      {isAdding ? (
        <NewMachineAction
          handleAddingClose={handleAddingClose}
          handleAddMachine={handleAddMachine}
          stationList={stationList}
          stationTypeList={stationTypeList}
        />
      ) : (
        <div>
          <Toolbar className={classes.toolbar}>
            <Grid
              container
              style={{ marginTop: 25, marginBottom: 20, width: '100%' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  rounded
                  startIcon={<AddIcon />}
                  onClick={handleAddingOpen}
                >
                  新增一項
                </Button>
              </Grid>
              <Grid container xs={6} alignItems="center" justifyContent="flex-end">
                <Grid>
                  <Button
                    variant="outlined"
                    rounded
                    color={isChecked ? 'error' : ''}
                    startIcon={<DeleteOutlinedIcon />}
                    onClick={() => handleDeleteClick()}
                    disabled={!isChecked}
                  >
                    刪除多筆
                  </Button>
                </Grid>
                <Grid xs={1}>
                  <IconButton
                    className={classes.iconBtnFilter}
                    aria-label="modify"
                    onClick={() => handleFilterOpen()}
                  >
                    <FilterListIcon className={classes.iconBtnFilterIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
          {isFilterOpen && (
            <StationFilter
              stationList={stationTypeList}
              station={station}
              onStationChange={onStationChange}
              handleSearch={handleSearch}
            />
          )}
        </div>
      )}
    </div>
  );
}; // MachineTableToolbar

MachineTableToolbar.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  stationList: PropTypes.array.isRequired,
  stationTypeList: PropTypes.array.isRequired,
  station: PropTypes.string.isRequired,
  onStationChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleAddMachine: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool.isRequired,
  handleIsAdding: PropTypes.func.isRequired,
};

export default MachineTableToolbar;
