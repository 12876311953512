/* eslint-disable no-unused-vars */
import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import useStyles from '../useStyles';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import ProductTypeTableBtnSets from './SettingsConfigProductTypeTableBtnSets';
import { measureStyleType } from '../../../constants/enums';
import TableContainer from '../../../components/TableContainer/TableContainer';
import Table from '../../../components/Table/Table';
import TableHead from '../../../components/TableHead/TableHead';
import TableBody from '../../../components/TableBody/TableBody';
import TableRow from '../../../components/TableRow/TableRow';
import TableCell from '../../../components/TableCell/TableCell';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';

const SettingsConfigProductRecipeTable = ({
  headCells,
  tableContentList,
  addAllList,
  onRowEditClick,
  onRowAddClick,
  onAddingAllRowAddClick,
  handleRowEditing,
  handleAddAllRowEditing,
  editedData,
  onRowDelete,
  handleEditData,
  handleAddData,
  handleAddAllData,
  isLoading,
  isEditing,
  isAdding,
  isAddingAll,
}) => {
  const { classes } = useStyles();
  const meRecipeErrRef = useRef(false);
  const meItemErrRef = useRef(false);
  const meTargetErrRef = useRef(false);
  const meUSpErrRef = useRef(false);
  const meLSpErrRef = useRef(false);
  const meULiErrRef = useRef(false);
  const meLLiErrRef = useRef(false);
  const meDevErrRef = useRef(false);

  const [meTargetErrMsg, setMeTargetErrMsg] = useState('');
  const [meUSpErrMsg, setMeUSpErrMsg] = useState('');
  const [meLSpErrMsg, setMeLSpErrMsg] = useState('');
  const [meULiErrMsg, setMeULiErrMsg] = useState('');
  const [meLLiErrMsg, setMeLLiErrMsg] = useState('');

  const { dialogDispatch } = useContext(DialogContext);
  const checkIsNotEmpty = (inputedStr) => {
    let isNotEmpty = true;
    if (!inputedStr || inputedStr === '-' || inputedStr === '' || inputedStr === undefined) {
      isNotEmpty = false;
    } else {
      isNotEmpty = true;
    }
    return isNotEmpty;
  }; // checkIsNotEmpty

  const isNumeric = (numStr) => {
    // !Number.isNaN(numStr) && !Number.isNaN(parseFloat(numStr))
    let result = true;
    if (numStr.match(/^-?\d+$/)) {
      // valid integer (positive or negative)
      result = true;
    } else if (numStr.match(/^\d+\.\d+$/)) {
      // valid float
      result = true;
    } else {
      // not valid number
      result = false;
    }
    return result;
  }; // isNumeric

  const isPositiveNum = (numStr) => {
    let result = true;
    if (parseFloat(numStr) > 0 || parseFloat(numStr) === 0) {
      result = true;
    } else {
      result = false;
    }
    return result;
  }; // isPositiveNum

  const validationTable = (targetRow) => {
    switch (targetRow.styleId) {
      // SIZE check: (not empty -> item, target, USp, LSp), (is number: target, USp, LSp, ULi, LLi, Dev), (is positive: USp, LSp, ULi, LLi)
      case measureStyleType.SIZE:
        // item: not empty
        if (checkIsNotEmpty(targetRow.measureItem) === false) {
          meItemErrRef.current = true;
        } else {
          meItemErrRef.current = false;
        }
        // target: not empty, is number
        if (checkIsNotEmpty(targetRow.measureTarget) === false) {
          meTargetErrRef.current = true;
          setMeTargetErrMsg('Target不得為空');
        } else if (isNumeric(targetRow.measureTarget) === false) {
          meTargetErrRef.current = true;
          setMeTargetErrMsg('Target須為數字');
        } else {
          meTargetErrRef.current = false;
          setMeTargetErrMsg('');
        }
        // USp: not empty, is number, is positive
        if (checkIsNotEmpty(targetRow.measureUpperSpec) === false) {
          meUSpErrRef.current = true;
          setMeUSpErrMsg('Upper Spec不得為空');
        } else if (isNumeric(targetRow.measureUpperSpec) === false) {
          meUSpErrRef.current = true;
          setMeUSpErrMsg('Upper Spec須為數字');
        } else if (isPositiveNum(targetRow.measureUpperSpec) === false) {
          meUSpErrRef.current = true;
          setMeUSpErrMsg('Upper Spec須為正數');
        } else {
          meUSpErrRef.current = false;
          setMeUSpErrMsg('');
        }
        // LSp: not empty, is number, is positive
        if (checkIsNotEmpty(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec不得為空');
        } else if (isNumeric(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec須為數字');
        } else if (isPositiveNum(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec須為正數');
        } else {
          meLSpErrRef.current = false;
          setMeLSpErrMsg('');
        }
        // ULi: is number, is positive
        if (checkIsNotEmpty(targetRow.measureUpperLimit) === true) {
          if (isNumeric(targetRow.measureUpperLimit) === false) {
            meULiErrRef.current = true;
            setMeULiErrMsg('Upper Limit須為數字');
          } else if (isPositiveNum(targetRow.measureUpperLimit) === false) {
            meULiErrRef.current = true;
            setMeULiErrMsg('Upper Limit須為正數');
          } else {
            meULiErrRef.current = false;
            setMeULiErrMsg('');
          }
        } else {
          meULiErrRef.current = false;
          setMeULiErrMsg('');
        }
        // LLi: is number, is positive
        if (checkIsNotEmpty(targetRow.measureLowerLimit) === true) {
          if (isNumeric(targetRow.measureLowerLimit) === false) {
            meLLiErrRef.current = true;
            setMeLLiErrMsg('Lower Limit須為數字');
          } else if (isPositiveNum(targetRow.measureLowerLimit) === false) {
            meLLiErrRef.current = true;
            setMeLLiErrMsg('Lower Limit須為正數');
          } else {
            meLLiErrRef.current = false;
            setMeLLiErrMsg('');
          }
        } else {
          meLLiErrRef.current = false;
          setMeLLiErrMsg('');
        }
        // Dev: is number
        if (checkIsNotEmpty(targetRow.measureDeviation) === true) {
          if (isNumeric(targetRow.measureDeviation) === false) {
            meDevErrRef.current = true;
          } else {
            meDevErrRef.current = false;
          }
        } else {
          meDevErrRef.current = false;
        }
        if (
          meItemErrRef.current === true ||
          meTargetErrRef.current === true ||
          meUSpErrRef.current === true ||
          meLSpErrRef.current === true ||
          meULiErrRef.current === true ||
          meLLiErrRef.current === true ||
          meDevErrRef.current === true
        ) {
          return false;
        }
        return true;
      // measureStyleType.SIZE

      // COIL & STAR check: (not empty -> recipe)
      case measureStyleType.COIL || measureStyleType.STAR:
        // recipe: not empty
        if (checkIsNotEmpty(targetRow.measureRecipe) === false) {
          meRecipeErrRef.current = true;
        } else {
          meRecipeErrRef.current = false;
        }
        if (meRecipeErrRef.current === true) {
          return false;
        }
        return true;
      // measureStyleType.COIL, measureStyleType.STAR;

      // DB & Z check: (not empty -> recipe, item, LSp), (is number: target, USp, LSp, ULi, LLi, Dev), (is positive: USp, LSp, ULi, LLi)
      case measureStyleType.DB || measureStyleType.Z:
        // recipe: not empty
        if (checkIsNotEmpty(targetRow.measureRecipe) === false) {
          meRecipeErrRef.current = true;
        } else {
          meRecipeErrRef.current = false;
        }
        // item: not empty
        if (checkIsNotEmpty(targetRow.measureItem) === false) {
          meItemErrRef.current = true;
        } else {
          meItemErrRef.current = false;
        }
        // target: is number
        if (checkIsNotEmpty(targetRow.measureTarget) === true) {
          if (isNumeric(targetRow.measureTarget) === false) {
            meTargetErrRef.current = true;
            setMeTargetErrMsg('Target須為數字');
          } else {
            meTargetErrRef.current = false;
            setMeTargetErrMsg('');
          }
        } else {
          meTargetErrRef.current = false;
          setMeTargetErrMsg('');
        }
        // USp: is number, is positive
        if (checkIsNotEmpty(targetRow.measureUpperSpec) === true) {
          if (isNumeric(targetRow.measureUpperSpec) === false) {
            meUSpErrRef.current = true;
            setMeUSpErrMsg('Upper Spec須為數字');
          } else if (isPositiveNum(targetRow.measureUpperSpec) === false) {
            meUSpErrRef.current = true;
            setMeUSpErrMsg('Upper Spec須為正數');
          } else {
            meUSpErrRef.current = false;
            setMeUSpErrMsg('');
          }
        } else {
          meUSpErrRef.current = false;
          setMeUSpErrMsg('');
        }
        // LSp: not empty, is number, is positive
        if (checkIsNotEmpty(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec不得為空');
        } else if (isNumeric(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec須為數字');
        } else if (isPositiveNum(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec須為正數');
        } else {
          meLSpErrRef.current = false;
          setMeLSpErrMsg('');
        }
        // ULi: is number, is positive
        if (checkIsNotEmpty(targetRow.measureUpperLimit) === true) {
          if (isNumeric(targetRow.measureUpperLimit) === false) {
            meULiErrRef.current = true;
            setMeULiErrMsg('Upper Limit須為數字');
          } else if (isPositiveNum(targetRow.measureUpperLimit) === false) {
            meULiErrRef.current = true;
            setMeULiErrMsg('Upper Limit須為正數');
          } else {
            meULiErrRef.current = false;
            setMeULiErrMsg('');
          }
        } else {
          meULiErrRef.current = false;
          setMeULiErrMsg('');
        }
        // LLi: is number, is positive
        if (checkIsNotEmpty(targetRow.measureLowerLimit) === true) {
          if (isNumeric(targetRow.measureLowerLimit) === false) {
            meLLiErrRef.current = true;
            setMeLLiErrMsg('Lower Limit須為數字');
          } else if (isPositiveNum(targetRow.measureLowerLimit) === false) {
            meLLiErrRef.current = true;
            setMeLLiErrMsg('Lower Limit須為正數');
          } else {
            meLLiErrRef.current = false;
            setMeLLiErrMsg('');
          }
        } else {
          meLLiErrRef.current = false;
          setMeLLiErrMsg('');
        }
        // Dev: is number
        if (checkIsNotEmpty(targetRow.measureDeviation) === true) {
          if (isNumeric(targetRow.measureDeviation) === false) {
            meDevErrRef.current = true;
          } else {
            meDevErrRef.current = false;
          }
        } else {
          meDevErrRef.current = false;
        }
        if (
          meRecipeErrRef.current === true ||
          meItemErrRef.current === true ||
          meTargetErrRef.current === true ||
          meUSpErrRef.current === true ||
          meLSpErrRef.current === true ||
          meULiErrRef.current === true ||
          meLLiErrRef.current === true ||
          meDevErrRef.current === true
        ) {
          return false;
        }
        return true;
      // measureStyleType.DB, measureStyleType.Z

      // L check: (not empty -> recipe, item, target, USp, LSp), (is number: target, USp, LSp, ULi, LLi, Dev), (is positive: USp, LSp, ULi, LLi)
      case measureStyleType.L:
        // recipe: not empty
        if (checkIsNotEmpty(targetRow.measureRecipe) === false) {
          meRecipeErrRef.current = true;
        } else {
          meRecipeErrRef.current = false;
        }
        // item: not empty
        if (checkIsNotEmpty(targetRow.measureItem) === false) {
          meItemErrRef.current = true;
        } else {
          meItemErrRef.current = false;
        }
        // target: not empty, is number
        if (checkIsNotEmpty(targetRow.measureTarget) === false) {
          meTargetErrRef.current = true;
          setMeTargetErrMsg('Target不得為空');
        } else if (isNumeric(targetRow.measureTarget) === false) {
          meTargetErrRef.current = true;
          setMeTargetErrMsg('Target須為數字');
        } else {
          meTargetErrRef.current = false;
          setMeTargetErrMsg('');
        }
        // USp: not empty, is number, is positive
        if (checkIsNotEmpty(targetRow.measureUpperSpec) === false) {
          meUSpErrRef.current = true;
          setMeUSpErrMsg('Upper Spec不得為空');
        } else if (isNumeric(targetRow.measureUpperSpec) === false) {
          meUSpErrRef.current = true;
          setMeUSpErrMsg('Upper Spec須為數字');
        } else if (isPositiveNum(targetRow.measureUpperSpec) === false) {
          meUSpErrRef.current = true;
          setMeUSpErrMsg('Upper Spec須為正數');
        } else {
          meUSpErrRef.current = false;
          setMeUSpErrMsg('');
        }
        // LSp: not empty, not empty, is number, is positive
        if (checkIsNotEmpty(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec不得為空');
        } else if (isNumeric(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec須為數字');
        } else if (isPositiveNum(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec須為正數');
        } else {
          meLSpErrRef.current = false;
          setMeLSpErrMsg('');
        }
        // ULi: is number, is positive
        if (checkIsNotEmpty(targetRow.measureUpperLimit) === true) {
          if (isNumeric(targetRow.measureUpperLimit) === false) {
            meULiErrRef.current = true;
            setMeULiErrMsg('Upper Limit須為數字');
          } else if (isPositiveNum(targetRow.measureUpperLimit) === false) {
            meULiErrRef.current = true;
            setMeULiErrMsg('Upper Limit須為正數');
          } else {
            meULiErrRef.current = false;
            setMeULiErrMsg('');
          }
        } else {
          meULiErrRef.current = false;
          setMeULiErrMsg('');
        }
        // LLi: is number, is positive
        if (checkIsNotEmpty(targetRow.measureLowerLimit) === true) {
          if (isNumeric(targetRow.measureLowerLimit) === false) {
            meLLiErrRef.current = true;
            setMeLLiErrMsg('Lower Limit須為數字');
          } else if (isPositiveNum(targetRow.measureLowerLimit) === false) {
            meLLiErrRef.current = true;
            setMeLLiErrMsg('Lower Limit須為正數');
          } else {
            meLLiErrRef.current = false;
            setMeLLiErrMsg('');
          }
        } else {
          meLLiErrRef.current = false;
          setMeLLiErrMsg('');
        }
        // Dev: is number
        if (checkIsNotEmpty(targetRow.measureDeviation) === true) {
          if (isNumeric(targetRow.measureDeviation) === false) {
            meDevErrRef.current = true;
          } else {
            meDevErrRef.current = false;
          }
        } else {
          meDevErrRef.current = false;
        }
        if (
          meRecipeErrRef.current === true ||
          meItemErrRef.current === true ||
          meTargetErrRef.current === true ||
          meUSpErrRef.current === true ||
          meLSpErrRef.current === true ||
          meULiErrRef.current === true ||
          meLLiErrRef.current === true ||
          meDevErrRef.current === true
        ) {
          return false;
        }
        return true;
      // measureStyleType.L

      // N check: (not empty -> recipe, item, LSp), (is number: target, USp, LSp, ULi, LLi), (is positive: USp, LSp, ULi, LLi)
      case measureStyleType.N:
        // recipe: not empty
        if (checkIsNotEmpty(targetRow.measureRecipe) === false) {
          meRecipeErrRef.current = true;
        } else {
          meRecipeErrRef.current = false;
        }
        // item: not empty
        if (checkIsNotEmpty(targetRow.measureItem) === false) {
          meItemErrRef.current = true;
        } else {
          meItemErrRef.current = false;
        }
        // target: is number
        if (checkIsNotEmpty(targetRow.measureTarget) === true) {
          if (isNumeric(targetRow.measureTarget) === false) {
            meTargetErrRef.current = true;
            setMeTargetErrMsg('Target須為數字');
          } else {
            meTargetErrRef.current = false;
            setMeTargetErrMsg('');
          }
        } else {
          meTargetErrRef.current = false;
          setMeTargetErrMsg('');
        }
        // USp: is number, is positive
        if (checkIsNotEmpty(targetRow.measureUpperSpec) === true) {
          if (isNumeric(targetRow.measureUpperSpec) === false) {
            meUSpErrRef.current = true;
            setMeUSpErrMsg('Upper Spec須為數字');
          } else if (isPositiveNum(targetRow.measureUpperSpec) === false) {
            meUSpErrRef.current = true;
            setMeUSpErrMsg('Upper Spec須為正數');
          } else {
            meUSpErrRef.current = false;
            setMeUSpErrMsg('');
          }
        } else {
          meUSpErrRef.current = false;
          setMeUSpErrMsg('');
        }
        // LSp: not empty, not empty, is number, is positive
        if (checkIsNotEmpty(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec不得為空');
        } else if (isNumeric(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec須為數字');
        } else if (isPositiveNum(targetRow.measureLowerSpec) === false) {
          meLSpErrRef.current = true;
          setMeLSpErrMsg('Lower Spec須為正數');
        } else {
          meLSpErrRef.current = false;
          setMeLSpErrMsg('');
        }
        // ULi: is number, is positive
        if (checkIsNotEmpty(targetRow.measureUpperLimit) === true) {
          if (isNumeric(targetRow.measureUpperLimit) === false) {
            meULiErrRef.current = true;
            setMeULiErrMsg('Upper Limit須為數字');
          } else if (isPositiveNum(targetRow.measureUpperLimit) === false) {
            meULiErrRef.current = true;
            setMeULiErrMsg('Upper Limit須為正數');
          } else {
            meULiErrRef.current = false;
            setMeULiErrMsg('');
          }
        } else {
          meULiErrRef.current = false;
          setMeULiErrMsg('');
        }
        // LLi: is number, is positive
        if (checkIsNotEmpty(targetRow.measureLowerLimit) === true) {
          if (isNumeric(targetRow.measureLowerLimit) === false) {
            meLLiErrRef.current = true;
            setMeLLiErrMsg('Lower Limit須為數字');
          } else if (isPositiveNum(targetRow.measureLowerLimit) === false) {
            meLLiErrRef.current = true;
            setMeLLiErrMsg('Lower Limit須為正數');
          } else {
            meLLiErrRef.current = false;
            setMeLLiErrMsg('');
          }
        } else {
          meLLiErrRef.current = false;
          setMeLLiErrMsg('');
        }
        if (
          meRecipeErrRef.current === true ||
          meItemErrRef.current === true ||
          meTargetErrRef.current === true ||
          meUSpErrRef.current === true ||
          meLSpErrRef.current === true ||
          meULiErrRef.current === true ||
          meLLiErrRef.current === true
        ) {
          return false;
        }
        return true;
      // measureStyleType.N

      default:
        return true;
    }
  }; // validationTable

  const handleEditFinished = (targetRow) => {
    if (validationTable(editedData) === false) {
      return;
    }

    if (isAdding) {
      handleAddData(targetRow);
      onRowAddClick(targetRow.measureId, false, false);
    } else {
      handleEditData(targetRow);
      onRowEditClick(targetRow.measureId, false);
    }
  }; // handleEditFinished
  const handleAddFinished = () => {
    // styleName of rows that failed
    const failedRows = [];

    const isAddAllValidate = addAllList.map((row) => {
      if (row.styleName !== 'L' && validationTable(row) === false) {
        failedRows.push(row.styleName);
        return false;
      }
      return true;
    });

    if (isAddAllValidate.filter((result) => result === false).length !== 0) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '請正確並完整填寫所有欄位！',
          message: `${failedRows} 未完成填寫或內容不符合格式！`,
        },
      });
      failedRows.length = 0;
      return;
    }
    handleAddAllData();
    failedRows.length = 0;
  }; // handleAddFinished

  const handleCancelEditing = (targetRowId) => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認離開？',
        message: '是否確認退出編輯？您所做出的更動將不會被儲存。',
        handleConfirm: () => onRowEditClick(targetRowId, false),
      },
    });
    if (isAdding) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '確認離開？',
          message: '是否確認退出新增？您所做出的更動將不會被儲存。',
          handleConfirm: () => onRowAddClick(targetRowId, false, true),
        },
      });
    }
  }; // handleCancelEditing

  const handleAddingClick = (targetRow) => {
    if (isEditing) {
      if (isAddingAll === false) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            singleBtn: true,
            title: '您尚未完成編輯!',
            message: '請先完成新增或取消編輯！',
          },
        });
        return;
      }
    }

    if (!isAddingAll) {
      // handle add row below
      // 1. find index of targetrow in tableContentList
      const index = tableContentList.indexOf(targetRow);

      // 2. add an empty list obj in after the index
      const newRow = {
        measureId: 'new_row',
        styleId: targetRow.styleId,
        measureRecipe: '',
        measureItem: '',
        measureTarget: '',
        measureLimit: '',
        measureUpperSpec: '',
        measureLowerSpec: '',
        measureDeviation: '',
        styleName: targetRow.styleName,
      };
      tableContentList.splice(index + 1, 0, newRow);

      // 3. set new_row isAdding
      onRowAddClick('new_row', true, false);
    } // !isAddingAll
    else {
      // handle add row below
      // 1. find styleId of target row
      const index = addAllList.indexOf(targetRow);
      // 2. add an empty list obj in after target row
      const newRow = {
        rowId: -1,
        styleId: targetRow.styleId,
        measureRecipe: '',
        measureItem: '',
        measureTarget: '',
        measureLimit: '',
        measureUpperSpec: '',
        measureLowerSpec: '',
        measureDeviation: '',
        styleName: targetRow.styleName,
      };
      newRow.isOld = true;
      addAllList.forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        if (el.styleId === targetRow.styleId && el.rowId !== -1) el.isOld = false;
      });
      addAllList.splice(index, 0, newRow);
      // 3. set newRow isAdding
      onAddingAllRowAddClick(addAllList);
    } // isAddingAll
  }; // handleAddingClick

  const handleEditingClick = (targetRow) => {
    if (isAdding) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '您尚未完成新增!',
          message: '請先完成新增或取消新增！',
        },
      });
      return;
    }
    const isEditingRow = tableContentList.find((row) => row.isEditing);
    if (isEditingRow) {
      handleCancelEditing(isEditingRow.measureId);
    } else if (targetRow.measureId === undefined) {
      onRowEditClick(targetRow.styleId, true);
    } else {
      onRowEditClick(targetRow.measureId, true);
    }
  }; // handleEditingClick

  const handleDeletingClick = (targetRow) => {
    onRowDelete(targetRow);
  }; // handleDeletingClick
  const handleDefaultDeleteClick = (rowId) => {
    // use in when isAddingAll is true
    onAddingAllRowAddClick(addAllList.filter((el) => el.rowId !== rowId));
  };
  const handleIsAddAllAddBtnShow = (styleId) => {
    if (styleId === measureStyleType.STAR) {
      return true;
    }
    return false;
  };
  return (
    <TableContainer isLoading={isLoading}>
      <Table>
        <TableHead headCells={headCells} style={{ whiteSpace: 'nowrap' }} />
        {isAddingAll ? (
          <TableBody>
            {addAllList.map((row, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index} style={{ height: 108 }}>
                <TableCell align="left" padding="none" style={{ paddingLeft: 18 }}>
                  {row.styleName}
                </TableCell>
                <TableCell align="left" padding="none" style={{ whiteSpace: 'pre-wrap' }}>
                  {row.styleId !== measureStyleType.SIZE && (
                    <InputField
                      className={classes.table_input}
                      id="measureRecipe"
                      label="量測Recipe"
                      value={row.measureRecipe}
                      variant="outlined"
                      onChange={(e) => handleAddAllRowEditing(e, row.rowId)}
                      placeholder="量測Recipe"
                      name="measureRecipe"
                      type="text"
                    />
                  )}
                </TableCell>
                <TableCell align="left" padding="none">
                  {row.styleId !== measureStyleType.COIL &&
                    row.styleId !== measureStyleType.STAR && (
                      <InputField
                        className={classes.table_input}
                        id="measureItem"
                        label="量測項目"
                        value={row.measureItem}
                        variant="outlined"
                        onChange={(e) => handleAddAllRowEditing(e, row.rowId)}
                        placeholder="量測項目"
                        name="measureItem"
                        type="text"
                      />
                    )}
                </TableCell>
                <TableCell align="left" padding="none">
                  {row.styleId !== measureStyleType.COIL &&
                    row.styleId !== measureStyleType.STAR && (
                      <InputField
                        className={classes.table_input}
                        id="measureTarget"
                        label="Target"
                        value={row.measureTarget}
                        variant="outlined"
                        onChange={(e) => handleAddAllRowEditing(e, row.rowId)}
                        placeholder="Target"
                        name="measureTarget"
                        type="text"
                      />
                    )}
                </TableCell>
                <TableCell align="left" padding="none">
                  {row.styleId !== measureStyleType.COIL &&
                    row.styleId !== measureStyleType.STAR && (
                      <InputField
                        className={classes.table_input}
                        id="measureUpperSpec"
                        label="Upper Spec"
                        value={row.measureUpperSpec}
                        variant="outlined"
                        onChange={(e) => handleAddAllRowEditing(e, row.rowId)}
                        placeholder="Upper Spec"
                        name="measureUpperSpec"
                        type="text"
                      />
                    )}
                </TableCell>
                <TableCell align="left" padding="none">
                  {row.styleId !== measureStyleType.COIL &&
                    row.styleId !== measureStyleType.STAR && (
                      <InputField
                        className={classes.table_input}
                        id="measureLowerSpec"
                        label="Lower Spec"
                        value={row.measureLowerSpec}
                        variant="outlined"
                        onChange={(e) => handleAddAllRowEditing(e, row.rowId)}
                        placeholder="Lower Spec"
                        name="measureLowerSpec"
                        type="text"
                      />
                    )}
                </TableCell>
                <TableCell align="left" padding="none">
                  {row.styleId !== measureStyleType.COIL &&
                    row.styleId !== measureStyleType.STAR && (
                      <InputField
                        className={classes.table_input}
                        id="measureUpperLimit"
                        label="Upper Limit"
                        value={row.measureUpperLimit}
                        variant="outlined"
                        onChange={(e) => handleAddAllRowEditing(e, row.rowId)}
                        placeholder="Upper Limit"
                        name="measureUpperLimit"
                        type="text"
                      />
                    )}
                </TableCell>
                <TableCell align="left" padding="none">
                  {row.styleId !== measureStyleType.COIL &&
                    row.styleId !== measureStyleType.STAR && (
                      <InputField
                        className={classes.table_input}
                        id="measureLowerLimit"
                        label="Lower Limit"
                        value={row.measureLowerLimit}
                        variant="outlined"
                        onChange={(e) => handleAddAllRowEditing(e, row.rowId)}
                        placeholder="Lower Limit"
                        name="measureLowerLimit"
                        type="text"
                      />
                    )}
                </TableCell>
                <TableCell align="left" padding="none">
                  {row.styleId !== measureStyleType.N &&
                    row.styleId !== measureStyleType.STAR &&
                    row.styleId !== measureStyleType.COIL && (
                      <InputField
                        className={classes.table_input}
                        id="measureDeviation"
                        label="量測偏差"
                        value={row.measureDeviation}
                        variant="outlined"
                        onChange={(e) => handleAddAllRowEditing(e, row.rowId)}
                        placeholder="量測偏差"
                        name="measureDeviation"
                        type="text"
                      />
                    )}
                </TableCell>
                <TableCell align="right" className={classes.table_input}>
                  <ProductTypeTableBtnSets
                    handleAdd={() => handleAddingClick(row)}
                    handleEdit={() => handleEditingClick(row)}
                    handleDelete={() => handleDefaultDeleteClick(row.rowId)}
                    isAdding={isAdding}
                    isEditing={isEditing}
                    isAddBtnShow
                    isEditBtnShow={false}
                    isDeleteBtnShow={false}
                    isAddingAll
                    isStyleStar={handleIsAddAllAddBtnShow(row.styleId)}
                    isDefaultRow={row.isOld}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            {tableContentList.map((row) => (
              <TableRow tabIndex={-1} key={row.measureId}>
                <TableCell
                  align="left"
                  padding="none"
                  className={classes.product_table_cell}
                  style={{ paddingLeft: 18 }}
                >
                  {row.styleName}
                </TableCell>

                {(row.isEditing || row.isAdding) && row.styleId !== measureStyleType.SIZE ? (
                  <TableCell
                    align="left"
                    padding="none"
                    className={classes.product_table_cell}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    <InputField
                      className={classes.table_input}
                      id="measureRecipe"
                      label="量測Recipe"
                      value={editedData.measureRecipe}
                      variant="outlined"
                      onChange={handleRowEditing}
                      placeholder="量測Recipe"
                      name="measureRecipe"
                      type="text"
                      error={meRecipeErrRef.current}
                      errormsg="量測Recipe不得為空"
                    />
                  </TableCell>
                ) : (
                  <TableCell align="left" className={classes.product_table_cell}>
                    {row.measureRecipe}
                  </TableCell>
                )}
                {(row.isEditing || row.isAdding) &&
                row.styleId !== measureStyleType.COIL &&
                row.styleId !== measureStyleType.STAR ? (
                  <TableCell align="left" padding="none" className={classes.product_table_cell}>
                    <InputField
                      className={classes.table_input}
                      id="measureItem"
                      label="量測項目"
                      value={editedData.measureItem}
                      variant="outlined"
                      onChange={handleRowEditing}
                      placeholder="量測項目"
                      name="measureItem"
                      type="text"
                      error={meItemErrRef.current}
                      errormsg="量測項目不得為空"
                    />
                  </TableCell>
                ) : (
                  <TableCell align="left" className={classes.product_table_cell}>
                    {row.measureItem}
                  </TableCell>
                )}
                {(row.isEditing || row.isAdding) &&
                row.styleId !== measureStyleType.COIL &&
                row.styleId !== measureStyleType.STAR ? (
                  <TableCell align="left" padding="none" className={classes.product_table_cell}>
                    <InputField
                      className={classes.table_input}
                      id="measureTarget"
                      label="Target"
                      value={editedData.measureTarget}
                      variant="outlined"
                      onChange={handleRowEditing}
                      placeholder="Target"
                      name="measureTarget"
                      type="text"
                      error={meTargetErrRef.current}
                      errormsg={meTargetErrMsg}
                    />
                  </TableCell>
                ) : (
                  <TableCell align="left" className={classes.product_table_cell}>
                    {row.measureTarget}
                  </TableCell>
                )}
                {(row.isEditing || row.isAdding) &&
                row.styleId !== measureStyleType.COIL &&
                row.styleId !== measureStyleType.STAR ? (
                  <TableCell align="left" padding="none" className={classes.product_table_cell}>
                    <InputField
                      className={classes.table_input}
                      id="measureUpperSpec"
                      label="Upper Spec"
                      value={editedData.measureUpperSpec}
                      variant="outlined"
                      onChange={handleRowEditing}
                      placeholder="Upper Spec"
                      name="measureUpperSpec"
                      type="text"
                      error={meUSpErrRef.current}
                      errormsg={meUSpErrMsg}
                    />
                  </TableCell>
                ) : (
                  <TableCell align="left" className={classes.product_table_cell}>
                    {row.measureUpperSpec}
                  </TableCell>
                )}
                {(row.isEditing || row.isAdding) &&
                row.styleId !== measureStyleType.COIL &&
                row.styleId !== measureStyleType.STAR ? (
                  <TableCell align="left" padding="none" className={classes.product_table_cell}>
                    <InputField
                      className={classes.table_input}
                      id="measureLowerSpec"
                      label="Lower Spec"
                      value={editedData.measureLowerSpec}
                      variant="outlined"
                      onChange={handleRowEditing}
                      placeholder="Lower Spec"
                      name="measureLowerSpec"
                      type="text"
                      error={meLSpErrRef.current}
                      errormsg={meLSpErrMsg}
                    />
                  </TableCell>
                ) : (
                  <TableCell align="left" className={classes.product_table_cell}>
                    {row.measureLowerSpec}
                  </TableCell>
                )}
                {(row.isEditing || row.isAdding) &&
                row.styleId !== measureStyleType.COIL &&
                row.styleId !== measureStyleType.STAR ? (
                  <TableCell align="left" padding="none" className={classes.product_table_cell}>
                    <InputField
                      className={classes.table_input}
                      id="measureUpperLimit"
                      label="Upper Limit"
                      value={editedData.measureUpperLimit}
                      variant="outlined"
                      onChange={handleRowEditing}
                      placeholder="Upper Limit"
                      name="measureUpperLimit"
                      type="text"
                      error={meULiErrRef.current}
                      errormsg={meULiErrMsg}
                    />
                  </TableCell>
                ) : (
                  <TableCell align="left" className={classes.product_table_cell}>
                    {row.measureUpperLimit}
                  </TableCell>
                )}
                {(row.isEditing || row.isAdding) &&
                row.styleId !== measureStyleType.COIL &&
                row.styleId !== measureStyleType.STAR ? (
                  <TableCell align="left" padding="none" className={classes.product_table_cell}>
                    <InputField
                      className={classes.table_input}
                      id="measureLowerLimit"
                      label="Lower Limit"
                      value={editedData.measureLowerLimit}
                      variant="outlined"
                      onChange={handleRowEditing}
                      placeholder="Lower Limit"
                      name="measureLowerLimit"
                      type="text"
                      error={meLLiErrRef.current}
                      errormsg={meLLiErrMsg}
                    />
                  </TableCell>
                ) : (
                  <TableCell align="left" className={classes.product_table_cell}>
                    {row.measureLowerLimit}
                  </TableCell>
                )}
                {(row.isEditing || row.isAdding) &&
                row.styleId !== measureStyleType.N &&
                row.styleId !== measureStyleType.COIL &&
                row.styleId !== measureStyleType.STAR ? (
                  <TableCell align="left" padding="none" className={classes.product_table_cell}>
                    <InputField
                      className={classes.table_input}
                      id="measureDeviation"
                      label="量測偏差"
                      value={editedData.measureDeviation}
                      variant="outlined"
                      onChange={handleRowEditing}
                      placeholder="量測偏差"
                      name="measureDeviation"
                      type="text"
                      error={meDevErrRef.current}
                      errormsg="量測偏差須為數字"
                    />
                  </TableCell>
                ) : (
                  <TableCell align="left" className={classes.product_table_cell}>
                    {row.measureDeviation}
                  </TableCell>
                )}

                {row.isEditing || row.isAdding ? (
                  <TableCell align="right" padding="none">
                    <Button
                      style={{ marginTop: 6, marginBottom: 3 }}
                      variant="outlined"
                      color="secondary"
                      rounded
                      onClick={() => handleEditFinished(row)}
                    >
                      儲存
                    </Button>
                    <Button
                      style={{ marginTop: 3, marginBottom: 6 }}
                      variant="outlined"
                      color="error"
                      rounded
                      onClick={() =>
                        handleCancelEditing(row.measureId ? row.measureId : row.styleId)
                      }
                    >
                      取消
                    </Button>
                  </TableCell>
                ) : (
                  <TableCell padding="none" align="right" className={classes.table_btn_set}>
                    <ProductTypeTableBtnSets
                      handleAdd={() => handleAddingClick(row)}
                      handleEdit={() => handleEditingClick(row)}
                      handleDelete={() => handleDeletingClick(row)}
                      isAdding={isAdding}
                      isEditing={isEditing}
                      isAddBtnShow={row.isAddBtnShow}
                      isDeleteBtnShow={row.isDeleteBtnShow}
                      isAddingAll={false}
                      isLEmpty={!!(row.styleName === 'L' && row.measureTarget === '-')}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {isAddingAll && (
        <Grid
          container
          style={{ marginTop: 25, marginBottom: 20, marginRight: 0 }}
          spacing={0}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              rounded
              startIcon={<CheckIcon />}
              onClick={() => handleAddFinished()}
              style={{ width: 122 }}
            >
              儲存
            </Button>
          </Grid>
        </Grid>
      )}
    </TableContainer>
  );
};

SettingsConfigProductRecipeTable.defaultProps = {
  onRowDelete: null,
  isAdding: false,
  isAddingAll: false,
};

SettingsConfigProductRecipeTable.propTypes = {
  headCells: PropTypes.arrayOf.isRequired,
  tableContentList: PropTypes.arrayOf.isRequired,
  addAllList: PropTypes.arrayOf.isRequired,
  onRowEditClick: PropTypes.func.isRequired,
  onRowAddClick: PropTypes.func.isRequired,
  onAddingAllRowAddClick: PropTypes.func.isRequired,
  handleRowEditing: PropTypes.func.isRequired,
  handleAddAllRowEditing: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  editedData: PropTypes.objectOf(PropTypes.object).isRequired,
  onRowDelete: PropTypes.func,
  handleEditData: PropTypes.func.isRequired,
  handleAddData: PropTypes.func.isRequired,
  handleAddAllData: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool,
  isAddingAll: PropTypes.bool,
};

export default SettingsConfigProductRecipeTable;
