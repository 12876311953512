/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Typography from '../../../../components/Typography/Typography';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Divider from '../../../../components/Divider/Divider';
import useStyles from '../useStyles';
import useCommonStyles from '../../useStyles';

const ScOdFormDataRow = ({ rowData }) => {
  const {
    transId,
    preScOd,
    initDate,
    updateDate,
    transQty,
    bucketNo,
    formingMachine,
    formingUser,
    standardQty,
    efficiency,
    theFormHours,
  } = rowData;
  const commonClasses = useCommonStyles().classes;
  const { classes } = useStyles();

  return (
    <PaperContainer className={`${commonClasses.container} ${classes.scOd_container}`}>
      <div className={classes.scOd_trans}>
        <div className={classes.scOd_trans_row}>
          <Typography variant="subtitle1">移轉單資訊</Typography>
          <Typography variant="subtitle2">壓型移轉單號</Typography>
          {preScOd && <Typography variant="subtitle2">原始工令單號</Typography>}
          <Typography variant="subtitle2">建立日期</Typography>
          <Typography variant="subtitle2">更新日期</Typography>
          <Typography variant="subtitle2">移轉數量</Typography>
          <Typography variant="subtitle2">標準產能</Typography>
          <Typography variant="subtitle2">效率</Typography>
        </div>
        <div className={classes.scOd_trans_row}>
          <p />
          <Typography variant="body1">{transId}</Typography>
          {preScOd && <Typography variant="body1">{preScOd}</Typography>}
          <Typography variant="body1">
            {initDate ? `${format(new Date(initDate), 'yyyy.MM.dd HH:mm:ss')}` : '-'}
          </Typography>
          <Typography variant="body1">
            {updateDate ? `${format(new Date(updateDate), 'yyyy.MM.dd HH:mm:ss')}` : '-'}
          </Typography>
          <Typography variant="body1">{transQty}</Typography>
          <Typography variant="body1">{standardQty}</Typography>
          <Typography variant="body1">
            {efficiency && efficiency !== 'NaN' ? `${efficiency}%` : '-'}
          </Typography>
        </div>
      </div>
      <Divider
        className={commonClasses.dashed_divider}
        style={{ marginTop: 10, marginBottom: 16 }}
      />
      <div className={classes.scOd_manuInfo}>
        <div className={classes.scOd_manuInfo_row}>
          <Typography variant="subtitle1">製造資訊</Typography>
          <Typography variant="subtitle2">粉料桶號</Typography>
          <Typography variant="subtitle2">壓型機台</Typography>
          <Typography variant="subtitle2">壓型人員</Typography>
        </div>
        <div className={classes.scOd_manuInfo_row}>
          <p />
          <Typography variant="body1">{bucketNo || '-'}</Typography>
          <Typography variant="body1">{formingMachine || '-'}</Typography>
          <Typography variant="body1">{formingUser || '-'}</Typography>
        </div>
      </div>
      <Divider
        className={commonClasses.dashed_divider}
        style={{ marginTop: 10, marginBottom: 16 }}
      />
      <div className={classes.scOd_period}>
        <div className={classes.scOd_period_row}>
          <Typography variant="subtitle1">該站停留時間</Typography>
          <Typography variant="subtitle2">壓型</Typography>
        </div>
        <div className={classes.scOd_period_row}>
          <p />
          <Typography variant="body1">{theFormHours ? `${theFormHours} hr` : '-'}</Typography>
        </div>
      </div>
    </PaperContainer>
  );
};

ScOdFormDataRow.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default ScOdFormDataRow;
