/* eslint-disable no-unused-vars */
import React, { useState, useReducer, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import useAxios from '../hooks/useAxios';
import { DialogContext } from '../contexts/DialogContext';
import { dialogActions } from '../reducers/dialogReducer';
import regex from '../constants/validationRegex';
import useCancelToken from '../hooks/useCancelToken';
import PageTitle from '../components/PageTitle/PageTitle';
import Typography from '../components/Typography/Typography';
import Grid from '../components/Grid/Grid';
import PaperContainer from '../components/PaperContainer/PaperContainer';
import InputField from '../components/InputField/InputField';
import ReadonlyField from '../components/ReadonlyField/ReadonlyField';
import Button from '../components/Button/Button';
import Chip from '../components/Chip/Chip';

const PackageActions = {
  CREATE: 'CREATE',
  FETCH: 'FETCH',
};

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(1.5),
  },
  header: {
    marginTop: theme.spacing(2.5),
  },
  headerBtn: {
    marginRight: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(4),
  },
  chips: {
    marginTop: theme.spacing(1.2),
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(2.5),
  },
  field_left: {
    width: 'calc(100% - 80px)',
    marginRight: 80,
    maxWidth: 330,
  },
  field_right: {
    width: 'calc((100% - 80px) / 2)',
    maxWidth: 330,
    marginBottom: theme.spacing(3),
    '&:not(:nth-of-type(2n))': {
      marginRight: 80,
    },
  },
  confirm: {
    marginTop: theme.spacing(3),
  },
}));

const qtyReducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return state + action.qty;
    case 'RESET':
      return 0;
    default:
      throw Error('Invalid action');
  }
};

const PackageCreate = () => {
  const { dialogDispatch } = useContext(DialogContext);
  const [packageAction, setPackageAction] = useState(PackageActions.CREATE);
  const [packageId, setPackageId] = useState('');
  const [packageIdErr, setPackageIdErr] = useState(false);
  const [transIds, setTransIds] = useState('');
  const transIdsRef = useRef('');
  const [transIdsErr, setTransIdsErr] = useState(false);
  const [transIdsErrMsg, setTransIdsErrMsg] = useState('請輸入正確的研磨移轉單號，並不得輸入中文');
  const [transArr, setTransArr] = useState([]);
  const transDataArr = useRef([]);
  const latestTransIds = useRef(null);
  const [transQty, transDispatch] = useReducer(qtyReducer, 0);
  const [createQty, createDispatch] = useReducer(qtyReducer, 0);
  const [transInfo, setTransInfo] = useState(null);
  const [showTransInfo, setShowTransInfo] = useState(false);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { newCancelToken } = useCancelToken();

  const hasDuplicates = (arr) => arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));

  const toggleAction = (action) => {
    setPackageAction(action);
    setTransIds('');
    transIdsRef.current = '';
    createDispatch({ type: 'RESET' });
    transDispatch({ type: 'RESET' });
  };

  const resetCurrentTransIds = (transIdsArr) => {
    transIdsArr.pop();
    if (transIdsArr.length === 0) {
      setTransIds('');
      transIdsRef.current = '';
      return;
    }
    setTransIds(`${transIdsArr.toString()},`);
    transIdsRef.current = `${transIdsArr.toString()},`;
  };

  const calcQty = () => {
    createDispatch({ type: 'RESET' });
    transDispatch({ type: 'RESET' });
    transDataArr.current.forEach((trans) => {
      createDispatch({ type: 'ADD', qty: trans.createQuantity });
      transDispatch({ type: 'ADD', qty: trans.transQuantity });
    });
  };

  const handleDelete = (transId) => {
    setTransIds(`${transArr.filter((item) => transId !== item).toString()},`);
    transIdsRef.current = `${transArr.filter((item) => transId !== item).toString()},`;
    setTransArr((prev) => prev.filter((item) => transId !== item));
    transDataArr.current = transDataArr.current.filter((item) => item.transId !== transId);
    calcQty();
  };

  const validationTransId = (transId) => regex.GRINDER_TRANS_ID.test(transId);
  const validationCurrentTransIds = () =>
    transIdsRef.current
      .split(',')
      .filter((x) => x)
      .every((transId) => validationTransId(transId));

  const validationTransForm = () => {
    if (transIdsRef.current.length === 0) {
      setTransIdsErr(true);
      setTransIdsErrMsg('請輸入至少一個研磨移轉單號！');
      return false;
    }
    if (!transInfo) {
      setTransIdsErr(true);
      setTransIdsErrMsg('找不到移轉單的資料，請輸入正確的移轉單號！');
      return false;
    }
    return validationCurrentTransIds();
  };

  const handleFetch = () => {
    if (!validationTransForm()) {
      setTransIdsErr(true);
      return;
    }
    setShowTransInfo(true);
  };

  const isSameScOd = (scOdno) => {
    if (transInfo) {
      if (transInfo.workId !== scOdno) return false;
    }
    return true;
  };

  const fetchTransData = async (trans) => {
    if (!validationCurrentTransIds()) {
      setTransIdsErr(true);
      setTransIdsErrMsg('請輸入正確的研磨移轉單號！');
      return;
    }
    const lastTransId = trans[trans.length - 1];
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getPackageTrans',
      { transId: lastTransId },
      true,
      cancelToken
    );
    if (status) {
      const {
        transfer: {
          transId,
          scOdno,
          elNo,
          materialName,
          prodName,
          createQuantity,
          transQuantity,
          scFdat,
          odRedat,
          customerAlias,
        },
        transfer,
      } = result;
      if (!transfer || !isSameScOd(scOdno)) {
        setTransIdsErr(true);
        setTransIdsErrMsg('請輸入相同工令的移轉單號！');
        resetCurrentTransIds(trans, true);
        return;
      }
      setTransArr(trans);
      setTransInfo({
        transId,
        elNo,
        materialName,
        workId: scOdno,
        customer: customerAlias,
        productName: prodName,
        scFDate: scFdat,
        odReDate: odRedat,
      });
      transDataArr.current = transDataArr.current.filter((item) => trans.includes(item.transId)); // Update transDataArr content with trans first then push new transData api result
      transDataArr.current = [
        ...transDataArr.current.filter((item) => item.transId !== transId),
        {
          transId,
          transQuantity: transQuantity || 0,
          createQuantity: createQuantity || 0,
        },
      ];
      calcQty();
    } else {
      setTransIdsErr(true);
      setTransIdsErrMsg(noPrivilegeMsg || `${result}`);
      resetCurrentTransIds(trans, true);
    }
  };

  const validationConfirm = () => {
    if (!transInfo || transArr.length === 0) {
      setTransIdsErr(true);
      setTransIdsErrMsg('請正確掃描移轉單barcode，或請確認正確輸入移轉單號並以逗號分隔與結尾！');
      return false;
    }
    return true;
  };

  const handleConfirm = async () => {
    if (!validationConfirm()) return;
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/createPackage',
      { transfer: transArr.map((item) => ({ transId: item })) },
      true,
      cancelToken
    );
    if (status) {
      const { fileUrl, checkId } = result;
      window.open(fileUrl, '_blank');
      setTransArr([]);
      transDataArr.current = [];
      navigate(`/package-update-testing/${checkId}`);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '建立檢裝單失敗！',
          message: noPrivilegeMsg || result,
        },
      });
    }
  };

  const handleTransChange = () => {
    if (latestTransIds.current.includes(',') || latestTransIds.current.length % 11 === 0) {
      const trans = latestTransIds.current.split(',').filter((x) => x);
      if (!trans.every((item) => item.length === 10)) {
        setTransIdsErr(true);
        setTransIdsErrMsg('請輸入正確的研磨移轉單號！');
        return;
      }
      if (hasDuplicates(trans)) {
        resetCurrentTransIds(trans);
        return;
      }
      if (!validationTransId(trans[trans.length - 1])) {
        setTransIdsErr(true);
        setTransIdsErrMsg('請輸入正確的研磨移轉單號！');
        resetCurrentTransIds(trans);
        return;
      }
      fetchTransData(trans);
    }
  };

  const handleTransIdChange = (e) => {
    setTransIdsErr(false);
    if (e.target.value) {
      if (e.target.value.includes(';;;;;')) {
        const currentTransId = e.target.value.replaceAll(';;;;;', ',');
        setTransIds(currentTransId);
        transIdsRef.current = currentTransId;
        latestTransIds.current = currentTransId;
        handleTransChange();
        return;
      }
    }
    if (
      !e.target.value.includes(';') &&
      e.target.value.length > 0 &&
      e.target.value.length % 11 === 0
    ) {
      if (e.target.value.length > transIdsRef.current.length) {
        latestTransIds.current = `${transIds},`;
        setTransIds((prev) => `${prev},`);
        transIdsRef.current = `${transIds},`;
      } else {
        setTransIds(e.target.value.trim());
        transIdsRef.current = e.target.value.trim();
        latestTransIds.current = e.target.value.trim();
      }
      handleTransChange();
      return;
    }
    setTransIds(e.target.value.trim());
    transIdsRef.current = e.target.value.trim();
  };

  const handlePackageIdChange = (e) => {
    if (packageIdErr) {
      setPackageIdErr(false);
    }
    setPackageId(e.target.value);
  };

  const validationPackageId = () => {
    if (packageId.length === 0) {
      setPackageIdErr(true);
      return false;
    }
    if (!regex.PACKAGING_TRANS_ID.test(packageId) && !regex.TESTING_TRANS_ID.test(packageId)) {
      setPackageIdErr(true);
      return false;
    }
    return true;
  };

  const handleDownload = async () => {
    if (!validationPackageId()) return;
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getPackagePdf',
      { packageId },
      true,
      cancelToken
    );
    if (status) {
      const { fileUrl } = result;
      window.open(fileUrl, '_blank');
      setPackageId('');
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '下載檢裝單失敗！',
          message: noPrivilegeMsg || result,
        },
      });
    }
  };

  useEffect(() => {
    if (transArr.length === 0) {
      setTransIds('');
      transIdsRef.current = '';
      setShowTransInfo(false);
      setTransInfo(null);
    }
    calcQty();
  }, [transArr.length]);

  useEffect(() => {
    if (transIdsRef.current.length === 0) {
      setTransArr([]);
      transDataArr.current = [];
      setShowTransInfo(false);
      setTransInfo(null);
    }
  }, [transIds]);

  useEffect(() => {
    if (transInfo) {
      setShowTransInfo(true);
    } else {
      setTransInfo(false);
    }
  }, [transInfo]);

  return (
    <Grid container>
      <PageTitle title="建立檢裝單" />
      <PaperContainer className={classes.container}>
        <Typography variant="h6">輸入移轉單號</Typography>
        <Grid container alignItems="flex-start" spacing={2} className={classes.header}>
          <Typography variant="body1" style={{ margin: '2.5px 8px 0' }}>
            新建檢裝單或重新下載？
          </Typography>
          <Button
            variant="text"
            color={packageAction === PackageActions.CREATE ? 'primary' : 'grey'}
            onClick={() => toggleAction(PackageActions.CREATE)}
            customClasses={classes.headerBtn}
          >
            建立新檢裝單
          </Button>
          <Button
            variant="text"
            color={packageAction === PackageActions.FETCH ? 'primary' : 'grey'}
            onClick={() => toggleAction(PackageActions.FETCH)}
          >
            下載已建立檢裝單
          </Button>
        </Grid>
        {packageAction === PackageActions.CREATE && (
          <>
            <Grid container spacing={2} className={classes.content} alignItems="flex-end">
              <Grid item xs={5}>
                <InputField
                  id="transId"
                  name="transId"
                  label="移轉單號"
                  placeholder="請輸入移轉單號 e.g. TI20000001"
                  type="text"
                  error={transIdsErr}
                  errormsg={transIdsErrMsg || '請輸入正確的研磨移轉單號！'}
                  value={transIdsRef.current}
                  onChange={handleTransIdChange}
                />
              </Grid>
              <Grid item xs={2}>
                <ReadonlyField
                  name="transQty"
                  value={String(transQty)}
                  label="累計移轉數量"
                  className={transIdsErr ? classes.error : 'false'}
                />
              </Grid>
              <Grid item xs={2}>
                <ReadonlyField
                  name="productQty"
                  value={String(createQty)}
                  label="累計匣數"
                  className={transIdsErr ? classes.error : 'false'}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CheckRoundedIcon />}
                  onClick={handleConfirm}
                  customClasses={transIdsErr && classes.error}
                >
                  確認
                </Button>
              </Grid>
            </Grid>
            <Grid container className={classes.chips}>
              <Grid item xs={5}>
                {transArr.map((transId) => (
                  <Chip
                    key={transId}
                    label={transId}
                    onDelete={() => handleDelete(transId)}
                    color="primary"
                    variant="outlined"
                    className={classes.chip}
                  />
                ))}
              </Grid>
            </Grid>
          </>
        )}
        {packageAction === PackageActions.FETCH && (
          <Grid container alignItems="flex-end" spacing={2} className={classes.content}>
            <Grid item xs={5}>
              <InputField
                type="text"
                id="transId"
                name="transId"
                placeholder="請輸入檢裝單號"
                label="檢裝單號"
                value={packageId}
                errormsg="請正確填寫檢裝單號"
                onChange={handlePackageIdChange}
                error={packageIdErr}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppRoundedIcon />}
                onClick={handleDownload}
                customClasses={packageIdErr && classes.error}
              >
                下載
              </Button>
            </Grid>
          </Grid>
        )}
      </PaperContainer>
      {showTransInfo && (
        <>
          <PaperContainer className={classes.container}>
            <Typography variant="h6">工令資訊</Typography>
            <Grid container className={classes.content}>
              <Grid item xs={4} container>
                <Grid item className={classes.field_left}>
                  <ReadonlyField label="工令單號" name="workId" value={transInfo.workId} />
                </Grid>
              </Grid>
              <Grid item xs={8} container>
                <Grid item className={classes.field_right}>
                  <ReadonlyField label="料號" name="elNo" value={transInfo.elNo} />
                </Grid>
                <Grid item className={classes.field_right}>
                  <ReadonlyField label="客戶" name="customer" value={transInfo.customer} />
                </Grid>
                <Grid item className={classes.field_right}>
                  <ReadonlyField label="材質" name="materialName" value={transInfo.materialName} />
                </Grid>
                <Grid item className={classes.field_right}>
                  <ReadonlyField
                    label="產品名稱"
                    name="productName"
                    value={transInfo.productName}
                  />
                </Grid>
                <Grid item className={classes.field_right}>
                  <ReadonlyField label="開工日期" name="scFdate" value={transInfo.scFDate} />
                </Grid>
                <Grid item className={classes.field_right}>
                  <ReadonlyField label="出貨日期" name="odReDate" value={transInfo.odReDate} />
                </Grid>
              </Grid>
            </Grid>
          </PaperContainer>
          {/* <Grid item xs={12} container justifyContent="flex-end" className={classes.confirm}>
            <Button
              variant="contained"
              color="primary"
              rounded
              startIcon={<CheckRoundedIcon />}
              onClick={handleConfirm}
            >
              確認
            </Button>
          </Grid> */}
        </>
      )}
    </Grid>
  );
};

export default PackageCreate;
