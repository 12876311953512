/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '../ListItemText/ListItemText';
import useStyles from './useStyles';

const SelectMultiField = ({
  error,
  value,
  handleChange,
  selections,
  placeholder,
  label,
  errormsg,
  id,
  selectedList,
  ...rest
}) => {
  // eslint-disable-next-line prettier/prettier

  const { classes } = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth {...rest}>
      <InputLabel id={id} shrink className={classes.label}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        id="demo-simple-select-outlined"
        value={value}
        onChange={handleChange}
        label={label}
        error={error}
        className={classes.select}
        name={id}
        multiple
        displayEmpty
        renderValue={(selected) =>
          selected.length !== 0 ? selected.join(', ') : <>{placeholder}</>
        }
      >
        <MenuItem value="0" disabled>
          {placeholder}
        </MenuItem>
        {selections &&
          selections.map((item) => (
            <MenuItem key={item.name} value={item.name}>
              <Checkbox color="secondary" checked={selectedList.indexOf(item.name) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
      </Select>
      {error && <FormHelperText className={classes.error}>{errormsg}</FormHelperText>}
    </FormControl>
  );
};

SelectMultiField.defaultProps = {
  error: false,
  errormsg: '請選擇欄位',
  selectedList: [],
};

SelectMultiField.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errormsg: PropTypes.string,
  selectedList: PropTypes.array,
};

export default SelectMultiField;
