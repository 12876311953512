/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import MuiDialogActions from '@mui/material/DialogActions';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Button from '../Button/Button';

const DialogActions = ({ className, style, children, ...rest }) => (
  <MuiDialogActions className={className} style={style}>
    {children !== '關閉' ? (
      <Button {...rest} variant="contained" rounded startIcon={<CheckRoundedIcon />}>
        {children}
      </Button>
    ) : (
      <Button {...rest} variant="outlined">
        {children}
      </Button>
    )}
  </MuiDialogActions>
);

DialogActions.defaultProps = {
  className: '',
  style: {},
  children: '',
};

DialogActions.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.string,
};

export default DialogActions;
