import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
import { amber, red } from '@mui/material/colors';
import Typography from '../Typography/Typography';
import Paper from '../Paper/Paper';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 0,
  },
  error: {
    border: `1px solid ${red[700]}`,
    backgroundColor: alpha(theme.palette.error.light, 0.3),
    color: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.secondary.light,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  warning: {
    backgroundColor: amber[500],
    border: `1px solid ${amber[700]}`,
  },
  msg: {
    marginLeft: theme.spacing(2),
  },
}));

const Snackbar = ({ msg, type, className, open }) => {
  const { classes } = useStyles();

  let icon;
  let bgColor;
  switch (type) {
    case 'error':
      icon = <CancelOutlinedIcon />;
      bgColor = classes.error;
      break;
    case 'warning':
      icon = <ErrorOutlineRoundedIcon />;
      bgColor = classes.warning;
      break;
    case 'success':
    case 'default':
    default:
      icon = <CheckCircleOutlineRoundedIcon />;
      bgColor = classes.success;
  }

  return open ? (
    <Paper elevation={2} className={`${classes.root} ${bgColor} ${className}`}>
      {icon}
      <Typography variant="body1" className={classes.msg}>
        {msg}
      </Typography>
    </Paper>
  ) : null;
};

Snackbar.defaultProps = {
  type: 'default',
  className: '',
};

Snackbar.propTypes = {
  msg: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default Snackbar;
