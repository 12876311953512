import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
import styleConst from '../../constants/style';

const { TOOLBAR_HEIGHT } = styleConst;

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: `0 ${theme.spacing(3)}`,
  },
  content_desktop: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  rwd_content: {
    padding: `0 ${theme.spacing(3)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  sub_title: {
    letterSpacing: 0,
    marginBottom: theme.spacing(3),
    borderBottom: `2px dashed ${alpha(theme.palette.common.black, 0.12)}`,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  field: {
    marginBottom: theme.spacing(3),
  },
  rwd_field: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: `calc((100% - ${theme.spacing(7)}) / 2)`,
      '&:nth-of-type(2n + 1)': {
        marginRight: theme.spacing(7),
      },
    },
    [theme.breakpoints.up('lg')]: {
      width: `calc((100% - ${theme.spacing(12)}) / 3)`,
      '&:nth-of-type(2n + 1)': {
        marginRight: 0,
      },
      '&:not(:nth-of-type(3n))': {
        marginRight: theme.spacing(6),
      },
    },
  },
  defect_field: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  selection: {
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      width: `calc((100% - ${theme.spacing(5)}) / 2)`,
      '&:nth-of-type(2n)': {
        marginRight: theme.spacing(5),
      },
    },
    [theme.breakpoints.up('lg')]: {
      width: `calc((100% - ${theme.spacing(10)}) / 3)`,
      '&:nth-of-type(2n)': {
        marginRight: 0,
      },
      '&:not(:nth-of-type(3n + 4))': {
        marginRight: theme.spacing(5),
      },
    },
  },
  desktop_full_width: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  divider: {
    margin: `${theme.spacing(4)} 0`,
    borderBottom: `2px dashed ${alpha(theme.palette.common.black, 0.12)}`,
    backgroundColor: 'transparent',
  },
  confirm: {
    width: '100%',
    padding: `0 ${theme.spacing(3)}`,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(45),
      marginLeft: 'auto',
    },
    display: 'flex',
  },
  upper_gap: {
    marginTop: theme.spacing(2),
  },
  upper_gap_sm: {
    marginTop: theme.spacing(1),
  },
  upper_gap_lg: {
    marginTop: theme.spacing(5),
  },
  lower_gap_xl: {
    marginBottom: theme.spacing(8),
  },
  result: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${theme.spacing(6)} - ${TOOLBAR_HEIGHT}px)`,
  },
  icon_container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3.2),
  },
  icon: {
    fontSize: 110,
  },
  row: {
    flexDirection: 'row',
  },
  process_btn: {
    width: '80%',
    marginRight: theme.spacing(2),
  },
  checkbox: {
    margin: '0',
  },
  ngTotal: {
    marginRight: theme.spacing(5),
  },
  setMargin: {
    marginTop: theme.spacing(3),
  },
  dialog_input: {
    width: 300,
    margin: `${theme.spacing(2)} 0`,
  },
}));

export default useStyles;
