/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import DatePicker from '../../../components/DatePicker/DatePicker';
import Button from '../../../components/Button/Button';
import Divider from '../../../components/Divider/Divider';
import Radio from '../../../components/Radio/Radio';
import SelectAutoMultiField from '../../../components/SelectField/SelectAutoMultiField';
import InputField from '../../../components/InputField/InputField';
import useCommonStyles from '../useStyles';
import useStyles from './useStyles';
import { reportScheduleType } from '../../../constants/enums';
import useAxios from '../../../hooks/useAxios';
import useCancelToken from '../../../hooks/useCancelToken';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';

const FilterInfo = ({
  onActionBasisChange,
  actionBasis,
  filterField,
  setFilterField,
  filterErr,
  setFilterErr,
  resetFilterFieldErr,
  handleFetch,
  customerSelection,
  productSelection,
  setCustomerSelection,
  setProductSelection,
  initCustomerSelection,
  initProductSelection,
  showAdvance,
  handleCancelDeliveryAdvanced,
  handleShowAdvancedFilter,
}) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();
  const { dialogDispatch } = useContext(DialogContext);
  const sectionType = useRef('');
  const elNameRef = useRef([]);
  const customerNameRef = useRef([]);
  const scOdNoRef = useRef('');

  const handleStartDateChange = (date) => {
    setFilterField((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFilterField((prev) => ({
      ...prev,
      endDate: date,
    }));
  };

  const handleElNameChange = (data = []) => {
    const targetElNameValue = productSelection.filter((product) =>
      data.toString().includes(product.name)
    );
    setFilterField((prev) => ({ ...prev, elName: targetElNameValue }));
    elNameRef.current = targetElNameValue;
    sectionType.current = 'prods';
  };

  const handleCustomerNameChange = (data = []) => {
    const targetCustomerValue = customerSelection.filter((customer) =>
      data.toString().includes(customer.name)
    );
    setFilterField((prev) => ({ ...prev, customerName: targetCustomerValue }));
    customerNameRef.current = targetCustomerValue;
    sectionType.current = 'customs';
  };

  const handleScOdNoChange = (e) => {
    const prevScOdNo = scOdNoRef.current;
    if (e.target.value.includes(' ')) {
      scOdNoRef.current = prevScOdNo;
    } else {
      scOdNoRef.current = e.target.value.trim();
    }
    setFilterField((prev) => ({
      ...prev,
      scOdNo: scOdNoRef.current,
    }));
  };

  const handleAdvancedClear = () => {
    if (actionBasis === reportScheduleType.BY_DELIVERY_DATE) {
      setFilterField((prev) => ({
        ...prev,
        elName: [],
        customerName: [],
      }));
      setCustomerSelection(initCustomerSelection);
      setProductSelection(initProductSelection);
      resetFilterFieldErr();
    }

    if (actionBasis === reportScheduleType.BY_SC_OD_NO) {
      setFilterField((prev) => ({
        ...prev,
        scOdNo: [],
      }));
      scOdNoRef.current = '';
      resetFilterFieldErr();
    }
  };

  const validationConfig = () => {
    resetFilterFieldErr();
    const { startDate, endDate, scOdNo } = filterField;

    if (+new Date(startDate) > +new Date(endDate)) {
      setFilterErr((prev) => ({
        ...prev,
        startDate: true,
        endDate: true,
      }));
      return false;
    }

    if (actionBasis === reportScheduleType.BY_SC_OD_NO) {
      if (scOdNo.length === 0) {
        setFilterErr((prev) => ({
          ...prev,
          scOdNo: true,
        }));
        return false;
      }
    }

    return true;
  };

  const handleSearch = () => {
    if (!validationConfig()) return;
    handleFetch({ isAdvanced: 1, type: actionBasis, page: 1 });
  };

  const getDeliverOptionList = async (type, data) => {
    const finalData = {
      [type]: data,
      checkBegin: format(new Date(filterField.startDate), 'yyyy-MM-dd'),
      checkEnd: format(new Date(filterField.endDate), 'yyyy-MM-dd'),
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/deliveryOptionList',
      finalData,
      true,
      cancelToken
    );
    if (status) {
      if (type === 'prods') {
        return result.customer;
      }
      if (type === 'customs') {
        return result.product;
      }
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: `無法取得${type === 'prods' ? '客戶全名稱' : '產品名稱'}資訊`,
          message: noPrivilegeMsg || `目前無法取得移轉單資訊，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  // 取得對應客戶名稱
  const getNewCustomerName = async () => {
    const data = filterField.elName.map((item) => item.name);
    // 如果客戶名稱 input 裡有資料就不可以叫
    if (customerNameRef.current.length !== 0) return;
    const newCustomerName = await getDeliverOptionList('prods', data.toString());
    setCustomerSelection(
      newCustomerName.map((item) => ({
        name: item.customerName,
        value: item.customerId,
      }))
    );
  };

  // 取得對應產品名稱
  const getNewElName = async () => {
    const data = filterField.customerName.map((item) => item.value);
    // 如果產品名稱 input裡有資料就不可以叫
    if (elNameRef.current.length !== 0) return;
    const newElName = await getDeliverOptionList('customs', data.toString());
    setProductSelection(
      newElName.map((item, index) => ({
        name: item.prodName,
        value: index + 1,
      }))
    );
  };

  useEffect(() => {
    handleAdvancedClear();
  }, [actionBasis]);

  return (
    <PaperContainer className={commonClasses.container}>
      <Grid container alignItems="center">
        <Typography variant="h6" style={{ marginRight: '24px' }}>
          篩選模式
        </Typography>
        <Radio
          label="出貨日期"
          name="byDeliveryDate"
          value={reportScheduleType.BY_DELIVERY_DATE}
          selectedValue={actionBasis}
          onChange={onActionBasisChange}
          style={{ marginRight: '24px' }}
        />
        <Radio
          label="生產批號"
          name="byScOdNo"
          value={reportScheduleType.BY_SC_OD_NO}
          selectedValue={actionBasis}
          onChange={onActionBasisChange}
          style={{ marginRight: '24px' }}
        />
      </Grid>
      <Grid container className={commonClasses.content} direction="column">
        <Grid container xs={12} alignItems="center">
          <Grid item xs={3} className={classes.filter_date_grid}>
            <DatePicker
              id="startDate"
              label="查詢起始日期"
              value={filterField.startDate}
              onChange={handleStartDateChange}
              hasDateErr={filterErr.startDate}
              errMsg="結束日期不得早於起始日期"
              maxDate={format(new Date(2100, 12, 31), 'yyyy-MM-dd')}
              containerClass={classes.filter_date_picker}
            />
          </Grid>
          <Typography
            variant="h6"
            style={{ marginRight: 24 }}
            className={filterErr ? classes.error : ''}
          >
            ~
          </Typography>
          <Grid item xs={3} className={classes.filter_date_grid}>
            <DatePicker
              id="endDate"
              label="查詢結束日期"
              value={filterField.endDate}
              onChange={handleEndDateChange}
              hasDateErr={filterErr.endDate}
              errMsg="結束日期不得早於起始日期"
              maxDate={format(new Date(2100, 12, 31), 'yyyy-MM-dd')}
              containerClass={classes.filter_date_picker}
            />
          </Grid>

          {!showAdvance && (
            <>
              <Button
                variant="outlined"
                color="primary"
                customClasses={classes.filter_btn}
                startIcon={<SearchRoundedIcon />}
                onClick={() => handleFetch({ page: 1 })}
              >
                查詢
              </Button>
              <Button
                variant="outlined"
                color="primary"
                customClasses={classes.filter_btn}
                startIcon={<ImageSearchRoundedIcon />}
                onClick={() => handleShowAdvancedFilter(true)}
              >
                進階查詢
              </Button>
            </>
          )}
        </Grid>
        {showAdvance && (
          <>
            <Divider style={{ marginTop: 24 }} />
            <Grid container xs={12} className={classes.advanced_filter} direction="column">
              <Grid container xs={12} justifyContent="space-between">
                <Typography variant="subtitle1">進階查詢條件</Typography>
                <Button
                  variant="outlined"
                  color="grey"
                  rounded
                  startIcon={<ExpandLessRoundedIcon />}
                  onClick={handleCancelDeliveryAdvanced}
                >
                  取消進階查詢
                </Button>
              </Grid>

              {actionBasis === reportScheduleType.BY_DELIVERY_DATE && (
                <Grid container xs={12} className={classes.advanced_filter_row}>
                  <Grid xs={6} className={classes.filter_field}>
                    <SelectAutoMultiField
                      id="elName"
                      label="產品名稱"
                      placeholder={filterField.elName.length > 0 ? '' : '請選擇產品名稱'}
                      selections={productSelection}
                      handleChange={handleElNameChange}
                      handleBlur={getNewCustomerName}
                      customerNameList={filterField.customerName.map((mold) => mold.name)}
                      elNameList={filterField.elName.map((mold) => mold.name)}
                    />
                  </Grid>
                  <Grid xs={6} className={classes.filter_field}>
                    <SelectAutoMultiField
                      id="customerName"
                      label="客戶全名稱"
                      placeholder={filterField.customerName.length > 0 ? '' : '請選擇客戶全名稱'}
                      selections={customerSelection}
                      handleChange={handleCustomerNameChange}
                      handleBlur={getNewElName}
                      customerNameList={filterField.customerName.map((mold) => mold.name)}
                      elNameList={filterField.elName.map((mold) => mold.name)}
                    />
                  </Grid>
                </Grid>
              )}

              {actionBasis === reportScheduleType.BY_SC_OD_NO && (
                <Grid container className={classes.advanced_filter_row}>
                  <Grid xs={6}>
                    <InputField
                      id="scOdNo"
                      label="生產批號"
                      value={scOdNoRef.current}
                      onChange={handleScOdNoChange}
                      placeholder="請輸入生產批號"
                      error={filterErr.scOdNo}
                      errormsg="生產批號不可不填！"
                    />
                  </Grid>
                </Grid>
              )}

              <Grid
                xs={12}
                className={
                  actionBasis === reportScheduleType.BY_DELIVERY_DATE
                    ? classes.delivery_progress_advanced_filter_btn
                    : `${classes.advanced_filter_btn}`
                }
                container
                alignItems="flex-end"
              >
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<SearchRoundedIcon />}
                  onClick={handleSearch}
                >
                  查詢
                </Button>
                <Button
                  variant="outlined"
                  color="grey"
                  startIcon={<ClearRoundedIcon />}
                  onClick={handleAdvancedClear}
                  style={{ marginLeft: 16 }}
                >
                  重設
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </PaperContainer>
  );
};

FilterInfo.propTypes = {
  onActionBasisChange: PropTypes.func.isRequired,
  actionBasis: PropTypes.string.isRequired,
  filterField: PropTypes.object.isRequired,
  setFilterField: PropTypes.func.isRequired,
  filterErr: PropTypes.bool.isRequired,
  setFilterErr: PropTypes.func.isRequired,
  resetFilterFieldErr: PropTypes.func.isRequired,
  handleFetch: PropTypes.func.isRequired,
  showAdvance: PropTypes.bool.isRequired,
  handleShowAdvancedFilter: PropTypes.func.isRequired,
  handleCancelDeliveryAdvanced: PropTypes.func.isRequired,
  customerSelection: PropTypes.array.isRequired,
  productSelection: PropTypes.array.isRequired,
  setCustomerSelection: PropTypes.func.isRequired,
  setProductSelection: PropTypes.func.isRequired,
  initCustomerSelection: PropTypes.array.isRequired,
  initProductSelection: PropTypes.array.isRequired,
};

export default FilterInfo;
