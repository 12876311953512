/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../components/Grid/Grid';
import Accordion from '../../../components/SettingsAccordion/Accordion';

const PrivilegeCheckbox = ({
  checkboxItems,
  checkboxItems2,
  checkboxItems3,
  checkboxDispatch,
  checkboxDispatch2,
  checkboxDispatch3,
  isViewing,
  codeValue,
}) => (
  <>
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={4}>
        <Accordion
          title="移轉單系統"
          itemList={checkboxItems}
          checkboxDispatch={checkboxDispatch}
          isViewing={isViewing}
          codeValue={codeValue}
        />
      </Grid>
      <Grid item xs={4}>
        <Accordion
          title="QC單系統"
          itemList={checkboxItems2}
          checkboxDispatch={checkboxDispatch2}
          isViewing={isViewing}
          codeValue={codeValue}
        />
      </Grid>
      <Grid item xs={4}>
        <Accordion
          title="Administration"
          itemList={checkboxItems3}
          checkboxDispatch={checkboxDispatch3}
          isViewing={isViewing}
          codeValue={codeValue}
        />
      </Grid>
    </Grid>
  </>
);

PrivilegeCheckbox.propTypes = {
  checkboxItems: PropTypes.array.isRequired,
  checkboxItems2: PropTypes.array.isRequired,
  checkboxItems3: PropTypes.array.isRequired,
  checkboxDispatch: PropTypes.func.isRequired,
  checkboxDispatch2: PropTypes.func.isRequired,
  checkboxDispatch3: PropTypes.func.isRequired,
  isViewing: PropTypes.bool.isRequired,
  codeValue: PropTypes.array.isRequired,
};

export default PrivilegeCheckbox;
