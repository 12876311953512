import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  select: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    '& .MuiAutocomplete-clearIndicator': {
      opacity: 0,
    },
  },
  label: {
    zIndex: 2,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      display: 'block',
      width: '100%',
      padding: '0 3px',
      boxSizing: 'content-box',
      height: 10,
      backgroundColor: alpha(theme.palette.grey[100], 0.8),
      transform: 'translate(-50%, -50%)',
      zIndex: -1,
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  errorMsg: {
    marginTop: '-5px',
  },
  autoCompleteInput: {
    width: '150px',
    height: '35px',
    marginLeft: '15px',
  },
  readyOnlyLabel: {
    color: '#000',
    top: '5.5px',
    left: '-8.5px',
    fontSize: '18.4px',
  },
  readyOnly: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  },
}));

export default useStyles;
