import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { format } from 'date-fns';
import Grid from '../../components/Grid/Grid';
import InputField from '../../components/InputField/InputField';
import DatePicker from '../../components/DatePicker/DatePicker';
import Button from '../../components/Button/Button';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';
import { useFormingTypeToEnum } from '../../hooks/useFormingType';

const useStyles = makeStyles()(() => ({
  container: {
    margin: '19px 20px 16px 8px',
  },
  datePicker_container: {
    '& span': {
      top: '55%',
    },
  },
  datePicker: {
    margin: '0 !important',
  },
}));

const NewDeclareAction = ({ maxQty, onAdd, onCloseAdding, workId, formingType }) => {
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);
  const [declareData, setDeclareData] = useState({ declareDate: new Date(), declareQty: '' });
  const [declareQtyErr, setDeclareQtyErr] = useState(false);
  const [declareQtyErrMessage, setDeclareQtyErrMessage] = useState('');

  const handleDateChange = (date) => {
    setDeclareData((prev) => ({
      ...prev,
      declareDate: date,
    }));
  };

  const validationDeclareQty = (declareQty) => {
    // eslint-disable-next-line no-restricted-globals
    if (declareQty.length === 0 || isNaN(declareQty) || Number(declareQty) <= 0) {
      setDeclareQtyErr(true);
      setDeclareQtyErrMessage('請輸入正確的申報數量');
      return false;
    }

    if (maxQty - declareQty < 0) {
      setDeclareQtyErr(true);
      setDeclareQtyErrMessage(
        '申報數量不可大於(已壓型總數 + 掛帳工令總數 - 鑽孔/排列數量 - 耗損總數)'
      );
      return false;
    }

    setDeclareQtyErr(false);
    return true;
  };

  const handleQtyChange = (e) => {
    const { value } = e.target;
    setDeclareData((prev) => ({
      ...prev,
      declareQty: value,
    }));
    validationDeclareQty(value);
  };

  const handleSubmit = () => {
    if (!validationDeclareQty(declareData.declareQty)) return;
    const data = {
      wasteRecordDtos: [
        {
          workId,
          manufactureType: useFormingTypeToEnum(formingType),
          recordDate: format(new Date(declareData.declareDate), 'yyyy-MM-dd'),
          wasteQuantity: declareData.declareQty,
          isEnable: 1,
        },
      ],
    };
    onAdd({
      data,
      type: 'add',
      message: '已成功新增申報紀錄！',
      errorMessage: '新增申報紀錄失敗，請稍後再試，或通知網管人員！',
    });
  };

  const handleCancel = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認取消？',
        message: '是否確認退出編輯？您所做出的更動將不會被儲存。',
        handleConfirm: onCloseAdding,
      },
    });
  };

  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between" alignItems="start" spacing={1}>
        <Grid container item xs={7} spacing={4} alignItems="center">
          <Grid item xs>
            <DatePicker
              id="declareDate"
              label="申報日期"
              containerClass={classes.datePicker_container}
              className={classes.datePicker}
              value={declareData.declareDate}
              onChange={handleDateChange}
              maxDate={new Date()}
              style={{ paddingBottom: declareQtyErr ? '15.59px' : 0 }}
            />
          </Grid>
          <Grid item xs>
            <InputField
              type="text"
              id="declareQty"
              label="申報數量"
              value={declareData.declareQty}
              variant="outlined"
              onChange={handleQtyChange}
              placeholder="請輸入申報數量"
              error={declareQtyErr}
              errormsg={declareQtyErrMessage}
            />
          </Grid>
        </Grid>
        <Grid container item xs={5} justifyContent="flex-end">
          <Button
            style={{ marginRight: 10 }}
            variant="outlined"
            color="secondary"
            rounded
            onClick={handleSubmit}
          >
            新增
          </Button>
          <Button variant="outlined" color="error" rounded onClick={handleCancel}>
            取消
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

NewDeclareAction.propTypes = {
  maxQty: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onCloseAdding: PropTypes.func.isRequired,
  workId: PropTypes.number.isRequired,
  formingType: PropTypes.number.isRequired,
};

export default NewDeclareAction;
