/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { makeStyles } from 'tss-react/mui';
import Grid from '../../components/Grid/Grid';
import ReadonlyField from '../../components/ReadonlyField/ReadonlyField';
import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';

const useStyles = makeStyles()(() => ({
  container: {
    margin: '19px 20px 16px 8px',
  },
  datePicker_container: {
    '& span': {
      top: '55%',
    },
  },
  datePicker: {
    margin: '0 !important',
  },
}));

const NewStockAction = ({ onAdd, onCloseAdding, productInfo }) => {
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);
  const [stockData, setDeclareData] = useState({
    stockDate: format(new Date(), 'yyyy/MM/dd'),
    stockQty: '',
  });
  const [stockQtyErr, setDeclareQtyErr] = useState(false);
  const [stockQtyErrMessage, setDeclareQtyErrMessage] = useState('');

  const validationDeclareQty = (stockQty) => {
    // eslint-disable-next-line no-restricted-globals
    if (stockQty.length === 0 || isNaN(stockQty) || Number(stockQty) <= 0) {
      setDeclareQtyErr(true);
      setDeclareQtyErrMessage('請輸入正確的盤點數量');
      return false;
    }

    setDeclareQtyErr(false);
    return true;
  };

  const handleQtyChange = (e) => {
    const { value } = e.target;
    setDeclareData((prev) => ({
      ...prev,
      stockQty: value,
    }));
    validationDeclareQty(value);
  };

  const handleSubmit = () => {
    if (!validationDeclareQty(stockData.stockQty)) return;
    const data = {
      elNo: productInfo.elNo,
      prodName: productInfo.name,
      stock: stockData.stockQty,
      isEnable: 1,
    };
    onAdd({
      data,
      type: 'add',
      message: '已成功新增盤點紀錄！',
      errorMessage: '新增盤點紀錄失敗，請稍後再試，或通知網管人員！',
    });
  };

  const handleCancel = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認取消？',
        message: '是否確認退出編輯？您所做出的更動將不會被儲存。',
        handleConfirm: onCloseAdding,
      },
    });
  };

  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between" alignItems="start" spacing={1}>
        <Grid container item xs={7} spacing={4} alignItems="center">
          <Grid item xs>
            <ReadonlyField
              id="stockDate"
              label="盤點日期"
              containerClass={classes.datePicker_container}
              className={classes.datePicker}
              value={stockData.stockDate}
              style={{ paddingBottom: stockQtyErr ? '15.59px' : 0 }}
            />
          </Grid>
          <Grid item xs>
            <InputField
              type="text"
              id="stockQty"
              label="盤點數量"
              value={stockData.stockQty}
              variant="outlined"
              onChange={handleQtyChange}
              placeholder="請輸入盤點數量"
              error={stockQtyErr}
              errormsg={stockQtyErrMessage}
            />
          </Grid>
        </Grid>
        <Grid container item xs={5} justifyContent="flex-end">
          <Button
            style={{ marginRight: 10 }}
            variant="outlined"
            color="secondary"
            rounded
            onClick={handleSubmit}
          >
            新增
          </Button>
          <Button variant="outlined" color="error" rounded onClick={handleCancel}>
            取消
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

NewStockAction.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onCloseAdding: PropTypes.func.isRequired,
  productInfo: PropTypes.object.isRequired,
};

export default NewStockAction;
