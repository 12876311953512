/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import useStyles from './useStyles';
import useCommonStyles from '../useStyles';
import TransInfo from './TransInfo';
import ConfigInfo from './ConfigInfo';
import useForm from '../../../hooks/useForm';
import useAxios from '../../../hooks/useAxios';
import { moldSelection } from '../../../constants/configSelections';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import Radio from '../../../components/Radio/Radio';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';
import Snackbar from '../../../components/Snackbar/Snackbar';
import ConfigItemRow from './ConfigItemRow';
import { tabDirectionType, qcFormResult, qcFormingPath } from '../../../constants/enums';
import regex from '../../../constants/validationRegex';
import EditRecordSelection from './EditRecordSelection';
import useCancelToken from '../../../hooks/useCancelToken';

const QCForming = ({ path }) => {
  const { dialogDispatch } = useContext(DialogContext);
  const [transId, setTransId] = useState('');
  const [transIdErr, setTransIdErr] = useState(false);
  const [qcDefectTypes, setQCDefectTypes] = useState([]);
  const [actionBasis, setActionBasis] = useState(tabDirectionType.BY_SAMPLE);
  const [formingResult, setFormingResult] = useState(qcFormResult.PASS);
  const initialTransInfo = {
    customer: '-',
    elName: '-',
    bucketNo: '-',
    formMachine: '-',
    formMachineId: '',
    modifyUser: '-',
    isRStick: false,
  };
  const [transInfo, setTransInfo] = useState(initialTransInfo);
  const [isEdit, setIsEdit] = useState(false);
  const initialConfigInfo = {
    heightMax: '',
    heightMin: '',
    weightMax: '',
    weightMin: '',
    cuttingNum: '0',
    moduler: '',
    mold: [...moldSelection],
    formCheckId: '0',
  };
  const {
    formData: configInfo,
    setFormData: setConfigInfo,
    formErr: configInfoErr,
    setFormErr: setConfigInfoErr,
    resetFormData: resetConfigInfo,
    resetFormErr: resetConfigInfoErr,
  } = useForm(initialConfigInfo);
  const [renderRow, setRenderRow] = useState([]);
  const [toRender, setToRender] = useState(false);
  const [needToRerender, setNeedToRerender] = useState(false);
  const [isTargetChange, setIsTargetChange] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [showUnfinishAlert, setShowUnfinishAlert] = useState(true);
  const [showConfig, setShowConfig] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [resultNote, setResultNote] = useState('');
  const [warningMsg, setWarningMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [resetTransInfo, setResetTransInfo] = useState(false);
  const [machineList, setMachineList] = useState([]);
  const [showConfigScrollData, setShowConfigScrollData] = useState(false);
  const [modulerList, setModulerList] = useState([]);
  const qcFormingRecord = useRef('0');
  const qcFormingRecordErr = useRef(false);
  const qcFormingRecords = useRef([]);
  const currentConfig = useRef(null);
  const originalCurrentConfig = useRef(null);
  const tableEl = useRef(null);
  const warningTimer = useRef(null);
  const successTimer = useRef(null);
  const realTimeTransId = useRef('');
  const currentTransId = useRef('');
  const scrollTarget = useRef(null);
  const editedAndCreate = useRef(false);
  const times = useRef(0);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();

  const resetAllField = () => {
    setTransId('');
    setTransInfo(initialTransInfo);
    setResultNote('');
    resetConfigInfo();
    setShowEditBtn(false);
    setShowUnfinishAlert(true);
    setResetTransInfo(true);
    setRenderRow([]);
    setShowTable(false);
    setShowConfig(false);
    realTimeTransId.current = '';
    qcFormingRecord.current = '0';
    qcFormingRecords.current = [];
    currentConfig.current = null;
    times.current = 0;
  };

  const resetDataField = () => {
    setResultNote('');
    currentConfig.current = null;
    setFormingResult(qcFormResult.PASS);
  };

  const getTransInfo = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getFormingBase',
      { transId: realTimeTransId.current },
      true,
      cancelToken
    );
    if (status) {
      const { transfer } = result;
      const {
        bucketNo,
        customerAlias,
        machineName,
        prodName,
        modifyUser,
        isRStick,
        grindMachineId,
        materialName,
        scOdno,
        receiveFromInventory,
      } = transfer;
      setTransInfo({
        bucketNo: bucketNo || '',
        modifyUser: modifyUser || '0',
        formMachine: machineName || '0',
        formMachineId: grindMachineId || '0',
        customer: customerAlias,
        elName: `${prodName}(${materialName})`,
        isRStick: !!isRStick,
        isInventory: receiveFromInventory,
        transId: transfer.sourceTransId ? transfer.sourceTransId : null,
        workNo: scOdno,
      });
      qcFormingRecord.current = '0';
      if (receiveFromInventory) {
        times.current += 1;
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '該單號為暫存單號',
            message: `該單號為暫存單號，不得新增壓型檢測！`,
            singleBtn: true,
          },
        });
      }
    } else {
      setTransInfo(initialTransInfo);
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得移轉單資訊',
          message: noPrivilegeMsg || '目前無法取得移轉單資訊，請稍後再試，或請聯絡網管人員！',
          singleBtn: true,
        },
      });
    }
    times.current += 1;
  };

  const getRecordSelection = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getFormingDate',
      { transId: realTimeTransId.current },
      true,
      cancelToken
    );
    if (status) {
      const { formCheck } = result;
      qcFormingRecords.current = formCheck.map((record) => ({
        name: format(new Date(record.modifyDate), 'yyyy-MM-dd HH:mm:ss'),
        value: record.formCheckId,
      }));
      if (formCheck.length > 0) {
        setShowEditBtn(true);
      }
      if (formCheck.length === 0) {
        setShowEditBtn(false);
        originalCurrentConfig.current = null;
      }
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得壓型檢測紀錄',
          message:
            noPrivilegeMsg ||
            '目前無法取得此移轉單號之壓型檢測紀錄，請稍後再試，或請聯絡網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  const validationTransId = () => {
    if (currentTransId.current.length === 0) {
      setTransIdErr(true);
      return false;
    }
    if (!regex.FORMING_TRANS_ID.test(currentTransId.current)) {
      setTransIdErr(true);
      return false;
    }
    return true;
  };

  const handleTransBlur = () => {
    if (!validationTransId()) return;
    if (showConfig) {
      setShowConfig(false);
    }
    if (showTable && showUnfinishAlert) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '檢測資料尚未儲存',
          message:
            '目前的檢測資料尚未儲存，點擊確認將會放棄此次編輯！若您要保存檢測資料，請先關閉要保存檢測資料，請先關閉此對話框，並點選下方【送出】按鈕儲存！',
          handleConfirm: () => {
            times.current = 0;
            realTimeTransId.current = currentTransId.current;
            setTransId(currentTransId.current);
            setShowConfig(false);
            setShowTable(false);
            getTransInfo();
            getRecordSelection();
          },
          handleCancel: () => setTransId(realTimeTransId.current),
        },
      });
      return;
    }
    if (transInfo.isInventory) {
      setShowConfig(false);
      setShowTable(false);
    }
    realTimeTransId.current = currentTransId.current;
    if (times.current === 0) {
      getTransInfo();
    }
    getRecordSelection();
  };

  const handleTransIdChange = (id) => {
    if (transIdErr) {
      setTransIdErr(false);
    }
    setTransId(id);
    currentTransId.current = id;
    if (id.length === 10) {
      handleTransBlur();
    }
    times.current = 0;
  };

  const handleConfigChange = (e, value) => {
    setConfigInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleAutoConfigChange = (e, value) => {
    const valueName = e.target.id.split('-')[0];
    setConfigInfo((prev) => ({
      ...prev,
      [valueName]: value,
    }));
  };
  const needRerender = () => {
    if (!currentConfig.current) {
      setNeedToRerender(true);
      setIsTargetChange(true);
      return true;
    }
    const { mold, cuttingNum, weightMax, weightMin, heightMax, heightMin } = currentConfig.current;
    const isTargetNotChange =
      weightMax === configInfo.weightMax &&
      weightMin === configInfo.weightMin &&
      heightMax === configInfo.heightMax &&
      heightMin === configInfo.heightMin;
    setIsTargetChange(!isTargetNotChange);

    const isMoldNotChange =
      configInfo.mold.length === mold.length &&
      mold.every((item) => configInfo.mold.find((el) => el.value === item.value));

    const isCuttingNumNotChange = cuttingNum === configInfo.cuttingNum;

    if (isCuttingNumNotChange && isMoldNotChange) {
      setNeedToRerender(false);
      return false;
    }
    setNeedToRerender(true);
    return true;
  };

  const handleTableRender = (renderWithData = false) => {
    if (!renderWithData && currentConfig.current !== null && !needRerender()) return;
    if (renderWithData) {
      setIsEdit(true);
    }
    if (!renderWithData) {
      setToRender(false);
      setRenderRow([]);
      setIsEdit(false);
      const { cuttingNum, mold } = configInfo;
      // editedAndCreate.current 是有修改紀錄點擊新增
      //  originalCurrentConfig.current === null 是沒有修改紀錄點擊新增狀況
      if (editedAndCreate.current || originalCurrentConfig.current === null || !showEditBtn) {
        const newRows = [];
        mold.forEach((item) => {
          for (let i = 1; i <= cuttingNum; i += 1) {
            newRows.push({
              moldNo: item.value,
              cutNo: i,
              height: '',
              weight: '',
              defectType: [...qcDefectTypes],
              note: '',
            });
          }
        });
        setRenderRow((prev) => [...prev, ...newRows]);
      }
      // 這是有修改紀錄而且就是要修改的情況
      if (originalCurrentConfig.current !== null && !editedAndCreate.current && showEditBtn) {
        const newMolds = mold
          .filter(
            (object1) =>
              !originalCurrentConfig.current.mold.some((object2) => object1.value === object2.value)
          )
          .map((m) => m.value);

        const findDetailId = (moldId) => {
          const matchedRow = renderRow.find((row) => row.moldNo === moldId);
          return matchedRow ? matchedRow.detailId : '0';
        };

        const newRows = [];
        mold.forEach((item) => {
          for (let i = 1; i <= cuttingNum; i += 1) {
            newRows.push({
              detailId: !newMolds.includes(item.value) ? findDetailId(item.value) : '0',
              moldNo: item.value,
              cutNo: i,
              height: '',
              weight: '',
              defectType: [...qcDefectTypes],
              note: '',
            });
          }
        });
        setRenderRow((prev) => [...prev, ...newRows]);
      }

      setRenderRow((prev) =>
        prev.map((row, idx) => ({
          ...row,
          rowId: idx,
        }))
      );

      currentConfig.current = {
        mold: configInfo.mold,
        cuttingNum: configInfo.cuttingNum,
        heightMax: configInfo.heightMax,
        heightMin: configInfo.heightMin,
        weightMax: configInfo.weightMax,
        weightMin: configInfo.weightMin,
      };
      // setShowTable(true);
    }
    // setNeedToRerender(true);
    setToRender(true);
    setIsTargetChange(true);
    setShowTable(true);
  };
  const onActionBasisChange = (e) => {
    setActionBasis(e.target.value);
  };

  const onFormingResultChange = (e) => {
    setFormingResult(e.target.value);
  };

  const handleResultNoteChange = (e) => {
    setResultNote(e.target.value);
  };

  const handleFocusChange = (e) => {
    const { mold, cuttingNum } = configInfo;
    const maxTabIndex = mold.length * cuttingNum * 2 + 1;
    const minTabIndex = 1;
    const currentTabIndex = e.target.tabIndex;
    if (e.key === 'Tab' && e.shiftKey) {
      e.preventDefault();
      let prevTabIndex = 0;
      if (currentTabIndex === minTabIndex) {
        prevTabIndex = currentTabIndex;
      } else {
        prevTabIndex = currentTabIndex - 1;
      }
      const focusTarget = document.querySelectorAll(`[tabindex = "${prevTabIndex}"]`)[1];
      if (!focusTarget) return;
      focusTarget.focus();
      return;
    }
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      let nextTabIndex = 0;
      if (currentTabIndex === maxTabIndex) {
        nextTabIndex = currentTabIndex;
      } else {
        nextTabIndex = currentTabIndex + 1;
      }
      const focusTarget = document.querySelectorAll(`[tabindex = "${nextTabIndex}"]`)[1];
      if (!focusTarget) return;
      focusTarget.focus();
    }
  };

  const getEditRecord = async (notNewWantEdit, data) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getFormingCheck',
      {
        transId: realTimeTransId.current.trim(),
        formCheckId: notNewWantEdit ? qcFormingRecord.current : data,
      },
      true,
      cancelToken
    );
    if (status) {
      const { formCheck, formDetail } = result;
      const {
        checkComment,
        formCheckId,
        // formModuler,
        formModulerName,
        heightLower,
        heightUpper,
        isPass,
        knifeCount,
        weightUpper,
        weightLower,
        formModel,
      } = formCheck;
      const formModelArr = formModel.split(',');
      setConfigInfo({
        formCheckId,
        heightMax: heightUpper,
        heightMin: heightLower,
        weightMax: weightUpper,
        weightMin: weightLower,
        // moduler: formModuler || '0',
        moduler: formModulerName || '0',
        cuttingNum: knifeCount,
        mold: formModelArr.map((mold) => ({
          name: `模具${mold}`,
          value: mold,
        })),
      });
      originalCurrentConfig.current = {
        heightMax: heightUpper,
        heightMin: heightLower,
        weightMax: weightUpper,
        weightMin: weightLower,
        cuttingNum: knifeCount,
        mold: formModelArr.map((mold) => ({
          name: `模具${mold}`,
          value: mold,
        })),
      };
      currentConfig.current = originalCurrentConfig.current;
      // notNewWantEdit的意思是單純修改
      if (!notNewWantEdit) {
        setShowConfig(true);

        setResultNote('');
        resetDataField();
      } else {
        setFormingResult(isPass ? qcFormResult.PASS : qcFormResult.FAIL);
        const sortedFormDetail = formDetail.sort((a, b) => a.formDetailId - b.formDetailId);
        setShowConfig(true);
        setRenderRow(
          sortedFormDetail.map((row, idx) => ({
            rowId: idx,
            detailId: row.formDetailId,
            moldNo: row.formModel,
            cutNo: row.knifeCount,
            height: row.formHeight || '',
            weight: row.formWeight || '',
            note: row.detailComments || '',
            defectType: row.checkDetectList.map((item) => ({
              name: item.detectId.toString(),
              label: item.detectName,
              checked: item.isPromise,
            })),
          }))
        );
        setResultNote(checkComment);
        handleTableRender(true);
      }
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得該筆資料',
          message:
            rtCode === 'E99'
              ? result
              : noPrivilegeMsg || '目前無法取得該筆壓型檢測之資料，請稍後再試，或請聯絡網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  const validationEditRecord = () => {
    if (qcFormingRecord.current === '0') {
      qcFormingRecordErr.current = true;
      const dialogTimer = setTimeout(() => {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: `請選擇要更新的壓型檢測`,
            body: (
              <EditRecordSelection
                qcFormingRecords={qcFormingRecords.current}
                qcFormingRecord={qcFormingRecord.current}
                qcFormingRecordErr={qcFormingRecordErr.current}
                handleQcFormingRecordChange={(value) => {
                  qcFormingRecord.current = value;
                }}
              />
            ),
            handleConfirm: validationEditRecord,
            handleCancel: () => {
              qcFormingRecordErr.current = false;
              qcFormingRecord.current = '0';
            },
          },
        });
        clearTimeout(dialogTimer);
      }, [500]);
      return;
    }
    qcFormingRecordErr.current = false;
    getEditRecord(true);
  };

  const showRecordsSelectionDialog = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: `請選擇要更新的壓型檢測`,
        body: (
          <EditRecordSelection
            qcFormingRecords={qcFormingRecords.current}
            qcFormingRecord={qcFormingRecord.current}
            qcFormingRecordErr={qcFormingRecordErr.current}
            handleQcFormingRecordChange={(value) => {
              qcFormingRecord.current = value;
            }}
          />
        ),
        handleConfirm: validationEditRecord,
        handleCancel: () => {
          qcFormingRecordErr.current = false;
          qcFormingRecord.current = '0';
        },
      },
    });
  };
  const handleEditMeasure = (isNew) => {
    setShowConfig(false);
    setShowTable(false);
    resetConfigInfo();
    if (!validationTransId()) return;
    if (isNew && showEditBtn) {
      editedAndCreate.current = true;
      setToRender(false);
      getEditRecord(false, qcFormingRecords.current[0].value);
      return;
    }
    if (isNew) {
      editedAndCreate.current = false;
      resetDataField();
      setShowConfig(true);
      return;
    }
    showRecordsSelectionDialog();
    times.current = 0;
  };

  const openWarningSnackBar = (msg) => {
    setWarningMsg(msg);
    window.scrollTo({
      top: 600,
      behavior: 'smooth',
    });
    clearTimeout(warningTimer.current);
    warningTimer.current = setTimeout(() => {
      setWarningMsg('');
      clearTimeout(warningTimer.current);
    }, 6000);
  };

  const openSuccessSnackBar = (msg) => {
    setSuccessMsg(msg);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    clearTimeout(successTimer.current);
    successTimer.current = setTimeout(() => {
      setSuccessMsg('');
      clearTimeout(successTimer.current);
    }, 5000);
  };

  const getMachineList = (list) => {
    setMachineList(
      list.map((item) => ({
        name: item.machineName,
        value: item.machineId,
      }))
    );
  };
  const fetchModulerList = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/modulerEnable',
      {},
      true,
      cancelToken
    );
    if (status) {
      const { moduler } = result;
      setModulerList(
        moduler.map((item) => ({
          name: item.userName,
          value: item.userId,
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得裝模人員清單',
          message: noPrivilegeMsg || '目前暫時無法取得裝模人員清單，清稍後再試，或請聯絡網管人員！',
          singleBtn: true,
        },
      });
    }
  };
  const handleSaveForm = async (rowData) => {
    // eslint-disable-next-line prettier/prettier
    const {
      heightMax,
      heightMin,
      weightMax,
      weightMin,
      mold,
      moduler,
      cuttingNum,
      formCheckId,
      // eslint-disable-next-line prettier/prettier
    } = configInfo;
    const data = {
      transId: realTimeTransId.current.trim(),
      formCheck: {
        transId,
        formCheckId: editedAndCreate.current ? '0' : formCheckId,
        heightUpper: heightMax,
        heightLower: heightMin,
        weightUpper: weightMax,
        weightLower: weightMin,
        knifeCount: cuttingNum,
        formModuler: modulerList.filter((person) => person.name === moduler)[0].value,
        formModel: mold.map((item) => item.value).join(','),
        isPass: formingResult,
        checkComment: resultNote,
        formMachineId: transInfo.formMachineId,
        bucketNo: transInfo.bucketNo,
        formUser: transInfo.modifyUser,
        machineName: transInfo.formMachine,
      },
      formDetail: rowData.map((row) => ({
        formDetailId: row.detailId || '0', // 這邊在editedAndCreate.current的時候，要再分辨原本的與新增的，只有新增的給0
        formModel: row.moldNo,
        knifeCount: row.cutNo,
        formHeight: row.height,
        formWeight: row.weight,
        detailComments: row.note,
        checkDetectList: row.defectType.map((type) => ({
          detectId: type.name,
          isPromise: type.checked,
        })),
      })),
    };
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '資料儲存中',
        message: '資料儲存中，請稍候...',
        noBtn: true,
      },
    });
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/formCheckSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      dialogDispatch({
        type: dialogActions.CLOSE,
      });
      setShowConfig(false);
      setShowTable(false);
      openSuccessSnackBar('壓型檢測儲存成功！');
      setTimeout(() => {
        document.querySelector('#transId').focus();
      }, 800);
    } else {
      dialogDispatch({
        type: dialogActions.CLOSE,
      });
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法儲存資料',
          message:
            rtCode === 'E99'
              ? result
              : noPrivilegeMsg || '無法儲存此筆壓型檢測資料，請稍後再試，或請聯絡網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  const validationRowData = (rowData) => {
    const inValidRow = [];
    const inValidRowIndex = [];
    const isValid = rowData.map((row, index) => {
      const { weight, height, note, defectType } = row;
      if ((height === '' && weight === '') || (height !== '' && weight !== '')) {
        return true;
      }
      inValidRow.push(row);
      inValidRowIndex.push(index + 1);
      setRenderRow((prev) =>
        prev.map((item) =>
          item.rowId === index
            ? { ...item, weight, height, note, defectType: [...defectType], isInValid: true }
            : item
        )
      );
      return false;
    });
    if (inValidRowIndex.length > 0) {
      openWarningSnackBar(
        `請注意！！第 ${inValidRowIndex.join(', ')} 筆量測資料沒有輸入完全，量測結果不得送出！！`
      );
    }

    return isValid.every((x) => x);
  };

  const validationEmptyRow = (rowData) => {
    const groupByMold = [];
    const { mold } = configInfo;
    mold.forEach((item) => {
      groupByMold.push(rowData.filter((row) => row.moldNo === item.value));
    });
    const inValidMold = [];
    const inValidMoldIndex = [];
    groupByMold.forEach((row) => {
      const isValidRow = row.every((item) => {
        if (item.weight !== '' && item.height !== '') {
          return true;
        }
        setRenderRow((prev) =>
          prev.map((spec) =>
            spec.rowId === item.rowId
              ? {
                  ...spec,
                  weight: item.weight,
                  height: item.height,
                  note: item.note,
                  defectType: [...item.defectType],
                  isInValid: true,
                }
              : spec
          )
        );
        return false;
      });
      if (!isValidRow) {
        inValidMold.push(row[0].moldNo);
        inValidMoldIndex.push(row[0].moldNo);
      }
    });
    if (inValidMold.length > 0) {
      openWarningSnackBar(
        `請注意！！模具 ${inValidMold.join(', ')} 的資料為空，資料輸入不完全，量測結果不得送出！！`
      );
    }

    return inValidMold.length === 0;
  };

  const handleFormSubmit = () => {
    // Gather every single itemRow's data by calling their gatherData function
    const allRowData = renderRow.map((row) => row.gatherData());
    if (!validationRowData(allRowData)) return;
    if (!validationEmptyRow(allRowData)) return;
    handleSaveForm(allRowData);
  };

  const getDefectTypes = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/qcDetect',
      { page: 0 },
      true,
      cancelToken
    );
    if (status) {
      const { detect } = result;
      setQCDefectTypes(
        detect.map((type) => ({
          name: type.detectId.toString(),
          label: type.detectName,
          checked: false,
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得壓型檢測Defect Type',
          message:
            noPrivilegeMsg ||
            '目前暫時無法取得壓型檢測之Defect Type，請稍後再試，或請通知網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  const handleDelete = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/deleteTransferQcData',
      { transId, formCheckId: qcFormingRecord.current },
      true,
      cancelToken
    );
    if (status) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '刪除成功',
          message: `已刪除${transId}的資料！`,
          singleBtn: true,
          handleConfirm: resetAllField,
        },
      });
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法刪除',
          message:
            noPrivilegeMsg || `目前暫時無法刪除${transId}的壓型資料請稍後再試，或請通知網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleShowConfirmDelete = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: `確認刪除？`,
        message: `刪除之後無法復原，確認刪除${transId}的此筆壓型資料嗎？`,
        singleBtn: false,
        handleConfirm: handleDelete,
      },
    });
  };

  useEffect(() => {
    getDefectTypes();

    return () => {
      clearTimeout(warningTimer.current);
      clearTimeout(successTimer.current);
    };
  }, []);

  useEffect(() => {
    if (toRender && tableEl.current) {
      tableEl.current.addEventListener('keydown', handleFocusChange);
    }
    return () => {
      if (!tableEl.current) return;
      tableEl.current.removeEventListener('keydown', handleFocusChange);
    };
  }, [toRender, configInfo.mold.length, configInfo.cuttingNum, tableEl.current]);

  useEffect(() => {
    if (showTable && path === qcFormingPath.FORMING) {
      window.addEventListener('scroll', () => {
        if (!scrollTarget.current) return;
        const position = scrollTarget.current.getBoundingClientRect();
        if (window.pageYOffset >= position.bottom + position.height) {
          setShowConfigScrollData(true);
          return;
        }
        setShowConfigScrollData(false);
      });
    }
  }, [showTable]);

  useEffect(() => {
    times.current = 0;
  }, []);

  return (
    <div className={commonClasses.main}>
      <PageTitle title="壓型檢測" />
      <TransInfo
        transInfo={transInfo}
        setTransInfo={setTransInfo}
        transId={transId}
        setTransId={handleTransIdChange}
        transIdErr={transIdErr}
        handleTransBlur={handleTransBlur}
        handleEditMeasure={handleEditMeasure}
        showEditBtn={showEditBtn}
        resetTransInfo={resetTransInfo}
        setResetTransInfo={setResetTransInfo}
        getMachineList={getMachineList}
      />
      <Snackbar
        msg={successMsg}
        open={successMsg.length > 0}
        type="success"
        className={commonClasses.success_snackbar}
      />
      {showConfig && (
        <ConfigInfo
          configInfo={configInfo}
          configInfoErr={configInfoErr}
          setConfigInfoErr={setConfigInfoErr}
          resetConfigInfoErr={resetConfigInfoErr}
          handleConfigChange={handleConfigChange}
          onConfirmClick={handleTableRender}
          setConfigInfo={setConfigInfo}
          isRStick={transInfo.isRStick}
          onScroll={showConfigScrollData}
          ref={scrollTarget}
          modulerList={modulerList}
          fetchModulerList={fetchModulerList}
          handleAutoConfigChange={handleAutoConfigChange}
          disabled={transInfo.isInventory}
        />
      )}
      {showTable && (
        <PaperContainer
          className={commonClasses.container}
          position={commonClasses.container_scroll}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">填寫量測數據</Typography>
            <Grid container xs={5} justifyContent="space-between">
              <Radio
                label="依量測sample填寫"
                name="bySample"
                value={tabDirectionType.BY_SAMPLE}
                selectedValue={actionBasis}
                onChange={onActionBasisChange}
                disabled={transInfo.isInventory}
              />
              <Radio
                label="依量測item填寫"
                name="byItem"
                value={tabDirectionType.BY_ITEM}
                selectedValue={actionBasis}
                onChange={onActionBasisChange}
                disabled={transInfo.isInventory}
              />
            </Grid>
          </Grid>
          <Snackbar
            msg={warningMsg}
            open={warningMsg.length > 0}
            type="error"
            className={commonClasses.error_snackbar}
          />
          <Grid container className={commonClasses.table} ref={tableEl}>
            <ul className={commonClasses.table_header}>
              <li className={`${commonClasses.table_column} ${classes.table_action_cell}`}>
                <Typography variant="subtitle2">動作</Typography>
              </li>
              <li className={commonClasses.table_column}>
                <div className={`${classes.table_cell} ${commonClasses.table_cell}`}>
                  <Typography variant="subtitle2">模具</Typography>
                </div>
                <div className={`${classes.table_cell} ${commonClasses.table_cell}`}>
                  <Typography variant="subtitle2">刀數</Typography>
                </div>
                <div
                  className={`${commonClasses.table_cell} ${classes.table_cell} ${classes.table_input_cell}`}
                >
                  <Typography variant="subtitle2">壓高</Typography>
                </div>
                <div
                  className={`${commonClasses.table_cell} ${classes.table_cell} ${classes.table_input_cell}`}
                >
                  <Typography variant="subtitle2">重量</Typography>
                </div>
              </li>
              <li className={`${commonClasses.table_column} ${classes.table_defect_cell}`}>
                <Typography variant="subtitle2">Defect Type</Typography>
              </li>
              <li className={`${commonClasses.table_column} ${classes.table_note_cell}`}>
                <Typography variant="subtitle2">備註</Typography>
              </li>
            </ul>
            {toRender &&
              renderRow.map((row, idx) => (
                <ConfigItemRow
                  key={idx}
                  data={row}
                  rowIndex={idx}
                  QCDefectType={qcDefectTypes}
                  handleRowChange={setRenderRow}
                  totalMold={configInfo.mold.length}
                  totalCut={configInfo.cuttingNum}
                  tabDirection={actionBasis}
                  target={configInfo}
                  targetRerender={isTargetChange}
                  setTargetRerender={setIsTargetChange}
                  needRerender={needToRerender}
                  disabled={transInfo.isInventory}
                />
              ))}
          </Grid>
          <Grid container className={classes.footer} justifyContent="flex-end">
            <Grid container xs={5} alignItems="center" justifyContent="flex-end">
              <Typography variant="subtitle2" className={classes.result}>
                輸入量測結果：
              </Typography>
              <Radio
                label="通過"
                name="resultPass"
                value={qcFormResult.PASS}
                selectedValue={formingResult}
                onChange={onFormingResultChange}
                className={classes.result_item}
                disabled={transInfo.isInventory}
              />
              <Radio
                label="不通過"
                name="resultFail"
                value={qcFormResult.FAIL}
                selectedValue={formingResult}
                onChange={onFormingResultChange}
                disabled={transInfo.isInventory}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.footer} wrap="nowrap" alignItems="center">
            <InputField
              id="resultNote"
              label="備註"
              value={resultNote}
              type="text"
              name="resultNote"
              placeholder="請輸入備註內容"
              onChange={handleResultNoteChange}
              disabled={transInfo.isInventory}
            />
            {/* 20230103 Shane - Delete QC record functionality will re-implement in the next phase */}
            {isEdit && (
              <Button
                variant="contained"
                color="error"
                rounded
                customClasses={classes.submit_btn}
                startIcon={<DeleteIcon />}
                onClick={handleShowConfirmDelete}
                disabled={transInfo.isInventory}
              >
                刪除
              </Button>
            )}

            <Button
              variant="contained"
              rounded
              color="primary"
              onClick={handleFormSubmit}
              customClasses={classes.submit_btn}
              startIcon={<CheckRoundedIcon />}
              disabled={transInfo.isInventory}
            >
              送出
            </Button>
          </Grid>
        </PaperContainer>
      )}
    </div>
  );
};
QCForming.propTypes = {
  path: PropTypes.string.isRequired,
};
export default QCForming;
