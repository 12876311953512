/* eslint-disable camelcase */
import React from 'react';
import FormingInfo from './FormingType/FormingInfo';
import { stationType, formingManufactureType } from '../../constants/enums';

const TransUpdateDrilling = () => (
  <FormingInfo
    pageTitle="更新移轉單（鑽孔）"
    pageLabel="鑽孔"
    formingType={formingManufactureType.DRILLING}
    stationType={stationType.DRILLING}
  />
);

export default TransUpdateDrilling;
