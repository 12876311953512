/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { calcTargetValue, isOutSpec } from '../../detailUtils';
import useStyles from '../useStyles';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';

const SizeDetail = ({ sinteringSize, sizeSpecConfig, isLoading }) => {
  const { classes } = useStyles();
  return (
    <>
      {(!sizeSpecConfig || !sinteringSize) && !isLoading && (
        <div style={{ marginTop: 50, textAlign: 'center' }}>
          <Typography variant="subtitle1">目前沒有尺寸資料</Typography>
        </div>
      )}
      {sizeSpecConfig && sinteringSize && (
        <>
          <Grid container className={classes.detail_header_row}>
            {sizeSpecConfig &&
              sizeSpecConfig.map((spec) => (
                <div
                  key={spec.specId}
                  className={`${classes.detail_header_column} ${classes.size_header_column}`}
                >
                  <Grid item className={classes.inspection_column}>
                    <Typography variant="subtitle1">{spec.specItem}</Typography>
                    <div className={classes.size_target_group}>
                      <Typography variant="body1" className={classes.size_target}>
                        {spec.specTarget}
                      </Typography>
                      <ul className={classes.size_spec_group}>
                        <li className={`${classes.size_spec} ${classes.size_spec_upper}`}>
                          +{parseFloat(spec.specUpper)}
                        </li>
                        <li className={`${classes.size_spec} ${classes.size_spec_lower}`}>
                          {parseFloat(spec.specUpper) > 0
                            ? `-${parseFloat(spec.specLower)}`
                            : spec.specLower}
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  {spec.specBias && (
                    <Typography variant="body2" className={classes.size_bias}>
                      量測偏差： {spec.specBias}
                    </Typography>
                  )}
                </div>
              ))}

            <Grid item className={`${classes.detail_header_column} ${classes.size_defect}`}>
              <Typography variant="subtitle1">Defect Type</Typography>
            </Grid>
          </Grid>
          {sinteringSize &&
            sinteringSize.map((row) => (
              <Grid container className={classes.detail_row} key={row.itemId}>
                {row.specItems.map((spec) => {
                  const targetSpec = sizeSpecConfig.find((item) => item.specId === spec.specId);
                  const [maxValue, minValue, upperLimit, lowerLimit] = calcTargetValue(targetSpec);
                  const [outSpec, outLimit] = isOutSpec(
                    spec.specValue,
                    maxValue,
                    minValue,
                    upperLimit,
                    lowerLimit
                  );
                  return (
                    <Grid
                      item
                      className={`${classes.detail_column} ${classes.inspection_column} ${
                        classes.inspection_value_cell
                      } ${outSpec ? classes.out_spec : ''} ${outLimit ? classes.out_limit : ''}`}
                      key={spec.itemId}
                    >
                      <Typography variant="body1">{spec.specValue || '-'}</Typography>
                    </Grid>
                  );
                })}
                <Grid item className={`${classes.detail_column} ${classes.size_defect}`}>
                  <Typography variant="body1">
                    {row.defectType.length !== 0
                      ? row.defectType.map((type) => type.detectName).join(', ')
                      : '-'}
                  </Typography>
                </Grid>
              </Grid>
            ))}
        </>
      )}
    </>
  );
};

SizeDetail.propTypes = {
  sizeSpecConfig: PropTypes.array.isRequired,
  sinteringSize: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SizeDetail;
