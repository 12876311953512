/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';
import IconButton from '../IconButton/IconButton';

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(1.5),
  },
  head: {
    borderTop: '1px solid rgba(0, 0, 0, .12)',
  },
  iconBtn: {
    width: 24,
    height: 24,
    border: '1px solid',
    marginLeft: theme.spacing(1.25),
    marginTop: 0,
    marginBottom: 0,
  },
  iconBtnView: {
    color: theme.palette.secondary.main,
  },
  iconBtnModify: {
    color: theme.palette.primary.main,
  },
  iconBtnDelete: {
    color: theme.palette.error.main,
  },
  iconBtnFilter: {
    width: 42,
    height: 42,
    border: '1px solid',
    marginLeft: theme.spacing(0.625),
    color: '#000000',
    opacity: 0.6,
  },
  iconBtnIcon: {
    padding: 3.9,
  },
  iconBtnFilterIcon: {
    width: 24,
    height: 24,
  },
  tablePaginationArrows: {
    flexShrink: 0,
    marginLeft: theme.spacing(5),
  },
  tablePagination: {
    border: 'none',
  },
  filter: {
    borderTop: '1px solid rgba(0, 0, 0, .12)',
    padding: theme.spacing(2),
  },
}));

const TableBtnSets = ({
  isDelete,
  handleView,
  handleEditing,
  handleDelete,
  isEditing,
  isView,
  canDelete = true,
  canEdit = true,
}) => {
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);

  const handleEdit = () => {
    handleEditing();
  };

  const handleDeleting = () => {
    if (isEditing) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '編輯尚未完成',
          message: '您尚未完成編輯，請先儲存您的編輯，或點選「取消」放棄編輯！',
          singleBtn: true,
        },
      });
      return;
    }
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認刪除？',
        message: '是否確認刪除此筆資料？',
        handleConfirm: handleDelete,
      },
    });
  };

  return (
    <div>
      {isView && (
        <IconButton
          className={`${classes.iconBtn} ${classes.iconBtnView}`}
          aria-label="modify"
          onClick={() => handleView()}
        >
          <VisibilityIcon className={classes.iconBtnIcon} />
        </IconButton>
      )}
      {canEdit && (
        <IconButton
          className={`${classes.iconBtn} ${classes.iconBtnModify}`}
          aria-label="modify"
          onClick={() => handleEdit()}
        >
          <CreateIcon className={classes.iconBtnIcon} />
        </IconButton>
      )}
      {isDelete && canDelete && (
        <IconButton
          className={`${classes.iconBtn} ${classes.iconBtnDelete}`}
          color="error"
          aria-label="delete"
          onClick={() => handleDeleting()}
        >
          <DeleteOutlinedIcon className={classes.iconBtnIcon} />
        </IconButton>
      )}
    </div>
  );
};
TableBtnSets.propTypes = {
  isView: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  isDelete: PropTypes.bool.isRequired,
  handleView: PropTypes.func,
  handleEditing: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default TableBtnSets;
