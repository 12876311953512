/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '../ListItemText/ListItemText';
import Typography from '../Typography/Typography';
import useStyles from './useStyles';

const SelectAutoMultiField = ({
  handleChange,
  handleBlur = () => {},
  selections,
  placeholder,
  label,
  id,
  elNameList,
  customerNameList,
  isNormal = false,
  selectedValue = [],
  ...rest
}) => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = useState('');
  const inputEl = useRef(null);

  useEffect(() => {
    if (
      (elNameList &&
        customerNameList &&
        elNameList.length === 0 &&
        customerNameList.length === 0) ||
      (selectedValue.length === 0 && isNormal)
    ) {
      const clearIndicatorBtn = document.querySelector('.MuiAutocomplete-clearIndicator');
      if (clearIndicatorBtn) {
        clearIndicatorBtn.click();
      }
    }
  }, [elNameList, customerNameList, selectedValue]);

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth {...rest}>
      <InputLabel id={id} shrink className={classes.label}>
        {label}
      </InputLabel>
      <Autocomplete
        multiple
        disableCloseOnSelect
        openOnFocus
        className={`${classes.select}`}
        options={selections}
        onChange={(e, option) => handleChange(option.map((item) => item.name))}
        onBlur={() => handleBlur()}
        inputValue={inputValue}
        onInputChange={(e, value, reason) => {
          if (e && e.type === 'blur') {
            setInputValue('');
          } else if (reason !== 'reset') {
            setInputValue(value);
          }
        }}
        renderTags={(selected) => (
          <Typography
            onClick={(e) => {
              inputEl.current.focus();
              e.stopPropagation();
            }}
            noWrap
            sx={{
              paddingRight: '1rem',
            }}
          >
            {selected.map((item) => item.name).join(', ')}
          </Typography>
        )}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props}>
            <Checkbox color="secondary" checked={selected} />
            <ListItemText primary={option.name} />
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            placeholder={placeholder}
            name={id}
            inputRef={inputEl}
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                style: {
                  minWidth: 'auto',
                },
              },
              sx: {
                '&.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
                  paddingLeft: '1rem',
                  paddingRight: '2rem',
                  display: 'inline-flex',
                  flexWrap: 'nowrap',
                },
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};

SelectAutoMultiField.propTypes = {
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  selections: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  elNameList: PropTypes.array,
  customerNameList: PropTypes.array,
  selectedValue: PropTypes.array,
  isNormal: PropTypes.bool,
};

export default SelectAutoMultiField;
