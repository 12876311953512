/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header/Header';
import MainMenu from './components/MainMenu/MainMenu';
import './App.scss';
import routes from './routes';
import { AuthContext } from './contexts/AuthContext';
import Login from './pages/Login/Login';
import withLoginCheck from './hoc/withLoginCheck';
import { DialogContext } from './contexts/DialogContext';
import { dialogActions } from './reducers/dialogReducer';
import useReactPath from './hooks/useReactPath';
import Main from './components/Main/Main';

// TODO: Temp

function App({ isLoginPending }) {
  const {
    authContext: { isLogin },
    logout,
    checkLogin,
    checkLoginStatus,
  } = useContext(AuthContext);
  const [hasPrivilege, setHasPrivilege] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { dialogDispatch } = useContext(DialogContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const path = useReactPath();
  const logoutTimer = useRef(null);
  const alertTimer = useRef(null);

  const logoutHandler = () => {
    logout();
    localStorage.setItem('targetPath', pathname);
    window.location.href = `${process.env.REACT_APP_BASE_URL}/#/login`;
    dialogDispatch({
      type: dialogActions.CLOSE,
    });
  };

  const alertHandler = () => {
    // eslint-disable-next-line no-use-before-define
    removeIdleListener();
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '您即將被登出！',
        message: '系統閒置過久即將被登出，請點擊確認以繼續使用！',
        singleBtn: true,
        handleConfirm: () => {
          // eslint-disable-next-line no-use-before-define
          attatchIdleListener();
          checkLogin();
          // eslint-disable-next-line no-use-before-define
          idleHandler();
        },
      },
    });
  };

  const idleHandler = () => {
    clearTimeout(logoutTimer.current);
    clearTimeout(alertTimer.current);
    logoutTimer.current = setTimeout(logoutHandler, process.env.REACT_APP_TIMEOUT);
    alertTimer.current = setTimeout(alertHandler, process.env.REACT_APP_ALERT_TIMEOUT);
  };

  const attatchIdleListener = () => {
    document.addEventListener('click', idleHandler);
    document.addEventListener('keyup', idleHandler);
  };

  const removeIdleListener = () => {
    document.removeEventListener('click', idleHandler);
    document.removeEventListener('keyup', idleHandler);
  };

  const getMenuStatus = (status) => {
    setMenuOpen(status);
  };

  const getPrivilege = () => {
    const privilege = JSON.parse(localStorage.getItem('privilege'));
    const currentPathname = pathname;
    if (privilege && checkLoginStatus()) {
      const hascurrentPrivilege = privilege.some((list) =>
        list.privilegeList.some((item) => item.linkUrl === currentPathname)
      );
      const canUpdate = privilege.some((list) =>
        list.privilegeList.find((item) => item.linkUrl === '/trans-update')
      );
      if (
        currentPathname.includes('/trans-update') ||
        currentPathname.includes('/package-update') ||
        currentPathname.includes('/trans-report/')
      ) {
        setHasPrivilege(canUpdate);
      } else {
        setHasPrivilege(hascurrentPrivilege);
      }
    } else {
      navigate('/login', { replace: true });
    }
  };

  useEffect(() => {
    if (!pathname.includes('/login') && !(path.includes('?') && !path.includes('state='))) {
      const targetPath = localStorage.getItem('targetPath');
      const isHomePage = pathname === '/';
      getPrivilege();
      if (isHomePage && targetPath !== pathname) return;
      localStorage.setItem('targetPath', pathname);
    }
  }, [pathname, path]);

  useEffect(() => {
    const currentPathname = pathname;

    if (!currentPathname.includes('qc-sintering-ng')) {
      localStorage.setItem('NGTransId', '');
    }
  }, [window.location.hash]);

  useEffect(() => {
    getPrivilege();

    if (!pathname.includes('login')) {
      attatchIdleListener();
      logoutTimer.current = setTimeout(logoutHandler, process.env.REACT_APP_TIMEOUT);
      alertTimer.current = setTimeout(alertHandler, process.env.REACT_APP_ALERT_TIMEOUT);
    }

    return () => {
      removeIdleListener();
      clearTimeout(logoutTimer.current);
      clearTimeout(alertTimer.current);
    };
  }, []);

  return (
    <div className="App">
      <Header />
      {isLogin && !isLoginPending && <MainMenu getMenuStatus={getMenuStatus} />}
      <Routes>
        {isLogin && !isLoginPending && (
          <Route
            element={<Main isLogin={isLogin} hasPrivilege={hasPrivilege} menuOpen={menuOpen} />}
          >
            {routes.map((route) => (
              <Route exact path={route.path} element={route.component} key={route.path} />
            ))}
          </Route>
        )}
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default withLoginCheck(App);
