/* eslint-disable no-unused-vars */
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  fetch_btn: {
    alignSelf: 'flex-end',
    marginTop: 'auto',
  },
  measure_selection: {
    marginLeft: theme.spacing(1.6),
  },
  select_btn: {
    marginLeft: theme.spacing(3),
  },
  measure_header: {
    padding: `${theme.spacing(1.5)}`,
    borderBottom: 0,
  },
  measure_info: {
    marginRight: theme.spacing(3),
    fontWeight: 700,
    fontSize: '17px',
    '& i': {
      fontStyle: 'normal',
      fontWeight: 500,
    },
  },
  measure_info_disabled: {
    color: theme.palette.grey[500],
    textDecoration: 'line-through',
  },
  font_weight: {
    fontWeight: 700,
  },
  table_action_cell: {
    width: 80,
    minWidth: 80,
    alignSelf: 'center',
  },
  table_header: {
    marginTop: 0,
  },
  table_column: {
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
  },
  table_cell: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    lineHeight: 1,
    '& i': {
      fontStyle: 'normal',
      fontWeight: 500,
      paddingLeft: theme.spacing(1.3),
    },
  },
  spec_config: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    '& i': {
      fontStyle: 'normal',
      fontWeight: 700,
      paddingLeft: theme.spacing(1.3),
      fontSize: '17px',
    },
    '& b': {
      fontSize: '17px',
    },
  },
  spec_divider: {
    display: 'inline-block',
    width: 1,
    height: theme.spacing(1.8),
    backgroundColor: alpha(theme.palette.common.black, 0.4),
    marginLeft: theme.spacing(1.3),
  },
  db_selection: {
    display: 'flex',
    marginTop: theme.spacing(1),
    alignItems: 'center',
  },
  amp_selection: {
    marginLeft: theme.spacing(1),
    fontWeight: 300,
    cursor: 'pointer',
    color: alpha(theme.palette.common.black, 0.5),
    border: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    padding: `${theme.spacing(0.4)} ${theme.spacing(1.5)}`,
    backgroundColor: 'transparent',
    transition: 'all .3s ease-in-out',
    borderRadius: 50,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.light, 0.4),
      borderColor: theme.palette.primary.main,
    },
    '&.active': {
      fontWeight: 500,
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.light, 0.4),
      borderColor: theme.palette.primary.main,
      display: 'inline-block',
    },
  },
  amp_selection_remove: {
    textDecoration: 'line-through',
  },
  table_footer: {
    width: '100%',
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  submit_btn: {
    marginLeft: theme.spacing(2),
    width: 122,
  },
  paper_padding: {
    padding: `${theme.spacing(1)} ${theme.spacing(3.25)} ${theme.spacing(1)}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(3)} 0`,
    },
  },
  paper_padding_title: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(3.25)} ${theme.spacing(3)}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(3)} 0`,
    },
  },
  title_padding: {
    paddingBottom: `${theme.spacing(3)}`,
  },
  measure_style_group: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  // paper_padding_row: {
  //   padding: `${theme.spacing(2.5)} ${theme.spacing(3.25)} ${theme.spacing(4)}`,
  //   [theme.breakpoints.down('sm')]: {
  //     padding: `${theme.spacing(3)} 0`,
  //   },
  // },
}));

export default useStyles;
