import React from 'react';
import { makeStyles } from 'tss-react/mui';
import PaperContainer from '../components/PaperContainer/PaperContainer';
import Typography from '../components/Typography/Typography';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(10),
    margin: `${theme.spacing(2.5)} auto 0`,
  },
}));

const NoPrivilege = () => {
  const { classes } = useStyles();
  return (
    <PaperContainer className={classes.container}>
      <Typography variant="h6">您沒有此頁面的權限，請聯絡網管人員或部門主管。</Typography>
    </PaperContainer>
  );
};

export default NoPrivilege;
