/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import useStyles from '../useStyles';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import InputField from '../../../components/InputField/InputField';
import Button from '../../../components/Button/Button';
import ReadonlyField from '../../../components/ReadonlyField/ReadonlyField';
import SelectAutoComplete from '../../../components/SelectField/SelectAutoComplete';
import Radio from '../../../components/Radio/Radio';
import { manufactureType } from '../../../constants/enums';

const SettingsConfigSearchByElNo = ({
  elNo,
  elNoInput,
  elShape,
  elSize,
  elMaterial,
  elPlan,
  prodWeight,
  selectElCustomer,
  elCustomerList,
  selectedFormingType,
  handleElNoInputChange,
  handleMaterialInputChange,
  handleSearch,
  handleUpdateConfig,
  elNoErr,
  elNoErrMsg,
  toShowUpdateBtn,
  handleElPlanChange,
  handleElCustomerChange,
  handleChangeFormingType,
}) => {
  const { classes } = useStyles();

  return (
    <PaperContainer className={classes.container}>
      <Typography variant="h6">產品相關設定</Typography>
      <Grid container style={{ marginTop: 22 }} spacing={3} alignItems="flex-end">
        <Grid item xs={5}>
          <InputField
            id="elNo"
            label=""
            value={elNoInput}
            variant="outlined"
            onChange={handleElNoInputChange}
            placeholder="請輸入料號"
            name="elNo"
            type="text"
            error={elNoErr}
            errormsg={elNoErrMsg}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<SearchRoundedIcon />}
            onClick={handleSearch}
          >
            確認
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginTop: 22 }}
        justifyContent="space-between"
        alignItems="center"
        spacing={toShowUpdateBtn ? 0 : 5}
      >
        {toShowUpdateBtn ? (
          <>
            <Grid item xs={12} container spacing={4}>
              <Grid item xs={2} className={classes.field_column}>
                <ReadonlyField label="料號" value={elNo} name="elNo" />
              </Grid>
              <Grid item xs={2} className={classes.field_column}>
                <InputField
                  id="elMaterial"
                  label="材質"
                  value={elMaterial}
                  variant="outlined"
                  onChange={handleMaterialInputChange}
                  placeholder="請輸入材質"
                  name="elMaterial"
                  type="text"
                  errormsg="請輸入材質"
                />
              </Grid>
              <Grid item xs={2} className={classes.field_column}>
                <ReadonlyField label="產品形狀" value={elShape} name="elShape" />
              </Grid>
              <Grid item xs={2} className={classes.field_column}>
                <ReadonlyField label="產品規格" value={elSize} name="elSize" />
              </Grid>
              <Grid item xs={2} className={classes.field_column}>
                <ReadonlyField label="單重" value={prodWeight} name="prodWeight" />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={4}
              style={{ alignItems: 'end', marginTop: '1rem' }}
            >
              <Grid item xs={2} className={classes.field_column}>
                <SelectAutoComplete
                  label="客戶"
                  name="customer"
                  placeholder="請選擇客戶"
                  value={selectElCustomer.customerAlias}
                  onFocus={handleElCustomerChange}
                  handleChange={handleElCustomerChange}
                  selections={elCustomerList}
                  id="customer"
                  errormsg="請輸入客戶"
                />
              </Grid>
              <Grid item xs={2} className={classes.field_column}>
                <InputField
                  id="planQuantity"
                  label="計畫產能"
                  value={elPlan.planQuantity}
                  variant="outlined"
                  onChange={handleElPlanChange}
                  placeholder="請輸入計畫產能"
                  name="planQuantity"
                  type="text"
                  errormsg="請輸入計畫產能"
                />
              </Grid>
              <Grid item xs={2} className={classes.field_column}>
                <InputField
                  id="planHours"
                  label="計畫時數"
                  value={elPlan.planHours}
                  variant="outlined"
                  onChange={handleElPlanChange}
                  placeholder="請輸入計畫時數"
                  name="planHours"
                  type="text"
                  errormsg="請輸入計畫時數"
                />
              </Grid>
              <Grid item xs={2} className={classes.field_column}>
                <ReadonlyField
                  label="計畫小時產能"
                  value={elPlan.planHoursQuantity}
                  name="planHoursQuantity"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container style={{ alignItems: 'end', marginTop: '1rem' }}>
              <Grid item xs={10} container alignItems="center">
                <Grid item xs={12}>
                  <Typography style={{ fontSize: 14 }}>壓型加工</Typography>
                </Grid>
                <Grid item xs={12} container spacing={4}>
                  <Grid item>
                    <Radio
                      label="無"
                      name="無"
                      value={manufactureType.FORMING}
                      selectedValue={selectedFormingType}
                      onChange={handleChangeFormingType}
                    />
                  </Grid>
                  <Grid item>
                    <Radio
                      label="鑽孔"
                      name="鑽孔"
                      value={manufactureType.DRILLING}
                      selectedValue={selectedFormingType}
                      onChange={handleChangeFormingType}
                    />
                  </Grid>
                  <Grid item>
                    <Radio
                      label="排列"
                      name="排列"
                      value={manufactureType.ALIGNING}
                      selectedValue={selectedFormingType}
                      onChange={handleChangeFormingType}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} container justifyContent="end">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<SearchRoundedIcon />}
                  onClick={() => handleUpdateConfig(null)}
                  style={{ marginRight: 32 }}
                >
                  儲存產品設定
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={2} className={classes.field_column}>
              <ReadonlyField label="料號" value={elNo} name="elNo" />
            </Grid>
            <Grid item xs={2} className={classes.field_column}>
              <InputField
                id="elMaterial"
                label="材質"
                value={elMaterial}
                variant="outlined"
                onChange={handleMaterialInputChange}
                placeholder="請輸入材質"
                name="elMaterial"
                type="text"
                errormsg="請輸入材質"
              />
            </Grid>
            <Grid item xs={2} className={classes.field_column}>
              <ReadonlyField label="產品形狀" value={elShape} name="elShape" />
            </Grid>
            <Grid item xs={3}>
              <ReadonlyField label="產品規格" value={elSize} name="elSize" />
            </Grid>
          </>
        )}
      </Grid>
    </PaperContainer>
  );
};

SettingsConfigSearchByElNo.defaultProps = {
  elNo: '',
  elShape: '',
  elSize: '',
  elNoErr: false,
  elNoErrMsg: '',
  elPlan: {},
  selectElCustomer: {},
  elCustomerList: [],
};

SettingsConfigSearchByElNo.propTypes = {
  elNo: PropTypes.string,
  elNoInput: PropTypes.string.isRequired,
  elShape: PropTypes.string,
  elSize: PropTypes.string,
  elPlan: PropTypes.object,
  prodWeight: PropTypes.string.isRequired,
  selectElCustomer: PropTypes.object,
  elCustomerList: PropTypes.array,
  elMaterial: PropTypes.string.isRequired,
  selectedFormingType: PropTypes.string.isRequired,
  handleElNoInputChange: PropTypes.func.isRequired,
  handleMaterialInputChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleUpdateConfig: PropTypes.func.isRequired,
  elNoErr: PropTypes.bool,
  elNoErrMsg: PropTypes.string,
  toShowUpdateBtn: PropTypes.bool.isRequired,
  handleElPlanChange: PropTypes.func.isRequired,
  handleElCustomerChange: PropTypes.func.isRequired,
  handleChangeFormingType: PropTypes.func.isRequired,
};

export default SettingsConfigSearchByElNo;
