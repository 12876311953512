/* eslint-disable react/prop-types */
/* eslint-disable no-useless-return */
/* eslint-disable no-use-before-define */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import useCommonStyles from '../useStyles';
import PageTitle from '../../../components/PageTitle/PageTitle';
import useForm from '../../../hooks/useForm';
import useAxios from '../../../hooks/useAxios';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import {
  measureStyleType,
  positionType,
  sinteringSizePath,
  sinteringSizeType,
  specConfigItemStatus,
} from '../../../constants/enums';
import { checkSpecConfigDiff } from '../../../Utils/arrayUtil';
import regex from '../../../constants/validationRegex';
import TransInfo from './TransInfo';
import Snackbar from '../../../components/Snackbar/Snackbar';
import QCSinteringElectrical from './QCSinteringElectrical/QCSinteringElectrical';
import QCSinteringSize from './QCSinteringSize/QCSinteringSize';
import useCancelToken from '../../../hooks/useCancelToken';

const QCSinteringContainer = ({ path }) => {
  const { dialogDispatch, infoDialogDispatch } = useContext(DialogContext);
  const isMultipleTransId = useRef(false);
  const isMultipleTransQty = useRef(0);
  const [transId, setTransId] = useState('');
  const [transIdErr, setTransIdErr] = useState(false);
  const [transIdErrMsg, setTransIdErrMsg] = useState('請輸入正確的移轉單號');
  const [subTransIds, setSubTransIds] = useState([]);
  const [elecStoveSelection, setElecStoveSelection] = useState([]);
  const initialTransInfo = {
    transId: '',
    sinterCheckId: '',
    customer: '-',
    elName: '-',
    elecStoveNo: '-',
    position: '0',
    layer: '0',
    transQty: '-',
    sampleQty: 20,
    isRStick: 0,
    machineName: '-',
  };
  const {
    formData: transInfo,
    setFormData: setTransInfo,
    formErr: transInfoErr,
    setFormErr: setTransInfoErr,
    resetFormData: resetTransInfo,
    resetFormErr: resetTransInfoErr,
  } = useForm(initialTransInfo);
  const [foldingRow, setFoldingRow] = useState(true);
  const [currentSpec, setCurrentSpec] = useState([]);
  const [lastSubSpec, setLastSubSpec] = useState('');
  const [specConfig, setSpecConfig] = useState([]);
  const [diffNLMeasure, setDiffNLMeasure] = useState([]);
  const [measureStyle, setMeasureStyle] = useState(0); // For measureStyle selection render
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showPaper, setShowPaper] = useState(false);
  const [renderRow, setRenderRow] = useState([]);
  const [resultNote, setResultNote] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [warningMsg, setWarningMsg] = useState('');
  const [oneColumn, setOneColumn] = useState(false);
  const [showUnfinishAlert, setShowUnfinishAlert] = useState(true);
  const [resetTransInfoField, setResetTransInfoField] = useState(false);
  const [disabledDeleteBtn, setDisabledDeleteBtn] = useState(false);
  const [otherIsDone, setOtherIsDone] = useState(false); // 判斷巡檢還沒做過但已經做完電性＆尺寸
  const finalTransId = useRef(''); // 負責存取最後一次的 merge 移轉單號
  const currentIsRStick = useRef(0);
  const currentMeasureStyle = useRef(0); // For current measureStyle data store
  const lastMeasureStyle = useRef(0); // To determine if measureStyle has changed
  const transDefectTypes = useRef([]);
  const realTimeTransId = useRef('');
  const lastTransId = useRef('');
  const currentTransId = useRef(null);
  const currentSubTransId = useRef(null);
  const outSpecCount = useRef(0);
  const formResult = useRef(false);
  const sampleQty = useRef(0);
  const tableEl = useRef(null);
  const tableTimer = useRef(null);
  const warningTimer = useRef(null);
  const successTimer = useRef(null);
  const inspectionCheck = useRef(null);
  const inspectionSpecConfig = useRef(null);
  const realTimeInspection = useRef(null);
  const currentSubSpec = useRef('');
  // 舊的 measureConfig
  const measureConfig = useRef([]);
  // 新的 measureConfig
  const currentMeasureConfig = useRef([]);
  const ampSpec = useRef([]);
  const elecStoveNumber = useRef('0');
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();
  const styleId = useRef(path === sinteringSizePath.ELECTRICAL ? null : measureStyleType.SIZE);
  const sizeType =
    path === sinteringSizePath.INSPECTION ? sinteringSizeType.INSPECTION : sinteringSizeType.SIZE;

  const resetAllField = () => {
    setTransId('');
    resetTransInfo();
    resetTransInfoErr();
    setTransIdErr(false);
    setResultNote('');
    setRenderRow([]);
    setShowTable(false);
    setShowPaper(false);
    currentSubSpec.current = '';
    // setCurrentSubSpec('');
    setFoldingRow(true);
    setShowUnfinishAlert(true);
    setDisabledDeleteBtn(false);
    realTimeTransId.current = '';
    lastTransId.current = '';
    currentIsRStick.current = 0;
    currentTransId.current = null;
    currentSubTransId.current = null;
    lastMeasureStyle.current = 0;
    outSpecCount.current = 0;
    sampleQty.current = 0;
    inspectionCheck.current = null;
    inspectionSpecConfig.current = null;
    realTimeInspection.current = null;
    setResetTransInfoField(true);
    isMultipleTransQty.current = 0;
    finalTransId.current = '';
    isMultipleTransId.current = false;
    setOtherIsDone(false);
    elecStoveNumber.current = '0';
  };

  const resetInspectionField = () => {
    setResultNote('');
    setCurrentSpec('');
    setFoldingRow(true);
    setShowUnfinishAlert(true);
    outSpecCount.current = 0;
    const nextLayer = transInfo.layer + 1 > 5 ? 1 : transInfo.layer + 1;
    let nextPosition = transInfo.position;
    if (nextLayer === 1) {
      if (transInfo.position === positionType.BACK) nextPosition = positionType.FRONT;
      if (transInfo.position === positionType.FRONT) nextPosition = positionType.MIDDLE;
      if (transInfo.position === positionType.MIDDLE) nextPosition = positionType.BACK;
    }
    setTransInfo((prev) => ({
      ...prev,
      layer: nextLayer,
      position: nextPosition,
    }));
  };

  const resetElectricalField = () => {
    currentSubSpec.current = '';
    setResultNote('');
    setFoldingRow(true);
    outSpecCount.current = 0;
  };
  const validationTransInfo = (type) => {
    resetTransInfoErr();
    if (realTimeTransId.current.length === 0) {
      setTransIdErr(true);
      return false;
    }
    if (
      (path === sinteringSizePath.SIZE || path === sinteringSizePath.ELECTRICAL) &&
      (transInfo.elecStoveNo === '0' || transInfo.elecStoveNo === '-') &&
      type === 'blur'
    ) {
      return false;
    }
    if (
      (path === sinteringSizePath.SIZE || path === sinteringSizePath.ELECTRICAL) &&
      (transInfo.elecStoveNo === '0' || transInfo.elecStoveNo === '-')
    ) {
      setTransInfoErr((prev) => ({
        ...prev,
        elecStoveNo: true,
      }));
      return false;
    }

    if (path !== sinteringSizePath.INSPECTION) return true;
    if (transInfo.position === '0') {
      setTransInfoErr((prev) => ({
        ...prev,
        position: true,
      }));
      return false;
    }
    if (transInfo.layer === '0') {
      setTransInfoErr((prev) => ({
        ...prev,
        layer: true,
      }));
      return false;
    }
    return true;
  };
  const handleShowTable = (type) => {
    resetTransInfoErr();
    setShowPaper(true);
    if (!validationTransInfo(type)) return;
    setShowTable(false);
    setIsLoading(true);
    clearTimeout(tableTimer.current);
    tableTimer.current = setTimeout(() => {
      setIsLoading(false);
      setShowTable(true);
      clearTimeout(tableTimer.current);
    }, 1000);
  };
  // 取得合併子單號長度
  const getSubMergeTransId = (id) => {
    const mergedIds = id
      .trim()
      .split(',')
      .map((item) => item.substring(0, 10))
      .toString()
      .replaceAll(',', '');
    return mergedIds.length;
  };

  const handleTransIdChange = (value, fromSubTransId = false) => {
    resetTransInfo();
    if (transIdErr) {
      setTransIdErr(false);
    }
    setMeasureStyle(0);
    setTransId(value);
    realTimeTransId.current = value;
    if (value.length === 10 && !regex.SINTERING_TRANS_ID.test(value)) {
      setTransIdErr(true);
      return;
    }
    if ((fromSubTransId || value.length === 10) && finalTransId.current === '') {
      handleTransBlur({
        pass: true,
        callByMeasureStyleChange: true,
        type: 'blur',
      });
    }
    isMultipleTransId.current = false;
    // 判斷merge單選 輸入格式對不對
    const isMerge = realTimeTransId.current.includes(',') && !realTimeTransId.current.includes('-');
    const isSubMerge =
      realTimeTransId.current.includes(',') && realTimeTransId.current.includes('-');

    // 合併單號
    if (isMerge) {
      if (
        realTimeTransId.current.trim().replaceAll(',', '').length % 10 === 0 &&
        realTimeTransId.current.substring(realTimeTransId.current.length - 1) !== ','
      ) {
        handleTransBlur({
          pass: true,
          callByMeasureStyleChange: true,
          type: 'blur',
          isMultiple: true,
        });
        isMultipleTransId.current = true;
      }
      return;
    }

    // 合併子單號
    if (isSubMerge) {
      if (
        getSubMergeTransId(realTimeTransId.current) % 10 === 0 &&
        realTimeTransId.current.substring(realTimeTransId.current.length - 1) !== ','
      ) {
        handleTransBlur({
          pass: true,
          callByMeasureStyleChange: true,
          type: 'blur',
          isMultiple: true,
        });
        isMultipleTransId.current = true;
      }
      return;
    }
  };

  const handleTransInfoChange = (e) => {
    resetTransInfoErr();
    setTransInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === 'elecStoveNo') {
      elecStoveNumber.current = e.target.value;
    }
  };

  const handleMeasureStyleChange = (e) => {
    setMeasureStyle(e.target.value);
    currentMeasureStyle.current = e.target.value;
  };

  const handleSubSpecChange = (specRecipe) => {
    // Only for measureStyle DB
    if (showTable && currentSubSpec.current !== '' && currentSubSpec.current !== lastSubSpec) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '檢測資料尚未儲存',
          message:
            '目前的檢測資料尚未儲存，點擊確認將會放棄此次編輯！若您要保存檢測資料，請先關閉要保存檢測資料，請先關閉此對話框，並點選下方【送出】按鈕儲存！',
          handleConfirm: () => {
            // eslint-disable-next-line no-use-before-define
            // setCurrentSubSpec(specRecipe);
            currentSubSpec.current = specRecipe;
          },
        },
      });
      return;
    }
    setLastSubSpec(specRecipe);
    currentSubSpec.current = specRecipe;
    // setCurrentSubSpec(specRecipe);
  };

  const handleResultNoteChange = (e) => {
    setResultNote(e.target.value);
  };

  const toggleFoldingRow = () => {
    setFoldingRow((prev) => !prev);
  };
  const openWarningSnackBar = (msg) => {
    setWarningMsg(msg);
    window.scrollTo({
      top: 300,
      behavior: 'smooth',
    });
    clearTimeout(warningTimer.current);
    warningTimer.current = setTimeout(() => {
      setWarningMsg('');
      clearTimeout(warningTimer.current);
    }, 6000);
  };

  const openSuccessSnackBar = (msg) => {
    setSuccessMsg(msg);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    clearTimeout(successTimer.current);
    successTimer.current = setTimeout(() => {
      setSuccessMsg('');
      clearTimeout(successTimer.current);
    }, 3000);
  };

  const setSpecData = (specConfigList) => {
    const sample = path === sinteringSizePath.INSPECTION ? 20 : sampleQty.current;
    if (path === sinteringSizePath.ELECTRICAL) {
      const specItemsFilter = specConfigList.filter(
        (spec) => spec.styleId === currentMeasureStyle.current
      );
      setRenderRow(
        Array.from({ length: sample }, (v, i) => ({
          rowId: i,
          specItems: specItemsFilter.map((spec) => ({
            specId: spec.specId,
            specItem: spec.specItem,
            specRecipe: spec.specRecipe,
            value: '',
          })),
          defectType: [...transDefectTypes.current],
        }))
      );
      return;
    }
    setRenderRow(
      Array.from({ length: sample }, (v, i) => ({
        rowId: i,
        specItems: specConfigList.map((spec) => ({
          specId: spec.specId,
          specItem: spec.specItem,
          value: '',
        })),
        defectType: [...transDefectTypes.current],
      }))
    );
  };
  const getSpecDetail = async (props) => {
    const { sinterCheckArr, specConfigList } = props;
    let { callBySubSpecChange, type } = props;
    callBySubSpecChange = callBySubSpecChange || false;
    type = type || 'click';

    const subTransId = sinterCheckArr.find((item) => item.transId === realTimeTransId.current);
    currentSubTransId.current = subTransId;
    const cancelToken = newCancelToken();
    if ((type === 'blur' || type === undefined) && path === sinteringSizePath.ELECTRICAL) {
      if (currentIsRStick.current === 0) {
        currentMeasureStyle.current = measureStyleType.Z;
        lastMeasureStyle.current = measureStyleType.Z;
      }
      if (currentIsRStick.current === 1) {
        lastMeasureStyle.current = measureStyleType.N;
        currentMeasureStyle.current = measureStyleType.N;
      }
    }
    const data = {
      styleId: currentMeasureStyle.current,
      sizeType: path === sinteringSizePath.ELECTRICAL ? 0 : sizeType,
    };
    if (!subTransId) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得檢測資料',
          message: '目前無法取得檢測資料，請稍後再試，或請通知網管人員！',
          handleConfirm: () => resetAllField(),
        },
      });
      return;
    }
    if (subTransId.mergedCheckIds && realTimeTransId.current.includes(',')) {
      data.mergedCheckIds = subTransId.mergedCheckIds;
    } else {
      data.sinterCheckId = subTransId.sinterCheckId;
    }
    if (path === sinteringSizePath.INSPECTION) {
      data.position = transInfo.position !== '0' ? transInfo.position : positionType.FRONT;
      data.level = transInfo.layer !== '0' ? transInfo.layer : 1;
    }
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getSinterCheck',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { sinterCheck, sinterRow } = result;
      const {
        sinterPosition,
        sinterLevel,
        isPass,
        checkComment,
        sizeComment,
        elecComment,
        sizeUser,
        elecUser,
        elSizeMergeNo,
      } = sinterCheck;
      const dataSpecConfig = sinterRow[0].checkDetailList;
      measureConfig.current = dataSpecConfig[0]?.measureConfig;
      dataSpecConfig.forEach((spec) => {
        if (ampSpec.current.includes(spec.measureRecipe)) return;
        ampSpec.current.push(spec.measureRecipe);
      });

      const currentSpecConfigList = specConfigList.filter(
        (spec) => spec.styleId === currentMeasureStyle.current
      );
      if (currentMeasureStyle.current === measureStyleType.DB) {
        let subSpec;
        if (sinterRow[0].checkDetailList.length === 0) {
          // 預設Amp（尚未輸入資料時）
          subSpec = currentSpecConfigList.find((item) => item.isEnabled).specRecipe;
        } else {
          // 預設Amp（已輸入資料時）
          subSpec = sinterRow[0].checkDetailList[0].measureRecipe;
        }
        currentSubSpec.current = subSpec;
      }
      const { modifiedCurrentSpecConfig, currentDiff, dataDiff } = checkSpecConfigDiff(
        currentSpecConfigList && currentSpecConfigList,
        dataSpecConfig
      );
      setTransInfo((prev) => ({
        ...prev,
        sinterCheckId: subTransId.sinterCheckId,
        mergedCheckIds: subTransId.mergedCheckIds,
        rowMapperId: sinterRow[0].rowMapperId,
        elSizeMergeNo,
      }));
      realTimeInspection.current = {
        position: sinterPosition || transInfo.position,
        layer: sinterLevel || transInfo.layer,
      };
      lastTransId.current = realTimeTransId.current;
      if (path === sinteringSizePath.INSPECTION) {
        setResultNote(checkComment);
      }
      if (path === sinteringSizePath.SIZE) {
        setResultNote(sizeComment);
      }
      if (path === sinteringSizePath.ELECTRICAL) {
        setResultNote(elecComment);
      }
      formResult.current = !!isPass;
      if (sinterRow.length <= 1) {
        const enabledSpecConfig = specConfigList.filter((spec) => spec.isEnabled);
        setSpecConfig(enabledSpecConfig);
        setSpecData(enabledSpecConfig);
      } else {
        setSpecConfig(modifiedCurrentSpecConfig);
        setRenderRow(
          sinterRow.map((row, idx) => {
            const specItems = row.checkDetailList.map((col) => {
              const targetSpec = modifiedCurrentSpecConfig.find(
                (spec) => spec.specId === col.measureId.toString()
              );
              // 只有data有的部分，要標示為已刪除
              const targetItem = dataDiff.find((item) => item.specId === col.measureId.toString());
              return {
                specId: col.measureId.toString(),
                specItem: targetSpec ? targetSpec.specItem : col.measureId,
                value: col.detailValue,
                detailId: col.sinterDetailId,
                sinterId: row.sinterRowId,
                specRecipe: col.measureRecipe,
                colStatus: targetItem ? specConfigItemStatus.REMOVED : specConfigItemStatus.CURRENT,
              };
            });
            setDiffNLMeasure(dataDiff);
            currentDiff.forEach((item) =>
              specItems.push({
                specId: item.specId,
                specItem: item.specItem,
                value: '',
                detailId: 0,
                sinterId: 0,
                specRecipe: item.specRecipe,
                colStatus: specConfigItemStatus.ADDED,
              })
            );
            const sortedSpecItems = specItems.sort(
              (a, b) => b.colStatus - a.colStatus || a.specId - b.specId
            );
            const rowDetail = {
              rowId: idx,
              sinterRowId: row.sinterRowId,
              sinterCheckId: row.sinterCheckId,
              specItems: sortedSpecItems,
            };
            if (path !== sinteringSizePath.ELECTRICAL) {
              // eslint-disable-next-line no-shadow
              rowDetail.defectType = row.checkDetectList.map((type) => ({
                name: type.detectId.toString(),
                label: type.detectName,
                checked: type.isPromise,
              }));
            }
            return rowDetail;
          })
        );
      }
      setOtherIsDone(!!sizeUser || !!elecUser);
      if ((!!sizeUser || !!elecUser) && path === sinteringSizePath.INSPECTION) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '無法更新檢測資料',
            message: '此單號已進行燒結尺寸／電性檢測，不得再輸入燒結巡檢數據',
            singleBtn: true,
          },
        });
      }
      if (callBySubSpecChange) handleShowTable(type);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得檢測資料',
          message: noPrivilegeMsg || '目前無法取得檢測資料，請稍後再試，或請通知網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  const validationInspectionFetch = (pass) => {
    if (pass) return true;
    // 判斷是否為合併子單號
    const isMultiple = transId.includes(',') ? transId.split(',') : false;
    if (transId.length === 0) {
      setTransIdErr(true);
      return false;
    }
    // 不是合併子單號 ＆ 不符合燒結移轉單號 ＆ 不符合燒結移轉子單號
    if (
      !isMultiple &&
      !regex.SINTERING_TRANS_ID.test(transId) &&
      !regex.SINTERING_SUBTRANS_ID.test(transId)
    ) {
      setTransIdErr(true);
      return false;
    }
    // 是合併子單號 ＆ 不符合多張燒結移轉子單號
    if (isMultiple.length > 1 && !validationMultipleTransIds()) {
      setTransIdErr(true);
      return false;
    }
    if (transInfo.elecStoveNo === '0') {
      setTransInfoErr((prev) => ({
        ...prev,
        elecStoveNo: true,
      }));
      return false;
    }
    if (
      lastTransId.current === realTimeTransId.current &&
      realTimeInspection.current &&
      realTimeInspection.current.position === transInfo.position &&
      realTimeInspection.current.layer === transInfo.layer
    ) {
      return false;
    }
    if (
      showTable &&
      realTimeInspection.current &&
      (realTimeInspection.current.position !== transInfo.position ||
        realTimeInspection.current.layer !== transInfo.layer)
    ) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '檢測資料尚未儲存',
          message:
            '目前的檢測資料尚未儲存，點擊確認將會放棄此次編輯！若您要保存檢測資料，請先關閉要保存檢測資料，請先關閉此對話框，並點選下方【送出】按鈕儲存！',
          handleConfirm: () => {
            // eslint-disable-next-line no-use-before-define
            handleInspectionFetch(null, true);
          },
        },
      });
      return false;
    }
    return true;
  };

  const handleInspectionFetch = (e, pass = false) => {
    resetTransInfoErr();
    setTransIdErr(false);
    if (!validationInspectionFetch(pass)) return;
    setShowTable(false);
    handleTransBlur({
      pass: true,
      callByMeasureStyleChange: true,
    });
  };

  const validationMultipleTransIds = () =>
    realTimeTransId.current
      .split(',')
      .filter((x) => x)
      .every((id) => regex.SINTERING_TRANS_ID.test(id) || regex.SINTERING_SUBTRANS_ID.test(id));

  const validationTransId = (props) => {
    let { pass, callBySubSpecChange, isMultiple } = props;
    pass = pass || false;
    callBySubSpecChange = callBySubSpecChange || false;
    isMultiple = isMultiple || false;

    if (!pass && !callBySubSpecChange && realTimeTransId.current === currentTransId.current) {
      return false;
    }
    if (realTimeTransId.current.length === 0) {
      return false;
    }
    if (
      !isMultiple &&
      path !== sinteringSizePath.INSPECTION &&
      !regex.SINTERING_TRANS_ID.test(realTimeTransId.current) &&
      !regex.SINTERING_SUBTRANS_ID.test(realTimeTransId.current)
    ) {
      setTransIdErr(true);
      return false;
    }
    if (isMultiple && !validationMultipleTransIds()) {
      setTransIdErr(true);
      return false;
    }
    if (!pass && showTable && showUnfinishAlert) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '檢測資料尚未儲存',
          message:
            '目前的檢測資料尚未儲存，點擊確認將會放棄此次編輯！若您要保存檢測資料，請先關閉要保存檢測資料，請先關閉此對話框，並點選下方【送出】按鈕儲存！',
          handleConfirm: () => {
            setShowTable(false);
            // 進行下一次request前先將currentMeasureStyle跟measureStyle設為預設值 -> 電性檢測時預設為N，巡檢與尺寸檢測時預設為SIZE
            if (path === sinteringSizePath.ELECTRICAL) {
              currentMeasureStyle.current = measureStyleType.N;
              setMeasureStyle(measureStyleType.N);
            } else {
              currentMeasureStyle.current = measureStyleType.SIZE;
            }
            // eslint-disable-next-line no-use-before-define
            handleTransBlur({ pass: true });
          },
        },
      });
      return false;
    }
    return true;
  };

  const isNotRepeatTransId = (transIds) => {
    const isRepeat = transIds
      .split(',')
      .filter((element, index, arr) => arr.indexOf(element) === index);
    return isRepeat;
  };

  const handleTransBlur = async (props) => {
    let { pass, callByMeasureStyleChange, isMultiple } = props;
    const { type } = props;
    pass = pass || false;
    callByMeasureStyleChange = callByMeasureStyleChange || false;
    isMultiple = isMultiple || false;

    if (
      !validationTransId({
        pass,
        callBySubSpecChange: callByMeasureStyleChange,
        isMultiple: isMultiple || isMultipleTransId.current,
      })
    )
      return;
    if (type === 'blur') setMeasureStyle(0);
    setResultNote('');
    currentTransId.current = realTimeTransId.current;
    lastMeasureStyle.current = currentMeasureStyle.current;
    const currentStyleId =
      callByMeasureStyleChange || pass
        ? currentMeasureStyle.current !== 0
          ? currentMeasureStyle.current
          : styleId.current
        : path !== sinteringSizePath.ELECTRICAL
        ? measureStyleType.SIZE
        : measureStyleType.N;
    const cancelToken = newCancelToken();
    const [status, result, rtCode] = await useAxios(
      '/getSinterBase',
      {
        transId: realTimeTransId.current,
        styleId: path === sinteringSizePath.ELECTRICAL && type === 'blur' ? -1 : currentStyleId,
        sizeType:
          path === sinteringSizePath.INSPECTION ? 1 : path === sinteringSizePath.SIZE ? 2 : 3,
      },
      true,
      cancelToken
    );
    if (status) {
      const { check, machine, transfer, measure, detect, isLMeasure, sampleCount } = result;
      const {
        customerAlias,
        prodName,
        machineName,
        isRStick,
        transQuantity,
        elecStoveNo,
        materialName,
      } = transfer[0];
      isMultipleTransQty.current = 0;
      finalTransId.current = '';

      sampleQty.current = sampleCount;

      if (path !== sinteringSizePath.INSPECTION && !elecStoveNo) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '燒結移轉單尚未送出',
            message: '此移轉單尚未完成燒結，不得輸入燒結尺寸/電性！',
            singleBtn: true,
          },
        });
        return;
      }

      if (transfer.length > 1) {
        // 若輸入框最後的值不是「,」就讓輸入框自動呈現合併單號
        if (realTimeTransId.current.trim().substring(realTimeTransId.current.length - 1) !== ',') {
          const value = transfer.map((item) => item.transId).toString();
          realTimeTransId.current = value;
          finalTransId.current = value;
          setTransId(realTimeTransId.current);
        }
        isMultipleTransQty.current = transfer
          .map((item) => item.transQuantity)
          .reduce((a, b) => a + b);
      }

      // 不能讓巡檢站合併
      // 將輸入框跟realTimeTransId變回輸入框第一個的transId（這裡由後端給前端）
      if (path === sinteringSizePath.INSPECTION && transfer.length === 1) {
        realTimeTransId.current = transfer[0].transId;
        setTransId(realTimeTransId.current);
      }

      if (!transQuantity) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '未完成壓型',
            message: '此移轉單未完成壓型移轉單更新，無法做燒結尺寸檢測！',
            singleBtn: true,
          },
        });
        return;
      }
      if (!measure || measure.length === 0) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '未設定量測項目',
            message: '此料號尚未設定相關的量測項目，請聯絡網管人員先建立相關的量測項目！',
            singleBtn: true,
          },
        });
        return;
      }

      currentIsRStick.current = isRStick;
      // 清空上一次送出的 NL 量測規格
      setDiffNLMeasure([]);
      ampSpec.current = [];

      // eslint-disable-next-line no-unused-expressions
      measure.filter((style) => style.styleName !== '線圈設定').length === 1
        ? setOneColumn(true)
        : setOneColumn(false);
      setTransInfo((prev) => ({
        ...prev,
        transId: realTimeTransId.current,
        isRStick,
        customer: customerAlias,
        elName: `${prodName}(${materialName})`,
        elecStoveNo: elecStoveNo || elecStoveNumber.current,
        transQty:
          isMultiple || isMultipleTransId.current
            ? transfer.map((item) => item.transQuantity).reduce((a, b) => a + b)
            : transQuantity || '-',
        position: prev.position === '0' ? positionType.FRONT : prev.position,
        layer: prev.layer === '0' ? 1 : prev.layer,
        machineName: machineName || '0',
        isLMeasure,
      }));
      if (detect && detect.length > 0) {
        // eslint-disable-next-line no-shadow
        transDefectTypes.current = detect.map((type) => ({
          name: type.detectId.toString(),
          label: type.detectName,
          checked: false,
        }));
      }
      setSubTransIds(
        check
          .filter((item) => item.transId !== '')
          .map((item) => ({
            transId: item.transId,
            sinteringId: item.sinterCheckId,
            mergedIds: item.mergedCheckIds,
          }))
      );
      if (machine && machine.length > 0) {
        setElecStoveSelection(
          machine.map((item) => ({
            name: item.machineName,
            value: item.machineId,
          }))
        );
      }
      // 取出「線圈、星號」且是 isEnabled 的資料
      currentMeasureConfig.current = measure
        .filter(
          (spec) =>
            (spec.styleId === measureStyleType.COIL || spec.styleId === measureStyleType.STAR) &&
            spec.isEnabled
        )
        .map((spec) => ({
          measureId: spec.measureId,
          styleId: spec.styleId,
        }));
      const specConfigList = measure.map((spec) => ({
        specId: spec.measureId.toString(),
        specItem: spec.measureItem.trim(),
        specTarget: spec.measureTarget.trim() || '',
        specUpper: spec.measureUpperSpec.trim() || '',
        specLower: spec.measureLowerSpec.trim() || '',
        specBias: spec.measureDeviation.trim(),
        limitUpper: spec.measureUpperLimit.trim() || '',
        limitLower: spec.measureLowerLimit.trim() || '',
        specRecipe: spec.measureRecipe.trim() || '',
        styleId: spec.styleId,
        styleName: spec.styleName.trim(),
        isEnabled: !!spec.isEnabled,
        measureConfig: spec.measureConfig || currentMeasureConfig.current,
      }));
      setCurrentSpec(specConfigList);
      if (path === sinteringSizePath.INSPECTION) {
        inspectionCheck.current = check.filter((item) => item.transId !== '');
      } else {
        inspectionCheck.current = check;
      }
      inspectionSpecConfig.current = specConfigList;
      getSpecDetail({
        sinterCheckArr: inspectionCheck.current,
        specConfigList,
        callBySubSpecChange: callByMeasureStyleChange,
        type,
      });
    } else {
      if (isMultiple) {
        const lastTransIds = realTimeTransId.current.split(',');
        lastTransIds.pop();
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '無法取得移轉單資料',
            message: result,
            singleBtn: true,
            handleConfirm: () =>
              rtCode === 'C01'
                ? setTransId(isNotRepeatTransId(realTimeTransId.current).toString())
                : setTransId(lastTransIds.toString()),
          },
        });
        return;
      }
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: rtCode === 'C01' ? '壓型移轉單尚未送出' : '無法取得移轉單資料',
          message:
            rtCode === 'C01'
              ? result
              : '目前無法取得該移轉單號之資料，請稍後再試，或請通知網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  const validationSubSpec = () => {
    if (path === sinteringSizePath.ELECTRICAL) {
      if (!showTable) return true;
      if (currentMeasureStyle === 0) {
        return false;
      }
      if (currentMeasureStyle.current === lastMeasureStyle.current && !isMultipleTransId.current) {
        return false;
      }
    }
    return true;
  };

  const handleFetchDifferentMeasureStyle = () => {
    if (!validationSubSpec()) return;
    setShowTable(false);
    handleTransBlur({
      pass: true,
      callByMeasureStyleChange: true,
    });
  };

  const handleDBMeasureStyleFormSubmit = (rowData) =>
    // 1. rowData帶的是目前要儲存的是currentSubSpec的那個Amp分頁的資料 -> specItems中只有specRecipe === currentSubSpec的column
    // 2. renderRow是完整的row資料 -> specItems中有所有的column
    // 3. map renderRow, 檢查每一row內的specItems的每個spec, 若specRecipe === currentSubSpec, 將該spec的資料替換成rowData中的資料, 若不是currentSubSpec的話, 則保留原本renderRow中的資料
    renderRow.map((row) => ({
      ...row,
      specItems: row.specItems.map((spec) => {
        if (
          spec.specRecipe === currentSubSpec.current &&
          spec.colStatus !== specConfigItemStatus.REMOVED
        ) {
          const targetRow = rowData.find((item) => item.rowId === row.rowId);
          const targetSpec = targetRow.specItems.find((item) => item.specId === spec.specId);
          return targetSpec;
        }
        return spec;
      }),
    }));

  const handleSaveForm = async (rowData) => {
    infoDialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '資料儲存中',
        message: '資料儲存中，請稍候...',
        noBtn: true,
      },
    });
    let requestRowData = rowData;
    if (path === sinteringSizePath.ELECTRICAL && measureStyle === measureStyleType.DB) {
      requestRowData = handleDBMeasureStyleFormSubmit(rowData);
    }
    const {
      mergedCheckIds,
      sinterCheckId,
      position,
      layer,
      elecStoveNo,
      transQty,
      machineName,
      elSizeMergeNo,
    } = transInfo;

    // 判斷送出時transId最後一個字是否為「,」，若是的話需把「,」拿掉再送出
    realTimeTransId.current =
      realTimeTransId.current.substring(realTimeTransId.current.length - 1) !== ','
        ? realTimeTransId.current
        : realTimeTransId.current.substring(0, realTimeTransId.current.length - 1);
    setTransId(realTimeTransId.current);

    const sinterCheck = {
      mergedCheckIds,
      sinterCheckId,
      transId: realTimeTransId.current,
      elecStoveNo,
      machineName,
      elSizeMergeNo,
    };
    if (path === sinteringSizePath.INSPECTION) {
      sinterCheck.sinterPosition = position;
      sinterCheck.sinterLevel = layer;
    }
    // 多個移轉單單號
    if (mergedCheckIds && path !== sinteringSizePath.INSPECTION) {
      sinterCheck.mergeQuantity = transQty === '-' ? 0 : transQty;
    }
    // 單個移轉單單號
    if (sinterCheckId) {
      sinterCheck.transQuantity = transQty === '-' ? 0 : transQty;
    }
    if (path === sinteringSizePath.INSPECTION) {
      sinterCheck.isPass = formResult.current ? 1 : 0;
      sinterCheck.checkComment = resultNote;
    }
    if (path === sinteringSizePath.SIZE) {
      sinterCheck.isPassSize = formResult.current ? 1 : 0;
      sinterCheck.sizeComment = resultNote;
    }
    if (path === sinteringSizePath.ELECTRICAL) {
      sinterCheck.isPassElec = formResult.current ? 1 : 0;
      sinterCheck.elecComment = resultNote;
    }
    const currentStyleId =
      path === sinteringSizePath.ELECTRICAL ? lastMeasureStyle.current : measureStyleType.SIZE;
    const data = {
      sinterCheck,
      sinterRow: requestRowData.map((row) => {
        const rowDetail = {
          rowMapperId: transInfo.rowMapperId,
          sinterCheckId,
          sizeType,
          styleId: currentStyleId,
          sinterRowId: row.sinterRowId || 0,
          checkDetailList: row.specItems
            .filter(
              (item) =>
                !(
                  item.colStatus === specConfigItemStatus.REMOVED &&
                  item.specRecipe === currentSubSpec.current
                )
            )
            .map((spec) => ({
              sinterDetailId: spec.detailId || 0,
              sinterRowId: row.sinterRowId || 0,
              measureId: spec.specId,
              detailValue: spec.value,
              measureConfig: currentMeasureConfig.current,
            })),
        };
        if (path === sinteringSizePath.INSPECTION || path === sinteringSizePath.ELECTRICAL) {
          if (!row.isEmpty) {
            // 判斷每一row有沒有超過限制範圍
            rowDetail.isPass = row.isOutSpec ? 0 : 1;
          } else {
            rowDetail.isPass = -1;
          }
        }
        if (path !== sinteringSizePath.ELECTRICAL) {
          rowDetail.checkDetectList = row.defectType.map((type) => ({
            detectId: type.name,
            isPromise: type.checked,
          }));
        }
        if (path === sinteringSizePath.SIZE) {
          // 判斷每一row有沒有超過限制範圍
          rowDetail.isPass = row.isOutSpec ? 0 : 1;
        }
        return rowDetail;
      }),
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/sinterCheckSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      setShowTable(false);
      infoDialogDispatch({
        type: dialogActions.CLOSE,
      });
      openSuccessSnackBar(`移轉單號 ${transId} 的燒結檢測儲存成功！`);
      if (path === sinteringSizePath.ELECTRICAL) {
        resetElectricalField();
        handleTransBlur({ pass: true });
      }
      if (path === sinteringSizePath.INSPECTION) {
        resetInspectionField();
        handleTransBlur({ pass: true });
      }
    } else {
      setShowUnfinishAlert(false);
      infoDialogDispatch({
        type: dialogActions.CLOSE,
      });
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法儲存檢測資料',
          message:
            result || noPrivilegeMsg || '目前暫時無法儲存檢測資料，請稍後再試，或請通知網管人員！',
          singleBtn: true,
        },
      });
    }
  };

  const validationRowData = (rowData) => {
    const inValidRowIndex = [];
    const hasInvalidRow = rowData.map((row, idx) => {
      const { isInvalid, isEmpty } = row;
      if ((!isInvalid && !isEmpty) || (isInvalid && isEmpty)) {
        // 所有欄位都有填跟所有欄位都不填都算valid
        return true;
      }
      inValidRowIndex.push(idx + 1);
      setRenderRow((prev) =>
        prev.map((item) => {
          if (item.rowId === row.rowId) {
            const rowDetail = {
              ...item,
              specItems: [...row.specItems],
              isInvalid: true,
            };
            if (path !== sinteringSizePath.ELECTRICAL) {
              rowDetail.defectType = [...row.defectType];
            }
            return rowDetail;
          }
          return item;
        })
      );
      return false;
    });
    if (inValidRowIndex.length > 0) {
      let msg = '';
      if (inValidRowIndex.length < 10) {
        msg = `請注意！！第 ${inValidRowIndex.join(
          ', '
        )} 筆量測資料沒有輸入完全，量測結果不得送出！！`;
      } else {
        msg = `請注意！！共有 ${inValidRowIndex.length} 筆量測資料沒有輸入完全，量測結果不得送出！！`;
      }
      openWarningSnackBar(msg);
    }
    return hasInvalidRow.every((x) => x);
  };

  const handleEmptyRow = (emptyRowIdx, rowData) => {
    const ngNum = outSpecCount.current;

    if (emptyRowIdx.length === 0) return;
    if (path === sinteringSizePath.INSPECTION || path === sinteringSizePath.ELECTRICAL) {
      if (emptyRowIdx.includes(1)) {
        openWarningSnackBar('請從第一筆資料開始填寫！');
        return;
      }
      const sampleCount = path === sinteringSizePath.INSPECTION ? 20 : sampleQty.current;
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '您是否確定要送出？',
          message: `總共量測 ${sampleCount} 筆， ${ngNum} 筆NG，第 ${emptyRowIdx.join(
            ', '
          )} 筆資料為空，按下確認後，資料將寫入資料庫`,
          handleConfirm: () => handleSaveForm(rowData),
        },
      });
      return;
    }
    if (path === sinteringSizePath.SIZE) {
      let msg = '';
      if (emptyRowIdx.length < 10) {
        msg = `請注意！！第 ${emptyRowIdx.join(', ')} 筆量測資料為空，量測結果不得送出！！`;
      } else {
        msg = `請注意！！共有 ${emptyRowIdx.length} 筆量測資料為空，量測結果不得送出！！`;
      }
      openWarningSnackBar(msg);
    }
  };

  const validationEmptyRow = (rowData, validRowData) => {
    const emptyRowIndex = [];
    const hasEmptyRow = rowData.map((row, idx) => {
      const { isInvalid, isEmpty, isOutSpec } = row;
      if (isOutSpec) {
        outSpecCount.current += 1;
      }
      if (!isInvalid && !isEmpty) {
        return true;
      }
      emptyRowIndex.push(idx + 1);
      setRenderRow((prev) =>
        prev.map((item) => {
          if (item.rowId === row.rowId) {
            const rowDetail = {
              ...item,
              specItems: [...row.specItems],
              isEmpty: true,
            };
            if (path !== sinteringSizePath.ELECTRICAL) {
              rowDetail.defectType = [...row.defectType];
            }
            return rowDetail;
          }
          return item;
        })
      );
      return false;
    });
    handleEmptyRow(emptyRowIndex, validRowData);
    return hasEmptyRow.every((x) => x);
  };

  const validationOutSpec = (validRowData) => {
    const ngNum = outSpecCount.current;
    if (outSpecCount.current > 0) {
      const sampleCount = path === sinteringSizePath.INSPECTION ? 20 : sampleQty.current;
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '您是否確定要送出？',
          message: `總共量測 ${sampleCount} 筆， ${ngNum} 筆NG，按下確認後，資料將寫入資料庫`,
          handleConfirm: () => handleSaveForm(validRowData),
        },
      });
      return false;
    }
    return true;
  };

  const handleFormSubmit = () => {
    resetTransInfoErr();
    if (!validationTransInfo()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }
    // Gather all data from every row
    const allRowsData = renderRow.map((item) => item.gatherData(true));
    outSpecCount.current = 0;
    formResult.current = !allRowsData.some((row) => row.isOutSpec);
    if (!validationRowData(allRowsData)) return;
    const validAllRowsData = renderRow.map((item) => item.gatherData()); // 儲存時僅儲存colStatus
    if (!validationEmptyRow(allRowsData, validAllRowsData)) return;
    if (!validationOutSpec(validAllRowsData)) return;
    handleSaveForm(validAllRowsData);
  };

  const handleFocusChange = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      const nextTabIndex = e.target.tabIndex + 1;
      const nextEl = document.querySelectorAll(`[tabindex="${nextTabIndex}"]`)[1];
      if (!nextEl) return;
      nextEl.focus();
    }
  };
  const handleDelete = async () => {
    setDisabledDeleteBtn(true);
    const cancelToken = newCancelToken();
    const data =
      path === sinteringSizePath.INSPECTION
        ? {
            transId,
            sinterCheckId: transInfo.sinterCheckId,
            rowMapperId: transInfo.rowMapperId,
            position: transInfo.position,
            level: transInfo.layer,
            sizeType,
          }
        : path === sinteringSizePath.SIZE
        ? {
            transId: realTimeTransId.current,
            rowMapperId: transInfo.rowMapperId,
            sinterCheckId: transInfo.sinterCheckId,
            sizeType: 2,
          }
        : {
            transId: realTimeTransId.current,
            rowMapperId: transInfo.rowMapperId,
            sinterCheckId: transInfo.sinterCheckId,
            sizeType: 3,
            styleId: currentMeasureStyle.current,
          };
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/deleteTransferQcData',
      data,
      true,
      cancelToken
    );
    if (status) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '刪除成功',
          message: `已刪除${realTimeTransId.current}的資料！`,
          singleBtn: true,
          handleConfirm: resetAllField,
        },
      });
      setDisabledDeleteBtn(false);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法刪除',
          message:
            rtCode === 'C01'
              ? result
              : noPrivilegeMsg ||
                `目前暫時無法刪除${realTimeTransId.current}的資料請稍後再試，或請通知網管人員！`,
          singleBtn: true,
        },
      });
      setDisabledDeleteBtn(false);
    }
  };

  const handleShowConfirmDelete = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: `確認刪除？`,
        message: `刪除之後無法復原，確認刪除${realTimeTransId.current}的此筆資料嗎？`,
        singleBtn: false,
        handleConfirm: handleDelete,
      },
    });
  };

  // 監聽是否為合併檢測單
  useEffect(() => {
    if (
      path !== sinteringSizePath.INSPECTION &&
      transId.includes(',') &&
      (realTimeTransId.current.trim().replaceAll(',', '').length % 10 === 0 ||
        getSubMergeTransId(realTimeTransId.current) % 10 === 0)
    ) {
      isMultipleTransId.current = true;
    }
    if (path !== sinteringSizePath.INSPECTION && !transId.includes(',')) {
      isMultipleTransId.current = false;
    }
    if (finalTransId.current !== transId) {
      setShowTable(false);
    }

    if (transId.length === 0) {
      // 清空數量的數字
      isMultipleTransQty.current = 0;
      finalTransId.current = '';
      // 清空子單號
      setSubTransIds([]);
    }
  }, [transId]);

  useEffect(() => {
    if (showTable && tableEl.current) {
      tableEl.current.addEventListener('keydown', handleFocusChange);
    }

    return () => {
      if (!tableEl.current) return;
      tableEl.current.removeEventListener('keydown', handleFocusChange);
    };
  }, [showTable]);

  useEffect(() => {
    if (path !== sinteringSizePath.ELECTRICAL) {
      currentMeasureStyle.current = measureStyleType.SIZE;
      lastMeasureStyle.current = measureStyleType.SIZE;
      return;
    }
    if (transInfo.isRStick === 0) {
      // setMeasureStyle(measureStyleType.Z);
      currentMeasureStyle.current = measureStyleType.Z;
      lastMeasureStyle.current = measureStyleType.Z;
      return;
    }
    lastMeasureStyle.current = measureStyleType.N;
    currentMeasureStyle.current = measureStyleType.N;
  }, [transInfo.isRStick, transId]);

  useEffect(() => {
    resetAllField();
  }, [path]);

  useEffect(
    () => () => {
      clearTimeout(tableTimer.current);
      clearTimeout(warningTimer.current);
      clearTimeout(successTimer.current);
    },
    []
  );

  return (
    <div className={commonClasses.main}>
      {path === sinteringSizePath.INSPECTION && <PageTitle title="燒結巡檢" />}
      {path === sinteringSizePath.SIZE && <PageTitle title="燒結尺寸檢測" />}
      {path === sinteringSizePath.ELECTRICAL && <PageTitle title="燒結電性檢測" />}
      <TransInfo
        transId={transId}
        transIdErr={transIdErr}
        subTransIds={subTransIds}
        elecStoveSelection={elecStoveSelection}
        multipleTransQty={isMultipleTransQty.current}
        transInfo={transInfo}
        transInfoErr={transInfoErr}
        handleFetch={
          path === sinteringSizePath.INSPECTION
            ? handleInspectionFetch
            : handleFetchDifferentMeasureStyle
        }
        handleTransIdChange={handleTransIdChange}
        handleTransInfoChange={handleTransInfoChange}
        path={path}
        resetTransInfo={resetTransInfoField}
        setResetTransInfo={setResetTransInfoField}
        transIdErrMsg={transIdErrMsg}
        setShowTable={setShowTable}
      />
      <Snackbar
        msg={successMsg}
        open={successMsg.length > 0}
        type="success"
        className={commonClasses.success_snackbar}
      />
      {path === sinteringSizePath.ELECTRICAL && (
        <Snackbar
          msg={warningMsg}
          open={warningMsg.length > 0}
          type="error"
          className={commonClasses.error_snackbar}
        />
      )}
      {path === sinteringSizePath.ELECTRICAL && (
        <QCSinteringElectrical
          isRStick={transInfo.isRStick}
          measureStyle={measureStyle}
          lastMeasureStyle={lastMeasureStyle.current}
          handleMeasureStyleChange={handleMeasureStyleChange}
          handleFetchDifferentMeasureStyle={handleFetchDifferentMeasureStyle}
          handleShowTable={handleShowTable}
          showTable={showTable}
          showPaper={showPaper}
          isLoading={isLoading}
          currentSubSpec={currentSubSpec.current}
          currentSpec={currentSpec}
          measureConfig={measureConfig.current}
          diffNLMeasure={diffNLMeasure}
          ampSpecConfig={ampSpec.current}
          specConfig={specConfig}
          renderRow={renderRow}
          setRenderRow={setRenderRow}
          handleSubSpecChange={handleSubSpecChange}
          resultNote={resultNote}
          handleResultNoteChange={handleResultNoteChange}
          handleFormSubmit={handleFormSubmit}
          isLMeasure={transInfo.isLMeasure}
          currentMeasureStyle={currentMeasureStyle.current}
          oneColumn={oneColumn}
          rowLength={sampleQty.current}
          handleDelete={handleShowConfirmDelete}
          disabled={disabledDeleteBtn}
        />
      )}
      {path === sinteringSizePath.INSPECTION && (
        <QCSinteringSize
          path={path}
          showTable={showTable}
          isLoading={isLoading}
          foldingRow={foldingRow}
          toggleFoldingRow={toggleFoldingRow}
          warningMsg={warningMsg}
          specConfig={specConfig}
          renderRow={renderRow}
          transDefectTypes={transDefectTypes}
          setRenderRow={setRenderRow}
          resultNote={resultNote}
          handleResultNoteChange={handleResultNoteChange}
          handleFormSubmit={handleFormSubmit}
          rowLength={sampleQty.current}
          handleDelete={handleShowConfirmDelete}
          disabled={disabledDeleteBtn}
          otherIsDone={otherIsDone}
        />
      )}
      {path === sinteringSizePath.SIZE && (
        <QCSinteringSize
          path={path}
          showTable={showTable}
          isLoading={isLoading}
          foldingRow={foldingRow}
          toggleFoldingRow={toggleFoldingRow}
          warningMsg={warningMsg}
          specConfig={specConfig}
          renderRow={renderRow}
          transDefectTypes={transDefectTypes}
          setRenderRow={setRenderRow}
          resultNote={resultNote}
          handleResultNoteChange={handleResultNoteChange}
          handleFormSubmit={handleFormSubmit}
          rowLength={sampleQty.current}
          handleDelete={handleShowConfirmDelete}
          disabled={disabledDeleteBtn}
        />
      )}
    </div>
  );
};

QCSinteringContainer.propTypes = {
  path: PropTypes.string.isRequired,
};

export default QCSinteringContainer;
