/* eslint-disable no-unused-vars */
import { makeStyles } from 'tss-react/mui';
import { alpha, darken } from '@mui/material';
import { deepOrange } from '@mui/material/colors';

const useStyles = makeStyles()((theme) => ({
  fetch_btn: {
    alignSelf: 'flex-end',
    marginTop: 'auto',
    margin: `auto 3px`,
  },

  sub_transid_container: {
    width: '100%',
    minWidth: 220,
    padding: `${theme.spacing(1)} 0`,
  },
  sub_transid: {
    width: '100%',
    maxwidth: 230,
  },
  unfold_btn: {
    marginLeft: 'auto',
    backgroundColor: 'transparent',
    borderWidth: 1.5,
    borderColor: theme.palette.common.black,
    color: theme.palette.common.black,
    '&.active': {
      backgroundColor: alpha(theme.palette.common.black, 0.8),
      color: theme.palette.common.white,
    },
  },
  unfold_single_btn: {
    backgroundColor: 'transparent',
    borderColor: alpha(theme.palette.common.black, 0.1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: alpha(theme.palette.common.black, 0.6),
  },
  folding_rows: {
    height: 0,
    visibility: 'hidden',
    borderBottom: 'none',
  },
  table_header: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  table_column: {
    width: '100%',
    position: 'relative',
  },
  table_cell: {
    width: '100%',
    display: 'flex!important',
    // paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    justifyContent: 'space-between',
    // alignItems: 'center',
  },
  table_action_cell: {
    width: 80,
    minWidth: 80,
    alignSelf: 'center',
  },
  defect_type_cell: {
    width: 160,
    minWidth: 160,
    alignSelf: 'center',
  },
  spec_group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  target_group: {
    display: 'flex',
    alignItems: 'flex-start',
    fontWeight: 'bold',
  },
  target_value: {
    fontWeight: 400,
    fontSize: 29,
    marginRight: '0.5rem',
  },
  target: {
    display: 'inline-block',
    fontWeight: 400,
    // marginLeft: theme.spacing(1.2),
    padding: 0,
    paddingLeft: theme.spacing(1.5),
    lineHeight: 1,
    fontSize: 29,
    // borderLeft: `3px solid currentColor`,
    // marginBottom: theme.spacing(0.6),
  },
  upper: {
    color: theme.palette.secondary.main,
  },
  lower: {
    color: theme.palette.grey[500],
  },
  limit: {
    // display: 'flex',
    // flexDirection: 'column',
    // marginTop: theme.spacing(1),
  },
  bias: {
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: theme.spacing(1.5),
  },
  table_footer: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit_btn: {
    marginLeft: theme.spacing(2),
    width: 122,
  },
  merge_input: {
    width: '80%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
}));

export default useStyles;
