import React from 'react';
import SentimentVeryDissatisfiedRoundedIcon from '@mui/icons-material/SentimentVeryDissatisfiedRounded';
import useStyles from './useStyles';
import PageTitle from '../../components/PageTitle/PageTitle';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import PaperContainer from '../../components/PaperContainer/PaperContainer';

const TransUpdateFailed = () => {
  const { classes } = useStyles();
  return (
    <Grid container>
      <PageTitle title="更新移轉單失敗" />
      <PaperContainer className={classes.result}>
        <div className={classes.icon_container}>
          <SentimentVeryDissatisfiedRoundedIcon color="error" className={classes.icon} />
        </div>
        <Typography variant="h5" color="error">
          Update failed
        </Typography>
        <Typography variant="h6" className={classes.upper_gap_sm}>
          資料更新失敗！
        </Typography>
        <Typography variant="caption" className={`${classes.upper_gap_lg} ${classes.lower_gap_xl}`}>
          請關閉此分頁並重新掃描移轉單上之QRCode
        </Typography>
      </PaperContainer>
    </Grid>
  );
};

export default TransUpdateFailed;
