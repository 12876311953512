/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import SelectField from '../../../components/SelectField/SelectField';
import InputField from '../../../components/InputField/InputField';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useForm from '../../../hooks/useForm';
import { stationType as stationEnum } from '../../../constants/enums';

const NewMachineAction = ({
  handleAddingClose,
  stationList,
  stationTypeList,
  handleAddMachine,
}) => {
  const { dialogDispatch } = useContext(DialogContext);
  const initialForm = {
    stationTypeId: '0',
    stationTypeName: '',
    stationId: '0',
    stationName: '',
    machineName: '',
    machineId: 0,
    isEnabled: 1,
  };
  const {
    formData: machineForm,
    setFormData: setMachineForm,
    formErr,
    setFormErr,
    resetFormErr,
  } = useForm(initialForm);

  const handleStationTypeSelectChange = (e) => {
    resetFormErr();
    setMachineForm((prev) => ({
      ...prev,
      stationTypeId: e.target.value,
      stationTypeName: stationTypeList.find((station) => station.id === e.target.value).name,
    }));
  };

  const handleStationSelectChange = (e) => {
    resetFormErr();
    setMachineForm((prev) => ({
      ...prev,
      stationId: e.target.value,
      stationName: stationList.find((station) => station.id === e.target.value).name,
    }));
  };

  const handleMachineInputChange = (e) => {
    resetFormErr();
    setMachineForm((prev) => ({
      ...prev,
      machineName: e.target.value,
    }));
  };

  const validationMachineForm = () => {
    const { machineName, stationId, stationTypeId } = machineForm;
    if (stationTypeId === '0') {
      setFormErr((prev) => ({
        ...prev,
        stationTypeId: true,
      }));
      return false;
    }
    if (stationTypeId === stationEnum.GRINDING && stationId === '0') {
      setFormErr((prev) => ({
        ...prev,
        stationId: true,
      }));
      return false;
    }
    if (machineName.length === 0) {
      setFormErr((prev) => ({
        ...prev,
        machineName: true,
      }));
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validationMachineForm()) return;
    handleAddMachine(machineForm);
    handleAddingClose();
  };

  const handleCancel = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        singleBtn: true,
        title: '確認離開？',
        message: '是否確認放棄新增此筆資料？？',
        handleConfirm: handleAddingClose,
      },
    });
  };

  return (
    <div style={{ margin: 20 }}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid container item xs={7} spacing={1}>
          <Grid item xs>
            <SelectField
              label="製程站別"
              placeholder="請選擇站別"
              value={machineForm.stationTypeId}
              handleChange={handleStationTypeSelectChange}
              selections={stationTypeList}
              id="stationTypeId"
              name="statioInyped"
              error={formErr.stationTypeId}
              errormsg="請選擇製程站別"
            />
          </Grid>
          {machineForm.stationTypeId === stationEnum.GRINDING && (
            <Grid item xs>
              <SelectField
                label="研磨機台"
                placeholder="請選擇研磨機台"
                value={machineForm.stationId}
                handleChange={handleStationSelectChange}
                selections={stationList}
                id="stationId"
                name="stationId"
                error={formErr.stationId}
                errormsg="請選擇研磨機台"
              />
            </Grid>
          )}
          <Grid item xs>
            <InputField
              id="machineName"
              label="機台名稱"
              value={machineForm.machineName}
              variant="outlined"
              onChange={handleMachineInputChange}
              placeholder="請輸入機台名稱"
              name="machineName"
              type="text"
              error={formErr.machineName}
              errormsg="請輸入正確的機台名稱"
            />
          </Grid>
        </Grid>
        <Grid container item xs={5} justifyContent="flex-end">
          <Button
            style={{ marginRight: 10 }}
            variant="outlined"
            color="secondary"
            rounded
            onClick={handleSubmit}
          >
            新增
          </Button>
          <Button variant="outlined" color="error" rounded onClick={handleCancel}>
            取消
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}; // NewMachineAction
NewMachineAction.propTypes = {
  handleAddingClose: PropTypes.func.isRequired,
  stationList: PropTypes.array.isRequired,
  stationTypeList: PropTypes.array.isRequired,
  handleAddMachine: PropTypes.func.isRequired,
};

export default NewMachineAction;
