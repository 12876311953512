/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react';
import useStyles from '../useStyles';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import TransferDetectTypeTableToolbar from './SettingsConfigTransferDetectTypeToolbar';
import styleConst from '../../../constants/style';
import useAxios from '../../../hooks/useAxios';
import useCancelToken from '../../../hooks/useCancelToken';
import Typography from '../../../components/Typography/Typography';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import ConfigTable from '../../../components/ConfigTable/ConfigTable';
import TablePagination from '../../../components/TablePagination/TablePagination';
import Snackbar from '../../../components/Snackbar/Snackbar';

const settingsConfigTableHeadCells = [
  { id: 'station', numeric: false, disablePadding: true, label: 'No' },
  { id: 'machine', numeric: false, disablePadding: true, label: 'Defect Type' },
];

const SettingsConfigTransferDetectType = () => {
  const { classes } = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [willDeleteList, setWillDeleteList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [detectList, setDetectList] = useState([]);
  const [statusMsg, setStatusMsg] = useState('');
  const [statusType, setStatusType] = useState('success');
  const [showStatus, setShowStatus] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [editedData, setEditedData] = useState({});
  const snackbarTimer = useRef(null);
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();

  const rowsPerPage = styleConst.ROWS_PER_PAGE;

  const handleCheckBoxChecked = (targetRow) => {
    setDetectList((prev) =>
      prev.map((config) =>
        config.rowId === targetRow.rowId
          ? {
              ...config,
              columns: [...config.columns],
              isChecked: !config.isChecked,
            }
          : config
      )
    );
  };

  const openSnackbar = () => {
    setShowStatus(true);
    clearTimeout(snackbarTimer.current);
    snackbarTimer.current = setTimeout(() => {
      setShowStatus(false);
      clearTimeout(snackbarTimer.current);
    }, 3000);
  };

  const getTransDetect = async (page = currentPage, keyWord, isFromLastPage = false) => {
    const data = keyWord ? { page, keyWord } : { page };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/transDetect',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { count, detect } = result;
      setTotalRows(count);
      setDetectList(
        detect.map((item) => ({
          rowId: item.detectId,
          isChecked: false,
          isEditing: false,
          columns: [
            {
              name: 'detectId',
              value: item.detectId,
              displayValue: item.detectId,
              visible: true,
              isEditable: false,
              type: 'text',
            },
            {
              name: 'detectName',
              value: item.detectName,
              displayValue: item.detectName,
              visible: true,
              isEditable: true,
              type: 'text',
              label: 'Defect Type',
              placeholder: '請輸入檢測Defect type',
              isRequired: true,
              error: false,
            },
          ],
        }))
      );
      setIsLoading(false);
      setIsChecked(false);
    } else {
      if (isFromLastPage || currentPage <= 1) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '無法取得檢測移轉單Defect Type',
            message: noPrivilegeMsg || `目前無法取得檢測移轉單Defect Type，${result}`,
          },
        });
        return;
      }
      setCurrentPage(currentPage - 1);
      getTransDetect(currentPage - 1, '', true);
    }
  };

  const onRowEditClick = (targetRowId, isOpenEdit) => {
    setDetectList((prev) =>
      prev.map((config) =>
        config.rowId === targetRowId
          ? { ...config, columns: [...config.columns], isEditing: isOpenEdit, isChecked: false }
          : config
      )
    );
    if (isOpenEdit) {
      const targetRow = detectList.find((config) => config.rowId === targetRowId);
      setEditedData({
        detectId: targetRow.columns[0].value,
        detectName: targetRow.columns[1].value,
      });
    } else {
      setEditedData({});
    }
  };

  const handleRowError = (targetRow, validation) => {
    setDetectList((prev) =>
      prev.map((config) =>
        config.rowId === targetRow.rowId
          ? {
              ...config,
              columns: targetRow.columns.map((col) => ({ ...col, error: !validation[col.name] })),
            }
          : config
      )
    );
  };

  const handleRowEditing = (e) => {
    setEditedData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEditData = async () => {
    const data = {
      detect: [
        {
          ...editedData,
          isEnabled: 1,
        },
      ],
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/transDetectSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      setStatusMsg('已成功更新Defect Type設定！');
      setStatusType('success');
      getTransDetect();
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '更新Defect Type設定失敗，請稍後再試，或通知網管人員！');
    }
    openSnackbar();
    setEditedData({});
  };

  const handleWillDeleteList = (targetRow, isAdd) => {
    let newList = [];
    if (isAdd) {
      newList = [...willDeleteList, targetRow];
    } else {
      newList = willDeleteList.filter((item) => item.rowId !== targetRow.rowId);
    }
    setWillDeleteList([...newList]);
    if (newList.length > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const handleDelete = async () => {
    const data = {
      detect: willDeleteList.map((item) => ({
        detectId: item.columns[0].value,
        detectName: item.columns[1].value,
        isEnabled: 0,
      })),
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/transDetectSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      setStatusMsg(`已成功刪除 ${willDeleteList.length} 筆Defect Type！`);
      setStatusType('success');
      setWillDeleteList([]);
      setIsChecked(false);
      getTransDetect();
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '刪除多筆Defect Type失敗，請稍後再試，或聯絡網管人員！');
    }
    openSnackbar();
  };

  const onMultiRowsDelete = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認刪除？',
        message: '是否確認刪除此筆資料？',
        handleConfirm: handleDelete,
      },
    });
  };

  const handleAddTransferType = async (transferType) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/transDetectSetting',
      { detect: [{ detectName: transferType }] },
      true,
      cancelToken
    );
    if (status) {
      getTransDetect();
      setStatusMsg('新增Defect Type成功！');
      setStatusType('success');
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '新增Defect Type失敗！');
    }
    openSnackbar();
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
    getTransDetect(newPage);
  };

  const handleRowDelete = async (targetRow) => {
    const { rowId, columns } = targetRow;
    const data = { detect: [{ detectId: rowId, detectName: columns[1].value, isEnabled: 0 }] };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/transDetectSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      getTransDetect();
      setStatusMsg(`已成功刪除 ${columns[1].value} Defect Type！`);
      setStatusType('success');
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || `刪除 ${columns[1].value} Defect Type失敗！`);
    }
    openSnackbar();
  };

  useEffect(() => {
    const hasEditingRow = detectList.find((item) => item.isEditing);
    if (hasEditingRow) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [detectList]);

  useEffect(() => {
    setCurrentPage(1);
    getTransDetect();

    return () => {
      clearTimeout(snackbarTimer.current);
    };
  }, []);

  return (
    <PaperContainer className={classes.container}>
      <Typography variant="h6">檢測移轉單Defect Type設定</Typography>
      <TransferDetectTypeTableToolbar
        handleDelete={onMultiRowsDelete}
        isChecked={isChecked}
        handleAddTransferType={handleAddTransferType}
        isEditing={isEditing}
        isAdding={isAdding}
        handleIsAdding={setIsAdding}
      />
      <Snackbar msg={statusMsg} type={statusType} open={showStatus} className={classes.snackbar} />
      <ConfigTable
        isCheckBox
        isLoading={isLoading}
        isAdding={isAdding}
        isEditing={isEditing}
        handleIsAdding={setIsAdding}
        headCells={settingsConfigTableHeadCells}
        tableContentList={detectList}
        isDeleteBtnShow
        handleCheckBoxChecked={handleCheckBoxChecked}
        onRowEditClick={onRowEditClick}
        handleRowError={handleRowError}
        handleRowEditing={handleRowEditing}
        editedData={editedData}
        handleEditData={handleEditData}
        inputErrMsg="請輸入正確的機台名稱"
        onRowDelete={handleRowDelete}
        handleWillDeleteList={handleWillDeleteList}
      />
      <TablePagination
        isEditing={isEditing}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handleChangePage}
      />
    </PaperContainer>
  );
};

export default SettingsConfigTransferDetectType;
