/* eslint-disable import/prefer-default-export */
import { measureStyleType } from '../constants/enums';
/**
 * 取得顯示燒結檢測單的量測規格
 * @param specSettings - array: 所有的量測規格（含啟用＆未啟用）
 * @param measureStyle - number: 該量測規格的 StyleType
 * @param measureConfig - array: 上一次送出的量測規格
 * @param isDiff - boolean: 是否為要取得不一樣的量測規格
 */

export const getSpec = (specSettings, measureStyle, measureConfig = [], isDiff = false) => {
  let currentSpec;

  if (
    isDiff &&
    (measureStyle === measureStyleType.COIL || measureStyle === measureStyleType.STAR)
  ) {
    currentSpec = specSettings
      .filter((spec) =>
        measureConfig.find(
          (config) => spec.styleId === measureStyle && config.measureId.toString() === spec.specId
        )
      )
      .map((item) => item.specRecipe);
  }
  if (isDiff && measureStyle !== measureStyleType.COIL && measureStyle !== measureStyleType.STAR) {
    currentSpec = specSettings
      .filter((spec) =>
        measureConfig.find(
          (config) => spec.styleId === measureStyle && config.specId === spec.specId
        )
      )
      .map((item) => item.specRecipe);
  }
  if (!isDiff) {
    currentSpec = specSettings
      .filter((spec) => spec.styleId === measureStyle && spec.isEnabled)
      .map((item) => item.specRecipe);
  }

  return currentSpec;
};
