import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(4),
  },
  date_group: {
    display: 'flex',
    alignItems: 'center',
  },
  date_heading: {
    marginBottom: theme.spacing(1),
  },
  date_picker: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(2),
    },
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(2),
    },
  },
  error_red: {
    color: theme.palette.error.main,
  },
  error_opacity: {
    color: 'transparent',
  },
  date_checkbox: {
    '&.MuiFormControlLabel-root': {
      justifyContent: 'flex-start',
      marginLeft: -8,
    },
  },
  query_btn_container: {
    '&.MuiGrid2-root': {
      width: `calc((100% / 12) * 10 + 50px)`,
    },
  },
  table_header: {
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  },
  table_row: {
    display: 'flex',
    width: '100%',
    padding: `${theme.spacing(1.5)} ${theme.spacing(0.5)}`,
    color: theme.palette.grey[800],
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  },
  table_column: {
    width: `calc(100% / 9)`,
  },
  table_column_updateDate: {
    width: `calc(100% / 8)`,
  },
  table_column_station: {
    width: `calc(100% / 15.5)`,
  },
  table_column_scOdNo: {
    width: `calc(100% / 12)`,
  },
  table_column_elNo: {
    width: `calc(100% / 7)`,
  },
  table_column_elName: {
    width: `calc(100% / 4.5)`,
    overflowWrap: 'break-word',
    paddingRight: '10px',
  },
  table_column_transQty: {
    width: `calc(100% / 13)`,
  },
  table_column_updateUser: {
    width: `calc(100% / 12)`,
  },
  table_column_defectSize: {
    width: `calc(100% / 12)`,
  },
  detail_table_row: {
    display: 'flex',
    width: '100%',
    padding: `${theme.spacing(0.3)} ${theme.spacing(0.5)}`,
    color: theme.palette.grey[800],
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.2)}`,
  },
  detail_table_column: {
    width: `calc(100% / 9)`,
    height: '100%',
    borderRight: `1px solid ${alpha(theme.palette.common.black, 0.3)}`,
    textAlign: 'center',
    lineHeight: '2.5',
  },

  hyper_link: {
    cursor: 'pointer',
    color: '#3f51b5',
  },
  table_content: {
    width: '100%',
    paddingLeft: 0,
    marginTop: 0,
  },
  export_footer: {
    '&.MuiGrid2-root': {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
  },
  export_btn: {
    '&.MuiButton-root': {
      marginLeft: theme.spacing(3),
    },
  },
  advanced_filter: {
    marginTop: theme.spacing(2.5),
  },
  advanced_filter_row: {
    marginTop: theme.spacing(4),
  },
  advanced_filter_btn: {
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2.5),
  },
}));

export default useStyles;
