import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import DetailDialog from './DetailDialog';
import useAxios from '../../hooks/useAxios';
import useCancelToken from '../../hooks/useCancelToken';
import { DialogContext } from '../../contexts/DialogContext';
import { dialogActions } from '../../reducers/dialogReducer';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';

const TransItemRow = ({
  transId,
  updateDate,
  scOdNo,
  stationType,
  transQty,
  updateUser,
  elNO,
  elName,
  defectSize,
  manufactureType,
}) => {
  const { classes } = useStyles();
  const [openDetail, setOpenDetail] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [badArr, setBadArr] = useState([]);
  const { newCancelToken } = useCancelToken();
  const { dialogDispatch } = useContext(DialogContext);
  const handleDetailClose = () => {
    setOpenDetail(false);
  };
  const getDetail = async (id) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkBadByPackage',
      { packageId: id },
      true,
      cancelToken
    );
    const { bad } = result;
    if (status) {
      setBadArr(bad);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得不良品資訊',
          message:
            noPrivilegeMsg || '目前暫時無法取得不良品詳細資訊，請稍後再試，或請聯絡網管人員！',
          singleBtn: true,
          handleConfirm: handleDetailClose,
        },
      });
    }
  };
  const handleDetailClick = (row) => {
    setOpenDetail(true);
    setCurrentRow(row);
    getDetail(transId);
  };
  return (
    <>
      <li className={classes.table_row}>
        <Grid item className={classes.table_column}>
          <Typography variant="body1">{transId}</Typography>
        </Grid>
        <Grid item className={classes.table_column_updateDate}>
          <Typography variant="body1">{updateDate || '-'}</Typography>
        </Grid>
        <Grid item className={classes.table_column_station}>
          <Typography variant="body1">{stationType}</Typography>
        </Grid>
        <Grid item className={classes.table_column_scOdNo}>
          <Typography variant="body1">{scOdNo}</Typography>
        </Grid>
        <Grid item className={classes.table_column_elNo}>
          <Typography variant="body1">{elNO}</Typography>
        </Grid>
        <Grid item className={classes.table_column_elNo}>
          <Typography variant="body1">{manufactureType}</Typography>
        </Grid>
        <Grid item className={classes.table_column_elName}>
          <Typography variant="body1">{elName}</Typography>
        </Grid>
        <Grid item className={classes.table_column_transQty}>
          <Typography variant="body1">{transQty || '-'}</Typography>
        </Grid>
        <Grid item className={classes.table_column_updateUser}>
          <Typography variant="body1">{updateUser || '-'}</Typography>
        </Grid>
        <Grid
          item
          className={`${classes.table_column_defectSize} ${
            defectSize !== '-' && defectSize !== 0 && classes.hyper_link
          }`}
          onClick={() => (defectSize !== '-' && defectSize !== 0 ? handleDetailClick() : '')}
        >
          <Typography variant="body1">
            {defectSize || defectSize === 0 ? defectSize : '-'}
          </Typography>
        </Grid>
      </li>
      <DetailDialog
        open={openDetail}
        handleDetailClose={handleDetailClose}
        currentRow={currentRow}
        transId={transId}
        badArr={badArr}
        defectSize={defectSize}
      />
    </>
  );
};

TransItemRow.defaultProps = {
  updateDate: '-',
  transQty: '-',
  updateUser: '-',
  elNO: '-',
  elName: '-',
  defectSize: '-',
  manufactureType: '-',
};

TransItemRow.propTypes = {
  transId: PropTypes.string.isRequired,
  updateDate: PropTypes.string,
  scOdNo: PropTypes.string.isRequired,
  stationType: PropTypes.string.isRequired,
  transQty: PropTypes.number,
  updateUser: PropTypes.string,
  elNO: PropTypes.string,
  elName: PropTypes.string,
  defectSize: PropTypes.number,
  manufactureType: PropTypes.string,
};

export default TransItemRow;
