import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';
import indigo from '@mui/material/colors/indigo';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';

const useStyles = makeStyles()((theme) => ({
  label: {
    justifyContent: 'space-between',
    margin: 0,
  },
  reverse: {
    flexDirection: 'row',
    marginRight: theme.spacing(2),
    marginLeft: 0,
  },
  disabledLabel: {
    '&.Mui-disabled': {
      color: alpha(theme.palette.common.black, 0.6),
    },
  },
  disabledCheckbox: {
    '&.Mui-disabled': {
      color: alpha(indigo[500], 0.38),
    },
  },
  error: {
    color: '#B00020',
  },

  errorMsg: {
    color: '#B00020',
    fontWeight: '500',
    fontSize: 14,
  },
}));

const Checkbox = ({
  name,
  label,
  handleChange,
  checked,
  disabled,
  reverse,
  customClasses,
  error,
  errorMsg,
  ...rest
}) => {
  const { classes } = useStyles();
  return (
    <>
      <FormControlLabel
        className={`${classes.label} ${reverse ? classes.reverse : ''} ${customClasses}`}
        control={
          <MuiCheckbox
            checked={checked}
            onChange={handleChange}
            name={name}
            color="primary"
            classes={{
              colorPrimary: classes.disabledCheckbox,
            }}
            style={error ? { color: '#B00020' } : {}}
          />
        }
        label={label}
        labelPlacement="start"
        disabled={disabled}
        classes={{ disabled: classes.disabledLabel }}
        {...rest}
      />
      {error && <div className={classes.errorMsg}>{errorMsg}</div>}
    </>
  );
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  reverse: false,
  customClasses: '',
  error: false,
  errorMsg: '',
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  reverse: PropTypes.bool,
  customClasses: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
};

export default Checkbox;
