import { useRef, useEffect } from 'react';
import { CancelToken } from 'axios';

const useCancelToken = () => {
  const axiosSource = useRef(null);
  const newCancelToken = () => {
    axiosSource.current = CancelToken.source();
    return axiosSource.current.token;
  };

  useEffect(
    () => () => {
      if (axiosSource.current) axiosSource.current.cancel('api call is cancelled');
    },
    []
  );

  return { newCancelToken };
};

export default useCancelToken;
