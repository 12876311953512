import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(1.5),
  },
  toolbar: {
    '&.MuiToolbar-root': {
      padding: 0,
    },
  },
  head: {
    borderTop: '1px solid rgba(0, 0, 0, .12)',
  },
  iconBtnModify: {
    width: 24,
    height: 24,
    border: '1px solid',
    marginLeft: theme.spacing(0.5),
    // marginTop: 0,
    // marginBottom: 0,
    color: theme.palette.primary.main,
  },
  iconBtnDelete: {
    width: 24,
    height: 24,
    border: '1px solid',
    marginLeft: theme.spacing(0.5),
    // marginTop: 0,
    // marginBottom: 0,
    color: theme.palette.error.main,
  },
  iconBtnAdd: {
    width: 24,
    height: 24,
    border: '1px solid',
    marginLeft: theme.spacing(0.5),
    // marginTop: 0,
    // marginBottom: 0,
    color: theme.palette.secondary.main,
  },
  iconBtnFilter: {
    width: 42,
    height: 42,
    border: '1px solid',
    marginLeft: theme.spacing(0.625),
    color: '#000000',
    opacity: 0.6,
  },
  iconBtnIcon: {
    padding: 3.25,
  },
  iconBtnFilterIcon: {
    width: 24,
    height: 24,
  },
  tablePaginationArrows: {
    flexShrink: 0,
    marginLeft: theme.spacing(5),
  },
  tablePagination: {
    border: 'none',
  },
  filter: {
    borderTop: '1px solid rgba(0, 0, 0, .12)',
    padding: theme.spacing(2),
  },
  error: {
    marginBottom: theme.spacing(2.5),
  },
  snackbar: {
    marginBottom: theme.spacing(2),
  },
  moduler_container: {
    display: 'flex',
    alignItems: 'stretch',
    marginBottom: theme.spacing(3),
  },
  checkbox_container: {
    borderTop: '1px solid rgba(0,0,0,.12)',
    borderBottom: '1px solid rgba(0,0,0,.12)',
    padding: `${theme.spacing(2)} 0`,
    flex: 1,
    marginBottom: 'auto',
  },
  checkbox_group: {
    paddingRight: theme.spacing(2),
  },
  checkbox_column: {
    paddingRight: theme.spacing(4),
  },
  moduler_content: {
    paddingBottom: theme.spacing(1.5),
    width: `calc(100% - 300px - ${theme.spacing(2)})`,
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  moduler_sidebar: {
    width: 300,
    display: 'flex',
  },
  sidebar_container: {
    paddingBottom: theme.spacing(2.4),
    display: 'flex',
    flexDirection: 'column',
  },
  moduler_count: {
    marginTop: 'auto',
    paddingTop: theme.spacing(2),
    textAlign: 'right',
  },
  chips_container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 120,
    overflowY: 'scroll',
    border: '1px solid #ddd',
    padding: `${theme.spacing(0.2)}`,
    borderRadius: 4,
  },
  chips_container_tall: {
    height: 200,
    marginTop: theme.spacing(2),
  },
  chip: {
    width: 150,
    height: 33,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 100,
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(1),
    },
  },
  chip_label: {
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
    fontSize: theme.typography.body2.fontSize,
  },
  table: {
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1.8)} 0 ${theme.spacing(1)}`,
  },
  table_header: {
    display: 'flex',
    padding: 0,
    width: '100%',
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    marginBottom: 0,
  },
  table_column: {
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}`,
    boxSizing: 'border-box',
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
    },
  },
  table_cell: {
    width: 65,
    minWidth: 65,
    display: 'block',
    textAlign: 'center',
    padding: `${theme.spacing(1)} ${theme.spacing(0.6)}`,
    boxSizing: 'border-box',
    alignSelf: 'flex-start',
  },
  table_action_cell: {
    width: 90,
    minWidth: 90,
  },
  table_row: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
  },
  table_input: {
    paddingLeft: 6,
  },
  table_btn_set: {
    // display: 'flex',
    width: '10%',
    // width: 'max-content',
  },
  product_table_cell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  field_column: {
    width: `calc(100% / 5)`,
    maxWidth: `calc(100% / 5)`,
    display: 'flex',
    flexDirection: 'column',
  },
})); // useStyles

export default useStyles;
