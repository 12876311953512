import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useStyles from './useStyles';
import logo from '../../images/logo.svg';
import Typography from '../Typography/Typography';
import { AuthContext } from '../../contexts/AuthContext';
import useAxios from '../../hooks/useAxios';
import useCancelToken from '../../hooks/useCancelToken';

const Header = () => {
  const {
    authContext: { isLogin },
    logout,
  } = useContext(AuthContext);
  const { newCancelToken } = useCancelToken();
  const [userName, setUserName] = useState('');
  const [userAvatar, setUserAvatar] = useState('');
  const [version, setVersion] = useState('');
  const navigate = useNavigate();
  const [menuEl, setMenuEl] = useState(null);
  const [rollEl, setRollEl] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const { classes } = useStyles();

  const handleClick = (e) => {
    if (e.currentTarget.id === 'role') {
      setRollEl(e.currentTarget);
    } else {
      setMenuEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setMenuEl(null);
    setRollEl(null);
  };

  const handleLogout = () => {
    logout();
    localStorage.setItem('targetPath', '/');
    navigate('/login');
  };

  const getUserRole = async () => {
    const cancelToken = newCancelToken();
    const [state, result] = await useAxios('/getUserRole', {}, true, cancelToken);
    if (state) {
      setRoleList(result.roleList);
    }
  };
  useEffect(() => {
    if (isLogin) {
      const currentUser = localStorage.getItem('currentUser');
      const { name, picture } = JSON.parse(currentUser);
      setUserName(name);
      setUserAvatar(picture);
      setVersion(localStorage.getItem('version'));
      getUserRole();
    }
  }, [isLogin]);
  return (
    <header>
      <AppBar className={classes.root}>
        <Toolbar classes={{ gutters: classes.toolbar }}>
          <div className={classes.logo}>
            <img src={logo} alt="Ferrite" />
          </div>
          <Typography variant="h6" className={`${classes.title} ${isLogin && classes.d_none}`}>
            宏業電化軟體系統
          </Typography>
          <Typography variant="body1" className={`${classes.version} ${isLogin && classes.d_none}`}>
            {version}
          </Typography>
          {isLogin && (
            <div className={classes.navFunc}>
              <IconButton classes={{ root: classes.logout }} onClick={handleLogout}>
                <ExitToAppRoundedIcon />
              </IconButton>
              <span className={classes.profileDivider} />
              <div className={classes.profile}>
                <Typography variant="body1">
                  {/* Annisa Fajar */}
                  {userName}
                </Typography>
                <Avatar
                  id="role"
                  alt={userName}
                  src={userAvatar}
                  className={classes.avatar}
                  onClick={handleClick}
                />
              </div>
              <Menu
                anchorEl={rollEl}
                open={Boolean(rollEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <List
                  sx={{ maxWidth: '600px', minWidth: '200px' }}
                  subheader={<ListSubheader component="div">所屬角色</ListSubheader>}
                >
                  {roleList.length > 0 ? (
                    roleList.map((role) => (
                      <ListItem>
                        <ListItemText>{role}</ListItemText>
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText>目前無選擇的角色</ListItemText>
                    </ListItem>
                  )}
                </List>
              </Menu>
              <IconButton classes={{ root: classes.logoutMobile }} onClick={handleClick}>
                <MoreVertRoundedIcon />
              </IconButton>
              <Menu
                anchorEl={menuEl}
                open={Boolean(menuEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default Header;
