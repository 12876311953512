/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import useCommonStyles from '../useStyles';
import useStyles from './useStyles';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import InputField from '../../../components/InputField/InputField';
import Button from '../../../components/Button/Button';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useAxios from '../../../hooks/useAxios';
import useCancelToken from '../../../hooks/useCancelToken';

const TransInfo = ({
  transId,
  handleTransIdChange,
  transIdErr,
  handleFetch,
  transNGRecords,
  mergedTransIds,
}) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();

  const handleDelete = async (recode) => {
    const cancelToken = newCancelToken();
    const data = {
      listId: recode.listId,
    };
    if (mergedTransIds !== '') {
      data.mergedTransIds = mergedTransIds;
    } else {
      data.transId = transId;
    }
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/ngDeleteHistory',
      data,
      true,
      cancelToken
    );
    if (status) {
      handleFetch();
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法刪除',
          message:
            noPrivilegeMsg || `目前暫時無法刪除${transId}的資料請稍後再試，或請通知網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleDeleteTransNGRecord = (recode) => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '您是否確定要刪除？',
        message: `刪除之後無法復原，確認刪除此筆資料嗎？`,
        handleConfirm: () => handleDelete(recode),
      },
    });
  };

  return (
    <>
      <PaperContainer className={commonClasses.container}>
        <Typography variant="h6">輸入NG的移轉單號</Typography>
        <Grid className={commonClasses.content} alignItems="flex-end">
          <Grid container xs={12}>
            <Grid item xs={5}>
              <InputField
                id="transId"
                name="transId"
                label="移轉單號"
                placeholder="請輸入NG的壓型或燒結移轉單號"
                value={transId}
                onChange={handleTransIdChange}
                type="text"
                error={transIdErr}
                errormsg="請輸入正確且有效的NG壓型或燒結移轉單號"
              />
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SearchRoundedIcon />}
              onClick={handleFetch}
              customClasses={`${classes.fetch_btn} ${transIdErr ? classes.error : ''}`}
            >
              查詢
            </Button>
          </Grid>
          {mergedTransIds !== '' && (
            <Typography variant="subtitle2">
              該單號已被合併，合併單號為：{mergedTransIds}
            </Typography>
          )}
        </Grid>
      </PaperContainer>
      {transNGRecords.length !== 0 && (
        <PaperContainer className={commonClasses.container}>
          <Typography variant="h6">NG處理歷史紀錄</Typography>
          <Grid container className={commonClasses.content}>
            <div className={classes.record_header}>
              <Grid item xs={1} className={classes.record_column}>
                <Typography variant="subtitle2">No</Typography>
              </Grid>
              <Grid item xs={2} className={classes.record_column}>
                <Typography variant="subtitle2">動作</Typography>
              </Grid>
              <Grid item xs={3} className={classes.record_column}>
                <Typography variant="subtitle2">更新時間</Typography>
              </Grid>
              <Grid item xs={6} className={classes.record_column}>
                <Typography variant="subtitle2">備註</Typography>
              </Grid>
            </div>
            <ul className={classes.record_content}>
              {transNGRecords.map((record) => (
                <li className={classes.record_row}>
                  <Grid item xs={1} className={classes.record_column}>
                    <Typography variant="body1">{record.id}</Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.record_column}>
                    <Typography variant="body1">{record.action}</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.record_column}>
                    <Typography variant="body1">{record.updateDateTime}</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.record_column}>
                    <Typography variant="body1">{record.note}</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.record_column}>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<RemoveCircleOutlineRoundedIcon />}
                      onClick={() => handleDeleteTransNGRecord(record)}
                    >
                      刪除
                    </Button>
                  </Grid>
                </li>
              ))}
            </ul>
          </Grid>
        </PaperContainer>
      )}
    </>
  );
};

TransInfo.propTypes = {
  transId: PropTypes.string.isRequired,
  transIdErr: PropTypes.bool.isRequired,
  mergedTransIds: PropTypes.string.isRequired,
  handleTransIdChange: PropTypes.func.isRequired,
  handleFetch: PropTypes.func.isRequired,
  transNGRecords: PropTypes.array.isRequired,
};

export default TransInfo;
