/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../components/Grid/Grid';
import Checkbox from '../../../components/Checkbox/Checkbox';
import List from '../../../components/List/List';
import ListItemText from '../../../components/ListItemText/ListItemText';
import { checkboxActions } from '../../../reducers/checkboxReducer';

const PrivilegeCheckbox = ({ checkboxItems, checkboxDispatch, type, disabled }) => {
  const handleChange = (e) => {
    checkboxDispatch({
      type: checkboxActions.CHANGE,
      target: Number(e.target.name),
    });
  };
  return (
    <>
      {checkboxItems.map((item) => (
        <Grid
          item
          xs={3}
          style={{
            paddingBottom: 0,
            paddingLeft: type !== 'view' && 0,
          }}
        >
          {type === 'view' ? (
            <List key={item.name} style={{ paddingBottom: 0 }}>
              <ListItemText primary={item.label} style={{ marginBottom: 0 }} />
            </List>
          ) : (
            <Checkbox
              name={item.name}
              label={item.label}
              handleChange={handleChange}
              reverse
              checked={item.checked}
              disabled={disabled}
            />
          )}
        </Grid>
      ))}
    </>
  );
};

PrivilegeCheckbox.propTypes = {
  checkboxItems: PropTypes.array.isRequired,
  checkboxDispatch: PropTypes.func.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default PrivilegeCheckbox;
