import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

const useCommonStyles = makeStyles()((theme) => ({
  main: {
    marginTop: theme.spacing(2),
  },
  container: {
    marginBottom: theme.spacing(1.5),
  },
  filter_container: {
    paddingBottom: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(1.8)} 0 ${theme.spacing(1)}`,
    display: 'flex',
    flexDirection: 'column',
  },
  report_nav: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 0,
    borderRadius: 5,
    overflow: 'hidden',
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(2.5),
    transition: 'all .4s ease-in-out',
  },
  report_nav_item: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(5)} ${theme.spacing(1.2)}`,
    cursor: 'pointer',
    '&.active': {
      color: theme.palette.secondary.main,
      borderBottom: `4px solid currentColor`,
    },
  },
  dashed_divider: {
    background: `repeating-linear-gradient(90deg,${alpha(theme.palette.common.black, 0.2)},${alpha(
      theme.palette.common.black,
      0.2
    )} 6px,transparent 6px,transparent 12px)`,
    border: 'none',
    height: 1,
  },
  detail_header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: `0 4px 6px ${alpha(theme.palette.common.black, 0.15)}`,
  },
  detail_footer: {
    boxShadow: `0 -4px 6px ${alpha(theme.palette.common.black, 0.1)}`,
  },
  detail_btn: {
    margin: `${theme.spacing(0.6)} 0`,
  },
  detail_content: {
    height: '70vh',
    overflowY: 'scroll',
  },
}));

export default useCommonStyles;
