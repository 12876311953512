/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react';
import useStyles from '../useStyles';
import Typography from '../../../components/Typography/Typography';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import ConfigTable from '../../../components/ConfigTable/ConfigTable';
import TablePagination from '../../../components/TablePagination/TablePagination';
import TimerReasonToolbar from './SettingsConfigTimerReasonTableToolbar';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useAxios from '../../../hooks/useAxios';
import Snackbar from '../../../components/Snackbar/Snackbar';
import styleConst from '../../../constants/style';
import { stationType as stationEnum } from '../../../constants/enums';
import useCancelToken from '../../../hooks/useCancelToken';

const SettingsConfigTimerReasonType = () => {
  const { classes } = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [willDeleteList, setWillDeleteList] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [stationFilter, setStationFilter] = useState('1');
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [grindStationList, setGrindStationList] = useState([]);
  const [stationTypeList, setStationTypeList] = useState([]);
  const [settingsConfigTableContent, setSettingsConfigTableContent] = useState([]);
  const [currentTargetId, setCurrentTargetId] = useState('');
  const [settingsConfigTableHeadCells, setSettingsConfigTableHeadCells] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [statusMsg, setStatusMsg] = useState('');
  const [showStatus, setShowStatus] = useState(false);
  const [statusType, setStatusType] = useState('success');
  const [isLoading, setIsLoading] = useState(false);
  const snackbarTimer = useRef(null);
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();

  const rowsPerPage = styleConst.ROWS_PER_PAGE;

  const getStationType = async () => {
    setIsLoading(true);
    const cancelToken = newCancelToken();
    const [status, result] = await useAxios('/stationType', {}, true, cancelToken);
    if (status) {
      const { stationType } = result;
      setStationTypeList(
        stationType
          // 取得通孔以外的站別名稱
          .filter((type) => type.stationTypeId !== 3)
          .map((type) => ({
            id: type.stationTypeId.toString(),
            value: type.stationTypeId.toString(),
            name: type.stationTypeName,
          }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '無法取得製程站別',
          message: result,
        },
      });
    }
    setIsLoading(false);
  };

  const getReasonData = async (page = currentPage, isFromLastPage = false) => {
    setIsLoading(true);
    const cancelToken = newCancelToken();
    const [status, result] = await useAxios(
      '/getTimerReason',
      { stationType: stationFilter, page },
      true,
      cancelToken
    );
    if (status) {
      const { timerReason, count } = result;
      setTotalRows(count);
      setSettingsConfigTableContent(
        timerReason.map((item) => ({
          rowId: item.reasonId,
          columns: [
            {
              name: 'stationTypeId',
              value: item.stationType.toString(),
              displayValue: item.stationName,
              visible: true,
              isEditable: false,
            },
            {
              name: 'reasonTC',
              value: item.reasonTC,
              displayValue: item.reasonTC,
              visible: true,
              type: 'text',
              isEditable: true,
              label: '暫停原因(中文)',
              placeholder: '請輸入暫停原因(中文)',
              isRequired: true,
              error: false,
            },
            {
              name: 'reasonIDN',
              value: item.reasonIDN,
              displayValue: item.reasonIDN,
              visible: true,
              type: 'text',
              isEditable: true,
              label: '暫停原因(印尼文)',
              placeholder: '請輸入暫停原因(印尼文)',
              isRequired: true,
              error: false,
            },
          ],
          isEditing: false,
          isChecked: false,
        }))
      );
      setIsChecked(false);
      setIsLoading(false);
    } else {
      if (isFromLastPage || currentPage <= 1) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '無法取得暫停原因列表',
            message: `目前無法取得暫停原因列表，${result}`,
          },
        });
        return;
      }
      setCurrentPage(currentPage - 1);
      getReasonData(currentPage - 1, true);
      setIsLoading(false);
    }
  };

  const onRowEditClick = (targetRowId, isOpenEdit) => {
    setCurrentTargetId(targetRowId);
    setSettingsConfigTableContent((prev) =>
      prev.map((config) =>
        config.rowId === targetRowId
          ? { ...config, columns: [...config.columns], isEditing: isOpenEdit, isChecked: false }
          : config
      )
    );
    if (isOpenEdit) {
      const targetRow = settingsConfigTableContent.find((config) => config.rowId === targetRowId);
      setEditedData({
        reasonId: targetRow.rowId,
        stationType: targetRow.columns[0].value,
        reasonTC: targetRow.columns[1].value,
        reasonIDN: targetRow.columns[2].value,
      });
    } else {
      setEditedData({});
    }
  };

  const handleRowError = (targetRow, validation) => {
    setSettingsConfigTableContent((prev) =>
      prev.map((config) =>
        config.rowId === targetRow.rowId
          ? {
              ...config,
              columns: targetRow.columns.map((col) => ({ ...col, error: !validation[col.name] })),
            }
          : config
      )
    );
  };

  const handleRowEditing = (e) => {
    setEditedData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const openSnackbar = () => {
    setShowStatus(true);
    clearTimeout(snackbarTimer.current);
    snackbarTimer.current = setTimeout(() => {
      setShowStatus(false);
      clearTimeout(snackbarTimer.current);
    }, 3000);
  };

  const handleEditData = async () => {
    const data = {
      timerReasons: [{ ...editedData, isEnabled: 1 }],
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/timerReasonSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      setStatusMsg('已成功更新暫停原因設定！');
      setStatusType('success');
      getReasonData();
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '更新暫停原因設定失敗，請稍後再試，或通知網管人員！');
    }
    openSnackbar();
    setEditedData({});
  };

  const getDeleteData = (reason) => {
    const { rowId, columns } = reason;
    const data = {
      reasonId: rowId,
      stationType: columns[0].value,
      reasonTC: columns[1].value,
      reasonIDN: columns[2].value,
      isEnabled: 0,
    };
    return data;
  };

  // 刪除多筆
  const handleDelete = async () => {
    const cancelToken = newCancelToken();
    const data = {
      timerReasons: willDeleteList,
    };
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/timerReasonSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      setStatusMsg(`已成功刪除 ${willDeleteList.length} 筆暫停原因設定！`);
      setStatusType('success');
      setWillDeleteList([]);
      setIsChecked(false);
      getReasonData();
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '刪除多筆暫停原因設定失敗，請稍後再試，或聯絡網管人員！');
    }
    openSnackbar();
  };

  const handleCheckBoxChecked = (targetRow) => {
    setSettingsConfigTableContent((prev) =>
      prev.map((config) =>
        config.rowId === targetRow.rowId
          ? {
              ...config,
              columns: [...config.columns],
              isChecked: !config.isChecked,
            }
          : config
      )
    );
  };

  const handleWillDeleteList = (targetRow, isAdd) => {
    let newList = [];
    if (isAdd) {
      newList = [...willDeleteList, getDeleteData(targetRow)];
    } else {
      newList = willDeleteList.filter((item) => item.rowId !== targetRow.rowId);
    }
    setWillDeleteList(newList);
    if (newList.length > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  // 刪除多筆跳出 dialog
  const onMultiRowsDelete = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認刪除？',
        message: '是否確認刪除此資料？',
        handleConfirm: handleDelete,
      },
    });
  };

  const handleChangePage = (targetPage) => {
    setCurrentPage(targetPage);
    getReasonData(targetPage);
  };

  const handleStationChange = (e) => {
    setStationFilter(e.target.value);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    getReasonData(1);
  };

  const handleAddReason = async (reason) => {
    const { reasonTC, reasonIDN, isEnabled, stationTypeId } = reason;
    const data = {
      timerReasons: [{ stationType: stationTypeId, reasonTC, reasonIDN, isEnabled }],
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/timerReasonSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      getReasonData();
      setStatusMsg('新增暫停原因設定成功！');
      setStatusType('success');
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '新增暫停原因設定失敗，請稍後再試！');
    }
    openSnackbar();
  };

  // 刪除單筆
  const handleRowDelete = async (data) => {
    const { reasonTC, reasonIDN } = getDeleteData(data);
    const cancelToken = newCancelToken();
    const deleteData = {
      timerReasons: [getDeleteData(data)],
    };
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/timerReasonSetting',
      deleteData,
      true,
      cancelToken
    );
    if (status) {
      getReasonData();
      setStatusMsg(`已成功刪除「${reasonTC} / ${reasonIDN}」！`);
      setStatusType('success');
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || `刪除「${reasonTC} / ${reasonIDN}」失敗，請稍後再試！`);
    }
    openSnackbar();
  };

  useEffect(() => {
    const hasEditingRow = settingsConfigTableContent.find((item) => item.isEditing);
    if (hasEditingRow) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [settingsConfigTableContent]);

  useEffect(() => {
    if (stationTypeList.length > 0) {
      setIsLoading(false);
      // Make sure the stationList is already has data, then fetch the reason data
      getReasonData();
    }
  }, [stationTypeList.length]);

  useEffect(() => {
    setSettingsConfigTableHeadCells([
      { id: 'station', numeric: false, disablePadding: true, label: '製程站別' },
      { id: 'reasonTC', numeric: false, disablePadding: true, label: '暫停原因(中文)' },
      { id: 'reasonIDN', numeric: false, disablePadding: true, label: '暫停原因(印尼文)' },
    ]);
    getStationType();
    setStationFilter('1');
    setCurrentPage(1);

    return () => {
      clearTimeout(snackbarTimer.current);
    };
  }, []);

  return (
    <PaperContainer className={classes.container}>
      <Typography variant="h6">移轉單各站別暫停原因</Typography>
      <TimerReasonToolbar
        handleDelete={onMultiRowsDelete}
        isChecked={isChecked}
        stationList={grindStationList}
        stationTypeList={stationTypeList}
        station={stationFilter}
        onStationChange={handleStationChange}
        handleSearch={handleSearch}
        handleAddReason={handleAddReason}
        isEditing={isEditing}
        isAdding={isAdding}
        handleIsAdding={setIsAdding}
      />
      <Snackbar msg={statusMsg} type={statusType} open={showStatus} className={classes.snackbar} />
      {settingsConfigTableContent.length === 0 ? (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="subtitle1">目前沒有資料</Typography>
        </div>
      ) : (
        <>
          <ConfigTable
            isCheckBox
            isLoading={isLoading}
            isAdding={isAdding}
            isEditing={isEditing}
            handleIsAdding={setIsAdding}
            headCells={settingsConfigTableHeadCells}
            tableContentList={settingsConfigTableContent}
            isDeleteBtnShow
            handleCheckBoxChecked={handleCheckBoxChecked}
            onRowEditClick={onRowEditClick}
            handleRowError={handleRowError}
            handleRowEditing={handleRowEditing}
            editedData={editedData}
            handleEditData={handleEditData}
            onRowDelete={handleRowDelete}
            handleWillDeleteList={handleWillDeleteList}
          />
          <TablePagination
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            isEditing={isEditing}
          />
        </>
      )}
    </PaperContainer>
  );
};

export default SettingsConfigTimerReasonType;
