/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import Dialog from '../../components/Dialog/Dialog';
import DialogTitle from '../../components/DialogTitle/DialogTitle';
import DialogContent from '../../components/DialogContent/DialogContent';
import DialogActions from '../../components/DialogActions/DialogActions';
import CircularProgress from '../../components/CircularProgress/CircularProgress';
import useStyles from './useStyles';
import { DialogContext } from '../../contexts/DialogContext';
import useCancelToken from '../../hooks/useCancelToken';

const DetailDialog = ({ open, handleDetailClose, transId, badArr, defectSize }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { classes } = useStyles();

  const handleConfirm = () => {
    handleDetailClose();
  };

  return (
    <div className={classes.dialog}>
      <Dialog open={open} onClose={handleDetailClose} fullWidth maxWidth="lg">
        <DialogTitle id="detail-title" onClose={handleDetailClose}>
          {transId} - 不良品資訊 / {`總不良數量${defectSize}`}
        </DialogTitle>
        <DialogContent dividers>
          {isLoading && <CircularProgress mt={7.5} />}
          <div className={`${classes.table_header} ${classes.detail_table_row}`}>
            {badArr.length > 0 &&
              badArr.map((item) => (
                <Grid item className={classes.detail_table_column}>
                  <Typography variant="subtitle2">{item.detectName}</Typography>
                </Grid>
              ))}
          </div>
          <ul className={classes.table_content}>
            <li className={classes.detail_table_row}>
              {badArr.length > 0 &&
                badArr.map((item) => (
                  <Grid item className={classes.detail_table_column}>
                    <Typography variant="body1">{item.detectCount}</Typography>
                  </Grid>
                ))}
            </li>
          </ul>
        </DialogContent>
        <DialogActions onClick={handleConfirm}>確認</DialogActions>
      </Dialog>
    </div>
  );
};

DetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDetailClose: PropTypes.func.isRequired,
  transId: PropTypes.string.isRequired,
  badArr: PropTypes.array.isRequired,
  defectSize: PropTypes.number.isRequired,
};

export default DetailDialog;
