/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import useStyles from '../useStyles';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import SelectField from '../../../components/SelectField/SelectField';

const StationFilter = ({ stationList, station, onStationChange, handleSearch }) => {
  const { classes } = useStyles();

  return (
    <Grid
      className={classes.filter}
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={2}>
        <Typography variant="body1">以製程站別篩選：</Typography>
      </Grid>
      <Grid item xs={3}>
        <SelectField
          label="製程站別"
          placeholder="製程站別"
          value={station}
          handleChange={onStationChange}
          selections={stationList}
          id="station"
        />
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<SearchRoundedIcon />}
          onClick={handleSearch}
        >
          查詢
        </Button>
      </Grid>
    </Grid>
  );
}; // StationFilter

StationFilter.propTypes = {
  stationList: PropTypes.array.isRequired,
  station: PropTypes.string.isRequired,
  onStationChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default StationFilter;
