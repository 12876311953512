/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compareDesc, format, addDays, subDays } from 'date-fns';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import useCommonStyles from '../../useStyles';
import useStyles from './useStyles';
import Typography from '../../../../components/Typography/Typography';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import Divider from '../../../../components/Divider/Divider';
import SelectField from '../../../../components/SelectField/SelectField';
import ReadonlyField from '../../../../components/ReadonlyField/ReadonlyField';
// import SelectAutoComplete from '../../../../components/SelectField/SelectAutoComplete';
import {
  finishedFilterIntervalSelection,
  finishedFilterSeasonSelection,
  finishedFilterSemiSelection,
} from '../../../../constants/configSelections';
import { reportFinishedFilterInterval } from '../../../../constants/enums';
import Button from '../../../../components/Button/Button';
import AutoComplete from '../../../../components/SelectField/AutoComplete';

const FinishedFilter = ({
  finishedFilter,
  setFinishedFilter,
  handleFetchFinishedData,
  advanceData,
  getAdvanceSelections,
  showAdvanced,
  setShowAdvanced,
  advancedSelectionValue,
  handleSelectionChange,
  resetAdvanceFilter,
  advancedSelectionValueError,
}) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const hasDateErr = compareDesc(new Date(finishedFilter.endDate), new Date()) < 0;

  const handleFinishedFilterChange = (e) => {
    setFinishedFilter((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    resetAdvanceFilter();
  };

  const calcEndDate = (startDate) => {
    const { interval } = finishedFilter;
    let calcDate = startDate;
    if (interval === reportFinishedFilterInterval.WEEKLY) {
      calcDate = addDays(new Date(startDate), 7);
    }
    return calcDate;
  };

  const handleFinishedStartDateChange = (date) => {
    resetAdvanceFilter();
    const endDate = calcEndDate(date);
    setFinishedFilter((prev) => ({
      ...prev,
      endDate,
      startDate: date,
    }));
  };

  const handleFinishedMonthChange = (date) => {
    setFinishedFilter((prev) => ({
      ...prev,
      month: date,
    }));
  };

  const handleFinishedYearChange = (date) => {
    setFinishedFilter((prev) => ({
      ...prev,
      year: date,
    }));
  };

  const handleAdvancedSearch = () => {
    getAdvanceSelections();
  };
  const handleCancelAdvancedSearch = () => {
    setShowAdvanced(false);
    resetAdvanceFilter();
  };

  useEffect(() => {
    resetAdvanceFilter();
  }, [finishedFilter]);

  return (
    <PaperContainer
      className={classes.filter_container}
      // style={!showAdvanced && { height: '440px' }}
    >
      <Typography variant="h6">設定分析區間</Typography>
      <div className={`${commonClasses.content} ${classes.filter_content}`}>
        <SelectField
          id="interval"
          label="選擇區間"
          placeholder="請選擇查詢區間"
          value={String(finishedFilter.interval)}
          selections={finishedFilterIntervalSelection}
          handleChange={handleFinishedFilterChange}
        />
        <Divider
          className={commonClasses.dashed_divider}
          style={{ marginTop: 24, marginBottom: 16 }}
        />
        {finishedFilter.interval === reportFinishedFilterInterval.WEEKLY && (
          <>
            <DatePicker
              id="weeklyStartDatePicker"
              label="查詢起始日期"
              value={finishedFilter.startDate}
              onChange={handleFinishedStartDateChange}
              hasDateErr={hasDateErr}
              errMsg="結束日期不得大於今日"
              maxDate={subDays(new Date(), 7)}
            />
            <ReadonlyField
              name="finishedFilterEndDate"
              label="查詢結束日期"
              value={format(finishedFilter.endDate, 'yyyy-MM-dd')}
              className={classes.finished_date}
            />
          </>
        )}

        {finishedFilter.interval === reportFinishedFilterInterval.MONTHLY && (
          <DatePicker
            id="monthlyDatePicker"
            label="查詢月份"
            value={format(finishedFilter.month, 'yyyy-MM')}
            onChange={handleFinishedMonthChange}
            hasDateErr={false}
            errMsg=""
            format="yyyy-MM"
            configs={{
              openTo: 'year',
              views: ['year', 'month'],
            }}
          />
        )}

        {(finishedFilter.interval === reportFinishedFilterInterval.QUARTERLY ||
          finishedFilter.interval === reportFinishedFilterInterval.SEMI_ANNUALLY ||
          finishedFilter.interval === reportFinishedFilterInterval.ANNUALLY) && (
          <>
            <DatePicker
              id="quarterlyYearPicker"
              label="查詢年份"
              value={format(finishedFilter.year, 'yyyy')}
              onChange={handleFinishedYearChange}
              hasDateErr={false}
              errMsg=""
              format="yyyy"
              configs={{
                openTo: 'year',
                views: ['year'],
              }}
            />
            {finishedFilter.interval === reportFinishedFilterInterval.QUARTERLY && (
              <SelectField
                id="quarter"
                label="查詢季度"
                value={finishedFilter.quarter}
                placeholder="請選擇查詢季度"
                handleChange={handleFinishedFilterChange}
                selections={finishedFilterSeasonSelection}
              />
            )}
            {finishedFilter.interval === reportFinishedFilterInterval.SEMI_ANNUALLY && (
              <SelectField
                id="semi"
                label="查詢區間"
                value={finishedFilter.semi}
                placeholder="請選擇查詢區間"
                handleChange={handleFinishedFilterChange}
                selections={finishedFilterSemiSelection}
              />
            )}
          </>
        )}
        <div
          className={classes.finished_btn_div}
          style={{ display: !showAdvanced ? 'flex' : 'block' }}
        >
          {!showAdvanced ? (
            <div>
              <Button
                variant="outlined"
                color="primary"
                customClasses={classes.advance_filter_btn}
                startIcon={<ImageSearchRoundedIcon />}
                onClick={handleAdvancedSearch}
              >
                進階查詢
              </Button>
            </div>
          ) : (
            <Button
              variant="outlined"
              color="grey"
              rounded
              startIcon={<ExpandLessRoundedIcon />}
              onClick={handleCancelAdvancedSearch}
            >
              取消進階查詢
            </Button>
          )}
          {showAdvanced && (
            <div className={classes.auto_complete_selection_div}>
              <AutoComplete
                options={advanceData.scOdnoArray}
                label="工令單號"
                id="scOdno"
                value={advancedSelectionValue.scOdno}
                onChange={handleSelectionChange}
                placeholder="請輸入工令單號"
                error={advancedSelectionValueError}
              />
              <AutoComplete
                options={advanceData.transferArray}
                label="移轉單號"
                id="transId"
                value={advancedSelectionValue.transId}
                onChange={handleSelectionChange}
                placeholder="請輸入移轉單號"
                error={advancedSelectionValueError}
              />
              <AutoComplete
                options={advanceData.prodNameArray}
                label="產品名稱"
                value={advancedSelectionValue.prodName}
                onChange={handleSelectionChange}
                id="prodName"
                placeholder="請輸入產品名稱"
                error={advancedSelectionValueError}
              />
              {advancedSelectionValueError && (
                <div style={{ color: '#b00020', textAlign: 'left' }}>
                  請至少填寫一個進階搜尋欄位!
                </div>
              )}
            </div>
          )}
          <div>
            <Button
              customClasses={classes.finished_btn}
              variant="contained"
              color="primary"
              rounded
              startIcon={<CheckRoundedIcon />}
              onClick={handleFetchFinishedData}
            >
              套用
            </Button>
          </div>
        </div>
      </div>
    </PaperContainer>
  );
};

FinishedFilter.propTypes = {
  finishedFilter: PropTypes.object.isRequired,
  setFinishedFilter: PropTypes.func.isRequired,
  handleFetchFinishedData: PropTypes.func.isRequired,
  advanceData: PropTypes.object.isRequired,
  getAdvanceSelections: PropTypes.func.isRequired,
  showAdvanced: PropTypes.bool.isRequired,
  setShowAdvanced: PropTypes.func.isRequired,
  advancedSelectionValue: PropTypes.object.isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
  resetAdvanceFilter: PropTypes.func.isRequired,
  advancedSelectionValueError: PropTypes.bool.isRequired,
};

export default FinishedFilter;
