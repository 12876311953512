/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useStyles from './useStyles';
import useCommonStyles from '../../useStyles';
import ConfigItemRow from '../ConfigItemRow';
import { measureStyleType, sinteringSizePath } from '../../../../constants/enums';
import ZMeasureStyle from './ZMeasureStyle';
import NMeasureStyle from './NMeasureStyle';
import LMeasureStyle from './LMeasureStyle';
import DBMeasureStyle from './DBMeasureStyle';
import Typography from '../../../../components/Typography/Typography';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import CircularProgress from '../../../../components/CircularProgress/CircularProgress';

const MeasureStyleContent = ({
  measureStyle,
  currentSubSpec,
  showTable,
  isLoading,
  renderRow,
  handleRowChange,
  currentSpec,
  measureConfig,
  diffNLMeasure,
  ampSpecConfig,
  specConfig,
  handleSubSpecChange,
  isLMeasure,
  isRStick,
  tabDirection,
  rowLength,
}) => {
  const scrollTarget = useRef(null);
  const [specSettings, setSpecSettings] = useState({});
  const [specItems, setSpecItems] = useState([]);
  const [currentRenderRow, setCurrentRenderRow] = useState([]);
  const [onScroll, setOnScroll] = useState(false);
  const tableEl = useRef(null);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const scrollObject = {
    position: 'sticky',
    top: '75px',
    backgroundColor: '#fff',
    opacity: '1',
    zIndex: '10',
    alignItem: 'middle',
    padding: measureStyle === measureStyleType.DB ? '1rem 1.5rem 0 1.5rem' : '1rem 1.5rem',
    width: 'auto',
  };

  const handleFocusChange = (e) => {
    const minTabIndex = 1;
    const currentTabIndex = e.target.tabIndex;
    if (e.key === 'Tab' && e.shiftKey) {
      e.preventDefault();
      let prevTabIndex = 0;
      if (currentTabIndex === minTabIndex) {
        prevTabIndex = currentTabIndex;
      } else {
        prevTabIndex = currentTabIndex - 1;
      }
      const prevEl = document.querySelectorAll(`[tabindex = "${prevTabIndex}"]`)[1];
      if (!prevEl) return;
      prevEl.focus();
      return;
    }
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      const nextTabIndex = currentTabIndex + 1;
      const nextEl = document.querySelectorAll(`[tabindex="${nextTabIndex}"]`)[1];
      if (!nextEl) return;
      nextEl.focus();
    }
  };

  const showSpecItemsType = (item) => {
    const { specItem, specRecipe, specTarget, specUpper, specLower, specBias, styleName } = item;
    const lowerValue =
      specLower.length > 0 ? (specUpper.length > 0 ? `${specLower} ` : `${specLower}↑ `) : '';
    const upperValue = specUpper.length > 0 ? ` ~ ${specUpper} ` : '';
    const targetValue = specTarget.length > 0 ? `/ ${specTarget}` : '';
    const specLLowerValue =
      specLower.length > 0 && specTarget.length > 0
        ? `${(parseFloat(specTarget) - parseFloat(specLower)).toFixed(2)}`
        : '';
    const specLUpperValue =
      specUpper.length > 0 && specTarget.length > 0
        ? `${(parseFloat(specTarget) + parseFloat(specUpper)).toFixed(2)}`
        : '';

    if (specItem === 'L' || specItem === '4' || styleName === 'L') {
      return `${specLLowerValue} ~ ${specLUpperValue}`;
    }
    if (specTarget.length !== 0 || specUpper.length !== 0 || specLower.length !== 0) {
      return `${lowerValue}${upperValue}${targetValue}`;
    }
    return '';
  };

  useEffect(() => {
    if (!currentSpec) return;
    setSpecSettings(currentSpec);
    if (measureStyle === measureStyleType.DB) {
      setSpecItems(
        specConfig.filter(
          (spec) => spec.styleId === measureStyle && spec.specRecipe === currentSubSpec
        )
      );
      return;
    }
    setSpecItems(specConfig.filter((spec) => spec.styleId === measureStyle));
  }, [currentSpec, currentSubSpec, specConfig]);

  useEffect(() => {
    if (tableEl.current) {
      tableEl.current.addEventListener('keydown', handleFocusChange);
    }

    return () => {
      if (tableEl.current) {
        tableEl.current.removeEventListener('keydown', handleFocusChange);
      }
    };
  }, []);

  useEffect(() => {
    if (showTable) {
      document.addEventListener('scroll', () => {
        if (!scrollTarget.current) return;
        const position = scrollTarget.current.getBoundingClientRect();
        if (window.pageYOffset >= position.bottom + position.height * 6) {
          setOnScroll(true);
          return;
        }
        setOnScroll(false);
      });
    }
  }, [showTable]);
  return (
    <>
      {showTable && onScroll && (
        <PaperContainer
          className={commonClasses.container}
          type="QCSinteringElectrical"
          // eslint-disable-next-line react/jsx-boolean-value
          positionFixed={true}
          scrollObject={scrollObject}
        >
          <ul className={`${commonClasses.table_header} ${classes.table_header}`}>
            <li className={`${commonClasses.table_column} ${commonClasses.onScroll_table_column}`}>
              <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`}>
                <Typography variant="subtitle1" className={classes.font_weight} />
              </div>
            </li>
            <li className={commonClasses.table_column}>
              <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`}>
                <Typography variant="subtitle2" />
              </div>
            </li>
            {specItems.length > 0 &&
              specItems.map((item) => {
                const {
                  specItem,
                  specRecipe,
                  specTarget,
                  specUpper,
                  specLower,
                  specBias,
                  styleName,
                } = item;

                return (
                  <li
                    className={`${commonClasses.table_column} ${classes.table_column}`}
                    key={item.specId}
                  >
                    <div className={`${commonClasses.table_cell} ${classes.table_cell}`}>
                      <Typography variant="subtitle1" className={classes.spec_config}>
                        <b>{specItem || specRecipe}</b>
                        <span className={classes.spec_divider} />
                        <i> {showSpecItemsType(item)}</i>
                      </Typography>
                    </div>
                    {specBias.length > 0 &&
                      measureStyle !== measureStyleType.N &&
                      isLMeasure !== 1 && (
                        <Typography variant="body1" style={{ marginBottom: 8, fontWeight: 500 }}>
                          量測偏差：{' '}
                          <b>{parseFloat(specBias) > 0 ? `+${parseFloat(specBias)}` : +specBias}</b>
                        </Typography>
                      )}
                  </li>
                );
              })}
          </ul>
        </PaperContainer>
      )}
      <div className={`${commonClasses.table}${commonClasses.content}`} ref={tableEl}>
        {!showTable && isLoading && <CircularProgress mt={3.75} mb={3.75} />}
        {showTable && (
          <>
            <div>
              {measureStyle === measureStyleType.Z && (
                <ZMeasureStyle specSettings={specSettings} measureConfig={measureConfig} />
              )}
              {measureStyle === measureStyleType.N && (
                <NMeasureStyle
                  specSettings={specSettings}
                  measureConfig={measureConfig}
                  diffNLMeasure={diffNLMeasure}
                />
              )}
              {measureStyle === measureStyleType.L && (
                <LMeasureStyle
                  specSettings={specSettings}
                  measureConfig={measureConfig}
                  diffNLMeasure={diffNLMeasure}
                />
              )}
              {measureStyle === measureStyleType.DB && (
                <DBMeasureStyle
                  currentSubSpec={currentSubSpec}
                  currentSpec={currentSpec}
                  handleSubSpecChange={handleSubSpecChange}
                  measureConfig={measureConfig}
                  ampSpecConfig={ampSpecConfig}
                />
              )}
            </div>

            <ul
              className={`${commonClasses.table_header} ${classes.table_header} ${classes.paper_padding}`}
              ref={scrollTarget}
            >
              <li className={commonClasses.table_column}>
                <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`}>
                  <Typography variant="subtitle1" className={classes.font_weight}>
                    動作
                  </Typography>
                </div>
              </li>
              <li className={commonClasses.table_column}>
                <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`}>
                  <Typography variant="subtitle2">序號</Typography>
                </div>
              </li>
              {specItems.length > 0 &&
                specItems.map((item) => {
                  const {
                    specItem,
                    specRecipe,
                    specTarget,
                    specUpper,
                    specLower,
                    specBias,
                    styleName,
                  } = item;

                  return (
                    <li
                      className={`${commonClasses.table_column} ${classes.table_column} `}
                      key={item.specId}
                    >
                      <div className={`${commonClasses.table_cell} ${classes.table_cell}`}>
                        <Typography variant="subtitle1" className={classes.spec_config}>
                          <b>{specItem || specRecipe}</b>
                          <span className={classes.spec_divider} />
                          <i>{showSpecItemsType(item)}</i>
                        </Typography>
                      </div>
                      {specBias.length > 0 &&
                        measureStyle !== measureStyleType.N &&
                        isLMeasure !== 1 && (
                          <Typography variant="body1" style={{ marginBottom: 8, fontWeight: 500 }}>
                            量測偏差：{' '}
                            <b>
                              {parseFloat(specBias) > 0 ? `+${parseFloat(specBias)}` : +specBias}
                            </b>
                          </Typography>
                        )}
                    </li>
                  );
                })}
            </ul>
            <div className={classes.paper_padding}>
              {renderRow.map((row, index) => (
                <ConfigItemRow
                  path={sinteringSizePath.ELECTRICAL}
                  data={row}
                  specConfig={specItems}
                  key={row.rowId}
                  rowId={row.rowId}
                  handleRowChange={handleRowChange}
                  currentSubSpec={currentSubSpec}
                  measureStyle={measureStyle}
                  foldingAllRows
                  index={index}
                  tabDirection={tabDirection}
                  rowLength={rowLength}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

MeasureStyleContent.propTypes = {
  measureStyle: PropTypes.string.isRequired,
  currentSubSpec: PropTypes.string.isRequired,
  renderRow: PropTypes.array.isRequired,
  handleRowChange: PropTypes.array.isRequired,
  showTable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentSpec: PropTypes.array.isRequired,
  measureConfig: PropTypes.array.isRequired,
  diffNLMeasure: PropTypes.array.isRequired,
  ampSpecConfig: PropTypes.array.isRequired,
  specConfig: PropTypes.array.isRequired,
  handleSubSpecChange: PropTypes.func.isRequired,
  isLMeasure: PropTypes.number.isRequired,
  isRStick: PropTypes.bool.isRequired,
  tabDirection: PropTypes.string.isRequired,
  rowLength: PropTypes.number.isRequired,
};

export default MeasureStyleContent;
