import React from 'react';
import PropTypes from 'prop-types';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Typography from '../Typography/Typography';

// 判斷如果 id !== 'detail-title' ，就不要加 Typography
const getID = (ID) => {
  if (ID !== 'detail-title') {
    return false;
  }
  return true;
};

const DialogTitle = ({ className, children, id, ...rest }) => (
  <MuiDialogTitle className={className} id={id} {...rest}>
    {getID(id) ? <Typography variant="body1">{children}</Typography> : <>{children}</>}
  </MuiDialogTitle>
);

DialogTitle.defaultProps = {
  className: '',
  id: '',
  children: '',
};

DialogTitle.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.string,
};

export default DialogTitle;
