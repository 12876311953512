import React from 'react';
import PropTypes from 'prop-types';
import MuiTableContainer from '@mui/material/TableContainer';
import CircularProgress from '../CircularProgress/CircularProgress';
import Table from '../Table/Table';

const TableContainer = ({ isLoading, ...rest }) => (
  <MuiTableContainer>
    {isLoading ? (
      <CircularProgress alignItems="center" style={{ height: '200px' }} />
    ) : (
      <Table size="small" {...rest} />
    )}
  </MuiTableContainer>
);

TableContainer.defaultProps = {};

TableContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default TableContainer;
