/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import useStyes from './QCSinteringSize/useStyles';
import useCommonStyles from '../useStyles';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';
import checkboxReducer, { checkboxActions } from '../../../reducers/checkboxReducer';
import CheckboxGroup from '../../../components/CheckboxGroup/CheckboxGroup';
import {
  measureStyleType,
  sinteringSizePath,
  specConfigItemStatus,
  tabDirectionType,
} from '../../../constants/enums';
import { calcTargetValue as calcTargetValueUtil, isOutSpec as isOutSpecUtil } from '../detailUtils';

const ConfigItemRow = ({
  path,
  data,
  handleRowChange,
  foldingAllRows,
  rowId,
  specConfig,
  currentSubSpec,
  measureStyle,
  index,
  initialRowState,
  tabDirection,
  rowLength,
  otherIsDone,
}) => {
  const { specItems, defectType = [] } = data;
  const [defectTypeItems, defectTypeDispatch] = useReducer(checkboxReducer, []);
  const [foldingRow, setFoldingRow] = useState(true);
  const [rowState, setRowState] = useState(initialRowState);
  const [disabledRowState, setDisabledRowState] = useState([]);
  const [targetBias, setTargetBias] = useState('');
  const isOutSpec = useRef([]);
  const rowData = useRef([]);
  const { classes } = useStyes();
  const commonClasses = useCommonStyles().classes;
  const toggleFoldingRow = () => {
    setFoldingRow((prev) => !prev);
  };

  const calcTargetValue = (specId) => {
    const targetSpec = specConfig.find((spec) => spec.specId === specId);
    if (path === sinteringSizePath.ELECTRICAL) {
      return calcTargetValueUtil(targetSpec);
    }
    const maxValue =
      targetSpec && targetSpec.specUpper.length > 0
        ? +targetSpec.specTarget + +targetSpec.specUpper
        : '';
    const minValue =
      targetSpec && targetSpec.specLower.length > 0
        ? +targetSpec.specLower < 0
          ? +targetSpec.specTarget + +targetSpec.specLower
          : +targetSpec.specTarget - +targetSpec.specLower
        : '';
    const upperLimit =
      targetSpec && targetSpec.limitUpper.length > 0
        ? +targetSpec.specTarget + +targetSpec.limitUpper
        : '';
    const lowerLimit =
      targetSpec && targetSpec.limitLower.length > 0
        ? +targetSpec.limitLower < 0
          ? +targetSpec.specTarget + +targetSpec.limitLower
          : +targetSpec.specTarget - +targetSpec.limitLower
        : '';
    return [maxValue, minValue, upperLimit, lowerLimit];
  };

  const calcIsOutSpec = (specId, value) => {
    const valueNum = parseFloat(value);
    if (!valueNum) return false;
    const [maxValue, minValue, upperLimit, lowerLimit] = calcTargetValue(specId);
    const [outSpec, outLimit] = isOutSpecUtil(value, maxValue, minValue, upperLimit, lowerLimit);
    return outSpec || outLimit;
  };

  const handleInputChange = (e, addBias, outSpec, outLimit) => {
    setRowState((prev) => ({
      ...prev,
      specItems: prev.specItems.map((item) =>
        item.specId && item.specId === e.target.id
          ? {
              ...item,
              value:
                addBias && item.specItem === 'N'
                  ? (addBias * 9.8).toFixed(2)
                  : addBias || e.target.value,
            }
          : item.specRecipe && item.specRecipe === e.target.name
          ? { ...item, value: addBias || e.target.value }
          : item
      ),
      isInvalid: false,
    }));
    rowData.current = {
      ...rowData.current,
      specItems: rowData.current.specItems.map((item) =>
        item.specId && item.specId === e.target.id
          ? {
              ...item,
              value:
                addBias && item.specItem === 'N'
                  ? (addBias * 9.8).toFixed(2)
                  : addBias || e.target.value,
            }
          : item.specRecipe === e.target.name
          ? { ...item, value: addBias || e.target.value }
          : item
      ),
      isInvalid: false,
    };
    if (isOutSpec.current)
      // 判斷是否有ＮＧ
      // 這裡是為了處理Ｎ型態有按 enter
      isOutSpec.current = isOutSpec.current.map((spec) =>
        spec.specId && spec.specId === e.target.id
          ? {
              ...spec,
              isOutSpec:
                spec.specItem === 'N'
                  ? calcIsOutSpec(spec.specId, (+e.target.value * 9.8).toFixed(2))
                  : outSpec || outLimit,
            }
          : spec.specRecipe && spec.specRecipe === e.target.name
          ? {
              ...spec,
              isOutSpec:
                spec.specItem === 'N'
                  ? calcIsOutSpec(spec.specId, (+e.target.value * 9.8).toFixed(2))
                  : outSpec || outLimit,
            }
          : spec
      );
  };

  const handleInputBlur = (e, target, bias, outSpec, outLimit) => {
    if (e.target.value === '') return;
    if (isOutSpec.current)
      // 判斷是否有ＮＧ
      // 這裡是為了處理Ｎ型態沒有按 enter
      isOutSpec.current = isOutSpec.current.map((spec) =>
        spec.specId && spec.specId === e.target.id && spec.colStatus !== 0
          ? {
              ...spec,
              isOutSpec:
                spec.specItem === 'N'
                  ? calcIsOutSpec(spec.specId, e.target.value)
                  : outSpec || outLimit,
            }
          : spec.specRecipe && spec.specRecipe === e.target.name
          ? {
              ...spec,
              isOutSpec:
                spec.specItem === 'N'
                  ? calcIsOutSpec(spec.specId, e.target.value)
                  : outSpec || outLimit,
            }
          : spec
      );
    if (e.type === 'keydown' && e.keyCode === 13)
      handleInputChange(e, +e.target.value + +bias, outSpec, outLimit);
    setTargetBias(target);
  };

  const handleClearRow = () => {
    rowData.current = {
      ...rowData.current,
      specItems: rowData.current.specItems.map((item) => ({
        ...item,
        value: '',
      })),
      defectType: rowData.current.defectType.map((type) => ({ ...type, checked: false })),
    };
    setRowState((prev) => ({
      ...prev,
      specItems: prev.specItems.map((item) => ({
        ...item,
        value: '',
      })),
      defectType: prev.defectType.map((type) => ({ ...type, checked: false })),
    }));
    defectTypeDispatch({
      type: checkboxActions.RESET,
    });
  };
  useEffect(() => {
    if (path === sinteringSizePath.ELECTRICAL) {
      if (measureStyle === measureStyleType.DB) {
        // 燒結電性檢測DB量測項目要篩出Amp分頁
        const specItemsFilter = specItems.filter((spec) => spec.specRecipe === currentSubSpec);
        setRowState({
          ...data,
          specItems: [...specItemsFilter],
        });
        setDisabledRowState({
          ...data,
          specItems: [...specItemsFilter],
        });
        rowData.current = {
          ...data,
          specItems: [...specItemsFilter],
        };
        isOutSpec.current = specItemsFilter
          // 篩選掉disabled的資料（防止原有ＮＧ但新資料無ＮＧ，而抓取到舊資料的ＮＧ導致錯誤
          .filter((item) => item.colStatus !== 0)
          .map((spec) => ({
            specId: spec.specId,
            specItem: spec.specItem,
            isOutSpec: calcIsOutSpec(spec.specId, spec.value),
          }));
      } else {
        // 燒結電性檢測沒有defectType項目
        setRowState({
          ...data,
          specItems: [...specItems],
        });
        setDisabledRowState({
          ...data,
          specItems: [...specItems],
        });
        rowData.current = {
          ...data,
          specItems: [...specItems],
        };
        isOutSpec.current = specItems
          // 篩選掉disabled的資料（防止原有ＮＧ但新資料無ＮＧ，而抓取到舊資料的ＮＧ導致錯誤
          .filter((item) => item.colStatus !== 0)
          .map((spec) => ({
            specId: spec.specId,
            specItem: spec.specItem,
            isOutSpec: calcIsOutSpec(spec.specId, spec.value),
          }));
      }
    } else {
      setRowState({
        ...data,
        specItems: [...specItems],
        defectType: [...defectType],
      });
      setDisabledRowState({
        ...data,
        specItems: [...specItems],
        defectType: [...defectType],
      });
      rowData.current = {
        ...data,
        specItems: [...specItems],
        defectType: [...defectType],
      };
      defectTypeDispatch({
        type: checkboxActions.INIT,
        init: defectType.map((item) => ({
          name: item.name,
          label: item.label,
          checked: item.checked,
        })),
      });
      isOutSpec.current = specItems
        // 篩選掉disabled的資料（防止原有ＮＧ但新資料無ＮＧ，而抓取到舊資料的ＮＧ導致錯誤
        .filter((item) => item.colStatus !== 0)
        .map((spec) => ({
          specId: spec.specId,
          specItem: spec.specItem,
          isOutSpec: calcIsOutSpec(spec.specId, spec.value),
        }));
    }
  }, [data, currentSubSpec]);

  useEffect(() => {
    setFoldingRow(foldingAllRows);
  }, [foldingAllRows]);

  useEffect(() => {
    if (path === sinteringSizePath.ELECTRICAL) return;
    setRowState((prev) => ({
      ...prev,
      specItems: [...prev.specItems],
      defectType: [...defectTypeItems],
    }));
    setDisabledRowState({
      ...data,
      specItems: [...specItems],
      defectType: [...defectType],
    });
    rowData.current = {
      ...rowData.current,
      specItems: [...rowData.current.specItems],
      defectType: [...defectTypeItems],
    };
  }, [defectTypeItems]);

  useEffect(() => {
    handleRowChange((prev) =>
      prev.map((item) =>
        item.rowId === rowId
          ? {
              ...item,
              gatherData: (withDisabledCol = false) => {
                const rowDetail = {
                  ...rowData.current,
                  rowId,
                  isInvalid: rowData.current.specItems.some((spec) => spec.value === ''),
                  isEmpty: rowData.current.specItems.every((spec) => spec.value === ''),
                  isOutSpec: isOutSpec.current.some((spec) => spec.isOutSpec),
                  // specItems: [...rowData.current.specItems],
                  specItems: withDisabledCol
                    ? [...rowData.current.specItems]
                    : rowData.current.specItems.filter(
                        (spec) => spec.colStatus !== specConfigItemStatus.REMOVED
                      ),
                };
                if (path !== sinteringSizePath.ELECTRICAL) {
                  rowDetail.defectType = [...rowData.current.defectType];
                }
                return rowDetail;
              },
            }
          : item
      )
    );
  }, []);
  return (
    <>
      <div className={`${commonClasses.table_row}`}>
        <div className={commonClasses.table_column}>
          <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`}>
            <Button
              variant="outlined"
              rounded
              color="primary"
              customClasses={commonClasses.empty_btn}
              onClick={handleClearRow}
              disabled={otherIsDone}
            >
              清空
            </Button>
          </div>
        </div>
        <div className={commonClasses.table_column}>
          <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`}>
            {index + 1}
          </div>
        </div>
        {rowState &&
          rowState.specItems &&
          rowState.specItems
            .filter((item) => item.colStatus !== specConfigItemStatus.REMOVED)
            .map((col, idx) => {
              const targetSpec = specConfig.find((spec) => spec.specId === col.specId);
              if (!targetSpec) return '';
              const [maxValue, minValue, upperLimit, lowerLimit] = calcTargetValue(col.specId);

              const [outSpec, outLimit] = isOutSpecUtil(
                col.value,
                maxValue,
                minValue,
                upperLimit,
                lowerLimit
              );
              const enableItemsLength = rowState.specItems.filter(
                (spec) => spec.colStatus !== specConfigItemStatus.REMOVED
              ).length;

              return (
                <div
                  className={`${commonClasses.table_column} ${classes.table_column} ${
                    col.specItem === targetBias && col.value !== ''
                      ? commonClasses.bias_animation
                      : ''
                  }`}
                  key={`${rowId + 1}${col.specId}`}
                >
                  <InputField
                    id={col.specId}
                    name={col.specItem || col.specRecipe}
                    value={col.value}
                    type="number"
                    onChange={handleInputChange}
                    onBlur={(e) =>
                      handleInputBlur(e, col.specItem, targetSpec.specBias, outSpec, outLimit)
                    }
                    inputClasses={`${commonClasses.table_input} ${
                      outSpec ? commonClasses.out_spec : ''
                    } ${outLimit ? commonClasses.out_limit : ''}`}
                    tabIndex={
                      col.colStatus === specConfigItemStatus.REMOVED
                        ? 0
                        : tabDirection === tabDirectionType.BY_ITEM
                        ? `${(rowId + 1) * enableItemsLength + idx}`
                        : `${rowId + 1 + (enableItemsLength + idx) * rowLength}`
                    }
                    error={rowData.current.isInvalid}
                    onKeyDown={(e) =>
                      handleInputBlur(e, col.specItem, targetSpec.specBias, outSpec, outLimit)
                    }
                    disabled={otherIsDone}
                  />
                  {targetSpec.specBias && (
                    <i className={commonClasses.table_cell_bias}>
                      {parseInt(targetSpec.specBias, 10) > 0
                        ? `+${parseFloat(targetSpec.specBias)}`
                        : targetSpec.specBias}
                    </i>
                  )}
                </div>
              );
            })}
        {disabledRowState &&
          disabledRowState.specItems &&
          disabledRowState.specItems
            .filter((item) => item.colStatus === specConfigItemStatus.REMOVED)
            .map((col, idx) => {
              const targetSpec = specConfig.find((spec) => spec.specId === col.specId);
              if (!targetSpec) return '';
              const [maxValue, minValue, upperLimit, lowerLimit] = calcTargetValue(col.specId);

              const [outSpec, outLimit] = isOutSpecUtil(
                col.value,
                maxValue,
                minValue,
                upperLimit,
                lowerLimit
              );

              return (
                <div
                  className={`${commonClasses.table_column} ${classes.table_column} ${
                    col.specItem === targetBias && col.value !== ''
                      ? commonClasses.bias_animation
                      : ''
                  }`}
                  key={`${rowId + 1}${col.specId}`}
                >
                  <InputField
                    name={col.specItem || col.specRecipe}
                    value={col.value}
                    type="number"
                    inputClasses={`${commonClasses.table_input} ${
                      outSpec ? commonClasses.out_spec : ''
                    } ${outLimit ? commonClasses.out_limit : ''} ${commonClasses.removed_column}`}
                    error={rowData.current.isInvalid}
                    disabled
                  />
                  {targetSpec.specBias && (
                    <i className={commonClasses.table_cell_bias}>
                      {parseInt(targetSpec.specBias, 10) > 0
                        ? `+${parseFloat(targetSpec.specBias)}`
                        : targetSpec.specBias}
                    </i>
                  )}
                </div>
              );
            })}
        {path !== sinteringSizePath.ELECTRICAL && (
          <div className={commonClasses.table_column}>
            <div className={`${commonClasses.table_cell} ${classes.defect_type_cell}`}>
              {foldingRow && (
                <Button
                  variant="outlined"
                  endIcon={<ExpandMoreRoundedIcon />}
                  customClasses={classes.unfold_single_btn}
                  onClick={toggleFoldingRow}
                >
                  展開選項
                </Button>
              )}
              {!foldingRow && (
                <Button
                  variant="outlined"
                  endIcon={<ExpandLessRoundedIcon />}
                  customClasses={classes.unfold_single_btn}
                  onClick={toggleFoldingRow}
                >
                  收闔選項
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      {path !== sinteringSizePath.ELECTRICAL && (
        <div className={`${commonClasses.table_row} ${foldingRow ? classes.folding_rows : ''}`}>
          <div className={commonClasses.table_column}>
            <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`} />
          </div>
          <div className={commonClasses.table_column}>
            <CheckboxGroup
              itemList={defectTypeItems}
              checkboxDispatch={defectTypeDispatch}
              direction="row"
              reverse
              disabled={otherIsDone}
            />
          </div>
        </div>
      )}
    </>
  );
};

ConfigItemRow.defaultProps = {
  currentSubSpec: '',
  measureStyle: 0,
};

ConfigItemRow.propTypes = {
  path: PropTypes.string.isRequired,
  handleRowChange: PropTypes.func.isRequired,
  foldingAllRows: PropTypes.bool.isRequired,
  rowId: PropTypes.number.isRequired,
  specConfig: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  currentSubSpec: PropTypes.string,
  measureStyle: PropTypes.number,
  index: PropTypes.number.isRequired,
  initialRowState: PropTypes.object.isRequired,
  tabDirection: PropTypes.string.isRequired,
  rowLength: PropTypes.number.isRequired,
  otherIsDone: PropTypes.bool,
};

export default React.memo(ConfigItemRow);
