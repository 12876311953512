/* eslint-disable no-lonely-if */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react';
import useStyles from '../useStyles';
import Typography from '../../../components/Typography/Typography';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import ConfigTable from '../../../components/ConfigTable/ConfigTable';
import TablePagination from '../../../components/TablePagination/TablePagination';
import MachineTableToolbar from './SettingsConfigMachineTableToolbar';
import { DialogContext } from '../../../contexts/DialogContext';
import { dialogActions } from '../../../reducers/dialogReducer';
import useAxios from '../../../hooks/useAxios';
import Snackbar from '../../../components/Snackbar/Snackbar';
import styleConst from '../../../constants/style';
import { stationType as stationEnum } from '../../../constants/enums';
import useCancelToken from '../../../hooks/useCancelToken';

const SettingsConfigMachineType = () => {
  const { classes } = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [willDeleteList, setWillDeleteList] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [stationFilter, setStationFilter] = useState('1');
  const [currentStation, setCurrentStation] = useState('1');
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [grindStationList, setGrindStationList] = useState([]);
  const [stationTypeList, setStationTypeList] = useState([]);
  const [settingsConfigTableContent, setSettingsConfigTableContent] = useState([]);
  const [settingsConfigTableHeadCells, setSettingsConfigTableHeadCells] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [statusMsg, setStatusMsg] = useState('');
  const [showStatus, setShowStatus] = useState(false);
  const [statusType, setStatusType] = useState('success');
  const [isLoading, setIsLoading] = useState(false);
  const snackbarTimer = useRef(null);
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();

  const rowsPerPage = styleConst.ROWS_PER_PAGE;

  const getStationType = async () => {
    setIsLoading(true);
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/stationType',
      {},
      true,
      cancelToken
    );
    if (status) {
      const { stationType, station } = result;
      setGrindStationList(
        station
          .filter((type) => type.stationTypeId === parseInt(stationEnum.GRINDING, 10))
          .map((type) => ({
            id: type.stationId.toString(),
            value: type.stationId.toString(),
            name: type.stationName,
          }))
      );
      setStationTypeList(
        stationType
          .filter((type) => type.stationTypeId !== parseInt(stationEnum.DRILLING, 10))
          .map((type) => ({
            id: type.stationTypeId.toString(),
            value: type.stationTypeId.toString(),
            name: type.stationTypeName,
          }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '無法取得製程站別',
          message: noPrivilegeMsg || result,
        },
      });
    }
    setIsLoading(false);
  };

  const getMachineData = async (page = currentPage, isFromLastPage = false) => {
    setIsLoading(true);
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/machineData',
      { stationType: stationFilter, page },
      true,
      cancelToken
    );
    if (status) {
      const { machine, count } = result;
      setTotalRows(count);
      // eslint-disable-next-line prettier/prettier
      const grindStationName = stationTypeList.find(
        (type) => type.id === stationEnum.GRINDING
      ).name;
      setSettingsConfigTableContent(
        machine.map((type) => ({
          rowId: type.machineId,
          columns: [
            {
              name: 'stationTypeId',
              value:
                stationFilter === parseInt(stationEnum.GRINDING, 10)
                  ? stationEnum.GRINDING
                  : type.stationId.toString(),
              displayValue:
                stationFilter === parseInt(stationEnum.GRINDING, 10)
                  ? grindStationName
                  : type.stationName,
              visible: true,
              type: 'select',
              isEditable: false,
              // label: '製程站別',
              // placeholder: '請選擇製程站別',
              // selectionList: stationTypeList,
              // isRequired: true,
              // error: false,
            },
            {
              name: 'stationId',
              value: type.stationId.toString(),
              displayValue: type.stationName,
              visible: stationFilter === parseInt(stationEnum.GRINDING, 10),
              type: 'select',
              isEditable: true,
              conditional: stationFilter === parseInt(stationEnum.GRINDING, 10),
              relatedField: 'stationTypeId',
              targetValue: stationEnum.GRINDING,
              label: '機台類型',
              placeholder: '請選擇機台類型',
              selectionList: grindStationList,
              isRequired: true,
              error: false,
            },
            {
              name: 'machineName',
              value: type.machineName,
              displayValue: type.machineName,
              visible: true,
              type: 'text',
              isEditable: true,
              label: '機台名稱',
              placeholder: '請輸入機台名稱',
              isRequired: true,
              error: false,
            },
            {
              name: 'machineId',
              value: type.machineId,
              displayValue: type.machineId,
              visible: false,
              type: 'text',
              isEditable: false,
            },
          ],
          isEditing: false,
          isChecked: false,
        }))
      );
      setIsChecked(false);
    } else {
      if (isFromLastPage || currentPage <= 1) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '無法取得機台列表',
            message: noPrivilegeMsg || `目前無法取得機台列表，${result}`,
          },
        });
        return;
      }
      setCurrentPage(currentPage - 1);
      getMachineData(currentPage - 1, true);
    }
    setIsLoading(false);
  };

  const onRowEditClick = (targetRowId, isOpenEdit) => {
    setSettingsConfigTableContent((prev) =>
      prev.map((config) =>
        config.rowId === targetRowId
          ? { ...config, columns: [...config.columns], isEditing: isOpenEdit, isChecked: false }
          : config
      )
    );
    if (isOpenEdit) {
      const targetRow = settingsConfigTableContent.find((config) => config.rowId === targetRowId);
      if (currentStation === stationEnum.GRINDING) {
        setEditedData({
          stationTypeId: targetRow.columns[0].value,
          stationId: targetRow.columns[1].value,
          machineName: targetRow.columns[2].value,
          machineId: targetRow.columns[3].value,
        });
      } else {
        setEditedData({
          stationTypeId: targetRow.columns[1].value,
          machineName: targetRow.columns[2].value,
          machineId: targetRow.columns[3].value,
        });
      }
    } else {
      setEditedData({});
    }
  };

  const handleRowError = (targetRow, validation) => {
    setSettingsConfigTableContent((prev) =>
      prev.map((config) =>
        config.rowId === targetRow.rowId
          ? {
              ...config,
              columns: targetRow.columns.map((col) => ({ ...col, error: !validation[col.name] })),
            }
          : config
      )
    );
  };

  const handleRowEditing = (e) => {
    setEditedData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const openSnackbar = () => {
    setShowStatus(true);
    clearTimeout(snackbarTimer.current);
    snackbarTimer.current = setTimeout(() => {
      setShowStatus(false);
      clearTimeout(snackbarTimer.current);
    }, 3000);
  };

  const handleEditData = async () => {
    const machine =
      editedData.stationTypeId === stationEnum.GRINDING
        ? {
            ...editedData,
            isEnabled: 1,
          }
        : {
            ...editedData,
            stationId: editedData.stationTypeId,
            isEnabled: 1,
          };
    const data = {
      machine: [{ ...machine }],
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/machineSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      setStatusMsg('已成功更新機台設定！');
      setStatusType('success');
      getMachineData();
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '更新機台設定失敗，請稍後再試，或通知網管人員！');
    }
    openSnackbar();
    setEditedData({});
  };

  const handleDelete = async () => {
    const data = {
      machine: willDeleteList.map((machine) => {
        const { rowId, columns } = machine;
        return {
          machineId: rowId,
          stationId: columns[1].value,
          stationName: columns[1].displayValue,
          machineName: columns[2].value,
          isEnabled: 0,
        };
      }),
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/machineSetting',
      data,
      true,
      cancelToken
    );
    if (status) {
      setStatusMsg(`已成功刪除 ${willDeleteList.length} 筆機台設定！`);
      setStatusType('success');
      setWillDeleteList([]);
      setIsChecked(false);
      getMachineData();
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '刪除多筆機台設定失敗，請稍後再試，或聯絡網管人員！');
    }
    openSnackbar();
  };

  const handleCheckBoxChecked = (targetRow) => {
    setSettingsConfigTableContent((prev) =>
      prev.map((config) =>
        config.rowId === targetRow.rowId
          ? {
              ...config,
              columns: [...config.columns],
              isChecked: !config.isChecked,
            }
          : config
      )
    );
  };

  const handleWillDeleteList = (targetRow, isAdd) => {
    let newList = [];
    if (isAdd) {
      newList = [...willDeleteList, targetRow];
    } else {
      newList = willDeleteList.filter((item) => item.rowId !== targetRow.rowId);
    }
    setWillDeleteList([...newList]);
    if (newList.length > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const onMultiRowsDelete = () => {
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '確認刪除？',
        message: '是否確認刪除此資料？',
        handleConfirm: handleDelete,
      },
    });
  };

  const handleChangePage = (targetPage) => {
    setCurrentPage(targetPage);
    getMachineData(targetPage);
  };

  const handleStationChange = (e) => {
    setStationFilter(e.target.value);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setCurrentStation(stationFilter);
    getMachineData(1);
  };

  const handleAddMachine = async (machine) => {
    const data =
      currentStation === stationEnum.GRINDING
        ? {
            ...machine,
          }
        : {
            ...machine,
            stationId: machine.stationTypeId,
            stationName: machine.stationTypeName,
          };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/machineSetting',
      {
        machine: [{ ...data }],
      },
      true,
      cancelToken
    );
    if (status) {
      getMachineData();
      setStatusMsg('新增機台設定成功！');
      setStatusType('success');
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || '新增機台設定失敗，請稍後再試！');
    }
    openSnackbar();
  };

  const handleRowDelete = async (machine) => {
    const { columns, rowId } = machine;
    const machineName = columns[2].displayValue;
    const data = {
      machineName,
      stationId: columns[0].value,
      machineId: rowId,
      stationName: columns[0].displayValue,
      isEnabled: 0,
    };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/machineSetting',
      { machine: [data] },
      true,
      cancelToken
    );
    if (status) {
      getMachineData();
      setStatusMsg(`已成功刪除 ${machineName} 機台！`);
      setStatusType('success');
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || `刪除 ${machineName} 機台失敗，請稍後再試！`);
    }
    openSnackbar();
  };

  useEffect(() => {
    const hasEditingRow = settingsConfigTableContent.find((item) => item.isEditing);
    if (hasEditingRow) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [settingsConfigTableContent]);

  useEffect(() => {
    if (currentStation === stationEnum.GRINDING) {
      setSettingsConfigTableHeadCells([
        { id: 'station', numeric: false, disablePadding: true, label: '製程站別' },
        { id: 'machine', numeric: false, disablePadding: true, label: '機台類型' },
        { id: 'machine', numeric: false, disablePadding: true, label: '機台名稱' },
      ]);
    } else {
      setSettingsConfigTableHeadCells([
        { id: 'station', numeric: false, disablePadding: true, label: '製程站別' },
        { id: 'machine', numeric: false, disablePadding: true, label: '機台名稱' },
      ]);
    }
  }, [currentStation]);

  useEffect(() => {
    if (stationTypeList.length > 0) {
      setIsLoading(false);
      // Make sure the stationList is already has data, then fetch the machine data
      getMachineData();
    }
  }, [stationTypeList.length]);

  useEffect(() => {
    getStationType();
    setStationFilter('1');
    setCurrentPage(1);

    return () => {
      clearTimeout(snackbarTimer.current);
    };
  }, []);

  return (
    <PaperContainer className={classes.container}>
      <Typography variant="h6">移轉單各站別機台</Typography>
      <MachineTableToolbar
        handleDelete={onMultiRowsDelete}
        isChecked={isChecked}
        stationList={grindStationList}
        stationTypeList={stationTypeList}
        station={stationFilter}
        onStationChange={handleStationChange}
        handleSearch={handleSearch}
        handleAddMachine={handleAddMachine}
        isEditing={isEditing}
        isAdding={isAdding}
        handleIsAdding={setIsAdding}
      />
      <Snackbar msg={statusMsg} type={statusType} open={showStatus} className={classes.snackbar} />
      <ConfigTable
        isCheckBox
        isLoading={isLoading}
        isAdding={isAdding}
        isEditing={isEditing}
        handleIsAdding={setIsAdding}
        headCells={settingsConfigTableHeadCells}
        tableContentList={settingsConfigTableContent}
        isDeleteBtnShow
        handleCheckBoxChecked={handleCheckBoxChecked}
        onRowEditClick={onRowEditClick}
        handleRowError={handleRowError}
        handleRowEditing={handleRowEditing}
        editedData={editedData}
        handleEditData={handleEditData}
        onRowDelete={handleRowDelete}
        handleWillDeleteList={handleWillDeleteList}
      />
      <TablePagination
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handleChangePage}
        isEditing={isEditing}
      />
    </PaperContainer>
  );
};

export default SettingsConfigMachineType;
