/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from 'tss-react/mui';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '../Typography/Typography';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import List from '../List/List';
import ListItemText from '../ListItemText/ListItemText';

const AccordionSummary = withStyles(MuiAccordionSummary, {
  root: {
    minHeight: 48,
    '&.Mui-expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&.Mui-expanded': {
      margin: '0',
    },
  },
});

const useStyles = makeStyles()(() => ({
  accordion: {
    boxShadow: 'none',
  },
  accordionSummary: {
    borderBottom: '1px solid rgba(0, 0, 0, .2)',
    '&.MUi-expanded': {
      minHeight: 48,
      margin: 0,
    },
  },
}));

const Accordion = ({ title, itemList, checkboxDispatch, disabled, isViewing, codeValue }) => {
  const { classes } = useStyles();
  return (
    <MuiAccordion className={classes.accordion} defaultExpanded="true">
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <Typography variant="subtitle1">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isViewing ? (
          itemList.map(
            (item) =>
              codeValue.includes(Number(item.name)) && (
                <List key={item.name}>
                  <ListItemText primary={item.label} />
                </List>
              )
          )
        ) : (
          <CheckboxGroup
            itemList={itemList}
            checkboxDispatch={checkboxDispatch}
            disabled={disabled}
          />
        )}
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  itemList: PropTypes.array.isRequired,
  checkboxDispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isViewing: PropTypes.bool,
  codeValue: PropTypes.array,
};
