/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import useStyles from './useStyles';
import useCommonStyles from '../useStyles';
import Typography from '../../../components/Typography/Typography';
import Grid from '../../../components/Grid/Grid';
import PaperContainer from '../../../components/PaperContainer/PaperContainer';
import InputField from '../../../components/InputField/InputField';
import SelectField from '../../../components/SelectField/SelectField';
import SelectMultiField from '../../../components/SelectField/SelectMultiField';
import ReadonlyField from '../../../components/ReadonlyField/ReadonlyField';
import { cuttingSelection, moldSelection } from '../../../constants/configSelections';
import Button from '../../../components/Button/Button';
import Chip from '../../../components/Chip/Chip';
// import useAxios from '../../../hooks/useAxios';
// import { DialogContext } from '../../../contexts/DialogContext';
// import { dialogActions } from '../../../reducers/dialogReducer';
// import useCancelToken from '../../../hooks/useCancelToken';
import AutoComplete from '../../../components/SelectField/AutoComplete';

const ConfigInfo = React.forwardRef((props, ref) => {
  const {
    configInfo,
    setConfigInfo,
    configInfoErr,
    setConfigInfoErr,
    resetConfigInfoErr,
    handleConfigChange,
    onConfirmClick,
    isRStick,
    onScroll,
    modulerList,
    fetchModulerList,
    handleAutoConfigChange,
    disabled,
  } = props;
  // const { dialogDispatch } = useContext(DialogContext);
  // const [modulerList, setModulerList] = useState([]);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  // const { newCancelToken } = useCancelToken();
  const hasError = configInfoErr.mold || configInfoErr.cuttingNum || configInfoErr.moduler;

  const scrollObject = {
    position: 'sticky',
    top: '75px',
    backgroundColor: '#fff',
    opacity: '1',
    zIndex: '10',
    margin: '0 auto',
    alignItem: 'middle',
    padding: '1rem 1.5rem',
  };

  const handleDelete = (targetMold) => {
    setConfigInfo((prev) => ({
      ...prev,
      mold: prev.mold.filter((mold) => mold.name !== targetMold.name),
    }));
  };

  const handleMoldChange = (e) => {
    const targetMoldValue = moldSelection.filter((mold) => e.target.value.includes(mold.name));
    setConfigInfo((prev) => ({
      ...prev,
      mold: targetMoldValue,
    }));
  };

  const parseStringToFixed = (str) => parseFloat(parseFloat(str).toFixed(3));

  const validationConfig = () => {
    resetConfigInfoErr();
    const { heightMax, heightMin, weightMax, weightMin, cuttingNum, moduler, mold } = configInfo;
    const heightMaxFloat = parseStringToFixed(heightMax);
    const heightMinFloat = parseStringToFixed(heightMin);
    const weightMaxFloat = parseStringToFixed(weightMax);
    const weightMinFloat = parseStringToFixed(weightMin);
    if (heightMax.length === 0) {
      setConfigInfoErr((prev) => ({
        ...prev,
        heightMax: true,
      }));
      return false;
    }
    if (heightMin.length === 0) {
      setConfigInfoErr((prev) => ({
        ...prev,
        heightMin: true,
      }));
      return false;
    }
    if (heightMaxFloat < heightMinFloat) {
      setConfigInfoErr((prev) => ({
        ...prev,
        heightMax: true,
        heightMin: true,
      }));
      return false;
    }
    if (weightMax.length === 0) {
      setConfigInfoErr((prev) => ({
        ...prev,
        weightMax: true,
      }));
      return false;
    }
    if (weightMin.length === 0) {
      setConfigInfoErr((prev) => ({
        ...prev,
        weightMin: true,
      }));
      return false;
    }
    if (weightMaxFloat < weightMinFloat) {
      setConfigInfoErr((prev) => ({
        ...prev,
        weightMax: true,
        weightMin: true,
      }));
      return false;
    }
    if (cuttingNum === '0') {
      setConfigInfoErr((prev) => ({
        ...prev,
        cuttingNum: true,
      }));
      return false;
    }
    if (moduler === '') {
      setConfigInfoErr((prev) => ({
        ...prev,
        moduler: true,
      }));
      return false;
    }

    if (isRStick && moduler === '0') {
      setConfigInfoErr((prev) => ({
        ...prev,
        moduler: true,
      }));
      return false;
    }
    if (mold.length === 0) {
      setConfigInfoErr((prev) => ({
        ...prev,
        mold: true,
      }));
      return false;
    }
    setConfigInfo((prev) => ({
      ...prev,
      heightMax: heightMaxFloat,
      heightMin: heightMinFloat,
      weightMax: weightMaxFloat,
      weightMin: weightMinFloat,
    }));
    return true;
  };

  const onClick = () => {
    if (!validationConfig()) return;
    onConfirmClick();
  };

  useEffect(() => {
    fetchModulerList();
  }, []);
  return (
    <>
      <div ref={ref}>
        <PaperContainer className={commonClasses.container}>
          <Typography variant="h6">檢測設定</Typography>
          <Grid container className={commonClasses.content}>
            <Grid item xs={2} className={classes.config_column}>
              <InputField
                id="heightMax"
                label="壓高上限"
                value={configInfo.heightMax}
                onChange={handleConfigChange}
                placeholder="請輸入壓高上限"
                type="number"
                name="heightMax"
                error={configInfoErr.heightMax}
                errormsg="請輸入正確的壓高上限並不得小於下限"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2} className={classes.config_column}>
              <InputField
                id="heightMin"
                label="壓高下限"
                value={configInfo.heightMin}
                onChange={handleConfigChange}
                placeholder="請輸入壓高下限"
                type="number"
                name="heightMin"
                error={configInfoErr.heightMin}
                errormsg="請輸入正確的壓高下限並不得高於上限"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2} className={classes.config_column}>
              <InputField
                id="weightMax"
                label="重量上限"
                value={configInfo.weightMax}
                onChange={handleConfigChange}
                placeholder="請輸入重量上限"
                type="number"
                name="weightMax"
                error={configInfoErr.weightMax}
                errormsg="請輸入正確的重量上限並不得低於下限"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2} className={classes.config_column}>
              <InputField
                id="weightMin"
                label="重量下限"
                value={configInfo.weightMin}
                onChange={handleConfigChange}
                placeholder="請輸入重量下限"
                type="number"
                name="weightMin"
                error={configInfoErr.weightMin}
                errormsg="請輸入正確的重量下限並不得高於上限"
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid container className={commonClasses.content}>
            <Grid item xs={2} className={classes.config_column}>
              <SelectField
                id="cuttingNum"
                label="刀數"
                placeholder="請選擇刀數"
                value={configInfo.cuttingNum}
                selections={cuttingSelection}
                handleChange={handleConfigChange}
                error={configInfoErr.cuttingNum}
                errormsg="刀數不可不填！"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2} className={classes.config_column}>
              <AutoComplete
                options={modulerList}
                label="裝模人員"
                value={configInfo.moduler}
                onChange={handleAutoConfigChange}
                id="moduler"
                className={classes.auto_complete}
                placeholder="請輸入裝模人員"
                error={configInfoErr.moduler}
                errormsg="裝模人員不可不填！"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2} className={classes.config_column}>
              <SelectMultiField
                id="mold"
                label="模具"
                placeholder="請選擇模具"
                value={configInfo.mold.map((mold) => mold.name)}
                selections={moldSelection}
                handleChange={handleMoldChange}
                error={configInfoErr.mold}
                errormsg="模具不可不填！"
                selectedList={configInfo.mold.map((mold) => mold.name)}
                disabled={disabled}
              />
            </Grid>
            <Grid
              item
              xs={2}
              className={`${classes.config_column} ${classes.config_btn} ${
                hasError && classes.error
              }`}
            >
              <Button
                variant="outlined"
                color="primary"
                startIcon={<SearchRoundedIcon />}
                onClick={onClick}
                disabled={disabled}
              >
                確認
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <hr className={classes.divider} />
            <Grid item xs={9} className={classes.mold_container}>
              <Typography variant="body1" className={classes.mold_heading}>
                已選擇模具：
              </Typography>
              <div className={classes.mold_content}>
                {configInfo.mold.map((mold) => (
                  <Chip
                    key={mold.value}
                    label={mold.name}
                    onDelete={() => handleDelete(mold)}
                    color="primary"
                    variant="outlined"
                    className={classes.chip}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </PaperContainer>
      </div>
      {onScroll && (
        <PaperContainer
          className={`${commonClasses.container}`}
          // eslint-disable-next-line react/jsx-boolean-value
          positionFixed={true}
          scrollObject={scrollObject}
        >
          <Typography variant="h6">檢測設定</Typography>
          <Grid container className={classes.config_content}>
            <Grid item xs={2} className={classes.scroll_config_column}>
              <ReadonlyField label="壓高上限" value={configInfo.heightMax} name="heightMax" />
            </Grid>
            <Grid item xs={2} className={classes.scroll_config_column}>
              <ReadonlyField label="壓高下限" value={configInfo.heightMin} name="heightMin" />
            </Grid>
            <Grid item xs={2} className={classes.scroll_config_column}>
              <ReadonlyField label="重量上限" value={configInfo.weightMax} name="weightMax" />
            </Grid>
            <Grid item xs={2} className={classes.scroll_config_column}>
              <ReadonlyField label="重量下限" value={configInfo.weightMin} name="weightMin" />
            </Grid>
          </Grid>
        </PaperContainer>
      )}
    </>
  );
});

ConfigInfo.propTypes = {
  configInfo: PropTypes.object.isRequired,
  setConfigInfo: PropTypes.func.isRequired,
  configInfoErr: PropTypes.object.isRequired,
  setConfigInfoErr: PropTypes.func.isRequired,
  resetConfigInfoErr: PropTypes.func.isRequired,
  handleConfigChange: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  isRStick: PropTypes.bool.isRequired,
  onScroll: PropTypes.bool.isRequired,
  modulerList: PropTypes.array.isRequired,
  fetchModulerList: PropTypes.func.isRequired,
  handleAutoConfigChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ConfigInfo;
