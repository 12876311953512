/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
// import ImageSearchRoundedIcon from '@mui/icons-material/ImageSearchRounded';
import useCommonStyles from '../../useStyles';
import useStyles from './useStyles';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Button from '../../../../components/Button/Button';
import DatePicker from '../../../../components/DatePicker/DatePicker';

const UnfinishedFilter = ({
  unfinishedFilter,
  setUnfinishedFilter,
  unfinishedFilterErr,
  handleFetchUnfinishedData,
  dateErrMsg,
}) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const hasDateErr =
    unfinishedFilterErr.startDate || unfinishedFilterErr.endDate
      ? unfinishedFilterErr.startDate || unfinishedFilterErr.endDate
      : false;

  const handleStartDateChange = (date) => {
    setUnfinishedFilter((prev) => ({
      ...prev,
      startDate: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setUnfinishedFilter((prev) => ({
      ...prev,
      endDate: date,
    }));
  };

  return (
    <PaperContainer className={`${commonClasses.container} ${commonClasses.filter_container}`}>
      <Typography variant="h6">設定分析區間</Typography>
      <Grid className={commonClasses.content}>
        <Grid container item xs={12} className={classes.date_group}>
          <Grid item xs={3} className={classes.unfinished_date_grid}>
            <DatePicker
              id="unfinishedStartDate"
              label="查詢起始日期"
              value={unfinishedFilter.startDate}
              onChange={handleStartDateChange}
              hasDateErr={hasDateErr}
              errMsg={dateErrMsg}
              maxDate={new Date()}
              containerClass={classes.unfinished_date_picker}
            />
          </Grid>
          <Typography
            variant="h6"
            style={{ marginRight: 24 }}
            className={hasDateErr ? classes.error : ''}
          >
            ~
          </Typography>
          <Grid item xs={3} className={classes.unfinished_date_grid}>
            <DatePicker
              id="unfinishedEndDate"
              label="查詢日期"
              value={unfinishedFilter.endDate}
              onChange={handleEndDateChange}
              hasDateErr={unfinishedFilterErr.endDate ? unfinishedFilterErr.endDate : false}
              errMsg={dateErrMsg}
              maxDate={new Date()}
              containerClass={classes.unfinished_date_picker}
            />
          </Grid>
          <Grid
            item
            xs={5}
            container
            alignItems="flex-end"
            className={`${classes.unfinished_btn} ${hasDateErr ? classes.error : ''}`}
          >
            <Button
              variant="outlined"
              color="primary"
              customClasses={classes.filter_btn}
              startIcon={<SearchRoundedIcon />}
              onClick={handleFetchUnfinishedData}
            >
              查詢
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

UnfinishedFilter.propTypes = {
  unfinishedFilter: PropTypes.object.isRequired,
  setUnfinishedFilter: PropTypes.func.isRequired,
  unfinishedFilterErr: PropTypes.object.isRequired,
  handleFetchUnfinishedData: PropTypes.func.isRequired,
  dateErrMsg: PropTypes.string.isRequired,
};

export default UnfinishedFilter;
