import React from 'react';
import FormingInfo from './FormingType/FormingInfo';
import { stationType, formingManufactureType } from '../../constants/enums';

const TransUpdateAligning = () => (
  <FormingInfo
    pageTitle="更新移轉單（排列）"
    pageLabel="排列"
    formingType={formingManufactureType.ALIGNING}
    stationType={stationType.ALIGNING}
  />
);

export default TransUpdateAligning;
