/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ConfigItemRow from '../ConfigItemRow';
import { tabDirectionType } from '../../../../constants/enums';
import useStyles from './useStyles';
import useCommonStyles from '../../useStyles';
import Typography from '../../../../components/Typography/Typography';
import Grid from '../../../../components/Grid/Grid';
import Snackbar from '../../../../components/Snackbar/Snackbar';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import Button from '../../../../components/Button/Button';
import InputField from '../../../../components/InputField/InputField';
import Radio from '../../../../components/Radio/Radio';
import CircularProgress from '../../../../components/CircularProgress/CircularProgress';

const QCSinteringSize = ({
  path,
  showTable,
  isLoading,
  foldingRow,
  toggleFoldingRow,
  warningMsg,
  specConfig,
  renderRow,
  transDefectTypes,
  setRenderRow,
  resultNote,
  handleResultNoteChange,
  handleFormSubmit,
  rowLength,
  handleDelete,
  disabled,
  otherIsDone,
}) => {
  const tableEl = useRef(null);
  const scrollTarget = useRef(null);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const [onScroll, setOnScroll] = useState(false);
  const scrollObject = {
    position: 'sticky',
    top: '75px',
    backgroundColor: '#fff',
    opacity: '1',
    zIndex: '10',
    margin: '0 auto',
    alignItem: 'middle',
    padding: '.5em 1.5rem 1rem 1.5rem',
  };

  const [actionBasis, setActionBasis] = useState(tabDirectionType.BY_ITEM);

  const onActionBasisChange = (e) => {
    setActionBasis(e.target.value);
  };
  const handleFocusChange = (e) => {
    const minTabIndex = 1;
    const currentTabIndex = e.target.tabIndex;
    if (e.key === 'Tab' && e.shiftKey) {
      e.preventDefault();
      let prevTabIndex = 0;
      if (currentTabIndex === minTabIndex) {
        prevTabIndex = currentTabIndex;
      } else {
        prevTabIndex = currentTabIndex - 1;
      }
      const prevEl = document.querySelectorAll(`[tabindex = "${prevTabIndex}"]`)[1];
      if (!prevEl) return;
      prevEl.focus();
      return;
    }
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      const nextTabIndex = currentTabIndex + 1;
      const nextEl = document.querySelectorAll(`[tabindex="${nextTabIndex}"]`)[1];
      if (!nextEl) return;
      nextEl.focus();
    }
  };

  useEffect(() => {
    if (showTable) {
      document.addEventListener('scroll', () => {
        if (!scrollTarget.current) return;
        const position = scrollTarget.current.getBoundingClientRect();
        if (window.pageYOffset >= position.bottom + position.height * 2) {
          setOnScroll(true);
          return;
        }
        setOnScroll(false);
      });
    }
    if (showTable && tableEl.current) {
      tableEl.current.addEventListener('keydown', handleFocusChange);
    }

    return () => {
      if (!tableEl.current) return;
      tableEl.current.removeEventListener('keydown', handleFocusChange);
    };
  }, [showTable]);

  return (
    <>
      {!showTable && isLoading && <CircularProgress mt={7.5} />}
      {showTable && onScroll && (
        // eslint-disable-next-line react/jsx-boolean-value
        <PaperContainer positionFixed={true} scrollObject={scrollObject}>
          <Grid xs={12} container className={classes.table} ref={tableEl}>
            <ul className={commonClasses.table_header}>
              <li
                className={`${commonClasses.table_column} ${commonClasses.onScroll_table_column}`}
              >
                <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`}>
                  <Typography variant="subtitle2" />
                </div>
              </li>
              <li className={commonClasses.table_column}>
                <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`}>
                  <Typography variant="subtitle2" />
                </div>
              </li>
              {specConfig.map((item) => (
                <li
                  className={`${commonClasses.table_column} ${classes.table_column} ${classes.table_header}`}
                  key={item.specId}
                >
                  <div className={`${commonClasses.table_cell} ${classes.table_cell}`}>
                    <Typography variant="subtitle2">{item.specItem}</Typography>
                    <div className={classes.spec_group}>
                      <div className={classes.target_group}>
                        <Typography variant="h5" className={classes.target_value}>
                          {item.specTarget}
                        </Typography>
                        <div className={classes.limit}>
                          <span className={`${classes.upper} ${classes.target}`}>
                            +{item.specUpper}
                          </span>
                          <span className={`${classes.lower} ${classes.target}`}>
                            {item.specLower}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {item.specBias.length > 0 && (
                    <Typography variant="body2" className={classes.bias}>
                      量測偏差：{' '}
                      {parseInt(item.specBias, 10) > 0 ? `+${item.specBias}` : item.specBias}
                    </Typography>
                  )}
                </li>
              ))}
              <li className={commonClasses.table_column}>
                <div className={`${commonClasses.table_cell} ${classes.defect_type_cell}`}>
                  <Typography variant="subtitle2" />
                </div>
              </li>
            </ul>
          </Grid>
        </PaperContainer>
      )}
      {showTable && (
        <PaperContainer className={commonClasses.container}>
          <Typography variant="h6">填寫量測數據</Typography>
          <Grid container className={commonClasses.content} direction="column">
            <Grid xs={12} container justifyContent="space-between">
              <Radio
                label="依量測item填寫"
                name="byItem"
                value={tabDirectionType.BY_ITEM}
                selectedValue={actionBasis}
                onChange={onActionBasisChange}
              />
              <Radio
                label="依量測sample填寫"
                name="bySample"
                value={tabDirectionType.BY_SAMPLE}
                selectedValue={actionBasis}
                onChange={onActionBasisChange}
              />
              {foldingRow && (
                <Button
                  variant="outlined"
                  // color="grey"
                  customClasses={classes.unfold_btn}
                  rounded
                  endIcon={<ExpandMoreRoundedIcon />}
                  onClick={toggleFoldingRow}
                >
                  全部展開
                </Button>
              )}
              {!foldingRow && (
                <Button
                  variant="outlined"
                  // color="grey"
                  customClasses={`${classes.unfold_btn} active`}
                  rounded
                  endIcon={<ExpandLessRoundedIcon />}
                  onClick={toggleFoldingRow}
                >
                  全部收闔
                </Button>
              )}
            </Grid>
            <Snackbar
              msg={warningMsg}
              open={warningMsg.length > 0}
              type="error"
              className={commonClasses.error_snackbar}
            />
            <Grid xs={12} container className={classes.table} ref={tableEl}>
              <ul className={commonClasses.table_header} ref={scrollTarget}>
                <li className={commonClasses.table_column}>
                  <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`}>
                    <Typography variant="subtitle2">動作</Typography>
                  </div>
                </li>
                <li className={commonClasses.table_column}>
                  <div className={`${commonClasses.table_cell} ${classes.table_action_cell}`}>
                    <Typography variant="subtitle2">序號</Typography>
                  </div>
                </li>
                {specConfig.map((item) => (
                  <li
                    className={`${commonClasses.table_column} ${classes.table_column} ${classes.table_header}`}
                    key={item.specId}
                  >
                    <div className={`${commonClasses.table_cell} ${classes.table_cell}`}>
                      <Typography variant="subtitle2">{item.specItem}</Typography>
                      <div className={classes.spec_group}>
                        <div className={classes.target_group}>
                          <Typography variant="h5" className={classes.target_value}>
                            {item.specTarget}
                          </Typography>
                          <Typography variant="h5" className={`${classes.upper} ${classes.target}`}>
                            +{item.specUpper}
                          </Typography>
                          <Typography variant="h5" className={`${classes.lower} ${classes.target}`}>
                            {item.specLower}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    {item.specBias.length > 0 && (
                      <Typography variant="body2" className={classes.bias}>
                        量測偏差：{' '}
                        {parseInt(item.specBias, 10) > 0 ? `+${item.specBias}` : item.specBias}
                      </Typography>
                    )}
                  </li>
                ))}
                <li className={commonClasses.table_column}>
                  <div className={`${commonClasses.table_cell} ${classes.defect_type_cell}`}>
                    <Typography variant="subtitle2">Defect Type</Typography>
                  </div>
                </li>
              </ul>
              {renderRow.map((item, index) => (
                <ConfigItemRow
                  path={path}
                  data={item}
                  handleRowChange={setRenderRow}
                  foldingAllRows={foldingRow}
                  specConfig={specConfig}
                  rowId={item.rowId}
                  key={item.rowId}
                  index={index}
                  initialRowState={{ specItems: item.specItems, defectType: item.defectType }}
                  tabDirection={actionBasis}
                  rowLength={rowLength}
                  otherIsDone={otherIsDone}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container alignItems="center" wrap="nowrap" className={classes.table_footer}>
            <InputField
              id="note"
              name="note"
              type="text"
              label="備註"
              placeholder="請輸入備註內容"
              value={resultNote}
              onChange={handleResultNoteChange}
              disabled={otherIsDone}
            />
            {/* 20230103 Shane - Delete QC record functionality will re-implement in the next phase */}
            {renderRow[0].specItems[0].value !== '' && (
              <Button
                variant="contained"
                color="error"
                rounded
                customClasses={classes.submit_btn}
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
                disabled={disabled}
              >
                刪除
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              rounded
              customClasses={classes.submit_btn}
              startIcon={<CheckRoundedIcon />}
              onClick={handleFormSubmit}
              disabled={otherIsDone}
            >
              送出
            </Button>
          </Grid>
        </PaperContainer>
      )}
    </>
  );
};

QCSinteringSize.propTypes = {
  path: PropTypes.string.isRequired,
  showTable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  foldingRow: PropTypes.bool.isRequired,
  toggleFoldingRow: PropTypes.func.isRequired,
  warningMsg: PropTypes.string.isRequired,
  specConfig: PropTypes.array.isRequired,
  renderRow: PropTypes.array.isRequired,
  transDefectTypes: PropTypes.array.isRequired,
  setRenderRow: PropTypes.func.isRequired,
  resultNote: PropTypes.string.isRequired,
  handleResultNoteChange: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  rowLength: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  otherIsDone: PropTypes.bool,
};

export default QCSinteringSize;
