/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Chart } from 'chart.js';
import useCommonStyles from '../../useStyles';
import useStyles from './useStyles';
import Typography from '../../../../components/Typography/Typography';
import PaperContainer from '../../../../components/PaperContainer/PaperContainer';
import useAxios from '../../../../hooks/useAxios';
import useCancelToken from '../../../../hooks/useCancelToken';
import { DialogContext } from '../../../../contexts/DialogContext';
import { dialogActions } from '../../../../reducers/dialogReducer';

const tableHeaderColumn = ['No', '機台名稱', '被檢測日期', '被檢測時間'];

const UnfinishedDataChart = ({
  currentFilterData,
  prodData,
  machineData,
  testData,
  toUpdateProd,
  toUpdateMachine,
  toUpdateTest,
  setToUpdateProd,
  setToUpdateMachine,
  setToUpdateTest,
  toRenderProd,
  toRenderMachine,
  toRenderTest,
  setToRenderProd,
  setToRenderMachine,
  setToRenderTest,
  emptyProdResult,
  emptyMachineResult,
  emptyTestResult,
}) => {
  const { dialogDispatch } = useContext(DialogContext);
  const [showMachineTestTimeList, setShowMachineTestTimeList] = useState(false);
  const [machineTestTimeList, setMachineTestTimeList] = useState([]);
  const prodBarChartEl = useRef(null);
  const prodBarChart = useRef(null);
  const machineBarChartEl = useRef(null);
  const machineBarChart = useRef(null);
  const testBarChartEl = useRef(null);
  const testBarChart = useRef(null);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { newCancelToken } = useCancelToken();

  const percentageTicks = {
    ticks: {
      sampleSize: 10,
      callback: (value) => `${value}%`,
    },
  };

  const percentageTooltip = {
    callbacks: {
      label: (context) => `${context.raw}%`,
    },
  };

  const drawBarChart = (
    ctx,
    label,
    labels,
    dataset,
    tooltip = {},
    yAxisConfig = {},
    onClick = null
  ) =>
    new Chart(ctx, {
      data: {
        labels,
        datasets: [
          {
            label,
            type: 'bar',
            data: dataset,
            backgroundColor: ['rgba(63, 81, 181, .2)'],
            tooltip,
          },
        ],
      },
      options: {
        onClick,
        aspectRatio: 2.5,
        scales: {
          x: {
            axis: 'x',
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            ...yAxisConfig,
          },
        },
      },
    });

  const drawProdBarChart = () => {
    if (prodBarChart.current) {
      prodBarChart.current.destroy();
    }
    prodBarChartEl.current = document.getElementById('productBarChart');
    const ctx = prodBarChartEl.current.getContext('2d');
    const labels = Array.from(prodData, (item) => item.prodName);
    const dataset = Array.from(prodData, (item) => parseFloat(item.detectPersent * 100).toFixed(0));
    const label = '累積比例';
    prodBarChart.current = drawBarChart(
      ctx,
      label,
      labels,
      dataset,
      percentageTooltip,
      percentageTicks
    );
  };

  const drawMachineBarChart = () => {
    if (machineBarChart.current) {
      machineBarChart.current.destroy();
    }
    machineBarChartEl.current = document.getElementById('machineBarChart');
    const ctx = machineBarChartEl.current.getContext('2d');
    const labels = Array.from(machineData, (item) => item.machineName);
    const dataset = Array.from(machineData, (item) =>
      parseFloat(item.detectPersent * 100).toFixed(0)
    );
    const label = '累積比例';
    machineBarChart.current = drawBarChart(
      ctx,
      label,
      labels,
      dataset,
      percentageTooltip,
      percentageTicks
    );
  };

  const handleFetchMachineTestTime = async (targetMachine) => {
    const data = { ...currentFilterData, machineId: targetMachine.machineId };
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkHalfMachineTime',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { half } = result;
      setMachineTestTimeList(half);
      setShowMachineTestTimeList(true);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '無法取得機台相關資訊',
          message:
            noPrivilegeMsg || `${result}：目前無法取得機台相關資訊，請稍後再試，或請聯絡網管人員！`,
          singleBtn: true,
        },
      });
    }
  };

  const handleTestBarClick = (e) => {
    const activeEl = testBarChart.current.getActiveElements(e);
    if (activeEl[0] && activeEl[0].datasetIndex !== 0) return;
    const targetMachine = testData[activeEl[0].index];
    setShowMachineTestTimeList(false);
    handleFetchMachineTestTime(targetMachine);
  };

  const drawTestBarChart = () => {
    if (testBarChart.current) {
      testBarChart.current.destory();
    }
    testBarChartEl.current = document.getElementById('testBarChart');
    const ctx = testBarChartEl.current.getContext('2d');
    const labels = Array.from(testData, (item) => item.machineName);
    const dataset = Array.from(testData, (item) => item.checkCount);
    const label = '被檢測次數';
    const yAxisConfig = {
      suggestedMax: Math.max(...testData.map((item) => item.checkCount)) + 1,
      ticks: {
        stepSize: 1,
      },
    };
    testBarChart.current = drawBarChart(
      ctx,
      label,
      labels,
      dataset,
      {},
      yAxisConfig,
      handleTestBarClick
    );
  };

  useEffect(() => {
    if (toUpdateProd || toUpdateMachine || toUpdateTest) {
      setShowMachineTestTimeList(false);
      setMachineTestTimeList([]);
    }
    if (toUpdateProd) {
      prodBarChart.current.data.labels = Array.from(prodData, (item) => item.prodName);
      prodBarChart.current.data.datasets[0].data = Array.from(prodData, (item) =>
        parseFloat(item.detectPersent * 100).toFixed(0)
      );
      prodBarChart.current.update();
      setToUpdateProd(false);
      return;
    }
    if (toUpdateMachine) {
      machineBarChart.current.data.labels = Array.from(machineData, (item) => item.machineName);
      machineBarChart.current.data.datasets[0].data = Array.from(machineData, (item) =>
        parseFloat(item.detectPersent * 100).toFixed(0)
      );
      machineBarChart.current.update();
      setToUpdateMachine(false);
      return;
    }
    if (toUpdateTest) {
      testBarChart.current.data.labels = Array.from(testData, (item) => item.machineName);
      testBarChart.current.data.datasets[0].data = Array.from(testData, (item) => item.checkCount);
      testBarChart.current.options.onClick = handleTestBarClick; // 需重新指派資料才會是最新的
      testBarChart.current.update();
      setToUpdateTest(false);
    }
  }, [toUpdateProd, toUpdateMachine, toUpdateTest]);

  useEffect(() => {
    if (toRenderProd || toRenderMachine || toRenderTest) {
      setShowMachineTestTimeList(false);
      setMachineTestTimeList([]);
    }
    if (toRenderProd) {
      if (prodData.length === 0) {
        if (prodBarChart.current) {
          prodBarChart.current.destroy();
        }
        setToRenderProd(false);
        return;
      }
      drawProdBarChart();
      setToRenderProd(false);
    }
    if (toRenderMachine) {
      if (machineData.length === 0) {
        if (machineBarChart.current) {
          machineBarChart.current.destroy();
        }
        setToRenderMachine(false);
        return;
      }
      drawMachineBarChart();
      setToRenderMachine(false);
    }
    if (toRenderTest) {
      if (testData.length === 0) {
        if (testBarChart.current) {
          testBarChart.current.destroy();
        }
        setToRenderTest(false);
        return;
      }
      drawTestBarChart();
      setToRenderTest(false);
    }
  }, [toRenderProd, toRenderMachine, toRenderTest]);

  useEffect(
    () => () => {
      if (prodBarChart.current) {
        prodBarChart.current.destroy();
      }
      if (machineBarChart.current) {
        machineBarChart.current.destroy();
      }
      if (testBarChart.current) {
        testBarChart.current.destroy();
      }
    },
    []
  );

  return (
    <>
      <PaperContainer className={commonClasses.container}>
        <Typography variant="h6">個別產品累積不良比例</Typography>
        <div className={classes.bar_chart_container}>
          {emptyProdResult.length > 0 && (
            <Typography variant="subtitle1" style={{ margin: 'auto', textAlign: 'center' }}>
              {emptyProdResult}
            </Typography>
          )}
          {prodData.length > 0 && <canvas id="productBarChart" width="600" height="400" />}
          {prodData.length === 0 && <canvas id="productBarChart" width="0" height="0" />}
        </div>
      </PaperContainer>
      <PaperContainer className={commonClasses.container}>
        <Typography variant="h6">個別機台累積不良比例</Typography>
        <div className={classes.bar_chart_container}>
          {emptyMachineResult.length > 0 && (
            <Typography variant="subtitle1" style={{ margin: 'auto', textAlign: 'center' }}>
              {emptyMachineResult}
            </Typography>
          )}
          {machineData.length > 0 && <canvas id="machineBarChart" width="600" height="400" />}
          {machineData.length === 0 && <canvas id="machineBarChart" width="0" height="0" />}
        </div>
      </PaperContainer>
      <PaperContainer className={commonClasses.container}>
        <Typography variant="h6">個別機台累積被檢測次數</Typography>
        <div className={classes.bar_chart_container}>
          {emptyTestResult.length > 0 && (
            <Typography variant="subtitle1" style={{ margin: 'auto', textAlign: 'center' }}>
              {emptyTestResult}
            </Typography>
          )}
          {testData.length > 0 && <canvas id="testBarChart" width="600" height="400" />}
          {testData.length === 0 && <canvas id="testBarChart" width="0" height="0" />}
        </div>
      </PaperContainer>
      {showMachineTestTimeList && (
        <PaperContainer className={commonClasses.container}>
          <Typography variant="h6">個別機台被檢測時間</Typography>
          <div className={classes.machine_table}>
            <div className={classes.machine_table_header}>
              {tableHeaderColumn.map((col) => (
                <div className={classes.machine_table_column} key={col}>
                  <Typography variant="subtitle1">{col}</Typography>
                </div>
              ))}
            </div>
            {machineTestTimeList.map((row, idx) => (
              <div className={classes.machine_table_row}>
                <div className={classes.machine_table_column}>
                  <Typography variant="body1">{idx + 1}</Typography>
                </div>
                <div className={classes.machine_table_column}>
                  <Typography variant="body1">{row.machineName}</Typography>
                </div>
                <div className={classes.machine_table_column}>
                  <Typography variant="body1">
                    {format(new Date(row.modifyDate), 'yyyy-MM-dd')}
                  </Typography>
                </div>
                <div className={classes.machine_table_column}>
                  <Typography variant="body1">
                    {format(new Date(row.modifyDate), 'HH:mm:ss')}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </PaperContainer>
      )}
    </>
  );
};

UnfinishedDataChart.propTypes = {
  currentFilterData: PropTypes.object.isRequired,
  prodData: PropTypes.array.isRequired,
  machineData: PropTypes.array.isRequired,
  testData: PropTypes.array.isRequired,
  toUpdateProd: PropTypes.bool.isRequired,
  toUpdateMachine: PropTypes.bool.isRequired,
  toUpdateTest: PropTypes.bool.isRequired,
  setToUpdateProd: PropTypes.func.isRequired,
  setToUpdateMachine: PropTypes.func.isRequired,
  setToUpdateTest: PropTypes.func.isRequired,
  toRenderProd: PropTypes.bool.isRequired,
  toRenderMachine: PropTypes.bool.isRequired,
  toRenderTest: PropTypes.bool.isRequired,
  setToRenderProd: PropTypes.func.isRequired,
  setToRenderMachine: PropTypes.func.isRequired,
  setToRenderTest: PropTypes.func.isRequired,
  emptyProdResult: PropTypes.string.isRequired,
  emptyMachineResult: PropTypes.string.isRequired,
  emptyTestResult: PropTypes.string.isRequired,
};

export default React.memo(UnfinishedDataChart);
