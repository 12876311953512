import { manufactureType, stationType } from '../constants/enums';

const useStationType = (type) => {
  switch (type) {
    case manufactureType.FORMING:
      return Number(stationType.FORMING);
    case manufactureType.DRILLING:
      return Number(stationType.DRILLING);
    case manufactureType.ALIGNING:
      return Number(stationType.ALIGNING);
    case 'SINTERING':
      return Number(stationType.SINTERING);
    case 'GRINDING':
      return Number(stationType.GRINDING);
    case 'TESTING':
      return Number(stationType.TESTING);
    case 'PACKAGING':
      return Number(stationType.PACKAGING);
    default:
      return Number(stationType.FORMING);
  }
};

export default useStationType;
