/* eslint-disable react/forbid-prop-types */
import React from 'react';
// import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Typography from '../../../components/Typography/Typography';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Button from '../../../components/Button/Button';
import useStyles from './useStyles';

const ResultRow = ({ row, handleRowCheck, handleDetailClick }) => {
  const { classes } = useStyles();
  const handleSearchQCNG = (transId) => {
    localStorage.setItem('NGTransId', transId);
    window.location.hash = '#/qc-sintering-ng';
  };
  return (
    // <div container item xs={12} className={classes.table_row}>
    <div className={classes.table_row}>
      <div className={`${classes.table_column} ${classes.table_column_selection}`}>
        <Checkbox
          name={row.rowId}
          checked={row.isChecked}
          handleChange={() => handleRowCheck(row.rowId)}
          customClasses={classes.table_selection}
        />
      </div>
      <div className={`${classes.table_column} ${classes.transId_cell}`}>
        <Typography variant="body1" className={classes.table_cell}>
          {row.transId}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.customer_cell}`}>
        <Typography variant="body1" className={classes.table_cell}>
          {row.customer}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.update_product_cell}`}>
        <Typography variant="body1" className={classes.product_cell_font}>
          {row.product}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.measure_cell}`}>
        <Typography variant="body1" className={classes.table_cell}>
          {row.measureStyle}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.machine_cell}`}>
        <Typography variant="body1" className={classes.table_cell}>
          {row.machine}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.user_cell}`}>
        <Typography variant="body1" className={classes.table_cell} sx={{ wordBreak: 'break-all' }}>
          {row.measureUser}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.update_cell}`}>
        <Typography variant="body1" className={classes.table_cell}>
          {row.updateTime}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.update_NG_cell}`}>
        <Typography
          variant="body1"
          className={`${classes.table_cell} ${row.isNGHistoryData && classes.hyper_link}`}
          onClick={row.isNGHistoryData ? () => handleSearchQCNG(row.transId) : null}
        >
          {row.isNGHistoryData ? '是' : '否'}
        </Typography>
      </div>
      <div className={`${classes.table_column} ${classes.table_column_action}`}>
        <Button
          variant="outlined"
          color="primary"
          rounded
          customClasses={classes.table_action_cell}
          onClick={() => handleDetailClick(row)}
        >
          <SearchRoundedIcon fontSize="small" />
        </Button>
      </div>
    </div>
  );
};

ResultRow.propTypes = {
  row: PropTypes.object.isRequired,
  handleRowCheck: PropTypes.func.isRequired,
  handleDetailClick: PropTypes.func.isRequired,
};

export default ResultRow;
