/* eslint-disable import/prefer-default-export */
export const downloadFile = (url) => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.style.height = 0;
  iframe.src = url;
  document.body.appendChild(iframe);
  const timer = setTimeout(() => {
    iframe.remove();
    clearTimeout(timer);
  }, 1000);
};
