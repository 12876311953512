import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { format } from 'date-fns';
import { alpha } from '@mui/system';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AddIcon from '@mui/icons-material/Add';
import Grid from '../../components/Grid/Grid';
import PageTitle from '../../components/PageTitle/PageTitle';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import InputField from '../../components/InputField/InputField';
import Toolbar from '../../components/Toolbar/Toolbar';
import NewStockAction from './NewStockAction';
import Divider from '../../components/Divider/Divider';
import ConfigTable from '../../components/ConfigTable/ConfigTable';
import useCancelToken from '../../hooks/useCancelToken';
import useAxios from '../../hooks/useAxios';
import Snackbar from '../../components/Snackbar/Snackbar';
import { dialogActions } from '../../reducers/dialogReducer';
import { DialogContext } from '../../contexts/DialogContext';
import FilterInfo from './FilterInfo';
import { manufactureType } from '../../constants/enums';
import useInventoryStatus from '../../hooks/useInventoryStatus';

const tableHeadCells = [
  { id: 'stockDate', numeric: false, disablePadding: true, label: '盤點日期' },
  { id: 'preStockQty', numeric: false, disablePadding: true, label: '盤前數量' },
  { id: 'stockQty', numeric: false, disablePadding: true, label: '盤點數量' },
  { id: 'category', numeric: false, disablePadding: true, label: '類別' },
  { id: 'phase', numeric: false, disablePadding: true, label: '盈虧數量' },
];

const productInfoInit = {
  elNo: '',
  materialName: '',
  elShape: '',
  elSize: '',
  formingType: '',
};

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(1.5),
  },
  content: {
    marginTop: theme.spacing(4),
  },
  error: {
    marginBottom: theme.spacing(2.5),
  },
  confirm: {
    marginTop: theme.spacing(3),
  },
  dashed_divider: {
    margin: '24px -26px',
    background: `repeating-linear-gradient(90deg,${alpha(theme.palette.common.black, 0.2)},${alpha(
      theme.palette.common.black,
      0.2
    )} 6px,transparent 6px,transparent 12px)`,
    border: 'none',
    height: 1,
  },
  toolbar: {
    '&.MuiToolbar-root': {
      padding: 0,
    },
  },
  snackbar: {
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: '100%',
  },
}));

const FormingStock = () => {
  const { classes } = useStyles();
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();
  const [productElNo, setProductElNo] = useState('');
  const [productElNoErr, setProductErr] = useState(false);
  const [productInfo, setProductInfo] = useState(productInfoInit);
  const [isShowRecordList, setIsShowRecordList] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tableContentList, setTableContentList] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [statusMsg, setStatusMsg] = useState('');
  const [showStatus, setShowStatus] = useState(false);
  const [statusType, setStatusType] = useState('success');
  const snackbarTimer = useRef(null);

  const handleChange = (e) => {
    setProductElNo(e.target.value);
  };

  const getStockRecordList = async (data) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/findStockRecord',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { stockRecord, editableStockId } = result;
      setIsShowRecordList(true);
      setTableContentList(
        stockRecord.map((item) => ({
          rowId: item.stockId,
          isChecked: false,
          isEditing: false,
          canDelete: editableStockId === item.stockId,
          canEdit: editableStockId === item.stockId,
          columns: [
            {
              name: 'stockDate',
              label: '盤點日期',
              placeholder: '請輸入盤點日期',
              value: 1,
              displayValue: format(new Date(item.modifyDate), 'yyyy/MM/dd'),
              visible: true,
              type: 'date',
              isEditable: false,
              isRequired: true,
              error: false,
            },
            {
              name: 'preStockQty',
              label: '盤前數量',
              value: item.preStock,
              displayValue: item.preStock,
              visible: true,
              isEditable: false,
              isRequired: true,
              error: false,
            },
            {
              name: 'stockQty',
              label: '盤點數量',
              placeholder: '請輸入盤點數量',
              value: item.stock,
              displayValue: item.stock,
              visible: true,
              type: 'text',
              isEditable: true,
              isRequired: true,
              error: false,
            },
            {
              name: 'status',
              label: '類別',
              value: useInventoryStatus(item.status).name,
              displayValue: useInventoryStatus(item.status).name,
              visible: true,
              isEditable: false,
              isRequired: true,
              error: false,
            },
            {
              name: 'inventoryQty',
              label: '盈虧數量',
              value: item.prefixLossQty,
              displayValue: item.prefixLossQty,
              visible: true,
              isEditable: false,
              isRequired: true,
              error: false,
            },
          ],
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '取得損耗盤點紀錄失敗',
          message: noPrivilegeMsg || '取得損耗盤點紀錄失敗，請稍後再試，或聯絡網管人員！',
        },
      });
    }
  };

  const validationWorkNo = () => {
    setProductErr(false);
    if (productElNo.length === 0) {
      setProductErr(true);
      return false;
    }
    return true;
  };

  const handleFetch = async () => {
    if (!validationWorkNo()) return;
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/findProdInfo',
      {
        elNo: productElNo.trim(),
      },
      true,
      cancelToken
    );
    if (status) {
      const { prodInfo } = result;
      setProductInfo({
        name: prodInfo.prodName,
        id: prodInfo.elNo,
        elNo: prodInfo.elNo,
        materialName: prodInfo.materialName,
        elShape: prodInfo.prodShape,
        elSize: prodInfo.elSize,
        formingType: prodInfo.manufactureType,
        // 盤前數量
        preStockQty: prodInfo.preStockQty,
      });
      setIsShowRecordList(false);

      if (prodInfo.manufactureType === manufactureType.FORMING) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '該工令壓型加工為無',
            message: '該工令壓型加工為無，不得做盤點動作！',
          },
        });
        return;
      }
      if (prodInfo.manufactureType === manufactureType.NONE) {
        dialogDispatch({
          type: dialogActions.OPEN,
          config: {
            title: '尚未設定該工令壓型加工類別',
            message: '該工令壓型加工尚未設定，不得做盤點動作！',
          },
        });
        return;
      }
      getStockRecordList({
        prodName: prodInfo.prodName,
        elNo: prodInfo.elNo,
      });
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '取得產品相關資訊失敗',
          message: noPrivilegeMsg || '取得產品相關資訊失敗，請稍後再試，或聯絡網管人員！',
        },
      });
    }
  };

  const openSnackbar = () => {
    setShowStatus(true);
    clearTimeout(snackbarTimer.current);
    snackbarTimer.current = setTimeout(() => {
      setShowStatus(false);
      clearTimeout(snackbarTimer.current);
    }, 3000);
  };

  const settingStock = async ({ data, type, message, errorMessage }) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/stockRecordSetting',
      data,
      true,
      cancelToken
    );

    if (status) {
      setStatusMsg(message);
      setStatusType('success');
      getStockRecordList({
        prodName: productInfo.name,
        elNo: productInfo.elNo,
      });
      handleFetch();
    } else {
      setStatusType('error');
      setStatusMsg(noPrivilegeMsg || errorMessage);
    }
    openSnackbar();

    if (type === 'add') {
      setIsAdding(false);
    }

    if (type === 'update') {
      setEditedData({});
    }
  };

  const onRowEditClick = (targetRowId, isOpenEdit) => {
    setTableContentList((prev) =>
      prev.map((config) =>
        config.rowId === targetRowId
          ? { ...config, columns: [...config.columns], isEditing: isOpenEdit, isChecked: false }
          : config
      )
    );
    if (isOpenEdit) {
      const targetRow = tableContentList.find((config) => config.rowId === targetRowId);
      setEditedData({
        stockId: targetRow.rowId,
        stockQty: targetRow.columns[2].value,
      });
    } else {
      setEditedData({});
    }
  };

  const handleRowEditing = (e) => {
    setEditedData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRowDateEditing = (date) => {
    setEditedData((prev) => ({
      ...prev,
      stockDate: date,
    }));
  };

  const handleEditData = () => {
    const data = {
      stockId: editedData.stockId,
      elNo: productInfo.elNo,
      prodName: productInfo.name,
      stock: editedData.stockQty,
      isEnable: 1,
    };
    settingStock({
      data,
      type: 'update',
      message: '已成功更新盤點紀錄！',
      errorMessage: '更新盤點紀錄失敗，請稍後再試，或通知網管人員！',
    });
  };

  const handleRowError = (targetRow, validation) => {
    setTableContentList((prev) =>
      prev.map((config) =>
        config.rowId === targetRow.rowId
          ? {
              ...config,
              columns: targetRow.columns.map((col) => ({ ...col, error: !validation[col.name] })),
            }
          : config
      )
    );
  };

  // 刪除單筆
  const handleRowDelete = async (stock) => {
    const data = {
      stockId: stock.rowId,
      elNo: productInfo.elNo,
      prodName: productInfo.name,
      stock: stock.columns[2].displayValue,
      isEnable: 0,
    };

    settingStock({
      data,
      type: 'delete',
      message: `已成功刪除「 盤點日期：${stock.columns[0].displayValue} ， 盤點數量：${stock.columns[1].displayValue} 」！`,
      errorMessage: `刪除「 盤點日期：${stock.columns[0].displayValue} ， 盤點數量：${stock.columns[1].displayValue} 」失敗，請稍後再試！`,
    });
  };

  useEffect(() => {
    const hasEditingRow = tableContentList.find((item) => item.isEditing);
    if (hasEditingRow) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [tableContentList]);

  return (
    <Grid container>
      <PageTitle title="鑽孔排列盤點" />
      <PaperContainer className={classes.container}>
        <Typography variant="h6">工令盤點鑽孔排列盤點數量</Typography>
        <Grid container spacing={2} className={classes.content} alignItems="flex-end">
          <Grid item xs={5}>
            <InputField
              type="text"
              id="productElNo"
              name="productElNo"
              label="產品料號"
              placeholder="請輸入料號"
              value={productElNo}
              errormsg="請輸入料號"
              onChange={handleChange}
              error={productElNoErr}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SearchRoundedIcon />}
              onClick={handleFetch}
              customClasses={productElNoErr && classes.error}
            >
              查詢
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.dashed_divider} />
        <FilterInfo productInfo={productInfo} classes={classes} />
      </PaperContainer>
      {isShowRecordList && (
        <PaperContainer className={classes.container}>
          <Typography variant="h6">鑽孔盤點紀錄</Typography>
          {isAdding ? (
            <NewStockAction
              productInfo={productInfo}
              formingType={productInfo.formingType}
              onAdd={settingStock}
              onCloseAdding={() => setIsAdding(false)}
            />
          ) : (
            <Toolbar className={classes.toolbar}>
              <Grid
                container
                style={{ marginTop: 25, marginBottom: 20, width: '100%' }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    rounded
                    startIcon={<AddIcon />}
                    onClick={() => setIsAdding(true)}
                  >
                    新增一項
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          )}
          <Snackbar
            msg={statusMsg}
            type={statusType}
            open={showStatus}
            className={classes.snackbar}
          />
          {tableContentList.length <= 0 ? (
            <div style={{ textAlign: 'center' }}>
              <Typography variant="subtitle1">目前沒有資料</Typography>
            </div>
          ) : (
            <ConfigTable
              isDeleteBtnShow
              headCells={tableHeadCells}
              tableContentList={tableContentList}
              editedData={editedData}
              isAdding={isAdding}
              isEditing={isEditing}
              handleIsAdding={setIsAdding}
              onRowEditClick={onRowEditClick}
              handleRowEditing={handleRowEditing}
              handleRowDateEditing={handleRowDateEditing}
              handleEditData={handleEditData}
              handleRowError={handleRowError}
              onRowDelete={handleRowDelete}
            />
          )}
        </PaperContainer>
      )}
    </Grid>
  );
};
export default FormingStock;
