/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Chart, registerables } from 'chart.js';
import Typography from '../../../components/Typography/Typography';
import Dialog from '../../../components/Dialog/Dialog';
import DialogTitle from '../../../components/DialogTitle/DialogTitle';
import DialogContent from '../../../components/DialogContent/DialogContent';
import DialogActions from '../../../components/DialogActions/DialogActions';
import CircularProgress from '../../../components/CircularProgress/CircularProgress';
import useStyles from './useStyles';
import useCommonStyles from '../useStyles';
import { CHART_COLORS } from '../../../Utils/chartUtils';
import { parseDecimalToFixedString } from '../../../Utils/stringUtil';

const YieldRateChart = ({
  open,
  handleChartClose,
  targetScOdNo,
  yieldRateData,
  toRender,
  setToRender,
}) => {
  const [isLoading, setInsLoading] = useState(false);
  const yieldPieChartEl = useRef(null);
  const yieldPieChart = useRef(null);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  Chart.register(...registerables);

  const drawYieldPieChart = () => {
    if (yieldPieChart.current) {
      yieldPieChart.current.destroy();
    }
    yieldPieChartEl.current = document.getElementById('yieldRateChart');
    const ctx = yieldPieChartEl.current.getContext('2d');
    yieldPieChart.current = new Chart(ctx, {
      data: {
        labels: Array.from(yieldRateData, (item) => item.detectName),
        datasets: [
          {
            type: 'pie',
            data: Array.from(yieldRateData, (item) => item.detectCount),
            backgroundColor: CHART_COLORS,
          },
        ],
      },
      options: {
        aspectRatio: 1.8,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            labels: {
              padding: 15,
              generateLabels: (chart) => {
                const { data } = chart;
                return data.labels.map((label, i) => {
                  const custom = data.datasets[0];
                  const fill = custom.backgroundColor[i % CHART_COLORS.length];
                  const stroke = custom.borderColor;

                  return {
                    text: `${label}`,
                    fillStyle: fill,
                    strokeStyle: stroke,
                    lineWidth: 0,
                    index: i,
                  };
                });
              },
            },
          },
          tooltip: {
            usePointStyle: true,
            callbacks: {
              labelPointStyle: (context) => ({
                pointStyle: 'circle',
              }),
              label: (context) => ` 次數： ${context.formattedValue}`,
              afterLabel: (context) => {
                const { dataIndex } = context;
                return ` 佔比： ${parseDecimalToFixedString(
                  yieldRateData[dataIndex].detectPersent * 100
                )}%`;
              },
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (!toRender) return;
    setTimeout(() => {
      drawYieldPieChart();
      setToRender(false);
    }, 100);
  }, [toRender]);

  useEffect(() => {
    if (open) return;
    if (yieldPieChart.current) {
      yieldPieChart.current.destroy();
    }
  }, [open]);

  return (
    <div className={classes.dialog}>
      <Dialog open={open} onClose={handleChartClose} fullWidth maxWidth="md">
        <DialogTitle
          id="detail-title"
          onClose={handleChartClose}
          className={commonClasses.detail_header}
        >
          {targetScOdNo}｜個別不良原因佔比
        </DialogTitle>
        <DialogContent
          dividers
          className={`${commonClasses.detail_content} ${classes.yield_chart_container}`}
        >
          {yieldRateData.length === 0 && (
            <Typography variant="subtitle1">此單沒有相關不良原因！</Typography>
          )}
          <canvas id="yieldRateChart" width="600" height="400" />
          {isLoading && <CircularProgress mt={7.5} />}
        </DialogContent>
        <DialogActions className={commonClasses.detail_footer} onClick={handleChartClose}>
          確認
        </DialogActions>
      </Dialog>
    </div>
  );
};

YieldRateChart.propTypes = {
  open: PropTypes.bool.isRequired,
  handleChartClose: PropTypes.func.isRequired,
  targetScOdNo: PropTypes.string.isRequired,
  yieldRateData: PropTypes.array.isRequired,
  toRender: PropTypes.bool.isRequired,
  setToRender: PropTypes.func.isRequired,
};

export default YieldRateChart;
