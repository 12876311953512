/* eslint-disable import/prefer-default-export */
import useAxios from '../hooks/useAxios';
import { dialogActions } from '../reducers/dialogReducer';

// eslint-disable-next-line consistent-return
export const handleUpdateTransTime = async (newCancelToken, dialogDispatch, data) => {
  const cancelToken = newCancelToken();
  const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
    '/updateTransTime',
    { timerRecord: data },
    true,
    cancelToken
  );
  if (status) {
    const { timerRecord } = result;
    return [status, timerRecord];
  }
  dialogDispatch({
    type: dialogActions.OPEN,
    config: {
      singleBtn: true,
      title: '更新錯誤',
      message: result || noPrivilegeMsg || '目前無法更新PROCESS時間，請稍後再試，或聯繫網管人員',
    },
  });
  return [status];
};
